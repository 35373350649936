import { render, staticRenderFns } from "./JewelryKind.vue?vue&type=template&id=74dc23cb&scoped=true&"
import script from "./JewelryKind.vue?vue&type=script&lang=js&"
export * from "./JewelryKind.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74dc23cb",
  null
  
)

export default component.exports