<template>
  <v-container fluid class="container_bg">

    <v-row align="center" justify="center" class="mt-5" dense>
      <v-col cols="9" md="9">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row dense align="center" justify="end">

      <v-col cols="6" md="2" class="pl-3 pr-3 pl-md-0 pr-md-3 pa-1">
        <v-select 
          v-model="SEARCH_DIAMOND_CLASS" :items="DIASEARCHTYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="search_run"
          />
      </v-col>

      <v-col cols="6" md="2" class="pl-3 pr-3 pl-md-0 pr-md-3 pa-1">
        <v-select 
          v-model="SEARCH_DIAMOND_SHAPE" :items="DIASHAPELIST" item-color="black"
          placeholder="구분" dense item-text="NAME_ENG" item-value="IDX"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="search_run"
          />
      </v-col>
      
      <v-col cols="12" md="3" class="pa-0 pl-3 pl-md-0 pr-3 pr-md-3">
        <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
      </v-col>

      <v-col cols="6" md="2" class="pa-0 pr-3 pl-md-0 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="startDownload">
          <v-icon left>mdi-download-box</v-icon>Excel Download<br>엑셀다운
        </v-btn>
      </v-col>

    </v-row>


    <v-row class="mt-5">
      <v-col>
        <v-simple-table 
          height="70vh"
          dense fixed-header
          class="datatablestyle"
          >
          <template v-slot:default>
            <thead style="height:48px">
              <tr>
                <th class="text-center header_text_style" style="min-width:180px" >계정</th>
                <th class="text-center header_text_style" >모양</th>
                
                <th class="text-right header_text_style">천연</th>
                <th class="text-right header_text_style">천연무게</th>
                <th class="text-right header_text_style">천연달러</th>
                <th class="text-right header_text_style">천연원화</th>

                <th class="text-right header_text_style">합성</th>
                <th class="text-right header_text_style">합성무게</th>
                <th class="text-right header_text_style">합성달러</th>
                <th class="text-right header_text_style">합성원화</th>

              </tr>
            </thead>
            <tbody>
              <tr style="background-color:#f0f0f0">
                <td class="text-center" colspan="2">합계</td>
                <td class="text-right">{{ priceToString(sum1) }}</td>
                <td class="text-right">{{ priceToString(sum2.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum3.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum4) }}</td>

                <td class="text-right">{{ priceToString(sum5) }}</td>
                <td class="text-right">{{ priceToString(sum6.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum7.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum8) }}</td>

              </tr>
              <tr v-for="(item,index) in list" :key="index">
                <td class="text-center" >{{ item.NAME }}</td>
                <td class="text-center" >{{ item.SHAPE_NAME_ENG }}</td>

                <td class="text-right">{{ priceToString(item.DIA1) }}</td>
                <td class="text-right">{{ priceToString(item.DIA1WEIGHT) }}</td>
                <td class="text-right">{{ priceToString(item.DIA1USD) }}</td>
                <td class="text-right">{{ priceToString(item.DIA1WON) }}</td>

                <td class="text-right">{{ priceToString(item.DIA2) }}</td>
                <td class="text-right">{{ priceToString(item.DIA2WEIGHT) }}</td>
                <td class="text-right">{{ priceToString(item.DIA2USD) }}</td>
                <td class="text-right">{{ priceToString(item.DIA2WON) }}</td>

              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>

import XLSX from 'xlsx'
export default {


  beforeDestroy(){
    
  },

  mounted(){

    if( this.$session.get("GRADE") < 5 || (this.$session.get("GRADE") == 5 && this.$session.get("SUB_DAYS") < 0) ){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
    }

    this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
    this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
    this.grade = this.$session.get("GRADE");


    if(this.$session.exists("ACCNT_IDX")){
      this.initpage();
    }else{
      //키값 없으면 로그인으로
      this.$router.push("/Login");
    }
  },

  data: () => ({

      list:[],
      sum1:0,
      sum2:0,
      sum3:0,
      sum4:0,
      sum5:0,
      sum6:0,
      sum7:0,
      sum8:0,
      
      SEARCH_DIAMOND_CLASS:-1,
      SEARCH_DIAMOND_SHAPE:-1,
      DIASEARCHTYPELIST: [
          {name:'전체',         value:-1}
        , {name:'Natural/천연', value:0}
        , {name:'Lab-Grown/합성',   value:1}
      ],
      DIASHAPELIST:[],

      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,

  }),

  methods: {
    

    startDownload(){

      this.$confirm("엑셀파일로 다운로드 하시겠습니까?").then(() => {

        var templist = [];
        this.list.forEach(item=>{
          templist.push({
            "계정":item.NAME
          , "모양":item.SHAPE_NAME_ENG
          , "천연":parseInt(item.DIA1)
          , "천연무게":parseFloat(item.DIA1WEIGHT)
          , "천연달러":parseFloat(item.DIA1USD)
          , "천연원화":parseInt(item.DIA1WON)
          , "합성":parseInt(item.DIA2)
          , "합성무게":parseFloat(item.DIA2WEIGHT)
          , "합성달러":parseFloat(item.DIA2USD)
          , "합성원화":parseInt(item.DIA2WON)
          });
        });

        //하단 합계 넣기
        templist.push({
            "계정":""
          , "모양":"합계"
          , "천연":this.priceToString(this.sum1)
          , "천연무게":this.priceToString(this.sum2.toFixed(2))
          , "천연달러":this.priceToString(this.sum3.toFixed(2))
          , "천연원화":this.priceToString(this.sum4)
          , "합성":this.priceToString(this.sum5)
          , "합성무게":this.priceToString(this.sum6.toFixed(2))
          , "합성달러":this.priceToString(this.sum7.toFixed(2))
          , "합성원화":this.priceToString(this.sum8)
        });
        
        var item_sheet = XLSX.utils.json_to_sheet(templist);

        //상단 컬럼명 수정
        item_sheet.A1 = {v:'계정'};
        item_sheet.B1 = {v:'모양'};

        item_sheet.C1 = {v:'천연'};
        item_sheet.D1 = {v:'천연무게'};
        item_sheet.E1 = {v:'천연달러'};
        item_sheet.F1 = {v:'천연원화'};

        item_sheet.G1 = {v:'합성'};
        item_sheet.H1 = {v:'합성무게'};
        item_sheet.I1 = {v:'합성달러'};
        item_sheet.J1 = {v:'합성원화'};

        //컬럼별 넓이
        var wscols = [{wch:20},{wch:10},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'}];
        item_sheet['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, item_sheet, '다이아통계');
        XLSX.writeFile(wb, '다이아통계_'+Date.now().toString()+'.xlsx');

      });

    },    

    initpage(){
      this.getTypeList();
      this.search_run();
    },


      //타입 가져오기
      getTypeList(){
        this.DIASHAPELIST.splice(0, this.DIASHAPELIST.length); 
        this.$http.post(this.$host+'/GuestTotalTypeList',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
            const list1 = result.data.DiamondShape;
            
            this.DIASHAPELIST.push({NAME_ENG:"전체", IDX:-1}); 
            list1.forEach(element => {
                this.DIASHAPELIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },          

    search_run(){

      this.$http.post(this.$host+'/ChartDiamonds',{
            ACCNT_IDX: this.SEARCH_ACCNT_IDX
          , SEARCH_DIAMOND_CLASS: this.SEARCH_DIAMOND_CLASS
          , SEARCH_SHAPE: this.SEARCH_DIAMOND_SHAPE
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{

        if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            this.list.splice(0, this.list.length);   //데이터 초기화
            this.list = result.data.resultData;

            this.sum1 = 0;
            this.sum2 = 0;
            this.sum3 = 0;
            this.sum4 = 0;
            this.sum5 = 0;
            this.sum6 = 0;
            this.sum7 = 0;
            this.sum8 = 0;

            this.list.forEach(element => {

              this.sum1 += parseInt(element.DIA1);
              this.sum2 += parseFloat(element.DIA1WEIGHT);
              this.sum3 += parseFloat(element.DIA1USD);
              this.sum4 += parseInt(element.DIA1WON);

              this.sum5 += parseInt(element.DIA2);
              this.sum6 += parseFloat(element.DIA2WEIGHT);
              this.sum7 += parseFloat(element.DIA2USD);
              this.sum8 += parseInt(element.DIA2WON);

            });

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$fire({
            html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
              this.$EventBus.$emit('clearsession');
            }
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        
        if(error.message == 'Request failed with status code 429')
        {
          this.$fire({
            html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }else{
          this.$fire({
            html: error.message,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }
      });

    },

    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    //검색조건 업체 설정
    comsearchcb(val1){
      if(val1.NAME.length > 0){
        this.SEARCH_ACCNT_NAME = val1.NAME;
        this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
        this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
        this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
      }else{
        this.SEARCH_ACCNT_NAME = '';
        this.SEARCH_ACCNT_IDX  = null;
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
      }
      this.search_run();
    },

    // eslint-disable-next-line no-unused-vars
    comsearchclear(var2){
        this.SEARCH_ACCNT_IDX    = null;
        this.SEARCH_ACCNT_NAME   = '';
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
        this.search_run();
    },      


  }
    
}

</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>