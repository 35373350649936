<template>
  <v-container fluid class="container_bg">

    <v-row align="center" justify="center" class="mt-5" dense>
      <v-col cols="9" md="9">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-spacer/>
      <template v-if="grade == 99">
        <v-col cols="12" md="3" class="pa-0 pl-3 pl-md-0 pr-3 pr-md-0">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="3" class="pa-0"/>
      </template>
      
      <v-col cols="12" md="3" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_PAYTYPE" :items="PAYTYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="search_run"
          />
      </v-col>
    </v-row>

    <v-row align="center" dense>
      <v-spacer/>
      <v-col cols="12" md="2" sm="12" xs="12">
        <v-menu
          v-model="date1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_start_date"
              label="검색 시작일"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_start_date" @input="date1 = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>      
      </v-col>
      <v-col cols="12" md="2" sm="12" xs="12">                      
        <v-menu
          v-model="date2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_end_date"
              label="검색 종료일"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_end_date" @input="date2=false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>                    
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="6" class="d-flex justify-center">
        <v-checkbox v-model="checkdate" dense label="일자별보기" @change="search_run"/>
      </v-col>
      <v-col cols="6" md="2" class="pa-0 pr-3 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="startDownload">
          <v-icon left>mdi-download-box</v-icon>Excel Download<br>엑셀다운
        </v-btn>
      </v-col>
    </v-row>    

    <v-row>
      <v-col>
        <v-simple-table 
          height="40vh"
          dense fixed-header
          class="datatablestyle"
          >
          <template v-slot:default>
            <thead style="height:48px">
              <tr>
                <th class="text-center header_text_style">날짜</th>
                <th class="text-center header_text_style">건수</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color:#f0f0f0">
                <td class="text-center">합계</td>
                <td class="text-center">{{ cntsum }}</td>
              </tr>
              <tr v-for="item in list" :key="item.REG_DATE">
                <td class="text-center">{{ item.REG_DATE }}</td>
                <td class="text-center">{{ item.CNT }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-spacer/>
      <v-col cols="12" align-self="center">
        <canvas ref="drawarea" style="width:100%;height:50vh"/>
      </v-col>
      <v-spacer/>
    </v-row>

  </v-container>
</template>
<script>

import Chart from 'chart.js/auto';
import moment from 'moment'
import XLSX from 'xlsx'

export default {

  beforeDestroy(){
    
    //차트 초기화 - 제대로 안지우면 인스턴스 계속 쌓인다.
    if(this.myChart2 != null){
      this.myChart2.destroy();
    }
  },

  mounted() {

    if( this.$session.get("GRADE") < 5 || (this.$session.get("GRADE") == 5 && this.$session.get("SUB_DAYS") < 0) ){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
    }

    this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
    this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
    this.grade = this.$session.get("GRADE");

    this.search_start_date = moment().subtract(1, 'YEARS').format("YYYY-MM-DD");
    this.search_end_date   = moment().format("YYYY-MM-DD");

    if(this.$session.exists("ACCNT_IDX")){
      this.initpage();
    }else{
      //키값 없으면 로그인으로
      this.$router.push("/Login");
    }

  },

    

    data: () => ({
      myChart2: null,
      date1:false,
      date2:false,
      search_start_date : '',
      search_end_date : '',
      checkdate : true,
      list:[],
      cntsum:0,

      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,

      SEARCH_PAYTYPE:1,
      PAYTYPELIST: [
          {name:'구매요청',  value:1}
        , {name:'거래완료',  value:25}
        , {name:'거래취소',  value:50}
      ],

    }),

  methods: {

    startDownload(){

      this.$confirm("엑셀파일로 다운로드 하시겠습니까?").then(() => {

        var templist = [];
        this.list.forEach(item=>{
          templist.push({"REG_DATE":item.REG_DATE, "CNT":item.CNT});
        });

        //하단 합계 넣기
        templist.push({"REG_DATE":"합계", "CNT":this.cntsum});
        
        var item_sheet = XLSX.utils.json_to_sheet(templist);

        //상단 컬럼명 수정
        item_sheet.A1 = {v:'날짜'};
        item_sheet.B1 = {v:'건수'};

        //컬럼별 넓이
        var wscols = [{wch:20},{wch:10}];
        item_sheet['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, item_sheet, '건수');
        XLSX.writeFile(wb, '건수_'+Date.now().toString()+'.xlsx');

      });

    },

    initpage(){
      this.search_run();
    },    

    search_run(){

      if(this.grade < 99){
        if(this.SEARCH_ACCNT_IDX == null){
          this.$alert("검색창 상단의 공급처를 선택해주세요.");
          return;
        }
      }

      if(this.search_start_date.length < 4){
        this.$alert("검색 시작일을 입력하세요.");
        return;
      }
      if(this.search_end_date.length < 4){
        this.$alert("검색 종료일을 입력하세요.");
        return;
      }
      if(this.search_start_date > this.search_end_date){
        this.$alert("시작일이 종료일보다 큽니다.");
        return;
      }

      this.$http.post(this.$host+'/ChartNumber',{
            ACCNT_IDX: this.SEARCH_ACCNT_IDX
          , SEARCH_PAYTYPE:   this.SEARCH_PAYTYPE
          , CHECKDATE: this.checkdate
          , search_start_date:this.search_start_date == undefined ? '' : this.search_start_date
          , search_end_date:this.search_end_date == undefined ? '' : this.search_end_date
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{

        if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            //chart.js로 차트를 그린다. 캔버스를 읽어서 차트객체를 만들어주면 알아서 생성된다.
            const ctx2 = this.$refs.drawarea;
            //캔버스 초기화
            if(this.myChart2 != null){
              this.myChart2.destroy();
            }

            //ctx2.getContext("2d").clearRect(0, 0, ctx2.width, ctx2.height);
            this.list.splice(0, this.list.length);   //데이터 초기화
            this.list = result.data.resultData;
            const datanames = [];
            const step1 = []; //출력
            this.cntsum = 0;
            this.list.slice().reverse().forEach(element => {
              datanames.push(element.REG_DATE);
              step1.push(element.CNT);
              this.cntsum += parseInt(element.CNT);
            });

            const datas = {
              labels:datanames, //차트 하단에 표시됨
              datasets:[
                  {label:'건수', data:step1, backgroundColor: 'rgba(9, 156, 253, 0.5)',borderColor: 'rgb(9, 156, 253)', lineTension:0.3, fill: false,}
              ],
            };

            const options = {
              plugins:{
                title: {
                    display: true,
                    text: '건수'
                },             
                legend: { 
                    position: 'right' 
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                      label: function(tooltipItem, tdata) {
                        var value1 = tdata.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return parseInt(value1).toLocaleString();
                      }
                    }
                },
              },
              hover: {
                  mode: 'nearest',
                  intersect: true
              },
              scales: {
                  x: {
                      display: true,
                      ticks:{reverse:true},
                      scaleLabel: {
                          display: true,
                          labelString: '날짜'
                      }
                  },
                  y: {
                      display: true,
                      ticks: {
                          suggestedMin: 0,
                          stepSize : 1,
                      },
                      scaleLabel: {
                          display: true,
                          labelString: '건수'
                      }
                  }
              },    
              responsive: true,
              maintainAspectRatio: false
            };     

            //차트 객체에는 캔버스 객체와 타입, 데이터, 옵션이 들어간다.
            //eslint-disable-next-line no-unused-vars
            this.myChart2 = new Chart(ctx2, {type:'line', data:datas, options: options});            


        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$fire({
            html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
              this.$EventBus.$emit('clearsession');
            }
          });
        }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
              html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }else{
            this.$fire({
              html: error.message,
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }
        });

    },


    //검색조건 업체 설정
    comsearchcb(val1){
      if(val1.NAME.length > 0){
        this.SEARCH_ACCNT_NAME = val1.NAME;
        this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
        this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
        this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
      }else{
        this.SEARCH_ACCNT_NAME = '';
        this.SEARCH_ACCNT_IDX  = null;
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
      }
      this.search_run();
    },

    // eslint-disable-next-line no-unused-vars
    comsearchclear(var2){
        this.SEARCH_ACCNT_IDX    = null;
        this.SEARCH_ACCNT_NAME   = '';
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
        this.search_run();
    },      


  },

}
</script>

<style scoped>

</style>