<template>
  <v-container fluid class="container_bg">

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row align="center" justify="center" dense>
      <v-col cols="12" md="8" align="center"> <!-- 해상도 조절 컬럼 -->


        <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
          <v-col cols="6" md="2" align="start">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">다이아몬드 도매</span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.DIAWHOLE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">다이아몬드 소매 </span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.DIARETAIL"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          
          <v-col cols="6" md="2" offset-md="1" align="start">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">렙-그로운 다이아몬드 도매</span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.FDIAWHOLE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">렙-그로운 다이아몬드 소매 </span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.FDIARETAIL"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
        </v-row>                  


        <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
          <v-col cols="6" md="2" align="start">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">젬스톤 도매</span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.GEMWHOLE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">젬스톤 소매 </span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.GEMRETAIL"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          
          <v-col cols="6" md="2" offset-md="1" align="start">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">주얼리 도매</span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.JEWELYWHOLE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">주얼리 소매 </span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.JEWELYRETAIL"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
        </v-row>                  


        <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
          <v-col cols="6" md="2" align="start">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">세공 도매</span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.CRAFTWHOLE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">세공 소매 </span></p>
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.CRAFTRETAIL"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
          
          <v-col cols="6" md="2" offset-md="1" align="start">
          </v-col>
          <v-col cols="6" md="2" align="start"  class="ma-0 pa-0">
          </v-col>
        </v-row>                  


        <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
          <v-col cols="12" md="9" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Exchange Rate&nbsp;<span class="dialog_subtitle_kor">환율</span></p>
          </v-col>
          <v-col cols="12" md="9" class="ma-0 pa-0">
            <v-text-field 
              class="none_radius" color="black"
              single-line v-model="editedItem.EXCHANGE"
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
        </v-row>                  

        <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
          <v-col cols="12" md="9" align="start"  class="ma-0 pa-0">
            <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Bank account number&nbsp;<span class="dialog_subtitle_kor">계좌번호</span></p>
          </v-col>
          <v-col cols="12" md="9" class="ma-0 pa-0">
            <v-textarea
              class="none_radius" color="black"
              single-line v-model="editedItem.BANKNUMBER"
              height="70"
              outlined dense hide-details/>
          </v-col>
        </v-row>                  

        <v-row align="center" justify="center" class="mt-10">
          <v-col cols="11" md="6" class="ma-0 pa-0">
            <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
          </v-col>
        </v-row>


      </v-col>
    </v-row>

  </v-container>
</template>
<script>
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    mounted() {
      if(this.$session.get("GRADE") < 90){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    data: () => ({

      editedItem:{}

    }),

    created(){

    },

    methods: {
      
      back(){
        this.$router.back();
      },

      initpage(){
        this.loadpage();
      },  


      loadpage(){

        this.$http.post(this.$host+'/SettingValue',{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.editedItem = result.data.resultData[0];

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },      


      save(){

        const checkwhosale = cmvalidation.checkValReg(this.editedItem.DIARETAIL, /[^0-9.]/g, -1, -1);
        if(checkwhosale == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkwhosale == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.DIARETAIL <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check2 = cmvalidation.checkValReg(this.editedItem.DIAWHOLE, /[^0-9.]/g, -1, -1);
        if(check2 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check2 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.DIAWHOLE <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check3 = cmvalidation.checkValReg(this.editedItem.FDIARETAIL, /[^0-9.]/g, -1, -1);
        if(check3 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check3 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.FDIARETAIL <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check4 = cmvalidation.checkValReg(this.editedItem.FDIAWHOLE, /[^0-9.]/g, -1, -1);
        if(check4 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check4 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.FDIAWHOLE <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check5 = cmvalidation.checkValReg(this.editedItem.GEMRETAIL, /[^0-9.]/g, -1, -1);
        if(check5 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check5 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.GEMRETAIL <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check6 = cmvalidation.checkValReg(this.editedItem.GEMWHOLE, /[^0-9.]/g, -1, -1);
        if(check6 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check6 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.GEMWHOLE <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check7 = cmvalidation.checkValReg(this.editedItem.JEWELYRETAIL, /[^0-9.]/g, -1, -1);
        if(check7 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check7 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.JEWELYRETAIL <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check8 = cmvalidation.checkValReg(this.editedItem.JEWELYWHOLE, /[^0-9.]/g, -1, -1);
        if(check8 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check8 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.JEWELYWHOLE <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }              
        

        const check9 = cmvalidation.checkValReg(this.editedItem.CRAFTRETAIL, /[^0-9.]/g, -1, -1);
        if(check9 == 1){
          this.$fire({
            html: "Please enter craft margin rate<br><br>세공 소매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check9 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for craft margin rates.<br><br>세공 소매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.CRAFTRETAIL <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>세공 소매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const check10 = cmvalidation.checkValReg(this.editedItem.CRAFTWHOLE, /[^0-9.]/g, -1, -1);
        if(check10 == 1){
          this.$fire({
            html: "Please enter wholesale margin rate<br><br>세공 도매 마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(check10 == 2){
          this.$fire({
            html: "Only numbers and . can be entered for wholesale margin rates.<br><br>세공 도매 마진율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.CRAFTWHOLE <= 0){
          this.$fire({
            html: "Wholesale margin ratio cannot be equal to or less than zero.<br><br>세공 도매 마진율은 0보다 작거나 같을 수 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        


        const checkexchange = cmvalidation.checkValReg(this.editedItem.EXCHANGE, /[^0-9.]/g, -1, -1);
        if(checkexchange == 1){
          this.$fire({
            html: "Enter the exchange rate<br><br>환율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkexchange == 2){
          this.$fire({
            html: "exchange rate can only be numeric and .<br><br>환율은 숫자와 .만 입력할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const checkbank = cmvalidation.checkValReg(this.editedItem.BANKNUMBER, null, 8, 100);
        if(checkbank == 1){
          this.$fire({
            html: "Enter the Bank account number rate<br><br>계좌번호를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkbank == 3){
          this.$fire({
            html: "Please enter at least 8 characters of bank account number<br><br>계좌번호는 8자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkbank == 4){
          this.$fire({
            html: "Please enter your bank account number in 100 characters or less<br><br>계좌번호는 100자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

        this.$http.post(this.$host+'/SettingValueUpdate',{
            DIARETAIL:    this.editedItem.DIARETAIL
          , DIAWHOLE:     this.editedItem.DIAWHOLE
          , FDIARETAIL:   this.editedItem.FDIARETAIL
          , FDIAWHOLE:    this.editedItem.FDIAWHOLE
          , GEMRETAIL:    this.editedItem.GEMRETAIL
          , GEMWHOLE:     this.editedItem.GEMWHOLE
          , JEWELYRETAIL: this.editedItem.JEWELYRETAIL
          , JEWELYWHOLE:  this.editedItem.JEWELYWHOLE
          , CRAFTRETAIL: this.editedItem.CRAFTRETAIL
          , CRAFTWHOLE:  this.editedItem.CRAFTWHOLE
          , EXCHANGE:   this.editedItem.EXCHANGE
          , BANKNUMBER: escape(this.editedItem.BANKNUMBER)
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },      


    },



}
</script>

<style scoped>

</style>