<template>
  <v-container fluid style="background:#ffffff">

    <!-- 주소팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="address_dialog" max-width="500px">
        <v-card class="pa-0 ma-0">
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Search Address&nbsp;주소검색</span>
            </v-col>
          </v-row>
          </v-card-title>
          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
          </v-card-text>
          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="address_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="address_dialog = false">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <pdfinfo ref="pdfinfo" />

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">Please wait...</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <!-- 이용 약관 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="agree1_dialog" fullscreen>
        <v-card class="dialog_round pa-0 ma-0" style="overflow-x:hidden;">

          <v-row dense class="mt-3 mb-3" justify="center">
            <v-col cols="10" md="5" align="center">
              <span class="dialog_title">Terms and Conditions of Service<br>서비스 이용약관</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center">
              <v-col cols="12" md="5" sm="10" xs="10">            

                <h2 class="text-md-h6 text-subtitle-2">제1조 (목적)</h2>              
                <p class="text-md-body-1 text-caption">
                  이 약관은 (주)KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 운영하는 kDiaGem App(이하 “kDiaGem App”이라 한다)에서 제공하는 인터넷 관련 서비스를 이용함에 있어 (주)KDGE와 이용자의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.<br>※「PC 통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」
                </p>

                <h2 class="text-md-h6 text-subtitle-2">Article 1 (Purpose)</h2>
                <p class="text-md-body-1 text-caption">
                  These Terms and Conditions stipulate the rights, obligations and responsibilities of KDGE Co., Ltd. and its users in using Internet-related services provided by kDiaGem App (hereinafter referred to as “kDiaGem App”) operated by KDGE Co., Ltd. &lt;Korea DIAMOND GEM Exchange&gt;. is intended for.<br>※“Even for e-commerce transactions using PC communication, wireless, etc., these Terms and Conditions shall be applied mutatis mutandis as long as they are not contrary to their nature.”
                </p>

                <h2 class="text-md-h6 text-subtitle-2">제2조 (정의)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem APP”이라 함은 (주)KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 컴퓨터 등 정보통신설비를 이용하여 “다이아몬드와 보석 및 주얼리 등”를 구매자에게 판매하기 위하여 설립한 가상 온라인상의 영업장을 말합니다.</li>
                  <li>“이용자”란 "kDiaGem App"에 접속하여 "다이아몬드, 보석 및 주얼리 등"를 판매하거나 구매할 수 있는 회원 및 비회원을 말합니다.</li>
                  <li>"회원"이란 "kDiaGem App"에 회원 등록을 한 자로서, 계속하여 "kDiaGem App"이 제공하는 서비스를 이용할 수 있는 자를 말합니다.</li>
                  <li>"비회원"이라 함은 회원에 가입하지 않고 "kDiaGem App"에서 제공하는 서비스를 이용하는 자를 말합니다.</li>
                </ol>            
                <h2 class="text-md-h6 text-subtitle-2">Article 2 (Definition)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem APP” refers to an online business site established by KDGE &lt;Korea DIAMOND GEM Exchange&gt; to sell “diamonds, gemstones and jewelry” to buyers using information and communication facilities such as computers.</li>
                  <li>“User” refers to members and non-members who can access “kDiaGem App” to sell or purchase “diamonds, gems and jewellery.”</li>
                  <li>"Member" means a person who has registered as a member of "kDiaGem App" and can continue to use the services provided by "kDiaGem App".</li>
                  <li>"Non-member" refers to a person who uses the services provided by "kDiaGem App" without subscribing to membership.</li>
                </ol>            
                <br>

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제3조 (안전 거래)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>kDiaGem App에서 판매하는 모든 상품의 품질은 (주)KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 보증합니다.</li>
                  <li>구매자가 처음 주문한 내용과 다를 경우에는 언제든지 취소할 수 있고 수수료도 발생하지 않습니다.</li>
                  <li>만약 구매자가 “kDiaGem App”에서 구매한 상품으로 손해가 발생한다면 (주)KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 배상을 책임집니다.</li>
                </ol>            
                <h2 class="text-md-h6 text-subtitle-2">Article 3 (Safe Transaction)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>The quality of all products sold in the kDiaGem App is guaranteed by KDGE &lt;Korea DIAMOND GEM Exchange&gt;.</li>
                  <li>If the order is different from the original order, the buyer can cancel at any time and no fee will be charged.</li>
                  <li>If the buyer suffers damages from the product purchased from “kDiaGem App”, KDGE &lt;Korea DIAMOND GEM Exchange&gt; is responsible for compensation.</li>
                </ol>            
                <br>

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제4조 (약관 등의 명시와 설명 및 개정)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지의 주소, 사업자등록번호 등을 이용자가 쉽게 알 수 있도록 “kDiaGem App”의 초기 서비스 화면에 게시합니다. 다만, 약관의 내용은 이용자가 연결 화면을 통하여 볼 수 있도록 게시할 수 있습니다.</li>
                  <li>“kDiaGem App”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자 문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명 법」, 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등의 관련 법을 위배하지 않는 범위에서 이 약관을 언제든지 개정할 수 있습니다.</li>
                  <li>“kDiaGem App”이 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 “kDiaGem App”의 초기 화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관 내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "kDiaGem App”은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시하여 안내합니다.</li>
                  <li>“kDiaGem App”이 약관을 개정할 경우에는 그 개정 약관은 적용 일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기 원하는 뜻을 제3항에 의한 개정 약관의 공지 기간 내에 “kDiaGem App”에 송신하여 “kDiaGem App”의 동의를 받은 경우에는 개정 약관 조항이 적용될 수 있습니다.</li>
                  <li>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관해서는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래 위원회가 정하는 전자상거래 등에서의 소비자 보호 지침 및 관계법령 또는 관례에 따릅니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 4 (Specification, Explanation and Revision of Terms and Conditions, etc.)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” posts the contents of these Terms and Conditions, company name and representative name, business address, business registration number, etc. on the initial service screen of “kDiaGem App” so that users can easily find out. However, the contents of the terms and conditions can be posted so that users can view them through the connection screen.</li>
                  <li>“kDiaGem App” is subject to the 「Act on Consumer Protection in Electronic Commerce, Etc.」, 「Regulation of Terms and Conditions」, 「Framework Act on Electronic Documents and Electronic Commerce」, 「Electronic Financial Transaction Act」, 「Electronic Signature Act」, 「 These terms and conditions may be amended at any time within the scope of not violating relevant laws such as the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc., the 「Door-to-Door Sales Act」, and the 「Framework Act on Consumers」.</li>
                  <li>If “kDiaGem App” revises the terms and conditions, the date of application and reason for revision shall be specified and announced along with the current terms and conditions on the initial screen of “kDiaGem App” from 7 days before the effective date to the day before the effective date. However, if the contents of the terms and conditions are changed unfavorably to the user, it will be notified with a grace period of at least 30 days in advance. In this case, the "kDiaGem App" clearly compares the content before and after the revision, and displays and guides the user in an easy-to-understand manner.</li>
                  <li>If “kDiaGem App” revises the terms and conditions, the revised terms and conditions apply only to contracts concluded after the effective date, and the terms and conditions before the revision apply to contracts already concluded before that date. However, if a user who has already entered into a contract transmits his or her desire to be subject to the revised terms and conditions to “kDiaGem App” within the notice period of the revised terms and conditions pursuant to Paragraph 3 and receives consent from “kDiaGem App,” the revised terms and conditions will not be enforced. may apply.</li>
                  <li>Matters not specified in these Terms and Conditions and interpretation of these Terms and Conditions shall be governed by the Act on Consumer Protection in Electronic Commerce, etc., the Act on Regulation of Terms and Conditions, and the Consumer Protection Guidelines and related laws in e-commerce, etc. set forth by the Fair Trade Commission.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제5조 (서비스의 제공 및 변경)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>kDiaGem APP”은 다음과 같은 업무를 수행합니다.</li>
                  <li>“다이아몬드, 보석, 주얼리”에 대한 정보 제공 및 구매계약의 체결</li>
                  <li>구매계약이 체결된 “다이아몬드, 보석, 주얼리”의 납품</li>
                  <li>이용자가 보유한 다이아몬드, 보석 및 주얼리의 경매 대행</li>
                  <li>기타 “kDiaGem App”이 정하는 업무</li>
                  <li>kDiaGem App”은 “다이아몬드, 보석, 주얼리”의 품절 또는 기술적 사양의 표시 변경될 수 있습니다.</li>
                  <li>또한 판매 완료에 따라 온라인상 전시된 상품 중에서 일부 다이아몬드, 보석, 주얼리의 거래가 불가능할 수 있습니다.</li>
                  <li>7항의 경우에도 이용자 피해는 발생하지 않습니다. 그러나 만약 전항으로 인해 소비자 피해가 발생한다면 “kDiaGem App”은 이로 인하여 이용자가 입은 손해를 배상합니다.</li>
                  <li>다만, “kDiaGem App”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 5 (Provision and change of service)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>kDiaGem APP” performs the following tasks.</li>
                  <li>Provision of information on “diamonds, gemstones, jewelry” and conclusion of purchase contracts.</li>
                  <li>Delivery of “diamonds, gemstones, jewelry” for which a purchase contract has been concluded.</li>
                  <li>Auction agency for diamonds, gemstones and jewelry owned by users.</li>
                  <li>Other tasks specified by “kDiaGem App”</li>
                  <li>“kDiaGem App” may be sold out of “diamonds, gemstones, jewelry” or the display of technical specifications may be changed.</li>
                  <li>In addition, depending on the completion of the sale, some diamonds, gemstones, and jewelry among the products displayed online may not be tradeable.</li>
                  <li>Even in the case of paragraph 7, no user damage occurs. However, if consumer damage occurs due to the preceding paragraph, “kDiaGem App” compensates for damages suffered by the user.</li>
                  <li>However, this is not the case if “kDiaGem App” proves that there is no intention or negligence.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제6조 (서비스의 중단)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”은 컴퓨터와 같은 정보통신 설비의 보수 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.</li>
                  <li>“kDiaGem App”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다.</li>
                  <li>단, “kDiaGem App”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.</li>
                  <li>사업 종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 7일 전에 “kDiaGem App”의 공지사항에 고지합니다.</li>
                  <li>만약 “kDiaGem App”은 서비스를 중지할 경우에도 구매자의 주문품은 차질 없이 납품을 합니다.</li>
                  <li>만약 서비스 중단으로 구매자 피해가 발생할 경우에는 KDGE 본사가 관계법령에 따라 그 배상을 책임집니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 6 (Suspension of Service)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” may temporarily suspend the provision of services in the event of maintenance, replacement or failure of information and communication facilities such as computers, or loss of communication.</li>
                  <li>“kDiaGem App” compensates for damages suffered by users or third parties due to the temporary suspension of service provision due to the reason in Paragraph 1.</li>
                  <li>However, this is not the case if “kDiaGem App” proves that there is no intention or negligence.</li>
                  <li>In the event that the service cannot be provided due to business type conversion, business abandonment, or integration between companies, it will be notified on the notice of “kDiaGem App” 7 days in advance.</li>
                  <li>Even if “kDiaGem App” stops service, the buyer's order will be delivered without a hitch.</li>
                  <li>If the buyer suffers damage due to service interruption, KDGE head office is responsible for the compensation according to the relevant laws and regulations.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제7조(회원가입)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>이용자는 "kDiaGem App"이 정한 가입 양식에 따라 회원 정보를 기재한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.</li>
                  <li>"kDiaGem App"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>가입 신청자가 이 약관 제8조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만, 회원자격 상실 후 3년이 경과한 후 "kDiaGem APP" 재가입 승인을 얻은 경우는 제외 (제8-3항에 따른 회원자격) 합니다.</li>
                      <li>등록 내용에 허위, 누락, 오기가 있는 경우</li>
                      <li>"kDiaGem APP"의 기술상 현저히 지장이 있다고 판단되는 경우</li>
                    </ol>
                  </li>
                  <li>회원가입 계약의 성립 시기는 회원에게 발송되는 "kDiaGem App"의 승인 일로 합니다.</li>
                  <li>회원은 등록 내용의 변경이 있는 경우 합당한 기간 이내에 "kDiaGem App"에 회원 정보를 수정하여 합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 7 (Member Registration)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Users apply for membership registration by expressing their intention to agree to these Terms and Conditions after entering member information according to the sign-up form set by "kDiaGem App".</li>
                  <li>"kDiaGem App" registers as a member unless it falls under each of the following among users who have applied for membership as in Paragraph 1.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>If the applicant for membership has previously lost membership in accordance with Article 8, Paragraph 3 of these Terms and Conditions, except for cases where approval for re-entry to the "kDiaGem APP" has been obtained after 3 years have elapsed since the membership was lost (Membership qualifications pursuant to Article 8, Paragraph 3).</li>
                      <li>If there is falsehood, omission, or typo in the registration information</li>
                      <li>If it is judged that there is a significant technical impediment to the "kDiaGem APP"</li>
                    </ol>
                  </li>
                  <li>The date of establishment of the membership contract is the date of approval of the "kDiaGem App" sent to the member.</li>
                  <li>If there is a change in the registration details, the member must modify the member information in the "kDiaGem App" within a reasonable period of time.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제8조 (회원 탈퇴 및 자격 상실 등)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>회원은 "kDiaGem App"에 언제든지 탈퇴를 요청할 수 있으며 "kDiaGem App"은 즉시 탈퇴를 처리합니다.</li>
                  <li>회원이 다음 각호에 해당하는 경우 "kDiaGem App"은 해당 이용자의 회원자격을 제한 및 정지시킬 수 있습니다.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>가입 신청 시 허위 내용을 등록한 경우</li>
                      <li>회원이 "kDiaGem App"을 이용하여 구입한 "다이아몬드, 보석, 주얼리" 및 기타 "kDiaGem App"의 이용에 관한 회원의 채무를 이행하지 않는 경우</li>
                      <li>다른 사람의 "kDiaGem App" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
                      <li>"kDiaGem App"의 약관에 반하는 행위를 하는 경우, "kDiaGem App"은 즉시 회원자격을 상실시킬 수 있습니다.</li>
                    </ol>
                  </li>
                  <li>"kDiaGem App"이 회원자격을 상실시키는 경우, 회원가입을 말소합니다. 이 경우 회원에게 즉시 통지하고, 고의가 아님이 명백히 입증되는 경우 "kDiaGem App"이 회원자격을 다시 부여합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 8 (Withdrawal from Membership and Loss of Qualification, etc.)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Members may request withdrawal from the "kDiaGem App" at any time, and the "kDiaGem App" will process the withdrawal immediately.</li>
                  <li>If a member falls under any of the following subparagraphs, "kDiaGem App" may restrict or suspend the member's membership.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>In the case of registering false information when applying for membership</li>
                      <li>If the member does not fulfill the member's obligations regarding the use of "diamonds, jewels, jewelry" and other "kDiaGem App" purchased using the "kDiaGem App"</li>
                      <li>Threatening e-commerce order, such as interfering with the use of "kDiaGem App" by others or stealing the information</li>
                      <li>If an act is against the terms and conditions of "kDiaGem App", "kDiaGem App" may immediately cancel membership.</li>
                    </ol>
                  </li>
                  <li>If "kDiaGem App" loses membership, membership registration is terminated. In this case, the member is notified immediately, and if it is clearly proven that it is not intentional, "kDiaGem App" grants membership again</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제9조 (회원에 대한 통지)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”이 회원에 대해 어떠한 통지를 하는 경우, 회원이 “kDiaGem App”에 미리 약정하여 지정한 회원가입 시 사용한 전자우편 주소로 통지 할 수 있습니다.</li>
                  <li>불특정 다수 회원에 대한 통지의 경우 1주일 이상 “kDiaGem App”의 공지사항(Notice)에 게시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별 통지를 합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 9 (Notification to Members)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>If “kDiaGem App” makes any notice to a member, it can be notified to the e-mail address used when signing up for membership designated by the member in advance with “kDiaGem App”.</li>
                  <li>In the case of notification to unspecified members, it can be substituted for individual notification by posting on the notice of “kDiaGem App” for more than one week. However, individual notices are given for matters that have a significant impact on the member's own transaction.</li>
                </ol>            

                <h2 class="text-md-h6 text-subtitle-2">제10조 (계약의 성립)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>"kDiaGem App"은 제12조와 같은 구매 신청이 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의가 없는 경우 미성년자 본인 또는 법정대리인이 계약을 해지할 수 있음을 고지합니다.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>신청 내용에 허위, 누락, 오기가 있는 경우</li>
                      <li>"kDiaGem App"에서 "다이아몬드, 보석, 주얼리" 판매 협력업체 등록 시 신청 내용이 허위, 기재 누락, 오 기재 또는 상호의 사업자등록번호, 계좌번호 등 구비서류가 미 제출된 경우</li>
                      <li>기타 구매 신청에 승낙하는 것이 "kDiaGem App" 기술에 현저히 지장이 있다고 판단되는 경우</li>
                    </ol>
                  </li>
                  <li>계약의 성립 시기는 "kDiaGem App"으로부터 승인 통지가 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.</li>
                  <li>"kDiaGem App"의 승인의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매 가능 여부, 구매 신청의 취소 및 정정에 관한 정보 등을 포함하여야 합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 10 (Establishment of Contract)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>"kDiaGem App" may not accept a purchase request as in Article 12 if it falls under any of the following subparagraphs. However, in the case of entering into a contract with a minor, the minor or his/her legal representative may terminate the contract without the consent of the legal representative.
                    <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                      <li>In case there is falsehood, omission, or typo in the contents of the application</li>
                      <li>When registering as a sales partner for "diamonds, gems, jewelry" in the "kDiaGem App", if the application details are false, omitted, misrepresented, or required documents such as business registration number and account number of the trade name are not submitted</li>
                      <li>If it is judged that accepting other purchase requests is significantly impeding the "kDiaGem App" technology</li>
                    </ol>
                  </li>
                  <li>The contract is considered to be concluded when the notification of approval from "kDiaGem App" reaches the user.</li>
                  <li>The expression of intention to approve the "kDiaGem App" must include information on the user's purchase request confirmation and sale availability, cancellation and correction of the purchase request, etc.</li>
                </ol>            
                <br>


                <br>
                <h2 class="text-md-h6 text-subtitle-2">제11조 (환매 보장)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>KDGE는 판매한 다이아몬드 또는 보석 및 주얼리에 대해 구매자가 판매를 원할 경우 다음 환매 기준에 따라 재매입을 보장합니다.
                    <v-col cols="11">
                      1) VIVID DIAMOND 및 보석 나석은 환매 당시 판매가의 80%에 재매입 (VAT 포함)<br>
                      2) 일반 DIAMOND 및 고가 보석이 세팅된 주얼리는 판매가의 70%에 재매입 (VAT 포함)<br>
                      3) 순금주얼리 : 순금 실 중량 × 국제시세<br>
                      4) 준보석 및 패션주얼리 : 감정평가 후 결정<br>                   
                    </v-col>
                  </li>
                  <li>환매하려는 상품이 구매자의 부주의로 인하여 크게 파손되었거나 KDGE가 판매한 상품이 아닌 경우에는 제11조 1항이 적용되지 않습니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 11 (Repurchase Guarantee)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>KDGE guarantees the repurchase of sold diamonds, gemstones or jewelry according to the following repurchase standards if the buyer wants to sell them.</li>
                    <v-col cols="11">
                      1) VIVID DIAMOND and polished gemstones are repurchased at 80% of the selling price at the time of redemption (VAT included)<br>
                      2) Jewelry set with regular diamonds and expensive gemstones can be repurchased at 70% of the selling price (VAT included)<br>
                      3) Pure gold jewelry: Actual weight of pure gold × International market price<br>
                      4) Semi-precious stones and fashion jewelry: Decided after appraisal<br>         
                    </v-col>
                  <li>If the product to be redeemed is significantly damaged due to the buyer's negligence or is not a product sold by KDGE, the repurchase standard does not apply.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제12조 (거래 방법)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>구매자가 특정 상품에 대해 구매요청을 하시면 본사 담당자가 먼저 이메일로 전화를 드린다는 안내를 합니다.</li>
                  <li>구매 문의한 상품은 KDGE가 철저하게 상품의 품질 등을 확인 한 후 요청하신 상품에 조금이라도 문제가 있을 경우에는 본사에서 책임지고 보증 할 수 있는 대체 상품을 추천 할 수 있습니다.</li>
                  <li>본사는 GIA감정서 중에서 엄격한 평가등급 세분화 감정 기준을 통과한 다이아몬드만을 VIVID DIAMOND Certificate를 발행하여 기존 GIA  Certificate를 함께 첨부하여 납품합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 12 (Transaction method)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>When a buyer makes a purchase request for a specific product, a representative from our headquarters will first contact them via email.</li>
                  <li>After KDGE has thoroughly checked the quality of the product you have inquired about purchasing, if there is any problem with the requested product, we may recommend a replacement product that our head office can take responsibility for and guarantee.</li>
                  <li>Our company issues a VIVID DIAMOND Certificate only for diamonds that have passed the strict evaluation grading standards among the GIA Certificates and delivers them with the existing GIA Certificate attached.</li>
                </ol>            
                <br>

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제13조 (납품 기간)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>납품 기간은 상품대금 전액 또는 계약금(10%)을 KDGE 통장으로 입금한 날로부터 7일~14일이 소요됩니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 13 (Delivery Period)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>The delivery period takes 7 to 14 days from the date the full product price or down payment (10%) is deposited into KDGE bank account.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제14조 (납품 방법)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>납품 방법은 주문한 소매상에서 찾으실 수 있고, 고객이 지정하는 장소로 KDGE 직원이 직접 납품해 드립니다.</li>
                  <li>또한 고객이 희망할 경우 본사를 방문하여 직접 확인 후 인수하실 수도 있습니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 14 (Delivery method)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>The delivery method can be found at the retailer where the order was placed, and KDGE staff will deliver directly to the location designated by the customer.</li>
                  <li>In addition, if the customer wishes, he or she can visit the head office and directly check and take over.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제15조 (구매 취소)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>구매자는 상품대금이나 10%의 계약금(구매 보증금)을 KDGE 본사의 통장으로 송금하기 전까지는 언제든지 구매를 취소할 수 있습니다.</li>
                  <li>주문하신 상품이 아니거나 상품에 하자가 있는 경우에는 아무 조건 없이 취소할 수 있으며 100% 환불 받을 수 있습니다.</li>
                  <li>그러나 상품 주문 후 천재지변이 아닌 단순 변심에 의해 구매 취소할 경우 계약금(구매보증금) 또는 상품 가격의 10%는 환불 받을 수 없습니다.</li>
                  <li>그 이유는 해외 수입에 따른 비용과 감정 수수료 및 부가세 등의 비용이 발생하기 때문입니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 15 (Cancellation of Purchase)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>The purchaser may cancel the purchase at any time until the product price or 10% down payment (purchase deposit) is remitted to the bank account of KDGE headquarters.</li>
                  <li>If the product is not what you ordered or if the product is defective, you can cancel without any conditions and we will give you a 100% refund.</li>
                  <li>However, if the purchase is canceled due to a simple change of mind, not a natural disaster after ordering the product, the down payment (purchase deposit) or 10% of the product price will not be refunded.</li>
                  <li>The reason is that there are costs related to overseas imports, appraisal fees, and VAT.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제16조 (결제 방법)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>불필요한 수수료를 없애고 최저가로 공급하기 위해 모든 상품의 대금 결제는 본사 통장 거래로만 가능합니다.</li>
                  <li>대금 결제
                    <v-col cols="11">
                    (1)주문 시 상품대금 100% 전액을 입금할 경우 0.5% DC 혜택을 드립니다.<br>
                    (2)10%의 계약금(구매보증금)을 지불하고 인수하면서 잔액 90%를 지불할 수 있습니다.<br>                  
                    </v-col>
                  </li>
                  <li>모든 상품은 정식 수입을 하기 때문에 부가세 10%가 포함되어 있으며, 현금영수증 또는 세금계산서를 발행합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 16 (Payment method)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>In order to eliminate unnecessary fees and supply at the lowest price, payment for all products can only be made through the head office's bank account transaction.</li>
                  <li>Payment
                    <v-col cols="11">
                    (1)If you deposit 100% of the product price when ordering, you will receive a 0.5% DC benefit.<br>
                    (2)You can pay 10% down payment (purchase deposit) and pay the remaining 90% upon takeover.<br>                  
                    </v-col>
                  </li>
                  <li>All products are formally imported, so 10% VAT is included, and cash receipts or tax invoices are issued.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제17조 (유의 사항)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>다이아몬드는 동일한 감정 기관의 감정서가 같은 등급인 경우에도 품질의 차이가 있어 품질에 따라 가격이 다를 수 있습니다.</li>
                  <li>감정 기관 마다 감정 기준이 다르기 때문에 감정서 상에서는 같은 등급이라도 같은 품질과 가치를 가지고 있는 것은 아닙니다.</li>
                  <li>같은 등급이라도 다이아몬드 감정서의 등급 표기상으로만 같을 뿐이고 세부적으로는 상중하로 나누어져 있어 그 가치가 다르기 때문입니다.</li>
                  <li>따라서 구매자는 단순 가격 비교로 다이아몬드를 구매하지 말고, 전문가의 의견을 듣고 구매를 결정하시기 바랍니다.</li>
                  <li>KDGE는 구매자를 위해 세부 등급 표기 VIVID DIAMOND Certificate를 공급하고 있습니다. (VIVID DIAMOND Certificate 설명을 참고 하시기 바랍니다.)</li>
                  <li>다이아몬드 또는 보석에 첨부되어 있는 이미지(사진)는 실물과 다를 수 있습니다.</li>
                  <li>따라서 반드시 첨부된 다이아몬드 감정서를 참고하여 KDGE에 문의하시기 바랍니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 17 (Notes)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Even if diamonds are of the same grade from the same appraisal agency, the price may differ depending on the quality because there is a difference in quality.</li>
                  <li>Because each appraisal agency has different appraisal standards, even the same grade on the appraisal certificates does not have the same quality and value.</li>
                  <li>Even if the grade is the same, it is only the same in terms of the grade of the diamond appraisal certificates, and in detail, it is divided into high, middle and low, so the value is different.</li>
                  <li>Therefore, buyers are advised to listen to experts' opinions before making purchase decisions based on simple price comparison.</li>
                  <li>KDGE is supplying VIVID DIAMOND Certificate with detailed grades for buyers. (Please refer to the VIVID DIAMOND Certificate description.)</li>
                  <li>Images (photos) attached to diamonds or gemstones may differ from the actual product.</li>
                  <li>Therefore, please refer to the attached diamond appraisal certificate and contact KDGE.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제18조 (개인정보 보호)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”은 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
                  <li>“kDiaGem App”은 회원 가입 시 구매계약 이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무 이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</li>
                  <li>“kDiaGem App”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</li>
                  <li>“kDiaGem App”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공하는 단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</li>
                  <li>“kDiaGem App”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집 목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                  <li>이용자는 언제든지 “kDiaGem App”이 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 본사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “kDiaGem App”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
                  <li>“kDiaGem App”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행 계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</li>
                  <li>“kDiaGem App” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다</li>
                  <li>“kDiaGem App”은 개인정보의 수집·이용·제공에 관한 동의를 미리 선택한 것으로 설정 해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집 항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등의 서비스 제공을 제한하거나 거절하지 않습니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 18 (Duties of the "kDiaGem APP")</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” collects the minimum personal information to the extent necessary to provide services when collecting personal information of users.</li>
                  <li>“kDiaGem App” does not collect information necessary for fulfillment of purchase contract in advance when signing up for membership. However, this is not the case when minimum specific personal information is collected in the case where identification is required prior to the purchase contract in order to fulfill obligations under the relevant laws and regulations.</li>
                  <li>When "kDiaGem App" collects and uses users' personal information, the user is notified of the purpose and consent is obtained.</li>
                  <li>"kDiaGem App" cannot use the collected personal information for any purpose other than the purpose, and when a new purpose of use occurs or when it is provided to a third party, the user is notified of the purpose and consent is given during the use/provision stage. receive. However, exceptions are made in cases where the relevant laws and regulations stipulate otherwise.</li>
                  <li>If “kDiaGem App” is required to obtain the consent from users pursuant to paragraphs 2 and 3, the identity of the person in charge of personal information management (affiliation, name and phone number, other contact information), purpose of collection and use of information, matters related to the provision of information to third parties (recipient, purpose of provision, contents of information to be provided), etc. specified in Article 22, Paragraph 2 of the 「Act on Promotion of Information and Communications Network Utilization and Information Protection, Etc.」 are specified or notified in advance, and the user may withdraw this consent at any time.</li>
                  <li>Users may request to view and correct errors on their personal information possessed by “kDiaGem App” at any time, and the head office is obliged to take necessary measures without delay. If a user requests correction of an error, "kDiaGem App" does not use the personal information until the error is corrected.</li>
                  <li>"kDiaGem App" should limit the person who handles the user's personal information to a minimum for the protection of personal information, and the user's personal information, including credit card and bank account, is lost, stolen, leaked, or provided to a third party without consent. , take full responsibility for damages to the user due to alteration, etc.</li>
                  <li>"kDiaGem App" or a third party who receives personal information from it destroys the personal information without delay when the purpose of collecting or receiving the personal information is achieved.</li>
                  <li>“kDiaGem App” does not set consent for the collection, use, and provision of personal information as pre-selected. In addition, the services restricted when the user refuses to consent to the collection, use, and provision of personal information are specified in detail, and the member registration, etc. We do not limit or refuse to provide services.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제19조 (“kDiaGem App”의 의무)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”은 법령과 이 약관이 금지하거나 상거래 관례에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는 데 최선을 다합니다.</li>
                  <li>“kDiaGem App”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용 정보 포함) 보호를 위한 보안 시스템을 갖추고 있습니다.</li>
                  <li>kDiaGem App”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.</li>
                  <li>“kDiaGem App”은 이용자가 원하지 않는 영리 목적의 광고성 전자우편을 발송하지 않습니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 19 (Obligations of “kDiaGem App”)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” does not engage in acts prohibited by the law and these terms and conditions or contrary to commercial customs, and does its best to provide goods and services continuously and reliably as stipulated in these terms and conditions.</li>
                  <li>"kDiaGem App" has a security system to protect users' personal information (including credit information) so that users can safely use the Internet service.</li>
                  <li>kDiaGem App” is responsible for compensating users for damages caused by unjust and unreasonable indications and advertisements prescribed in Article 3 of the 「Act on Fair Display and Advertisement」 for products or services.</li>
                  <li>“kDiaGem App” does not send commercial e-mails that users do not want.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제20조 (회원의 ID 및 비밀번호에 대한 의무)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>제18조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</li>
                  <li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.</li>
                  <li>회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 KDGE에 통보하고 KDGE의 안내가 있는 경우에는 그에 따라야 합니다.</li>
                </ol>      
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 20 (Obligations for member ID and password)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Except for the case of Article 18, members are responsible for managing their ID and password.</li>
                  <li>Members must not let a third party use their ID and password.</li>
                  <li>If a member recognizes that his/her ID and password are stolen or used by a third party, he or she must immediately notify the KDGE and follow the instructions of the KDGE.</li>
                </ol>            


                <br>
                <h2 class="text-md-h6 text-subtitle-2">제21조 (이용자의 의무)</h2>
                <p>이용자는 다음의 행위를 하여서는 안 됩니다.</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>신청 또는 변경 시 허위 내용의 등록</li>
                  <li>타인의 정보를 도용한 행위</li>
                  <li>“kDiaGem App”에 게시된 정보의 허위 변경</li>
                  <li>“kDiaGem App”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등을 송신 또는 게시</li>
                  <li>“kDiaGem App”과 기타 제3자의 저작권 등 지식재산권에 대한 침해</li>
                  <li>“kDiaGem App”과 기타 제3자의 명예를 훼손하거나 업무를 방해하는 행위</li>
                  <li>외설 또는 폭력적인 메시지, 화상, 음성, 기타 공중 질서에 반하는 정보를 “kDiaGem App”에 공개 또는 게시하는 행위</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 21 (Obligations of Users)</h2>
                <p>Users must not do any of the following:</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Registration of false information when applying or changing</li>
                  <li>Stealing other people's information</li>
                  <li>False change of information posted on “kDiaGem App”</li>
                  <li>Sending or posting information (computer program, etc.) other than the information specified by “kDiaGem App”</li>
                  <li>Infringement of intellectual property rights such as copyrights of “kDiaGem App” and other third parties</li>
                  <li>Defaming the reputation of “kDiaGem App” and other third parties or interfering with their work</li>
                  <li>Disclosing or posting obscene or violent messages, video, voice, or other information that is against public order on the “kDiaGem App”</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제22조 (저작권의 귀속 및 이용 제한)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”이 작성한 저작물에 대한 저작권 및 기타 지식재산권은 KDGE에 귀속합니다.</li>
                  <li>이용자는 “kDiaGem App”을 이용함으로써 얻은 정보 중에서 “kDiaGem App”에게 지식재산권이 귀속된 정보를 KDGE의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송, 기타 방법 등에 의하여 영리 목적으로 이용하거나 또는 제3자에게 이용하게 하여서는 안 됩니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 22 (Attribution of Copyright and Restrictions on Use)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Copyright and other intellectual property rights for the works created by “kDiaGem App” belong to KDGE.</li>
                  <li>Among the information obtained by using the “kDiaGem App”, the user may use the information for which intellectual property rights are attributed to the “kDiaGem App” for commercial purposes by copying, transmitting, publishing, distributing, broadcasting, or other methods without prior consent from KDGE. or allow a third party to use it.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제23조 (분쟁 해결)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>본 약관에 적시되지 않은 사항들은 “kDiaGem App” 이용 약관과 전자상거래 관련 법률에 의거합니다. 그러나 만약 이용자와 본사 간의 법적 분쟁이 발생할 경우에는 관할 법원을 서울중앙지방법원으로 정합니다. 또한 “kDiaGem App”과 이용자 간에 제기된 전자상거래 소송은 대한민국 법을 적용합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 23 (Dispute Resolution)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Matters not specified in these terms and conditions are governed by the “kDiaGem App” terms of use and e-commerce related laws. However, if a legal dispute arises between the user and the head office, the competent court is determined as the Seoul Central District Court. In addition, the laws of the Republic of Korea apply to e-commerce lawsuits filed between “kDiaGem App” and users.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제24조 (데이터베이스권 침해 금지)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>본 kDiaGem 웹사이트 및 앱에 게시된 사진, 링크, 동영상을 포함하는 모든 정보에 대해 수집, 가공, 복제를 할 수 없습니다. 이를 어길 경우 저작권법에 의거하여 처벌받게 되며, 그 행위자는 본사의 손해배상 청구를 전적으로 책임져야 함을 고지합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 24 (Prohibition of Infringement of Database Rights)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>All information including photos, links, and videos posted on this kDiaGem  website and app cannot be collected, processed, or reproduced. If you violate this, you will be punished according to the copyright law, and we inform you that the violator must be fully responsible for claims for damages from the head office.</li>
                </ol>            

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" md="5" sm="10" xs="10">             
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="agree1_dialog = false">취소(Cancellation)</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="check_agree1">동의(Agreement)</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>

    <!-- 개인정보처리방침 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="agree2_dialog" fullscreen>
        <v-card class="dialog_round pa-0 ma-0" style="overflow-x:hidden;">

          <v-row dense class="mt-3 mb-3" justify="center">
            <v-col cols="10" md="5" align="center">
              <span class="dialog_title">Privacy Policy<br>개인 정보처리 정책</span>
            </v-col>
          </v-row>          
          
          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-row align="center" justify="center">
              <v-col cols="12" md="8" sm="10" xs="10">            

                <h2 class="text-md-h6 text-subtitle-2">제1조 (정책)</h2>              
                <p class="text-md-body-1 text-caption">
                  (주)KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 운영하는 kDiaGem App은 고객의 개인정보를 소중하게 생각하고 정보통신망 이용 촉진 및 정보보호에 관한 법률을 준수합니다.<br>
                  (주)KDGE는 개인 정보처리 정책을 통하여 고객이 제공하는 개인정보의 이용목적과 이용 방법, 개인정보 보호를 위해 어떤 조치를 취하고 있는지를 알려드립니다.
                </p>
                <h2 class="text-md-h6 text-subtitle-2">Article 1 (Policy)</h2>
                <p class="text-md-body-1 text-caption">
                  kDiaGem App, operated by KDGE &lt;Korea DIAMOND GEM Exchange&gt;, values customers' personal information and complies with the Act on Promotion of Information and Communications Network Utilization and Information Protection.<br>
                  Through the personal information processing policy, KDGE Co., Ltd. informs you of the purpose and method of using personal information provided by customers, and what measures are being taken to protect personal information.
                </p>
                
                <br>
                <h2 class="text-md-h6 text-subtitle-2">제2조 (개인정보 보호)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”은 이용자의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
                  <li>“kDiaGem App”은 회원 가입 시 구매계약 이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무 이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.</li>
                  <li>“kDiaGem App”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.</li>
                  <li>“kDiaGem App”은 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공하는 단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.</li>
                  <li>“kDiaGem App”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집 목적 및 이용목적, 제3자에 대한 정보제공 관련 사항(제공받은 자, 제공 목적 및 제공할 정보의 내용) 등 「정보통신망 이용 촉진 및 정보보호 등에 관한 법률」 제22조 제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                  <li>이용자는 언제든지 “kDiaGem App”이 가지고 있는 자신의 개인 정보에 대해 열람 및 오류 정정을 요구할 수 있으며 본사는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “kDiaGem App”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
                  <li>“kDiaGem App”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행 계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.</li>
                  <li>“kDiaGem App” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집 목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다</li>
                  <li>“kDiaGem App”은 개인정보의 수집·이용·제공에 관한 동의를 미리 선택한 것으로 설정 해두지 않습니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 2 (Personal Information Protection)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” collects the minimum personal information to the extent necessary to provide services when collecting personal information of users.</li>
                  <li>“kDiaGem App” does not collect information necessary for fulfillment of purchase contract in advance when signing up for membership. However, this is not the case when minimum specific personal information is collected in the case where identification is required prior to the purchase contract in order to fulfill obligations under the relevant laws and regulations.</li>
                  <li>When "kDiaGem App" collects and uses users' personal information, the user is notified of the purpose and consent is obtained.</li>
                  <li>"kDiaGem App" cannot use the collected personal information for any purpose other than the purpose, and when a new purpose of use occurs or when it is provided to a third party, the user is notified of the purpose and consent is given during the use/provision stage. receive. However, exceptions are made in cases where the relevant laws and regulations stipulate otherwise.</li>
                  <li>If “kDiaGem App” is required to obtain consent from users pursuant to paragraphs 2 and 3, the identity of the person in charge of personal information management (affiliation, name and phone number, other contact information), purpose of collection and use of information, Matters related to the provision of information to third parties (recipient, purpose of provision, contents of information to be provided), etc. specified in Article 22, Paragraph 2 of the Act on Promotion of Information and Communications Network Utilization and Information Protection, etc., are specified or notified in advance The user may withdraw this consent at any time.</li>
                  <li>Users may request to view and correct errors on their personal information possessed by “kDiaGem App” at any time, and the head office is obliged to take necessary measures without delay. If a user requests correction of an error, "kDiaGem App" does not use the personal information until the error is corrected.</li>
                  <li>"kDiaGem App" should limit the person who handles the user's personal information to a minimum for the protection of personal information, and the user's personal information, including credit card and bank account, is lost, stolen, leaked, or provided to a third party without consent. , take full responsibility for damages to the user due to alteration, etc.</li>
                  <li>“kDiaGem App” or a third party who receives personal information from it destroys the personal information without delay when the purpose of collecting or receiving the personal information is achieved.</li>
                  <li>“kDiaGem App” does not set consent for the collection, use, and provision of personal information as pre-selected.</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제3조 (회원의 ID 및 비밀번호에 대한 의무)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>제2조의 경우를 제외한 ID와 비밀번호에 관한 관리 책임은 회원에게 있습니다.</li>
                  <li>회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다.</li>
                  <li>회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “kDiaGem App”에 통보하고 “kDiaGem App”의 안내가 있는 경우에는 그에 따라야 합니다.</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 3 (Obligations for member ID and password)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Except for the case of Article 2, members are responsible for managing their ID and password.</li>
                  <li>Members must not let a third party use their ID and password.</li>
                  <li>If a member recognizes that his/her ID and password are stolen or used by a third party, he or she must immediately notify the "kDiaGem App" and follow the instructions of the "kDiaGem App".</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제4조 (개인정보의 수집 및 이용목적)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”에 수집된 개인 정보를 다음과 같은 목적으로 사용합니다. 서비스 제공에 관한 계약의 이행 및 요금 정산 서비스 제공에 따른 내용, 재화의 구매 및 결제, 재화의 배송 또는 청구서 수신 주소 발송, 금융 거래 식별 및 금융 서비스.</li>
                  <li>회원 관리<br>
                  회원 서비스 이용에 따른 신원 확인 및 개인 식별, 불량 회원의 부정 이용 방지 및 무단 사용 방지, 제휴 의사 확인, 연령 확인, 14세 미만 아동의 개인정보 수집 시 법정대리인 동의 여부, 불만 처리, 처리의 통지.
                  </li>
                  <li>마케팅 및 광고 활용<br>
                  이벤트 광고 정보 전달, 접속 빈도 식별 또는 회원 서비스 사용에 대한 통계.
                  </li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 4 (Purpose of Collection and Use of Personal Information)</h2>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App” uses collected personal information for the following purposes.Performance of contracts related to service provision, provision of fee settlement, provision of service, purchase and payment of goods, delivery of goods or delivery of billing addresses, identification of financial transactions, and financial services.</li>
                  <li>Member management<br>
                  Identification and personal identification according to the use of member services, prevention of fraudulent use and unauthorized use of bad members, confirmation of intention to affiliate, age confirmation, consent of legal representative when collecting personal information of children under the age of 14, handling of complaints, notification of processing.
                  </li>
                  <li>Utilization of marketing and advertising<br>
                  Statistics on delivery of event advertising information, identification of access frequency or use of member services.
                  </li>
                </ol>            

                <br>
                <h2 class="text-md-h5 text-subtitle-1">제5조 (개인 정보의 보유 및 이용 기간)</h2>
                <p class="text-md-body-1 text-caption">
                  원칙적으로 개인정보 수집 및 이용 목적이 달성된 후, 정보는 지체 없이 파기됩니다. 그러나 다음과 같은 이유로 지정된 기간 동안 다음 정보가 보존됩니다.
                </p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>“kDiaGem App”의 내부 정책에 따른 정보 보유 사유입니다.</li>
                  <li>불량 회원의 부정 이용 재발 방지, 분쟁 해결, 수사기관의 요청에 협조, 관련 법률에 따라 정보를 보유해야 하는 경우입니다.</li>
                  <li>전자상거래 등에 관한 소비자보호법 등 관련법의 규정에 따라 보존이 필요한 경우, 회사는 회원 정보를 다음과 같이 일정한 기간 동안 보관하고 있습니다.</li>
                  <li>회원이 탈퇴한 경우에도 이용계약 종료일로부터 5년간 회원 정보를 보유하여 이를 방지할 수 있습니다.</li>
                  <li>계약 또는 청약 철회에 관한 기록
                    <ol type="A">
                      <li>보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                      <li>보존 기간: 5년</li>
                    </ol>
                  </li>
                  <li>대금 결제 및 재화 등의 공급에 관한 기록
                    <ol type="A">
                      <li>보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                      <li>보존 기간: 5년</li>
                    </ol>
                  </li>
                  <li>소비자 불만 또는 분쟁 처리에 관한 기록
                    <ol type="A">
                      <li>보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률</li>
                      <li>보존 기간: 3년</li>
                    </ol>
                  </li>
                  <li>로그인 기록
                    <ol type="A">
                      <li>보존 이유: 통신비밀 보호법</li>
                      <li>보존 기간: 3개월</li>
                    </ol>
                  </li>
                </ol>         
                <br>
                <h2 class="text-md-h5 text-subtitle-1">Article 5 (Retention and Use Period of Personal Information)</h2>
                <p class="text-md-body-1 text-caption">
                  In principle, after the purpose of collecting and using personal information is achieved, the information is destroyed without delay. However, the following information will be retained for a specified period of time for the following reasons:
                </p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>This is the reason for retaining information according to the internal policy of “kDiaGem App”</li>
                  <li>Preventing recurrence of fraudulent use by bad members, resolving disputes, and cooperating with requests from investigative agencies. When we are required to retain information under applicable law.</li>
                  <li>If preservation is necessary in accordance with the provisions of relevant laws, such as the Consumer Protection Act on Electronic Commerce, etc., the company retains member information for a certain period as follows.</li>
                  <li>Even if a member withdraws, it can be prevented by retaining member information for 5 years from the end of the use contract.</li>
                  <li>Records on withdrawal of contract or offer
                    <ol type="A">
                      <li>Reason for Retention: Act on Consumer Protection in Electronic Commerce, Etc.</li>
                      <li>Retention period: 5 years</li>
                    </ol>
                  </li>
                  <li>Records on payment and supply of goods, etc.
                    <ol type="A">
                      <li>Reason for Retention: Act on Consumer Protection in Electronic Commerce, Etc.</li>
                      <li>Retention period: 5 years</li>
                    </ol>
                  </li>
                  <li>Records on handling consumer complaints or disputes
                    <ol type="A">
                      <li>Reason for Retention: Act on Consumer Protection in Electronic Commerce, Etc.</li>
                      <li>Retention period: 3 years</li>
                    </ol>
                  </li>
                  <li>Login history
                    <ol type="A">
                      <li>Reason for Retention: Protection of Communications Secrets Act</li>
                      <li>Retention period: 3 months</li>
                    </ol>
                  </li>
                </ol>    


                <br>
                <h2 class="text-md-h6 text-subtitle-2">제6조 (파기 절차 및 방법)</h2>
                <p>“kDiaGem App”은 개인정보 수집 및 이용 목적을 달성한 후 지체 없이 개인정보를 파기하는 것을 원칙으로 합니다.<br>파기 절차와 방법은 다음과 같습니다.
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>파기 절차<br>
                  회원가입을 위해 회원이 입력한 정보는 목적을 달성한 후 내부 방침 및 기타 관련 법률(보유기간 및 사용기간)에 따른 정보 보호 사유에 따라 별도의 DB (종이의 경우, 별도 파일 캐비닛)로 이관됩니다. 또한, 해당 DB는 일정 기간 보관 후 파기됩니다.<br>
                  별도의 DB로 전송되는 개인 정보는 법률에 따라 요구되지 않는 한 보관 이외의 다른 목적으로 사용되지 않습니다.
                  </li>
                  <li>파기 방법<br>
                  전자 파일의 형태로 저장된 개인정보는 기록을 재현할 수 없는 기술적 방법으로 삭제됩니다.
                  </li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 6 (Destruction procedure and method)</h2>
                <p>In principle, “kDiaGem App” destroys personal information without delay after achieving the purpose of collecting and using personal information.<br>The destruction procedure and method are as follows.</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Destruction procedure<br>
                  The information entered by the member for membership registration is transferred to a separate DB (separate file cabinet in the case of paper) in accordance with internal policies and reasons for information protection according to other relevant laws (retention period and use period) after the purpose is achieved. Also, the DB is destroyed after being kept for a certain period of time.<br>Personal information transmitted to a separate DB is not used for any purpose other than storage unless required by law.
                  </li>
                  <li>Destruction method<br>
                  Personal information stored in the form of electronic files is deleted in a technical way that cannot reproduce the record.
                  </li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제7조 (개인정보의 제공)</h2>
                <p>“kDiaGem App”은 원칙적으로 이용자의 개인정보를 외부에 제공하지 않습니다. 단, 다음의 경우는 예외입니다.
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>이용자가 사전에 동의하는 경우</li>
                  <li>법과 규정에 따르거나, 수사 목적의 법률에 명시된 절차와 방법에 따라 수사기관의 요청이 있을 경우</li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 7 (Provision of personal information)</h2>
                <p>In principle, "kDiaGem App" does not provide users' personal information to the outside. However, the following cases are exceptions.</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>When the user agrees in advance</li>
                  <li>In accordance with the provisions of the law and regulations, or in accordance with the procedures and methods specified in the law for the purpose of investigation, if there is a request from the investigative agency</li>
                </ol>            

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제8조 (개인정보 자동수집, 운영 및 거부에 관한 사항)</h2>
                <p>㈜KDGE는 수시로 이용자의 정보를 저장하고 찾아내는 "쿠키"를 운영합니다. 쿠키는 웹 사이트를 운영하는 데 사용되는 서버가 브라우저로 보내는 매우 작은 텍스트 파일로 컴퓨터의 하드 디스크에 저장됩니다. 회사는 다음과 같은 목적으로 쿠키를 사용합니다</p>
                <p>쿠키 등의 사용 목적</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>회원과 비회원의 접속 빈도 및 방문 시간 등을 분석하여 이용자의 취향과 관심 분야 및 자취를 파악하고 각종 이벤트 참여도 및 방문 횟수 등을 파악하여 타깃 마케팅 및 개인화 서비스를 제공합니다.</li>
                  <li>귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.</li>
                  <li>쿠키 설정을 거부하는 방법
                    <ol type="A">
                      <li>쿠키 설정을 거부하는 방법으로는 웹브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나, 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.</li>
                      <li>설정 방법 예시(Internet Explorer의 경우): 웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보</li>
                      <li>다만, 쿠키 설치를 거부할 경우 서비스 이용이 불가능할 수 있습니다.</li>
                    </ol>
                  </li>
                </ol>            
                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 8 (Matters related to automatic collection, operation and rejection of personal information)</h2>
                <p>KDGE Co., Ltd. operates "cookies" that store and find your information from time to time. Cookies are very small text files sent to your browser by the server used to run the website and stored on your computer's hard disk. The company uses cookies for the following purposes.</p>
                <p>Purpose of use of cookies, etc.</p>
                <ol class="text-md-body-1 text-caption md-pl-2 pl-3">
                  <li>Analyze the access frequency and visit time of members and non-members to identify users' tastes, areas of interest, and traces, and provide targeted marketing and personalized services by identifying the participation rate and number of visits to various events.</li>
                  <li>You have a choice about installing cookies. Therefore, you can allow all cookies by setting options in your web browser, go through confirmation whenever a cookie is saved, or refuse to save all cookies.</li>
                  <li>How to refuse cookie setting
                    <ol type="A">
                      <li>As a method of rejecting cookie settings, you can accept all cookies by selecting an option in your web browser, go through confirmation every time a cookie is saved, or refuse to save all cookies.</li>
                      <li>Example of setting method (for Internet Explorer): Tools at the top of the web browser > Internet Options > Privacy.</li>
                      <li>However, if you refuse to install cookies, you may not be able to use the service.</li>
                    </ol>
                  </li>
                </ol>         

                <br>
                <h2 class="text-md-h6 text-subtitle-2">제9조 (문서관리)</h2>
                <p>
                  개인정보에 관한 사항<br>
                  “kDiaGem App”은 고객님의 개인정보를 보호하고 개인 정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.<br><br>
                  개인정보 보호 책임자 성명: 노승희<br>
                  소속: ㈜KDGE<br>
                  전화번호: +82-2-765-0701<br>
                  이메일: kdge1000@gmail.com<br><br>
                  
                  이용자는 “kDiaGem App”의 서비스를 이용하시면서 발생하는 모든 개인정보 보호 관련 민원을 개인정보 보호 책임자 또는 담당 부서로 신고하실 수 있습니다.<br>
                  “kDiaGem App”은 이용자의 신고사항에 대해 신속하고 충분한 답변을 드릴 것입니다.<br>
                  신고 또는 직원 침해 기타 정보에 대해 문의해야 하는 경우, ㈜KDGE 개인정보 보호 책임자 또는 아래의 기관에 문의하시기 바랍니다.<br><br>

                  개인 정보 침해 신고 센터 (지역 번호 없이 118 / privacy.kisa.or.kr)<br>
                  대검찰청 사이버 범죄 수사팀 (02-3480-2000 / www.spo.go.kr)<br>
                  경찰청 사이버안전지킴이 (지역 번호 없이 182 / www.police.go.kr/www/security/cyber.jsp)<br>
                </p>

                <br>
                <h2 class="text-md-h6 text-subtitle-2">Article 9 (Document Management)</h2>
                <p>
                  Matters concerning personal information<br>
                  “kDiaGem App” appoints the relevant department and personal information manager as follows to protect customers' personal information and handle complaints related to personal information.<br><br>
                  Personal Information Protection Officer Name: Noh Seung-hee<br>
                  Affiliation: KDGE Co., Ltd.<br>
                  Phone number: +82-2-765-0701<br>
                  Email: kdge1000@gmail.com<br><br>
                  Users can report all personal information protection-related complaints that occur while using the service of “kDiaGem App” to the person in charge of personal information protection or the department in charge.
                  "kDiaGem App" will provide a prompt and sufficient response to the user's report.<br>
                  If you need to inquire about a report or employee infringement or other information;<br>
                  Please contact the person in charge of personal information protection at KDGE Co., Ltd. or the organizations below.<br><br>

                  Privacy Infringement Report Center (118 without area code / privacy.kisa.or.kr)<br>
                  Supreme Prosecutor’s Office Cyber Crime Investigation Team (02-3480-2000 / www.spo.go.kr)<br>
                  National Police Agency Cyber Safety Guard (182 without area code / www.police.go.kr/www/security/cyber.jsp)<br>
                </p>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row align="center" justify="center">
              <v-col cols="12" md="5" sm="10" xs="10">             
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="agree2_dialog = false">취소(Cancellation)</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="check_agree2">동의(Agreement)</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>    


    <!-- 링크 입력 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="link_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Link&nbsp;링크</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Link&nbsp;<span class="dialog_subtitle_kor">링크</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Link"
                      single-line v-model="LINKADDR" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>    

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="link_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_link">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

<v-row align="center" justify="center">
  <v-col class="mt-5" cols="12" md="5" sm="10" xs="10">

    <v-row>
      <v-col cols="12" align="center" class="text-md-h4 text-h6">
        Join 회원가입
      </v-col>
    </v-row>

    <div class="black_line mt-2"/>

    <v-row class="mt-2" align="center">
      <v-col cols="1">
        <input type="checkbox" style="accent-color: #000000" :checked="agree1" @change="clickagree1">
      </v-col>
      <v-col cols="7">
        <span>Terms and Conditions of Service(Required)<br>서비스 이용약관 동의(필수)</span>
      </v-col>
      <v-col cols="4" align="center" class="text-body-2">
        <a @click="agree1_dialog = true">보기</a>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="1">
        <input type="checkbox" style="accent-color: #000000" :checked="agree2" @change="clickagree2">
      </v-col>
      <v-col cols="7">
        <span>Privacy Policy(Required)<br>개인 정보처리 정책 동의(필수)</span>
      </v-col>
      <v-col cols="4" align="center" class="text-body-2">
        <a @click="agree2_dialog = true">보기</a>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="left" class="text-md-h6 text-subtitle-1">
        How to join 가입방법
      </v-col>
    </v-row>
    <div class="black_line"/>
    <v-row dense class="mt-1">
      <v-col cols="12" class="text-body-2">
        <p>1. Enter your name, contact number, email account and password, and click Join to send an authentication email to the email account you entered.
        이름, 연락처, 이메일 계정과 비밀번호를 입력하시고 가입하기를 누르시면 입력하신 이메일 계정으로 인증메일을 보내드립니다.</p>
        <p>2. If you click the link attached to the authentication mail, you can use your account after signing up.
          인증메일에 첨부된 링크를 누르시면 가입이 완료되어 계정사용이 가능합니다.</p>        
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5" dense>
      <v-col cols="12" md="10" sm="12" xs="12" class="text-body-2">
        Are you a consumer?(당신은 소비자인가요?)
      </v-col>
    </v-row>

    <v-row justify="center" dense class="text-body-2">
      <v-col cols="12" md="10" sm="12" xs="12">

        <v-list>
          <v-list-item-group v-model="select_model" >
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>consumer</v-list-item-title>
                <v-list-item-title>소비자</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Retailer</v-list-item-title>
                <v-list-item-title>소매업자</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Supplier/Wholesaler</v-list-item-title>
                <v-list-item-title>공급자/도매업자</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- 
            <v-btn color="white" height="50" elevation="0" href="/files/SUPPLIER_AND_IMPORTER_AGREEMENT.docx" download>
              <v-icon class="mr-7" color="black">mdi-download</v-icon>Download Agreement<br>공급자 약정서다운
            </v-btn>
            -->

          </v-list-item-group>
        </v-list>          
      </v-col>
    </v-row>


    <template v-if="select_model == 0">
      <!-- 소비자 -->
      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Name&nbsp;<span style="color:#D9D9D9" class="text-caption">이름</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense >
        <v-col cols="12" md="10" sm="12" xs="12" class="ma-0 pa-0">
          <v-text-field 
            v-model="company_name" name="company_name"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Tel&nbsp;<span style="color:#D9D9D9" class="text-caption">연락처</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="tel" name="tel" maxlength="100"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>        
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Mobile&nbsp;<span style="color:#D9D9D9" class="text-caption">휴대폰번호</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="mobilenumber" name="mobilenumber" maxlength="100"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>        
        </v-col>
      </v-row>

    </template>
    <template v-else>
      <!-- 공급자, 도소매 -->
      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Company Name&nbsp;<span style="color:#D9D9D9" class="text-caption">업체명</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense >
        <v-col cols="12" md="10" sm="12" xs="12" class="ma-0 pa-0">
          <v-text-field 
            v-model="company_name" name="company_name"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Tel&nbsp;<span style="color:#D9D9D9" class="text-caption">연락처</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="tel" name="tel" maxlength="100"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>        
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          For business operators, please enter the information below Approval is required through screening.
          (사업자의 경우 아래 정보를 입력해주세요. 심사 후 승인이 필요합니다.)
        </v-col>
      </v-row>
      
      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Registrant Name&nbsp;<span style="color:#D9D9D9" class="text-caption">신청자 이름</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense >
        <v-col cols="12" md="10" sm="12" xs="12" class="ma-0 pa-0">
          <v-text-field 
            v-model="registrant_name" name="registrant_name"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Registrant Position&nbsp;<span style="color:#D9D9D9" class="text-caption">신청자 직책</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense >
        <v-col cols="12" md="10" sm="12" xs="12" class="ma-0 pa-0">
          <v-text-field 
            v-model="registrant_position" name="registrant_position"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Registrant Mobile&nbsp;<span style="color:#D9D9D9" class="text-caption">신청자 휴대폰번호</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="mobilenumber" name="mobilenumber" maxlength="100"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>        
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Owner Name&nbsp;<span style="color:#D9D9D9" class="text-caption">대표자 이름</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense >
        <v-col cols="12" md="10" sm="12" xs="12" class="ma-0 pa-0">
          <v-text-field 
            v-model="owner_name" name="owner_name"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Owner Mobile&nbsp;<span style="color:#D9D9D9" class="text-caption">대표자 휴대폰번호</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="owner_mobile" name="owner_mobile" maxlength="100"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>        
        </v-col>
      </v-row>


      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Business registration number&nbsp;<span style="color:#D9D9D9" class="text-caption">사업자번호</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="business_number" name="business_number" maxlength="50"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3" dense >
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Business item&nbsp;<span style="color:#D9D9D9" class="text-caption">업태/주요종목</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-text-field 
            v-model="business_item" name="business_item" maxlength="50"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3" dense >
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Address&nbsp;<span style="color:#D9D9D9" class="text-caption">주소</span></label>
        </v-col>
      </v-row>
      <v-row justify="center" dense class="mt-0">
        <v-col cols="12" md="10" sm="12" xs="12">
          <v-textarea 
            v-model="address" name="address" maxlength="200" height="70"
            class="input_design" outlined  type="text" color="black"
            dense single-line hide-details></v-textarea>        
        </v-col>
        <v-col cols="6" md="4">
          <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Business License&nbsp;<span style="color:#D9D9D9" class="text-caption">사업자등록증</span></label>
        </v-col>
        <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
          <div class="black_line"/>    
        </v-col>
        <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
          <v-col align="center" align-self="center" >

          <template v-if="previewImg1.length > 0">
            <v-row dense  justify="center">
              
              <template v-if="previewImg1 == pdfPreviewImg1 || previewImg1.split('.').pop().toLowerCase() == 'pdf'">
                <div style="position:relative">
                  <div style="position:absolute;bottom:5px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                    <v-icon style="width:40px;height:40px;color:white" @click="showImage_pdf(0)">mdi-magnify-expand</v-icon>
                    <v-icon style="width:40px;height:40px;color:white" @click="fileSelect_pdf(0)">mdi-pencil</v-icon>
                    <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                  </div>
                  <img  style="hegiht:auto" width="120" :src="pdfPreviewImg1" class="image">
                </div>
              </template>
              <template v-else>
                <div style="position:relative">
                  <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                    <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                    <v-icon style="width:40px;height:40px;color:white" @click="fileSelect_pdf(0)">mdi-pencil</v-icon>
                    <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                  </div>
                  <img  width="240" style="hegiht:auto" :src="previewImg1" class="image">
                </div>
              </template>

            </v-row>
            </template>
            <template v-else>
              <v-row dense class="image-border" align="center" justify="center">
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect_pdf(0)">mdi-image</v-icon>
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="linkadd(0)">mdi-link</v-icon>
              </v-row>
            </template>


          </v-col>
        </v-col>
      </v-row>
          


      <v-row justify="center" class="mt-3" dense>
        <v-col cols="12" md="10" sm="12" xs="12">
          <label class="text-body-2">Business Card&nbsp;<span style="color:#D9D9D9" class="text-caption">명함</span></label>
        </v-col>
        <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
          <div class="black_line"/>    
        </v-col>
        <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
          <v-col align="center" align-self="center" >

            <template v-if="previewImg2.length > 0">
              <v-row dense  justify="center">

                <template v-if="previewImg2 == pdfPreviewImg2 || previewImg2.split('.').pop().toLowerCase() == 'pdf'">
                  <div style="position:relative">
                    <div style="position:absolute;bottom:5px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                      <v-icon style="width:40px;height:40px;color:white" @click="showImage_pdf(1)">mdi-magnify-expand</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="fileSelect_pdf(1)">mdi-pencil</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                    </div>
                    <img  style="hegiht:auto" width="120" :src="pdfPreviewImg2" class="image">
                  </div>
                </template>
                <template v-else>
                  <div style="position:relative">
                    <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                      <v-icon style="width:80px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                      <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(1)">mdi-pencil</v-icon>
                      <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                    </div>
                    <img  width="240" style="hegiht:auto" :src="previewImg2" class="image">
                  </div>
                </template>
              </v-row>
            </template>
            <template v-else>
              <v-row dense class="image-border" align="center" justify="center">
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect_pdf(1)">mdi-image</v-icon>
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="linkadd(1)">mdi-link</v-icon>
              </v-row>
            </template>

          </v-col>
        </v-col>
      </v-row>

    </template>

    <!-- 공통 -->
    <v-row justify="center" class="mt-3" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <label class="text-body-2">Email Account&nbsp;<span style="color:#D9D9D9" class="text-caption">이메일 계정</span></label>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <v-text-field 
          v-model="user_id" name="user_id" autocapitalize="off"
          class="input_design" outlined  type="text" color="black"
          dense single-line hide-details></v-text-field>        
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-3" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <label class="text-body-2">Password&nbsp;<span style="color:#D9D9D9" class="text-caption">비밀번호</span></label>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <v-text-field 
          v-model="user_pw" name="password" type="password" id="password"
          class="input_design" outlined color="black" autocapitalize="off"
          dense single-line hide-details></v-text-field>        
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-3" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <label class="text-body-2">Confirm Password&nbsp;<span style="color:#D9D9D9" class="text-caption">비밀번호 확인</span></label>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="10" sm="12" xs="12">
        <v-text-field 
          v-model="user_pw2" name="password2" type="password" id="password2"
          class="input_design" outlined color="black"
          dense single-line hide-details></v-text-field>        
      </v-col>
    </v-row>
    
    <v-row justify="center" dense class="mt-10 mb-5">
      <v-col cols="12" align="center">
        <v-btn class="black_btn" @click="join">Join 가입하기</v-btn>
      </v-col>
    </v-row>

    </v-col>
  </v-row>

  </v-container>
</template>

<script src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"></script>
<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
export default {
  components:{
      VueCropper
  },
  data: () => ({


      //PDF첨부시 보여줄 공통 이미지 - 사업자등록증
      pdfPreviewImg1:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmimg.jpg",

      //PDF첨부시 보여줄 공통 이미지 - 명함
      pdfPreviewImg2:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmcard.jpg",

      link_dialog: false,
      link_index:0,
      LINKADDR:"",

      PRODUCT_IDX: -1,

      previewImg1:"",
      previewImg2:"",
      
      upload_path1:"",
      upload_path2:"",

      agree1_dialog:false,
      agree2_dialog:false,
      agree1:false,
      agree2:false,

      dialog:true,
      loading:false,
      select_type:1,
      select_login:false,
      e6: 1,

      user_id : ""
    , user_pw : ""
    , user_pw2 : ""
    
    , company_name : ""
    , business_number : ""  //사업자번호
    , business_item : ""    //업태/종목
    , address: ""
    , select_model: 0
    , tel : ""

    , registrant_name : ""
    , registrant_position : ""
    , mobilenumber : ""
    , owner_name : ""
    , owner_mobile : ""
    
    , rules: {
        mobile: value => !value || !/[^0-9-]/.test(value) || '숫자와 -외에는 입력할 수 없습니다.',
        eng: value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || '한글을 사용할 수 없습니다.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        notemail: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !pattern.test(value) || '이메일주소는 사용할 수 없습니다.'
        },
    }
    
    , address_dialog:false

    , crop_dialog:false
    , photo_index:0
    , imgSrc:''
    , uploadFile1 : []
    , uploadFile2 : []

    , options: {
        focus:false,
      } ,

    //신규 크롭 파라메터
    option: {
      img: "",
      size: 1,
      full: true,
      outputType: "jpg",
      canMove: true,
      fixed: false,         //크롭박스 비율 고정
      fixedNumber: [1,1],   //크롭박스 비율
      fixedBox: false,      //true면 크롭박스 크기 조절 안됨
      original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
      canMoveBox: true,
      autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
      autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
      autoCropHeight:100,   //크롭박스의 세로 크기 
      centerBox: true,
      high: false,
      cropData: {},
      enlarge: 1,
      mode: 'contain',
      maxImgSize: 1000,
      limitMinSize: [100, 100]
    },              

  }),

  mounted(){  
    this.PRODUCT_IDX = this.$route.query.code;
    this.select_login = false;
  },

  beforeDestroy(){
    //아이폰의 경우 찌거기 제거
    let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
    if(indexcnt > -1){
      let elements = document.getElementsByName("imageinputcamera");
      for(var i=0, len=elements.length ; i != len; ++i){
          elements[0].parentNode.removeChild(elements[0]);
      }
    }
  },

  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave(to, from, next){
    if(this.$refs.pdfinfo.pdf_dialog){
      this.$refs.pdfinfo.close();
      next(false);
    }else{
      next();
    }
  },


  methods: {


      linkadd(index){
        this.LINKADDR = "";
        this.link_index = index;
        this.link_dialog = true;
      },

      //링크 저장
      save_link(){
        const urlVal = /https?:\/\//;
        const checklink = cmvalidation.checkValReg(this.LINKADDR, urlVal, -1, 255, false);
        if(checklink == 1){
          this.$fire({
            html: "Please enter the Link URL.<br><br>Link URL을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 2){
          this.$fire({
            html: "Link URL must start with http or https.<br><br>Link URL은 http 또는 https로 시작해야 합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 4){
          this.$fire({
            html: "Link URL the maximum input limit.<br><br>Link URL이 최대 입력 제한을 초과합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }              
        
        if(this.link_index == 0){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
            //pdf면 분기처리
            this.previewImg1                  = this.pdfPreviewImg1;
          }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg1              = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .png, .pdf<br><br>URL 주소의 끝은 .jpg, .png, .pdf이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
          this.BUSINESS_IMG   = this.LINKADDR;
          
        }else if(this.link_index == 1){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
            //pdf면 분기처리
            this.previewImg2                  = this.pdfPreviewImg2;
          }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg2              = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .png, .pdf<br><br>URL 주소의 끝은 .jpg, .png, .pdf이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
          this.CARD_IMG   = this.LINKADDR;
        }
        this.link_dialog = false;
      },


      showImage_pdf(index){
        //console.log("showImage_pdf : " + index);
        if(index == 0){
          
          if(this.isNullCheck(this.uploadFile1)){
            //console.log("showImage_pdf url");
            this.$refs.pdfinfo.geturl(this.BUSINESS_IMG);
          }else{
            //console.log("showImage_pdf file");
            //파일로 보여줘야함.
            this.$refs.pdfinfo.getfile(this.uploadFile1);
          }
        }else{
          if(this.isNullCheck(this.uploadFile2)){
            this.$refs.pdfinfo.geturl(this.CARD_IMG);
          }else{
            //파일로 보여줘야함.
            this.$refs.pdfinfo.getfile(this.uploadFile2);
          }
        }
      },     


      setPdfPreview(index){
        if(index == 0){
          this.previewImg1 = this.pdfPreviewImg1;
        }else{
          this.previewImg2 = this.pdfPreviewImg2;
        }
      },

      fileSelect_pdf(index){

        const changefunction    = this.changefile;
        const changepdffunction = this.changefile_pdf;
        const updatefunction    = this.setPdfPreview;

        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png,.pdf"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction(index);
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }else{

          input.onchange = function (event) {

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction(index);
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }
        input.click();
      },      


      //이미지 선택시
      changefile_pdf(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //PDF는 크롭없이 바로 첨부
        const reader = new FileReader();
        reader.onload = (event2)=>{
          //console.log("index : " + pindex);
          if(pindex == 0){
            this.uploadFile1 = this.dataURItoBlob(event2.target.result);
          }else if(pindex == 1){
            this.uploadFile2 = this.dataURItoBlob(event2.target.result);
          }
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

      },      
      
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },



      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }
      },

      fileSelect(index){

        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();

      },    


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      
    
    clickagree1(){
      this.agree1 = !this.agree1;
    },
    clickagree2(){
      this.agree2 = !this.agree2;
    },

    check_agree1(){
      this.agree1 = true;
      this.agree1_dialog = false;
    },

    check_agree2(){
      this.agree2 = true;
      this.agree2_dialog = false;
    },

    isNullCheck(value){
      if(value == null || value == undefined || value.length < 1){
        return true;
      }else{
        return false;
      }
    },

    async join(){

      if(!this.agree1){
        this.$fire({
          html: "Please accept the Terms and Conditions.<br><br>이용 약관에 동의해주세요",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
        return;
      }

      if(!this.agree2){
        this.$fire({
          html: "Please agree to the privacy policy.<br><br>개인정보 처리 방침에 동의해주세요",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
        return;
      }


        this.loading = true;

        if(this.select_model > 0){

          //공급자, 도소매업자
          const checkaddr = cmvalidation.checkValReg(this.address, null, 10, 100);
          if(checkaddr == 1){
              this.loading = false;
              this.$fire({
                html: "Please enter your address<br><br>주소를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkaddr == 3){
            this.loading = false;
            this.$fire({
              html: "Please enter address in 10 characters or then<br><br>주소는 10자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkaddr == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter address in 100 characters or less<br><br>주소는 100자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          


          if(this.isNullCheck(this.uploadFile1)){
              if(this.isNullCheck(this.upload_path1)){
                this.loading = false;
                this.$fire({
                  html: "Register Business license image<br><br>사업자등록증 이미지를 등록하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          }
          if(this.isNullCheck(this.uploadFile2)){
              if(this.isNullCheck(this.upload_path2)){
                this.loading = false;
                this.$fire({
                  html: "Register Business card image<br><br>명함 이미지를 등록하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          }

          //사업자등록증 사진
          if(!this.isNullCheck(this.uploadFile1)){
            var uploadpath = Date.now().toString()+".jpg";
            if(this.previewImg1 == this.pdfPreviewImg1 || this.previewImg1.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath = Date.now().toString()+".pdf";
            }
            let upload_cb1 = await this.uploadStorage("company", uploadpath, this.uploadFile1);
            if(upload_cb1 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business license image upload error<br><br>사업자등록증 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile1  = [];
            this.upload_path1 = upload_cb1.resultRef;
          }          


          //명함 사진
          if(!this.isNullCheck(this.uploadFile2)){

            var uploadpath2 = Date.now().toString()+".jpg";
            if(this.previewImg2 == this.pdfPreviewImg2 || this.previewImg2.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath2 = Date.now().toString()+".pdf";
            }

            //파일이 수정된 경우
            let upload_cb2 = await this.uploadStorage("company", uploadpath2, this.uploadFile2);
            if(upload_cb2 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business card image upload error<br><br>명함 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile2  = [];
            this.upload_path2 = upload_cb2.resultRef;

          }
          


          const checkname = cmvalidation.checkValReg(this.company_name, null, -1, 50);
          if(checkname == 1){
            this.loading = false;
            this.$fire({
              html: "Please enter your Company name<br><br>업체명을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkname == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter no more than 50 characters for your Company name<br><br>업체명은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checknumber = cmvalidation.checkValReg(this.business_number, null, -1, 50);
          if(checknumber == 1){
            this.loading = false;
            this.$fire({
              html: "Enter business number (up to 50 characters)<br><br>사업자번호를 입력하세요(최대 50자)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checknumber == 4){
            this.loading = false;
            this.$fire({
              html: "Please Enter the business number in 50 characters or less<br><br>사업자번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


          const checkitem = cmvalidation.checkValReg(this.business_item, null, -1, 50);
          if(checkitem == 1){
            this.loading = false;
            this.$fire({
              html: "Enter business status/item<br><br>업태/종목을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkitem == 4){
            this.loading = false;
            this.$fire({
              html: "Please Enter business type/item in 50 characters or less<br><br>업태/종목은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checktel = cmvalidation.checkValReg(this.tel, /[^0-9-]/g, -1, 50);
          if(checktel == 2){
            this.loading = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and -<br><br>전화번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>전화번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          

          const checkmobile = cmvalidation.checkValReg(this.mobilenumber, /[^0-9-]/g, -1, 50);
          if(checkmobile == 1){
            this.loading = false;
            this.$fire({
              html: "Please enter registrant mobile phone number<br><br>신청자 휴대폰번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 2){
            this.loading = false;
            this.$fire({
              html: "registrant Mobile Phone numbers can only be entered in numbers and -<br><br>신청자 휴대폰번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter the registrant mobile phone number in 50 characters or less<br><br>신청자 휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }                 

          const checkregname = cmvalidation.checkValReg(this.registrant_name, null, -1, 50);
          if(checkregname == 1){
            this.loading = false;
            this.$fire({
              html: "Enter registrant name<br><br>등록자 이름을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkregname == 4){
            this.loading = false;
            this.$fire({
              html: "Please Enter registrant name in 50 characters or less<br><br>등록자 이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkregpos = cmvalidation.checkValReg(this.registrant_position, null, -1, 50);
          if(checkregpos == 1){
            this.loading = false;
            this.$fire({
              html: "Enter registrant position/item<br><br>등록자 직책을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkregpos == 4){
            this.loading = false;
            this.$fire({
              html: "Please Enter registrant position in 50 characters or less<br><br>등록자 직책은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checkownername = cmvalidation.checkValReg(this.owner_name, null, -1, 50);
          if(checkownername == 1){
            this.loading = false;
            this.$fire({
              html: "Enter Owner Name<br><br>대표자 이름을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkownername == 4){
            this.loading = false;
            this.$fire({
              html: "Please Enter Owner Name in 50 characters or less<br><br>대표자 이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checkownermobile = cmvalidation.checkValReg(this.owner_mobile, /[^0-9-]/g, -1, 50);
          if(checkownermobile == 1){
            this.loading = false;
            this.$fire({
              html: "Please enter owner mobile phone number<br><br>대표자 휴대폰번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkownermobile == 2){
            this.loading = false;
            this.$fire({
              html: "owner mobile phone numbers can only be entered in numbers and -<br><br>대표자 휴대폰번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkownermobile == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter the owner mobile phone number in 50 characters or less<br><br>대표자 휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }                 

        }else{

          //소비자는 해당 이미지 필요 없음
          this.upload_path1    = "";
          this.upload_path2    = "";

          const checkname = cmvalidation.checkValReg(this.company_name, null, -1, 50);
          if(checkname == 1){
            this.loading = false;
            this.$fire({
              html: "Please enter your name<br><br>이름을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkname == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter no more than 50 characters for your name<br><br>이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


          const checktel = cmvalidation.checkValReg(this.tel, /[^0-9-]/g, -1, 50);
          if(checktel == 2){
            this.loading = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and -<br><br>전화번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>전화번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }                    

          const checkmobile = cmvalidation.checkValReg(this.mobilenumber, /[^0-9-]/g, -1, 50);
          if(checkmobile == 1){
            this.loading = false;
            this.$fire({
              html: "Please enter your mobile phone number<br><br>휴대폰번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 2){
            this.loading = false;
            this.$fire({
              html: "Mobile Phone numbers can only be entered in numbers and -<br><br>휴대폰번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 4){
            this.loading = false;
            this.$fire({
              html: "Please enter the mobile phone number in 50 characters or less<br><br>휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }                 
          
        }
        
        //공통
        var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if(this.user_id  == undefined || this.user_id.length < 1){
          this.loading = false;
          this.$fire({
            html: "Please enter an email account<br><br>이메일을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(this.user_id.length > 50){
          this.loading = false;
          this.$fire({
            html: "Please enter an email account with no more than 50 characters<br><br>이메일 계정은 50자 이내로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(!emailVal.test(this.user_id)){
          this.loading = false;
          this.$fire({
            html: "The email you entered is not an email address form.<br><br>입력하신 이메일은 이메일 주소 양식이 아닙니다",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 

        if(this.user_pw.length < 1){
          this.loading = false;
            this.$fire({
              html: "Please enter your password.<br><br>비밀번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }
        if(this.user_pw.length < 6){
          this.loading = false;
          this.$fire({
            html: "Password must be at least 6 characters long<br><br>비밀번호는 최소 6글자 이상입니다",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.user_pw2.length < 1){
          this.loading = false;
            this.$fire({
              html: "Please enter password confirm<br><br>비밀번호 확인을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }
        if(this.user_pw2.length < 6){
          this.loading = false;
            this.$fire({
              html: "Password must be at least 6 characters long<br><br>비밀번호는 최소 6글자 이상입니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }
        if(this.user_pw != this.user_pw2){
          this.loading = false;
            this.$fire({
              html: "The password is different<br><br>비밀번호가 다릅니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }

        
        this.$http.post(this.$host+'/fireJoin',{
            USER_ID: this.user_id
          , USER_PW: Buffer.from(this.user_pw).toString('base64')
          , NAME: escape(this.company_name)
          , BUSINESSNUMBER: this.business_number == undefined || this.business_number.length < 1 ? '' : escape(this.business_number)
          , BUSINESSITEM: this.business_item == undefined || this.business_item.length < 1 ? '' : escape(this.business_item)
          , ADDRESS: this.address == undefined || this.address.length < 1 ? '' : escape(this.address) 
          , GRADE: this.select_model
          , BUSINESS_IMG: this.upload_path1 == undefined || this.upload_path1.length < 1 ? '' : escape(this.upload_path1) 
          , CARD_IMG: this.upload_path2 == undefined || this.upload_path2.length < 1 ? '' : escape(this.upload_path2)
          , TEL: this.tel == undefined || this.tel.length < 1 ? '' : escape(this.tel)
          
          , MOBILE: this.mobilenumber == undefined || this.mobilenumber.length < 1 ? '' : escape(this.mobilenumber)
          , REGNAME: this.registrant_name == undefined || this.registrant_name.length < 1 ? '' : escape(this.registrant_name)
          , REGPOSITION: this.registrant_position == undefined || this.registrant_position.length < 1 ? '' : escape(this.registrant_position)
          , OWNERNAME: this.owner_name == undefined || this.owner_name.length < 1 ? '' : escape(this.owner_name)
          , OWNERMOBILE: this.owner_mobile == undefined || this.owner_mobile.length < 1 ? '' : escape(this.owner_mobile)
        })
        .then((result)=>{
            this.loading = false;
            
            if(result.data.resultCode == 0){
                //신규추가 성공
                this.$fire({
                  html: "Authentication mail has been sent.<br>Please log in after checking the authentication mail.<br><br>인증 메일이 발송되었습니다.<br>인증 메일 확인 후 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                if(this.PRODUCT_IDX == undefined){
                  this.$router.push('/Login');
                }else{
                  this.$router.push({path:"/Login", query:{code:this.PRODUCT_IDX}});
                }
            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.loading = false;
          this.$fire({
            html: "Your membership failed due to an error.<br><br>오류로 인해 회원가입에 실패했습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });
        
    },

    //이미지 크롭시
    cropimg(){

      this.$refs.cropper_land.getCropBlob(blob=>{
        this.crop_dialog = false;
        this.$refs.cropper_land.getCropData(async data=>{
          
          if(this.photo_index == 0){
            this.uploadFile1 = blob;  //업로드용 blob 데이터
            this.previewImg1 = data;  //이미지뷰 미리보기용
          }else if(this.photo_index == 1){
            this.uploadFile2 = blob;
            this.previewImg2 = data; //이미지뷰 미리보기용
          }
        });
      });
    },

    postcodetoAddr(addr){
      this.address = addr;
      this.$forceUpdate();
    },

    postcode(){

      //this.address_dialog = true;

      //콜백에서 못찾아서 콘스트로 미리 빼둔다.
      
      const callback_addrfunction     = this.postcodetoAddr;

      new daum.Postcode({
          oncomplete: function(data) {

              //console.log(JSON.stringify(data));
              var addr = data.address; // 최종 주소 변수
              if(data.userSelectedType == "J"){
                //지번
                addr = data.jibunAddress; 
              }

              //주소 정보를 해당 필드에 넣는다.
              callback_addrfunction(addr);

          }
      }).open();


    },


    async uploadStorage(folder, filename, blobd_ata){
      try{
        const formData = new FormData();
        formData.append("FOLDER", folder);
        formData.append("FILENAME", filename);
        formData.append("customfile", blobd_ata);
        const result = await this.$http.post(this.$host+'/Guestuploadimg'
          , formData
          , {headers:{'Content-Type':'multipart/form-data'}
        });
        if(result.data.resultCode == 0){
          return result.data;
        }else{
          return null;
        }
      }catch(error){
        return null;
      }
    },

      

  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

a{
  color:black !important;
}

p {
  color:black !important;
}

li {
  color:black !important;
}

h2 {
  color:black !important;
}

.input_design{
  
  color: black !important;
  border-color: black !important;
  border: black 1px solid;
  border-radius: 0px !important;
}

</style>