<template>

  <v-container fluid class="container_bg">

    <pdfinfo ref="pdfinfo" />

    <!-- 제품 검색 옵션 -->
    <v-row align="center"  justify="center" >
      
      <v-dialog scrollable persistent overlay-color="#000000FF"  v-model="search_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center" style="height:70px;background:black">
            <v-col cols="2">
            </v-col>
            <v-col cols="8"  align="center" class="d-flex align-center justify-center">
              <div style="color:white;font-size:16px;line-height:20px"
                >Search Options 선택검색하기<br>
              {{ SEARCH_PRODUCT_CLASS == 0 ? 'DIAMONDS' : SEARCH_PRODUCT_CLASS == 1 ? 'GEM Stone' : 'JEWELRY' }}
              </div>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="search_dialog = false"><v-icon color="white">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

              <v-row align="center" justify="center" dense>
                <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                  <!-- 젬스톤 : 종류 -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 1">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Type <span style="color:#512da8">보석종류</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex flex-wrap">

                      <v-btn-toggle
                        v-model="search_gemstone_type"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in JEWELRYKINDLIST">
                        <v-btn :width="$vuetify.breakpoint.smAndUp ? 140 : 80" 
                               :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               elevation="1" :key="item.IDX" class="ma-1" :value="item.IDX">
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                          <div class="ma-0 pa-0" style="font-weight:bold;color:#202020;"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                            >
                            {{item.NAME_ENG}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                      
                    </v-col>
                  </v-row>
                  </template>

                  <!-- 다이아, 젬스톤 : 중량 -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 0">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Carat <span style="color:#512da8">중량</span></p>
                    </v-col>
                  </v-row>
                  <!--
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                      <inputweight @setprice="setminweight" :value="search_min_weight"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputweight @setprice="setmaxweight" :value="search_max_weight"/>
                    </v-col>

                    <!--
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <v-range-slider
                        v-model="search_range_weight"
                        :min="0.2"
                        :max="20"
                        :step="0.01"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        @input="setWeightRange"
                        hide-details dense
                        />
                    </v-col>                    
                    -->
                  </v-row>
                  </template>

                  <template v-if="SEARCH_PRODUCT_CLASS == 1">
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Carat <span style="color:#512da8">중량</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-3" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                      <inputweight @setprice="setgemminweight" :value="search_gem_min_weight"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputweight @setprice="setgemmaxweight" :value="search_gem_max_weight"/>
                    </v-col>

                    <!-- 
                    <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <v-range-slider
                        v-model="search_range_gemweight"
                        :min="0.2"
                        :max="30"
                        :step="0.01"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        @input="setGemWeightRange"
                        hide-details dense
                        />
                    </v-col>
                    -->
                  </v-row>
                  </template>


                  <!-- 관리자에 주얼리, 대분류, 소분류 메뉴가 있어서 해당 기능과 충돌, 일단 안보이게 처리함. -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 4">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Jewelry Type <span style="color:#512da8">주얼리 종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-btn-toggle
                        class="d-flex flex-wrap justify-space-around mt-1 mb-1" 
                        color="deep-purple accent-3" tile 
                        v-model="search_jewely_type"
                        @change="changejewelrytype"
                        >
                        <template v-for="item in PRODUCTTYPELIST">
                        <v-btn  :color="search_jewely_type == item.IDX ? 'red' : 'black'"
                                :width="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                :height="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                elevation="1" :key="item.IDX" :value="item.IDX" style="margin-left:2px;margin-right:2px" 
                                >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <!-- <img class="ma-0 pa-0 mt-1" :width="$vuetify.breakpoint.smAndUp ? 50 : 24" :src="item.IMG"/> -->
                          <div class="ma-0 pa-0 mt-1" style="font-weight:normal;color:white;white-space:pre-wrap;text-align:center;"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                            >
                            {{item.NAME_KOR}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-row>

                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-btn-toggle
                        class="d-flex flex-wrap  ma-0 pa-0" 
                        color="deep-purple accent-3" tile 
                        v-model="search_jewely_item" multiple
                        @change="changejewelryitem"
                        >
                        <template v-for="item in PRODUCTITEMLIST">
                        <v-btn  :width="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                :height="$vuetify.breakpoint.smAndUp ? 80 : ($vuetify.breakpoint.width/6)-4"
                                elevation="1" :key="item.IDX" :value="item.IDX" style="margin-left:2px;margin-right:2px" 
                                >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0 mt-1" :width="$vuetify.breakpoint.smAndUp ? 50 : $vuetify.breakpoint.width/12" :src="item.IMG"/>
                          <div class="ma-0 pa-0 mt-1" style="font-weight:bold;color:#202020"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                            >
                            {{item.NAME_KOR}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-row>

                  </template>

                  <!-- 가격 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Price <span style="color:#512da8">가격</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">$</div>  
                      <inputprice @setprice="setminprice" :pricetype="0" :value="search_min_price"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputprice @setprice="setmaxprice" :pricetype="0" :value="search_max_price"/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                      <div style="width:15px;margin-top:1px;margin-right:5px">₩</div>  
                      <inputprice @setprice="setminprice2" :pricetype="1" :value="search_min_price2"/>
                      <div style="margin-left:5px;margin-right:5px">~</div>
                      <inputprice @setprice="setmaxprice2" :pricetype="1" :value="search_max_price2"/>
                    </v-col>
                  </v-row>

                  <!-- 다이아몬드 -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 0">
                  
                  <!-- 다이아몬드 타입 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Diamond Type 다이아몬드 <span style="color:#512da8">구분</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_diamondtype" 
                        multiple class="flex" color="red"
                        >
                        <template v-for="item in DIAMONDTYPELIST">
                          <v-btn width="50%" :color="search_diamondtype.includes(item.value)?'red':'black'"
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 35"
                                elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                            <div class="ma-0 pa-0" 
                              style="font-weight:bold;color:#ffffff;white-space: pre-wrap;text-transform: none"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px;letter-spacing :0.5px;'" 
                              >{{item.name}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>                                  

                  <!-- 컬러 -->
                  <v-row dense align="center" justify="center" class="mt-1 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Color<span style="color:#512da8">컬러</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">

                      <!-- range slider -->
                      <v-range-slider
                        :tick-labels="COLORSNAME"
                        v-model="search_color"
                        :min="0"
                        :max="10"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        thumb-size="25"
                        style="font-size:11px;"
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:12px;text-align:center;white-space: nowrap;">
                            {{ COLORSNAME[props.value] }}
                          </label>
                        </template>                        
                        </v-range-slider>
                    </v-col>
                  </v-row>

                  <!-- 투명도 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Clarity <span style="color:#512da8">투명도</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">

                      <v-range-slider
                        :tick-labels="TRANSNAMES"
                        v-model="search_transparency"
                        :min="0"
                        :max="11"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        thumb-size="30"
                        style="font-size:11px;"
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:12px;text-align:center;white-space: nowrap;">
                            {{ TRANSNAMES[props.value] }}
                          </label>
                        </template>                        
                        </v-range-slider>
                    </v-col>
                  </v-row>

                  <!-- 연마 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Cut <span style="color:#512da8">연마</span></p>
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Cut Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_total"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Polish Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important">
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_polish"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <div style="font-size:12px;color:black">Symmetry Grade</div>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0" style="margin-top:-16px !important">
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_grind_syme"
                        :tick-labels="EVALNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ EVALNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>     

                  <!-- 형광성 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Fluorescence <span style="color:#512da8">형광성</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <!-- 
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="11" md="9" align="center" class="ma-0 pa-0">
                      <v-range-slider
                        v-model="search_fluorescence"
                        :tick-labels="FLUNAMES1"
                        :min="0"
                        :max="6"
                        ticks="always"
                        tick-size="1"
                        color="#512da8"
                        track-color="gray"
                        thumb-color="black"
                        style="font-size:12px;"
                        thumb-size="55"
                        dense
                        >
                        <template v-slot:thumb-label="props">
                          <label dark style="font-size:10px;text-align:center;white-space: nowrap;">
                            {{ FLUNAMES2[props.value] }}
                          </label>
                        </template>                        
                      </v-range-slider>
                    </v-col>
                  </v-row>
                  -->

                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_fluorescence"
                        multiple class="d-flex flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in FLUORESCENCELIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 85 : 85" 
                                :height="$vuetify.breakpoint.smAndUp ? 30 : 25" 
                                elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020;text-transform:none;"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:12px' : 'font-size:10px'" 
                              >{{item.name}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>          


                  <!-- 다이아몬드 감정서 -->
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 0" dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="8" md="8" align="start"  class="d-flex ma-0 pa-0">
                      <div class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" 
                         style="color:black;display:inline">Certificate <span style="color:#512da8">감정서</span></div>
                    </v-col>
                    <v-col cols="2" md="2" align="start"  class="d-flex justify-end ma-0 pa-0">
                      <v-btn x-small color="black" style="color:white" @click="vs_dialog = true">VIVID</v-btn>
                      <!-- <v-btn x-small color="black" class=" ml-1" style="color:white" @click="ks_dialog = true">KS</v-btn> -->
                    </v-col>

                  </v-row>
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 0" dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 0" dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_certi"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in DIACERTILIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 70 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                elevation="1" class="mr-1 mt-1" :key="item.IDX" :value="item.IDX">
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:11px'" 
                              >{{item.NAME}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>

                  <!-- 쉐입 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Diamond Shape <span style="color:#512da8">모양</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  
                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex flex-wrap">
                      <v-btn-toggle
                        v-model="search_shape" 
                        multiple class="d-flex flex-wrap" tile
                        color="deep-purple accent-3"
                        >
                        <template v-for="item in DIASHAPELIST">
                        <v-btn :width="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                               elevation="1" :key="item.IDX" :value="item.IDX" class="ma-1" 
                               >
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                          <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                            >
                            {{item.NAME_ENG}}
                          </div>
                          </v-row>
                        </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>


                  <!-- 다이아몬드 컬러 -->
                  <v-row dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Colored Diamonds <span style="color:#512da8">컬러 다이아몬드</span></p>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="center" align-self="center" class="ma-0 pa-0 d-flex justify-center">
                      <v-btn-toggle
                        v-model="search_diamondcolor" tile
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in DIAMONDCOLORLIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 100 : 60" 
                                elevation="1" :key="item.value" class="ma-1" :value="item.value">
                            <v-row class="d-flex flex-column align-center justify-center" dense>
                            <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 50 : 40" :src="item.IMG"/>
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                              >
                              {{item.name}}
                            </div>
                            </v-row>
                          </v-btn>
                        </template>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>

                  </template>

                  <!-- 주얼리 -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 2">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Name Search <span style="color:#512da8">보석이름 검색</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                        <input
                          dense single-line clearable
                          v-model="search_jw_name" 
                          color="#000000"
                          hide-details 
                          class="basicsearchstyle_gem"
                          />
                      </v-col>
                      <v-col cols="12" md="10" align="start" style="color:red" class="pa-0 ma-0">
                        Please enter in English.(영문으로 입력하세요)
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">GEM Weight <span style="color:#512da8">보석 중량</span></p>
                      </v-col>
                    </v-row>
                    <!--
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    -->
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">Ct</div>  
                        <inputweight @setprice="setjwminweight" :value="search_jw_min_weight"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setjwmaxweight" :value="search_jw_max_weight"/>
                      </v-col>
                      <!-- 
                      <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <v-range-slider
                          v-model="search_range_jwweight"
                          :min="0.2"
                          :max="30"
                          :step="0.01"
                          color="#512da8"
                          track-color="gray"
                          thumb-color="black"
                          @input="setJwWeightRange"
                          hide-details dense
                          />
                      </v-col>
                      -->
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Type <span style="color:#512da8">금속종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_jewely_metaltype"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in METALLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 110 : 86" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Content <span style="color:#512da8">함량</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_jewely_metalweight"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in WEIGHTLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 60 : 20" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  :x-small="$vuetify.breakpoint.smAndDown"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>                    

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Weight <span style="color:#512da8">금속 중량</span></p>
                      </v-col>
                    </v-row>
                    <!-- 
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    -->
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">g</div>  
                        <inputweight @setprice="setjwmingram" :value="search_jw_min_gram"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setjwmaxgram" :value="search_jw_max_gram"/>
                      </v-col>
                      <!-- 
                      <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <v-range-slider
                          v-model="search_range_jwgram"
                          :min="0"
                          :max="100"
                          :step="0.1"
                          color="#512da8"
                          track-color="gray"
                          thumb-color="black"
                          @input="setJwGramRange"
                          hide-details dense
                          />
                      </v-col>
                      -->
                    </v-row>

                  </template>

                  <!-- 세공 디자인 -->
                  <template v-if="SEARCH_PRODUCT_CLASS == 3">

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Type <span style="color:#512da8">금속종류</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_ds_metaltype"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in METALLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 110 : 86" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Content <span style="color:#512da8">함량</span></p>
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    <v-row dense align="center" justify="center" class="ma-0" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0">
                        <v-btn-toggle
                          v-model="search_ds_metalweight"
                          multiple class="flex-wrap" color="deep-purple accent-3" tile
                          >
                          <template v-for="item in WEIGHTLIST">
                            <v-btn :width="$vuetify.breakpoint.smAndUp ? 60 : 20" 
                                  :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                  :x-small="$vuetify.breakpoint.smAndDown"
                                  elevation="1" class="mr-1 mt-1" :key="item.value" :value="item.value">
                              <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                                :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                                >{{item.name}}</div>
                            </v-btn>
                          </template>
                        </v-btn-toggle>
                      </v-col>
                    </v-row>                    

                    <v-row dense align="center" justify="center" class="mt-2 ma-0">
                      <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Metal Weight <span style="color:#512da8">금속 중량</span></p>
                      </v-col>
                    </v-row>
                    <!-- 
                    <v-row dense align="center" justify="center" class="ma-0 pa-0">
                      <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                        <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                      </v-col>
                    </v-row>
                    -->
                    <v-row dense align="center" justify="center" class="ma-0 mt-1" >
                      <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <div style="width:15px;margin-top:1px;margin-right:5px">g</div>  
                        <inputweight @setprice="setdsmingram" :value="search_ds_min_weight"/>
                        <div style="margin-left:5px;margin-right:5px">~</div>
                        <inputweight @setprice="setdsmaxgram" :value="search_ds_max_weight"/>
                      </v-col>
                      <!-- 
                      <v-col cols="11" md="9" align="start" class="ma-0 pa-0 d-flex justify-center align-center">
                        <v-range-slider
                          v-model="search_range_jwgram"
                          :min="0"
                          :max="100"
                          :step="0.1"
                          color="#512da8"
                          track-color="gray"
                          thumb-color="black"
                          @input="setJwGramRange"
                          hide-details dense
                          />
                      </v-col>
                      -->
                    </v-row>

                  </template>


                  <!-- 젬스톤 감정서 -->
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 1" dense align="center" justify="center" class="mt-2 ma-0">
                    <v-col cols="12" md="12" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 pl-md-10 pl-1 text_searchdialog_subtitle" style="color:black">Certificate <span style="color:#512da8">감정서</span></p>
                    </v-col>
                  </v-row>
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 1" dense align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="12" md="10" align="start" class="pa-0 ma-0">
                      <div :class="$vuetify.breakpoint.smAndUp ? 'black_line' : 'black_line_mb'"/>    
                    </v-col>
                  </v-row>
                  <v-row v-if="SEARCH_PRODUCT_CLASS == 1" dense align="center" justify="center" class="ma-0" >
                    <v-col cols="12" md="10" align="start" class="ma-0 pa-0">

                      <v-btn-toggle
                        v-model="search_gem_certi"
                        multiple class="flex-wrap" color="deep-purple accent-3"
                        >
                        <template v-for="item in GEMCERTILIST">
                          <v-btn :width="$vuetify.breakpoint.smAndUp ? 70 : 60" 
                                :height="$vuetify.breakpoint.smAndUp ? 40 : 25"
                                elevation="1" class="mr-1 mt-1" :key="item.IDX" :value="item.IDX">
                            <div class="ma-0 pa-0" style="font-weight:bold;color:#202020"
                              :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:11px'" 
                              >{{item.NAME}}</div>
                          </v-btn>
                        </template>
                      </v-btn-toggle>

                    </v-col>
                  </v-row>                  

                  
                </v-col>
              </v-row>
            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="clear_search_where">Clear 초기화</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="apply_search">Apply 적용</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
      
    </v-row>


    <!-- 엑셀등록 팝업 -->
    <v-row  align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="excel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '800px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:black;font-size:16px;line-height:18px" class="ma-0 pa-0 d-flex flex-wrap align-center justify-center">
                <div style="color:white">엑셀 양식 다운로드 및 엑셀 리스트 업로드<br>Download excel form and upload excel list</div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-3 ma-0">
                  <v-col cols="12" md="10" align="start" style="color:black" class="ma-0 pa-0">
                    다이아몬드는 무색다이아몬드와 팬시컬러 다이아몬드를 구분합니다. 또한 합성 다이아몬드도 2개 타입에서 등록이 가능합니다.
                  </v-col>
                  <v-col cols="12" md="10" align="start" style="color:black" class="ma-0 pa-0">
                    Diamonds differentiate between colorless and fancy colored diamonds. In addition. synthetic diamonds can be registered in two types.
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Excel Download&nbsp;<span class="dialog_subtitle_kor">양식다운로드</span></p>
                  </v-col>
                </v-row>
                
                <template v-if="grade > 90">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="2" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Diamonds.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Diamond<br>다이아몬드</p>
                    </v-col>
                    <v-col cols="2" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_ColoredDiamonds.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Colored Diamond<br>컬러 다이아몬드</p>
                    </v-col>
                    <v-col cols="2" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Gemstone.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Gemstone<br>젬스톤</p>
                    </v-col>
                    <v-col cols="2" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Jewelry.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Jewelry<br>주얼리</p>
                    </v-col>
                    <v-col cols="2" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Craft.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Design<br>디자인</p>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="3" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Diamonds.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Diamond<br>다이아몬드</p>
                    </v-col>
                    <v-col cols="3" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_ColoredDiamonds.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Colored Diamond<br>컬러 다이아몬드</p>
                    </v-col>
                    <v-col cols="3" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Gemstone.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Gemstone<br>젬스톤</p>
                    </v-col>
                    <v-col cols="3" align="center">
                      <v-btn color="gray" fab icon elevation="0" href="/files/Upload_Jewelry.xlsx" download>
                        <v-icon color="black">mdi-download</v-icon>
                      </v-btn>
                      <p class="text-md-body-1 text-caption text-center">Jewelry<br>주얼리</p>
                    </v-col>
                  </v-row>
              </template>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Upload Type&nbsp;<span class="dialog_subtitle_kor">업로드 타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="UPLOAD_PRODUCT_CLASS" :items="EXCELCLASSLIST" 
                      dense :disabled="this.editedIndex > -1"
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Upload Excel List&nbsp;<span class="dialog_subtitle_kor">엑셀 리스트 업로드</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-file-input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                      v-model="uploadExcelFile" dense></v-file-input>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="ma-0">
                  <v-col cols="12" md="10" align="start" style="color:red" class="ma-0 pa-0">
                    엑셀 파일의 Stock No를 기준으로 신규등록 및 기존상품 수정이 가능합니다.
                  </v-col>
                  <v-col cols="12" md="10" align="start" style="color:red" class="ma-0 pa-0">
                    New registration and modification of existing products are possible based on the stock no. in the excel file.
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-progress-linear color="black" :indeterminate="excel_prog"/>
                </v-row>

              </v-col>
            </v-row>              
            </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="excel_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="uploadExcel">Insert 등록</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />
    
    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="photo_index == 5 ? false : option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <!-- 일괄 할인설정 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="event_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">Event Setting&nbsp;일괄 할인설정</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Use Discounts&nbsp;<span class="dialog_subtitle_kor">할인 사용</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="TOTAL_SALETYPE" :items="SALELIST" 
                        dense
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="TOTAL_SALETYPE > 0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount Rate&nbsp;<span class="dialog_subtitle_kor">할인율</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="TOTAL_SALERATE"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50" suffix="%"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="TOTAL_SALETYPE == 2">
                    <v-row justify="center">
                      <v-col cols="12" md="5">
                        <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount Start Date&nbsp;<span class="dialog_subtitle_kor">할인 시작일</span></p>
                        </v-col>
                        <v-col cols="12" md="10" class="ma-0 pa-0">
                          <v-menu
                            v-model="start_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="TOTAL_SALESTART" class="none_radius" color="black" prepend-icon="mdi-calendar"
                                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                                dense single-line
                                readonly outlined hide-details
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker color="black" v-model="TOTAL_SALESTART" @input="start_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                          </v-menu>                    
                        </v-col>
                      </v-col>

                      <v-col cols="12" md="5">
                        <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount End Date&nbsp;<span class="dialog_subtitle_kor">할인 종료일</span></p>
                        </v-col>
                        <v-col cols="12" md="10" class="ma-0 pa-0">
                          <v-menu
                            v-model="end_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="TOTAL_SALEEND" class="none_radius" color="black" prepend-icon="mdi-calendar"
                                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                                dense single-line
                                readonly outlined hide-details
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker color="black" v-model="TOTAL_SALEEND" @input="end_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                          </v-menu>                            
                        </v-col>
                      </v-col>
                      
                    </v-row>  
                  </v-row>     

              </v-col>
            </v-row>            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="event_dialog =false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_event">Process 처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>
    
    <!-- 일괄 판매설정 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sale_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">Sale Setting&nbsp;일괄 판매설정</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Sale State&nbsp;<span class="dialog_subtitle_kor">판매상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="TOTAL_PRODUCT_STATE" :items="STATELIST" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>

              </v-col>
            </v-row>            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="sale_dialog =false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_sale">Process 처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>
    
    <!-- 일괄 전시설정 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="display_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">Display Setting&nbsp;일괄 전시설정</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Display State&nbsp;<span class="dialog_subtitle_kor">전시상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="TOTAL_DISPLAY_STATE" :items="DISPLAYLIST" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

              </v-col>
            </v-row>            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="display_dialog =false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_display">Process 처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>


    <!-- 일괄 마진설정 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="margin_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">Margin Setting&nbsp;일괄 마진설정</span>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Margin&nbsp;<span class="dialog_subtitle_kor">상품 마진(마진율-상품마진)</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="TOTAL_MARGIN"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>

              </v-col>
            </v-row>            
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="margin_dialog =false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_margin">Process 처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>


    <!-- 순서변경 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sort_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Sort Order&nbsp;순서 변경</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Name.&nbsp;<span class="dialog_subtitle_kor">상품명</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="sortItem.PRODUCT_NUMBER" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Sort.&nbsp;<span class="dialog_subtitle_kor">상품 정렬</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="sortItem.PRODUCT_SORT"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="closesort">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="savesort">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>


    <!-- 추가/수정 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'" :retain-focus="false"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Product registration&nbsp;상품 등록</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Name.&nbsp;<span class="dialog_subtitle_kor">상품명</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="PRODUCT_NUMBER"
                      single-line v-model="editedItem.PRODUCT_NUMBER" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Stock No.&nbsp;<span class="dialog_subtitle_kor">재고 번호</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Stock Number"
                      single-line v-model="editedItem.PRODUCT_STOCK" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      :disabled="this.editedIndex > -1"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Type&nbsp;<span class="dialog_subtitle_kor">상품 구분&nbsp;</span><span class="dialog_subtitle_kor" style="color:red">Required Option 필수옵션</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.PRODUCT_CLASS" :items="CLASSLIST" 
                      dense :disabled="this.editedIndex > -1" attach
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      @change="editedItem.PRICE_CALCTYPE = 0"
                      />
                  </v-col>

                  <template v-if="this.editedIndex == -1">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn-toggle
                      v-model="editedItem.PRODUCT_CLASS" tile mandatory
                      class="flex-wrap" color="deep-purple accent-3"
                      @change="changepricetype"
                      >
                      <template v-for="item in CLASSLIST">
                        <v-btn :color="editedItem.PRODUCT_CLASS == item.value?'red':'black'" 
                              :width="$vuetify.breakpoint.smAndUp ? grade > 90 ? 100 : 140 : $vuetify.breakpoint.width/6" 
                              :height="$vuetify.breakpoint.smAndUp ? grade > 90 ? 100 : 140 : $vuetify.breakpoint.width/6"
                              elevation="1" :key="item.value" class="ma-1" :value="item.value">
                          <v-row class="d-flex flex-column align-center justify-center" dense>
                          <!-- <img class="ma-0 pa-0" :width="$vuetify.breakpoint.smAndUp ? 80 : 60" :src="item.img"/> -->
                          <div class="ma-0 pa-0" style="font-weight:bold;color:#ffffff"
                            :style="$vuetify.breakpoint.smAndUp ? 'font-size:14px' : 'font-size:10px'" 
                            >
                            {{item.subname}}<br>{{item.subname2}}
                          </div>
                          </v-row>
                        </v-btn>
                      </template>
                    </v-btn-toggle>
                  </v-col>
                  </template>

                </v-row>

                <!-- 다이아몬드 전용 -->
                <template dense align="center" class="text-center" v-if="editedItem.PRODUCT_CLASS == 0">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Kind&nbsp;<span class="dialog_subtitle_kor">다이아몬드 구분</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.DIAMOND_CLASS" :items="DIAMONDCLASSLIST" 
                        dense :disabled="this.editedIndex > -1" attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        @change="changepricetype"
                        />
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Type&nbsp;<span class="dialog_subtitle_kor">다이아몬드 타입</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.DIAMONDTYPE" :items="DIAMONDTYPELIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        @change="changepricetype"
                        />
                    </v-col>
                  </v-row>
                </template>                

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price calculation method&nbsp;<span class="dialog_subtitle_kor">가격계산 방법</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">

                    <v-select 
                      v-if="editedItem.PRODUCT_CLASS == 0"
                      v-model="editedItem.PRICE_CALCTYPE" 
                      :items="editedItem.PRODUCT_CLASS == 0 && editedItem.DIAMOND_CLASS == 0 && editedItem.DIAMONDTYPE == 0 ? PRICECALCTYPELIST1 : PRICECALCTYPELIST2" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      @change="changepricetype"
                      />

                    <v-select 
                      v-if="editedItem.PRODUCT_CLASS == 1 || editedItem.PRODUCT_CLASS == 2 || editedItem.PRODUCT_CLASS == 3"
                      v-model="editedItem.PRICE_CALCTYPE" 
                      :items="editedItem.PRODUCT_CLASS == 1 ? PRICECALCTYPELIST2 : PRICECALCTYPELIST3" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      @change="changepricetype"
                      />

                  </v-col>
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <span v-if="editedItem.PRICE_CALCTYPE == 0" style="text-align:start;color:red">입력하신 가격을 사용합니다.<br>Use the price you entered.</span>
                    <span v-if="editedItem.PRICE_CALCTYPE == 1" style="text-align:start;color:red">입력하신 가격과 중량을 곱해서 공급가를 생성합니다.<br>Multiply the price entered by the weight to generate the supply price.</span>
                    <span v-if="editedItem.PRICE_CALCTYPE == 2" style="text-align:start;color:red">별도의 가격을 입력하지 않고 RAPAPORT를 참조하여 공급 가격을 생성합니다.<br>Create a supply price by referring to the RAPAPORT without entering a separate price.</span>
                  </v-col>
                </v-row>


                <v-row v-if="editedItem.PRICE_CALCTYPE == 0 || editedItem.PRICE_CALCTYPE == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price Type&nbsp;<span class="dialog_subtitle_kor">가격 타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.PRICE_TYPE" :items="PRICELIST" 
                      dense :disabled="this.editedIndex > -1" attach
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row v-if="editedItem.PRICE_CALCTYPE == 0 || editedItem.PRICE_CALCTYPE == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p v-if="editedItem.PRICE_CALCTYPE == 0" class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price&nbsp;<span class="dialog_subtitle_kor">가격</span></p>
                    <p v-if="editedItem.PRICE_CALCTYPE == 1" class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price Per Weight&nbsp;<span class="dialog_subtitle_kor">중량단가</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="SUPPLY_PRICE"
                      single-line v-model="editedItem.SUPPLY_PRICE"
                      :prefix="editedItem.PRICE_TYPE == 0 ? '$' : '₩'"
                      :suffix="editedItem.PRICE_TYPE == 0 ? 'USD' : '원'"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      @input="inputtext"
                      outlined dense hide-details
                      />
                  </v-col>
                </v-row>

                <v-row v-if="grade > 90" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Sale State&nbsp;<span class="dialog_subtitle_kor">판매상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.PRODUCT_STATE" :items="STATELIST" 
                      dense attach
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Display State&nbsp;<span class="dialog_subtitle_kor">전시상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.DISPLAY_STATE" :items="DISPLAYLIST" 
                      dense attach
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="grade > 90">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Product Margin&nbsp;<span class="dialog_subtitle_kor">상품 마진(마진율-상품마진)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.PRODUCT_MARGIN" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" id="PRODUCT_IMG1">Product Image&nbsp;<span class="dialog_subtitle_kor">상품 이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:200px;margin-left:0px;height:40px;width:240px;background-color:#00000080">
                              <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img  height="240" width="240" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(0)">mdi-image</v-icon>
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(0)">mdi-link</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0" v-if="editedItem.PRODUCT_CLASS == 0">
                    <div style="color:red">If there is no diamond picture, a default image is provided<br>다이아몬드 사진이 없으면 기본 이미지를 제공합니다.</div>
                  </v-col>

                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg2.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:200px;margin-left:0px;height:40px;width:240px;background-color:#00000080">
                              <v-icon style="width:80px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                            </div>
                            <img  height="240" width="240" :src="previewImg2" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(1)">mdi-image</v-icon>
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(1)">mdi-link</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate&nbsp;<span class="dialog_subtitle_kor">감정서</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                </v-row>
                <v-row v-if="editedItem.PRODUCT_CLASS == 0 || editedItem.PRODUCT_CLASS == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Laboratory&nbsp;<span class="dialog_subtitle_kor">감정기관</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.CERTI_IDX" :items="editedItem.PRODUCT_CLASS == 0 ? DIACERTILIST : editedItem.PRODUCT_CLASS == 1 ? GEMCERTILIST : JEWCERTILIST" 
                      dense attach id="PRODUCT_CLASS"
                      item-text="NAME" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="10" md="8" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate image&nbsp;<span class="dialog_subtitle_kor">감정서 이미지</span></p>
                  </v-col>
                  <v-col cols="2" md="2" align="start"  class="ma-0 pa-0">
                    <!-- <v-btn v-if="editedItem.PRODUCT_CLASS == 3" small text @click="deletecerti">Delete 삭제</v-btn> -->
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      
                      
                      <template v-if="previewImg6.length > 0">
                        <v-row dense  justify="center">

                          <template v-if="previewImg6 == pdfPreviewImg || previewImg6.split('.').pop().toLowerCase() == 'pdf'">
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <v-icon style="width:80px;height:40px;color:white" @click="showImage_pdf(5)">mdi-magnify-expand</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="pdfPreviewImg" class="image">
                            </div>
                          </template>
                          <template v-else-if="previewImg6.split('.').pop().toLowerCase() == 'jpg' || previewImg6.split('.').pop().toLowerCase() == 'jpeg' || previewImg6.split('.').pop().toLowerCase() == 'png' ">
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <v-icon style="width:80px;height:40px;color:white" @click="showImage(5)">mdi-magnify-expand</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="previewImg6" class="image">
                            </div>
                          </template>
                          <template v-else>
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <v-icon style="width:80px;height:40px;color:white" @click="showsite(editedItem.APPRAISAL_IMG)">mdi-magnify-expand</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="pdflinkImg" class="image">
                            </div>
                          </template>

                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect_pdf(5)">mdi-image</v-icon>
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(5)">mdi-link</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Video&nbsp;<span class="dialog_subtitle_kor">동영상</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="10" md="8" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">VIDEO FILE&nbsp;<span class="dialog_subtitle_kor">비디오</span></p>
                  </v-col>
                  <v-col cols="2" md="2" align="start"  class="ma-0 pa-0">
                    <v-btn small text @click="deletevideo">Delete 삭제</v-btn>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0 mt-5">
                    <template v-if="editedItem.MOVIE_PATH != null && editedItem.MOVIE_PATH.split('.').pop().toLowerCase() == 'mp4'">
                      <video ref="video" width="100%" controls :src="editedItem.MOVIE_PATH" ></video>
                    </template>
                    <template v-else-if="editedItem.MOVIE_PATH != null && editedItem.MOVIE_PATH.length > 1">
                      <!-- 
                      <v-btn large outlined @click="showsite(editedItem.MOVIE_PATH)">동영상 확인</v-btn>
                      <img  width="240" style="hegiht:auto" :src="pdflinkImg" class="image">
                      -->
                      <a @click="showsite(editedItem.MOVIE_PATH)">
                        <img  width="240" style="hegiht:auto" :src="require('@/assets/webvideo_icon.png')" class="image">
                        <!-- <v-img contain :aspect-ratio="1/1" style="background:white" :src="require('@/assets/webvideo_icon.png')" width="100%"/> -->
                      </a>
                    </template>
                    <template v-else>

                    </template>
                  </v-col>
                  <v-col cols="11" md="9" class="ma-0 pa-0 mt-5">
                    <v-file-input 
                      outlined dense height="48" class="popupiputstyle"
                      prepend-icon="mdi-file"
                      show-size hide-details v-model="uploadMovieFile" 
                      accept="video/mp4"
                      @change="changeMoviefile"
                      />
                  </v-col>
                  <v-col cols="1" md="1" class="mt-4">
                    <v-btn icon @click="linkadd(6)"><v-icon>mdi-link</v-icon></v-btn>
                  </v-col>
                </v-row>            



                <!-- 다이아몬드 전용 -->
                <template dense align="center" class="text-center" v-if="editedItem.PRODUCT_CLASS == 0">

                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Carat&nbsp;<span class="dialog_subtitle_kor">중량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.WEIGHT" suffix="ct"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>

                  <v-row v-if="editedItem.PRICE_CALCTYPE == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      PRICE PER WEIGHT : <span style="color:black">{{ editedItem.SUPPLY_PRICE }}</span>  CT : <span style="color:black">{{ editedItem.WEIGHT +'ct '}}</span>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      SUPPLY PRICE = {{ SUPPLY_PRICE_WEIGHT }}
                    </v-col>
                  </v-row>


                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shape&nbsp;<span class="dialog_subtitle_kor">모양</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.SHAPE_IDX" :items="DIASHAPELIST" 
                        dense attach
                        item-text="NAME_ENG" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        >
                        <template v-slot:selection="{ item, index }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.NAME_ENG }}</span>
                        </template>                        
                        <template v-slot:item="{ item }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.NAME_ENG }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Clarity&nbsp;<span class="dialog_subtitle_kor">투명도</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.TRANSPARENCY" :items="TRANSLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <!-- 일반 다이아몬드 -->
                  <template dense align="center" class="text-center" v-if="editedItem.DIAMOND_CLASS == 0">

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Color&nbsp;<span class="dialog_subtitle_kor">색상</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.COLOR" :items="COLORLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row v-if="editedItem.PRICE_CALCTYPE == 2" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price DC&nbsp;<span class="dialog_subtitle_kor">공급가 할인</span>&nbsp;<span class="dialog_subtitle_kor" style="color:red">ex)dc:-25, add:25</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius"
                          single-line v-model="editedItem.PRICE_DC" 
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details
                          :color="editedItem.PRICE_DC > 0 ? 'blue' : 'red'"
                          />
                      </v-col>
                    </v-row>

                    <v-row v-if="editedItem.PRICE_CALCTYPE == 2" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        RAPAPORT : <span style="color:black">{{ editedItem.RAPAVALUE }}</span>  CT : <span style="color:black">{{ editedItem.WEIGHT +'ct '}}</span>  DC : <span :style="editedItem.PRICE_DC > 0 ? 'color:blue' : 'color:red'">{{ editedItem.PRICE_DC +'%'}}</span>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        SUPPLY PRICE = {{ this.valuecomma(  Number((editedItem.RAPAVALUE * editedItem.WEIGHT) * (1+(editedItem.PRICE_DC/100))).toFixed(2) ) }}
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-btn outlined color="black" @click="checkparaprice">Check price 가격확인</v-btn>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cut&nbsp;<span class="dialog_subtitle_kor">연마</span></p>
                      </v-col>
                      <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                        <div class="black_line"/>    
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cut Grade&nbsp;<span class="dialog_subtitle_kor">컷팅</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.EVALUATION" :items="EVALLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Polish Grade&nbsp;<span class="dialog_subtitle_kor">폴리쉬</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.POLISH" :items="EVALLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Symmetry Grade&nbsp;<span class="dialog_subtitle_kor">대칭</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.SYMMETRY" :items="EVALLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Measurements&nbsp;<span class="dialog_subtitle_kor">사이즈</span></p>
                      </v-col>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.HEIGHT" suffix="mm" placeholder="Length"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      <div class="ml-1 mr-1">X</div>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black" 
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.WIDTH" suffix="mm" placeholder="Width"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      <div class="ml-1 mr-1">X</div>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.DEPTH" suffix="mm" placeholder="Depth"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      
                    </v-row>                           

                  </template>
                  <template v-else>
                    <!-- 펜시 다이아몬드 -->
                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Colored Diamonds&nbsp;<span class="dialog_subtitle_kor">컬러 다이아몬드</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.DIAMONDCOLOR" :items="DIAMONDCOLORLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          >
                          <template v-slot:selection="{ item, index }">
                            <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.name }}</span>
                          </template>                        
                          <template v-slot:item="{ item }">
                            <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.name }}</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Color Distribution&nbsp;<span class="dialog_subtitle_kor">색분포</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black"
                          single-line v-model="editedItem.COLORDISTRI"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                    </v-row>                  

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cut&nbsp;<span class="dialog_subtitle_kor">연마</span></p>
                      </v-col>
                      <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                        <div class="black_line"/>    
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Polish Grade&nbsp;<span class="dialog_subtitle_kor">폴리쉬</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.POLISH" :items="EVALLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Symmetry Grade&nbsp;<span class="dialog_subtitle_kor">대칭</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.SYMMETRY" :items="EVALLIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Measurements&nbsp;<span class="dialog_subtitle_kor">사이즈</span></p>
                      </v-col>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.HEIGHT" suffix="mm" placeholder="Length"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      <div class="ml-1 mr-1">X</div>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black" 
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.WIDTH" suffix="mm" placeholder="Width"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      <div class="ml-1 mr-1">X</div>
                      <v-col cols="3" md="3" class="ma-0 pa-0">
                        <v-text-field 
                          class="none_radius" color="black"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                          single-line v-model="editedItem.DEPTH" suffix="mm" placeholder="Depth"
                          :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined dense hide-details/>
                      </v-col>
                      
                    </v-row>                                

                    <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Color Grade&nbsp;<span class="dialog_subtitle_kor">컬러 그레이드</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.COLORGRADE" :items="COLORGRADELIST" 
                          dense attach
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                  </template>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Fluorescence&nbsp;<span class="dialog_subtitle_kor">형광성</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.FLUORESCENCE" :items="FLUORESCENCELIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                </template>

                <!-- 젬스톤 전용 -->
                <template dense align="center" class="text-center" v-if="editedItem.PRODUCT_CLASS == 1">


                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Gem Name&nbsp;<span class="dialog_subtitle_kor">보석명</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.JEWELRYKIND_IDX" :items="JEWELRYKINDLIST" 
                        dense attach
                        item-text="NAME_ENG" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        >
                        <template v-slot:selection="{ item, index }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.NAME_ENG }}</span>
                        </template>                        
                        <template v-slot:item="{ item }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.NAME_ENG }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Carat&nbsp;<span class="dialog_subtitle_kor">중량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.WEIGHT" suffix="ct"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                  <v-row v-if="editedItem.PRICE_CALCTYPE == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      PRICE PER WEIGHT : <span style="color:black">{{ editedItem.SUPPLY_PRICE }}</span>  CT : <span style="color:black">{{ editedItem.WEIGHT +'ct '}}</span>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      SUPPLY PRICE = {{ SUPPLY_PRICE_WEIGHT }}
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shape&nbsp;<span class="dialog_subtitle_kor">모양</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.SHAPE_IDX" :items="GEMSHAPELIST" 
                        dense attach
                        item-text="NAME_ENG" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        >
                        <template v-slot:selection="{ item, index }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.NAME_ENG }}</span>
                        </template>                        
                        <template v-slot:item="{ item }">
                          <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.NAME_ENG }}</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Measurements&nbsp;<span class="dialog_subtitle_kor">사이즈</span></p>
                    </v-col>
                    <v-col cols="3" md="3" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                        single-line v-model="editedItem.HEIGHT" suffix="mm" placeholder="Length"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                    <div class="ml-1 mr-1">X</div>
                    <v-col cols="3" md="3" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black" 
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                        single-line v-model="editedItem.WIDTH" suffix="mm" placeholder="Width"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                    <div class="ml-1 mr-1">X</div>
                    <v-col cols="3" md="3" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
                        single-line v-model="editedItem.DEPTH" suffix="mm" placeholder="Depth"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                    
                  </v-row>            

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Identification&nbsp;<span class="dialog_subtitle_kor">식별</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.IDENTIFICATION"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Color&nbsp;<span class="dialog_subtitle_kor">컬러</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.GEMSTONECOLOR"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                </template>

                <!-- 주얼리 전용 -->
                <template dense align="center" class="text-center" v-if="editedItem.PRODUCT_CLASS == 2">

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Jewelry Classification&nbsp;<span class="dialog_subtitle_kor">주얼리 구분</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Jewelry Type&nbsp;<span class="dialog_subtitle_kor">주얼리 유형</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.PRODUCTTYPE_IDX" :items="PRODUCTTYPELIST" 
                        dense attach
                        item-text="NAME_ENG" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius" @change="setJewelyItem(editedItem.PRODUCTTYPE_IDX)"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Jewelry Items&nbsp;<span class="dialog_subtitle_kor">주얼리 품목</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.PRODUCTITEM_IDX" :items="PRODUCTITEMLIST" 
                        dense attach
                        item-text="NAME" item-value="IDX" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Main Stone Name&nbsp;<span class="dialog_subtitle_kor">메인 스톤 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.MAINSTONE"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details
                        />
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" style="color:red" align="start">
                      Please enter in English(영문으로 입력하세요)
                    </v-col>
                  </v-row>                  
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Main Stone Weight&nbsp;<span class="dialog_subtitle_kor">메인스톤 중량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.MAINWEIGHT"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Sub Stone Name&nbsp;<span class="dialog_subtitle_kor">보조 스톤 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.SUBSTONE"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Sub Stone Weight&nbsp;<span class="dialog_subtitle_kor">보조스톤 중량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.SUBWEIGHT"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Gem Type&nbsp;<span class="dialog_subtitle_kor">보석 타입</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.DIAMONDTYPE" :items="GEMTYPELIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Content&nbsp;<span class="dialog_subtitle_kor">금속함량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.METALWEIGHT" :items="WEIGHTLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Type&nbsp;<span class="dialog_subtitle_kor">금속종류</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.METALTYPE" :items="METALLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Weight&nbsp;<span class="dialog_subtitle_kor">중량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.WEIGHT"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50" suffix="G"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                </template>

                <!-- 세공 전용 -->
                <template dense align="center" class="text-center" v-if="editedItem.PRODUCT_CLASS == 3">

                  <!-- 세공 품목 -->
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Design Type&nbsp;<span class="dialog_subtitle_kor">디자인 분류</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.PRODUCTTYPE_IDX" :items="DESIGNCLASSLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>                

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Content&nbsp;<span class="dialog_subtitle_kor">금속함량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.METALWEIGHT" :items="WEIGHTLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Type&nbsp;<span class="dialog_subtitle_kor">금속종류</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.METALTYPE" :items="METALLIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Metal Weight&nbsp;<span class="dialog_subtitle_kor">중량</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.WEIGHT"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50" suffix="G"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                </template>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Explanation&nbsp;<span class="dialog_subtitle_kor">설명</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="editedItem.DESC" color="black" hide-details outlined dense />
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <span style="text-align:start;color:black">위의 상품 등록 양식에 없는 내용은 설명란에 기재해주세요.<br>Please describe in the description box what is not on the product registration form above.</span>
                  </v-col>
                </v-row>


                <template v-if="grade == 99">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount Settings&nbsp;<span class="dialog_subtitle_kor">할인설정</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Use Discounts&nbsp;<span class="dialog_subtitle_kor">할인 사용</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="editedItem.SALETYPE" :items="SALELIST" 
                        dense attach
                        item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined color="#000000" hide-details class="none_radius"
                        />
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="editedItem.SALETYPE > 0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount Rate&nbsp;<span class="dialog_subtitle_kor">할인율</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="editedItem.SALERATE"
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50" suffix="%"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="editedItem.SALETYPE == 2">
                    <v-row justify="center">
                      <v-col cols="12" md="5">
                        <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount Start Date&nbsp;<span class="dialog_subtitle_kor">할인 시작일</span></p>
                        </v-col>
                        <v-col cols="12" md="10" class="ma-0 pa-0">
                          <v-menu
                            v-model="start_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.SALESTART" class="none_radius" color="black" prepend-icon="mdi-calendar"
                                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                                dense single-line
                                readonly outlined hide-details
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker color="black" v-model="editedItem.SALESTART" @input="start_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                          </v-menu>                    
                        </v-col>
                      </v-col>

                      <v-col cols="12" md="5">
                        <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                          <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Discount End Date&nbsp;<span class="dialog_subtitle_kor">할인 종료일</span></p>
                        </v-col>
                        <v-col cols="12" md="10" class="ma-0 pa-0">
                          <v-menu
                            v-model="end_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px"
                            >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="editedItem.SALEEND" class="none_radius" color="black" prepend-icon="mdi-calendar"
                                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                                dense single-line
                                readonly outlined hide-details
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker color="black" v-model="editedItem.SALEEND" @input="end_date = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
                          </v-menu>                            
                        </v-col>
                      </v-col>
                      
                    </v-row>  
                  </v-row>     
                </template>             

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->

                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 링크 입력 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="link_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Link&nbsp;이미지 링크</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Link&nbsp;<span class="dialog_subtitle_kor">링크</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Link"
                      single-line v-model="LINKADDR" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>    

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="link_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_link">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 옵션상세 추가/수정 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="option_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Option&nbsp;옵션</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Option Name.&nbsp;<span class="dialog_subtitle_kor">옵션명</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="optionItem.OPTION_NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price&nbsp;<span class="dialog_subtitle_kor">가격</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="SUPPLY_PRICE"
                      single-line v-model="optionItem.OPTION_PRICE"
                      :prefix="optionItem.PRICE_TYPE == 0 ? '$' : '₩'"
                      :suffix="optionItem.PRICE_TYPE == 0 ? 'USD' : '원'"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      @input="inputtext"
                      outlined dense hide-details
                      />
                  </v-col>
                </v-row>                         
                
                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Option Sort.&nbsp;<span class="dialog_subtitle_kor">옵션 순서</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="optionItem.OPTION_SORT"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="closeoption">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="saveoption">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 옵션리스트 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="optionlist_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Option List&nbsp;옵션 리스트</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="end" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="end">
                      <!-- 여기게 옵션 추가-->
                      <v-btn elevation="0" x-small style="background:black" color="white" @click="addoption" icon><v-icon>mdi-plus</v-icon></v-btn>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-2 ma-0">
                    <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                      <v-data-table
                        :headers="optionHeaders"
                        :items="optionList"
                        no-data-text="옵션을 추가하세요"
                        height="300"
                        hide-default-footer
                      >
                      <template v-slot:item.actions="{ item }">  
                        <v-icon class="mr-2" small @click="edit_option(item)">mdi-pencil</v-icon>
                        <v-icon small @click="delete_option(item)">mdi-delete</v-icon>
                      </template>                        
                    </v-data-table>                      
                    </v-col>
                  </v-row>

              </v-col>
            </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="6" class="ma-0 pa-0" align="center">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="closeoptionlist">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        


    <!-- 상세내용 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog2" width="800px">
        <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ editedItem.PRODUCT_NUMBER }}</span>
          </v-col>
        </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row>
                  <v-col md="12" sm="12" xs="12">
                    <div v-html="editedItem.DESC">
                    </div>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn color="primary" @click="dialog2 = false">닫기</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog2" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요 
            <v-progress-circular
              indeterminate
              height="15"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>        

    <v-row align="center" justify="center" class="mt-5" dense>
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col v-if="grade == 99" cols="12" md="3" class="pa-0 pl-md-0">
        <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
      </v-col>
      <v-col cols="12" md="2" class="pa-0" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="opensearch">
          <v-icon left>mdi-magnify</v-icon>Search Option Setting<br>옵션 선택 검색
        </v-btn>
      </v-col>
      <v-col cols="12" md="2" class="pa-0 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="excellist_download">
          <v-icon left>mdi-download</v-icon>Download Excel<br>엑셀다운로드
        </v-btn>
      </v-col>
    </v-row>
    
    <v-row dense align="center" justify="start">
      <v-spacer/>      
      <v-col v-if="grade == 99" cols="6" md="2" class="pa-0  pr-md-0" align="center">         
        <v-checkbox v-model="SEARCH_DELETE_SHOW" label="삭제상품만 보기" @change="changecombo"/>
      </v-col>
      <v-col v-if="grade == 99" cols="6" md="2" class="pa-0  pr-md-0" align="center">         
        <v-btn class="search_btn_freesize_nocolor" elevation="0" outlined large @click="newsavemargin">
          <v-icon left>mdi-check</v-icon>Price Setting<br>일괄 마진설정
        </v-btn>
      </v-col>
      <v-col v-if="grade == 99" cols="6" md="2" class="pa-0  pr-md-0" align="center">         
        <v-btn class="search_btn_freesize_nocolor" elevation="0" outlined large @click="newsaveevent">
          <v-icon left>mdi-check</v-icon>Event Setting<br>일괄 할인설정
        </v-btn>
      </v-col>
      <v-col v-if="grade == 99" cols="6" md="2" class="pa-0 pr-md-0" align="center">         
        <v-btn  class="search_btn_freesize_nocolor" elevation="0" outlined large @click="newsavesale">
          <v-icon left>mdi-check</v-icon>Sale Setting<br>일괄 판매설정
        </v-btn>
      </v-col>
      <v-col cols="6" md="2" class="pa-0" align="center">         
        <v-btn  class="search_btn_freesize_nocolor" elevation="0" outlined large @click="newsavediaplay">
          <v-icon left>mdi-check</v-icon>Display Setting<br>일괄 전시설정
        </v-btn>
      </v-col>
      <v-col cols="6" md="2" class="pa-0 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize_nocolor" elevation="0" outlined large @click="selectdelete">
          <v-icon left>mdi-check</v-icon>Delete Selection<br>선택 제품 삭제
        </v-btn>
      </v-col>

    </v-row>

    <v-row dense align="center" justify="center">
      <v-spacer/>
      <v-col cols="6" md="1" align="center">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>
      <v-col cols="6" md="1" class="pa-0 ma-0 pl-3 pl-md-0 pr-1">
        <v-select
          v-model="pagerow"
          :items="pagerow_combo"
          item-text="NAME"      
          item-value="VALUE"
          label="목록개수 List Quantity"
          outlined hide-details
          color="#808080"
          dense @change="movepage(1)"
          ></v-select>
      </v-col>
      <v-col cols="12" md="2" class="pl-3 pr-3 pl-md-0 pr-md-1 pa-1">
        <v-select 
          v-model="SEARCH_PRODUCT_CLASS" :items="SEARCHCLASSLIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="changecombo_clear"
          />
      </v-col>

      <!-- 다이아몬드 타입 -->
      <v-col v-if="SEARCH_PRODUCT_CLASS == 0" cols="12" md="2" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_DIAMOND_CLASS" :items="DIASEARCHTYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="changecombo"
          />
      </v-col>

      <!-- 젬스톤 보석 타입 -->
      <v-col v-if="SEARCH_PRODUCT_CLASS == 1" cols="12" md="2" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_GEMSTONE_CLASS" :items="GEMSEARCHTYPELIST" item-color="black"
          placeholder="구분" dense item-text="NAME_ENG" item-value="IDX" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="changecombo"
          />
      </v-col>

      <!-- 주얼리 유형 -->
      <v-col v-if="SEARCH_PRODUCT_CLASS == 2" cols="12" md="2" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_JEWELRY_TYPE" :items="JEWELRYSEARCHTYPELIST" item-color="black" 
          placeholder="구분" dense item-text="NAME_ENG" item-value="IDX"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="setJewelySearchItem(SEARCH_JEWELRY_TYPE)"
          />
      </v-col>
      <v-col v-if="SEARCH_PRODUCT_CLASS == 2" cols="12" md="2" class="pl-3 pr-3 pl-md-1 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_JEWELRY_ITEM" :items="JEWELRYSEARCHITEMLIST" item-color="black" 
          placeholder="구분" dense item-text="NAME" item-value="IDX"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="changecombo"
          />
      </v-col>

      <!-- 세공 디자인 유형 -->
      <v-col v-if="SEARCH_PRODUCT_CLASS == 3" cols="12" md="2" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_DESIGN_TYPE" :items="SEARCH_DESIGNCLASSLIST" item-color="black" 
          placeholder="구분" dense item-text="name" item-value="value"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="changecombo"
          />
      </v-col>

      <v-col cols="6" md="2" class="pl-3 pl-md-0 pa-0" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="newinsert">
          <v-icon left>mdi-plus</v-icon>One by One<br>제품 1개씩 등록
        </v-btn>
      </v-col>
      <v-col cols="6" md="2" class="pa-0 pr-3 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="insertExcel">
          <v-icon left>mdi-plus</v-icon>Register/modify Excel<br>대량등록/수정
        </v-btn>
      </v-col>

  </v-row>
    
  <v-row dense justify="center" v-if="grade > 90">
    <v-col cols="6" md="2" style="padding:2px"> 
      <div class="cntsum_round">
        <label class="cntsum_title">DIAMOND</label>
        <label class="cntsum_value">{{priceToString(dia1_cnt)}}</label>
      </div>
    </v-col>
    <v-col cols="6" md="2" style="padding:2px">
      <div class="cntsum_round">
        <label class="cntsum_title">Lab Grown DIAMOND</label>
        <label class="cntsum_value">{{priceToString(dia2_cnt)}}</label>
      </div>
    </v-col>
    <v-col cols="6" md="2" style="padding:2px">
      <div class="cntsum_round">
        <label class="cntsum_title">GEMSTONE</label>
        <label class="cntsum_value">{{priceToString(gem_cnt)}}</label>
      </div>
    </v-col>
    <v-col cols="6" md="2" style="padding:2px">
      <div class="cntsum_round">
        <label class="cntsum_title">JEWELRY</label>
        <label class="cntsum_value">{{priceToString(jew_cnt)}}</label>
      </div>
    </v-col>
    <v-col v-if="grade > 90" cols="6" md="2" style="padding:2px">
      <div class="cntsum_round">
        <label class="cntsum_title">CRAFT</label>
        <label class="cntsum_value">{{priceToString(craft_cnt)}}</label>
      </div>
    </v-col>
  </v-row>

  <v-row align="start" dense class="mt-3 mt-md-0">
    <v-col>
      
       
      <div class="tablediv" @scroll="onTableScroll">
      <table >
        <thead :class=" isVertical ? 'fixed_thead thead_shadow' : 'fixed_thead'">
          <tr class="thead_text">
            <td style="width:60px;text-align:center;left:0px" :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_th'">
              <input type="checkbox" v-model="allcheck" @change="allcheckchange" />
            </td>
            <td style="width:60px;text-align:center;left:60px" :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_th'">
              <a :class="sort_name=='PRODUCT_SORT'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_SORT')">Sort<br>순서</a>
            </td>
            <td style="width:50px;text-align:center;left:120px" :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_th'">
              Image<br>이미지
            </td>
            <td style="width:200px;text-align:center;left:170px" 
              @mousemove="handleMousemove"
              @mousedown="handleMousedown"
              @mouseup="handleMouseup"
              @mouseleave="handleMouseleave"
              :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_th'"
              >
              <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
            </td>

            <td style="width:140px;text-align:center;left:370px" 
              @mousemove="handleMousemove"
              @mousedown="handleMousedown"
              @mouseup="handleMouseup"
              @mouseleave="handleMouseleave"
              :class="$vuetify.breakpoint.smAndDown ? '' : isHorizon ? 'fixed_th td_shadow' : 'fixed_th'" >
              <a :class="sort_name=='PRODUCT_STOCK'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_STOCK')" style="text-decoration: underline;">Stock Number<br>재고번호</a>
            </td>

            <td style="width:120px;text-align:center" >
              <a :class="sort_name=='PRODUCT_CLASS'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_CLASS')">Product Type<br>상품구분</a>
            </td>
            <td style="width:140px;text-align:center">
              <a :class="sort_name=='PRODUCT_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_STATE')">Sale State<br>판매상태</a>
            </td>
            <td style="width:120px;text-align:center">
              <a :class="sort_name=='DISPLAY_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('DISPLAY_STATE')">Display State<br>전시상태</a>
            </td>
            <td style="width:120px;text-align:center">
              <a :class="sort_name=='DISCOUNT'?'cheader_sortselect':'cheader_sort'" @click="customsort('SALETYPE')">Discount<br>할인</a>
            </td>
            <td style="width:120px;text-align:center">
              <a :class="sort_name=='PRICE_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_USD')">Supply Price<br>공급가</a>
            </td>
            <td style="width:120px;text-align:center">
              <a :class="sort_name=='PRICE_WHOLE_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_WHOLE_USD')">WholeSale<br>도매가</a>
            </td>
            <td style="width:120px;text-align:center">
              <a :class="sort_name=='PRICE_RETAIL_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_RETAIL_USD')">Retail<br>소비자가</a>
            </td>
            <td style="width:200px;text-align:center">
              공급처
            </td>
            <td style="width:150px;text-align:center">
              <a :class="sort_name=='EDIT_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('EDIT_DATE')">Edit Date<br>수정일</a>
            </td>
            <td style="width:150px;text-align:center">
              <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Reg Date<br>등록일</a>
            </td>
            <td style="width:60px;text-align:center">
              <a :class="sort_name=='ISQUICK'?'cheader_sortselect':'cheader_sort'" @click="customsort('ISQUICK')">즉시<br>납품</a>
            </td>
            <td v-if="SEARCH_PRODUCT_CLASS == 3" style="width:50px;text-align:center">
              <a class="cheader_normal">Option<br>옵션</a>
            </td>
            <td style="width:60px;text-align:center">
              <template v-if="grade > 90">
                <a class="cheader_normal">Edit<br>수정</a>
              </template>
              <template v-else>
                <a class="cheader_normal">Edit<br>수정/삭제</a>
              </template>
            </td>
          </tr>
        </thead>
        <tbody class="td_text">
          
            <tr 
              v-for="item in itemList" v-bind:key="item.PRODUCT_IDX" 
              style="height:50px;"
              >
              <td style="min-width:60px;text-align:center;left:0px"
                  
                  :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_td'">
                <input type="checkbox" v-model="item.check" @change="checkchange(item)"/>
              </td>
              <td style="min-width:60px;text-align:center;left:60px" 
                
                :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_td'">
                <template v-if="item.PRODUCT_SORT != null && item.PRODUCT_SORT != undefined">
                  <a @click="edit_sort(item)" >{{item.PRODUCT_SORT}}</a>
                </template>
                <template v-else>
                    <v-icon class="mr-2" style="position: sticky; left: 0;" small @click="edit_sort(item)">mdi-pencil</v-icon>
                </template>
              </td>
              <td style="min-width:50px;text-align:center;left:120px" 
               
                :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_td'">
                <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                  <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px;" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                </template>
              </td>
              <td 
                style="min-width:200px;text-align:center;left:170px" 
                
                :class="$vuetify.breakpoint.smAndDown ? '' : 'fixed_td'">
                <template v-if="grade > 90">
                  <a @click="loadnotice(item)" :style="item.PRODUCT_STATE == 0 && item.DAY_STATE == 1 ? 'color:red' : ''">{{item.PRODUCT_NUMBER}}</a>
                </template>
                <template v-else>
                  <label>{{item.PRODUCT_NUMBER}}</label>
                </template>
              </td>
              <td style="min-width:140px;text-align:center;left:370px" 
                @click="tdclick"
                :class=" $vuetify.breakpoint.smAndDown ? '' : isHorizon ? 'fixed_td td_shadow' : 'fixed_td'">
                <!-- {{ item.PRODUCT_STOCK }} -->
                <input type="text" v-model="item.PRODUCT_STOCK" />
              </td>
              <td style="min-width:120px;text-align:center">
                <label style="text-align:center">{{ item.PRODUCT_CLASS == 0 ? 'Diamonds' : item.PRODUCT_CLASS == 1 ? 'Gemstones' : 'Jewelry' }}</label><br>
                <label style="text-align:center">{{ item.PRODUCT_CLASS == 0 ? '다이아몬드' : item.PRODUCT_CLASS == 1 ? '젬스톤' : '주얼리' }}</label>
              </td>
              <td style="min-width:140px;text-align:center">
                <label :class="item.PRODUCT_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.PRODUCT_STATE == 0 ? 'Stop Sale' : item.PRODUCT_STATE == 1 ? 'On Sale' : 'On Hand' }}</label><br>
                <label :class="item.PRODUCT_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.PRODUCT_STATE == 0 ? ' 판매보류' : item.PRODUCT_STATE == 1 ? ' 판매' : '보유' }}</label>
              </td>
              <td style="min-width:120px;text-align:center">
                <label :class="item.DELETE_STATE == 1 ? 'point_text_red' : item.DISPLAY_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.DELETE_STATE == 1 ? 'Delete' : item.DISPLAY_STATE == 0 ? 'Diaplay Off' : 'Display' }}</label><br>
                <label :class="item.DELETE_STATE == 1 ? 'point_text_red' : item.DISPLAY_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.DELETE_STATE == 1 ? '삭제' : item.DISPLAY_STATE == 0 ? '전시중지' : '전시중' }}</label>
              </td>
              <td style="min-width:120px;text-align:center">
                <label>{{ item.SALETYPE == 0 ? 'None' :  item.SALETYPE == 1 ? 'Continuous Discount' : 'Regular Discount' }}</label><br>
                <label>{{ item.SALETYPE == 0 ? '없음' :  item.SALETYPE == 1 ? '지속할인' : '기간할인' }}</label>
              </td>
              <td style="min-width:120px;text-align:end;padding-right:5px">
                <label>{{'₩ '+item.SUPPLY_PRICE_WON_DP}}</label><br>
                <label>{{'$ '+item.SUPPLY_PRICE_USD_DP}}</label>
              </td>
              <td style="min-width:120px;text-align:end;padding-right:5px">
                <label>{{'₩ '+item.PRICE_WHOLE_DP_WON}}</label><br>
                <label>{{'$ '+item.PRICE_WHOLE_USD_DP}}</label>
              </td>
              <td style="min-width:120px;text-align:end;padding-right:5px">
                <label>{{'₩ '+item.PRICE_RETAIL_DP_WON}}</label><br>
                <label>{{'$ '+item.PRICE_RETAIL_USD_DP}}</label>
              </td>
              <td style="min-width:200px;text-align:center">
                <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.PROV_NAME}}</a>
              </td>
              <td style="min-width:150px;text-align:center">{{ item.EDIT_DATE }}</td>
              <td style="min-width:150px;text-align:center">{{ item.REG_DATE }}</td>
              <td style="min-width:60px;text-align:center">
                <template v-if="item.ISQUICK == 0">
                  <v-icon small @click="directship(item.PRODUCT_IDX, 1)" color="gray">mdi-truck</v-icon>
                </template>
                <template v-else>
                  <v-icon small @click="directship(item.PRODUCT_IDX, 0)" color="green">mdi-truck</v-icon>
                </template>
              </td>
              <td v-if="SEARCH_PRODUCT_CLASS == 3" style="min-width:50px;text-align:center">
                <v-icon small @click="load_optionlist(item)">mdi-pencil</v-icon>
              </td>
              <td style="min-width:60px;text-align:center">
                <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
                <v-icon v-if="grade < 90" small @click="delete_item(item)">mdi-delete</v-icon>
              </td>

            </tr>
          
        </tbody>
      </table>
      </div>
      
      
      <!-- 테이블  
      <v-data-table
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
        item-key="PRODUCT_IDX"
        :loading="loading"
        show-select
        mobile-breakpoint="0"
        height="620px"
        fixed-header
        >

        <template v-slot:header.sorts>
          <a :class="sort_name=='PRODUCT_SORT'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_SORT')">Sort<br>순서</a>
        </template>
        <template v-slot:header.THUM_IMG1>
          <a class="cheader_normal">Image<br>이미지</a>
        </template>
        <template v-slot:header.PRODUCT_NUMBER>
          <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
        </template>
        <template v-slot:header.PRODUCT_STOCK>
          <a :class="sort_name=='PRODUCT_STOCK'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_STOCK')" style="text-decoration: underline;">Stock Number<br>재고번호</a>
        </template>
        <template v-slot:header.EDIT_DATE>
          <a :class="sort_name=='EDIT_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('EDIT_DATE')">Edit Date<br>수정일</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Reg Date<br>등록일</a>
        </template>
        <template v-slot:header.PRODUCT_CLASS>
          <a :class="sort_name=='PRODUCT_CLASS'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_CLASS')">Product Type<br>상품구분</a>
        </template>
        <template v-slot:header.PRODUCT_STATE>
          <a :class="sort_name=='PRODUCT_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_STATE')">Sale State<br>판매상태</a>
        </template>
        <template v-slot:header.DISPLAY_STATE>
          <a :class="sort_name=='DISPLAY_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('DISPLAY_STATE')">Display State<br>전시상태</a>
        </template>
        <template v-slot:header.DISCOUNT>
          <a :class="sort_name=='DISCOUNT'?'cheader_sortselect':'cheader_sort'" @click="customsort('SALETYPE')">Discount<br>할인</a>
        </template>
        <template v-slot:header.SUPPLY_PRICE_DP>
          <a :class="sort_name=='PRICE_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_USD')">Supply Price<br>공급가</a>
        </template>
        <template v-slot:header.PRICE_WHOLE_USD_DP>
          <a :class="sort_name=='PRICE_WHOLE_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_WHOLE_USD')">WholeSale<br>도매가</a>
        </template>
        <template v-slot:header.PRICE_RETAIL_USD_DP>
          <a :class="sort_name=='PRICE_RETAIL_USD'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICE_RETAIL_USD')">Retail<br>소비자가</a>
        </template>
        <template v-slot:header.ISQUICK>
          <a :class="sort_name=='ISQUICK'?'cheader_sortselect':'cheader_sort'" @click="customsort('ISQUICK')">즉시납품</a>
        </template>
        <template v-slot:header.options>
          <a v-if="SEARCH_PRODUCT_CLASS == 3" class="cheader_normal">Option<br>옵션</a>
        </template>
        <template v-slot:header.actions>
          <template v-if="grade > 90">
            <a class="cheader_normal">Edit<br>수정</a>
          </template>
          <template v-else>
            <a class="cheader_normal">Edit<br>수정/삭제</a>
          </template>
        </template>

        <template v-slot:item.THUM_IMG1="{ item }">  
            <v-col class="pa-0" align="center">
              <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px;margin-top:10px;margin-bottom:10px;" contain/>
              </template>
              <template v-else>
                <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
              </template>
            </v-col>
        </template>
        
        <template v-slot:item.PRODUCT_NUMBER="{ item }">  
          <template v-if="grade > 90">
            <a @click="loadnotice(item)" :style="item.PRODUCT_STATE == 0 && item.DAY_STATE == 1 ? 'color:red' : ''">{{item.PRODUCT_NUMBER}}</a>
          </template>
          <template v-else>
            <label>{{item.PRODUCT_NUMBER}}</label>
          </template>
        </template>
        
        <template v-slot:item.SUPPLY_PRICE_DP="{ item }">  
          <label>{{'₩ '+item.SUPPLY_PRICE_WON_DP}}</label><br>
          <label>{{'$ '+item.SUPPLY_PRICE_USD_DP}}</label>
        </template>
        <template v-slot:item.PRICE_WHOLE_USD_DP="{ item }">  
          <label>{{'₩ '+item.PRICE_WHOLE_DP_WON}}</label><br>
          <label>{{'$ '+item.PRICE_WHOLE_USD_DP}}</label>
        </template>
        <template v-slot:item.PRICE_RETAIL_USD_DP="{ item }">  
          <label>{{'₩ '+item.PRICE_RETAIL_DP_WON}}</label><br>
          <label>{{'$ '+item.PRICE_RETAIL_USD_DP}}</label>
        </template>


        <template v-slot:item.PRODUCT_CLASS="{ item }">  
          <label style="text-align:center">{{ item.PRODUCT_CLASS == 0 ? 'Diamonds' : item.PRODUCT_CLASS == 1 ? 'Gemstones' : 'Jewelry' }}</label><br>
          <label style="text-align:center">{{ item.PRODUCT_CLASS == 0 ? '다이아몬드' : item.PRODUCT_CLASS == 1 ? '젬스톤' : '주얼리' }}</label>
        </template>     

        <template v-slot:item.PRODUCT_STATE="{ item }">  
          <label :class="item.PRODUCT_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.PRODUCT_STATE == 0 ? 'Stop Sale' : item.PRODUCT_STATE == 1 ? 'On Sale' : 'On Hand' }}</label><br>
          <label :class="item.PRODUCT_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.PRODUCT_STATE == 0 ? ' 판매보류' : item.PRODUCT_STATE == 1 ? ' 판매' : '보유' }}</label>
        </template>     

        <template v-slot:item.DISPLAY_STATE="{ item }">  
          <label :class="item.DELETE_STATE == 1 ? 'point_text_red' : item.DISPLAY_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.DELETE_STATE == 1 ? 'Delete' : item.DISPLAY_STATE == 0 ? 'Diaplay Off' : 'Display' }}</label><br>
          <label :class="item.DELETE_STATE == 1 ? 'point_text_red' : item.DISPLAY_STATE == 0 ? 'point_text_red' : 'point_text_green'">{{ item.DELETE_STATE == 1 ? '삭제' : item.DISPLAY_STATE == 0 ? '전시중지' : '전시중' }}</label>
        </template>     

        <template v-slot:item.DISCOUNT="{ item }">  
          <label>{{ item.SALETYPE == 0 ? 'None' :  item.SALETYPE == 1 ? 'Continuous Discount' : 'Regular Discount' }}</label><br>
          <label>{{ item.SALETYPE == 0 ? '없음' :  item.SALETYPE == 1 ? '지속할인' : '기간할인' }}</label>
        </template>     
        
        <template v-slot:item.PROV_NAME ="{ item }">
          <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.PROV_NAME}}</a>
        </template>

        <template v-slot:item.ISQUICK="{item}">
          <template v-if="item.ISQUICK == 0">
            <v-icon small @click="directship(item.PRODUCT_IDX, 1)" color="gray">mdi-truck</v-icon>
          </template>
          <template v-else>
            <v-icon small @click="directship(item.PRODUCT_IDX, 0)" color="green">mdi-truck</v-icon>
          </template>
        </template>

        <template v-slot:item.options="{item}">
          <v-icon v-if="SEARCH_PRODUCT_CLASS == 3" small @click="load_optionlist(item)">mdi-pencil</v-icon>
        </template>

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon v-if="grade < 90" small @click="delete_item(item)">mdi-delete</v-icon>
        </template>  

        <template v-slot:item.sorts="{ item }" >  
          <template v-if="item.PRODUCT_SORT != null && item.PRODUCT_SORT != undefined">
              <a @click="edit_sort(item)" >{{item.PRODUCT_SORT}}</a>
          </template>
          <template v-else>
              <v-icon class="mr-2" style="position: sticky; left: 0;" small @click="edit_sort(item)">mdi-pencil</v-icon>
          </template>
        </template>  
      

      </v-data-table>
      -->

    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>



  </v-container>
</template>

<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css";
//pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.2.146/build/pdf.worker.min.js";

import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
import XLSX from 'xlsx'

export default {

    components:{
      VueCropper,
    },

    data: () => ({
      
      //옵션
      option_dialog: false,
      optionlist_dialog: false,
      optionList:[],
      optionItem:{},
      parentItem: {}, //옵션 로드시 선택한 상품 아이템 백업

      defaultOptionItem: {
          CRAFTOPTION_IDX:-1,
          PRODUCT_IDX:null,
          OPTION_NAME:"",
          OPTION_PRICE:0,
          OPTION_SORT:1
      },

      optionHeaders: [
        {text: '순서', value: 'OPTION_SORT', sortable: false, align:'center', width:'50px', class:"cheader_normal"},
        {text: '옵션명', value: 'OPTION_NAME', sortable: false, align:'center', width:'150px', class:"cheader_normal"},
        {text: '옵션가격', value: 'OPTION_PRICE', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', width:'50px', class:"cheader_normal"}
      ],

      //검색조건 설정      
      search_dialog: false,
      DIASHAPELIST: [],     //다이아몬드 모양
      DIACERTILIST: [],     //다이아몬드 인증서
      
      JEWELRYKINDLIST: [],  //보석종류
      GEMCERTILIST: [],     //보석 인증서
      
      PRODUCTTYPELIST: [],    //주얼리 유형
      PRODUCTITEMLISTALL: [], //주얼리 품목 전체
      PRODUCTITEMLIST: [],    //주얼리 유형에 따른 품목
      SELECTTITEMLIST: [],    //선택된 주얼리 유형값 백업 (하나만 쓰면 타입 바꿀시 초기화 되버림)
      SELECTTITEMMAP: {},     //선택이 해제된 토글 버튼값을 추적하기 위한 2차원 배열
      JEWCERTILIST: [],       //주얼리 인증서
      GEMSHAPELIST: [],     //보석 모양

      //다이아
      search_shape: [],
      search_color: [0,10],
      search_transparency: [0,11],
      search_grind_total: [0, 6],
      search_grind_polish: [0, 6],
      search_grind_syme: [0, 6],
      search_fluorescence:[], //형광성
      search_diamondcolor:[], //다이아몬드 컬러
      search_diamondtype:[],  //다이아몬드 타입 - 천연/합성

      //젬스톤
      search_gemstone_type: [],
      search_gem_min_weight: null,
      search_gem_max_weight: null,
      //search_range_gemweight: [0, 30],
      search_gem_certi: [],           //감정서
      
      //다이아몬드 잼스톤 
      search_min_weight: null,
      search_max_weight: null,
      //search_range_weight: [0, 20],
      search_certi: [],           //감정서

      //주얼리
      search_jw_min_weight: null,
      search_jw_max_weight: null,
      //search_range_jwweight: [0, 30],
      search_jw_min_gram: null,
      search_jw_max_gram: null,
      //search_range_jwgram: [0, 100],
      search_jw_name: '',
      
      search_jewely_metaltype:[],   //메탈타입(금속종류)
      search_jewely_metalweight:[], //함량
      search_jewely_type: [],
      search_jewely_item: [],

      //디자인 세공
      search_ds_min_weight: null,
      search_ds_max_weight: null,
      search_ds_metaltype:[],   //메탈타입(금속종류)
      search_ds_metalweight:[], //함량
      

      //공통
      search_min_price:null,      //달러
      search_max_price:null,
      search_min_price2:null,     //원화
      search_max_price2:null,


      //세공 분류
      SEARCH_DESIGNCLASSLIST: [
          {name:'전체',     value:-1}
        , {name:'반지',     value:0}
        , {name:'목걸이',   value:1}
        , {name:'귀걸이',   value:2}
        , {name:'커플링',   value:3}
        , {name:'팔찌',     value:4}
        , {name:'기타',     value:9}
      ],
      DESIGNCLASSLIST: [
          {name:'반지',     value:0}
        , {name:'목걸이',   value:1}
        , {name:'귀걸이',   value:2}
        , {name:'커플링',   value:3}
        , {name:'팔찌',     value:4}
        , {name:'기타',     value:9}
      ],


      //검색용 평가
      EVALLIST: [
          {name:'No Grade',    value:9}
        , {name:'Ideal',       value:5}
        , {name:'Excellent',   value:0}
        , {name:'Very Good',   value:1}
        , {name:'Good',        value:2}
        , {name:'Fair',        value:3}
        , {name:'Poor',        value:4}
      ],
      EVALNAMES1:['EX', 'VG', 'GD', 'FR', 'PR', 'ID', 'NG'],
      EVALNAMES2:['Excellent', 'Very Good', 'Good', 'Fair', 'Poor', 'Ideal', 'No Grade'],

      //형광성 검색용
      FLUORESCENCELIST: [
          {name:'None',           value:0}
        , {name:'Faint',          value:1}
        , {name:'Faint Slight',   value:5}
        , {name:'Very Slight',    value:4}
        , {name:'Slight',         value:7}
        , {name:'Medium',         value:2}
        , {name:'Strong',         value:3}
        , {name:'Very Strong',    value:6}
        , {name:'N/A',            value:99}
      ],


      //순서 변경
      sort_dialog: false,
      defaultsortItem: {
          PRODUCT_IDX:0,
          PRODUCT_NUMBER:null,
          PRODUCT_SORT:1,
      },
      sortItem: {
      },

      //일괄처리
      event_dialog:false,
      sale_dialog:false,
      display_dialog:false,
      margin_dialog:false,
      
      TOTAL_SALETYPE:0,
      TOTAL_SALERATE:0,
      TOTAL_SALESTART:null,
      TOTAL_SALEEND:null,
      TOTAL_PRODUCT_STATE:0,
      TOTAL_DISPLAY_STATE:0,
      TOTAL_MARGIN:0,

      //엑셀등록
      excel_dialog: false,
      excel_prog: false,
      uploadExcelFile:null,
      UPLOAD_PRODUCT_CLASS: 0,
      
      link_dialog: false,
      link_index:0,
      LINKADDR:"",

      start_date: false,
      end_date:false,
      
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,
      
      progress_value:0,
      progoptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'circle'
        }
      },

      dia1_cnt:0,
      dia2_cnt:0,
      gem_cnt:0,
      jew_cnt:0,
      craft_cnt:0,
      
      uploadFile1 : [],
      uploadFileThum1 : [],
      uploadFile2 : [],
      uploadFile3 : [],
      uploadFile4 : [],
      uploadFile5 : [],
      uploadFile6 : [],
      uploadMovieFile : [],
      
      descImgs:[],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      pagerow : 100,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'10', VALUE:10}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
        , {NAME:'500', VALUE:500}
        , {NAME:'1000', VALUE:1000}
      ],    
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      progress_dialog:false,
      progress_dialog2:false,
      progress:0,
      defaultItem: {
          PRODUCT_IDX:0,
          PRODUCT_CLASS:0,
          PRODUCT_NUMBER:'',
          PRODUCT_STOCK:'',
          THUM_IMG1: null,
          PRODUCT_IMG1: null,
          PRODUCT_IMG2: '',
          PRODUCT_IMG3: '',
          PRODUCT_IMG4: '',
          PRODUCT_IMG5: '',
          PRODUCT_STATE:0,
          DISPLAY_STATE:1,
          WEIGHT:0,
          CERTI_IDX:0,
          APPRAISAL_IMG:'',
          SHAPE_IDX:0,
          COLOR:0,
          TRANSPARENCY:0,
          EVALUATION:0,
          POLISH:0,
          SYMMETRY:0,

          DIAMONDCOLOR:0,
          FLUORESCENCE:0,
          DIAMONDTYPE:0,

          SUPPLY_PRICE:null,
          PRODUCT_MARGIN:0,
          JEWELRYKIND_IDX:0,
          
          PRODUCTTYPE_IDX:0,
          PRODUCTITEM_IDX:0,
          MAINSTONE:'',
          SUBSTONE:'',
          METALWEIGHT:0,
          METALTYPE:0,
          DESC:'',
          MOVIE_PATH:'',
          QUANTITY:0,
          SALETYPE:0,
          SALERATE:0,
          SALESTART:null,
          SALEEND:null,
          EDIT_DATE:'',
          REG_DATE:'',
          PRODUCT_SORT:null,
          PRICE_TYPE:0,
          DIAMOND_CLASS:0,
          WIDTH:0,
          DEPTH:0,
          HEIGHT:0,
          COLORDISTRI:'',
          COLORGRADE:0,
          IDENTIFICATION:'',
          GEMSTONECOLOR:'',
          MAINWEIGHT:null,
          SUBWEIGHT:null,

          PRICE_CALCTYPE:0,
          RAPAVALUE:0,
          PRICE_DC:0,
      },


      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
      ],
      itemList: [],

      testalign:"center",

      sort_name: 'EDIT_DATE',
      sort_type: true,    //true : DESC, false: ASC
      search_keyword : '',

      COLORGRADELIST: [
          {name:'Fancy',        value:3}
        , {name:'Very Light',   value:4}
        , {name:'Light',        value:5}
        , {name:'Fancy Light',  value:2}
        , {name:'Fancy',        value:6}
        , {name:'Fancy Intense',value:1}
        , {name:'Fancy Vivid',  value:0}
        , {name:'Fancy Dark',   value:7}
        , {name:'Fancy Deep',   value:8}
        , {name:'Other',        value:99}
      ],

      PRICECALCTYPELIST1: [
          {name:'INPUT/직접입력',   value:0}
        , {name:'WEIGHT/중량단가',   value:1}
        , {name:'RAPAPORT/라파시세',   value:2}
      ],

      PRICECALCTYPELIST2: [
          {name:'INPUT/직접입력',   value:0}
        , {name:'WEIGHT/중량단가',   value:1}
      ],

      PRICECALCTYPELIST3: [
          {name:'INPUT/직접입력',   value:0}
      ],

      PRICELIST: [
          {name:'USD',   value:0}
        , {name:'WON',   value:1}
      ],

      DIAMONDCLASSLIST: [
          {name:'Diamond/다이아몬드', value:0}
        , {name:'Colored Diamond/컬러 다이아몬드',  value:1}
      ],

      //제품 구분
      CLASSLIST: [],

      EXCELCLASSLIST: [],

      SEARCH_PRODUCT_CLASS:-1,
      SEARCH_DIAMOND_CLASS:-1,
      SEARCH_GEMSTONE_CLASS:-1,
      SEARCH_JEWELRY_ITEM:-1,
      SEARCH_JEWELRY_TYPE:-1,
      SEARCH_DESIGN_TYPE:-1,
      SEARCH_DELETE_SHOW:false,

      SEARCHCLASSLIST: [
      ],

      //판매상태
      STATELIST: [
          {name:'Sale Off/판매보류', value:0}
        , {name:'Sale On/판매',    value:1}
        , {name:'On Hand/보유',    value:2}
      ],

      //전시상태
      DISPLAYLIST: [
          {name:'Off/전시중지', value:0}
        , {name:'On/전시중',    value:1}
      ],


      //금속함량
      WEIGHTLIST: [
          {name:'24k',   value:0}
        , {name:'22k',   value:1}
        , {name:'20k',   value:2}
        , {name:'18k',   value:3}
        , {name:'14k',   value:4}
        , {name:'12k',   value:5}
        , {name:'10k',   value:6}
        , {name:'999',   value:7}
        , {name:'995',   value:8}
        , {name:'950',   value:9}
        , {name:'900',   value:10}
      ],

      //금속종류
      METALLIST:[
          {name:'Yellow Gold',value:0}
        , {name:'White Gold', value:1}
        , {name:'Pink Gold',  value:2}
        , {name:'Pure Gold',  value:3}
        , {name:'Platinum',   value:4}
        , {name:'Silver',     value:5}
        , {name:'Titanium',   value:6}
        , {name:'Other',      value:7}
      ],


      //투명도
      TRANSLIST: [
          {name:'FL',    value:0}
        , {name:'IF',    value:1}
        , {name:'VVS1',  value:2}
        , {name:'VVS2',  value:3}
        , {name:'VS1',   value:4}
        , {name:'VS2',   value:5}
        , {name:'SI1',   value:6}
        , {name:'SI2',   value:7}
        , {name:'SI3',   value:8}
        , {name:'I1',    value:9}
        , {name:'I2',    value:10}
        , {name:'I3',    value:11}
      ],
      TRANSNAMES:['FL','IF','VVS1','VVS2','VS1','VS2','SI1','SI2','SI3','I1','I2','I3'],
      FLUNAMES1:['NON', 'FNT', 'FSL', 'VSL', 'SLT', 'MED', 'STG', 'VST', 'N/A'],
      FLUNAMES2:['None', 'Faint', 'Faint Slight', 'Very Slight', 'Slight', 'Medium', 'Strong', 'Very Strong', 'N/A'],

      //색상
      COLORLIST: [
          {name:'D',   value:0}
        , {name:'E',   value:1}
        , {name:'F',   value:2}
        , {name:'G',   value:3}
        , {name:'H',   value:4}
        , {name:'I',   value:5}
        , {name:'J',   value:6}
        , {name:'K',   value:7}
        , {name:'L',   value:8}
        , {name:'M',   value:9}
        , {name:'N',   value:10}
      ],
      COLORSNAME: ['D','E','F','G','H','I','J','K','L','M','N'],

      DIAMONDCOLORLIST: [
          {name:'Red',    value:0, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/1red.png'}
        , {name:'Blue',   value:1, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/2blue.png'}
        , {name:'Pink',   value:2, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/3pink.png'}
        , {name:'Yellow', value:3, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/4yellow.png'}
        , {name:'Green',  value:4, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/5green.png'}
        , {name:'Brown',  value:5, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/6brown.png'}
        , {name:'Black',  value:6, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/7black.png'}
        , {name:'Other',  value:7, IMG:''}
      ],

      DIAMONDTYPELIST: [
          {name:'Natural Diamond/천연',    value:0}
        , {name:'Lab Grown Diamond/합성',  value:1}
      ],

      GEMTYPELIST: [
          {name:'Natural/천연',    value:0}
        , {name:'Lab Grown/합성',  value:1}
        , {name:'None/없음',       value:2}
      ],

      SALELIST: [
          {name:'None/할인없음',   value:0}
        , {name:'Continuous Discount/지속할인',   value:1}
        , {name:'Regular Discount/기간할인',   value:2}
      ],

      DIASEARCHTYPELIST: [
          {name:'전체',         value:-1}
        , {name:'Natural/천연', value:0}
        , {name:'Fancy/팬시',   value:1}
      ],
      GEMSEARCHTYPELIST: [
      ],
      JEWELRYSEARCHTYPELIST: [],
      JEWELRYSEARCHITEMLIST: [],

      TREATMENTLIST:[
          {name:'No/아니오',   value:0}
        , {name:'Yes/예',   value:1}
      ],

      previewImg1:"",
      previewImg2:"",
      previewImg3:"",
      previewImg4:"",
      previewImg5:"",
      previewImg6:"",   //감정서

      //PDF첨부시 보여줄 공통 이미지
      pdfPreviewImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/pdfimg2.png",
      //외부 PDF 첨부시 보여줄 공통 이미지
      pdflinkImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/web_pdfimg3.png",

      options: {
        focus:false,
      },      
      crop_dialog:false,
      photo_index:0,
      imgSrc:'',
      youtube_thum:'',

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            

      loadJewelry:true,    //주얼리 최초에 값을 복원시에는 조회 및 초기화를 하면 안된다.
      
      imageviewer:null,


      isHorizon:false,
      isVertical:false,
      allcheck:false,
      
    }),


    filters:{

      comma(val){
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }

    },


    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    computed:{

      SUPPLY_PRICE_WEIGHT:function(){
        return this.valuecomma(Number(String(this.editedItem.SUPPLY_PRICE).replaceAll(",","") * this.editedItem.WEIGHT).toFixed(2));
      }

    },

    mounted(){

      if(this.$session.get("GRADE") < 10){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.grade == 99){
        //슈퍼관리자
        this.headers = [
          {text: '순서', value: 'sorts', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
          {text: '이미지',  value: 'THUM_IMG1', sortable: false, align:'center', width:80, class:"cheader_normal"},
          {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
          {text: '재고번호', value: 'PRODUCT_STOCK', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
          {text: '구분', value: 'PRODUCT_CLASS', sortable: false, align:'center', width:'140px', class:"cheader_normal"},
          {text: '판매상태', value: 'PRODUCT_STATE', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
          {text: '전시상태', value: 'DISPLAY_STATE', sortable: false, align:'center', width:'140px', class:"cheader_normal"},
          {text: '할인', value: 'DISCOUNT', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
          {text: '공급가', value: 'SUPPLY_PRICE_DP', sortable: false, align:'right', width:'120px', class:"cheader_normal"},
          {text: '도매가', value: 'PRICE_WHOLE_USD_DP', sortable: false, align:'right', width:'120px', class:"cheader_normal"},
          {text: '소매가', value: 'PRICE_RETAIL_USD_DP', sortable: false, align:'right', width:'120px', class:"cheader_normal"},
          {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
          {text: '수정일시', value: 'EDIT_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
          {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
          {text: '즉시', value: 'ISQUICK', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
          {text: '옵션', value: 'options', sortable: false, align:'center', width:'50px', class:"cheader_normal"},
          {text: '수정', value: 'actions', sortable: false, align:'center', width:'60px', class:"cheader_normal"}
        ];

        this.SEARCHCLASSLIST= [
            {name:'Select Item/품목 선택', value:-1}
          , {name:'Diamonds/다이아몬드',   value:0}
          , {name:'Gemstones/젬스톤',      value:1}
          , {name:'Jewelry/주얼리',        value:2}
          , {name:'Craft/세공',            value:3}
        ];

        this.CLASSLIST = [
            {name:'Diamonds/다이아몬드', subname:'DIAMONDS', subname2:'다이아몬드',  value:0, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_diamond.png'}
          , {name:'Gemstones/젬스톤', subname:'GEMSTONES', subname2:'보석',  value:1, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_gemstone.png'}
          , {name:'Jewelry/주얼리', subname:'JEWELRY', subname2:'주얼리',  value:2, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_jewelry.png'}
          , {name:'Craft/세공', subname:'CRAFT', subname2:'세공',  value:3, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_jewelry.png'}
        ];

        this.EXCELCLASSLIST = [
            {name:'Diamonds/다이아몬드',              value:0}
          , {name:'Colored Diamonds/컬러 다이아몬드', value:1}
          , {name:'Gemstones/젬스톤',                value:2}
          , {name:'Jewelry/주얼리',                  value:3}
          , {name:'Design/디자인',                   value:4}
        ];

      }else{
        //도매
        this.headers = [
          {text: '이미지',  value: 'THUM_IMG1', sortable: false, align:'center', width:80, class:"cheader_normal"},
          {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'250px', class:"cheader_normal"},
          {text: '재고번호', value: 'PRODUCT_STOCK', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
          {text: '구분', value: 'PRODUCT_CLASS', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
          {text: '전시상태', value: 'DISPLAY_STATE', sortable: false, align:'center', width:'160px', class:"cheader_normal"},
          {text: '공급가', value: 'SUPPLY_PRICE_DP', sortable: false, align:'right', width:'160px', class:"cheader_normal"},
          {text: '수정일시', value: 'EDIT_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
          {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
          {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
        ];

        this.SEARCHCLASSLIST= [
            {name:'Select Item/품목 선택', value:-1}
          , {name:'Diamonds/다이아몬드',   value:0}
          , {name:'Gemstones/젬스톤',      value:1}
          , {name:'Jewelry/주얼리',        value:2}
        ];

        this.CLASSLIST = [
            {name:'Diamonds/다이아몬드', subname:'DIAMONDS', subname2:'다이아몬드',  value:0, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_diamond.png'}
          , {name:'Gemstones/젬스톤', subname:'GEMSTONES', subname2:'보석',  value:1, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_gemstone.png'}
          , {name:'Jewelry/주얼리', subname:'JEWELRY', subname2:'주얼리',  value:2, img:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/type_jewelry.png'}
        ];

        this.EXCELCLASSLIST = [
            {name:'Diamonds/다이아몬드',              value:0}
          , {name:'Colored Diamonds/컬러 다이아몬드', value:1}
          , {name:'Gemstones/젬스톤',                value:2}
          , {name:'Jewelry/주얼리',                  value:3}
        ];

      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    create(){
      
    },

    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave(to, from, next){
      if(this.$refs.pdfinfo.pdf_dialog){
        this.$refs.pdfinfo.close();
        next(false);
      }else if(this.imageviewer != null){
        if(this.imageviewer.viewed){
          //이미지 상세보기가 떠있는 상태면
          try{
            this.imageviewer.destroy();
            this.imageviewer = null;
          }catch(error){
          }finally{
            next(false);
          }
        }else{
          next();
        }
      }else{
        next();
      }
    },

    beforeRouteUpdate(to, from, next) {
      if(this.$refs.pdfinfo.pdf_dialog){
        this.$refs.pdfinfo.close();
        next(false);
      }else if(this.imageviewer != null){
        if(this.imageviewer.viewed){
          //이미지 상세보기가 떠있는 상태면
          try{
            this.imageviewer.destroy();
            this.imageviewer = null;
          }catch(error){
          }finally{
            next(false);
          }
        }else{
          next();
        }
      }else{
        next();
      }
    },

    methods: {


      allcheckchange(){
        console.log("allcheckchange : " + this.allcheck);
        this.selected.splice(0, this.selected.length);   //초기화
        if(this.allcheck){
          this.allcheck = 1;
          this.itemList.forEach(item => {
            item.check = true;
            this.selected.push(item);
          });
        }else{
          this.allcheck = 0;
          this.itemList.forEach(item => {
            item.check = false;
          });
        }
        console.log("check cnt : " + this.selected.length);
      },
      
      checkchange(item){
        console.log("item : " + item.check + " / " + item.PRODUCT_IDX);
        if(item.check){
          // 중복 검사 후 추가
          const isDuplicate = this.selected.some(obj => obj.PRODUCT_IDX === item.PRODUCT_IDX);
          if (!isDuplicate) {
            this.selected.push(item);
          }   
        }else{
          const indexToDelete = this.selected.findIndex(obj => obj.PRODUCT_IDX === item.PRODUCT_IDX);
          if (indexToDelete !== -1) {
            this.selected.splice(indexToDelete, 1);
          }          

        }
        console.log("check cnt : " + this.selected.length);
      },
            

      //라파시세 가져오기
      checkparaprice(){

          const weightVal = /[^0-9.]/g;
          const check = cmvalidation.checkValReg(this.editedItem.WEIGHT, weightVal, -1, -1);
          if(check == 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 2){
            this.$fire({
              html: "Weight can only be entered with numbers and .<br><br>중량은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(String(this.editedItem.WEIGHT).replaceAll('.','').length < 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(this.editedItem.WEIGHT <= 0){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          if(this.isNullCheck(this.editedItem.SHAPE_IDX)){
            this.$fire({
              html: "Choose a shape<br><br>모양을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.TRANSPARENCY)){
            this.$fire({
              html: "Select Clarity<br><br>투명도를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.COLOR)){
            this.$fire({
              html: "Choose a Color<br><br>컬러를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          this.$http.post(this.$host+'/RapaValue',{
                WEIGHT: this.editedItem.WEIGHT
              , SHAPE_IDX: this.editedItem.SHAPE_IDX
              , TRANSPARENCY: this.editedItem.TRANSPARENCY
              , COLOR: this.editedItem.COLOR
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const list = result.data.resultData;
                this.editedItem.RAPAVALUE = list[0].RAPAVALUE;
                this.$forceUpdate();

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      changepricetype(){
        if(this.editedItem.PRICE_CALCTYPE == 2){

          if(this.editedItem.PRODUCT_CLASS != 0 || this.editedItem.DIAMOND_CLASS != 0 || this.editedItem.DIAMONDTYPE != 0){
              this.$fire({
                html: "The RAPPORT price calculation method is not available for the selected type. Change to the direct input method.<br><br>선택한 유형에 대해 RAPAPORT 가격 계산 방법을 사용할 수 없습니다. 직접 입력 방법으로 변경합니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.editedItem.PRICE_CALCTYPE = 0;
          }

          if(this.editedItem.PRICE_TYPE == 1){
              this.$fire({
                html: "When using the RAPAPORT price calculation method, the price type is changed to USD.<br><br>RAPAPORT 가격계산 방법 사용시 가격 타입은 USD로 변경됩니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.editedItem.PRICE_TYPE    = 0;
          }

          this.editedItem.SUPPLY_PRICE = 0;
          
        }
      },

      showImage_pdf(){

        //console.log("uploadfile6  : " + JSON.stringify(this.uploadFile6));
        //console.log("previewImg6  : " + this.previewImg6);

        if(this.isNullCheck(this.uploadFile6)){
          
          //여긴원래 레퍼런스 값만 쓰고 저장시 기준값이다.          
          //console.log("case 1 APPRAISAL_IMG_REF : "  + this.editedItem.APPRAISAL_IMG_REF);
          
          //조회한 경우에는 해당 값이 있고
          //console.log("case 11 APPRAISAL_IMG : " + this.editedItem.APPRAISAL_IMG);

          //링크로 보여줘야함.
          
          this.$refs.pdfinfo.geturl(this.editedItem.APPRAISAL_IMG);
        }else{
          //console.log("case 2 uploadfile is not null");
          //파일로 보여줘야함.
          this.$refs.pdfinfo.getfile(this.uploadFile6);
        }

        //this.loadpdffile();

      },           

      loadpdffile(){

        
        //var typedarray = new Uint8Array(this.uploadFile6);
        //this.getPdffile(typedarray);

      },


      async getPdffile(typedarray) {

        //pdf 로드
        //let loadingTask = pdfjsLib.getDocument("https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf");

        //console.log("1111");
        let loadingTask = pdfjsLib.getDocument({data:typedarray});
        //console.log("2222");

        var pdf = null;
        try{
          
          pdf = await loadingTask.promise;

          //console.log("3333");
          this.pdfdoc = pdf;                //전역으로 객체 백업
          //console.log("4444");
          this.pageNumber = pdf.numPages;   //전체페이지 수 
          //console.log("5555");

          this.pdfpage = await pdf.getPage(1);  //페이지 읽기
          //console.log("page load...");
              
          this.viewport  = this.pdfpage.getViewport(1.0);  //페이지 뷰포트 정보 읽기 스케일값은 float로 들어가야한다. 정수로 들어가면 값이 깨짐.
          //console.log("viewport : " + JSON.stringify(this.viewport));

          //var the_canvas    = document.getElementById("the-canvas");
          var context       = this.the_canvas.getContext('2d');
          this.the_canvas.width  = this.viewport.width;
          this.the_canvas.height = this.viewport.height;

          this.canvas_width  = this.viewport.width;
          this.canvas_height = this.viewport.height;
              
          //var tf = [Math.cos(radian),Math.sin(radian),-Math.sin(radian),Math.cos(radian),0,0];
          //var tf = [1,0,0,1,0,0];
          var renderContext = {
            canvasContext:context
          , viewport:this.viewport
          };
              
          await this.pdfpage.render(renderContext).promise;   //그리기
          //console.log("page render...");
          
        }catch(error){
          if(error.name == 'PasswordException'){
            //this.$alert("PDF로드중 오류가 발생했습니다. " + error.name);
            this.$alert("패스워드가 걸려있는 PDF는 사용할 수 없습니다." + error.name);
            this.pdf_dialog = false;
          }else{
            this.$alert("PDF로드중 오류가 발생했습니다. " + error.name);
            this.pdf_dialog = false;
          }
        }

      },


      //pdf url 읽기
      async getPdfURL(pdfurl) {

        //pdf 로드
        let loadingTask = pdfjsLib.getDocument(pdfurl);
        var pdf = await loadingTask.promise;
        this.pdfdoc = pdf;                //전역으로 객체 백업
        this.pageNumber = pdf.numPages;   //전체페이지 수 

        this.pdfpage = await pdf.getPage(1);  //페이지 읽기
        //console.log("page load...");
            
        var viewport  = this.pdfpage.getViewport(1.0);  //페이지 뷰포트 정보 읽기 스케일값은 float로 들어가야한다. 정수로 들어가면 값이 깨짐.
        //console.log("viewport : " + JSON.stringify(viewport));
        var context   = this.the_canvas.getContext('2d');
        this.the_canvas.width  = viewport.width;
        this.the_canvas.height = viewport.height;
            
        this.canvas_width  = this.viewport.width;
        this.canvas_height = this.viewport.height;

        //var tf = [Math.cos(radian),Math.sin(radian),-Math.sin(radian),Math.cos(radian),0,0];
        //var tf = [1,0,0,1,0,0];
        var renderContext = {
          canvasContext:context
        , viewport:viewport
        };
            
        await this.pdfpage.render(renderContext).promise;   //그리기
        //console.log("page render...");

      },



    //금액 입력시 콤마 찍어주기 (소수점 포함)
    inputtext(event){
      this.editedItem.SUPPLY_PRICE =  this.valuecomma(event);
    },
    
    //금액 입력시 콤마 찍어주기 (소수점 포함)
    valuecomma(txt){
      var parts = txt.toString().split("."); 
      return String(parts[0]).replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },

    //get 파라메터 가져오기
    restorepage(){

      //페이지 및 검색조건 복원
      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }

      if(this.$route.query.row == undefined || this.$route.query.row == null){
        this.pagerow = 100;
      }else{
        this.pagerow = Number(this.$route.query.row);
      }
      
      if(this.$route.query.spc == undefined || this.$route.query.spc == null){
        this.SEARCH_PRODUCT_CLASS = -1;
      }else{
        this.SEARCH_PRODUCT_CLASS = Number(this.$route.query.spc);
      }

      if(this.$route.query.showdel == undefined || this.$route.query.showdel == null){
        this.SEARCH_DELETE_SHOW = false;
      }else{
        if(this.$route.query.showdel == "true"){
          this.SEARCH_DELETE_SHOW = true;
        }else{
          this.SEARCH_DELETE_SHOW = false;
        }
        
      }

      if(this.$route.query.sdc == undefined || this.$route.query.sdc == null){
        this.SEARCH_DIAMOND_CLASS = -1;
      }else{
        this.SEARCH_DIAMOND_CLASS = Number(this.$route.query.sdc);
      }
      
      if(this.$route.query.sgc == undefined || this.$route.query.sgc == null){
        this.SEARCH_GEMSTONE_CLASS = -1;
      }else{
        this.SEARCH_GEMSTONE_CLASS = Number(this.$route.query.sgc);
      }
      
      if(this.$route.query.sjt == undefined || this.$route.query.sjt == null){
        this.SEARCH_JEWELRY_TYPE = -1;
      }else{
        this.SEARCH_JEWELRY_TYPE = Number(this.$route.query.sjt);
      }

      if(this.$route.query.sdt == undefined || this.$route.query.sdt == null){
        this.SEARCH_DESIGN_TYPE = -1;
      }else{
        this.SEARCH_DESIGN_TYPE = Number(this.$route.query.sdt);
      }

      if(this.$route.query.sji == undefined || this.$route.query.sji == null){
        this.SEARCH_JEWELRY_ITEM = -1;
      }else{
        this.SEARCH_JEWELRY_ITEM = Number(this.$route.query.sji);
      }

      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.$refs.searchbar.setSearch(this.search_keyword);
      }

      
      if(this.sort_type == undefined || this.sort_type == null){
        this.sort_type = true;
      }else{
        if(this.$route.query.sorttype == "true"){
          this.sort_type = true;
        }else if(this.$route.query.sorttype == "false"){
          this.sort_type = false;
        }
      }

      this.sort_name = this.$route.query.sortname;
      if(this.sort_name == undefined || this.sort_name == null){
        this.sort_name = 'EDIT_DATE';
      }

    },


      changecombo_clear(){
        //검색조건 초기화
        this.clear_search_where();
        this.movepage(1);
      },

      changecombo(){

        //console.log("changecombo " + name);
        //this.loadpage(1);
        this.movepage(1);

      },

      newsavemargin(){
        if(this.selected.length == 0){
          this.$fire({
              html: "Please select the product you want to set.<br><br>설정하실 제품을 선택해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
          return;
        }
        this.margin_dialog = true;
      },

      newsaveevent(){
        if(this.selected.length == 0){
          this.$fire({
              html: "Please select the product you want to set.<br><br>설정하실 제품을 선택해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
          return;
        }
        this.event_dialog = true;
      },

      newsavesale(){
        if(this.selected.length == 0){
          this.$fire({
              html: "Please select the product you want to set.<br><br>설정하실 제품을 선택해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
          return;
        }
        this.sale_dialog = true;
      },

      newsavediaplay(){
        if(this.selected.length == 0){
          this.$fire({
              html: "Please select the product you want to set.<br><br>설정하실 제품을 선택해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
          return;
        }
        this.display_dialog = true;
      },


      selectdelete(){
        if(this.selected.length == 0){
          this.$fire({
              html: "Please select the product you want to delete.<br><br>삭제하실 제품을 선택해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
          return;
        }

        this.$fire({
          html: "Are you sure you want to delete the selected products?<br><br>선택하신 제품들을 정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Delete<br>삭제</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            //삭제 처리
            const idxs = [];
            for(var i=0;i<this.selected.length;i++){
              idxs.push(this.selected[i].PRODUCT_IDX);
            }

            this.loading = true;
            this.$http.post(this.$host+'/ProductDeleteUpdateMulti',{
              PRODUCT_IDX: idxs.join(",")
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Delete Complete<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    const p = this.page;
                    this.loadpage(p);
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });     

          }
        });
        
      },


      save_event(){
        const weightVal = /[^0-9.]/g;
        if(this.isNullCheck(this.TOTAL_SALETYPE)){
          this.$fire({
            html: "Please select a discount type<br><br>할인타입을 선택하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.TOTAL_SALETYPE == 1){
          //지속할인
          if(this.isNullCheck(this.TOTAL_SALERATE)){
            this.$fire({
              html: "Please enter the discount rate<br><br>할인율을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(weightVal.test(this.TOTAL_SALERATE)){
            this.$fire({
              html: "Only numbers and . can be entered for the discount rate.<br><br>할인율은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
        }else if(this.TOTAL_SALETYPE == 2){
          //기간 할인
          if(this.isNullCheck(this.TOTAL_SALERATE)){
            this.$fire({
              html: "Please enter the discount rate<br><br>할인율을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(weightVal.test(this.TOTAL_SALERATE)){
            this.$fire({
              html: "Only numbers and . can be entered for the discount rate.<br><br>할인율은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.TOTAL_SALESTART)){
            this.$fire({
              html: "Please enter a discount start date<br><br>할인 시작일을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.TOTAL_SALEEND)){
            this.$fire({
              html: "Please enter a discount end date<br><br>할인 종료일을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          const temp_start = new Date(this.TOTAL_SALESTART)
          const temp_end   = new Date(this.TOTAL_SALEEND)
          if(temp_start > temp_end){
            this.$fire({
              html: "The discount start date is greater than the discount end date.<br><br>할인 시작일이 할인 종료일보다 큽니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        const idxs = [];
        for(var i=0;i<this.selected.length;i++){
          idxs.push(this.selected[i].PRODUCT_IDX);
        }

          this.loading = true;
          this.$http.post(this.$host+'/ProductEventUpdate', {
              PRODUCT_IDX: idxs.join(",")
            , SALETYPE: this.TOTAL_SALETYPE
            , SALERATE: this.TOTAL_SALERATE
            , SALESTART: this.TOTAL_SALESTART == null || this.TOTAL_SALESTART == undefined ? null : escape(this.TOTAL_SALESTART)
            , SALEEND: this.TOTAL_SALEEND == null || this.TOTAL_SALEEND == undefined ? null : escape(this.TOTAL_SALEEND)
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.event_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.event_dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });        

      },
      
      save_margin(){
        //const weightVal = /[^0-9.]/g;
        const marginVal = /[^0-9.-]/g;
        
        if(this.isNullCheck(this.TOTAL_MARGIN)){
          this.$fire({
            html: "Please enter the margin rate<br><br>마진율을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
          
        if(marginVal.test(this.TOTAL_MARGIN)){
          this.$fire({
            html: "Only numbers and .- can be entered for the margin rate.<br><br>마진율은 숫자와 .-만 입력이 가능합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 


        const idxs = [];
        for(var i=0;i<this.selected.length;i++){
          idxs.push(this.selected[i].PRODUCT_IDX);
        }

          this.loading = true;
          this.$http.post(this.$host+'/ProductMarginUpdate', {
              PRODUCT_IDX: idxs.join(",")
            , PRODUCT_MARGIN: this.TOTAL_MARGIN
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.margin_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.margin_dialog = false;
            this.loading = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });

        });              

      },

      save_sale(){

        const idxs = [];
        for(var i=0;i<this.selected.length;i++){
          idxs.push(this.selected[i].PRODUCT_IDX);
        }

          this.loading = true;
          this.$http.post(this.$host+'/ProductSaleUpdate', {
              PRODUCT_IDX: idxs.join(",")
            , PRODUCT_STATE: this.TOTAL_PRODUCT_STATE
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.sale_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.sale_dialog = false;
            this.loading = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });

        });      

      },


      save_display(){

        const idxs = [];
        for(var i=0;i<this.selected.length;i++){
          idxs.push(this.selected[i].PRODUCT_IDX);
        }

        this.loading = true;
        this.$http.post(this.$host+'/ProductDisplayUpdate', {
              PRODUCT_IDX: idxs.join(",")
            , DISPLAY_STATE: this.TOTAL_DISPLAY_STATE
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.display_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.display_dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });        


      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }else if(index == 2){
          img = [this.previewImg3];
        }else if(index == 3){
          img = [this.previewImg4];
        }else if(index == 4){
          img = [this.previewImg5];
        }else if(index == 5){
          img = [this.previewImg6];
        }
        this.imageviewer = this.$viewerApi({
          images: img,
        })
      },      
 

      clearFile(index){
        if(index == 0){
          //메인 이미지가 없어지면 썸네일도 지워야 한다.
          this.previewImg1 = "";
          this.uploadFile1 = null;
          this.editedItem.PRODUCT_IMG1_REF = null;
          this.editedItem.THUM_IMG1_REF = null;
          123
        }else if(index == 1){
          this.previewImg2 = "";
          this.uploadFile2 = null;
          this.editedItem.PRODUCT_IMG2_REF = null;
        }else if(index == 2){
          this.previewImg3 = "";
          this.uploadFile3 = null;
          this.editedItem.PRODUCT_IMG3_REF = null;
        }else if(index == 3){
          this.previewImg4 = "";
          this.uploadFile4 = null;
          this.editedItem.PRODUCT_IMG4_REF = null;
        }else if(index == 4){
          this.previewImg5 = "";
          this.uploadFile5 = null;
          this.editedItem.PRODUCT_IMG5_REF = null;
        }else if(index == 5){
          this.previewImg6 = "";
          this.uploadFile6 = null;
          this.editedItem.APPRAISAL_IMG_REF = null;
        }
      },

      linkadd(index){
        this.LINKADDR = "";
        if(index == 6){
          this.LINKADDR = this.editedItem.MOVIE_PATH;
        }
        this.link_index = index;
        this.link_dialog = true;
      },

      deletecerti(){

        this.$fire({
          html: "Are you sure you want to delete the Certificate image? When saved, it is finally deleted.<br><br>감정서 이미지를 삭제하시겠습니까? 저장하면 최종 삭제됩니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.editedItem.APPRAISAL_IMG = "";
            this.editedItem.APPRAISAL_IMG_REF = "";
            this.previewImg6 = "";
            this.uploadFile6 = null;
            this.editedItem.PRODUCT_IMG6_REF = null;
          }
        });

      },

      deletevideo(){

        this.$fire({
          html: "Are you sure you want to delete the video? When saved, it is finally deleted.<br><br>동영상을 삭제하시겠습니까? 저장하면 최종 삭제됩니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.editedItem.MOVIE_PATH = "";
            this.editedItem.MOVIE_PATH_REF = "";
          }
        });

      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },

      fileSelect_pdf(index){
        const changefunction    = this.changefile;
        const changepdffunction = this.changefile_pdf;
        const updatefunction    = this.setPdfPreview;

        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png,.pdf"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction();
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }else{

          input.onchange = function (event) {

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction();
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }
        input.click();
      },      


      setPdfPreview(){


        this.previewImg6 = this.pdfPreviewImg;

        
      },

      
      getParam(url, name) {
        var params = url.substr(url.indexOf("?") + 1);
        var sval = "";
        params = params.split("&");
        var temp = [];
        for (var i = 0; i < params.length; i++) {
          temp = params[i].split("=");
          if ([temp[0]] == name) { sval = temp[1]; }
        }
        return sval;
      },      

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.$router.push({path:"/AdminProduct", query:{page:this.page, row:this.pagerow, spc:this.SEARCH_PRODUCT_CLASS, sdc:this.SEARCH_DIAMOND_CLASS, sgc:this.SEARCH_GEMSTONE_CLASS, sjt:this.SEARCH_JEWELRY_TYPE,sji:this.SEARCH_JEWELRY_ITEM, sdt:this.SEARCH_DESIGN_TYPE, search:this.search_keyword,sorttype:this.sort_type,sortname:this.sort_name, showdel:this.SEARCH_DELETE_SHOW}}); //.catch(()=>{});
        //this.loadpage(this.page);
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        //console.log("move page : " + p);
        //console.log("this.SEARCH_PRODUCT_CLASS : " + this.SEARCH_PRODUCT_CLASS);
        this.$router.push({path:"/AdminProduct", query:{page:p, row:this.pagerow, spc:this.SEARCH_PRODUCT_CLASS, sdc:this.SEARCH_DIAMOND_CLASS, sgc:this.SEARCH_GEMSTONE_CLASS, sjt:this.SEARCH_JEWELRY_TYPE,sji:this.SEARCH_JEWELRY_ITEM, sdt:this.SEARCH_DESIGN_TYPE, search:this.search_keyword,sorttype:this.sort_type,sortname:this.sort_name, showdel:this.SEARCH_DELETE_SHOW}}).catch(()=>{
          this.loadpage(1);
        });
        //this.loadpage(p);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          //console.log("loadpage : " + this.page);

          this.$http.post(this.$host+'/ProductList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type == true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , PRODUCT_CLASS : this.SEARCH_PRODUCT_CLASS
              , SEARCH_DIAMOND_CLASS : this.SEARCH_DIAMOND_CLASS
              , SEARCH_GEMSTONE_CLASS : this.SEARCH_GEMSTONE_CLASS
              , SEARCH_JEWELRY_TYPE : this.SEARCH_JEWELRY_TYPE
              , SEARCH_JEWELRY_ITEM : this.SEARCH_JEWELRY_ITEM
              , SEARCH_DESIGN_TYPE : this.SEARCH_DESIGN_TYPE
              , SEARCH_DELETE_SHOW : this.SEARCH_DELETE_SHOW

              , search_min_price: this.search_min_price != null && parseInt(this.search_min_price) > 0 ? this.search_min_price.toString() : null
              , search_max_price: this.search_max_price != null && parseInt(this.search_max_price) > 0 ? this.search_max_price.toString() : null
              , search_min_price2: this.search_min_price2 != null && parseInt(this.search_min_price2) > 0 ? this.search_min_price2.toString() : null
              , search_max_price2: this.search_max_price2 != null && parseInt(this.search_max_price2) > 0 ? this.search_max_price2.toString() : null
              , search_shape: this.search_shape
              , search_color: this.search_color
              , search_transparency: this.search_transparency
              , search_grind_total: this.search_grind_total
              , search_grind_polish: this.search_grind_polish
              , search_grind_syme: this.search_grind_syme
              , search_fluorescence: this.search_fluorescence
              , search_diamondcolor: this.search_diamondcolor
              , search_diamondtype: this.search_diamondtype
              , search_gemstone_type: this.search_gemstone_type
              , search_min_weight: this.search_min_weight
              , search_max_weight: this.search_max_weight
              , search_gem_min_weight: this.search_gem_min_weight
              , search_gem_max_weight: this.search_gem_max_weight
              , search_jewely_metalweight: this.search_jewely_metalweight
              , search_jewely_metaltype: this.search_jewely_metaltype
              , search_jewely_item: this.search_jewely_item

              , search_jw_min_weight: this.search_jw_min_weight
              , search_jw_max_weight: this.search_jw_max_weight
              , search_jw_min_gram: this.search_jw_min_gram
              , search_jw_max_gram: this.search_jw_max_gram
              , search_jw_name: this.search_jw_name == null || this.search_jw_name == undefined ?  '' : escape(this.search_jw_name)

              , search_ds_min_weight: this.search_ds_min_weight
              , search_ds_max_weight: this.search_ds_max_weight
              , search_ds_metalweight: this.search_ds_metalweight
              , search_ds_metaltype: this.search_ds_metaltype
              

              , search_certi: this.search_certi
              , search_gem_certi: this.search_gem_certi
              
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                
                this.dia1_cnt = parseInt(result.data.totalData[0].DIA1);
                this.dia2_cnt = parseInt(result.data.totalData[0].DIA2);
                this.gem_cnt = parseInt(result.data.totalData[0].GEM);
                this.jew_cnt = parseInt(result.data.totalData[0].JEW);
                this.craft_cnt = parseInt(result.data.totalData[0].CRAFT);

                this.allcheck = 0;
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                
               
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
            }


          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        
        this.SEARCH_PRODUCT_CLASS = this.$route.query.code;
        var tempclsss = this.$session.get("PRODUCT_CLASS");
        this.$session.set("PRODUCT_CLASS", this.SEARCH_PRODUCT_CLASS);
        if(this.SEARCH_PRODUCT_CLASS == tempclsss){
          //검색조건 복원
          this.loadSearchOption();
        }else{
          //검색조건 초기화
          this.clear_search_where();
        }

        //타입 가져오기 및 검색조건 초기화
        let typeload = this.$route.query.rtype;
        if(typeload == undefined || typeload == null){
          typeload = 1;
        }
        if(typeload == 1){
          //타입가져오기
          this.getTypeList();
        }else{
          this.DIASHAPELIST = this.$session.get("DIASHAPELIST");
          this.DIACERTILIST = this.$session.get("DIACERTILIST");
          this.JEWELRYKINDLIST = this.$session.get("JEWELRYKINDLIST");
          this.GEMCERTILIST = this.$session.get("GEMCERTILIST");
          this.PRODUCTTYPELIST = this.$session.get("PRODUCTTYPELIST");
          this.PRODUCTITEMLIST = this.$session.get("PRODUCTITEMLIST");
        }        

        //get 파라메터 로드
        this.restorepage();
        
        //this.getTypeList();
        
        //첫페이지 로드
        this.loadpage(this.page);

      },      



      //타입 가져오기
      getTypeList(){

        this.DIASHAPELIST.splice(0, this.DIASHAPELIST.length); 
        this.DIACERTILIST.splice(0, this.DIACERTILIST.length); 
        this.JEWELRYKINDLIST.splice(0, this.JEWELRYKINDLIST.length); 
        this.GEMCERTILIST.splice(0, this.GEMCERTILIST.length); 
        this.PRODUCTTYPELIST.splice(0, this.PRODUCTTYPELIST.length); 
        this.PRODUCTITEMLISTALL.splice(0, this.PRODUCTITEMLISTALL.length); 
        this.PRODUCTITEMLIST.splice(0, this.PRODUCTITEMLIST.length); 
        this.JEWCERTILIST.splice(0, this.JEWCERTILIST.length); 
        this.GEMSHAPELIST.splice(0, this.GEMSHAPELIST.length); 
        
        this.GEMSEARCHTYPELIST.splice(0, this.GEMSEARCHTYPELIST.length);
        this.GEMSEARCHTYPELIST.push({"NAME_ENG":"ALL/전체", IDX:-1});

        this.JEWELRYSEARCHTYPELIST.splice(0, this.JEWELRYSEARCHTYPELIST.length);
        this.JEWELRYSEARCHTYPELIST.push({"NAME_ENG":"ALL/전체", IDX:-1});
        this.JEWELRYSEARCHITEMLIST.splice(0, this.JEWELRYSEARCHITEMLIST.length);
        this.JEWELRYSEARCHITEMLIST.push({"NAME":"ALL/전체", IDX:-1});

        this.$session.remove("DIASHAPELIST");
        this.$session.remove("DIACERTILIST");
        this.$session.remove("JEWELRYKINDLIST");
        this.$session.remove("GEMCERTILIST");
        this.$session.remove("PRODUCTTYPELIST");
        this.$session.remove("PRODUCTITEMLIST");

        this.$http.post(this.$host+'/GuestTotalTypeList',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              
              const list1 = result.data.DiamondShape;
              list1.forEach(element => {
                  this.DIASHAPELIST.push(element); 
              });
              const list2 = result.data.DiamondCerti;
              list2.forEach(element => {
                  this.DIACERTILIST.push(element); 
              });
              const list3 = result.data.GemstoneType;
              list3.forEach(element => {
                  this.JEWELRYKINDLIST.push(element); 
                  this.GEMSEARCHTYPELIST.push(element); 
              });
              const list4 = result.data.GemstoneCerti;
              list4.forEach(element => {
                  this.GEMCERTILIST.push(element); 
              });
              const list5 = result.data.JewelryType;
              list5.forEach(element => {
                  this.PRODUCTTYPELIST.push(element); 
                  this.SELECTTITEMMAP[element.IDX] = [];
                  this.JEWELRYSEARCHTYPELIST.push(element); 
              });
              const list6 = result.data.JewelryItem;
              list6.forEach(element => {
                  this.PRODUCTITEMLISTALL.push(element); 
              });

              this.setJewelyItem(this.PRODUCTTYPELIST[0].IDX);
              this.setJewelySearchItem(this.SEARCH_JEWELRY_TYPE);

              const list7 = result.data.JewelryCerti;
              list7.forEach(element => {
                  this.JEWCERTILIST.push(element); 
              });

              const list8 = result.data.GemstoneShape;
              list8.forEach(element => {
                  this.GEMSHAPELIST.push(element); 
              });

              //스토리지 저장
              this.$session.set("DIASHAPELIST", this.DIASHAPELIST);
              this.$session.set("DIACERTILIST", this.DIACERTILIST);
              this.$session.set("JEWELRYKINDLIST", this.JEWELRYKINDLIST);
              this.$session.set("GEMCERTILIST", this.GEMCERTILIST);
              this.$session.set("PRODUCTTYPELIST", this.PRODUCTTYPELIST);
              this.$session.set("PRODUCTITEMLIST", this.PRODUCTITEMLIST);
              
          }else if(result.data.resultCode == 2){
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },          

      closesort() {
        this.sort_dialog     = false
        setTimeout(() => {
          this.sortItem = Object.assign({}, this.defaultsortItem);
        }, 300)
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem);
          this.editedIndex = -1
        }, 300)
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //링크 저장
      save_link(){
        const urlVal = /https?:\/\//;
        const checklink = cmvalidation.checkValReg(this.LINKADDR, urlVal, -1, 255, false);
        if(checklink == 1){
          this.$fire({
            html: "Please enter the Link URL.<br><br>Link URL을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 2){
          this.$fire({
            html: "Link URL must start with http or https.<br><br>Link URL은 http 또는 https로 시작해야 합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 4){
          this.$fire({
            html: "Link URL the maximum input limit.<br><br>Link URL이 최대 입력 제한을 초과합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }              
        
        if(this.link_index == 0){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg1                  = this.LINKADDR;
            this.editedItem.PRODUCT_IMG1_REF  = this.LINKADDR;
            this.editedItem.THUM_IMG1_REF     = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .jpeg, .png<br><br>URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
        }else if(this.link_index == 1){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg2                  = this.LINKADDR;
            this.editedItem.PRODUCT_IMG2_REF  = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .jpeg, .png<br><br>URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
        }else if(this.link_index == 2){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg3                  = this.LINKADDR;
            this.editedItem.PRODUCT_IMG3_REF  = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .jpeg, .png<br><br>URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
        }else if(this.link_index == 3){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg4                  = this.LINKADDR;
            this.editedItem.PRODUCT_IMG4_REF  = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .jpeg, .png<br><br>URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
        }else if(this.link_index == 4){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg5                  = this.LINKADDR;
            this.editedItem.PRODUCT_IMG5_REF  = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .jpeg, .png<br><br>URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
        }else if(this.link_index == 5){
          //인증서 
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
            //pdf면 분기처리
            this.previewImg6                  = this.pdfPreviewImg;
          }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg6                  = this.LINKADDR;
          }else{
            //양식에 어긋나는 pdf 주소인 경우
            this.previewImg6                  = this.LINKADDR;
          }
          //양식에 어긋나도 저장되게 한다. kdge 요청
          this.editedItem.APPRAISAL_IMG_REF   = this.LINKADDR;
          this.editedItem.APPRAISAL_IMG       = this.LINKADDR;

        }else if(this.link_index == 6){
          /* kdge요청으로 체크하지 않기로 함.
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'mp4'){
            this.editedItem.MOVIE_PATH     = this.LINKADDR;
            this.editedItem.MOVIE_PATH_REF = this.LINKADDR;
          }
          */
          this.editedItem.MOVIE_PATH     = this.LINKADDR;
          this.editedItem.MOVIE_PATH_REF = this.LINKADDR;
          
        }

        this.link_dialog = false;

      },

      //주얼리 유형에 따른 아이템 리스트 가져오기
      setJewelyItem(idx){
        if(idx == null || idx == undefined || idx.length < 1) return;
        this.PRODUCTITEMLIST.splice(0, this.PRODUCTITEMLIST.length);
        for(var i=0;i<this.PRODUCTITEMLISTALL.length;i++){
          var item = this.PRODUCTITEMLISTALL[i];
          if(item.PRODUCTTYPE_IDX == idx){
            this.PRODUCTITEMLIST.push(item);
          }
        }
      },


      //주얼리 유형에 따른 아이템 리스트 가져오기
      setJewelySearchItem(idx){

        //console.log(JSON.stringify(this.PRODUCTITEMLISTALL));
        this.JEWELRYSEARCHITEMLIST.splice(0, this.JEWELRYSEARCHITEMLIST.length);
        this.JEWELRYSEARCHITEMLIST.push({"NAME":"ALL/전체", IDX:-1});

        for(var i=0;i<this.PRODUCTITEMLISTALL.length;i++){
          var item = this.PRODUCTITEMLISTALL[i];
          if(item.PRODUCTTYPE_IDX == idx){
            this.JEWELRYSEARCHITEMLIST.push(item);
          }
        }
        
        //console.log("setJewelySearchItem : " + this.SEARCH_JEWELRY_ITEM);
        if(this.loadJewelry){
          this.loadJewelry = false;
        }else{
          this.SEARCH_JEWELRY_ITEM = -1;
          this.loadpage(1);
        }

      },      

      getJewelyItem(idx){
        const jlist = [];
        for(var i=0;i<this.PRODUCTITEMLISTALL.length;i++){
          var item = this.PRODUCTITEMLISTALL[i];
          if(item.PRODUCTTYPE_IDX == idx){
            jlist.push(item);
          }
        }
        return jlist;
      },

      //추가 저장
      async save () {
        
        const weightVal = /[^0-9.]/g;
        const marinVal = /[^0-9.-]/g;

        //인설트인 경우만 어카운트 필요
        let accnt_idx = this.$session.get("ACCNT_IDX");
        if (this.editedIndex > -1) {
        }else{
          if(this.grade > 90){
            if(this.SEARCH_ACCNT_IDX == null || this.SEARCH_ACCNT_IDX == undefined){
              this.$fire({
                html: "Please select a seller.<br><br>판매자를 선택하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            accnt_idx = this.SEARCH_ACCNT_IDX;
          }
        }


        //공통 파라메터
        const checkproductnumber = cmvalidation.checkValReg(this.editedItem.PRODUCT_NUMBER, null, 8, 25);
        if(checkproductnumber == 1){
          this.$fire({
            html: "Please enter the product name.<br><br>상품명을 입력하세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkproductnumber == 3){
          this.$fire({
            html: "Please Enter product name in 8 characters or then<br><br>상품명은 8자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkproductnumber == 4){
          this.$fire({
            html: "Please Enter product name in 25 characters or less<br><br>상품명은 25자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.isNullCheck(this.editedItem.SUPPLY_PRICE)){
          this.$fire({
            html: "Please enter a supply price<br><br>공급가격을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(weightVal.test( String(this.editedItem.SUPPLY_PRICE).replaceAll(",",""))){
          this.$fire({
            html: "Only numbers and . can be entered for the supply price.<br><br>공급가격은 숫자와 .만 입력이 가능합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(this.isNullCheck(this.editedItem.PRODUCT_MARGIN)){
          this.$fire({
            html: "Enter product margin<br><br>상품 마진을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(marinVal.test(this.editedItem.PRODUCT_MARGIN)){
          this.$fire({
            html: "Only numbers and . are allowed for product margins.<br><br>상품 마진은 숫자와 .만 입력이 가능합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(this.editedItem.PRODUCT_MARGIN > 1){
          this.$fire({
            html: "Product margin must be less than 1.<br><br>상품 마진은 1보다 작아야합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        

        //다이아몬드 값 검증
        if(this.editedItem.PRODUCT_CLASS == 0){
          
          if(this.isNullCheck(this.editedItem.CERTI_IDX)){
            this.$fire({
              html: "Select an laboratory<br><br>감정기관을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.DIAMOND_CLASS)){
            this.$fire({
              html: "Select Diamond Class<br><br>다이아몬드 구분을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.DIAMONDTYPE)){
            this.$fire({
              html: "Select Diamond Type<br><br>다이아몬드 타입을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          const check = cmvalidation.checkValReg(this.editedItem.WEIGHT, weightVal, -1, -1);
          if(check == 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 2){
            this.$fire({
              html: "Weight can only be entered with numbers and .<br><br>중량은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(String(this.editedItem.WEIGHT).replaceAll('.','').length < 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          if(this.isNullCheck(this.editedItem.SHAPE_IDX)){
            this.$fire({
              html: "Choose a shape<br><br>모양을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          const check_width = cmvalidation.checkValReg(this.editedItem.WIDTH, weightVal, -1, -1);
          if(check_width == 1){
            this.$fire({
              html: "Enter Width of product.<br><br>제품의 Width를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_width == 2){
            this.$fire({
              html: "Width can only be entered with numbers and .<br><br>넓이는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check_depth = cmvalidation.checkValReg(this.editedItem.DEPTH, weightVal, -1, -1);
          if(check_depth == 1){
            this.$fire({
              html: "Enter Depth of product.<br><br>제품의 Depth를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_depth == 2){
            this.$fire({
              html: "Depth can only be entered with numbers and .<br><br>깊이는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check_height = cmvalidation.checkValReg(this.editedItem.HEIGHT, weightVal, -1, -1);
          if(check_height == 1){
            this.$fire({
              html: "Enter Length of product.<br><br>제품의 Length를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_height == 2){
            this.$fire({
              html: "Length can only be entered with numbers and .<br><br>Length는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          if(this.isNullCheck(this.editedItem.POLISH)){
            this.$fire({
              html: "Select Policy<br><br>폴리쉬를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.SYMMETRY)){
            this.$fire({
              html: "Select Symmetry<br><br>대칭을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.TRANSPARENCY)){
            this.$fire({
              html: "Select Clarity<br><br>투명도를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.FLUORESCENCE)){
            this.$fire({
              html: "Select Fluorescence<br><br>형광성을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.editedItem.DIAMOND_CLASS == 0){
            //일반 다이아몬드
            if(this.isNullCheck(this.editedItem.COLOR)){
              this.$fire({
                html: "Choose a Color<br><br>컬러를 선택하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            } 
            if(this.isNullCheck(this.editedItem.EVALUATION)){
              this.$fire({
                html: "Select a cut<br><br>컷팅을 선택하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            } 
          }else{
            //펜시 다이아몬드
            if(this.isNullCheck(this.editedItem.DIAMONDCOLOR)){
              this.$fire({
                html: "Select Colored Diamonds<br><br>컬러 다이아몬드를 선택하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            } 

            const check_colordistri = cmvalidation.checkValReg(this.editedItem.COLORDISTRI, null, -1, 25);
            if(check_colordistri == 1){
              this.$fire({
                html: "Please Enter Color Distribution<br><br>색분포를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }else if(check_colordistri == 4){
              this.$fire({
                html: "You can enter up to 25 color distributions.<br><br>색분포는 최대 25까지 입력할 수 있습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }

            if(this.isNullCheck(this.editedItem.COLORGRADE)){
              this.$fire({
                html: "Select Color Grade<br><br>컬러 그레이드를 선택하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            } 

          }

        }


        //젬스톤 값 검증
        if(this.editedItem.PRODUCT_CLASS == 1){

          if(this.isNullCheck(this.editedItem.CERTI_IDX)){
            this.$fire({
              html: "Select an laboratory<br><br>감정기관을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.JEWELRYKIND_IDX)){
            this.$fire({
              html: "Please select a jewel type<br><br>보석종류 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          if(this.isNullCheck(this.editedItem.SHAPE_IDX)){
            this.$fire({
              html: "Choose a shape<br><br>모양을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 

          const check_width = cmvalidation.checkValReg(this.editedItem.WIDTH, weightVal, -1, -1);
          if(check_width == 1){
            this.$fire({
              html: "Enter Width of product.<br><br>제품의 Width를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_width == 2){
            this.$fire({
              html: "Width can only be entered with numbers and .<br><br>넓이는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check_depth = cmvalidation.checkValReg(this.editedItem.DEPTH, weightVal, -1, -1);
          if(check_depth == 1){
            this.$fire({
              html: "Enter Depth of product.<br><br>제품의 Depth를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_depth == 2){
            this.$fire({
              html: "Depth can only be entered with numbers and .<br><br>깊이는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check_height = cmvalidation.checkValReg(this.editedItem.HEIGHT, weightVal, -1, -1);
          if(check_height == 1){
            this.$fire({
              html: "Enter Length of product.<br><br>제품의 Length를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_height == 2){
            this.$fire({
              html: "Length can only be entered with numbers and .<br><br>Length는 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          

          const check_identifi = cmvalidation.checkValReg(this.editedItem.IDENTIFICATION, null, -1, 25);
          if(check_identifi == 1){
            this.$fire({
              html: "Please Enter Identification<br><br>식별값을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_identifi == 4){
            this.$fire({
              html: "You can enter up to 25 Identification.<br><br>식별값은 최대 25까지 입력할 수 있습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check_gemcolor = cmvalidation.checkValReg(this.editedItem.GEMSTONECOLOR, null, -1, 25);
          if(check_gemcolor == 1){
            this.$fire({
              html: "Please Enter Color<br><br>컬러를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check_gemcolor == 4){
            this.$fire({
              html: "You can enter up to 25 color.<br><br>컬러는 최대 25까지 입력할 수 있습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

        }


        //주얼리 값 검증
        if(this.editedItem.PRODUCT_CLASS == 2){

          if(this.isNullCheck(this.editedItem.PRODUCTTYPE_IDX)){
            this.$fire({
              html: "Please select a type<br><br>유형을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.PRODUCTITEM_IDX)){
            this.$fire({
              html: "Please select an item<br><br>품목을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.METALWEIGHT)){
            this.$fire({
              html: "Please select the Metal Content<br><br>금속함량을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.METALTYPE)){
            this.$fire({
              html: "Please select the metal type<br><br>금속종류를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.DIAMONDTYPE)){
            this.$fire({
              html: "Select Gem Name<br><br>보석명을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          const check = cmvalidation.checkValReg(this.editedItem.WEIGHT, weightVal, -1, -1);
          if(check == 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 2){
            this.$fire({
              html: "Weight can only be entered with numbers and .<br><br>중량은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          
          if(String(this.editedItem.WEIGHT).replaceAll('.','').length < 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


          if(!this.isNullCheck(this.editedItem.MAINWEIGHT)){
            const check_mainweight = cmvalidation.checkValReg(this.editedItem.MAINWEIGHT, weightVal, -1, -1);
            if(check_mainweight == 2){
              this.$fire({
                html: "Main Stone Weight can only be entered with numbers and .<br><br>메인스톤 중량은 숫자와 .만 입력이 가능합니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }          
            if(String(this.editedItem.MAINWEIGHT).replaceAll('.','').length < 1){
              this.$fire({
                html: "Please enter the Main Stone weight.<br><br>메인스톤 중량을 입력하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
          }
          if(!this.isNullCheck(this.editedItem.SUBWEIGHT)){
            const check_subweight = cmvalidation.checkValReg(this.editedItem.SUBWEIGHT, weightVal, -1, -1);
            if(check_subweight == 2){
              this.$fire({
                html: "Sub Stone Weight can only be entered with numbers and .<br><br>서브스톤 중량은 숫자와 .만 입력이 가능합니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }          
            if(String(this.editedItem.SUBWEIGHT).replaceAll('.','').length < 1){
              this.$fire({
                html: "Please enter the Sub Stone weight.<br><br>서브스톤 중량을 입력하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
          }

        }

        //세일관련 정보
        if(this.isNullCheck(this.editedItem.SALETYPE)){
          this.$fire({
            html: "Please select a discount type<br><br>할인타입을 선택하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        if(this.editedItem.SALETYPE == 1){
          //지속할인
          if(this.isNullCheck(this.editedItem.SALERATE)){
            this.$fire({
              html: "Please enter the discount rate<br><br>할인율을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(weightVal.test(this.editedItem.SALERATE)){
            this.$fire({
              html: "Only numbers and . can be entered for the discount rate.<br><br>할인율은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
        }else if(this.editedItem.SALETYPE == 2){
          //기간 할인
          if(this.isNullCheck(this.editedItem.SALERATE)){
            this.$fire({
              html: "Please enter the discount rate<br><br>할인율을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(weightVal.test(this.editedItem.SALERATE)){
            this.$fire({
              html: "Only numbers and . can be entered for the discount rate.<br><br>할인율은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.SALESTART)){
            this.$fire({
              html: "Please enter a discount start date<br><br>할인 시작일을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.isNullCheck(this.editedItem.SALEEND)){
            this.$fire({
              html: "Please enter a discount end date<br><br>할인 종료일을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          const temp_start = new Date(this.editedItem.SALESTART)
          const temp_end   = new Date(this.editedItem.SALEEND)
          if(temp_start > temp_end){
            this.$fire({
              html: "The discount start date is greater than the discount end date.<br><br>할인 시작일이 할인 종료일보다 큽니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }
        
        

        //감정서 이미지 검증 - 주얼리는 패스
        if(this.editedItem.PRODUCT_CLASS < 2){
          if(this.isNullCheck(this.uploadFile6)){
              if(this.isNullCheck(this.editedItem.APPRAISAL_IMG_REF)){
                this.progress_dialog = false;
                //기존에 등록된 이미지가 있으면 패스
                this.$fire({
                  html: "Register certification image<br><br>감정서 이미지를 등록하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          }
        }
        


        
        this.progress_dialog = true;
    
        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){

          if(this.editedItem.PRODUCT_CLASS != 0){ //다이아몬드는 기본으로 등록 안해도 자동으로 기본 이미지 나옴.

            if(this.isNullCheck(this.editedItem.PRODUCT_IMG1_REF)){
              this.progress_dialog = false;
              //기존에 등록된 이미지가 있으면 패스
              this.$fire({
                html: "Register representative image<br><br>대표 이미지를 등록하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
          }

        }else{

            //대표 썸네일 등록
            if(this.uploadFileThum1){
              
              let upload_thum_cb1 = await this.uploadStorage("product", accnt_idx+"/thum_"+Date.now().toString()+".jpg", this.uploadFileThum1);
              if(upload_thum_cb1 == null){
                
                this.progress_dialog = false;
                this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              } 
              this.editedItem.THUM_IMG1 = upload_thum_cb1.resultRef;
              this.uploadFileThum1 = [];

              if(!this.isNullCheck(this.editedItem.THUM_IMG1_REF)){
                try{
                  await this.deletefile(this.editedItem.THUM_IMG1_REF);
                }catch(e){
                }
              }
              this.editedItem.THUM_IMG1_REF = upload_thum_cb1.resultRef;

            }

            
            //대표 이미지 등록
            let upload_cb1 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            this.uploadFile1 = [];
            
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG1_REF)){
                try{
                  await this.deletefile(this.editedItem.PRODUCT_IMG1_REF);
                }catch(e){
                }
            }

            this.editedItem.PRODUCT_IMG1_REF = upload_cb1.resultRef;

        }
        

        if(!this.isNullCheck(this.uploadFile2)){

            //대표 이미지 등록
            let upload_cb2 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile2);
            if(upload_cb2 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 

            this.uploadFile2 = [];
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG2_REF)){
                try{
                  await this.deletefile(this.editedItem.PRODUCT_IMG2_REF);
                }catch(e){
                }
            }
            this.editedItem.PRODUCT_IMG2_REF = upload_cb2.resultRef;

        }

        if(!this.isNullCheck(this.uploadFile6)){

            var uploadpath = accnt_idx+"/"+Date.now().toString()+".jpg";
            if(this.previewImg6 == this.pdfPreviewImg || this.previewImg6.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath = accnt_idx+"/"+Date.now().toString()+".pdf";
            }

            //대표 이미지 등록
            let upload_cb6 = await this.uploadStorage("product", uploadpath, this.uploadFile6);
            if(upload_cb6 == null){
              
              this.progress_dialog = false;
              this.$fire({
                  html: "certificate registration error occurred<br><br>인증서 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            
            this.uploadFile6 = [];

            if(!this.isNullCheck(this.editedItem.APPRAISAL_IMG_REF)){
                try{
                  await this.deletefile(this.editedItem.APPRAISAL_IMG_REF);
                }catch(e){
                }
            }

            this.editedItem.APPRAISAL_IMG_REF = upload_cb6.resultRef;

        }                

        if(!this.isNullCheck(this.uploadMovieFile)){

            //대표 동영상 등록
            let upload_cb7 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".mp4", this.uploadMovieFile);
            if(upload_cb7 == null){
              this.progress_dialog = false;
              this.$fire({
                html: "Video registration error occurred<br><br>동영상 등록 오류 발생",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            } 
            
            this.uploadMovieFile = [];

            if(!this.isNullCheck(this.editedItem.MOVIE_PATH_REF)){
                try{
                  await this.deletefile(this.editedItem.MOVIE_PATH_REF);
                }catch(e){
                }
            }

            this.editedItem.MOVIE_PATH_REF = upload_cb7.resultRef;

        }              
        
        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/ProductUpdate', {
              PRODUCT_IDX: this.editedItem.PRODUCT_IDX
            , PRODUCT_CLASS: this.editedItem.PRODUCT_CLASS
            , PRODUCT_NUMBER: escape(this.editedItem.PRODUCT_NUMBER)
            , PRODUCT_STOCK: this.editedItem.PRODUCT_STOCK == null || this.editedItem.PRODUCT_STOCK == undefined ? '' : escape(this.editedItem.PRODUCT_STOCK)
            , THUM_IMG1: this.editedItem.THUM_IMG1_REF == null || this.editedItem.THUM_IMG1_REF == undefined ? null : escape(this.editedItem.THUM_IMG1_REF)
            , PRODUCT_IMG1: this.editedItem.PRODUCT_IMG1_REF == null || this.editedItem.PRODUCT_IMG1_REF == undefined ? null : escape(this.editedItem.PRODUCT_IMG1_REF)
            , PRODUCT_IMG2: this.editedItem.PRODUCT_IMG2_REF == null || this.editedItem.PRODUCT_IMG2_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG2_REF)
            , APPRAISAL_IMG: this.editedItem.APPRAISAL_IMG_REF == null || this.editedItem.APPRAISAL_IMG_REF == undefined ? '' : escape(this.editedItem.APPRAISAL_IMG_REF)
            , MOVIE_PATH: this.editedItem.MOVIE_PATH_REF == null || this.editedItem.MOVIE_PATH_REF == undefined ? '' : escape(this.editedItem.MOVIE_PATH_REF)
            , WEIGHT: this.editedItem.WEIGHT
            , CERTI_IDX: this.editedItem.CERTI_IDX
            , SHAPE_IDX: this.editedItem.SHAPE_IDX
            , COLOR: this.editedItem.COLOR
            , TRANSPARENCY: this.editedItem.TRANSPARENCY
            , EVALUATION: this.editedItem.EVALUATION
            , POLISH: this.editedItem.POLISH
            , SYMMETRY: this.editedItem.SYMMETRY
            , DIAMONDCOLOR: this.editedItem.DIAMONDCOLOR
            , FLUORESCENCE: this.editedItem.FLUORESCENCE
            , DIAMONDTYPE: this.editedItem.DIAMONDTYPE
            , SUPPLY_PRICE: String(this.editedItem.SUPPLY_PRICE).replaceAll(",","")
            , PRODUCT_MARGIN: this.editedItem.PRODUCT_MARGIN
            , PRICE_DC: this.editedItem.PRICE_DC
            , PRICE_CALCTYPE: this.editedItem.PRICE_CALCTYPE
            , JEWELRYKIND_IDX: this.editedItem.JEWELRYKIND_IDX

            //주얼리
            , PRODUCTTYPE_IDX: this.editedItem.PRODUCTTYPE_IDX
            , PRODUCTITEM_IDX: this.editedItem.PRODUCTITEM_IDX
            , METALWEIGHT: this.editedItem.METALWEIGHT
            , METALTYPE: this.editedItem.METALTYPE

            , MAINSTONE: escape(this.editedItem.MAINSTONE)
            , SUBSTONE: escape(this.editedItem.SUBSTONE) 
            
            , PRICE_TYPE: this.editedItem.PRICE_TYPE
            , DIAMOND_CLASS: this.editedItem.DIAMOND_CLASS
            , WIDTH: this.editedItem.WIDTH
            , DEPTH: this.editedItem.DEPTH
            , HEIGHT: this.editedItem.HEIGHT
            , COLORDISTRI: this.editedItem.COLORDISTRI == null || this.editedItem.COLORDISTRI == undefined ? '' : escape(this.editedItem.COLORDISTRI)
            , COLORGRADE: this.editedItem.COLORGRADE
            , IDENTIFICATION: this.editedItem.IDENTIFICATION == null || this.editedItem.IDENTIFICATION == undefined ? '' : escape(this.editedItem.IDENTIFICATION)
            , GEMSTONECOLOR: this.editedItem.GEMSTONECOLOR == null || this.editedItem.GEMSTONECOLOR == undefined ? '' : escape(this.editedItem.GEMSTONECOLOR)
            , MAINWEIGHT:  this.isNullCheck(this.editedItem.MAINWEIGHT) ? null : this.editedItem.MAINWEIGHT
            , SUBWEIGHT: this.isNullCheck(this.editedItem.SUBWEIGHT) ? null : this.editedItem.SUBWEIGHT

            , PRODUCT_STATE: this.editedItem.PRODUCT_STATE
            , DISPLAY_STATE: this.editedItem.DISPLAY_STATE
            , SALETYPE: this.editedItem.SALETYPE
            , SALERATE: this.editedItem.SALERATE
            , DESC: this.editedItem.DESC == null || this.editedItem.DESC == undefined ? '' : escape(this.editedItem.DESC)
            , SALESTART: this.editedItem.SALESTART == null || this.editedItem.SALESTART == undefined ? null : escape(this.editedItem.SALESTART)
            , SALEEND: this.editedItem.SALEEND == null || this.editedItem.SALEEND == undefined ? null : escape(this.editedItem.SALEEND)
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
            this.progress_dialog = false;
            this.loading = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          });

        }else{

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/ProductInsert',{
              PRODUCT_CLASS: this.editedItem.PRODUCT_CLASS
            , PRODUCT_NUMBER: escape(this.editedItem.PRODUCT_NUMBER)
            , PRODUCT_STOCK: this.editedItem.PRODUCT_STOCK == null || this.editedItem.PRODUCT_STOCK == undefined ? '' : escape(this.editedItem.PRODUCT_STOCK)
            , THUM_IMG1: this.editedItem.THUM_IMG1_REF == null || this.editedItem.THUM_IMG1_REF == undefined ? null : escape(this.editedItem.THUM_IMG1_REF)
            , PRODUCT_IMG1: this.editedItem.PRODUCT_IMG1_REF == null || this.editedItem.PRODUCT_IMG1_REF == undefined ? null : escape(this.editedItem.PRODUCT_IMG1_REF)
            , PRODUCT_IMG2: this.editedItem.PRODUCT_IMG2_REF == null || this.editedItem.PRODUCT_IMG2_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG2_REF)
            , APPRAISAL_IMG: this.editedItem.APPRAISAL_IMG_REF == null || this.editedItem.APPRAISAL_IMG_REF == undefined ? '' : escape(this.editedItem.APPRAISAL_IMG_REF)
            , MOVIE_PATH: this.editedItem.MOVIE_PATH_REF == null || this.editedItem.MOVIE_PATH_REF == undefined ? '' : escape(this.editedItem.MOVIE_PATH_REF)
            , WEIGHT: this.editedItem.WEIGHT
            , CERTI_IDX: this.editedItem.CERTI_IDX
            , SHAPE_IDX: this.editedItem.SHAPE_IDX
            , COLOR: this.editedItem.COLOR
            , TRANSPARENCY: this.editedItem.TRANSPARENCY
            , EVALUATION: this.editedItem.EVALUATION
            , POLISH: this.editedItem.POLISH
            , SYMMETRY: this.editedItem.SYMMETRY
            , DIAMONDCOLOR: this.editedItem.DIAMONDCOLOR
            , FLUORESCENCE: this.editedItem.FLUORESCENCE
            , DIAMONDTYPE: this.editedItem.DIAMONDTYPE
            , SUPPLY_PRICE: String(this.editedItem.SUPPLY_PRICE).replaceAll(",","")
            , PRODUCT_MARGIN: this.editedItem.PRODUCT_MARGIN
            , PRICE_DC: this.editedItem.PRICE_DC
            , PRICE_CALCTYPE: this.editedItem.PRICE_CALCTYPE
            , JEWELRYKIND_IDX: this.editedItem.JEWELRYKIND_IDX

            , PRODUCTTYPE_IDX: this.editedItem.PRODUCTTYPE_IDX
            , PRODUCTITEM_IDX: this.editedItem.PRODUCTITEM_IDX
            , MAINSTONE: escape(this.editedItem.MAINSTONE)
            , SUBSTONE: escape(this.editedItem.SUBSTONE) 
            , METALWEIGHT: this.editedItem.METALWEIGHT
            , METALTYPE: this.editedItem.METALTYPE

            , PRICE_TYPE: this.editedItem.PRICE_TYPE
            , DIAMOND_CLASS: this.editedItem.DIAMOND_CLASS
            , WIDTH: this.editedItem.WIDTH
            , DEPTH: this.editedItem.DEPTH
            , HEIGHT: this.editedItem.HEIGHT
            , COLORDISTRI: this.editedItem.COLORDISTRI == null || this.editedItem.COLORDISTRI == undefined ? '' : escape(this.editedItem.COLORDISTRI)
            , COLORGRADE: this.editedItem.COLORGRADE
            , IDENTIFICATION: this.editedItem.IDENTIFICATION == null || this.editedItem.IDENTIFICATION == undefined ? '' : escape(this.editedItem.IDENTIFICATION)
            , GEMSTONECOLOR: this.editedItem.GEMSTONECOLOR == null || this.editedItem.GEMSTONECOLOR == undefined ? '' : escape(this.editedItem.GEMSTONECOLOR)
            , MAINWEIGHT:  this.isNullCheck(this.editedItem.MAINWEIGHT) ? null : this.editedItem.MAINWEIGHT
            , SUBWEIGHT: this.isNullCheck(this.editedItem.SUBWEIGHT) ? null : this.editedItem.SUBWEIGHT

            , PRODUCT_STATE: this.editedItem.PRODUCT_STATE
            , DISPLAY_STATE: this.editedItem.DISPLAY_STATE
            , SALETYPE: this.editedItem.SALETYPE
            , SALERATE: this.editedItem.SALERATE
            , DESC: escape(this.editedItem.DESC)
            , SALESTART: this.editedItem.SALESTART == null || this.editedItem.SALESTART == undefined ? null : escape(this.editedItem.SALESTART)
            , SALEEND: this.editedItem.SALEEND == null || this.editedItem.SALEEND == undefined ? null : escape(this.editedItem.SALEEND)
            , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "A new product has been added.<br><br>새로운 상품이 추가되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });                
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            
            this.progress_dialog = false;
            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });

        }

        this.close()
      },      

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //console.log("search_run");
        //this.$router.push({path:"/AdminProduct", query:{page:1, row:this.pagerow, spc:this.SEARCH_PRODUCT_CLASS, sdc:this.SEARCH_DIAMOND_CLASS, sgc:this.SEARCH_GEMSTONE_CLASS, sjt:this.SEARCH_JEWELRY_TYPE,sji:this.SEARCH_JEWELRY_ITEM,search:this.search_keyword,sorttype:this.sort_type,sortname:this.sort_name}}); //.catch(()=>{});;
        //this.loadpage(1);
        this.movepage(1);
      },



      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },

      async uploadStorage(folder, filename, blobd_ata){

        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },



      //엑셀 등록
      insertExcel(){
        if(this.grade > 90){
          if(this.SEARCH_ACCNT_IDX == null || this.SEARCH_ACCNT_IDX == undefined){
            this.$fire({
              html: "Please select a seller<br><br>판매자를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        } 

        this.uploadExcelFile = null;
        this.excel_dialog = true;
      },
      

      //신규 추가
      newinsert(){
        
        if(this.grade > 90){
          if(this.SEARCH_ACCNT_IDX == null || this.SEARCH_ACCNT_IDX == undefined){
            this.$fire({
              html: "Please select a seller<br><br>판매자를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.uploadFile3 = [];
        this.uploadFile4 = [];
        this.uploadFile5 = [];

        this.uploadFile6 = [];
        this.uploadMovieFile = [];
        this.descImgs = [];

        this.previewImg1 = "";
        this.previewImg2 = "";
        this.previewImg3 = "";
        this.previewImg4 = "";
        this.previewImg5 = "";
        this.previewImg6 = "";

        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);

        this.editedItem.DESC  = "";
        //this.editorText = this.editedItem.DESC;

        this.editedItem.JEWELRYKIND_IDX = this.JEWELRYKINDLIST[0].IDX;
        this.editedItem.PRODUCTTYPE_IDX = this.PRODUCTTYPELIST[0].IDX;
        this.editedItem.PRODUCTITEM_IDX = this.PRODUCTITEMLIST[0].IDX;


        if(this.SEARCH_PRODUCT_CLASS > -1){
          this.editedItem.PRODUCT_CLASS = this.SEARCH_PRODUCT_CLASS;
        }

        this.dialog = true;
        
        window.scrollTo(0,0);

        //this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.            
        
      },

      
      //게시글 상세보기
      loadnotice(item){
        
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});

      },

      sleep(ms) {
        const wakeUpTime = Date.now() + ms;
        while (Date.now() < wakeUpTime) {}
      },

      directship(idx, state){
        this.$http.post(this.$host+'/ProductQuickUpdate', {
              PRODUCT_IDX: idx
            , ISQUICK: state
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.sort_dialog = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.loadpage(this.page);

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.sort_dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
            });
          });
      },

      edit_sort(item){
        this.sortItem = item;
        this.sort_dialog = true;
      },

      //소트순서만 업데이트
      savesort(){

          const numberVal = /[^0-9]/g;
          const check_sort = cmvalidation.checkValReg(this.sortItem.PRODUCT_SORT, numberVal, -1, -1);
          if(check_sort == 2){
            this.$fire({
              html: "You can enter only numbers in the sort order.<br><br>정렬 순서는 숫자만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }          

          
          this.$http.post(this.$host+'/ProductSortUpdate', {
              PRODUCT_IDX: this.sortItem.PRODUCT_IDX
            , PRODUCT_SORT: this.sortItem.PRODUCT_SORT == null || this.sortItem.PRODUCT_SORT == undefined ? null : this.sortItem.PRODUCT_SORT
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.sort_dialog = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.sort_dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
            });
          });
          
      },


      addoption(){
        //옵션추가
        this.optionItem = Object.assign({}, this.defaultOptionItem);
        this.optionItem.OPTION_SORT = this.optionList.length + 1;
        this.optionItem.PRICE_TYPE  = this.parentItem.PRICE_TYPE;
        this.option_dialog = true;
      },

      //옵션 추가 저장
      async saveoption () {
        
        const weightVal = /[^0-9.]/g;

        //공통 파라메터
        const checkproductnumber = cmvalidation.checkValReg(this.optionItem.OPTION_NAME, null, 4, 50);
        if(checkproductnumber == 1){
          this.$fire({
            html: "Please enter the option name.<br><br>옵션명을 입력하세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkproductnumber == 3){
          this.$fire({
            html: "Please Enter option name in 4 characters or then<br><br>옵션명은 4자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkproductnumber == 4){
          this.$fire({
            html: "Please Enter option name in 50 characters or less<br><br>옵션명은 50자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.isNullCheck(this.optionItem.OPTION_PRICE)){
          this.$fire({
            html: "Please enter a option price<br><br>옵션가격을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(weightVal.test( String(this.optionItem.OPTION_PRICE).replaceAll(",",""))){
          this.$fire({
            html: "Only numbers and . can be entered for the option price.<br><br>옵션가격은 숫자와 .만 입력이 가능합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 

        const numberVal = /[^0-9]/g;
          const check_sort = cmvalidation.checkValReg(this.optionItem.OPTION_SORT, numberVal, -1, -1);
          if(check_sort == 2){
            this.$fire({
              html: "You can enter only numbers in the sort order.<br><br>정렬 순서는 숫자만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }          


        if (this.optionItem.CRAFTOPTION_IDX > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/DesignOptionUpdate', {
              CRAFTOPTION_IDX: this.optionItem.CRAFTOPTION_IDX
            , OPTION_NAME: escape(this.optionItem.OPTION_NAME)
            , OPTION_PRICE: String(this.optionItem.OPTION_PRICE).replaceAll(",","")
            , OPTION_SORT: this.optionItem.OPTION_SORT
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
            this.progress_dialog = false;
            this.loading = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
            });
          });

        }else{

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/DesignOptionInsert',{
              PRODUCT_IDX: this.parentItem.PRODUCT_IDX
            , OPTION_NAME: escape(this.optionItem.OPTION_NAME)
            , OPTION_PRICE: String(this.optionItem.OPTION_PRICE).replaceAll(",","")
            , OPTION_SORT: this.optionItem.OPTION_SORT
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "A new option has been added.<br><br>새로운 옵션이 추가되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    
                    //옵션리트 갱신 필요.
                    this.load_optionlist(this.parentItem);

                  }
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });                
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            
            this.progress_dialog = false;
            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });

        }

        this.closeoption();
      },            


      closeoptionlist(){
        this.optionlist_dialog = false;
        setTimeout(() => {
          this.parentItem = {};
        }, 300)
      },

      closeoption() {
        this.option_dialog     = false;
        setTimeout(() => {
          this.optionItem = Object.assign({}, this.defaultOptionItem);
        }, 300)
      },      


      //옵션 리스트 로드
      load_optionlist(item){
        
        this.parentItem = item;
        console.log("load parent : " + JSON.stringify(this.parentItem));

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/AdminDesignOptionList',{
          PRODUCT_IDX: item.PRODUCT_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            
            const list = result.data.resultData;
            this.optionList.splice(0, this.optionList.length);   //데이터 초기화
            list.forEach(element => {
                element.PRICE_TYPE = item.PRICE_TYPE;
                this.optionList.push(element); 
            });

            this.optionlist_dialog = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },      

      //옵션 수정
      edit_option(item){
        this.optionItem = item;
        this.option_dialog = true;
      },

      delete_option(item){
        
        //등록이 된 경우 
        this.$fire({
            html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/DesignOptionDelete',{
                PRODUCT_IDX:item.PRODUCT_IDX
              , CRAFTOPTION_IDX:item.CRAFTOPTION_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Delete Complete<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.load_optionlist(this.parentItem);
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });     
            
            }
          });  

      },      



      //아이템 수정
      edit_item(item){
        
        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.uploadFile3 = [];
        this.uploadFile4 = [];
        this.uploadFile5 = [];

        this.uploadFile6 = [];
        this.uploadMovieFile = [];
        this.uploadFileThum1 = [];
        this.descImgs = [];

        this.previewImg1 = "";
        this.previewImg2 = "";
        this.previewImg3 = "";
        this.previewImg4 = "";
        this.previewImg5 = "";
        this.previewImg6 = "";

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/EditProductDetail',{
          PRODUCT_IDX: item.PRODUCT_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            this.dialogTitle = "수정하기"
            this.editedIndex = result.data.resultData[0].PRODUCT_IDX;
            this.editedItem = Object.assign({}, result.data.resultData[0]);

            this.editedItem.SUPPLY_PRICE = this.valuecomma(this.editedItem.SUPPLY_PRICE);
            this.dialog = true;
            window.scrollTo(0,0);

            this.previewImg1 = this.editedItem.PRODUCT_IMG1 != null && this.editedItem.PRODUCT_IMG1.length > 0 ? this.editedItem.PRODUCT_IMG1 : "";
            this.previewImg2 = this.editedItem.PRODUCT_IMG2 != null && this.editedItem.PRODUCT_IMG2.length > 0 ? this.editedItem.PRODUCT_IMG2 : "";

            this.previewImg6 = "";
            if(this.editedItem.APPRAISAL_IMG != null && this.editedItem.APPRAISAL_IMG.length > 0){
              if(this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'pdf'){
                //pdf면 pdf미리보기로 지정
                this.previewImg6 = this.pdfPreviewImg;
              }else if(this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'jpg' || this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'jpeg' || this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'png'){
                //이미지면 그대로 지정
                this.previewImg6 = this.editedItem.APPRAISAL_IMG;
              }else{
                //그외 양식에 어긋나는 pdf 주소
                this.previewImg6 = this.editedItem.APPRAISAL_IMG;
              }
            }
            

            if(this.editedItem.PRODUCT_CLASS == 2){
              //주얼리의 경우 주얼리유형에 따른 주얼리 타입을 복원시켜야 한다.
              this.setJewelyItem(this.editedItem.PRODUCTTYPE_IDX);
            }
            


          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      //아이템 삭제
      delete_item(item){
        
        if(this.grade > 90){
          this.$fire({
            html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/ProductDelete',{
              PRODUCT_IDX:item.PRODUCT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Delete Complete<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    const p = this.page;
                    this.loadpage(p);
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });     
            
            }
          });  

        }else{
          //공급자는 상태값만 바꾸는 가짜 삭제
          this.$fire({
            html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/ProductDeleteUpdate',{
              PRODUCT_IDX:item.PRODUCT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Delete Complete<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    const p = this.page;
                    this.loadpage(p);
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });     
            
            }
          });  

        }

              

      },

      opensearch(){
        if(this.SEARCH_PRODUCT_CLASS < 0){
          this.$fire({
            html: "Select an item to use the search option.<br><br>검색 옵션을 사용하려면 품목을 선택하세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }else{
          this.search_dialog = true;
        }
      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      


      //이미지 선택시
      changeMoviefile(){
        if(this.uploadMovieFile != undefined && this.uploadMovieFile.name != undefined && this.uploadMovieFile.name.length > 0){
          if(this.uploadMovieFile.size/1024/1024 > 100){
            this.$fire({
              html: "Videos can only be used with files of 100MB or less.<br><br>동영상은 100MB 이하 파일만 사용할 수 있습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            this.uploadMovieFile = [];
            return;
          }
          const videourl = URL.createObjectURL(this.uploadMovieFile);
          this.$refs.video.src = videourl;
        }
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }else if(pindex == 5){
            this.uploadFile6 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },


      //이미지 선택시
      changefile_pdf(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }else if(pindex == 5){
            this.uploadFile6 = [];
          }
          return;
        }

        //PDF는 크롭없이 바로 첨부
        const reader = new FileReader();
        reader.onload = (event2)=>{
          if(pindex == 0){
            this.uploadFile1 = event2.target.result;
          }else if(pindex == 1){
            this.uploadFile2 = event2.target.result;  //이게 blob데이터
          }else if(pindex == 2){
            this.uploadFile3 = event2.target.result;
          }else if(pindex == 3){
            this.uploadFile4 = event2.target.result;
          }else if(pindex == 4){
            this.uploadFile5 = event2.target.result;
          }else if(pindex == 5){
            this.uploadFile6 = this.dataURItoBlob(event2.target.result);
          }

        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        

      },      


      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){

              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용

              //썸네일 생성
              var image     = await this.loadImage(data);
              const canvas  = document.createElement("canvas");
              canvas.width  = 50;
              canvas.height = 50;
              canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
              
              //this.previewThumImg1 = canvas.toDataURL("image/jpeg");  //미리보기 데이터
              //this.uploadFileThum1 = await this.dataURItoBlob(this.previewThumImg1);     //썸네일 blob데이터

              this.uploadFileThum1 = this.dataURItoBlob(canvas.toDataURL("image/jpeg"));     //썸네일 blob데이터
              

            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 2){
              this.uploadFile3 = blob;
              this.previewImg3 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 3){
              this.uploadFile4 = blob;
              this.previewImg4 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 4){
              this.uploadFile5 = blob;
              this.previewImg5 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 5){
              this.uploadFile6 = blob;
              this.previewImg6 = data; //이미지뷰 미리보기용
            }

          });

        });
      },
      
      
      //지정한 data를 넣은 이미지를 리턴한다.
      loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
          myImage.onload = () => resolve(myImage)
        })
      },

      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },


      //로컬 파일을 다운받는 케이스 - 사용안함
      downloadExcelDiamonds(){
        this.$fire({
          html: "Downloads Diamond Excel File Form?<br><br>다이아몬드 등록용 엑셀파일 양식을 다운로드 하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            var atag = document.createElement("a");
            atag.href = "/files/Upload_Diamonds.xlsx";
            atag.download;
            atag.click();
          }
        });
      },

      //엑셀을 동적으로 생성하는 케이스 - 사용안함
      downloadExcelDiamondsFency(){
        this.$fire({
          html: "Downloads Fancy Diamond Excel File Form?<br><br>팬시 다이아몬드 등록용 엑셀파일 양식을 다운로드 하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            var templist1 = [];
            var templist2 = [];
            for(var i=0;i<1000;i++){
              templist1.push({"A":"","B":"","C":"","D":"","E":"","F":"","G":"","H":"","I":"","J":"","K":"","L":"","M":"","N":"","O":"","P":"","Q":"","R":"","S":"","T":"","U":"","V":"","W":"","X":""});
              templist2.push({"A":"","B":"","C":"","D":"","E":"","F":"","G":"","H":"","I":"","J":"","K":"","L":"","M":"","N":"","O":"","P":"","Q":"","R":"","S":"","T":"","U":"","V":"","W":"","X":""});
            }            

            var item_sheet = XLSX.utils.json_to_sheet(templist1);

            //상단 컬럼명 수정
            item_sheet.A1 = {v:'Product Name(상품명)'};
            item_sheet.B1 = {v:'Stock No(재고번호)'};
            item_sheet.C1 = {v:'Price(가격)'};
            item_sheet.D1 = {v:'Display State(전시상태)'};
            item_sheet.E1 = {v:'Explanation(상품설명)'};
            item_sheet.F1 = {v:'Carat(중량)'};
            item_sheet.G1 = {v:'Image1 URL(이미지1 URL)'};
            item_sheet.H1 = {v:'Image2 URL(이미지2 URL)'};
            item_sheet.L1 = {v:'Shape(모양)'};
            item_sheet.M1 = {v:'Color(색상)'};
            item_sheet.N1 = {v:'Clarity(투명도)'};
            item_sheet.O1 = {v:'Cut Grade(컷팅)'};
            item_sheet.P1 = {v:'Polish Grade(폴리쉬)'};
            item_sheet.Q1 = {v:'Symmetry Grade(대칭)'};
            item_sheet.R1 = {v:'Colored Diamonds(컬러 다이아몬드)'};
            item_sheet.S1 = {v:'Fluorescence(형광성)'};
            item_sheet.T1 = {v:'Diamond Type(다이아몬드타입)'};
            item_sheet.V1 = {v:'Video URL(비디오 URL)'};
            item_sheet.W1 = {v:'Laboratory(감정기관)'};
            item_sheet.X1 = {v:'Certificate URL(감정서 URL)'};

            //컬럼별 넓이
            var wscols = [{wch:25},{wch:12},{wch:25},{wch:30},{wch:15},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:15},{wch:15},{wch:15},{wch:25},{wch:15},{wch:15},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25},{wch:25}];
            item_sheet['!cols'] = wscols;

            //문자열 타입 처리
            for(var j=0;j<1000;j++){
              for(var k=0;k<24;k++){
                var cell_ref1 = item_sheet[XLSX.utils.encode_cell({c:k, r:j})];
                //이걸 해줘야 숫자를 010 식으로 입력해도 그대로 다 들어간다. 안해주면 010 입력시 10으로 변경됨.
                cell_ref1.t = 's';  
                cell_ref1.z = '@';  
              }
            }

            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, item_sheet, 'Diamonds');

            //코드값 정의
            var item_sheet2 = XLSX.utils.json_to_sheet(templist2);

            var wscols2 = [{wch:30}, {wch:30}];
            item_sheet2['!cols'] = wscols2;

            //Sell State
            var pos = 1;
            pos = this.makeExcelCode('Display State(전시상태)', item_sheet2, pos, this.DISPLAYLIST);
            pos++;
            pos = this.makeExcelCode2('Shape(모양)', item_sheet2, pos, this.DIASHAPELIST);
            pos++;
            pos = this.makeExcelCode('Color(색상)', item_sheet2, pos, this.COLORLIST);
            pos++;
            pos = this.makeExcelCode('Clarity(투명도)', item_sheet2, pos, this.TRANSLIST);
            pos++;
            pos = this.makeExcelCode('Cut Grade(컷팅)', item_sheet2, pos, this.EVALLIST);
            pos++;
            pos = this.makeExcelCode('Polish Grade(폴리쉬)', item_sheet2, pos, this.EVALLIST);
            pos++;
            pos = this.makeExcelCode('Symmetry Grade(대칭)', item_sheet2, pos, this.EVALLIST);
            pos++;
            pos = this.makeExcelCode('Colored Diamonds(컬러 다이아몬드)', item_sheet2, pos, this.DIAMONDCOLORLIST);
            pos++;
            pos = this.makeExcelCode('Fluorescencelist(형광성)', item_sheet2, pos, this.FLUORESCENCELIST);
            pos++;
            pos = this.makeExcelCode('Diamond Type(다이아몬드 타입)', item_sheet2, pos, this.DIAMONDTYPELIST);
            pos++;
            pos = this.makeExcelCode2('Laboratory(감정기관)', item_sheet2, pos, this.DIACERTILIST);

            XLSX.utils.book_append_sheet(wb, item_sheet2, 'codelist');
            XLSX.writeFile(wb, 'KDGE_Diamonds.xlsx');

          }
        });

      },

      makeExcelCode(title, item_sheet, pos, codelist){

        item_sheet['A'+pos] = {v:title};
        item_sheet['B'+pos] = {v:'Input Code Value(코드값 입력)'};
        pos++;

        for(var i=0;i < codelist.length ; i++){
          var element = codelist[i];
          item_sheet['A'+pos] = {v:element.name};
          item_sheet['B'+pos] = {v:element.value};
          pos++;
        }

        return pos;
      },

      makeExcelCode2(title, item_sheet, pos, codelist){

        item_sheet['A'+pos] = {v:title};
        item_sheet['B'+pos] = {v:'Input Code Value(코드값 입력)'};
        pos++;

        for(var i=0;i < codelist.length ; i++){
          var element = codelist[i];
          item_sheet['A'+pos] = {v:element.NAME};
          item_sheet['B'+pos] = {v:element.IDX};
          pos++;
        }

        return pos;
      },

      /** 타이틀, 아이템시트, 포스, 코드리스트 */
      makeExcelCode3(title, item_sheet, pos, codelist){

        item_sheet['A'+pos] = {v:title};
        item_sheet['B'+pos] = {v:'Input Code Value(코드값 입력)'};
        item_sheet['C'+pos] = {v:'Jewelry Items(주얼리품목)'};
        item_sheet['D'+pos] = {v:'Input Code Value(코드값 입력)'};
        pos++;

        for(var i=0;i < codelist.length ; i++){
          var element = codelist[i];
          var jlist = this.getJewelyItem(element.IDX);
          if(jlist.length > 0){
            for(var j=0;j<jlist.length;j++){
              var element2 = jlist[j];
              item_sheet['A'+pos] = {v:element.NAME};
              item_sheet['B'+pos] = {v:element.IDX};
              item_sheet['C'+pos] = {v:element2.NAME};
              item_sheet['D'+pos] = {v:element2.IDX};
              pos++;
            }
          }else{
            pos++;
          }
        }

        return pos;
      },



      uploadExcelDiamond(){

          //컬럼명
          var infoItem = [
              {NAME:"Product Name(상품명)", REQTYPE:1, REGEX:null}
            , {NAME:"Stock No(재고번호)", REQTYPE:0}  
            , {NAME:"Price Type(가격타입)", REQTYPE:1}
            , {NAME:"Price Calculation(가격계산방법)", REQTYPE:1, REGEX:"price_calc"}
            , {NAME:"Price(가격)", REQTYPE:1}
            , {NAME:"PriceDC(공급가DC)", REQTYPE:0}
            , {NAME:"Display State(전시상태)", REQTYPE:1}
            , {NAME:"Image1 URL(이미지1 URL)", REQTYPE:0, REGEX:"image"}    //다이아몬드는 기본이미지가 필수값 아님
            , {NAME:"Image2 URL(이미지2 URL)", REQTYPE:0, REGEX:"image"}
            //, {NAME:"Video URL(비디오 URL)",  REQTYPE:0, REGEX:"video"}
            , {NAME:"Video URL(비디오 URL)",  REQTYPE:0}
            , {NAME:"Laboratory(감정기관)", REQTYPE:1}
            //, {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1, REGEX:"certi"}
            , {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1}

            //다이아몬드 공통
            , {NAME:"Diamond Type(다이아몬드타입)", REQTYPE:1}
            , {NAME:"Carat(중량)", REQTYPE:1}
            , {NAME:"Shape(모양)", REQTYPE:1}
            , {NAME:"Clarity(투명도)", REQTYPE:1}
            , {NAME:"Length", REQTYPE:1}
            , {NAME:"Width", REQTYPE:1}
            , {NAME:"Depth", REQTYPE:1}
            , {NAME:"Fluorescence(형광성)", REQTYPE:1}
            
            //일반다이아몬드
            , {NAME:"Cut Grade(컷팅)", REQTYPE:1}
            , {NAME:"Polish Grade(폴리쉬)", REQTYPE:1}
            , {NAME:"Symmetry Grade(대칭)", REQTYPE:1}
            , {NAME:"Color(색상)", REQTYPE:1}

            , {NAME:"Explanation(상품설명)", REQTYPE:0}

            , {NAME:"PriceTypeValue", REQTYPE:1}
            , {NAME:"PriceCalcValue", REQTYPE:1}
            , {NAME:"DisplayStateValue", REQTYPE:1}
            , {NAME:"DiamondTypeValue", REQTYPE:1}
            , {NAME:"ShapeValue", REQTYPE:1}
            , {NAME:"ClarityValue", REQTYPE:1}
            , {NAME:"FluorescenceValue", REQTYPE:1}
            , {NAME:"CutGradeValue", REQTYPE:1}
            , {NAME:"PolishGradeValue", REQTYPE:1}
            , {NAME:"SymmetryGradeValue", REQTYPE:1}
            , {NAME:"ColorValue", REQTYPE:1}
            , {NAME:"LaboratoryValue", REQTYPE:1}

          ];

          this.excel_prog = true;
          const reader = new FileReader();
          
          reader.onload = async () => {

            var fileData = reader.result;
            let workbook = XLSX.read(fileData, {type : 'binary'});  
            var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
            var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
            var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
            let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {defval:""});

            //빈값 제거
            var uploaditem = [];

            wrokjson.forEach(element=>{
              var nullcnt = 0;
              //제품명이 들어있는지 체크
              var column_name = infoItem[0].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
              //들어있으면 업로드 대상
              if(nullcnt == 0){
                uploaditem.push(element);
              }
            })

            if(uploaditem.length < 1){
              this.$fire({
                html: "Insufficient data for Excel file input.<br><br>엑셀파일 입력데이터가 부족합니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            //console.log("upload len : " + uploaditem.length);

            //데이터 검증 
            var checkflag = true;
            var read_column = Object.keys(wrokjson[0]);
            //엑셀파일과 기본양식 컬럼값 비교
            for(var k=0;k<infoItem.length;k++){
              if(read_column[k] != infoItem[k].NAME){
                //console.log("error : "+infoItem[k].NAME)
                checkflag = false;
                break;
              }
            }
            if(!checkflag){
              this.$fire({
                html: "The Excel format is different. Please download the form again.<br><br>엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            for(var i=0;i<uploaditem.length;i++){

              if(!checkflag){
                break;
              }
              
              var element = uploaditem[i];
              console.log("element : " + JSON.stringify(element));

              //필수값 체크
              for(var j=0;j<infoItem.length;j++){
                var column_name = infoItem[j].NAME;
                if(infoItem[j].REQTYPE == 1){  //필수입력
                  if(element[column_name] == null || String(element[column_name]).length < 1){
                    this.$fire({
                      html: "Enter a value for "+column_name+" on the "+(i+1)+" line.<br><br>"+(i+1)+"번째 줄의 "+column_name+"값을 입력하세요",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    checkflag = false;
                    break;
                  }
                }

                //필수값이던 아니던 값이 있는 경우에는 정규표현식 체크
                if(element[column_name] != null && element[column_name] != undefined && String(element[column_name]).length > 0){

                  if(infoItem[j].REGEX != null && infoItem[j].REGEX != undefined && infoItem[j].REGEX.length > 0){
                      
                      var exp = element[column_name].split('.').pop().toLowerCase();
                      //console.log(column_name + " : " + exp);

                      if(infoItem[j].REGEX == "image"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png'){
                          
                          this.$fire({
                            html: "Only .jpg, .jpeg, and .png can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "certi"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png' && exp != 'pdf'){
                          this.$fire({
                            html: "Only .jpg, .jpeg, .png and .pdf can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png, .pdf 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "video"){
                        if(exp != 'mp4'){
                          this.$fire({
                            html: "Only .mp4 can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .mp4 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "price_calc"){

                        //console.log("price_calc : " + element['PriceCalcValue']);
                        //console.log("DiamondTypeValue : " + element['DiamondTypeValue']);
                        if(element['PriceCalcValue'] == 2){
                          //PRICE TYPE 2번은 천연 다이아만 사용 가능
                          if(element['DiamondTypeValue'] != 0){
                            this.$fire({
                              html: "Error "+column_name+" in line "+(i+1)+". Only natural diamonds can use RAPAPORT.<br><br>"+(i+1)+"번째 줄의 "+column_name+"이 잘못되었습니다. 천연다이아만 라파시세를 사용할 수 있습니다.",
                              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                              confirmButtonColor: '#000000',
                              allowEscapeKey:false, 
                              allowOutsideClick:false,
                              allowEnterKey:true,
                            });
                            checkflag = false;
                            break;
                          }
                          //PRICE TYPE 2번은 USD만 사용 가능
                          if(element['PriceTypeValue'] != 0){
                            this.$fire({
                              html: "Error "+column_name+" in line "+(i+1)+". RAPAPORT price can only be used in price type USD.<br><br>"+(i+1)+"번째 줄의 "+column_name+"이 잘못되었습니다. RAPA시세는 가격타입 USD만 사용이 가능합니다.",
                              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                              confirmButtonColor: '#000000',
                              allowEscapeKey:false, 
                              allowOutsideClick:false,
                              allowEnterKey:true,
                            });
                            checkflag = false;
                            break;
                          }
                        }

                      }

                  }
                }

              }

              if(!checkflag){
                this.excel_prog = false;
                this.excel_dialog = false;
                return;
              }

              checkflag = this.checkExcelValue(element, i, true);
              

            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            //요청으로 인해 업로드 엑셀파일 백업
            await this.uploadStorage("uploadexcel", this.SEARCH_ACCNT_NAME+"_diamond_"+Date.now().toString()+"."+this.uploadExcelFile.name.split('.').pop().toLowerCase(), this.uploadExcelFile);

            //서버로 전송해서 처리
            this.$http.post(this.$host+'/DiamondsUpload',{
                JSONVALUE: escape(JSON.stringify(uploaditem))
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
                this.$fire({
                  html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              this.loading = false;
              this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });

          }

          reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서
          
      },


      uploadExcelDiamondFency(){

          //컬럼명
          var infoItem = [
              {NAME:"Product Name(상품명)", REQTYPE:1, REGEX:null}
            , {NAME:"Stock No(재고번호)", REQTYPE:0}  
            , {NAME:"Price Type(가격타입)", REQTYPE:1}
            , {NAME:"Price Calculation(가격계산방법)", REQTYPE:1}
            , {NAME:"Price(가격)", REQTYPE:1}
            , {NAME:"Display State(전시상태)", REQTYPE:1}
            , {NAME:"Image1 URL(이미지1 URL)", REQTYPE:0, REGEX:"image"}
            , {NAME:"Image2 URL(이미지2 URL)", REQTYPE:0, REGEX:"image"}
            //, {NAME:"Video URL(비디오 URL)",  REQTYPE:0, REGEX:"video"}
            , {NAME:"Video URL(비디오 URL)",  REQTYPE:0}
            , {NAME:"Laboratory(감정기관)", REQTYPE:1}
            //, {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1, REGEX:"certi"}
            , {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1}

            //다이아몬드 공통
            , {NAME:"Diamond Type(다이아몬드타입)", REQTYPE:1}
            , {NAME:"Carat(중량)", REQTYPE:1}
            , {NAME:"Shape(모양)", REQTYPE:1}
            , {NAME:"Clarity(투명도)", REQTYPE:1}
            , {NAME:"Length", REQTYPE:1}
            , {NAME:"Width", REQTYPE:1}
            , {NAME:"Depth", REQTYPE:1}
            , {NAME:"Fluorescence(형광성)", REQTYPE:1}

            //펜시 다이아몬드
            , {NAME:"Polish Grade(폴리쉬)", REQTYPE:1}
            , {NAME:"Symmetry Grade(대칭)", REQTYPE:1}
            , {NAME:"Colored Diamonds(컬러 다이아몬드)", REQTYPE:1}
            , {NAME:"Color Distribution(색분포)", REQTYPE:0}
            , {NAME:"Color Grade(컬러 그레이드)", REQTYPE:1}
            , {NAME:"Explanation(상품설명)", REQTYPE:0}

            , {NAME:"PriceTypeValue", REQTYPE:1}
            , {NAME:"PriceCalcValue", REQTYPE:1}
            , {NAME:"DisplayStateValue", REQTYPE:1}
            , {NAME:"LaboratoryValue", REQTYPE:1}
            , {NAME:"DiamondTypeValue", REQTYPE:1}
            , {NAME:"ShapeValue", REQTYPE:1}
            , {NAME:"ClarityValue", REQTYPE:1}
            , {NAME:"FluorescenceValue", REQTYPE:1}
            , {NAME:"PolishGradeValue", REQTYPE:1}
            , {NAME:"SymmetryGradeValue", REQTYPE:1}
            , {NAME:"ColoredDiamondsValue", REQTYPE:1}
            , {NAME:"ColorGradeValue", REQTYPE:1}

          ];

          this.excel_prog = true;
          const reader = new FileReader();
          
          reader.onload = async ()=>{

            var fileData = reader.result;
            let workbook = XLSX.read(fileData, {type : 'binary'});  
            var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
            var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
            var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
            let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {defval:""});

            //빈값 제거
            var uploaditem = [];
            wrokjson.forEach(element=>{
              var nullcnt = 0;
              //제품명이 들어있는지 체크
              var column_name = infoItem[0].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
              //들어있으면 업로드 대상
              if(nullcnt == 0){
                uploaditem.push(element);
              }
            })

            if(uploaditem.length < 1){
              this.$fire({
                html: "There is no data in the excel file.<br><br>엑셀파일에 데이터가 없습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            //데이터 검증 
            var checkflag = true;
            var read_column = Object.keys(wrokjson[0]);
            //엑셀파일과 기본양식 컬럼값 비교
            for(var k=0;k<infoItem.length;k++){
              if(read_column[k] != infoItem[k].NAME){
                checkflag = false;
                break;
              }
            }
            if(!checkflag){
              this.$fire({
                html: "The Excel format is different. Please download the form again.<br><br>엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            for(var i=0;i<uploaditem.length;i++){

              if(!checkflag){
                break;
              }
              
              var element = uploaditem[i];

              //필수값 체크
              for(var j=0;j<infoItem.length;j++){
                var column_name = infoItem[j].NAME;
                if(infoItem[j].REQTYPE == 1){  //필수입력
                  if(element[column_name] == null || String(element[column_name]).length < 1){
                    this.$fire({
                      html: "Enter a value for "+column_name+" on the "+(i+1)+" line.<br><br>"+(i+1)+"번째 줄의 "+column_name+"값을 입력하세요",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    checkflag = false;
                    break;
                  }
                }

                //필수값이던 아니던 값이 있는 경우에는 정규표현식 체크
                if(element[column_name] != null && element[column_name] != undefined && String(element[column_name]).length > 0){

                  if(infoItem[j].REGEX != null && infoItem[j].REGEX != undefined && infoItem[j].REGEX.length > 0){
                      
                      var exp = element[column_name].split('.').pop().toLowerCase();
                      //console.log(column_name + " : " + exp);

                      if(infoItem[j].REGEX == "image"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png'){
                          
                          this.$fire({
                            html: "Only .jpg, .jpeg, and .png can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "certi"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png' && exp != 'pdf'){
                          this.$fire({
                            html: "Only .jpg, .jpeg, .png and .pdf can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png, .pdf 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "video"){
                        if(exp != 'mp4'){
                          this.$fire({
                            html: "Only .mp4 can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .mp4 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }

                  }
                }
            
            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            checkflag = this.checkExcelValue(element, i, true);
            
            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            //요청으로 인해 업로드 엑셀파일 백업
            await this.uploadStorage("uploadexcel", this.SEARCH_ACCNT_NAME+"_fancydia_"+Date.now().toString()+"."+this.uploadExcelFile.name.split('.').pop().toLowerCase(), this.uploadExcelFile);

            //서버로 전송해서 처리
            this.$http.post(this.$host+'/FencyDiamondsUpload',{
                JSONVALUE: escape(JSON.stringify(uploaditem))
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
                this.$fire({
                  html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              this.loading = false;
              this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });

          }

          reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서

      },      

      uploadExcelGemstone(){

          //컬럼명
          var infoItem = [
              {NAME:"Product Name(상품명)", REQTYPE:1, REGEX:null}
            , {NAME:"Stock No(재고번호)", REQTYPE:0}  
            , {NAME:"Price Type(가격타입)", REQTYPE:1}
            , {NAME:"Price Calculation(가격계산방법)", REQTYPE:1}
            , {NAME:"Price(가격)", REQTYPE:1}
            , {NAME:"Display State(전시상태)", REQTYPE:1}
            , {NAME:"Image1 URL(이미지1 URL)", REQTYPE:1, REGEX:"image"}
            , {NAME:"Image2 URL(이미지2 URL)", REQTYPE:0, REGEX:"image"}
            //, {NAME:"Video URL(비디오 URL)",  REQTYPE:0, REGEX:"video"}
            , {NAME:"Video URL(비디오 URL)",  REQTYPE:0}
            , {NAME:"Laboratory(감정기관)", REQTYPE:1}
            //, {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1, REGEX:"certi"}
            , {NAME:"Certificate URL(감정서 URL)",  REQTYPE:1}

            //젬스톤
            , {NAME:"Gem Name(보석명)", REQTYPE:1}
            , {NAME:"Shape(모양)", REQTYPE:1}
            , {NAME:"Carat(중량)", REQTYPE:1}
            , {NAME:"Length", REQTYPE:1}
            , {NAME:"Width", REQTYPE:1}
            , {NAME:"Depth", REQTYPE:1}
            , {NAME:"Identification(식별)", REQTYPE:1}
            , {NAME:"Color(컬러)", REQTYPE:1}

            , {NAME:"Explanation(상품설명)", REQTYPE:0}

            , {NAME:"PriceTypeValue", REQTYPE:1}
            , {NAME:"PriceCalcValue", REQTYPE:1}
            , {NAME:"DisplayStateValue", REQTYPE:1}
            , {NAME:"LaboratoryValue", REQTYPE:1}
            , {NAME:"GemNameValue", REQTYPE:1}
            , {NAME:"ShapeValue", REQTYPE:1}


          ];

          this.excel_prog = true;
          const reader = new FileReader();
          
          reader.onload = async ()=>{

            var fileData = reader.result;
            let workbook = XLSX.read(fileData, {type : 'binary'});  
            var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
            var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
            var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
            let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {defval:""});

            //빈값 제거
            var uploaditem = [];
            wrokjson.forEach(element=>{
              var nullcnt = 0;
              //제품명이 들어있는지 체크
              var column_name = infoItem[0].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
              //들어있으면 업로드 대상
              if(nullcnt == 0){
                uploaditem.push(element);
              }
            })

            if(uploaditem.length < 1){
              this.$fire({
                html: "There is no data in the excel file.<br><br>엑셀파일에 데이터가 없습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            //데이터 검증 
            var checkflag = true;
            var read_column = Object.keys(wrokjson[0]);
            //엑셀파일과 기본양식 컬럼값 비교
            for(var k=0;k<infoItem.length;k++){
              if(read_column[k] != infoItem[k].NAME){
                checkflag = false;
                break;
              }
            }
            if(!checkflag){
              this.$fire({
                html: "The Excel format is different. Please download the form again.<br><br>엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            for(var i=0;i<uploaditem.length;i++){

              if(!checkflag){
                break;
              }
              
              var element = uploaditem[i];

              //필수값 체크
              for(var j=0;j<infoItem.length;j++){
                var column_name = infoItem[j].NAME;
                if(infoItem[j].REQTYPE == 1){  //필수입력
                  if(element[column_name] == null || String(element[column_name]).length < 1){
                    this.$fire({
                      html: "Enter a value for "+column_name+" on the "+(i+1)+" line.<br><br>"+(i+1)+"번째 줄의 "+column_name+"값을 입력하세요",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    checkflag = false;
                    break;
                  }
                }
              

                //필수값이던 아니던 값이 있는 경우에는 정규표현식 체크
                if(element[column_name] != null && element[column_name] != undefined && String(element[column_name]).length > 0){

                  if(infoItem[j].REGEX != null && infoItem[j].REGEX != undefined && infoItem[j].REGEX.length > 0){
                      
                      var exp = element[column_name].split('.').pop().toLowerCase();
                      //console.log(column_name + " : " + exp);

                      if(infoItem[j].REGEX == "image"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png'){
                          
                          this.$fire({
                            html: "Only .jpg, .jpeg, and .png can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "certi"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png' && exp != 'pdf'){
                          this.$fire({
                            html: "Only .jpg, .jpeg, .png and .pdf can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png, .pdf 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "video"){
                        if(exp != 'mp4'){
                          this.$fire({
                            html: "Only .mp4 can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .mp4 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }

                  }
                }

              }

              if(!checkflag){
                this.excel_prog = false;
                this.excel_dialog = false;
                return;
              }

              checkflag = this.checkExcelValue(element, i, false);
              

            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            //요청으로 인해 업로드 엑셀파일 백업
            
            await this.uploadStorage("uploadexcel", this.SEARCH_ACCNT_NAME+"_gemstone_"+Date.now().toString()+"."+this.uploadExcelFile.name.split('.').pop().toLowerCase(), this.uploadExcelFile);

            //서버로 전송해서 처리
            this.$http.post(this.$host+'/GemstonesUpload',{
                JSONVALUE: escape(JSON.stringify(uploaditem))
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
                this.$fire({
                  html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              this.loading = false;
              this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });
           
          }

          reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서

      },



      uploadExcelJewely(){

          //컬럼명
          var infoItem = [
              {NAME:"Product Name(상품명)", REQTYPE:1, REGEX:null}
            , {NAME:"Stock No(재고번호)", REQTYPE:0}  
            , {NAME:"Price Type(가격타입)", REQTYPE:1}
            
            , {NAME:"Price(가격)", REQTYPE:1}
            , {NAME:"Display State(전시상태)", REQTYPE:1}
            
            , {NAME:"Image1 URL(이미지1 URL)", REQTYPE:1, REGEX:"image"}
            , {NAME:"Image2 URL(이미지2 URL)", REQTYPE:0, REGEX:"image"}
            //, {NAME:"Video URL(비디오 URL)",  REQTYPE:0, REGEX:"video"}
            , {NAME:"Video URL(비디오 URL)",  REQTYPE:0}
            //, {NAME:"Certificate URL(감정서 URL)",  REQTYPE:0, REGEX:"certi"}
            , {NAME:"Certificate URL(감정서 URL)",  REQTYPE:0}

            , {NAME:"Jewelry Items(주얼리 품목)", REQTYPE:1}
            , {NAME:"Gemstone Type(보석타입)",  REQTYPE:1}    //천연/합성 구분이고 다이아랑 같은 컬럼
            , {NAME:"MetalContent(금속함량)", REQTYPE:1}
            , {NAME:"MetalType(금속종류)",  REQTYPE:1}
            , {NAME:"MetalWeight(금속중량)", REQTYPE:1}
            , {NAME:"Main Stone(메인스톤)", REQTYPE:0}
            , {NAME:"Main Weight(메인스톤중량)", REQTYPE:0}
            , {NAME:"Sub Stone(보조스톤)", REQTYPE:0}
            , {NAME:"Sub Weight(보조스톤중량)", REQTYPE:0}

            , {NAME:"Explanation(상품설명)", REQTYPE:0}

            , {NAME:"PriceTypeValue", REQTYPE:1}
            , {NAME:"DisplayStateValue", REQTYPE:1}
            , {NAME:"JewelryTypeValue",   REQTYPE:1}
            , {NAME:"JewelryItemsValue",  REQTYPE:1}
            , {NAME:"GemstoneTypeValue",  REQTYPE:1}    
            , {NAME:"MetalContentValue", REQTYPE:1}
            , {NAME:"MetalTypeValue",  REQTYPE:1}


          ];

          this.excel_prog = true;
          const reader = new FileReader();
          
          reader.onload = async ()=>{

            var fileData = reader.result;
            let workbook = XLSX.read(fileData, {type : 'binary'});  
            var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
            var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
            var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
            let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {defval:""});

            //빈값 제거
            var uploaditem = [];
            wrokjson.forEach(element=>{
              var nullcnt = 0;
              //제품명이 들어있는지 체크
              var column_name = infoItem[0].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
              //들어있으면 업로드 대상
              if(nullcnt == 0){
                uploaditem.push(element);
              }
            })

            if(uploaditem.length < 1){
              this.$fire({
                html: "There is no data in the excel file.<br><br>엑셀파일에 데이터가 없습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            //데이터 검증 
            var checkflag = true;
            var read_column = Object.keys(wrokjson[0]);
            //엑셀파일과 기본양식 컬럼값 비교
            for(var k=0;k<infoItem.length;k++){
              if(read_column[k] != infoItem[k].NAME){
                checkflag = false;
                break;
              }
            }
            if(!checkflag){
              this.$fire({
                html: "The Excel format is different. Please download the form again.<br><br>엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            for(var i=0;i<uploaditem.length;i++){

              if(!checkflag){
                break;
              }
              
              var element = uploaditem[i];

              //필수값 체크
              for(var j=0;j<infoItem.length;j++){
                var column_name = infoItem[j].NAME;
                if(infoItem[j].REQTYPE == 1){  //필수입력
                  if(element[column_name] == null || String(element[column_name]).length < 1){
                    this.$fire({
                      html: "Enter a value for "+column_name+" on the "+(i+1)+" line.<br><br>"+(i+1)+"번째 줄의 "+column_name+"값을 입력하세요",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    checkflag = false;
                    break;
                  }
                }

                //필수값이던 아니던 값이 있는 경우에는 정규표현식 체크
                if(element[column_name] != null && element[column_name] != undefined && String(element[column_name]).length > 0){

                  if(infoItem[j].REGEX != null && infoItem[j].REGEX != undefined && infoItem[j].REGEX.length > 0){
                      
                      var exp = element[column_name].split('.').pop().toLowerCase();
                      //console.log(column_name + " : " + exp);

                      if(infoItem[j].REGEX == "image"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png'){
                          
                          this.$fire({
                            html: "Only .jpg, .jpeg, and .png can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "certi"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png' && exp != 'pdf'){
                          this.$fire({
                            html: "Only .jpg, .jpeg, .png and .pdf can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png, .pdf 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "video"){
                        if(exp != 'mp4'){
                          this.$fire({
                            html: "Only .mp4 can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .mp4 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }

                  }
                }
              
              }

              if(!checkflag){
                this.excel_prog = false;
                this.excel_dialog = false;
                return;
              }

              checkflag = this.checkExcelValue(element, i, false);
              

            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            //요청으로 인해 업로드 엑셀파일 백업
            await this.uploadStorage("uploadexcel", this.SEARCH_ACCNT_NAME+"_jewelry_"+Date.now().toString()+"."+this.uploadExcelFile.name.split('.').pop().toLowerCase(), this.uploadExcelFile);

            //서버로 전송해서 처리
            this.$http.post(this.$host+'/JewelryUpload',{
                JSONVALUE: escape(JSON.stringify(uploaditem))
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
                this.$fire({
                  html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              this.loading = false;
              this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });
            

          }

          reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서        

      },


      uploadExcelDesign(){

          //컬럼명
          var infoItem = [
              {NAME:"Product Name(상품명)", REQTYPE:1, REGEX:null}
            , {NAME:"Stock No(재고번호)", REQTYPE:0}  
            , {NAME:"Price Type(가격타입)", REQTYPE:1}
            
            , {NAME:"Price(가격)", REQTYPE:1}
            , {NAME:"Display State(전시상태)", REQTYPE:1}
            
            , {NAME:"Image1 URL(이미지1 URL)", REQTYPE:1, REGEX:"image"}
            , {NAME:"Image2 URL(이미지2 URL)", REQTYPE:0, REGEX:"image"}
            , {NAME:"Video URL(비디오 URL)",  REQTYPE:0}
            , {NAME:"Certificate URL(감정서 URL)",  REQTYPE:0}

            , {NAME:"Design Type(디자인 분류)", REQTYPE:1}
            , {NAME:"MetalContent(금속함량)", REQTYPE:1}
            , {NAME:"MetalType(금속종류)",  REQTYPE:1}
            , {NAME:"MetalWeight(금속중량)", REQTYPE:1}
            , {NAME:"Explanation(상품설명)", REQTYPE:0}

            , {NAME:"PriceTypeValue", REQTYPE:1}
            , {NAME:"DisplayStateValue", REQTYPE:1}
            , {NAME:"DesignTypeValue",   REQTYPE:1}
            , {NAME:"MetalContentValue", REQTYPE:1}
            , {NAME:"MetalTypeValue",  REQTYPE:1}


          ];

          this.excel_prog = true;
          const reader = new FileReader();
          
          reader.onload = async ()=>{

            var fileData = reader.result;
            let workbook = XLSX.read(fileData, {type : 'binary'});  
            var sheetNameList = workbook.SheetNames; // 시트 이름 목록 가져오기 
            var firstSheetName = sheetNameList[0]; // 첫번째 시트명            
            var firstSheet = workbook.Sheets[firstSheetName]; // 첫번째 시트 
            let wrokjson = XLSX.utils.sheet_to_json(firstSheet, {defval:""});

            //빈값 제거
            var uploaditem = [];
            wrokjson.forEach(element=>{
              var nullcnt = 0;
              //제품명이 들어있는지 체크
              var column_name = infoItem[0].NAME;
              if(element[column_name] == null || String(element[column_name]).length < 1){
                nullcnt++;
              }
              //들어있으면 업로드 대상
              if(nullcnt == 0){
                uploaditem.push(element);
              }
            })

            if(uploaditem.length < 1){
              this.$fire({
                html: "There is no data in the excel file.<br><br>엑셀파일에 데이터가 없습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            //데이터 검증 
            var checkflag = true;
            var read_column = Object.keys(wrokjson[0]);
            //엑셀파일과 기본양식 컬럼값 비교
            for(var k=0;k<infoItem.length;k++){
              if(read_column[k] != infoItem[k].NAME){
                checkflag = false;
                break;
              }
            }
            if(!checkflag){
              this.$fire({
                html: "The Excel format is different. Please download the form again.<br><br>엑셀양식이 다릅니다. 양식을 다시 다운로드하세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.excel_prog = false;
              return;
            }

            for(var i=0;i<uploaditem.length;i++){

              if(!checkflag){
                break;
              }
              
              var element = uploaditem[i];

              //필수값 체크
              for(var j=0;j<infoItem.length;j++){
                var column_name = infoItem[j].NAME;
                if(infoItem[j].REQTYPE == 1){  //필수입력
                  if(element[column_name] == null || String(element[column_name]).length < 1){
                    this.$fire({
                      html: "Enter a value for "+column_name+" on the "+(i+1)+" line.<br><br>"+(i+1)+"번째 줄의 "+column_name+"값을 입력하세요",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    checkflag = false;
                    break;
                  }
                }

                //필수값이던 아니던 값이 있는 경우에는 정규표현식 체크
                if(element[column_name] != null && element[column_name] != undefined && String(element[column_name]).length > 0){

                  if(infoItem[j].REGEX != null && infoItem[j].REGEX != undefined && infoItem[j].REGEX.length > 0){
                      
                      var exp = element[column_name].split('.').pop().toLowerCase();
                      //console.log(column_name + " : " + exp);

                      if(infoItem[j].REGEX == "image"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png'){
                          
                          this.$fire({
                            html: "Only .jpg, .jpeg, and .png can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "certi"){
                        if(exp != 'jpg' && exp != 'jpeg' && exp != 'png' && exp != 'pdf'){
                          this.$fire({
                            html: "Only .jpg, .jpeg, .png and .pdf can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .jpg, .jpeg, .png, .pdf 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }else if(infoItem[j].REGEX == "video"){
                        if(exp != 'mp4'){
                          this.$fire({
                            html: "Only .mp4 can be used at the end of the "+column_name+" in line "+(i+1)+".<br><br>"+(i+1)+"번째 줄의 "+column_name+"의 URL 주소의 끝은 .mp4 이외에는 사용할 수 없습니다.",
                            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                            confirmButtonColor: '#000000',
                            allowEscapeKey:false, 
                            allowOutsideClick:false,
                            allowEnterKey:true,
                          });
                          checkflag = false;
                          break;
                        }
                      }

                  }
                }
              
              }

              if(!checkflag){
                this.excel_prog = false;
                this.excel_dialog = false;
                return;
              }

              checkflag = this.checkExcelValue(element, i, false);
              

            }

            if(!checkflag){
              this.excel_prog = false;
              this.excel_dialog = false;
              return;
            }

            //요청으로 인해 업로드 엑셀파일 백업
            await this.uploadStorage("uploadexcel", this.SEARCH_ACCNT_NAME+"_design_"+Date.now().toString()+"."+this.uploadExcelFile.name.split('.').pop().toLowerCase(), this.uploadExcelFile);

            //서버로 전송해서 처리
            this.$http.post(this.$host+'/DesignUpload',{
                JSONVALUE: escape(JSON.stringify(uploaditem))
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
                this.$fire({
                  html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.excel_prog   = false;
              this.excel_dialog = false;
              this.loading = false;
              this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });
            

          }

          reader.readAsBinaryString(this.uploadExcelFile);  //비동기로 파일을 읽어서        

      },      

      uploadExcel(){
        
        if(this.uploadExcelFile == null){
          this.$alert("엑셀파일을 선택해주세요");
          return;
        }

        this.$fire({
          html: "Would you like to register the product by uploading an Excel file?<br><br>엑셀파일을 업로드하여 상품을 등록하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            if(this.UPLOAD_PRODUCT_CLASS == 0){
              this.uploadExcelDiamond();
            }else if(this.UPLOAD_PRODUCT_CLASS == 1){
              this.uploadExcelDiamondFency();
            }else if(this.UPLOAD_PRODUCT_CLASS == 2){
              this.uploadExcelGemstone();
            }else if(this.UPLOAD_PRODUCT_CLASS == 3){
              this.uploadExcelJewely();
            }else if(this.UPLOAD_PRODUCT_CLASS == 4){
              this.uploadExcelDesign();
            }
          }
        });


      },


      //민,맥스,밸유체크
      checkExcelValue(item, pos, isDia){
        
        pos++;
        const numberVal = /[^0-9]/g;
        const weightVal = /[^0-9.]/g;
        const urlVal = /https?:\/\//;

        //공통 데이터
        const checkproductnumber = cmvalidation.checkValReg(item["Product Name(상품명)"], null, 8, 50);
        if(checkproductnumber == 1){
          this.showAlertMsg('product name','상품명은', pos);
          return false;
        }else if(checkproductnumber == 3){
          this.showAlertMsgMin('product name','상품명은', 8, pos);
          return false;
        }else if(checkproductnumber == 4){
          this.showAlertMsgMax('product name','상품은', 50, pos);
          return false;
        }

        const checkstocknumber = cmvalidation.checkValReg(item["Stock No(재고번호)"], null, -1, 50);
        if(checkstocknumber == 1){
          this.showAlertMsg('Stock No','재고번호를', pos);
          return false;
        }else if(checkproductnumber == 4){
          this.showAlertMsgMax('Stock No','재고번호는', 50, pos);
          return false;
        }
        
        const check_pricetype = cmvalidation.checkValReg(item["Price Type(가격타입)"], numberVal, -1, -1);
        if(check_pricetype == 1){
          this.showAlertMsg('Price Type','가격타입을', pos);
          return false;
        }

        const checkprice = cmvalidation.checkValReg(item["Price(가격)"], weightVal, -1, -1);
        if(checkprice == 1){
          this.showAlertMsg('price','가격을', pos);
          return false;
        }else if(checkprice == 2){
          this.showAlertMsgRegx('price can only be entered with numbers', '가격은 숫자와 .만 입력이 가능합니다.', pos);
          return false;
        }

        const checkstate = cmvalidation.checkValReg(item["Display State(전시상태)"], numberVal, -1, -1);
        if(checkstate == 1){
          this.showAlertMsg('display status','전시상태를', pos);
          return false;
        }
        
        if(!isDia){ //다이아몬드가 아닌 경우만 이미지1 체크
          const checkimg1 = cmvalidation.checkValReg(item["Image1 URL(이미지1 URL)"], urlVal, -1, 255, false);
          if(checkimg1 == 1){
            this.showAlertMsg('image1 URL','이미지1 URL을', pos);
            return false;
          }else if(checkimg1 == 2){
            this.showAlertMsgRegx('Image 1 is an invalid URL address.', '이미지 1은 잘못된 URL 주소입니다.', pos);
            return false;
          }else if(checkimg1 == 4){
            this.showAlertMsgMax('URL for image 1','이미지1의 URL은', 255, pos);
            return false;
          }
        }else{
          //다이아몬드면 값이 있는 경우만 체크
          const checkimg1 = cmvalidation.checkValReg(item["Image1 URL(이미지1 URL)"], urlVal, -1, 255, false);
          if(checkimg1 == 2){
            this.showAlertMsgRegx('Image 1 is an invalid URL address.', '이미지 1은 잘못된 URL 주소입니다.', pos);
            return false;
          }else if(checkimg1 == 4){
            this.showAlertMsgMax('URL for image 1','이미지1의 URL은', 255, pos);
            return false;
          }
        }

        const checkimg2 = cmvalidation.checkValReg(item["Image2 URL(이미지2 URL)"], urlVal, -1, 255, false);
        if(checkimg2 == 2){
          this.showAlertMsgRegx('Image 2 is an invalid URL address.', '이미지 2는 잘못된 URL 주소입니다.', pos);
          return false;
        }else if(checkimg2 == 4){
          this.showAlertMsgMax('URL for image 2','이미지2의 URL은', 255, pos);
          return false;
        }

        const checkvideo = cmvalidation.checkValReg(item["Video URL(비디오 URL)"], urlVal, -1, 255, false);
        if(checkvideo == 2){
          this.showAlertMsgRegx('The Video URL must start with http or https.', '비디오 URL은 http 또는 https로 시작해야 합니다.', pos);
          return false;
        }else if(checkvideo == 4){
          this.showAlertMsgMax('URL for Video','비디오 URL은', 255, pos);
          return false;
        }        

        //주얼리는 필수 아님.
        if(this.UPLOAD_PRODUCT_CLASS == 0 || this.UPLOAD_PRODUCT_CLASS == 1 || this.UPLOAD_PRODUCT_CLASS == 2){
          const checkagency = cmvalidation.checkValReg(item["Laboratory(감정기관)"], numberVal, -1, -1);
          if(checkagency == 1){
            this.showAlertMsg('Laboratory','감정기관을', pos);
            return false;
          }

          const checkagencyurl = cmvalidation.checkValReg(item["Certificate URL(감정서 URL)"], urlVal, -1, 255, false);
          if(checkagencyurl == 1){
            this.showAlertMsg('Certificate URL','감정서 URL을', pos);
            return false;
          }else if(checkagencyurl == 2){
            this.showAlertMsgRegx('Invalid Certificate URL address.', '잘못된 감정서 URL 주소입니다.', pos);
            return false;
          }else if(checkagencyurl == 4){
            this.showAlertMsgMax('URL for Certificate','감정서 URL은', 255, pos);
            return false;
          }

        }


        //다이아몬드 공통
        if(this.UPLOAD_PRODUCT_CLASS == 0 || this.UPLOAD_PRODUCT_CLASS == 1){

          const checkdiatype = cmvalidation.checkValReg(item["Diamond Type(다이아몬드타입)"], numberVal, -1, -1);
          if(checkdiatype == 1){
            this.showAlertMsg('Diamond Type','다이아몬드 타입을', pos);
            return false;
          }

          const check = cmvalidation.checkValReg(item["Carat(중량)"], weightVal, -1, -1);
          if(check == 1){
            this.showAlertMsg('Carat','중량을', pos);
            return false;
          }else if(check == 2){
            this.showAlertMsgRegx('Carat can only be entered with numbers and .', '중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }
        
          const checkshape = cmvalidation.checkValReg(item["Shape(모양)"], numberVal, -1, -1);
          if(checkshape == 1){
            this.showAlertMsg('Shape','모양을', pos);
            return false;
          }
          
          const checktran = cmvalidation.checkValReg(item["Clarity(투명도)"], numberVal, -1, -1);
          if(checktran == 1){
            this.showAlertMsg('Clarity','투명도를', pos);
            return false;
          }

          const check_width = cmvalidation.checkValReg(item["Width"], weightVal, -1, -1);
          if(check_width == 1){
            this.showAlertMsg('Width','Width를', pos);
            return false;
          }else if(check_width == 2){
            this.showAlertMsgRegx('Width can only be entered with numbers and .', 'Width는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const check_depth = cmvalidation.checkValReg(item["Depth"], weightVal, -1, -1);
          if(check_depth == 1){
            this.showAlertMsg('Depth','Depth를', pos);
            return false;
          }else if(check_depth == 2){
            this.showAlertMsgRegx('Depth can only be entered with numbers and .', 'Depth는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const check_height = cmvalidation.checkValReg(item["Length"], weightVal, -1, -1);
          if(check_width == 1){
            this.showAlertMsg('Length','Length', pos);
            return false;
          }else if(check_height == 2){
            this.showAlertMsgRegx('Length can only be entered with numbers and .', 'Length는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const checkfluores = cmvalidation.checkValReg(item["Fluorescence(형광성)"], numberVal, -1, -1);
          if(checkfluores == 1){
            this.showAlertMsg('Fluorescence','형광성을', pos);
            return false;
          }
          
        }

        //일반 다이아몬드 전용
        if(this.UPLOAD_PRODUCT_CLASS == 0){

          const checkeval = cmvalidation.checkValReg(item["Cut Grade(컷팅)"], numberVal, -1, -1);
          if(checkeval == 1){
            this.showAlertMsg('Cut Grade','컷팅을', pos);
            return false;
          }

          const checkpolish = cmvalidation.checkValReg(item["Polish Grade(폴리쉬)"], numberVal, -1, -1);
          if(checkpolish == 1){
            this.showAlertMsg('Polish Grade','폴리쉬를', pos);
            return false;
          }

          const checksym = cmvalidation.checkValReg(item["Symmetry Grade(대칭)"], numberVal, -1, -1);
          if(checksym == 1){
            this.showAlertMsg('Symmetry Grade','대칭을', pos);
            return false;
          }

          const checkcolor = cmvalidation.checkValReg(item["Color(색상)"], numberVal, -1, -1);
          if(checkcolor == 1){
            this.showAlertMsg('Color','컬러를', pos);
            return false;
          }

        }

        //펜시 다이아몬드 전용
        if(this.UPLOAD_PRODUCT_CLASS == 1){

          const checkpolish = cmvalidation.checkValReg(item["Polish Grade(폴리쉬)"], numberVal, -1, -1);
          if(checkpolish == 1){
            this.showAlertMsg('Polish Grade','폴리쉬를', pos);
            return false;
          }
          
          const checksym = cmvalidation.checkValReg(item["Symmetry Grade(대칭)"], numberVal, -1, -1);
          if(checksym == 1){
            this.showAlertMsg('Symmetry Grade','대칭을', pos);
            return false;
          }

          const checkdmcolor = cmvalidation.checkValReg(item["Colored Diamonds(컬러 다이아몬드)"], numberVal, -1, -1);
          if(checkdmcolor == 1){
            this.showAlertMsg('Colored Diamonds','컬러 다이아몬드를', pos);
            return false;
          }   

          const check_colordistri = cmvalidation.checkValReg(item["Color Distribution(색분포)"], null, -1, 25);
          if(check_colordistri == 4){
            this.showAlertMsgMax('Color Distribution','색분포는', 25, pos);
            return false;
          }

          const check_colorgrade = cmvalidation.checkValReg(item["Color Grade(컬러 그레이드)"], numberVal, -1, -1);
          if(check_colorgrade == 1){
            this.showAlertMsg('Color Grade','컬러 그레이드를', pos);
            return false;
          }  

        }


        //젬스톤 전용
        if(this.UPLOAD_PRODUCT_CLASS == 2){

          const check_colorgrade = cmvalidation.checkValReg(item["Gem Name(보석명)"], numberVal, -1, -1);
          if(check_colorgrade == 1){
            this.showAlertMsg('Gem Name','보석명을', pos);
            return false;
          }
          const checkshape = cmvalidation.checkValReg(item["Shape(모양)"], numberVal, -1, -1);
          if(checkshape == 1){
            this.showAlertMsg('Shape','모양을', pos);
            return false;
          }
          
          const check = cmvalidation.checkValReg(item["Carat(중량)"], weightVal, -1, -1);
          if(check == 1){
            this.showAlertMsg('Carat','중량을', pos);
            return false;
          }else if(check == 2){
            this.showAlertMsgRegx('Carat can only be entered with numbers and .', '중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const check_width = cmvalidation.checkValReg(item["Width"], weightVal, -1, -1);
          if(check_width == 1){
            this.showAlertMsg('Width','Width를', pos);
            return false;
          }else if(check_width == 2){
            this.showAlertMsgRegx('Width can only be entered with numbers and .', 'Width는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const check_depth = cmvalidation.checkValReg(item["Depth"], weightVal, -1, -1);
          if(check_depth == 1){
            this.showAlertMsg('Depth','Depth를', pos);
            return false;
          }else if(check_depth == 2){
            this.showAlertMsgRegx('Depth can only be entered with numbers and .', 'Depth는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }

          const check_height = cmvalidation.checkValReg(item["Length"], weightVal, -1, -1);
          if(check_width == 1){
            this.showAlertMsg('Length','Length를', pos);
            return false;
          }else if(check_height == 2){
            this.showAlertMsgRegx('Length can only be entered with numbers and .', 'Length는 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }          

          const check_identifi = cmvalidation.checkValReg(item["Identification(식별)"], null, -1, 25);
          if(check_identifi == 1){
            this.showAlertMsg('Identification','식별을', pos);
            return false;
          }else if(check_identifi == 4){
            this.showAlertMsgMax('Identification','식별은', 25, pos);
            return false;
          }

          const check_gemcolor = cmvalidation.checkValReg(item["Color(컬러)"], null, -1, 25);
          if(check_gemcolor == 1){
            this.showAlertMsg('Color','컬러를', pos);
            return false;
          }else if(check_gemcolor == 4){
            this.showAlertMsgMax('Color','컬러는', 25, pos);
            return false;
          }

        }
            
        //주얼리 전용
        if(this.UPLOAD_PRODUCT_CLASS == 3){

          const check_jewelryitem = cmvalidation.checkValReg(item["Jewelry Items(주얼리 품목)"], numberVal, -1, -1);
          if(check_jewelryitem == 1){
            this.showAlertMsg('Jewelry Items','주얼리 품목을', pos);
            return false;
          }   

          const check_gemtype = cmvalidation.checkValReg(item["Gemstone Type(보석타입)"], numberVal, -1, -1);
          if(check_gemtype == 1){
            this.showAlertMsg('Gemstone Type','보석타입을', pos);
            return false;
          }

          const check_metalcontent = cmvalidation.checkValReg(item["MetalContent(금속함량)"], numberVal, -1, -1);
          if(check_metalcontent == 1){
            this.showAlertMsg('Metal Content','금속함량을', pos);
            return false;
          } 

          const check_metaltype = cmvalidation.checkValReg(item["MetalType(금속종류)"], numberVal, -1, -1);
          if(check_metaltype == 1){
            this.showAlertMsg('Metal Type','금속종류를', pos);
            return false;
          }

          const check_metalweight = cmvalidation.checkValReg(item["MetalWeight(금속중량)"], weightVal, -1, -1);
          if(check_metalweight == 1){
            this.showAlertMsg('Metal Weight','금속중량을', pos);
            return false;
          }else if(check_metalweight == 2){
            this.showAlertMsgRegx('Metal Weight can only be entered with numbers and .', '금속중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }          

          const check_mainstone = cmvalidation.checkValReg(item["Main Stone(메인스톤)"], urlVal, -1, 50, false);
          if(check_mainstone == 4){
            this.showAlertMsgMax('Main Stone','메인스톤은', 50, pos);
            return false;
          }        

          const check_substone = cmvalidation.checkValReg(item["Sub Stone(서브스톤)"], urlVal, -1, 50, false);
          if(check_substone == 4){
            this.showAlertMsgMax('Sub Stone','서브스톤은', 50, pos);
            return false;
          }        

          const check_mainweight = cmvalidation.checkValReg(item["Main Weight(메인스톤중량)"], weightVal, -1, -1);
          if(check_mainweight == 2){
            this.showAlertMsgRegx('Main Stone Weight can only be entered with numbers and .', '메인스톤 중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }          
          
          const check_subweight = cmvalidation.checkValReg(item["Sub Weight(서브스톤중량)"], weightVal, -1, -1);
          if(check_subweight == 2){
            this.showAlertMsgRegx('Sub Stone Weight can only be entered with numbers and .', '서브스톤 중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }          

        }

        //디자인 전용
        if(this.UPLOAD_PRODUCT_CLASS == 4){

          const check_designtype = cmvalidation.checkValReg(item["Design Type(디자인 분류)"], numberVal, -1, -1);
          if(check_designtype == 1){
            this.showAlertMsg('Design Type','디자인 분류를', pos);
            return false;
          }   

          const check_metalcontent = cmvalidation.checkValReg(item["MetalContent(금속함량)"], numberVal, -1, -1);
          if(check_metalcontent == 1){
            this.showAlertMsg('Metal Content','금속함량을', pos);
            return false;
          } 

          const check_metaltype = cmvalidation.checkValReg(item["MetalType(금속종류)"], numberVal, -1, -1);
          if(check_metaltype == 1){
            this.showAlertMsg('Metal Type','금속종류를', pos);
            return false;
          }

          const check_metalweight = cmvalidation.checkValReg(item["MetalWeight(금속중량)"], weightVal, -1, -1);
          if(check_metalweight == 1){
            this.showAlertMsg('Metal Weight','금속중량을', pos);
            return false;
          }else if(check_metalweight == 2){
            this.showAlertMsgRegx('Metal Weight can only be entered with numbers and .', '금속중량은 숫자와 .만 입력이 가능합니다.', pos);
            return false;
          }          

        }        

        return true;

      },

      showAlertMsg(eng, kor, pos){
        this.$fire({
          html: "Please enter "+eng+".<br><br>"+kor+" 입력하세요."+"<br>Check the "+pos+"th line. ("+pos+"번째 줄을 확인하세요)",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
      },

      showAlertMsgMax(eng, kor, len, pos){
        this.$fire({
          html: "Please Enter "+eng+" in "+len+" characters or less<br><br>"+kor+" 25자 이하로 입력하세요"+"<br>Check the "+pos+"th line. ("+pos+"번째 줄을 확인하세요)",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
      },

      showAlertMsgMin(eng, kor, len, pos){
        this.$fire({
          html: "Please Enter "+eng+" in "+len+" characters or then<br><br>"+kor+" "+len+"자 이상 입력하세요"+"<br>Check the "+pos+"th line. ("+pos+"번째 줄을 확인하세요)",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
      },

      showAlertMsgRegx(eng, kor, pos){
        this.$fire({
          html: eng + "<br><br>" + kor + "<br>Check the "+pos+"th line. ("+pos+"번째 줄을 확인하세요)",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
      },


      downloadFile(file_url){

        this.$http.get(file_url,{responseType:'blob'})
        .then(response=>{
          const blob = new Blob([response.data])
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = "download"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error=>{
          
        });

      },


      //검색창 전용


      //검색조건 저장
      saveSearchOption(){
        //다이아
        this.$session.set("search_shape", this.search_shape);
        this.$session.set("search_color", this.search_color);
        this.$session.set("search_transparency", this.search_transparency);
        this.$session.set("search_grind_total", this.search_grind_total);
        this.$session.set("search_grind_polish", this.search_grind_polish);
        this.$session.set("search_grind_syme", this.search_grind_syme);

        this.$session.set("search_diamondcolor", this.search_diamondcolor);
        this.$session.set("search_fluorescence", this.search_fluorescence);
        this.$session.set("search_diamondtype", this.search_diamondtype);

        //젬스톤
        this.$session.set("search_gemstone_type", this.search_gemstone_type);
        this.$session.set("search_gem_min_weight", this.search_gem_min_weight);
        this.$session.set("search_gem_max_weight", this.search_gem_max_weight);
        this.$session.set("search_gem_certi", this.search_gem_certi);

        //다이아몬드 잼스톤
        this.$session.set("search_certi", this.search_certi);
        this.$session.set("search_min_weight", this.search_min_weight);
        this.$session.set("search_max_weight", this.search_max_weight);

        //주얼리
        this.$session.set("search_jw_min_weight", this.search_jw_min_weight);
        this.$session.set("search_jw_max_weight", this.search_jw_max_weight);
        this.$session.set("search_jw_min_gram", this.search_jw_min_gram);
        this.$session.set("search_jw_max_gram", this.search_jw_max_gram);
        this.$session.set("search_jw_name", this.search_jw_name);

        this.$session.set("search_jewely_metalweight", this.search_jewely_metalweight);
        this.$session.set("search_jewely_metaltype", this.search_jewely_metaltype);
        this.$session.set("search_jewely_type", this.search_jewely_type);

        this.getSelectJewelyItem();
        this.search_jewely_item = [...this.SELECTTITEMLIST];
        this.$session.set("search_jewely_item", this.search_jewely_item);
        this.$session.set("SELECTTITEMMAP", this.SELECTTITEMMAP);

        //디자인 세공
        this.$session.set("search_ds_min_weight", this.search_ds_min_weight);
        this.$session.set("search_ds_max_weight", this.search_ds_max_weight);
        this.$session.set("search_ds_metalweight", this.search_ds_metalweight);
        this.$session.set("search_ds_metaltype", this.search_ds_metaltype);
        

        //공통
        this.$session.set("search_min_price", this.search_min_price);
        this.$session.set("search_max_price", this.search_max_price);
        this.$session.set("search_min_price2", this.search_min_price2);
        this.$session.set("search_max_price2", this.search_max_price2);

      },

      //조건만 초기화 하고 검색하지 않음.
      clear_search_where(){

        //다이아
        this.search_shape.splice(0, this.search_shape.length);
        this.search_color        = [0, 10];
        this.search_transparency = [0, 11];
        this.search_grind_total  = [0,6];
        this.search_grind_polish = [0,6];
        this.search_grind_syme   = [0,6];
        
        this.search_fluorescence.splice(0, this.search_fluorescence.length);
        this.search_diamondcolor.splice(0, this.search_diamondcolor.length);
        this.search_diamondtype.splice(0, this.search_diamondtype.length);
        
        //젬스톤
        this.search_gemstone_type.splice(0, this.search_gemstone_type.length);
        this.search_gem_min_weight = 0.1;
        this.search_gem_max_weight = 100;
        //this.search_range_gemweight = [0, 30];
        this.search_gem_certi.splice(0, this.search_gem_certi.length);

        //다이아몬드 잼스톤
        this.search_min_weight = 0.1;
        this.search_max_weight = 100;
        //this.search_range_weight = [0, 20];

        this.search_certi.splice(0, this.search_certi.length);

        //주얼리
        this.search_jw_min_weight  = 0;
        this.search_jw_max_weight  = 100;
        //this.search_range_jwweight = [0, 30];
        this.search_jw_min_gram  = 0;
        this.search_jw_max_gram  = 100;
        //this.search_range_jwgram = [0, 100];
        this.search_jw_name = '';

        this.search_jewely_metalweight.splice(0, this.search_jewely_metalweight.length);
        this.search_jewely_metaltype.splice(0, this.search_jewely_metaltype.length);
        this.search_jewely_type = [];
        this.search_jewely_item.splice(0, this.search_jewely_item.length);
        this.SELECTTITEMLIST.splice(0, this.SELECTTITEMLIST.length);
        
        this.SELECTTITEMMAP = {};
        this.PRODUCTTYPELIST.forEach(element=>{
          this.SELECTTITEMMAP[element.IDX] = [];
        });

        //디자인 세공
        this.search_ds_min_weight = 0;
        this.search_ds_max_weight = 100;
        this.search_ds_metalweight.splice(0, this.search_ds_metalweight.length);
        this.search_ds_metaltype.splice(0, this.search_ds_metaltype.length);
        

        //공통
        this.search_min_price = null;
        this.search_max_price = null;
        this.search_min_price2 = null;
        this.search_max_price2 = null;
        
        this.saveSearchOption();  //검색조건 저장

      },

      clear_search(){
        //검색필터 초기화 후 검색
        this.search_dialog = false;
        this.clear_search_where();
        this.search_run();
      },

      apply_search(){
        //검색필터 적용 후 검색
        this.search_dialog = false;
        this.saveSearchOption();  //검색조건 저장
        this.search_run();
      },

      setminprice(value){
        this.search_min_price = value;
      },

      setmaxprice(value){
        this.search_max_price = value;
      },

      setminprice2(value){
        this.search_min_price2 = value;
      },

      setmaxprice2(value){
        this.search_max_price2 = value;
      },

      setminweight(value){
        this.search_min_weight = value;
        //this.search_range_weight = [value, this.search_range_weight[1]];
      },
      setmaxweight(value){
        this.search_max_weight = value;
        //this.search_range_weight = [this.search_range_weight[0], value];
      },

      //사용안함
      setWeightRange(){
        //this.search_min_weight = this.search_range_weight[0];
        //this.search_max_weight = this.search_range_weight[1];
      },
      

      setgemminweight(value){
        this.search_gem_min_weight = value;
        //this.search_range_gemweight = [value, this.search_range_gemweight[1]];
      },
      setgemmaxweight(value){
        this.search_gem_max_weight = value;
        //this.search_range_gemweight = [this.search_range_gemweight[0], value];
      },
      //사용안함
      setGemWeightRange(){
        //this.search_gem_min_weight = this.search_range_gemweight[0];
        //this.search_gem_max_weight = this.search_range_gemweight[1];
      },

      setjwminweight(value){
        this.search_jw_min_weight = value;
        //this.search_range_jwweight = [value, this.search_range_jwweight[1]];
      },
      setjwmaxweight(value){
        this.search_jw_max_weight = value;
        //this.search_range_jwweight = [this.search_range_jwweight[0], value];
      },
      //사용안함
      setJwWeightRange(){
        //this.search_jw_min_weight = this.search_range_jwweight[0];
        //this.search_jw_max_weight = this.search_range_jwweight[1];
      },

      setjwmingram(value){
        this.search_jw_min_gram = value;
        //this.search_range_jwgram = [value, this.search_range_jwgram[1]];
      },
      setjwmaxgram(value){
        this.search_jw_max_gram = value;
        //this.search_range_jwgram = [this.search_range_jwgram[0], value];
      },
      //사용안함
      setJwGramRange(){
        //this.search_jw_min_gram = this.search_range_jwgram[0];
        //this.search_jw_max_gram = this.search_range_jwgram[1];
      },

      setdsmingram(value){
        this.search_ds_min_gram = value;
        //this.search_range_jwgram = [value, this.search_range_jwgram[1]];
      },
      setdsmaxgram(value){
        this.search_ds_max_gram = value;
        //this.search_range_jwgram = [this.search_range_jwgram[0], value];
      },


      loadSearchOption(){

        //다이아
        this.search_shape = this.$session.get("search_shape");
        if(this.search_shape == undefined){
            this.search_shape = [];
        }
        this.search_color = this.$session.get("search_color");
        if(this.search_color == undefined){
            this.search_color = [0, 10];
        }
        this.search_transparency = this.$session.get("search_transparency");
        if(this.search_transparency == undefined){
            this.search_transparency = [0, 11];
        }
        this.search_grind_total = this.$session.get("search_grind_total");
        if(this.search_grind_total == undefined){
            this.search_grind_total = [0,6];
        }
        this.search_grind_polish = this.$session.get("search_grind_polish");
        if(this.search_grind_polish == undefined){
            this.search_grind_polish = [0,6];
        }
        this.search_grind_syme = this.$session.get("search_grind_syme");
        if(this.search_grind_syme == undefined){
            this.search_grind_syme = [0,6];
        }
        this.search_diamondcolor = this.$session.get("search_diamondcolor");
        if(this.search_diamondcolor == undefined){
            this.search_diamondcolor = [];
        }
        this.search_fluorescence = this.$session.get("search_fluorescence");
        if(this.search_fluorescence == undefined){
            this.search_fluorescence = [];
        }
        this.search_diamondtype = this.$session.get("search_diamondtype");
        if(this.search_diamondtype == undefined){
            this.search_diamondtype = [];
        }

        //젬스톤
        this.search_gemstone_type = this.$session.get("search_gemstone_type");
        if(this.search_gemstone_type == undefined){
            this.search_gemstone_type = [];
        }
        this.search_gem_min_weight = this.$session.get("search_gem_min_weight");
        if(this.search_gem_min_weight == undefined){
            this.search_gem_min_weight = 0;
        }
        this.search_gem_max_weight = this.$session.get("search_gem_max_weight");
        if(this.search_gem_max_weight == undefined){
            this.search_gem_max_weight = 100;
        }
        //this.search_range_gemweight = [this.search_gem_min_weight, this.search_gem_max_weight];

        this.search_gem_certi = this.$session.get("search_gem_certi");
        if(this.search_gem_certi == undefined){
            this.search_gem_certi = [];
        }

        //다이아몬드
        this.search_certi = this.$session.get("search_certi");
        if(this.search_certi == undefined){
            this.search_certi = [];
        }
        this.search_min_weight = this.$session.get("search_min_weight");
        if(this.search_min_weight == undefined){
            this.search_min_weight = 0;
        }
        this.search_max_weight = this.$session.get("search_max_weight");
        if(this.search_max_weight == undefined){
            this.search_max_weight = 100;
        }
        //this.search_range_weight = [this.search_min_weight, this.search_max_weight];

        //주얼리
        this.search_jw_min_weight = this.$session.get("search_jw_min_weight");
        if(this.search_jw_min_weight == undefined){
            this.search_jw_min_weight = 0;
        }
        this.search_jw_max_weight = this.$session.get("search_jw_max_weight");
        if(this.search_jw_max_weight == undefined){
            this.search_jw_max_weight = 100;
        }
        //this.search_range_jwweight = [this.search_jw_min_weight, this.search_jw_max_weight];

        this.search_jw_min_gram = this.$session.get("search_jw_min_gram");
        if(this.search_jw_min_gram == undefined){
            this.search_jw_min_gram = 0;
        }
        this.search_jw_max_gram = this.$session.get("search_jw_max_gram");
        if(this.search_jw_max_gram == undefined){
            this.search_jw_max_gram = 100;
        }
        //this.search_range_jwgram = [this.search_jw_min_gram, this.search_jw_max_gram];

        this.search_jw_name = this.$session.get("search_jw_name");
        if(this.search_jw_name == undefined){
            this.search_jw_name = null;
        }

        this.search_jewely_metalweight = this.$session.get("search_jewely_metalweight");
        if(this.search_jewely_metalweight == undefined){
            this.search_jewely_metalweight = [];
        }
        this.search_jewely_metaltype = this.$session.get("search_jewely_metaltype");
        if(this.search_jewely_metaltype == undefined){
            this.search_jewely_metaltype = [];
        }
        this.search_jewely_type = this.$session.get("search_jewely_type");
        if(this.search_jewely_type == undefined){
          this.search_jewely_type = [];
        }else{
          this.search_jewely_type = [1];
        }
        this.search_jewely_item = this.$session.get("search_jewely_item");
        if(this.search_jewely_item == undefined){
            this.search_jewely_item = [];
        }
        this.SELECTTITEMMAP = this.$session.get("SELECTTITEMMAP");
        if(this.SELECTTITEMMAP == undefined){
            this.SELECTTITEMMAP = {};
        }

        //디자인 세공
        this.search_ds_min_weight = this.$session.get("search_ds_min_weight");
        if(this.search_ds_min_weight == undefined){
            this.search_ds_min_weight = 0;
        }
        this.search_ds_max_weight = this.$session.get("search_ds_max_weight");
        if(this.search_ds_max_weight == undefined){
            this.search_ds_max_weight = 100;
        }        
        this.search_ds_metalweight = this.$session.get("search_ds_metalweight");
        if(this.search_ds_metalweight == undefined){
            this.search_ds_metalweight = [];
        }
        this.search_ds_metaltype = this.$session.get("search_ds_metaltype");
        if(this.search_ds_metaltype == undefined){
            this.search_ds_metaltype = [];
        }



        //공통
        this.search_min_price = this.$session.get("search_min_price");
        if(this.search_min_price == undefined){
            this.search_min_price = null;
        }
        this.search_max_price = this.$session.get("search_max_price");
        if(this.search_max_price == undefined){
            this.search_max_price = null;
        }
        this.search_min_price2 = this.$session.get("search_min_price2");
        if(this.search_min_price2 == undefined){
            this.search_min_price2 = null;
        }
        this.search_max_price2 = this.$session.get("search_max_price2");
        if(this.search_max_price2 == undefined){
            this.search_max_price2 = null;
        }

      },   

      //현재 검색조건의 상품리스트 엑셀파일로 다운로드 (업로드 양식)
      excellist_download(){

        if(this.SEARCH_ACCNT_IDX == null){
          this.$fire({
            html: "Select a supplier to download the product Excel file<br><br>상품 엑셀파일을 다운로드 하려면 공급업체를 선택하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.SEARCH_PRODUCT_CLASS == -1){
          this.$fire({
            html: "Select an item to download the product Excel file<br><br>상품 엑셀파일을 다운로드 하려면 품목을 선택하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.SEARCH_PRODUCT_CLASS == 0){
          if(this.SEARCH_DIAMOND_CLASS == -1){
            this.$fire({
              html: "Select the diamond type to download the diamond product excel file<br><br>다이아몬드 상품 엑셀파일을 다운로드 하려면 다이아몬드 타입을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        this.$fire({
          html: "Do you want to download the product list that meets the current search conditions as an Excel file?<br><br>현재 검색조건에 해당하는 상품리스트를 엑셀파일로 다운로드 하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Download<br>다운로드</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            
            this.downloadExcelfile();

            /*
            if(this.SEARCH_PRODUCT_CLASS == 0 && this.SEARCH_DIAMOND_CLASS == 0){
              //천연 다이아

            }else if(this.SEARCH_PRODUCT_CLASS == 0 && this.SEARCH_DIAMOND_CLASS == 1){
              //펜시 다이아(컬러)

            }else if(this.SEARCH_PRODUCT_CLASS == 1){
              //젬스톤

            }else if(this.SEARCH_PRODUCT_CLASS == 2){
              //주얼리

            }
            */

          }
        });


      },

      downloadExcelfile(){
          this.progress_dialog2 = true;
          this.$http.post(this.$host+'/ProductListDownload',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type == true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , PRODUCT_CLASS : this.SEARCH_PRODUCT_CLASS
              , SEARCH_DIAMOND_CLASS : this.SEARCH_DIAMOND_CLASS
              , SEARCH_GEMSTONE_CLASS : this.SEARCH_GEMSTONE_CLASS
              , SEARCH_JEWELRY_TYPE : this.SEARCH_JEWELRY_TYPE
              , SEARCH_JEWELRY_ITEM : this.SEARCH_JEWELRY_ITEM
              , SEARCH_DESIGN_TYPE: this.SEARCH_DESIGN_TYPE
              , SEARCH_DELETE_SHOW : this.SEARCH_DELETE_SHOW

              , search_min_price: this.search_min_price != null && parseInt(this.search_min_price) > 0 ? this.search_min_price.toString() : null
              , search_max_price: this.search_max_price != null && parseInt(this.search_max_price) > 0 ? this.search_max_price.toString() : null
              , search_min_price2: this.search_min_price2 != null && parseInt(this.search_min_price2) > 0 ? this.search_min_price2.toString() : null
              , search_max_price2: this.search_max_price2 != null && parseInt(this.search_max_price2) > 0 ? this.search_max_price2.toString() : null
              , search_shape: this.search_shape
              , search_color: this.search_color
              , search_transparency: this.search_transparency
              , search_grind_total: this.search_grind_total
              , search_grind_polish: this.search_grind_polish
              , search_grind_syme: this.search_grind_syme
              , search_fluorescence: this.search_fluorescence
              , search_diamondcolor: this.search_diamondcolor
              , search_diamondtype: this.search_diamondtype
              , search_gemstone_type: this.search_gemstone_type
              , search_min_weight: this.search_min_weight
              , search_max_weight: this.search_max_weight
              , search_gem_min_weight: this.search_gem_min_weight
              , search_gem_max_weight: this.search_gem_max_weight
              , search_jewely_metalweight: this.search_jewely_metalweight
              , search_jewely_metaltype: this.search_jewely_metaltype
              , search_jewely_item: this.search_jewely_item

              , search_jw_min_weight: this.search_jw_min_weight
              , search_jw_max_weight: this.search_jw_max_weight
              , search_jw_min_gram: this.search_jw_min_gram
              , search_jw_max_gram: this.search_jw_max_gram
              , search_jw_name: this.search_jw_name == null || this.search_jw_name == undefined ?  '' : escape(this.search_jw_name)

              , search_ds_min_weight: this.search_ds_min_weight
              , search_ds_max_weight: this.search_ds_max_weight
              , search_ds_metalweight: this.search_ds_metalweight
              , search_ds_metaltype: this.search_ds_metaltype
              

              , search_certi: this.search_certi
              , search_gem_certi: this.search_gem_certi
              
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{responseType:'arraybuffer', headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.progress_dialog2 = false;
            if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else if(result.data.resultCode == 1){
              this.$fire({
                html: "Excel file download failed<br><br>엑셀 다운로드 실패",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              //다운
              const buffer = Buffer.from(result.data, 'binary');
              const blob = new Blob([buffer], { type: 'application/octet-stream' });
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(blob);
              if(this.SEARCH_PRODUCT_CLASS == 0 && this.SEARCH_DIAMOND_CLASS == 0){
                downloadLink.download = 'Diamond_'+Date.now().toString()+'.xlsx';
              }else if(this.SEARCH_PRODUCT_CLASS == 0 && this.SEARCH_DIAMOND_CLASS == 1){
                downloadLink.download = 'ColorDiamond_'+Date.now().toString()+'.xlsx';
              }else if(this.SEARCH_PRODUCT_CLASS == 1){
                downloadLink.download = 'Gemstone_'+Date.now().toString()+'.xlsx';
              }else if(this.SEARCH_PRODUCT_CLASS == 2){
                downloadLink.download = 'Jewelry_'+Date.now().toString()+'.xlsx';
              }else if(this.SEARCH_PRODUCT_CLASS == 3){
                downloadLink.download = 'Design_'+Date.now().toString()+'.xlsx';
              }
              downloadLink.click();
              URL.revokeObjectURL(downloadLink.href);
              downloadLink.remove();

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
            this.progress_dialog2 = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      


      changejewelrytype(){
        
        this.setJewelyItem(this.search_jewely_type);

        //선택값 복원
        this.getSelectJewelyItem();
        this.search_jewely_item = [...this.SELECTTITEMLIST];

      },


      //주얼리 아이템 선택을 변경하거나 위의 함수에서 this.search_jewely_item을 변경하면 실행됨.
      changejewelryitem(value){

        //value 중복값 제거
        value = [...new Set(value)];

        //선택 가능한 값만 처리
        const selectvalue = [];
        value.forEach(str=>{
          for(let i=0;i<this.PRODUCTITEMLIST.length;i++){
            if(this.PRODUCTITEMLIST[i].IDX == str){
              selectvalue.push(str);
              break;
            }
          }
        })

        const set = new Set([...selectvalue]);
        this.SELECTTITEMMAP[this.search_jewely_type] = [...set];
        
        //선택된 주얼리 아이템 복원
        this.getSelectJewelyItem();
      },

      getSelectJewelyItem(){
        //선택된 주얼리 아이템값 복원
        this.SELECTTITEMLIST.splice(0, this.SELECTTITEMLIST.length);
        //백업된 선택 리스트로 선택 리스트 재생성.
        for (const key in this.SELECTTITEMMAP) {
          if (this.SELECTTITEMMAP.hasOwnProperty(key)) {
            // 각 키(key)에 대한 처리
            const value = this.SELECTTITEMMAP[key];
            //console.log(`Key: ${key}, Value: ${value}`);
            this.SELECTTITEMLIST = this.SELECTTITEMLIST.concat(value);
          }
        }
        const set = new Set(this.SELECTTITEMLIST);
        this.SELECTTITEMLIST = [...set];
        
      },
      
      //mp4가 아닌 동영상 확인하기 (다른 웹사이트 띄우기)
      showsite(path){
        window.open(path, "_blank");
      },

      priceToString(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },


      //테이블 스크롤 감지 (그림자 스타일 표시)
      onTableScroll(event){
        if(event.target.scrollLeft > 1){
          this.isHorizon = true;
        }else{
          this.isHorizon = false;
        }
        if(event.target.scrollTop > 1){
          this.isVertical = true;
        }else{
          this.isVertical = false;
        }
      },

      //td클릭시 하일라이트 처리
      tdclick(event){
        const tdList = document.querySelectorAll('tbody td');
        tdList.forEach(function(td) {
          td.classList.remove('clicked');
        });        
        event.target.classList.add('clicked');
      },

      //마우스 오버 이벤트 (컬럼 사이즈 키우는 테스트 1차 성공)
      handleMousemove(event){
        
        //이동거리 알아내자! 근데 first_pos를 모른데! 그럴수있어!
        if(event.target.downflag){
          //드래그
          const subx = event.offsetX - event.target.FIRST_POS.x;
          console.log(`마우스 포인터의 상대 좌표 1: ${subx}`);
          event.target.style.width = event.target.FIRST_POS.width+subx + 'px';
          
        }else{
          if(event.offsetX > parseInt(event.target.style.width, 10)-20){
            event.target.style.cursor = "e-resize";
          }else{
            event.target.style.cursor = "default";
          }
        }
      },

      handleMousedown(event){
        if(event.offsetX > parseInt(event.target.style.width, 10)-20){
          console.log("down!!");
          event.target.downflag  = true;
          event.target.FIRST_POS = {x:event.offsetX, y:event.offsetY, width:parseInt(event.target.style.width, 10)};
        }

      },

      handleMouseup(event){
        event.target.downflag     = false;
        event.target.style.cursor = "default";
      },

      handleMouseleave(event){
        event.target.downflag     = false;
        event.target.style.cursor = "default";
      }

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.dialog_subtitle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: black;
}

.dialog_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:black;
}

.v-btn-toggle > .v-btn.v-btn {
  opacity: 1.0 !important;
}

.v-expansion-panels>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}



.tablediv{
  height:550px;
  overflow-x: auto;
  border: 1px solid #ccc;
}
table{
  width:100%;
  border-collapse: separate; 
  border-spacing:0;
  table-layout: fixed;
}


/*
td {
  border: 1px solid #C9C1C1;
  background:white;
}
*/

/* 컬럼헤드 위 고정 */
.fixed_thead{
  position: -webkit-sticky !important;
  position: sticky !important;
  top:0 !important;
  z-index:1 !important;
  background: #f0f0f0;
}
/* 테이블헤더 아래쪽 스크롤시 그림자 */
.thead_shadow{
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
}

/* 컬럼 고정 left값은 개별로 설정 */
.fixed_td{
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index:0 !important;
}
/* 좌우 스크롤시 그림자 */
.td_shadow{
  box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.2);
}

/* 컬럼헤더 고정 */
.fixed_th{
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index:1 !important;
  background:#f0f0f0;
}
/* 테이블 컬럼들 기본 라인은 우측과 아래만 */
td{
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-family: initial;
  font-size:12px;
  
}

/* 컨텐츠 컬럼 텍스트 스타일 */
.td_text{
  font-size:14px;
}

/* 컬럼헤더 텍스트 스타일 */
.thead_text{
  color:#222222;
  font-size:14px;
  font-weight: bold;
  line-height:15px;
  height:50px;
}

/* 홀수 행 배경색 */
tbody tr:nth-child(odd) td {
  background-color: white;
}
/* 짝수 행 배경색 */
tbody tr:nth-child(even) td {
  background-color: #fafbff;
}
/* 마우스 오버 로우 컬러 하일라이트 */
tbody tr:hover td {
  background-color: #feffcb;
}
/* td클릭시 하일라이트 하려면 */
td.clicked{
  border: 2px solid #4b89ff;
}


</style>