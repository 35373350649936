<template>
  <v-container fluid class="container_bg">

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h1>apiShapeList</h1>
      </v-col>
    </v-row>
    
    <v-divider/> 
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <p>Look up the shape code.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>HTTP Request</h3>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">POST</v-col>
      <v-col cols="12" md="8" class="fielddesc">/apiShapeList</v-col>
    </v-row>
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Request Body</h3>
        The request body contains data with the following structure.<br>Request Body is in the form x-www-form-urlencoded.
      </v-col>
    </v-row>
    
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_TYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">Number | <strong>Required</strong><br>
              Sets the shape type.
            </td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">0</td>
            <td width="80%">Diamond</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">1</td>
            <td width="80%">Gemstone</td>
          </tr>
        </table>
      </v-col>
    </v-row>
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Response Body</h3>
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12" md="11" class="fieldname"><h4>JSON representation</h4></v-col>
      <v-col cols="12" md="11" class="fielddesc pa-0">
        <pre translate="no" style="background:#fafafa">
        {
          "resultCode": 0,
          "resultData": [
            {
                "SHAPE_IDX": 1,
                "SHAPE_NAME_ENG": "Round",
            }
            ...
        }          
        </pre>
      </v-col>
      
    </v-row>

    <v-row justify="center" dense class="mt-5 mb-10">
      <v-col cols="12" md="11" class="fieldname"><h4>Fields</h4></v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_IDX</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">
        shape index code
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_NAME_ENG</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">
        English name for shape
      </v-col>
    </v-row>


    <v-row justify="center" dense>
    </v-row>



  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    methods: {

      test(){



      }

    },

    created(){
      //apiProductList
    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

table{
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #eaeaea;
  padding: 10px;
}
.fieldname {
  background: #f1f3f4;
  border: 1px solid #eaeaea;
}
.fielddesc {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
}

</style>