<template>

  <v-container fluid style="background:white;min-height:100%">

    <v-row align="center" justify="start" class="mt-1">
      <v-col cols="12" md="4" sm="12" xs="12" align-self="center">
        <span><v-icon color="black" @click="back">mdi-chevron-left</v-icon><label class="menutitle">News, Notice 뉴스, 공지사항</label></span>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row align="center" justify="start" >
      <v-col cols="12" md="3">
        <v-row dense>
          <v-col cols="6" class="pa-0">
            <v-btn :class="select_type == 0 ? 'enable_type' : 'disable_type'"  width="100%" :color="select_type == 0 ? 'black' : 'white'" elevation="0" @click="type_select(0)">공지사항</v-btn>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn :class="select_type == 1 ? 'enable_type' : 'disable_type'" width="100%" :color="select_type == 1 ? 'black' : 'white'" elevation="0" @click="type_select(1)">뉴스</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col class="ma-0 pa-0">
        <div class="black_line"/>
      </v-col>
    </v-row>
    
    <v-row justify="center" dense no-gutters class="ma-0 pa-0 pt-4">
      <v-col cols="10">
        <v-row align="center">
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="width:80px;color:#3d3d3d;font-weight:bold">No&nbsp;<span style="font-size:10px">번호</span></label>
          </v-col>
          <v-col cols="6" md="6">
            <label style="width:320px;color:#3d3d3d;font-weight:bold">Title&nbsp;<span style="font-size:10px">제목</span></label>
          </v-col>
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="width:200px;color:#3d3d3d;font-weight:bold">Date&nbsp;<span style="font-size:10px">등록 일자</span></label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <div style="height:1px;background:#000000"/>
      </v-col>
    </v-row>
    
  <v-row justify="center" style="min-height:400px" dense no-gutters class="ma-0 pa-0 mt-3">
    <v-col cols="10">
        <v-row v-for="(item, index) in itemList" :key="index" align="center" class="broadrow_line">
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="color:#3d3d3d;" class="text-md-body-1 text-body-2">{{ item.BOARD_IDX }}</label>
          </v-col>
          <v-col md="6" lg="6" xl="6" sm="9" xs="9" cols="9">
            <template v-if="item.BOARD_FIX == 1">
              <a style="color:red;" class="text-md-body-1 text-body-2" @click="loadnotice(item)" >{{item.BOARD_TITLE}}</a>
            </template>
            <template v-else>
              <a style="color:#3d3d3d;" class="text-md-body-1 text-body-2" @click="loadnotice(item)" >{{item.BOARD_TITLE}}</a>
            </template>
          </v-col>
          <v-col cols="3" class="hidden-sm-and-down">
            <label style="color:#3d3d3d;" class="text-md-body-1 text-body-2">{{ item.REG_DATE }}</label>
          </v-col>
        </v-row>
    </v-col>
  </v-row>


  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>

  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>

<script>

export default {

    data: () => ({

      select_type:0,

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 1,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: 'No 번호', value: 'BOARD_IDX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: 'Title 제목', value: 'BOARD_TITLE', sortable: false, align:'left', class:"cheader_normal"},
        {text: 'Date 등록 일자', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
      ],
      headersmobile: [
        {text: 'Title 제목', value: 'BOARD_TITLE', sortable: false, align:'left', class:"cheader_normal"},
        {text: 'Date 등록 일자', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

    }),


    beforeDestroy() {

    },

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.select_type = this.$route.query.select_type;
      if(this.select_type == undefined || this.select_type == null){
        this.select_type = 0;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.$refs.searchbar.setSearch(this.search_keyword);
      }

      this.loadpage(this.page);
    },

    create(){
      
    },

    methods: {
      
      type_select(type_index){
        
        this.select_type = type_index;
        this.page = 1;
        this.search_run();

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/Notice", query:{select_type:this.select_type, page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/GuestBoardList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , BOARD_TYPE:this.select_type
          })
          .then((result)=>{

            if(result.data.resultCode == 0){

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    if(element.FILE1 != undefined && element.FILE1 != null && element.FILE1.length > 0){
                      const last = element.FILE1.split("/")
                      element.FILENAME =  decodeURI(last[last.length-1]);
                    }else{
                      element.FILENAME = "";
                    }
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      
      
      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },      

      //게시글 상세보기
      loadnotice(item){
        this.$router.push({path:"/BoardDetail", query:{code:item.BOARD_IDX}});
      },


      back(){
        //뒤로
        this.$router.back();
      },
      
    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.menutitle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.enable_type{
  color:white !important;
  border-radius: 0px !important;
}

.disable_type{
  color:#D9D9D9 !important;
  border-radius: 0px !important;
  border: 1px solid #D9D9D9 !important;
}


.black_search_btn{
  color: #ffffff !important;
  background-color: #000000 !important;
  font-size: 16px !important;
  border-radius: 0px !important;
  height: 49px !important;
  width:100% !important;
}

.input_design{
  color: black !important;
  border-color: black !important;
  border: black 3px solid;
  border-radius: 0px !important;
}

</style>