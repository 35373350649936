<template>
<v-row align="center"  justify="center">

    <v-dialog scrollable overlay-color="#000000FF" persistent v-model="pdf_dialog" 
        fullscreen style="z-index:9999"
        class="none_radius">

          <WebViewer 
            ref="webviewer"
            style="margin-top:40px;background:white"
            :path="`${publicPath}lib`" :data="pdfcontent" :bb="pdfbuffer" />
            
          <div class="d-flex justify-end" style="position:fixed;width:100%;height:40px;background:black;left:0px;padding-top:4px">
            <v-btn download :href="pdfcontent" icon><v-icon color="white">mdi-download</v-icon></v-btn>
            <v-btn @click="close" icon><v-icon color="white">mdi-close</v-icon></v-btn>
          </div>

    </v-dialog>
</v-row>
</template>

<script>
//PDFTRON WEBDRIVER로 작업 
//vue 2.6 호환을 위해 public폴더 내부에 lib에 라이브러리 소스가 있고 path로 지정해야함.
export default {

  data(){
    return{
        pdf_dialog:false
      , publicPath: process.env.BASE_URL
      , pdfcontent:null
      , pdfbuffer:null
      , isFirst : true
      
    }
  },

  mounted(){

  },


  methods: {

    download(){

    },

    close(){
      this.pdf_dialog = false;
    },

    geturl(url){
      this.pdfbuffer = null;
      this.pdfcontent = null;
      this.getPdf(url, 1);
    },

    async getfile(file){
      this.pdfbuffer  = null;
      this.pdfcontent = null;
      var typedarray  = await file.arrayBuffer();
      await this.getPdf(typedarray, 2);
    },


    async getPdf(pdfdata, type) {
      this.pdf_dialog = true;

      //최초에 빠르게 읽으면 PDF뷰어가 생기기 전이라 오류남.
      if(this.isFirst){
        
        this.isFirst = false;
        
        if(type == 1){
          let pdfurl = "";
          if(String(pdfdata).startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){
            //우리측 PDF면 바로 로드
            pdfurl = pdfdata;
          }else{
            //다른 업체 PDF면 CORS우회
            pdfurl = this.$host+'/GuestPdfInfo?path='+pdfdata;
          }
          //const path = `${process.env.BASE_URL}webviewer`;
          this.pdfcontent = pdfurl;
          try{
            await this.$refs.webviewer.reloadurl(pdfurl);
          // eslint-disable-next-line no-empty
          }catch(e){
          }
        }else{
          setTimeout( async () => await this.getPdfTime(pdfdata, type), 1000);
        }
        
      }else{
        if(type == 1){
          let pdfurl = "";
          if(String(pdfdata).startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){
            //우리측 PDF면 바로 로드
            pdfurl = pdfdata;
          }else{
            //다른 업체 PDF면 CORS우회
            pdfurl = this.$host+'/GuestPdfInfo?path='+pdfdata;
          }
          //const path = `${process.env.BASE_URL}webviewer`;
          this.pdfcontent = pdfurl;
          try{
            await this.$refs.webviewer.reloadurl(pdfurl);
          // eslint-disable-next-line no-empty
          }catch(e){
          }
          
        }else{
          //파일
          //console.log("pdfdata1  : " + pdfdata);
          this.pdfbuffer = pdfdata;
          try{
            await this.$refs.webviewer.reloadfile(pdfdata);
            // eslint-disable-next-line no-empty
          }catch(e){
            await this.$refs.webviewer.reloadfile(pdfdata);
          }
          //this.$refs.webviewer.loadfile();
        }
      }
      
    },

    async getPdfTime(pdfdata, type){

      if(type == 1){
        var pdfurl = "";
        if(String(pdfdata).startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){
          //우리측 PDF면 바로 로드
          pdfurl = pdfdata;
        }else{
          //다른 업체 PDF면 CORS우회
          pdfurl = this.$host+'/GuestPdfInfo?path='+pdfdata;
        }
        //const path = `${process.env.BASE_URL}webviewer`;
        this.pdfcontent = pdfurl;
        try{
          await this.$refs.webviewer.reloadurl(pdfurl);
        // eslint-disable-next-line no-empty
        }catch(e){
        }
        
      }else{
        //파일
        //console.log("pdfdata1  : " + pdfdata);
        this.pdfbuffer = pdfdata;
        try{
          await this.$refs.webviewer.reloadfile(pdfdata);
          // eslint-disable-next-line no-empty
        }catch(e){
          await this.$refs.webviewer.reloadfile(pdfdata);
        }
        //this.$refs.webviewer.loadfile();
      }

    }
  }
};
</script>

<style>


</style>