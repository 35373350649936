<template>
  <v-container fluid class="pa-0 ma-0" style="background:white">
    
    <v-row justify="center">
      <v-col cols="11" md="8" align="center" class="mt-5 mt-md-15">
        <div class="mt-5 mt-md-15" 
             style="color:black;font-weight:bold"
             :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
             >KS 규격 감정서 설명</div>

        <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS 규격이란</div>
        <div class="mt-0 notosanskr_font" style="text-align:left;">‘KS’는 ‘Korean Industrial Standards’의 약자로 산업표준 심의회의 조사, 심의를 거쳐 정부가 제정한 대한민국의 국가 표준입니다.</div>

        <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS 다이아몬드 감정 규격</div>
        <div class="mt-0 notosanskr_font" style="text-align:left;">연마된 다이아몬드 감정에 대한 KS 규격은 KS D 2371-1 용어의 정의 및 분류, KS D 2371-2 검사 방법에 명기되어 있으며, 국제규격(ISO)을 기초로 제정되었습니다.</div>

        <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS 다이아몬드 검사 방법</div>
        <div class="mt-0 notosanskr_font" style="text-align:left;">불규칙한 다이아몬드 감정 결과에 의한 소비자 피해를 예방하고, 소매상과 소비자와의 분쟁이 발생하지 않도록 대한민국의 한국산업표준(KS)을 적용하여 감정을 하는 국제표준 시스템입니다.</div>

        <div class="ml-1 mt-3 notosanskr_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS 다이아몬드 감정 결과</div>
        <div class="mt-0 notosanskr_font" style="text-align:left;">다이아몬드 위원회가 지정한 3곳의 KS 규격 감정기관들(미래보석감정원, 서울보석감정원, 한미보석감정원)이 공동으로 엄격하게 감정한 결과는 국제 기준에 부합한 등급임을 확인합니다.</div>

        <div class="mt-5 mt-md-15" 
             style="color:black;font-weight:bold"
             :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
             >Description of KS Certification</div>
        <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          What is KS Standard?</div>
        <div class="mt-0 inter_font" style="text-align:left;">‘KS’ is an abbreviation for Korean Industrial Standards. It is the national standard of Korea established by the government after deliberation.</div>

        <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS Diamond Appraisal Standard</div>
        <div class="mt-0 inter_font" style="text-align:left;">The KS standard for polished diamond appraisal is specified in KS D 2371-1 Definition and Classification of Terms and KS D 2371-2 Inspection Method, and was established based on the International Standard (ISO).</div>

        <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS Diamond Inspection Method</div>
        <div class="mt-0 inter_font" style="text-align:left;">It is an international standard system for appraisal by applying Korean Industrial Standards (KS) to prevent consumer damage due to irregular diamond appraisal results and prevent disputes between retailers and consumers.</div>

        <div class="ml-1 mt-3 inter_font" style="text-align:left;text-weight:bold;color:black;font-size:30px" :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'">
          KS Diamond Appraisal Result</div>
        <div class="mt-0 inter_font" style="text-align:left;">The results of rigorous evaluation jointly by three KS standard appraisal agencies designated by the Diamond Commission (Mirae Gem Laboratory Co., Ltd.,  Gemological Institute Of Seoul, Hanmi Gemological Institute & Lab) confirm that the grade meets international standards.</div>

      </v-col>
    </v-row>

    <v-row justify="center">

        <v-col cols="12" md="8">

          <div class="mt-5 mt-md-10" 
             style="color:black;font-weight:bold;text-align:center"
             :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
             >KS 규격 감정서 표준 약관</div>

            <ol class="ml-2 mr-2 mt-5 mt-md-5 notosanskr_font" type="1" start="1">
                <li>본 감정서는 의뢰된 감정서의 감정 당시 상태를 (단체인증 제도에서 정한) 4C 시스템에 의하여 등급화 한 것입니다.</li>
                <li>중량 단위는 메트릭 캐럿 (Metric Carat)으로 표기하며, 전자저울로 측정하여 소수점 셋째 자리에서 8 이하는 버리고 9에서만 올림 하여 소수점 둘째 자리까지 표기한 것입니다.</li>
                <li>의뢰되는 다이아몬드는 나석 상태를 원칙으로 하며, 세팅된 상태에서 감정된 경우에는 감정 결과에 오차가 생길 수 있습니다.</li>
                <li>본 감정서의 감정 내용은 감정 당시의 기술력을 바탕으로 한 것이므로 미래의 발전된 기술 및 감별 장비의 발전으로 인하여 감정 및 감별 결과에 차이가 생길 수 있습니다.</li>
                <li>다음의 경우에는 감정서가 무효화됩니다.<br>(1) 감정서 내용의 변조, 가필 또는 수정된 경우<br>(2) 감정 후 해당 다이아몬드를 가공 또는 변형시킨 경우</li>
                <li>복사된 감정서는 원본의 효력을 대신할 수 없습니다.</li>
                <li>본 감정원은 감정 의뢰인의 동의 없이 비파괴 검사를 통하여 의뢰된 다이아몬드의 천연, 합성 및 인공 처리 등에 대한 실험을 할 수 있습니다.</li>
                <li>수집된 정보 및 모든 자료는 다이아몬드 산업의 발전을 위하여 사용될 수 있으며 지적재산권은 본 감정원에 귀속됩니다.</li>
                <li>본 감정서는 가격 평가서가 아닌 단지 의뢰된 다이아몬드의 특징과 상태에 대한 내용을 표기한 것으로 그 내용에 관해서는 신뢰할 수 있는 전문가 또는 판매점의 도움을 받을 것을 권합니다.</li>
            </ol>
            <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>

          <div class="mt-5 mt-md-10" 
             style="color:black;font-weight:bold;text-align:center"
             :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
             >Terms and Conditions of KS Certification</div>
            <ol class="ml-2 mr-2 mt-5 mt-md-5 inter_font" type="1" start="1">
                <li>This certificate is a rating of the requested status at the time of appraisal by the 4C system (as prescribed by the collective certification system).</li>
                <li>The unit of weight is expressed as a metric carat, and measured by an electronic scale, 8 or less from the third decimal place is discarded and raised only from 9 to the second decimal place.</li>
                <li>In principle, the requested diamond is a loose stone and if it is set in a product, there may be errors in the evaluation result.</li>
                <li>Since the appraisal contents of this are based on the technical skills at the time of appraisal, there may be differences in the discrimination results due to the development of technologies and equipment in the future.</li>
                <li>The certificate will be invalidated in the following cases.<br>
                    (1) If the contents are revised, falsified, or altered.<br>
                    (2) If the diamond is processed or altered after the evaluation.</li>
                <li>A copied certificate cannot replace the validity of the original.</li>
                <li>This Appraisal Board can conduct experiments on the natural, synthetic, and artificial treatment of the requested diamonds with non-destructive testing without the consent of the client.</li>
                <li>The collected information and all data can be used for the development of the diamond industry, and intellectual property rights belong to this Appraisal Board.</li>
                <li>This certificate is not a price assessment, it is a description of the characteristics and condition of the requested diamond, and we recommend that you get help from a trusted expert or dealer.</li>
            </ol>
        </v-col>
    </v-row>



    <v-row class="pt-15"></v-row>

  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    created(){

    },

    methods: {



    },



}
</script>

<style scoped>

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>