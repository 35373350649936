const methods = {

    //숫자만    : /[^0-9]/g
    //숫자 .    : /[^0-9.]/g
    //숫자 -    : /[^0-9-]/g

    /*
     리턴값에 따른 밸유체크 에러 타입
     0: 정상
     1: 널값
     2: 정규표현식 오류
     3: 최소값 오류
     4: 최대값 오류
    */
    
     /*
      예제
      checkValReg('123',null, -1, -1);  값만 체크
      checkValReg('123', /[^0-9]/g, 5, 10); 숫자만, 5~10자리

        const check = cmvalidation.checkValReg('', /[^0-9]/g, 1, 2);
        if(check == 1){
            
        }else if(check == 2){
            
        }else if(check == 3){
            
        }else if(check == 4){
            
        }

      import cmvalidation from '../assets/js/cmvalidation.js'
     */

    checkValReg(value, reg, minlen, maxlen, regflag){

        
        if(regflag == undefined){
            regflag = true;
        }

        if(value == null || value == undefined || value.length < 1){
            return 1;
        }
        
        if(reg != null){
            if(regflag){
                if(reg.test(value)){
                    return 2;
                }
            }else{
                if(!reg.test(value)){
                    return 2;
                }
            }
        }

        if(minlen > -1){
            if(minlen > value.length){
                return 3;
            }
        }

        if(maxlen > -1){
            if(maxlen < value.length){
                return 4;
            }
        }

        return 0;

    },

    isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
            return true;
        }else{
            return false;
        }
    }

}

export default methods
