<!-- 무게 입력창 -->
<template>
  <div>
    <input ref="inputtext" :class="$vuetify.breakpoint.smAndDown ? 'inputstyle_mb' : 'inputstyle'" @keydown.enter="enterinput"
      v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/>
  </div>
</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
          value:Number,
        },

        computed: {
          displayValue: {
              get: function() {
                //console.log("get...");
                  if (this.isInputActive) {
                      if(this.value == null || this.value == undefined){
                        return;
                      }else{
                        return this.value.toString();
                      }
                      
                  } else {
                      if(this.value == null || this.value == undefined){
                        return;
                      }else{
                        return this.value.toFixed(2);
                      }
                  }
              },
              set: function(modifiedValue) {
                //console.log("set...");
                  let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                  if (isNaN(newValue)) {
                      newValue = 0
                  }
                  this.$emit('setprice', newValue);
              }
          }
        },


        data: ()=> ({
          isInputActive: false
        }),

        methods: {
          enterinput(){
            this.$refs.inputtext.blur();
          }
        },

    }
</script>

<style scoped>

.inputstyle{
  border: 1px solid #888;
  border-radius: 10px;
  font-size: 16px;
  height: 34px;
  min-height:34px;
  width: 100%;
  padding-left: 10px;
  padding-top: 3px;
}

.inputstyle_mb{
  border: 1px solid #888;
  border-radius: 5px;
  font-size: 16px;
  height: 32px;
  min-height:25px;
  width: 100%;
  padding-left: 5px;
  padding-top: 3px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* WebKit (Chrome, Safari, etc.) */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>