<template>
  <v-container fluid class="container_bg">

    <v-row align="center" justify="center" class="mt-5" dense>
      <v-col cols="9" md="9">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-spacer/>
      <template v-if="grade == 99">
        <v-col cols="12" md="3" class="pa-0 pl-3 pl-md-0 pr-3 pr-md-0">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="3" class="pa-0"/>
      </template>

      <v-col cols="12" md="3" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_PAYTYPE" :items="PAYTYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="search_run"
          />
      </v-col>
      
    </v-row>

    <v-row align="center" dense>
      <v-spacer/>
      <v-col cols="12" md="2" sm="12" xs="12">
        <v-menu
          v-model="date1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_start_date"
              label="검색 시작일"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_start_date" @input="date1 = false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>      
      </v-col>
      <v-col cols="12" md="2" sm="12" xs="12">                      
        <v-menu
          v-model="date2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="search_end_date"
              label="검색 종료일"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="search_end_date" @input="date2=false" :first-day-of-week="0" locale="ko-kr"></v-date-picker>
        </v-menu>                    
      </v-col>
      <v-col cols="12" md="1" sm="6" xs="6" class="d-flex justify-center">
        <v-checkbox v-model="checkdate" dense label="일자별보기" @change="search_run"/>
      </v-col>
      <v-col cols="6" md="2" class="pa-0 pr-3 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="startDownload">
          <v-icon left>mdi-download-box</v-icon>Excel Download<br>엑셀다운
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense justify="center">
      <v-col cols="6" md="2" sm="6" xs="6" style="padding:2px"> 
        <div class="chartsum_round">
          <label class="chartsum_title">송금건수</label>
          <label class="chartsum_value">{{priceToString(sum1)}}</label>
        </div>
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6" style="padding:2px">
        <div class="chartsum_round">
          <label class="chartsum_title">달러</label>
          <label class="chartsum_value">{{priceToString(sum2)}}</label>
        </div>
      </v-col>
      <v-col cols="6" md="2" sm="6" xs="6" style="padding:2px">
        <div class="chartsum_round">
          <label class="chartsum_title">원화</label>
          <label class="chartsum_value">{{priceToString(sum3)}}</label>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col>
        <v-simple-table 
          height="40vh"
          dense fixed-header
          class="datatablestyle"
          >
          <template v-slot:default>
            <thead style="height:48px">
              <tr>
                <th class="text-center header_text_style">날짜</th>
                <th class="text-center header_text_style">건수</th>
                <th class="text-center header_text_style">달러</th>
                <th class="text-center header_text_style">원화</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color:#f0f0f0">
                <td class="text-center">합계</td>
                <td class="text-center">{{ priceToString(sum1) }}</td>
                <td class="text-center">{{ priceToString(sum2) }}</td>
                <td class="text-center">{{ priceToString(sum3) }}</td>
              </tr>
              <tr v-for="item in list" :key="item.REG_DATE">
                <td class="text-center">{{ item.REG_DATE }}</td>
                <td class="text-center">{{ priceToString(item.CNT) }}</td>
                <td class="text-center">{{ priceToString(item.PRICE_USD) }}</td>
                <td class="text-center">{{ priceToString(item.PRICE_WON) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10">
        <canvas ref="drawarea" style="width:95%"/>
      </v-col>
    </v-row>

  </v-container>
</template>
<script>

import Chart from 'chart.js/auto';
import moment from 'moment'
import XLSX from 'xlsx'
export default {


  beforeDestroy(){
    
    //차트 초기화 - 제대로 안지우면 인스턴스 계속 쌓인다.
    if(this.myChart2 != null){
      this.myChart2.destroy();
    }
  },

  mounted(){

    if( this.$session.get("GRADE") < 5 || (this.$session.get("GRADE") == 5 && this.$session.get("SUB_DAYS") < 0) ){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
    }

    this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
    this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
    this.grade = this.$session.get("GRADE");

    this.search_start_date = moment().subtract(1, 'YEARS').format("YYYY-MM-DD");
    this.search_end_date   = moment().format("YYYY-MM-DD");

    if(this.$session.exists("ACCNT_IDX")){
      this.initpage();
    }else{
      //키값 없으면 로그인으로
      this.$router.push("/Login");
    }
  },

  data: () => ({
      myChart2: null,
      date1:false,
      date2:false,
      search_start_date : '',
      search_end_date : '',
      checkdate : true,
      list:[],
      sum1:0,
      sum2:0,
      sum3:0,
      sum4:0,
      sum5:0,

      SEARCH_PAYTYPE:0,
      PAYTYPELIST: [
          {name:'All/전체',   value:0}
        , {name:'USD/달러',   value:1}
        , {name:'WON/원화',   value:2}
      ],

      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,

  }),

  methods: {
    

    startDownload(){

      this.$confirm("엑셀파일로 다운로드 하시겠습니까?").then(() => {

        var templist = [];
        this.list.forEach(item=>{
          templist.push({
            "REG_DATE":item.REG_DATE
          , "CNT":parseInt(item.CNT)
          , "DOWN_USD":parseFloat(item.PRICE_USD)
          , "DOWN_WON":parseInt(item.PRICE_WON)
          });
        });

        //하단 합계 넣기
        templist.push({
              "REG_DATE":"합계"
            , "CNT1":this.sum1
            , "COST1":this.sum2
            , "COST2":this.sum3
        });
        
        var item_sheet = XLSX.utils.json_to_sheet(templist);

        //상단 컬럼명 수정
        item_sheet.A1 = {v:'날짜'};
        item_sheet.B1 = {v:'송금건수'};
        item_sheet.C1 = {v:'달러'};
        item_sheet.D1 = {v:'원화'};

        //컬럼별 넓이
        var wscols = [{wch:20},{wch:10},{wch:10, t:'n'},{wch:10,t:'n'}];
        item_sheet['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, item_sheet, '공급가입금현황');
        XLSX.writeFile(wb, '공급가입금현황_'+Date.now().toString()+'.xlsx');

      });

    },    

    initpage(){

      this.search_run();

    },


    search_run(){

        if(this.grade < 99){
          if(this.SEARCH_ACCNT_IDX == null){
            this.$alert("검색창 상단의 공급처를 선택해주세요.");
            return;
          }
        }

        if(this.search_start_date.length < 4){
          this.$alert("검색 시작일을 입력하세요.");
          return;
        }
        if(this.search_end_date.length < 4){
          this.$alert("검색 종료일을 입력하세요.");
          return;
        }
        if(this.search_start_date > this.search_end_date){
          this.$alert("시작일이 종료일보다 큽니다.");
          return;
        }


      this.$http.post(this.$host+'/ChartWithdrawal',{
            ACCNT_IDX: this.SEARCH_ACCNT_IDX
          , SEARCH_PAYTYPE:   this.SEARCH_PAYTYPE
          , CHECKDATE: this.checkdate
          , search_start_date:this.search_start_date == undefined ? '' : this.search_start_date
          , search_end_date:this.search_end_date == undefined ? '' : this.search_end_date
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        
        

        if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            //chart.js로 차트를 그린다. 캔버스를 읽어서 차트객체를 만들어주면 알아서 생성된다.
            const ctx2 = this.$refs.drawarea;

            //캔버스 초기화
            if(this.myChart2 != null){
              this.myChart2.destroy();
            }
            //ctx2.getContext("2d").clearRect(0, 0, ctx2.width, ctx2.height);
            this.list.splice(0, this.list.length);   //데이터 초기화
            this.list = result.data.resultData;

            

            this.sum1 = 0;
            this.sum2 = 0;
            this.sum3 = 0;

            if(this.checkdate){
              
              const datanames = [];
              const cost1 = []; //건수
              const cost2 = []; //달러
              const cost3 = []; //원화
              this.list.slice().reverse().forEach(element => {
                datanames.push(element.REG_DATE);
                cost1.push(element.CNT);
                cost2.push(element.PRICE_USD);
                cost3.push(element.PRICE_WON);
                this.sum1 += parseInt(element.CNT);
                this.sum2 += parseFloat(element.PRICE_USD);
                this.sum3 += parseInt(element.PRICE_WON);
              });

              const datas = {
                labels:datanames, //차트 하단에 표시됨
                datasets:[

                    {label:'건수',  data:cost1, backgroundColor: 'rgb(9, 156, 253)',borderColor: 'rgb(9, 156, 253)', lineTension:0.3, fill: false,}
                  , {label:'달러',  data:cost2, backgroundColor: 'rgb(129, 66, 255)',borderColor: 'rgb(129, 66, 255)', lineTension:0.3, fill: false,}
                  , {label:'원화',  data:cost3, backgroundColor: 'rgb(255, 67, 107)',borderColor: 'rgb(255, 67, 107)', lineTension:0.3, fill: false,}

                ],
              };

              const options = {
                plugins: {
                    datalabels: {
                      color: '#ff0000'
                    },           
                    title: {
                        display: true,
                        text: '공급가 입금 현황'
                    },              
                    legend: { 
                        position: 'right' 
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function(tooltipItem, tdata) {
                            var value1 = tdata.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            return parseFloat(value1).toLocaleString();
                          }
                        }
                    },
                },                
                hover: {
                    mode: 'nearest',
                    intersect: true
                },

                scales: {
                    x: {
                        display: true,
                        ticks:{reverse:true},
                        scaleLabel: {
                            display: true,
                            labelString: '날짜'
                        }
                    },
                    y: {
                        display: true,
                        ticks: {
                            suggestedMin: 0,
                        },                        
                        title: {
                            display: true,
                            text: '금액'
                        }
                    }
                },    


                responsive: true,
                maintainAspectRatio: true,
                aspectRatio : 3
              };     

              //차트 객체에는 캔버스 객체와 타입, 데이터, 옵션이 들어간다.
              //eslint-disable-next-line no-unused-vars
              this.myChart2 = new Chart(ctx2, {type:'bar', data:datas, options: options});

            }else{

              const cost = []; //접수
              const cost1 = []; //건수
              const cost2 = []; //달러
              const cost3 = []; //원화
              cost1.push(result.data.resultData[0].CNT);
              cost2.push(result.data.resultData[0].PRICE_USD);
              cost3.push(result.data.resultData[0].PRICE_WON);
              this.sum1 = parseInt(result.data.resultData[0].CNT);
              this.sum2 = parseFloat(result.data.resultData[0].PRICE_USD);
              this.sum3 = parseInt(result.data.resultData[0].PRICE_WON);

              const datas = {
                labels:[ "건수 "+this.priceToString(result.data.resultData[0].CNT)
                        ,"달러 "+this.priceToString(result.data.resultData[0].DOWN_USD)
                        ,"원화 "+this.priceToString(result.data.resultData[0].DOWN_WON)
                       ],
                datasets:[
                    { data:cost, backgroundColor: ['rgb(9, 156, 253)','rgb(129, 66, 255)','rgb(255, 67, 107)'], borderColor: ['rgb(9, 156, 253)', 'rgb(129, 66, 255)', 'rgb(255, 67, 107)']}
                ],
              };

              const options = {
                title: {
                    display: true,
                    text: '공급가 입금 현황'
                },         
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                      label: function(tooltipItem, tdata) {
                        var value1 = tdata.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return parseFloat(value1).toLocaleString();
                      }
                    }
                },
                scales: {
                    xAxes: [{
                        display: false,
                        scaleLabel: {
                            display: false,
                            labelString: '날짜'
                        }
                    }],
                    yAxes: [{
                        display: false,
                        ticks: {
                            suggestedMin: 0,
                            beginAtZero:true,
                        },
                        scaleLabel: {
                            display: false,
                            labelString: '금액'
                        }
                    }]
                },    
                responsive: true,
                maintainAspectRatio: false
              };     
              //eslint-disable-next-line no-unused-vars
              this.myChart2 = new Chart(ctx2, {type:'pie', data:datas, options: options});

            }

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$fire({
            html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
              this.$EventBus.$emit('clearsession');
            }
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        
        if(error.message == 'Request failed with status code 429')
        {
          this.$fire({
            html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }else{
          this.$fire({
            html: error.message,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }
      });

    },

    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    //검색조건 업체 설정
    comsearchcb(val1){
      if(val1.NAME.length > 0){
        this.SEARCH_ACCNT_NAME = val1.NAME;
        this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
        this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
        this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
      }else{
        this.SEARCH_ACCNT_NAME = '';
        this.SEARCH_ACCNT_IDX  = null;
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
      }
      this.search_run();
    },

    // eslint-disable-next-line no-unused-vars
    comsearchclear(var2){
        this.SEARCH_ACCNT_IDX    = null;
        this.SEARCH_ACCNT_NAME   = '';
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
        this.search_run();
    },      


  }
    
}

</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>