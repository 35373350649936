<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%">

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <v-row justify="center" class="mt-md-15 mt-10">
      <v-col cols="11" md="8">
        <label style="color:#000000" class="text-md-h4 text-subtitle-2">{{BOARD_CONTENT.BOARD_TITLE}}</label>
      </v-col>
    </v-row>
    <v-row dense justify="center" align="center">
      <v-col cols="11" md="8" class="d-inline-flex justify-center align-center" align-self="center">
        <label style="font-size:16px;color:#919191" class="inter_font text-md-h6 text-body-2">{{BOARD_CONTENT.UP_DATE}}</label>
        <v-spacer/>
        <v-btn @click="back" elevation="0" text class="inter_font text-md-h6 text-body-2">List</v-btn>
      </v-col>
    </v-row>

    <v-row  dense justify="center">
      <v-col cols="11" md="8">
        <div class="black_line"/>
      </v-col>
    </v-row>
      
    <!-- 
    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <div v-html="BOARD_CONTENT.DESC"/>
      </v-col>
    </v-row>
    -->

    <v-row align="start" justify="center">
      <v-col cols="11" md="8">
        <viewer 
          v-if="BOARD_CONTENT.BOARD_DESC != null" 
          :initialValue="BOARD_CONTENT.BOARD_DESC" 
          @load="onEditorLoad"
          />
      </v-col>
    </v-row>


    <v-row style="height:100px"></v-row>

  </v-container>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from '@toast-ui/vue-editor';
import youtubeUrl from 'youtube-url'
export default {

    components: {
      viewer: Viewer
    },

    data: () => ({

      show1: false,
      loading : false,    //로딩 표시
      progress_dialog:false,

      BOARD_IDX:"",        //넘겨받은 게시글 번호
      BOARD_CONTENT:{},   //게시글 상세정보
      itemList: [],       //게시글 답글 댓글 리스트

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      dialog_delete1: false,

      defaultItem: {
        BOARD_IDX:null,
      },
      editedItem: {
      },

      numberrules:[v => Number.isInteger(Number(v)) || '숫자만 사용할 수 있습니다.'],

    }),


    beforeDestroy() {
    },

    mounted(){
      this.BOARD_IDX = this.$route.query.code;
      this.loadpage();
    },

    create(){
      
    },

    methods: {

      onEditorLoad (v) {
        const el  = v.preview.el
        this.html = this.checkimg(el);
        this.html = this.addYoutubeIframe(el);
      },

      //이미지 무조건 100%로 표시
      checkimg(el){
        const len = el.firstChild.children
        for (const p of len) {
          for (const img of p.children) {
            if(img.src){
              img.style.width = "100%";
              ////console.log("img : " + img);
            } 
          }
        }
        return el.innerHTML   
      },

      setIframe(id){
        const div = document.createElement('div');
        div.style.position  = "relative";
        div.style.overflow  = "hidden";
        div.style.width     = "100%";
        div.style.paddingTop = "56.25%";

        const iframe = document.createElement('iframe');
        iframe.style.position = "absolute";
        iframe.style.top = 0;
        iframe.style.left = 0;
        iframe.style.right = 0;
        iframe.style.bottom = 0;
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
        iframe.setAttribute('allowfullscreen', '')
        iframe.setAttribute('src', 'https://www.youtube.com/embed/' + id);
        div.appendChild(iframe);
        return div;
      },

      addYoutubeIframe(el){
        //모든 child를 돌면서 a href에 유튜브 아이디값이 있으면 해당 태그를 iframe으로 교체한다.
        const len = el.firstChild.children
        const items = []
        for (const p of len) {
          for (const a of p.children) {
            if (!a.href) continue
            const id = youtubeUrl.extractId(a.href)
            if (!id) continue
            p.setAttribute('id', id)
            items.push({
              doc: p,
              id: id
            })
          }
        }
        items.forEach(p => {
          p.doc.parentNode.insertBefore(this.setIframe(p.id), p.doc) 
          p.doc.parentNode.removeChild(p.doc);  //a href 제거
        })
        
        return el.innerHTML        
      },


      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        this.$router.back();
      },
      
      loadpage(){

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestBoardDetail',{
            BOARD_IDX: this.BOARD_IDX
          , token: this.$session.get("ACCESSTOKEN")
        }).then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            //이미 선택된 아이템에 상세만 교체
            window.scrollTo(0,0);
            this.BOARD_CONTENT  = result.data.resultData[0];
            if(this.BOARD_CONTENT == undefined){
              this.$fire({
                html: "The post does not exist.<br><br>게시글이 없습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.loadrepple();
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      


      //검색
      search_run(){
        this.loadpage();
      },

      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.v-main__wrap{
  background: white;
}


</style>