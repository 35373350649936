<template>

  <!-- 거래중인 리스트 -->
  <v-container fluid class="container_bg">

    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />

    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">답변</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
              <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Answer&nbsp;<span class="dialog_subtitle_kor">견적금액</span></p>
              </v-col>
              <v-col cols="12" md="10" class="ma-0 pa-0">
                <v-text-field 
                  v-model="editedItem.REPPLE_PRICE" color="black" 
                  :prefix="editedItem.BUY_PRICE_TYPE == 0 ? '$' : '₩'"
                  :suffix="editedItem.BUY_PRICE_TYPE == 0 ? 'USD' : '원'"
                  :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                  hide-details outlined dense
                  @input="inputtextbuy"
                  />
              </v-col>
            </v-row>                  

            <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
              <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Comment&nbsp;<span class="dialog_subtitle_kor">답변</span></p>
              </v-col>
              <v-col cols="12" md="10" class="ma-0 pa-0">
                <v-textarea v-model="editedItem.REPPLE_MSG" color="black" hide-details outlined dense/>
              </v-col>
            </v-row>                  

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-5 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
        
          <template v-slot:header.PRICEREQUEST_IDX>
            <a :class="sort_name=='PRICEREQUEST_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICEREQUEST_IDX')">No.<br>번호</a>
          </template>
          <template v-slot:header.THUM_IMG1>
            <label class="cheader_normal">Image<br>이미지</label>
          </template>
          <template v-slot:header.SALE_TYPE>
            <a :class="sort_name=='SALE_TYPE'?'cheader_sortselect':'cheader_sort'" @click="customsort('SALE_TYPE')">Type<br>타입</a>
          </template>
          <template v-slot:header.REQUEST_STATENAME>
            <a :class="sort_name=='REQ_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REQ_STATE')">State<br>상태</a>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>요청일자</a>
          </template>
          <template v-slot:header.NAME>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('NAME')">Requester<br>요청자</a>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">Answer<br>답변</label>
          </template>

          
          <template v-slot:item.THUM_IMG1="{ item }">  
              <v-col class="pa-0" align="center">
                <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                  <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                </template>
              </v-col>
          </template>
          <template v-slot:item.PRICEREQUEST_IDX="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRICEREQUEST_IDX}}</a>
          </template>
          <template v-slot:item.NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.NAME}}</a>
          </template>
          <template v-slot:item.SALE_TYPE="{ item }">  
            <a @click="loadnotice(item)" >{{item.SALE_TYPE == 0 ? '다이아몬드' : '보석'}}</a>
          </template>
          <template v-slot:item.REQUEST_STATENAME="{ item }">  
            <div :style="item.REQ_STATE == 1 ? 'background:#FFDE03;color:#000000;font-size:12px;padding-top:5px;padding-bottom:3px' : item.REQ_STATE == 2 ? 'background:green;color:#ffffff;font-size:12px;padding-top:5px;padding-bottom:3px' : 'background:red;color:#ffffff;font-size:12px;padding-top:5px;padding-bottom:3px'"
              @click="loadnotice(item)" style="cursor:pointer">
              {{item.REQ_STATE == 1 ? '가격문의' : '답변완료' }}
            </div>
          </template>

          <template v-slot:item.actions="{ item }">  
              <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  

        </v-data-table>
      </v-col>
    </v-row>
    
    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>
<script>
import cmvalidation from '../assets/js/cmvalidation.js'
export default {
    mounted() {

      if(this.$session.get("GRADE") < 90){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',

      dialog:false,
      editedItem: {
      },

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '번호', value: 'PRICEREQUEST_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {text: '타입', value: 'SALE_TYPE', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상태', value: 'REQUEST_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '이름', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '답변', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

      STATELIST: [
          {name:'가격문의',   value:"1"}
        , {name:'답변완료',   value:"2"}
      ],

    }),

    created(){

    },

    methods: {

      inputtextbuy(event){
        this.editedItem.REPPLE_PRICE =  this.valuecomma(event);
      },

      //금액 입력시 콤마 찍어주기 (소수점 포함)
      valuecomma(txt){
        var parts = txt.toString().split("."); 
        return String(parts[0]).replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
      },

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }

        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        this.loadpage(this.page);
      },      

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminSaleReq", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/PriceRequestList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      loadnotice(item){
        this.$router.push({path:"/SaleReqDetail", query:{code:item.PRICEREQUEST_IDX}});
      },


      //아이템 수정
      edit_item(item){
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
        
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //추가 저장
      async save () {

        if(this.isNullCheck(this.editedItem.REPPLE_PRICE)){
          this.$fire({
            html: "견적가격을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 

        const numberVal = /[^0-9]/g;
        const checkselprice = cmvalidation.checkValReg(String(this.editedItem.REPPLE_PRICE).replaceAll(",",""), numberVal, -1, -1);
        if(checkselprice == 1){
          this.$fire({
            html: "견적가격을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkselprice == 2){
          this.$fire({
            html: "Only numbers and . can be entered for the desired selling price.<br><br>견적가격은 숫자만 입력이 가능합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

        this.$http.post(this.$host+'/PriceRequestUpdate', {
            PRICEREQUEST_IDX: this.editedItem.PRICEREQUEST_IDX
          , REPPLE_PRICE: String(this.editedItem.REPPLE_PRICE).replaceAll(",","")
          , REPPLE_MSG: this.editedItem.REPPLE_MSG == null || this.editedItem.REPPLE_MSG == undefined ? '' : escape(this.editedItem.REPPLE_MSG)
        }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          this.dialog = false;
          if(result.data.resultCode == 0){
            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            this.$fire({
              html: "Your response has been completed.<br><br>답변이 완료되었습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.loadpage(this.page);
              }
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
              html: "Your answer failed.<br><br>답변에 실패했습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.dialog = false;
          this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });

      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


    },


}
</script>

<style scoped>

</style>