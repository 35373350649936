<template>
  <v-container fluid>

  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    created(){

    },

    methods: {

    },



}
</script>

<style scoped>

</style>