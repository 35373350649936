<template>
  <v-container fluid class="container_bg">

    <v-row align="center" justify="center" class="mt-5" dense>
      <v-col cols="9" md="9">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row dense align="center" justify="end">

      <v-col cols="6" md="2" class="pl-3 pr-3 pl-md-0 pr-md-3 pa-1">
        <v-select 
          v-model="SEARCH_JEWELRY_TYPE" :items="JEWELRYKINDLIST" item-color="black"
          placeholder="보석" dense item-text="NAME_ENG" item-value="IDX"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="search_run"
          />
      </v-col>
      
      <v-col cols="12" md="3" class="pa-0 pl-3 pl-md-0 pr-3 pr-md-3">
        <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
      </v-col>

      <v-col cols="6" md="2" class="pa-0 pr-3 pl-md-0 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="startDownload">
          <v-icon left>mdi-download-box</v-icon>Excel Download<br>엑셀다운
        </v-btn>
      </v-col>

    </v-row>


    <v-row class="mt-5">
      <v-col>
        <v-simple-table 
          height="70vh"
          dense fixed-header
          class="datatablestyle"
          >
          <template v-slot:default>
            <thead style="height:48px">
              <tr>
                <th class="text-center header_text_style" style="min-width:180px" >계정</th>
                <th class="text-center header_text_style" >보석</th>
                <th class="text-right header_text_style">수량</th>
                <th class="text-right header_text_style">무게</th>
                <th class="text-right header_text_style">달러</th>
                <th class="text-right header_text_style">원화</th>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color:#f0f0f0">
                <td class="text-center"></td>
                <td class="text-center">합계</td>
                <td class="text-right">{{ priceToString(sum1) }}</td>
                <td class="text-right">{{ priceToString(sum2.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum3.toFixed(2)) }}</td>
                <td class="text-right">{{ priceToString(sum4) }}</td>
              </tr>
              <tr v-for="(item,index) in list" :key="index">
                <td class="text-center" >{{ item.NAME }}</td>
                <td class="text-center" >{{ item.JEWELRYKIND_DESC }}</td>
                <td class="text-right">{{ priceToString(item.GEM) }}</td>
                <td class="text-right">{{ priceToString(item.GEMWEIGHT) }}</td>
                <td class="text-right">{{ priceToString(item.GEMUSD) }}</td>
                <td class="text-right">{{ priceToString(item.GEMWON) }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>

import XLSX from 'xlsx'
export default {


  beforeDestroy(){
    
  },

  mounted(){

    if( this.$session.get("GRADE") < 5 || (this.$session.get("GRADE") == 5 && this.$session.get("SUB_DAYS") < 0) ){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
    }

    this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
    this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
    this.grade = this.$session.get("GRADE");


    if(this.$session.exists("ACCNT_IDX")){
      this.initpage();
    }else{
      //키값 없으면 로그인으로
      this.$router.push("/Login");
    }
  },

  data: () => ({

      list:[],
      sum1:0,
      sum2:0,
      sum3:0,
      sum4:0,

      SEARCH_JEWELRY_TYPE:-1,
      JEWELRYKINDLIST:[],

      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,

  }),

  methods: {
    

    startDownload(){

      this.$confirm("엑셀파일로 다운로드 하시겠습니까?").then(() => {

        var templist = [];
        this.list.forEach(item=>{
          templist.push({
            "계정":item.NAME
          , "보석종류":item.JEWELRYKIND_DESC
          , "개수":parseInt(item.GEM)
          , "무게":parseFloat(item.GEMWEIGHT)
          , "달러":parseFloat(item.GEMUSD)
          , "원화":parseInt(item.GEMWON)
          });
        });

        //하단 합계 넣기
        templist.push({
            "계정":""
          , "보석종류":"합계"
          , "개수":this.priceToString(this.sum1)
          , "무게":this.priceToString(this.sum2.toFixed(2))
          , "달러":this.priceToString(this.sum3.toFixed(2))
          , "원화":this.priceToString(this.sum4)
        });
        
        var item_sheet = XLSX.utils.json_to_sheet(templist);

        //상단 컬럼명 수정
        item_sheet.A1 = {v:'계정'};
        item_sheet.B1 = {v:'보석종류'};

        item_sheet.C1 = {v:'수량'};
        item_sheet.D1 = {v:'무게'};
        item_sheet.E1 = {v:'달러'};
        item_sheet.F1 = {v:'원화'};

        //컬럼별 넓이
        var wscols = [{wch:20},{wch:10},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'},{wch:15,t:'n'}];
        item_sheet['!cols'] = wscols;

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, item_sheet, '보석통계');
        XLSX.writeFile(wb, '보석통계_'+Date.now().toString()+'.xlsx');

      });

    },    

    initpage(){
      this.getTypeList();
      this.search_run();
    },


      //타입 가져오기
      getTypeList(){
        this.JEWELRYKINDLIST.splice(0, this.JEWELRYKINDLIST.length); 
        this.$http.post(this.$host+'/GuestTotalTypeList',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){

            const list1 = result.data.GemstoneType;
            this.JEWELRYKINDLIST.push({NAME_ENG:"전체", IDX:-1}); 
            list1.forEach(element => {
                this.JEWELRYKINDLIST.push(element); 
            });

          }else if(result.data.resultCode == 2){
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },          

    search_run(){

      this.$http.post(this.$host+'/ChartGemstone',{
            ACCNT_IDX: this.SEARCH_ACCNT_IDX
          , SEARCH_JEWELRY_TYPE: this.SEARCH_JEWELRY_TYPE
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{

        if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            this.list.splice(0, this.list.length);   //데이터 초기화
            this.list = result.data.resultData;

            this.sum1 = 0;
            this.sum2 = 0;
            this.sum3 = 0;
            this.sum4 = 0;

            this.list.forEach(element => {

              this.sum1 += parseInt(element.GEM);
              this.sum2 += parseFloat(element.GEMWEIGHT);
              this.sum3 += parseFloat(element.GEMUSD);
              this.sum4 += parseInt(element.GEMWON);

            });

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$fire({
            html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
              this.$EventBus.$emit('clearsession');
            }
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        
        if(error.message == 'Request failed with status code 429')
        {
          this.$fire({
            html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }else{
          this.$fire({
            html: error.message,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }
      });

    },

    priceToString(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    //검색조건 업체 설정
    comsearchcb(val1){
      if(val1.NAME.length > 0){
        this.SEARCH_ACCNT_NAME = val1.NAME;
        this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
        this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
        this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
      }else{
        this.SEARCH_ACCNT_NAME = '';
        this.SEARCH_ACCNT_IDX  = null;
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
      }
      this.search_run();
    },

    // eslint-disable-next-line no-unused-vars
    comsearchclear(var2){
        this.SEARCH_ACCNT_IDX    = null;
        this.SEARCH_ACCNT_NAME   = '';
        this.$session.set("SEARCH_ACCNT_NAME", "");
        this.$session.set("SEARCH_ACCNT_IDX",  null);
        this.search_run();
    },      


  }
    
}

</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>