<template>

  <v-container fluid class="container_bg">

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="photo_index == 3 ? false : option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    

    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>      
        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 추가/수정 팝업 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->


                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">문의하실 상품의 종류를 선택하세요</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.SALE_TYPE" :items="CLASSLIST" 
                      dense 
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>

                </v-row>                

                <v-row v-if="editedItem.SALE_TYPE == 1" dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:red">*<span class="dialog_subtitle_kor">보석명을 입력하세요</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.TITLE"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">중량을 입력하세요</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.WEIGHT" suffix="ct"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">감정서를 등록하세요.</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0 mt-5">
                      <template v-if="previewImg4.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(3)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(3)">mdi-pencil</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="clearFile(3)">mdi-window-close</v-icon>
                            </div>
                            <img height="120" width="120" :src="previewImg4" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon size="25" style="width:50px;height:50px" @click="fileSelect(3)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">제품 실제 이미지를 등록하세요</span></p>
                  </v-col>
                </v-row>

              <v-row dense align="center" >
                <v-col cols="12" md="4" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
                    <template v-if="previewImg1.length > 0">
                      <v-row dense  justify="center">
                        <div style="position:relative">
                          <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                            <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                          </div>
                          <img height="120" width="120" :src="previewImg1" class="image">
                        </div>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense class="image-border" align="center" justify="center">
                        <v-icon size="25" @click="fileSelect(0)">mdi-plus</v-icon>
                      </v-row>
                    </template>
                </v-col>
                <v-col cols="12" md="4" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
                    <template v-if="previewImg2.length > 0">
                      <v-row dense  justify="center">
                        <div style="position:relative">
                          <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                            <v-icon style="width:40px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                          </div>
                          <img height="120" width="120" :src="previewImg2" class="image">
                        </div>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense class="image-border" justify="center">
                        <v-icon size="25" @click="fileSelect(1)">mdi-plus</v-icon>
                      </v-row>
                    </template>
                </v-col>
                <v-col cols="12" md="4" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px" >
                    <template v-if="previewImg3.length > 0">
                      <v-row dense  justify="center">
                        <div style="position:relative">
                          <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                            <v-icon style="width:40px;height:40px;color:white" @click="showImage(2)">mdi-magnify-expand</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(2)">mdi-pencil</v-icon>
                            <v-icon style="width:40px;height:40px;color:white" @click="clearFile(2)">mdi-window-close</v-icon>
                          </div>
                          <img height="120" width="120" :src="previewImg3" class="image">
                        </div>
                      </v-row>
                    </template>
                    <template v-else>
                      <v-row dense class="image-border" justify="center">
                        <v-icon size="25" @click="fileSelect(2)">mdi-plus</v-icon>
                      </v-row>
                    </template>
                </v-col>
              </v-row>                


              <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">구매가격을 입력하세요</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="editedItem.BUY_PRICE"
                    :prefix="editedItem.BUY_PRICE_TYPE == 0 ? '$' : '₩'"
                    :suffix="editedItem.BUY_PRICE_TYPE == 0 ? 'USD' : '원'"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    @input="inputtextbuy"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       

              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">구매처를 입력하세요.</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="editedItem.BUY_SHOP"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       
              
              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:red">*<span class="dialog_subtitle_kor">이름을 입력하세요</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="editedItem.NAME"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       

              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:red">*<span class="dialog_subtitle_kor">연락처를 입력하세요</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="editedItem.TEL"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       

              <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">기타 설명을 입력하세요.</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-textarea v-model="editedItem.DESC" color="black" hide-details outlined dense/>
                </v-col>
              </v-row>     

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>          

        </v-card>
      </v-dialog>  
    </v-row>


    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">판매가격 알아보기</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <v-col cols="12" md="3" class="pa-0 pr-0 pr-md-2" align="center">         
        <v-btn  class="search_btn_freesize" elevation="0" outlined large @click="loadprov">
          <v-icon left>mdi-plus</v-icon>Inquire the selling price<br>판매가격 문의하기
        </v-btn>
      </v-col>
      
  </v-row>
  
  <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
    <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
  </v-row>

  <v-row align="start" class="mt-5 mt-md-0" dense>

      <!-- 모바일 해상도 -->
      <v-col
        v-for="item in itemList"
        cols="12"
        class="hidden-md-and-up"
        >
        <v-card>
          <div class="pl-4 pt-2 text-left d-flex" 
              :style="item.REQ_STATE == 1 ? 'background:#FFDE03;color:#000000;font-size:12px;padding-bottom:3px' : item.REQ_STATE == 2 ? 'background:green;color:#ffffff;font-size:12px;padding-bottom:3px' : 'background:red;color:#ffffff;font-size:12px;padding-bottom:3px'"
              >
              <div class="mr-auto">{{ item.REQ_STATE == 1 ? '가격문의' : '답변완료' }}</div>
          </div>
          <v-list-item>
            <v-list-item-avatar
              tile
              size="50"
              color="#00000000"
              >
              <img class="radius_10" :src="item.THUM_IMG1"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="d-flex">
                <div class="d-flex flex-column me-auto" @click="loadnotice(item)">
                  <div class="pa-0 text_2line mb-1" style="color:black;font-size:15px;line-height:15px">
                    {{ item.SALE_TYPE == 0 ? '다이아몬드' : '보석' }}
                  </div>
                  <div class="pa-0" style="font-size:12px" v-text="item.REG_DATE"></div>
                </div>
                <v-icon v-if="item.REQ_STATE == 1" color="black" style="color:white" x-small @click="edit_item(item)">mdi-pencil</v-icon>
                </div>
            </v-list-item-content>
          </v-list-item>      
              
        </v-card>
      </v-col>

    <!-- PC해상도 -->
    <v-col cols="12" class="hidden-sm-and-down">
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
          
        item-key="PRODUCT_IDX"
        :loading="loading"
        item-class="itemstyle"
        mobile-breakpoint="0"
        >
          <template v-slot:header.PRICEREQUEST_IDX>
            <a :class="sort_name=='PRICEREQUEST_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRICEREQUEST_IDX')">No.<br>번호</a>
          </template>
          <template v-slot:header.THUM_IMG1>
            <label class="cheader_normal">Image<br>이미지</label>
          </template>
          <template v-slot:header.SALE_TYPE>
            <a :class="sort_name=='SALE_TYPE'?'cheader_sortselect':'cheader_sort'" @click="customsort('SALE_TYPE')">Type<br>타입</a>
          </template>
          <template v-slot:header.REQUEST_STATENAME>
            <a :class="sort_name=='REQ_STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REQ_STATE')">State<br>상태</a>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>요청일자</a>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">Edit<br>수정</label>
          </template>

          <template v-slot:item.THUM_IMG1="{ item }">  
            <v-col class="pa-0" align="center">
              <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                <v-img class="radius_10" :src="item.THUM_IMG1" style="width:50px;hegith:50px" contain/>
              </template>
              <template v-else>
                <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
              </template>
            </v-col>
          </template>

          <template v-slot:item.PRICEREQUEST_IDX="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRICEREQUEST_IDX}}</a>
          </template>
          <template v-slot:item.SALE_TYPE="{ item }">  
            <a @click="loadnotice(item)" >{{item.SALE_TYPE == 0 ? '다이아몬드' : '보석'}}</a>
          </template>
          <template v-slot:item.REQUEST_STATENAME="{ item }">  
            <div :style="item.REQ_STATE == 1 ? 'background:#FFDE03;color:#000000;font-size:12px;padding-top:5px;padding-bottom:3px' : item.REQ_STATE == 2 ? 'background:green;color:#ffffff;font-size:12px;padding-top:5px;padding-bottom:3px' : 'background:red;color:#ffffff;font-size:12px;padding-top:5px;padding-bottom:3px'"
              @click="loadnotice(item)" style="cursor:pointer">
              {{item.REQ_STATE == 1 ? '가격문의' : '답변완료' }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">  
              <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  

      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>

  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import dateFormat from 'dateformat'
import moment from 'moment'
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    components:{
      VueCropper,
    },

    data: () => ({

      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      grade : 0,
      
      progress_value:0,
      progoptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'circle'
        }
      },

      
      uploadFile1 : [],
      uploadFile2 : [],
      uploadFile3 : [],
      uploadFile4 : [],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      
      progress_dialog:false,
      progress:0,
      defaultItem: {
        PRICEREQUEST_IDX:0,
        SALE_TYPE:0,
        TITLE: '',
        WEIGHT:0,
        BUY_PRICE_DP:0,
        BUY_PRICE_TYPE:1,
        BUY_SHOP: '',
        NAME: '',
        TEL: '',
        DESC: '',
        
        THUM_IMG1: '',
        PRODUCT_IMG1: '',
        PRODUCT_IMG2: '',
        PRODUCT_IMG3: '',
        APPRAISAL_IMG:'',

        REQ_STATE:1,
        REG_DATE:'',
      },


      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '번호', value: 'PRICEREQUEST_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {text: '타입', value: 'SALE_TYPE', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '상태', value: 'REQUEST_STATENAME', sortable: false, align:'center' , width:'150px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '수정', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      
      //제품 구분 - 이걸 나눌까? 이렇게 합칠까?
      CLASSLIST: [
          {name:'다이아몬드',   value:0}
        , {name:'보석',        value:1}
      ],

      PRICELIST: [
          {name:'USD',   value:0}
        , {name:'WON',   value:1}
      ],
      
      previewImg1:"",
      previewImg2:"",
      previewImg3:"",
      previewImg4:"",

      options: {
        focus:false,
      },      
      crop_dialog:false,
      photo_index:0,
      imgSrc:'',
      youtube_thum:'',

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            


    }),


    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    mounted(){

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    create(){
      
    },

    methods: {

      //금액 입력시 콤마 찍어주기 (소수점 포함)
      inputtextbuy(event){
        this.editedItem.BUY_PRICE =  this.valuecomma(event);
      },
      inputtextsell(event){
        this.editedItem.SEL_PRICE =  this.valuecomma(event);
      },
      
      //금액 입력시 콤마 찍어주기 (소수점 포함)
      valuecomma(txt){
        var parts = txt.toString().split("."); 
        return String(parts[0]).replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
      },



      showmsg(item){
        if(item.REPPLEMSG != undefined && item.REPPLEMSG != null && item.REPPLEMSG.length > 0){
          this.$fire({
            html: item.REPPLEMSG,
            title: "Answer 답변",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }else{
          this.$fire({
            html: "No answer. <br><br>답변이 없습니다.",
            title: "INFO 알림",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        }
      },


      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }else if(index == 2){
          img = [this.previewImg3];
        }else if(index == 3){
          img = [this.previewImg4];
        }else if(index == 4){
          img = [this.previewImg5];
        }else if(index == 5){
          img = [this.previewImg6];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }else if(index == 2){
          this.previewImg3 = "";
        }else if(index == 3){
          this.previewImg4 = "";
        }else if(index == 4){
          this.previewImg5 = "";
        }else if(index == 5){
          this.previewImg6 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },

      getParam(url, name) {
        var params = url.substr(url.indexOf("?") + 1);
        var sval = "";
        params = params.split("&");
        var temp = [];
        for (var i = 0; i < params.length; i++) {
          temp = params[i].split("=");
          if ([temp[0]] == name) { sval = temp[1]; }
        }
        return sval;
      },      

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/UserSaleReq", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/PriceRequestList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      



      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //추가 저장
      async save () {

        let accnt_idx = this.$session.exists("ACCNT_IDX");


        if(this.editedItem.SALE_TYPE == 1){
          const checktitle = cmvalidation.checkValReg(this.editedItem.TITLE, null, 1, 50);
          if(checktitle == 1){
            this.$fire({
              html: "보석명을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktitle == 3){
            this.$fire({
              html: "보석명은 1자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktitle == 4){
            this.$fire({
              html: "보석명은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        const checkname = cmvalidation.checkValReg(this.editedItem.NAME, null, 2, 20);
        if(checkname == 1){
          this.$fire({
            html: "이름을 입력하세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 3){
          this.$fire({
            html: "이름은 2자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 4){
          this.$fire({
            html: "이름은 20자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const checktel = cmvalidation.checkValReg(this.editedItem.TEL, null, 6, 25);
        if(checktel == 1){
          this.$fire({
            html: "연락처를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 3){
          this.$fire({
            html: "연락처는 6자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 4){
          this.$fire({
            html: "연락처는 25자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){
            if(this.isNullCheck(this.editedItem.PRODUCT_IMG1)){
              //기존에 등록된 이미지가 있으면 패스
              this.$fire({
                html: "실제 이미지를 등록하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }

        }else{
            
            this.progress_dialog = true;            

            //대표 썸네일 등록
            if(this.uploadFileThum1){
              let upload_thum_cb1 = await this.uploadStorage("product", accnt_idx+"/thum_"+Date.now().toString()+".jpg", this.uploadFileThum1);
              if(upload_thum_cb1 == null){
                this.progress_dialog = false;
                
                this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

                return;
              } 
              this.editedItem.THUM_IMG1 = upload_thum_cb1.resultRef;
              
              this.uploadFileThum1 = [];

              if(!this.isNullCheck(this.editedItem.THUM_IMG1_REF)){
                try{
                  await this.deletefile(this.editedItem.THUM_IMG1_REF);
                }catch(e){
                }
              }

              this.editedItem.THUM_IMG1_REF = upload_thum_cb1.resultRef;

            }

            
            //대표 이미지 등록
            let upload_cb1 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            this.uploadFile1 = [];
            
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG1_REF)){
              try{
                await this.deletefile(this.editedItem.PRODUCT_IMG1_REF);
              }catch(e){
              }
            }

            this.editedItem.PRODUCT_IMG1_REF = upload_cb1.resultRef;

        }
        

        if(!this.isNullCheck(this.uploadFile2)){

            //대표 이미지 등록
            let upload_cb2 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile2);
            if(upload_cb2 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            
            this.uploadFile2 = [];

            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG2_REF)){
              try{
                await this.deletefile(this.editedItem.PRODUCT_IMG2_REF);
              }catch(e){
              }
            }
            this.editedItem.PRODUCT_IMG2_REF = upload_cb2.resultRef;

        }

        if(!this.isNullCheck(this.uploadFile3)){

            //대표 이미지 등록
            let upload_cb3 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile3);
            if(upload_cb3 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            
            this.uploadFile3 = [];

            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG3_REF)){
              try{
                await this.deletefile(this.editedItem.PRODUCT_IMG3_REF);
              }catch(e){
              }
            }
            this.editedItem.PRODUCT_IMG3_REF = upload_cb3.resultRef;
        }


        if(!this.isNullCheck(this.uploadFile4)){

            //대표 이미지 등록
            let upload_cb4 = await this.uploadStorage("product", accnt_idx+"/"+Date.now().toString()+".jpg", this.uploadFile4);
            if(upload_cb4 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            
            this.uploadFile4 = [];

            if(!this.isNullCheck(this.editedItem.APPRAISAL_IMG_REF)){
              try{
                await this.deletefile(this.editedItem.APPRAISAL_IMG_REF);
              }catch(e){
              }
            }

            this.editedItem.APPRAISAL_IMG_REF = upload_cb4.resultRef;

        }        


        
        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/PriceRequestUpInfo', {
              PRICEREQUEST_IDX: this.editedItem.PRICEREQUEST_IDX
            , TITLE: escape(this.editedItem.TITLE)
            , WEIGHT: this.editedItem.WEIGHT == null || this.editedItem.WEIGHT == undefined || this.editedItem.WEIGHT.length == 0 ? 0 : this.editedItem.WEIGHT
            , BUY_PRICE: this.editedItem.BUY_PRICE == null || this.editedItem.BUY_PRICE == undefined || this.editedItem.BUY_PRICE.length == 0 ? 0 : String(this.editedItem.BUY_PRICE).replaceAll(",","")
            , BUY_SHOP: this.editedItem.BUY_SHOP == null || this.editedItem.BUY_SHOP == undefined ? '' : escape(this.editedItem.BUY_SHOP)
            , NAME: escape(this.editedItem.NAME)
            , TEL: escape(this.editedItem.TEL)
            , DESC: this.editedItem.DESC == null || this.editedItem.DESC == undefined ? '' : escape(this.editedItem.DESC)
            , THUM_IMG1: this.editedItem.THUM_IMG1_REF == null || this.editedItem.THUM_IMG1_REF == undefined ? '' : escape(this.editedItem.THUM_IMG1_REF)
            , PRODUCT_IMG1: this.editedItem.PRODUCT_IMG1_REF == null || this.editedItem.PRODUCT_IMG1_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG1_REF)
            , PRODUCT_IMG2: this.editedItem.PRODUCT_IMG2_REF == null || this.editedItem.PRODUCT_IMG2_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG2_REF)
            , PRODUCT_IMG3: this.editedItem.PRODUCT_IMG3_REF == null || this.editedItem.PRODUCT_IMG3_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG3_REF)
            , APPRAISAL_IMG: this.editedItem.APPRAISAL_IMG_REF == null || this.editedItem.APPRAISAL_IMG_REF == undefined ? '' : escape(this.editedItem.APPRAISAL_IMG_REF)
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.dialog = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(this.page);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            this.dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          });

        }else{
          
          this.$http.post(this.$host+'/PriceRequestInsert',{
              TITLE: escape(this.editedItem.TITLE)
            , SALE_TYPE: this.editedItem.SALE_TYPE
            , WEIGHT: this.editedItem.WEIGHT == null || this.editedItem.WEIGHT == undefined || this.editedItem.WEIGHT.length == 0 ? 0 : this.editedItem.WEIGHT
            , BUY_PRICE: this.editedItem.BUY_PRICE == null || this.editedItem.BUY_PRICE == undefined || this.editedItem.BUY_PRICE.length == 0 ? 0 : String(this.editedItem.BUY_PRICE).replaceAll(",","")
            , BUY_SHOP: this.editedItem.BUY_SHOP == null || this.editedItem.BUY_SHOP == undefined ? '' : escape(this.editedItem.BUY_SHOP)
            , NAME: escape(this.editedItem.NAME)
            , TEL: escape(this.editedItem.TEL)
            , DESC: this.editedItem.DESC == null || this.editedItem.DESC == undefined ? '' : escape(this.editedItem.DESC)
            , THUM_IMG1: this.editedItem.THUM_IMG1_REF == null || this.editedItem.THUM_IMG1_REF == undefined ? '' : escape(this.editedItem.THUM_IMG1_REF)
            , PRODUCT_IMG1: this.editedItem.PRODUCT_IMG1_REF == null || this.editedItem.PRODUCT_IMG1_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG1_REF)
            , PRODUCT_IMG2: this.editedItem.PRODUCT_IMG2_REF == null || this.editedItem.PRODUCT_IMG2_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG2_REF)
            , PRODUCT_IMG3: this.editedItem.PRODUCT_IMG3_REF == null || this.editedItem.PRODUCT_IMG3_REF == undefined ? '' : escape(this.editedItem.PRODUCT_IMG3_REF)
            , APPRAISAL_IMG: this.editedItem.APPRAISAL_IMG_REF == null || this.editedItem.APPRAISAL_IMG_REF == undefined ? '' : escape(this.editedItem.APPRAISAL_IMG_REF)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.dialog = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.page = 0;
                this.$fire({
                  html: "Your sales request has been completed.<br><br>판매요청이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(1);
                  }
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.dialog = false;
            this.progress_dialog = false;
            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });

        }

      },      

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadnotice(item){
        this.$router.push({path:"/SaleReqDetail", query:{code:item.PRICEREQUEST_IDX}});
      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },
      

      //신규 추가
      newinsert(){

        //이미지 체크

        
        if(this.grade > 90){
          if(this.SEARCH_ACCNT_IDX == null || this.SEARCH_ACCNT_IDX == undefined){
            this.$fire({
              html: "Please select a seller<br><br>판매자를 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.uploadFile3 = [];
        this.uploadFile4 = [];

        this.previewImg1 = "";
        this.previewImg2 = "";
        this.previewImg3 = "";
        this.previewImg4 = "";

        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);

        this.editedItem.DESC  = "";
        //this.editorText = this.editedItem.DESC;

        this.dialog = true;
        
        window.scrollTo(0,0);

        //this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.            
        
      },

      //아이템 수정
      edit_item(item){

        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.uploadFile3 = [];
        this.uploadFile4 = [];

        this.previewImg1 = "";
        this.previewImg2 = "";
        this.previewImg3 = "";
        this.previewImg4 = "";

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestPriceReqDetail',{
          PRICEREQUEST_IDX: item.PRICEREQUEST_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          this.loading = false;

          if(result.data.resultCode == 0){

            this.dialogTitle = "수정하기"
            this.editedIndex = result.data.resultData[0].PRICEREQUEST_IDX;
            this.editedItem = Object.assign({}, result.data.resultData[0]);

            this.dialog = true;
            window.scrollTo(0,0);

            this.previewImg1 = this.editedItem.PRODUCT_IMG1 != null && this.editedItem.PRODUCT_IMG1.length > 0 ? this.editedItem.PRODUCT_IMG1 : "";
            this.previewImg2 = this.editedItem.PRODUCT_IMG2 != null && this.editedItem.PRODUCT_IMG2.length > 0 ? this.editedItem.PRODUCT_IMG2 : "";
            this.previewImg3 = this.editedItem.PRODUCT_IMG3 != null && this.editedItem.PRODUCT_IMG3.length > 0 ? this.editedItem.PRODUCT_IMG3 : "";
            this.previewImg4 = this.editedItem.APPRAISAL_IMG != null && this.editedItem.APPRAISAL_IMG.length > 0 ? this.editedItem.APPRAISAL_IMG : "";

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },






      //이미지 선택시
      changeMoviefile(){
        if(this.uploadMovieFile != undefined && this.uploadMovieFile.name != undefined && this.uploadMovieFile.name.length > 0){
          if(this.uploadMovieFile.size/1024/1024 > 100){
            this.$fire({
              html: "Videos can only be used with files of 100MB or less.<br><br>동영상은 100MB 이하 파일만 사용할 수 있습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const videourl = URL.createObjectURL(this.uploadMovieFile);
          this.$refs.video.src = videourl;
        }
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }else if(pindex == 2){
            this.uploadFile3 = [];
          }else if(pindex == 3){
            this.uploadFile4 = [];
          }
          return;

        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },

      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){

              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용

              //썸네일 생성
              var image     = await this.loadImage(data);
              const canvas  = document.createElement("canvas");
              canvas.width  = 50;
              canvas.height = 50;
              canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
              
              //this.previewThumImg1 = canvas.toDataURL("image/jpeg");  //미리보기 데이터
              //this.uploadFileThum1 = await this.dataURItoBlob(this.previewThumImg1);     //썸네일 blob데이터

              this.uploadFileThum1 = this.dataURItoBlob(canvas.toDataURL("image/jpeg"));     //썸네일 blob데이터
              

            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 2){
              this.uploadFile3 = blob;
              this.previewImg3 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 3){
              this.uploadFile4 = blob;
              this.previewImg4 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 4){
              this.uploadFile5 = blob;
              this.previewImg5 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 5){
              this.uploadFile6 = blob;
              this.previewImg6 = data; //이미지뷰 미리보기용
            }

          });

        });
      },
      
      
      //지정한 data를 넣은 이미지를 리턴한다.
      loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
          myImage.onload = () => resolve(myImage)
        })
      },

      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },



      //공급처 정보
      loadprov(){
        this.newinsert();

        /* 판매요청시 신분증 받기로 했다가 다시 취소요청함. 2022-10-15
        this.$http.post(this.$host+'/AcntDetail',{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              
              const provItem = result.data.resultData[0];
              if(provItem.CARD_IMG == null || provItem.CARD_IMG.length < 1){
                this.$fire({
                    html: "Please register the ID image in my information and request it again. It is required when requesting sales.<br><br>신분증 이미지를 내정보에 등록 후 다시 요청해주세요. 판매요청시 필요합니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
                return;
              }else{
                this.newinsert();
              }

              
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
        */

      },


      postcodetoAddr(addr){
        this.editedItem.ADDRESS = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);

            }
        }).open();

      },      
      

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

  
  .text_black{
    color:black !important;
  }
  
  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }

  .image-border2 {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 240px !important;
    width: 240px !important;
    text-align: center !important;
  }

</style>