<template>

  <v-container fluid class="container_bg">
    
    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate Name&nbsp;<span class="dialog_subtitle_kor">감정서 이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.CERTI_NAME"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate Type&nbsp;<span class="dialog_subtitle_kor">감정서 구분</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.CERTI_TYPE" :items="TYPELIST" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">State&nbsp;<span class="dialog_subtitle_kor">상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.CERTI_STATE" :items="STATELIST" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" class="pl-3 pr-3 pl-md-0 pr-md-0 pa-1">
        <v-select 
          v-model="SEARCH_PRODUCT_CLASS" :items="SEARCHCLASSLIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="loadpage(1)"
          />
      </v-col>
      <v-col cols="6" md="2" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">Select<br>조회</v-btn>
      </v-col>        
      <v-col cols="6" md="2" class="pa-0 pr-0" align="center">        
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="newinsert">ADD<br>추가</v-btn>
      </v-col>
      <v-col cols="6" md="2" class="pa-0 pr-0 pr-md-2" align="center">        
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="saveSort">Save Sort<br>순서저장</v-btn>
      </v-col>
    </v-row>

    
  <v-row align="start" class="mt-5" dense>
    <v-col>
      <!-- 테이블 -->
      <draggable v-model="itemList">
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          
          class="datatablestyle"
          item-key="IDX"
          :loading="loading"
          :items-per-page="9999"
          mobile-breakpoint="0"
          >
          <template v-slot:header.CERTI_NAME>
            <a class="cheader_normal">Certificate<br>감정서</a>
          </template>
          <template v-slot:header.CERTI_TYPE>
            <a class="cheader_normal">Type<br>구분</a>
          </template>
          <template v-slot:header.CERTI_STATE>
            <a class="cheader_normal">State<br>상태</a>
          </template>
          <template v-slot:header.actions>
            <a class="cheader_normal">Edit/Delete<br>수정/삭제</a>
          </template>

          <template v-slot:body="props">
            <draggable :list="props.items" ghost-class="ghost" @change="changedrag" tag="tbody">
              <tr v-for="(item, index) in props.items" :key="index">
                <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                <td style="width:100px; text-align:center">{{ item.CERTI_NAME }}</td>
                <td style="width:80px; text-align:center">{{ item.CERTI_TYPE == 0 ? "Diamond" : 'Gemstone' }}</td>
                <td :class="item.CERTI_STATE == 1 ? 'point_text_green' : 'point_text_red'" style="width:80px; text-align:center">{{ item.CERTI_STATE == 1 ? "활성" : '비활성' }}</td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon><v-icon small @click="delete_item(item)">mdi-delete</v-icon></td>
              </tr>
            </draggable>
          </template>

        </v-data-table>
      </draggable>

    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    components: {
      draggable,
    },

    data: () => ({

      //필수 페이징 파라메터
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          CERTI_NAME:'',
          CERTI_TYPE:0,
          CERTI_STATE:1,
          SORT: 1,
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '',  value: '', width:60,sortable: false, align:'center' },
        {text: '감정서',  value: 'CERTI_NAME', width:120,sortable: false, align:'center' },
        {text: '타입',    value: 'CERTI_TYPE', width:120,sortable: false, align:'center'},
        {text: '상태',    value: 'CERTI_STATE', width:120,sortable: false, align:'center'},
        {text: '수정/삭제', value: 'actions', width:120,sortable: false, align:'center'}
      ],
      itemList: [],

      STATELIST: [
          {name:'비활성',     value:0}
        , {name:'활성',       value:1}
      ],

      TYPELIST: [
          {name:'Diamond/다이아몬드',   value:0}
        , {name:'Gemstone/보석',       value:1}
        , {name:'Jewelry/주얼리',       value:2}
      ],

      SEARCH_PRODUCT_CLASS: 0,
      SEARCHCLASSLIST: [
          {name:'Diamonds/다이아몬드',  value:0}
        , {name:'Gemstones/젬스톤',     value:1}
        , {name:'Jewelry/주얼리',     value:2}
      ],

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    create(){

    },

    methods: {

      //오리지널 리스트 순서를 변경
      changedrag(e){
        this.itemList.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },

      saveSort(){

        if(this.itemList.length <= 1){
          this.$fire({
            html: "There is no history to change.<br><br>변경하실 내역이 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        //드래그앤드랍 순서를 저장한다.

        this.$fire({
          html: "Do you want to save the order?<br><br>순서를 저장하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

          var tempidx = 1;
          this.itemList.forEach(item=>{
            item.SORT = tempidx;
            tempidx++;
          });

          this.loading = true;
          this.$http.post(this.$host+'/CertiUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(this.itemList))
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadpage();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Failed to change<br><br>순서 변경이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });            

          }
        });

      },             

      loadpage(){
        
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화

        this.loading = true;

          this.$http.post(this.$host+'/CertiList',{
            CERTI_TYPE: this.SEARCH_PRODUCT_CLASS
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              const list = result.data.resultData;
              list.forEach(element => {
                  this.itemList.push(element); 
              });
              this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      save () {
        
        const checkname = cmvalidation.checkValReg(this.editedItem.CERTI_NAME, null, -1, 20);
        if(checkname == 1){
          this.$fire({
            html: "Please enter a Certificate name<br><br>감정서 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 4){
          this.$fire({
            html: "Please Enter  Certificate name in 20 characters or less<br><br>감정서 이름은 20자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/CertiUpdate',{
                CERTI_NAME: escape(this.editedItem.CERTI_NAME)
              , CERTI_TYPE: this.editedItem.CERTI_TYPE
              , CERTI_STATE: this.editedItem.CERTI_STATE
              , CERTI_IDX: this.editedItem.CERTI_IDX
              , SORT: this.editedItem.SORT
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
                this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          });


        }else{
          
          //신규
          this.loading = true;
          this.$http.post(this.$host+'/CertiInsert',{
                CERTI_NAME: escape(this.editedItem.CERTI_NAME)
              , CERTI_TYPE: this.editedItem.CERTI_TYPE
              , CERTI_STATE: this.editedItem.CERTI_STATE
              , SORT: this.editedItem.SORT
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });


        }

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage();
      },
      
      //신규 추가
      newinsert(){

        this.dialogTitle = "Add 추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.CERTI_TYPE = this.SEARCH_PRODUCT_CLASS;
        this.editedItem.SORT = this.itemList.length+1;
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "Edit 수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){
        this.$fire({
          html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.loading = true;
            this.$http.post(this.$host+'/CertiDelete',{
                CERTI_IDX:item.CERTI_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage();
                  }
                });

                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });      

          }
        });

      },




    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>