import { render, staticRenderFns } from "./AdminProduct_Backup.vue?vue&type=template&id=a52da69c&scoped=true&"
import script from "./AdminProduct_Backup.vue?vue&type=script&lang=js&"
export * from "./AdminProduct_Backup.vue?vue&type=script&lang=js&"
import style0 from "./AdminProduct_Backup.vue?vue&type=style&index=0&id=a52da69c&prod&lang=css&"
import style1 from "./AdminProduct_Backup.vue?vue&type=style&index=1&id=a52da69c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a52da69c",
  null
  
)

export default component.exports