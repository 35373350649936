<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:1000px">
    
    <!-- 로딩 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="center">
    <v-col class="md-mt-5" cols="11" md="8">

      <v-row align="center" justify="start" dense class="mt-1">
        <v-col cols="12" md="4" sm="12" xs="12" align="left" class="pa-0 d-flex align-center" >
          <a @click="back"><span><v-icon color="black">mdi-chevron-left</v-icon><label class="menutitle">Back</label></span></a>
        </v-col>
        <v-spacer/>
      </v-row>

      <v-row dense>

        <!-- 상품 이미지 -->
        <v-col cols="12" md="5">
          <viewer
              class="viewer" ref="viewer" 
              :options="options"
              >
              <!-- <v-img :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%" /> -->
              <v-img contain :aspect-ratio="1/1" style="background:white" 
                    :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%"
                    @click="show_image"
                    />
              

          </viewer>
          <v-row class="ma-0 pt-1" justify="start">
            <v-col cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.PRODUCT_IMG1)"><v-img :src="editedItem.PRODUCT_IMG1 ? editedItem.PRODUCT_IMG1 : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 pl-1" v-if="editedItem.PRODUCT_IMG2.length > 0">
              <a @click="select_img(editedItem.PRODUCT_IMG2)"><v-img :src="editedItem.PRODUCT_IMG2 ? editedItem.PRODUCT_IMG2 : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 pl-1" v-if="editedItem.PRODUCT_IMG3.length > 0">
              <a @click="select_img(editedItem.PRODUCT_IMG3)"><v-img :src="editedItem.PRODUCT_IMG3 ? editedItem.PRODUCT_IMG3 : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col cols="2" class="pa-0 ma-0 pl-1" v-if="editedItem.APPRAISAL_IMG.length > 0">
              <a @click="select_img(editedItem.APPRAISAL_IMG)"><v-img contain :aspect-ratio="1/1" style="background:gray" :src="editedItem.APPRAISAL_IMG ? editedItem.APPRAISAL_IMG : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
          </v-row>
        </v-col>

      </v-row>
      
      <v-row dense align="center" justify="center" class="mt-2">
        <v-col cols="12" class="pa-0 ma-0">
          <div class="black_line"/>
        </v-col>
      </v-row>
      
    <v-row justify="center" dense>
      <v-col cols="12">
        <v-tabs
            v-model="tab"
            fixed-tabs
            background-color="white" color="black"
            >
            <v-tabs-slider color="black"></v-tabs-slider>

            
            <v-tab>
              <label class="text-md-h6 text-caption" style="color:black;line-height:20px">Request Info<br><span class="text-subtitle-2">요청정보</span></label>
            </v-tab>
            <v-tab>
              <label class="text-md-h6 text-caption" style="color:black;line-height:20px">F&A<br><span class="text-subtitle-2">안내</span></label>
            </v-tab>

            <v-tab-item>
              <v-row class="mt-1">
                <v-col cols="12">


                  <v-row no-gutters dense align="center" justify="center">
                    <v-col cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center" v-if="editedItem.SALE_TYPE == 1">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">상품종류</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.SALE_TYPE == 0 ? '다이아몬드' : '보석' }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center" v-if="editedItem.SALE_TYPE == 1">
                      <v-col cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center" v-if="editedItem.SALE_TYPE == 1">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">보석명</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.TITLE }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>
                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Weight<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">중량</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.WEIGHT }}</div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Buying Price<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">구매가격</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.BUY_PRICE_TYPE == 0 ? ''+editedItem.BUY_PRICE_DP +' USD': ''+editedItem.BUY_PRICE_DP +' 원' }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>
                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Buying Price<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">구매처</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.BUY_SHOP }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Buying Price<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">이름</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.NAME }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Buying Price<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">연락처</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                              {{ editedItem.TEL }}
                            </div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-row>
                          <v-col cols="5" md="4" align="center">
                            <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                              <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Comment<br>
                              <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">설명</span></div>
                            </div>
                          </v-col>              
                          <v-col cols="7" md="8" align="start" class="d-flex align-center">
                            <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.DESC }}</div>
                          </v-col>              
                        </v-row>
                      </v-col>
                  </v-row>
                  <v-row no-gutters dense align="center" justify="center">
                      <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                        <v-divider></v-divider>
                      </v-col>
                  </v-row>
                 

                  
                  <template v-if="editedItem.REQ_STATE == 2">
                  <v-row dense align="center" justify="center" class="mt-5">
                    <v-col cols="11" class="pa-0 ma-0">
                      <div class="text_kor">
                        귀하가 요청하신 내용에 대한 견적입니다.
                      </div>
                    </v-col>
                    <v-col cols="12" class="pa-0 ma-0">
                      <div class="black_line"/>
                    </v-col>
                  </v-row>

                  <v-row no-gutters dense align="center" justify="center" class="mt-3">
                    <v-col cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="5" md="4" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Answer<br>
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">견적금액</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="7" md="8" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">
                            {{ `${editedItem.REPPLE_PRICE_DP}원` }}
                            <br>
                            위 금액은 감정 결과에 따라 다를 수 있습니다.
                          </div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>       

                <v-row no-gutters align="center" justify="center" v-if="editedItem.REPPLE_MSG != null && editedItem.REPPLE_MSG != undefined && editedItem.REPPLE_MSG.length > 0">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="5" md="4" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Comment<br>
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">답변</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="7" md="8" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.REPPLE_MSG }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>
              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>       
              </template>

              <v-row no-gutters dense align="center" justify="center" class="mt-5">
                <v-col md="6" cols="12"  align="center" class="pl-1 pr-1">
                  <v-btn @click="gofindshop" class="black_btn" >내 주변 소매점 찾기</v-btn>
                </v-col>
              </v-row>       


                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item >
              <v-row align="center" justify="center" class="mt-1">
                <v-col cols="12" align="center">
                  <v-expansion-panels accordion focusable class="none_radius">

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        판매 가격 알아보기 이후 절차는 어떻게 되나요?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pt-5" color="#FAFAFA">
                        <v-row align="center">
                          <v-col cols="12" align="left">
                            <ol>
                              <li>판매요청 자료 업로드 (보석명, 보석중량, 실물사진, 감정서, 보증서 등)</li>
                              <li>감정서와 현물 사진으로 1차 평가하여 잠정적 견적통보</li>
                              <li>정밀감정 3~5일 소요 (합성 다이아몬드 보석 확인 위해 꼭 필요함)</li>
                              <li>KDGE 협력사 및 본사 방문하여 매매 상담전화</li>
                              <li>다이아몬드 KS규격 감정의뢰 (보관증명서 발행)</li>
                              <li>최종 가치평가 가격확정 통보</li>
                              <li>소비자 판매결정</li>
                              <li>가까운 소매점 또는 본사 방문</li>
                            </ol>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        판매요청 취소가 가능한가요?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pt-5" color="#FAFAFA">
                        <v-row align="center">
                          <v-col cols="12" align="left">
                            <ol>
                              <li>판매결정 전에는 언제든지 취소 가능</li>
                              <li>소비자의 결정으로 감정서를 발행했을 경우 등 실비용 청구</li>
                              <li>판매 결정으로 대금이 송금된 이후에는 취소 불가</li>
                            </ol>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        평가 방법 및 금액 결정 기준은 무엇입니까?
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pt-5" color="#FAFAFA">
                        <v-row align="center">
                          <v-col cols="12" align="left">
                            <label>보석의 종류, 또는 인공, 중량, 품질, 컬러, 내포물, 투명도(스톤의 질), 연마(생김세), 국제시세등 종합적으로 판단하여 가치를 평가합니다.</label>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        판매시 판매금액은 어떻게 지급받나요??
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pt-5" color="#FAFAFA">
                        <v-row align="center">
                          <v-col cols="12" align="left">
                            <label>판매자의 통장으로 송금하거나 소매상 직접 방문수령 가능합니다.</label>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>



                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-tab-item>

        </v-tabs>
      </v-col>
    </v-row>

    <v-row style="height:100px"></v-row>

    </v-col>
    </v-row>

  </v-container>
</template>

<script>
export default {

    data: () => ({
      select_image:'',
      progress_dialog:false,
      PRICEREQUEST_IDX:"",     //넘겨받은 상품 번호
      editedItem:{},
      items:[],

      options: {
        focus:false,
      } ,
      

      SALELIST: [
          {name:'가격문의',   value:1}
        , {name:'답변완료',   value:2}
        
      ],


    }),

    mounted(){
      this.PRICEREQUEST_IDX = this.$route.query.code;
      this.loadpage();
    },

    create(){
      
    },

    methods: {

      gofindshop(){
        this.$router.push("/FindShop");
      },

      show_image(){
        this.$viewerApi({
          images: [this.select_image],
        })
      },

      select_img(img){
        this.select_image = img;
      },

      getIdxtoName(idx, list){
        if(idx == undefined) return;
        for(var i=0;i<list.length;i++){
          var element = list[i];
          
          if(element.value == idx){
            return element.name;
          }
        }
      },

      showlink(link){
        window.open(link);
      },

      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        this.$router.back();
      },
      
      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      loadpage(){

        //내용 가져오기
        this.$http.post(this.$host+'/GuestPriceReqDetail',{
            PRICEREQUEST_IDX: this.PRICEREQUEST_IDX
        }).then((result)=>{
          if(result.data.resultCode == 0){
            //이미 선택된 아이템에 상세만 교체
            window.scrollTo(0,0);
            this.editedItem  = result.data.resultData[0];
            
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG1)){
              this.select_image = this.editedItem.PRODUCT_IMG1;
              this.items.push(this.editedItem.PRODUCT_IMG1)
            }
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG2)){
              this.items.push(this.editedItem.PRODUCT_IMG2)
            }
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG3)){
              this.items.push(this.editedItem.PRODUCT_IMG3)
            }
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
        });

      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },


    },
  
}
</script>


<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}
.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.text_prov{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
}

.text_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
}


.text_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 20px;
  
}

.text_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:#909090;
}

a{
  color:black !important;
}

.v-slide-group__prev .v-slide-group__prev--disabled{
  visibility: collapse !important;
  display: none !important;
}

.tabs_content
{
  min-height: 70vh;
}


</style>