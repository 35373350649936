<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:1000px">
  
    <v-dialog v-model="web_dialog" 
      :fullscreen="$vuetify.breakpoint.smAndDown ? true : true"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '100%'"
      class="pa-0 ma-0" style="z-index:9999"
      >
      <v-card class="pa-0 ma-0">
        <v-card-title style="background:black;height:68px !important">
          <v-row dense class="ma-0 pa-0" align="center">
            <v-col cols="6" class="ma-0 pa-0" align="start">
              <span class="headline" style="color:white">{{ web_title }}</span>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0"  align="end">
              <v-btn elevation="0" style="width:100px !important;" height="50px" class="black_btn" @click="hidesite">Close 닫기</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <iframe
            ref="iframe"
            width="100%"
            :height="$vuetify.breakpoint.smAndDown ? viewportHeight+'px' : viewportHeight-76+'px'"
            :src="web_url"
            title="My Webpage"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            class="ma-0 pa-0"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- 로딩 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="center">
    <v-col class="md-mt-5" cols="11" md="8">

      <v-row align="center" justify="start" dense class="mt-1">
        <v-col cols="12" md="4" sm="12" xs="12" align="left" class="pa-0 d-flex align-center" >
          <a @click="back"><span><v-icon color="black">mdi-chevron-left</v-icon><label class="menutitle">Back</label></span></a>
        </v-col>
        <v-spacer/>
      </v-row>

      <v-row dense justify="center">
        <!-- 상품 이미지 -->
        <v-col cols="12" md="6">
          
          <!-- 
          <viewer
              class="viewer" ref="viewer" 
              :options="options" style="text-align: center;cursor:zoom-in"
              >
              <v-img contain :aspect-ratio="16/9" style="background:white" 
                    :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%"
                    @click="show_image"
                    />
          </viewer>
          -->

          <v-carousel
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            cycle
            show-arrows
            height="auto"
            >
            <v-carousel-item
              v-for="(slide, i) in items"
              :key="i"
              >
              <v-img 
                 :aspect-ratio="16/9" style="background:white" 
                :src="slide" width="100%"
                @click="select_img(slide)"
                />
            </v-carousel-item>
          </v-carousel>          
          
          <!-- 
          <v-row class="ma-0 mt-1" align="center">
            <v-col cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG1_URL)"><v-img :src="editedItem.IMG1_URL ? editedItem.IMG1_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.IMG2_URL != null && editedItem.IMG2_URL != undefined && editedItem.IMG2_URL.length > 0" cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG2_URL)"><v-img :src="editedItem.IMG2_URL ? editedItem.IMG2_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.IMG3_URL != null && editedItem.IMG3_URL != undefined && editedItem.IMG3_URL.length > 0" cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG3_URL)"><v-img :src="editedItem.IMG3_URL ? editedItem.IMG3_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.IMG4_URL != null && editedItem.IMG4_URL != undefined && editedItem.IMG4_URL.length > 0" cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG4_URL)"><v-img :src="editedItem.IMG4_URL ? editedItem.IMG4_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.IMG5_URL != null && editedItem.IMG5_URL != undefined && editedItem.IMG5_URL.length > 0" cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG5_URL)"><v-img :src="editedItem.IMG5_URL ? editedItem.IMG5_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.IMG6_URL != null && editedItem.IMG6_URL != undefined && editedItem.IMG6_URL.length > 0" cols="2" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.IMG6_URL)"><v-img :src="editedItem.IMG6_URL ? editedItem.IMG6_URL : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
          </v-row>
          -->

        </v-col>
        
        <!-- 상품 정보 -->
        <v-col cols="12" md="6">

          <v-row dense align="center" justify="center" >
            <v-col cols="12" md="10" >
              <div  class="text_prov text-subtitle-2" :class="$vuetify.breakpoint.smAndDown ? 'mt-3':'mt-1'">
                <span class="text_info" :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;font-weight:bold' : 'font-size:24px;font-weight:bold'">{{ editedItem.SHOPNAME }}</span>
              </div>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-1 pt-0">
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex flex-column">
              <div class="d-flex">
                <v-icon color="black" :size="$vuetify.breakpoint.smAndDown ? 20 : 23">mdi-map-marker</v-icon>
                <div style="'color:black'" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px'" class="text_info ml-3">{{ editedItem.ADDR1 }}</div>
              </div>
              <div class="d-flex">
                <v-icon color="white" :size="$vuetify.breakpoint.smAndDown ? 20 : 23">mdi-map-marker</v-icon>
                <div style="'color:black'" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'" class="text_info ml-3">{{ editedItem.ADDR2 }}</div>
              </div>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-3 pt-0">
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex flex-column">
              <a :href="'tel:+'+editedItem.SHOPTEL" class="d-flex">
                <v-icon color="black" :size="$vuetify.breakpoint.smAndDown ? 20 : 23">mdi-phone</v-icon>
                <div style="'color:black'" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px'" class="text_info ml-3">{{ editedItem.SHOPTEL }}</div>
              </a>
            </v-col>
          </v-row>

          <v-row v-if="editedItem.HOMEPAGE != null && editedItem.HOMEPAGE.length > 0" dense align="center" justify="center" class="mt-3 pt-0">
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex flex-column">
              <div class="d-flex" style="cursor: pointer;" @click="showsite2(editedItem.SHOPNAME, editedItem.HOMEPAGE)">
                <v-icon color="black" :size="$vuetify.breakpoint.smAndDown ? 20 : 23">mdi-web</v-icon>
                <div style="'color:black;'" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px'" class="text_info ml-3">{{ editedItem.HOMEPAGE }}</div>
              </div>
            </v-col>
          </v-row>

          <!-- 
          <v-row dense align="center" justify="center" class="mt-3 pt-0">
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex flex-column">
              <div class="d-flex" style="cursor: pointer;" @click="goshop(editedItem.SHOPNAME, editedItem.HOMEPAGE)">
                <v-btn @click="gofindshop" class="black_btn" >판매상품보기</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mt-3 pt-0">
            <v-btn text class="text_info">천연다이아</v-btn>
            <v-btn text class="text_info">합성다이아</v-btn>
            <v-btn text class="text_info">보석</v-btn>
            <v-btn text class="text_info">주얼리</v-btn>
          </v-row>
          -->

          
        </v-col>
      </v-row>

    <v-row dense align="center" justify="center" class="mt-4">
      <v-col cols="12" class="pa-0 ma-0">
        <div class="black_line"/>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center" class="mt-5">
      <v-col cols="11" class="pa-0 ma-0">
        <div class="text_info" style="white-space:pre-wrap" :style="$vuetify.breakpoint.smAndDown ? 'font-size:13px;' : 'font-size:16px'">{{ editedItem.SHOPDESC }}</div>
      </v-col>
    </v-row>

    <v-row style="height:50px"></v-row>

    </v-col>
    </v-row>


  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import moment from 'moment'
export default {

    components:{

    },

    data: () => ({

      select_image:'',
      tab: null,

      crop_dialog:false,
      photo_index:0,
      imgSrc:'',

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            

      
      uploadFile6 : [],
      previewImg6:"",   //감정서

      login_dialog: false,

      progress_dialog:false,
      ACCNT_IDX:"",     //넘겨받은 상점 번호
      editedItem:{},
      items:[],

      web_url:"",
      web_dialog:false,
      web_title:"",

      //이미지뷰어 옵션
      options: {
        focus:false,
      },

      imageviewer:null,
      viewportHeight: window.innerHeight,

    }),

    mounted(){
      window.addEventListener('resize', this.updateHeight);
      this.ACCNT_IDX = this.$route.query.code;
      this.loadpage();
    },

    
    beforeDestroy(){
      window.removeEventListener('resize', this.updateHeight);
    },


    beforeRouteLeave(to, from, next){

      if(this.imageviewer != null){
        if(this.imageviewer.viewed){
          //이미지 상세보기가 떠있는 상태면
          try{
            this.imageviewer.destroy();
            this.imageviewer = null;
          }catch(error){
          }finally{
            next(false);
          }
        }else{
          next();
        }
      }else{
        next();
      }
    },    


    create(){
      
    },



    methods: {

      goshop(){
        //productlist의 search_number를 이용해야 하는데 문제는 다이아,주얼리,보석이 한번에 안나오기 때문에 별도의 작업이 또 필요해짐.
        //http://localhost:8081/ProductList?code=0&page=1&rtype=0&isquick=0&stype=0&dtype=0&vivid=0&curidx=163
        //this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, dtype:0, vivid:0, isquick:0}});
      },

      updateHeight() {
        this.viewportHeight = window.innerHeight;
      },



      getIndextoValue(value, list){
        if(value == null || value == undefined){
          return '';
        }
        for(var i=0;i<list.length;i++){
          var element = list[i];
          if(element.value == Number(value)){
            return ' '+element.name;
          }
        }
        return '';
      },

      show_image(){

        const imglist = [];
        if(!this.isNullCheck(this.editedItem.IMG1_URL)){
          imglist.push(this.editedItem.IMG1_URL);
        }
        if(!this.isNullCheck(this.editedItem.IMG2_URL)){
          imglist.push(this.editedItem.IMG2_URL);
        }
        if(!this.isNullCheck(this.editedItem.IMG3_URL)){
          imglist.push(this.editedItem.IMG3_URL);
        }
        if(!this.isNullCheck(this.editedItem.IMG4_URL)){
          imglist.push(this.editedItem.IMG4_URL);
        }
        if(!this.isNullCheck(this.editedItem.IMG5_URL)){
          imglist.push(this.editedItem.IMG5_URL);
        }
        if(!this.isNullCheck(this.editedItem.IMG6_URL)){
          imglist.push(this.editedItem.IMG6_URL);
        }

        let initidx = 0;
        for(var i=0;i<imglist.length;i++){
          if(imglist[i] == this.select_image){
            initidx = i;
            break;
          }
        }
        
        this.imageviewer = this.$viewerApi({
          options:{
            initialViewIndex: initidx
          },
          images: imglist,
        });

      },

      goLogin(){
        this.$router.push({path:"/Login", query:{code:this.editedItem.PRODUCT_IDX}});
      },
      
      goJoin(){
        this.$router.push({path:"/Join", query:{code:this.editedItem.PRODUCT_IDX}});
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      test(img){
        console.log("select img : " + img);
      },

      select_img(img){
        console.log("select img : " + img);
        this.select_image = img;
        this.show_image();
      },

      showlink(link){
        window.open(link);
      },

      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        //this.$router.back();
        //console.log("window history len : " + window.history.length);
        if(window.history.length > 1){
          this.$router.go(-1);
          //this.$router.back();
        }else{
          this.$router.push("/Welcome");
        }
        
      },

      loadpage(){

        //내용 가져오기
        this.$http.post(this.$host + "/GuestShopInfoDetail",{
            ACCNT_IDX: this.ACCNT_IDX
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
            
            window.scrollTo(0,0);
            this.editedItem  = result.data.resultData[0];
            
            if(!this.isNullCheck(this.editedItem.IMG1_URL)){
              this.select_image = this.editedItem.IMG1_URL;
              this.items.push(this.editedItem.IMG1_URL)
            }
            if(!this.isNullCheck(this.editedItem.IMG2_URL)){
              this.items.push(this.editedItem.IMG2_URL)
            }
            if(!this.isNullCheck(this.editedItem.IMG3_URL)){
              this.items.push(this.editedItem.IMG3_URL)
            }
            if(!this.isNullCheck(this.editedItem.IMG4_URL)){
              this.items.push(this.editedItem.IMG4_URL)
            }
            if(!this.isNullCheck(this.editedItem.IMG5_URL)){
              this.items.push(this.editedItem.IMG5_URL)
            }
            if(!this.isNullCheck(this.editedItem.IMG6_URL)){
              this.items.push(this.editedItem.IMG6_URL)
            }
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          console.log("error : " + error);
        });

      },


      

      //mp4가 아닌 동영상 확인하기 (다른 웹사이트 띄우기)
      showsite2(title, path){
        //window.open(path, "_blank");
        //자바스크립트 인터페이스로 플러터 호출
        try{
          //플러터 웹뷰에 등록된 자바스크립트 함수 호출 웹=>플러터 호출
          window.flutter_inappwebview.callHandler('ShowSite', title, path).then(function(result) {
            //플러터에서 값을 리턴하는 경우 받아서 사용 가능
            //console.log(result);
          });
        }catch(error){
          //플러터 환경이 아닌 경우 기본 웹API 사용
          try{
            //this.web_title  = title;
            //this.web_url    = path;
            //this.web_dialog = true;
            window.open(path, "_blank");
          }catch(e){
            this.$fire({
              html: "Unable to verify.<br><br>확인할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }
        }

      },

      hidesite(){
        this.web_url = "about:blank";
        this.web_dialog = false;
      },


      showImage(index){
        var img = [this.previewImg6];
        this.imageviewer = this.$viewerApi({
          images: img,
        })
      },      
 
      //mp4가 아닌 동영상 확인하기 (다른 웹사이트 띄우기)
      showsite(path){
        window.open(path, "_blank");
      },



    },
  
}
</script>


<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.text_info{
  font-family: 'Inter';
  font-style: normal;
  color: #000000;
}

a {
  text-decoration: none;
}

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}
.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.text_prov{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
}

.text_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
}


.text_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 20px;
  
}

.text_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:#909090;
}


.dialog_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #090808;
  line-height: 26px;
}

.dialog_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:black;
}

a{
  color:black !important;
}

.v-slide-group__prev .v-slide-group__prev--disabled{
  visibility: collapse !important;
  display: none !important;
}

.tabs_content
{
  min-height: 70vh;
}


.text_sale_line{
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  -webkit-text-decoration-color: #a6a6a6;
  text-decoration-color: #a6a6a6;
  color:#a6a6a6;
}


.fixed_rightbottom_mb{
  position: fixed;
  right: 5px;
  bottom: 70px;
}

.fixed_rightbottom{
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.iframesize{
  width:100%;
  height:100%;
}

</style>

