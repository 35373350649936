<template>
    

    <!-- 공급처 정보 -->
    <v-row align="center"  justify="center" >
      
      <pdfinfo ref="pdfinfopopup" />

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="prov_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" 
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Requester Info&nbsp;요청자 정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Account&nbsp;<span class="dialog_subtitle_kor">계정</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="prov_id"
                      single-line v-model="provItem.ACCNT" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <template v-if="provItem.GRADE > 1">
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Company Name&nbsp;<span class="dialog_subtitle_kor">업체명</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.NAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.TEL" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                        
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Name&nbsp;<span class="dialog_subtitle_kor">신청자 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.REGNAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Position&nbsp;<span class="dialog_subtitle_kor">신청자 직책</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.REGPOSITION" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">신청자 휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.MOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                 
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Name&nbsp;<span class="dialog_subtitle_kor">대표자 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.OWNERNAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Mobile&nbsp;<span class="dialog_subtitle_kor">대표자 휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.OWNERMOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business Number&nbsp;<span class="dialog_subtitle_kor">사업자번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.BUSINESSNUMBER" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">business conditions/specifications&nbsp;<span class="dialog_subtitle_kor">업태/종목</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.BUSINESSITEM" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea height="70"
                        class="none_radius" color="black"
                        single-line v-model="provItem.ADDRESS" disabled
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business license&nbsp;<span class="dialog_subtitle_kor">사업자등록증</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                      <v-col align="center" align-self="center" >

                        <template v-if="provpreviewImg1.length > 0">
                          <v-row dense  justify="center">
                            <template v-if="provpreviewImg1 == provpdfPreviewImg1 || provpreviewImg1.split('.').pop().toLowerCase() == 'pdf'">
                              <img  width="240" style="hegiht:auto" :src="provpdfPreviewImg1" class="image" @click="showImage_pdf_popup(0)">
                            </template>
                            <template v-else>
                              <viewer class="viewer" ref="viewer" >
                                <img style="hegiht:auto" width="240" :src="provpreviewImg1" class="image">
                              </viewer>
                            </template>
                          </v-row>
                        </template>
                        <template v-else>
                          <v-row dense class="image-border2" align="center" justify="center">
                            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED">mdi-plus</v-icon>
                          </v-row>
                        </template>

                      </v-col>
                    </v-col>
                  </v-row>          

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business Card&nbsp;<span class="dialog_subtitle_kor">명함</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                      <v-col align="center" align-self="center" >

                        <template v-if="provpreviewImg2.length > 0">
                          <v-row dense  justify="center">
                            <template v-if="provpreviewImg2 == provpdfPreviewImg2 || provpreviewImg2.split('.').pop().toLowerCase() == 'pdf'">
                              <img  width="240" style="hegiht:auto" :src="provpdfPreviewImg2" class="image" @click="showImage_pdf_popup(1)">
                            </template>
                            <template v-else>
                              <viewer class="viewer" ref="viewer">
                                <img style="hegiht:auto" width="240" :src="provpreviewImg2" class="image">
                              </viewer>
                            </template>
                          </v-row>
                        </template>
                        <template v-else>
                          <v-row dense class="image-border2" align="center" justify="center">
                            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED">mdi-plus</v-icon>
                          </v-row>
                        </template>
                      </v-col>
                    </v-col>
                  </v-row>                    

                </template>
                <template v-else>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.NAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.TEL" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>      
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.MOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                 

                </template>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Provider Type&nbsp;<span class="dialog_subtitle_kor">공급자 분류</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="provItem.COMMENT_STATE" :items="COMMENTSTATELIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Comment&nbsp;<span class="dialog_subtitle_kor">코멘트</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea
                      class="none_radius" color="black" disabled
                      single-line v-model="provItem.COMMENT"
                      :height="$vuetify.breakpoint.smAndDown ? 80 : 100"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="prov_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
          
        </v-card>
      </v-dialog>  
    </v-row>        
</template>

<script>
export default {

    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{

    },

    data: ()=> ({
      //공급처 정보
      prov_dialog: false,
      provItem:{},

      //PDF첨부시 보여줄 공통 이미지 - 사업자등록증
      provpdfPreviewImg1:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmimg.jpg",

      //PDF첨부시 보여줄 공통 이미지 - 명함
      provpdfPreviewImg2:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmcard.jpg",

      provpreviewImg1:"",
      provpreviewImg2:"",

      COMMENTSTATELIST: [
          {name:'최우수', value:5}
        , {name:'우수',   value:4}
        , {name:'보통',   value:3}
        , {name:'미흡',   value:2}
        , {name:'정지',   value:1}
        , {name:'없음',   value:0}
      ],

    }),

    methods: {


      showImage_pdf_popup(index){
        //console.log("showImage_pdf : " + index);
        if(index == 0){
          this.$refs.pdfinfopopup.geturl(this.provItem.BUSINESS_IMG);
        }else{
          this.$refs.pdfinfopopup.geturl(this.provItem.CARD_IMG);
        }
      },           

      isNullCheck_popup(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },      

      //공급처 정보
      loadprov(idx){

        this.$http.post(this.$host+'/AcntDetail',{
          ACCNT_IDX:idx
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.provItem = result.data.resultData[0];

              this.provpreviewImg1 = "";
              this.provpreviewImg2 = "";
              if(this.provItem.BUSINESS_IMG != null && this.provItem.BUSINESS_IMG.length > 0){
                if(this.provItem.BUSINESS_IMG.split('.').pop().toLowerCase() == 'pdf'){
                  //pdf면 pdf미리보기로 지정
                  this.provpreviewImg1 = this.provpdfPreviewImg1;
                }else{
                  //이미지면 그대로 지정
                  this.provpreviewImg1 = this.provItem.BUSINESS_IMG != null && this.provItem.BUSINESS_IMG.length > 0 ? this.provItem.BUSINESS_IMG : "";
                }
              }
              if(this.provItem.CARD_IMG != null && this.provItem.CARD_IMG.length > 0){
                if(this.provItem.CARD_IMG.split('.').pop().toLowerCase() == 'pdf'){
                  //pdf면 pdf미리보기로 지정
                  this.provpreviewImg2 = this.provpdfPreviewImg2;
                }else{
                  //이미지면 그대로 지정
                  this.provpreviewImg2 = this.provItem.CARD_IMG != null && this.provItem.CARD_IMG.length > 0 ? this.provItem.CARD_IMG : "";
                }
              }
              

              //var temp = document.getElementById("prov_id");
              //this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});

              this.prov_dialog  = true;
              

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },

    }

}
</script>


<style scoped>

</style>

