<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:1000px">
  
    <v-dialog v-model="web_dialog" 
      :fullscreen="$vuetify.breakpoint.smAndDown ? true : true"
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '100%'"
      class="pa-0 ma-0" style="z-index:9999"
      >
      <v-card class="pa-0 ma-0">
        <v-card-title style="background:black;height:68px !important">
          <v-row dense class="ma-0 pa-0" align="center">
            <v-col cols="6" class="ma-0 pa-0" align="start">
              <span class="headline" style="color:white">{{ web_title }}</span>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0"  align="end">
              <v-btn elevation="0" style="width:100px !important;" height="50px" class="black_btn" @click="hidesite">Close 닫기</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="ma-0 pa-0">
          <iframe
            ref="iframe"
            width="100%"
            :height="$vuetify.breakpoint.smAndDown ? viewportHeight+'px' : viewportHeight-76+'px'"
            :src="web_url"
            title="My Webpage"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen
            class="ma-0 pa-0"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 공급처 정보 -->
    <!-- <userinfo ref="userinfo"/> -->

    <!-- 공급처 정보 -->
    <v-row align="center"  justify="center" >

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="userinfo_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" 
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Requester Info&nbsp;요청자 정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Account&nbsp;<span class="dialog_subtitle_kor">계정</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="prov_id"
                      single-line v-model="provItem.ACCNT" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <template v-if="provItem.GRADE > 1">
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Company Name&nbsp;<span class="dialog_subtitle_kor">업체명</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.NAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.TEL" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                        
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Name&nbsp;<span class="dialog_subtitle_kor">신청자 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.REGNAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Position&nbsp;<span class="dialog_subtitle_kor">신청자 직책</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.REGPOSITION" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">신청자 휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.MOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                 
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Name&nbsp;<span class="dialog_subtitle_kor">대표자 이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.OWNERNAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Mobile&nbsp;<span class="dialog_subtitle_kor">대표자 휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.OWNERMOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business Number&nbsp;<span class="dialog_subtitle_kor">사업자번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.BUSINESSNUMBER" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">business conditions/specifications&nbsp;<span class="dialog_subtitle_kor">업태/종목</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.BUSINESSITEM" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                  

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea height="70"
                        class="none_radius" color="black"
                        single-line v-model="provItem.ADDRESS" disabled
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business license&nbsp;<span class="dialog_subtitle_kor">사업자등록증</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                      <v-col align="center" align-self="center" >

                        <template v-if="provpreviewImg1.length > 0">
                          <v-row dense  justify="center">
                            <template v-if="provpreviewImg1 == provpdfPreviewImg1 || provpreviewImg1.split('.').pop().toLowerCase() == 'pdf'">
                              <img  width="240" style="hegiht:auto" :src="provpdfPreviewImg1" class="image" @click="showImage_pdf_popup(0)">
                            </template>
                            <template v-else>
                              <viewer class="viewer" ref="viewer" :options="options">
                                <v-img style="hegiht:auto" width="240" :src="provpreviewImg1" class="image" @click="show_image2(provpreviewImg1)"/>
                              </viewer>
                            </template>
                          </v-row>
                        </template>
                        <template v-else>
                          <v-row dense class="image-border2" align="center" justify="center">
                            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED">mdi-plus</v-icon>
                          </v-row>
                        </template>

                      </v-col>
                    </v-col>
                  </v-row>          

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business Card&nbsp;<span class="dialog_subtitle_kor">명함</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                      <v-col align="center" align-self="center" >

                        <template v-if="provpreviewImg2.length > 0">
                          <v-row dense  justify="center">
                            <template v-if="provpreviewImg2 == provpdfPreviewImg2 || provpreviewImg2.split('.').pop().toLowerCase() == 'pdf'">
                              <img  width="240" style="hegiht:auto" :src="provpdfPreviewImg2" class="image" @click="showImage_pdf_popup(1)">
                            </template>
                            <template v-else>
                              <viewer class="viewer" ref="viewer">
                                <v-img style="hegiht:auto" width="240" :src="provpreviewImg2" class="image" @click="show_image2(provpreviewImg2)"/>
                              </viewer>
                            </template>
                          </v-row>
                        </template>
                        <template v-else>
                          <v-row dense class="image-border2" align="center" justify="center">
                            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED">mdi-plus</v-icon>
                          </v-row>
                        </template>
                      </v-col>
                    </v-col>
                  </v-row>                    

                </template>
                <template v-else>
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.NAME" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"
                        single-line v-model="provItem.TEL" disabled
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>      
                  <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">휴대폰번호</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-text-field 
                        class="none_radius" color="black"  
                        single-line v-model="provItem.MOBILE" disabled 
                        :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        outlined dense hide-details/>
                    </v-col>
                  </v-row>                 

                </template>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Provider Type&nbsp;<span class="dialog_subtitle_kor">공급자 분류</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="provItem.COMMENT_STATE" :items="COMMENTSTATELIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Comment&nbsp;<span class="dialog_subtitle_kor">코멘트</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea
                      class="none_radius" color="black" disabled
                      single-line v-model="provItem.COMMENT"
                      :height="$vuetify.breakpoint.smAndDown ? 80 : 100"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="userinfo_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
          
        </v-card>
      </v-dialog>  
    </v-row>     

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="photo_index == 5 ? false : option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <pdfinfo ref="pdfinfo" />

    <!-- 로그인 유도 팝업 -->
    <v-row align="center" justify="center">
      <v-dialog  persistent overlay-color="#000000FF"  v-model="login_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">KDGE</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-spacer/>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <div class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >문의나 구매는 회원가입이 필요합니다.<br><span class="dialog_subtitle_eng">Membership registration is for Questions or buying request.</span></div>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goJoin">Join 가입하기</v-btn>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <p class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >회원이시면 로그인해주세요<br><span class="dialog_subtitle_eng">If you are a member, please log in.</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goLogin">Login 로그인</v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <v-spacer/>
          </v-card-text>

          <v-card-actions class="mt-5 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12"> <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="login_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 구매하기 팝업 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable persistent overlay-color="#000000FF"  v-model="buy_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Buy&nbsp;구매하기</span>
            </v-col>
          </v-row>
          </v-card-title>


          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->


                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Receiving method&nbsp;<span class="dialog_subtitle_kor">수령방식</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_SHIP_TYPE" :items="SHIPTYPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" 
                      outlined color="#000000" hide-details class="none_radius" 
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="BUY_NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      v-model="BUY_ADDR" class="none_radius" color="black" 
                      hide-details outlined dense
                      />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Comment&nbsp;<span class="dialog_subtitle_kor">요청사항</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea :height="$vuetify.breakpoint.smAndDown ? 60 : 80"
                      v-model="BUY_MSG" class="none_radius" color="black" 
                      hide-details outlined dense
                      />
                  </v-col>
                </v-row>

                <!-- 세공인 경우 세공정보 추가 입력 -->
                <template v-if="editedItem.PRODUCT_CLASS == 3">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" id="PRODUCT_IMG1">Jewelry Making information&nbsp;<span class="dialog_subtitle_kor">세공의뢰 정보입력</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Type of jewelry provided&nbsp;<span class="dialog_subtitle_kor">제공 보석종류</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="BUY_REQ_KIND" :items="DESIGNGEMLIST" 
                        dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        item-text="name" item-value="value" 
                        outlined color="#000000" hide-details class="none_radius" 
                        />
                    </v-col>
                  </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Jewelry Type Provided&nbsp;<span class="dialog_subtitle_kor">제공 보석타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_REQ_CLASS" :items="DIAMONDTYPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" 
                      outlined color="#000000" hide-details class="none_radius" 
                      />
                  </v-col>
                </v-row>

                <v-row v-if="BUY_REQ_KIND == 0" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Shape&nbsp;<span class="dialog_subtitle_kor">다이아 쉐입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_REQDIA_SHAPE" :items="DIASHAPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="NAME_ENG" item-value="IDX" 
                      outlined color="#000000" hide-details class="none_radius" 
                      >
                      <template v-slot:selection="{ item, index }">
                        <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.NAME_ENG }}</span>
                      </template>                        
                      <template v-slot:item="{ item }">
                        <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.NAME_ENG }}</span>
                      </template>                      
                    </v-select>
                  </v-col>
                </v-row>                

                <v-row v-if="BUY_REQ_KIND == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Gemstone Kind&nbsp;<span class="dialog_subtitle_kor">보석 종류</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_REQGEM_TYPE" :items="JEWELRYKINDLIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="NAME_ENG" item-value="IDX" 
                      outlined color="#000000" hide-details class="none_radius" 
                      >
                      <template v-slot:selection="{ item, index }">
                        <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3" style="font-weight:bold">{{ item.NAME_ENG }}</span>
                      </template>                        
                      <template v-slot:item="{ item }">
                        <img class="mt-1 mb-1" :width="$vuetify.breakpoint.smAndDown ? 30 : 40" :src="item.IMG"><span class="ml-3">{{ item.NAME_ENG }}</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Carat&nbsp;<span class="dialog_subtitle_kor">보석중량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="REQ_WEIGHT" suffix="ct"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Size&nbsp;<span class="dialog_subtitle_kor">보석사이즈</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="REQ_SIZE"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Laboratory&nbsp;<span class="dialog_subtitle_kor">감정기관</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="REQ_CERTI"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="10" md="8" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate image&nbsp;<span class="dialog_subtitle_kor">감정서 이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      
                      <template v-if="previewImg6.length > 0">
                        <v-row dense  justify="center">

                          <template v-if="previewImg6 == pdfPreviewImg || previewImg6.split('.').pop().toLowerCase() == 'pdf'">
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <v-icon style="width:80px;height:40px;color:white" @click="showImage_pdf(5)">mdi-magnify-expand</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="pdfPreviewImg" class="image">
                            </div>
                          </template>
                          <template v-else-if="previewImg6.split('.').pop().toLowerCase() == 'jpg' || previewImg6.split('.').pop().toLowerCase() == 'jpeg' || previewImg6.split('.').pop().toLowerCase() == 'png' ">
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <v-icon style="width:80px;height:40px;color:white" @click="showImage(5)">mdi-magnify-expand</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="previewImg6" class="image">
                            </div>
                          </template>
                          <template v-else>
                            <div style="position:relative">
                              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                                <template v-if="previewImg6.startsWith('data:image')">
                                  <v-icon style="width:80px;height:40px;color:white" @click="showImage(5)">mdi-magnify-expand</v-icon>
                                </template>
                                <template v-else>
                                  <v-icon style="width:80px;height:40px;color:white" @click="showsite(REQ_CERTIIMG)">mdi-magnify-expand</v-icon>
                                </template>
                                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(5)">mdi-pencil</v-icon>
                                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                              </div>
                              <img  width="240" style="hegiht:auto" :src="pdflinkImg" class="image">
                            </div>
                          </template>

                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect_pdf(5)">mdi-image</v-icon>
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(5)">mdi-link</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>
                

                <v-row v-if="optionList.length > 0" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Option List&nbsp;<span class="dialog_subtitle_kor">구매옵션</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_OPTION_IDX" :items="optionList" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      :item-text="getoptionName" item-value="CRAFTOPTION_IDX" 
                      outlined color="#000000" hide-details class="none_radius" 
                      @change="compute_price"
                      />
                  </v-col>
                </v-row>
                

                </template>
              

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Order Quantity&nbsp;<span class="dialog_subtitle_kor">주문수량</span></p>
                  </v-col>
                  <v-col cols="6" md="6" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="BUY_QUANTITY" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="3" md="2" class="pa-0 pl-1">
                    <v-btn :height="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
                      elevation="0" @click="minus_quantity" 
                      class="black_btn_noheight text-md-subtitle-1 text-body-2" width="100%">-</v-btn>
                  </v-col>
                  <v-col cols="3" md="2" class="pa-0 pl-1">
                    <v-btn :height="$vuetify.breakpoint.smAndDown ? '40px' : '50px'"
                      elevation="0" @click="add_quantity" class="black_btn_noheight text-md-subtitle-1 text-body-2" width="100%">+</v-btn>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Currency&nbsp;<span class="dialog_subtitle_kor">화폐</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="BUY_PRICE_TYPE" :items="PRICETYPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" 
                      outlined color="#000000" hide-details class="none_radius" @change="compute_price"
                      />
                  </v-col>
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p style="color:red" class="text-body-2 text-left ma-0 pa-0 dialog_subtitle_kor">You have to deposit in the selected currency.</p>
                    <p style="color:red" class="text-body-2 text-left ma-0 pa-0 dialog_subtitle_kor">선택하신 화폐로 구매비용을 입금하셔야 합니다.</p>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Total Order Price&nbsp;<span class="dialog_subtitle_kor">총 주문금액</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="BUY_PRICE" disabled :prefix="BUY_PRICE_TYPE == 0 ? '$' : 'W'"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="buy_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="buy_save">Buy 구매하기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 문의하기 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable persistent overlay-color="#000000FF"  v-model="qna_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Question&nbsp;질문하기</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Question Title&nbsp;<span class="dialog_subtitle_kor">질문제목</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="INQUIRY_TITLE" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Question Comment&nbsp;<span class="dialog_subtitle_kor">질문내용</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="INQUIRY_DESC" color="black" hide-details outlined dense/>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="qna_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="inquiry_save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    
    
    <!-- 로딩 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <!-- 링크 입력 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="link_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Link&nbsp;이미지 링크</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Link&nbsp;<span class="dialog_subtitle_kor">링크</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Link"
                      single-line v-model="LINKADDR" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>    

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="link_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_link">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="center">
    <v-col class="md-mt-5" cols="11" md="8">

      <v-row align="center" justify="start" dense class="mt-1">
        <v-col cols="12" md="4" sm="12" xs="12" align="left" class="pa-0 d-flex align-center" >
          <a @click="back"><span><v-icon color="black">mdi-chevron-left</v-icon><label class="menutitle">Back</label></span></a>
        </v-col>
        <v-spacer/>
      </v-row>

      <v-row dense justify="center">
        <!-- 상품 이미지 -->
        <v-col cols="12" md="5">
          <template v-if="!showMovie">
            <viewer
                class="viewer" ref="viewer" 
                :options="options" style="text-align: center;cursor:zoom-in"
                >
                <!-- <v-img :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%" /> -->
                <!--
                <img :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%" 
                  :style="$vuetify.breakpoint.smAndDown ? 'width:85vw;height:85vw;inline-size:fit-content':'width:25vw;height:25vw;inline-size:fit-content'"  
                  />
                -->
                <v-img contain :aspect-ratio="1/1" style="background:white" 
                      :src="select_image ? select_image : require('@/assets/no_image.jpg')" width="100%"
                      @click="show_image"
                      />
            </viewer>
            </template>
            <template v-else>
              <video ref="video1" width="100%" controls autoplay :src="editedItem.MOVIE_PATH"></video>
            </template>

          
          <v-row class="ma-0 mt-1" align="center">
            <v-col cols="3" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.PRODUCT_IMG1)"><v-img :src="editedItem.PRODUCT_IMG1 ? editedItem.PRODUCT_IMG1 : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col v-if="editedItem.PRODUCT_IMG2 != null && editedItem.PRODUCT_IMG2 != undefined && editedItem.PRODUCT_IMG2.length > 0" cols="3" class="pa-0 ma-0 pl-1">
              <a @click="select_img(editedItem.PRODUCT_IMG2)"><v-img :src="editedItem.PRODUCT_IMG2 ? editedItem.PRODUCT_IMG2 : require('@/assets/no_image.jpg')" width="100%"/></a>
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 pl-1" v-if="editedItem.APPRAISAL_IMG != null && editedItem.APPRAISAL_IMG != undefined && editedItem.APPRAISAL_IMG.length > 0">
              <template v-if="editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'pdf'">
                <!-- pdf인 경우 -->
                <a @click="select_pdf(editedItem.APPRAISAL_IMG)"><v-img contain :aspect-ratio="1/1" style="background:white" :src="pdfPreviewImg ? pdfPreviewImg : require('@/assets/no_image.jpg')" width="100%"/></a>  
              </template>
              <template v-else-if="this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'jpg' || this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'jpeg' || this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() == 'png'">
                <!-- 이미지인 경우 -->
                <a @click="select_img(editedItem.APPRAISAL_IMG)"><v-img contain :aspect-ratio="1/1" style="background:white" :src="editedItem.APPRAISAL_IMG ? editedItem.APPRAISAL_IMG : require('@/assets/no_image.jpg')" width="100%"/></a>
              </template>
              <template v-else>
                <!-- 외부 링크인 경우 -->
                <a @click="showsite2('certificate', editedItem.APPRAISAL_IMG)"><v-img contain :aspect-ratio="1/1" style="background:white" :src="pdflinkImg" width="100%"/></a>
                <!-- <a :href="editedItem.APPRAISAL_IMG" target="_blank"><v-img contain :aspect-ratio="1/1" style="background:white" :src="pdflinkImg" width="100%"/></a> -->
              </template>
            </v-col>
            <v-col cols="3" class="pa-0 ma-0 pl-1" align-self="center" v-if="editedItem.MOVIE_PATH != null && editedItem.MOVIE_PATH.length > 0">
              <template v-if="editedItem.MOVIE_PATH != null && editedItem.MOVIE_PATH.split('.').pop().toLowerCase() == 'mp4'">
                <a @click="play_movie"><v-img contain :aspect-ratio="1/1" style="background:white" :src="require('@/assets/newvideo_icon.png')" width="100%"/></a>
              </template>
              <template v-else>
                <a @click="showsite2('movie', editedItem.MOVIE_PATH)"><v-img contain :aspect-ratio="1/1" style="background:white" :src="require('@/assets/webvideo_icon.png')" width="100%"/></a>
              </template>
            </v-col>
          </v-row>
          
        </v-col>
        
        <!-- 상품 정보 -->
        <v-col cols="12" md="7">

          <v-row dense align="center" justify="center" >
            <v-col cols="10" md="8" >
              <div  class="text_prov text-subtitle-2"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-0':'mt-0'"
                >
                <span class="text_title text-h5">{{ editedItem.PRODUCT_NUMBER }}</span>
              </div>
              <div class="text_title text-h6">
                <template v-if="editedItem.PRODUCT_CLASS == 0">
                  <template v-if="editedItem.DIAMOND_CLASS == 0">
                    <!-- 다이아몬드 -->
                    {{ editedItem.WEIGHT+' ' + editedItem.CERTI_NAME + getIndextoValue(editedItem.COLOR, COLORLIST) + getIndextoValue(editedItem.TRANSPARENCY, TRANSLIST) + getIndextoValue(editedItem.EVALUATION, DISEVALLIST) + getIndextoValue(editedItem.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                  </template>
                  <template v-else>
                    {{ editedItem.WEIGHT+' ' + editedItem.CERTI_NAME + getIndextoValue(editedItem.DIAMONDCOLOR, DIAMONDCOLORLIST) + getIndextoValue(editedItem.TRANSPARENCY, TRANSLIST) + getIndextoValue(editedItem.FLUORESCENCE, DIDFLUORESCENCELIST)  }}
                  </template>
                </template>
                <template v-else-if="editedItem.PRODUCT_CLASS == 1">
                  {{ 'Weight ' + editedItem.WEIGHT + ' ' + editedItem.CERTI_NAME}}
                </template>
                <template v-else-if="editedItem.PRODUCT_CLASS == 2">
                  {{ editedItem.MAINSTONE +' '+ editedItem.MAINWEIGHT }}
                </template>
              </div>

              <template v-if="editedItem.PRODUCT_CLASS == 2 || editedItem.PRODUCT_CLASS == 3">
                <div class="text_title text-h6">
                  {{ getIndextoValue(editedItem.METALTYPE, METALLIST) + getIndextoValue(editedItem.METALWEIGHT, WEIGHTLIST) +' '+ editedItem.WEIGHT }}
                </div>
              </template>

            </v-col>
            <v-col cols="2" md="2" align="center" align-self="center" >
              <template v-if="isFav">
                <v-btn fab small @click="delFav" color="black">
                  <v-icon color="red">mdi-heart</v-icon>
                  <!-- <v-img width="50" min-width="40" aspect-ratio="1" :src="require('@/assets/fav_icon_on.png')"/> -->
                </v-btn>
              </template>
              <template v-else>
                <v-btn fab small color="black" @click="goFav">
                  <v-icon color="white">mdi-heart-outline</v-icon>
                  <!-- <v-img width="50" min-width="40" aspect-ratio="1" :src="require('@/assets/fav_icon.png')"/> -->
                </v-btn>
              </template>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-0 pt-0">
            <v-col cols="12" md="10" class="pa-0 ml-1" :class="$vuetify.breakpoint.smAndDown?'d-flex align-center':''">
              <template v-if="editedItem.SALERATEVALUE < 1">
                <div>
                  <div :style="grade > 1 ? 'color:red' : 'color:black'" class="text_title text-subtitle-1 text-md-h6">{{ '$ ' + editedItem.PRICE_DP_USD_SALE  + ' USD (VAT included)'}}</div>
                  <div :style="grade > 1 ? 'color:red' : 'color:black'" class="ml-md-0 text_sale_line text_title text-subtitle-2 text-md-body-2">{{ '$ '+editedItem.PRICE_DP_USD  + ' USD'}}</div>
                </div>
              </template>
              <template v-else>
                <div :style="grade > 1 ? 'color:red' : 'color:black'" class="text_title text-subtitle-1 text-md-h6">{{ '$ '+editedItem.PRICE_DP_USD  + ' USD (VAT included)'}}</div>
              </template>
            </v-col>
          </v-row>
          <v-row align="start" justify="center" dense >
            <v-col cols="12" md="10" class="pa-0 ml-1" :class="$vuetify.breakpoint.smAndDown?'d-flex align-center':''">
              <template v-if="editedItem.SALERATEVALUE < 1">
                <div>
                <div :style="grade > 1 ? 'color:red' : 'color:black'" class="text_title text-subtitle-1 text-md-h6">{{ '₩ '+editedItem.PRICE_DP_WON_SALE  + ' 원 (부가세포함 금액)'}}</div>
                <div :style="grade > 1 ? 'color:red' : 'color:black'" class="ml-md-0 text_sale_line text_title text-subtitle-2 text-md-body-2">{{ '₩ '+editedItem.PRICE_DP_WON  + ' 원'}}</div>
                </div>
              </template>
              <template v-else>
                <div :style="grade > 1 ? 'color:red' : 'color:black'" class="text_title text-subtitle-1 text-md-h6">{{ '₩ '+editedItem.PRICE_DP_WON  + ' 원 (부가세포함 금액)'}}</div>
              </template>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense >
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex align-center">
              <div class="text_title text-subtitle-1 text-md-h6">Status <span class="text-body-2">현황</span></div>
              <div class="ml-3 ml-md-5 text_title text-subtitle-1 text-md-h6">
                <span :style="editedItem.PRODUCT_STATE == 0 ? 'color:#ff0000':'color:#000000'">{{ editedItem.PRODUCT_STATE == 0 ? 'Off Sale 판매보류' : editedItem.PRODUCT_STATE == 1 ? 'On Sale 판매 중' : 'On Hand 보유' }}</span>
              </div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense >
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex align-center">
              <div class="text_title text-subtitle-1 text-md-h6">{{ editedItem.PRODUCT_STOCK }}</div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense >
            <v-col cols="12" md="10" class="pa-0 ml-1 d-flex align-center">
              <a v-if="grade > 90" class="text_title text-subtitle-1 text-md-h6" style="color:blue !important" 
                @click="loadprov(editedItem.ACCNT_IDX)">{{ editedItem.NAME }}</a>
            </v-col>
          </v-row>

        <!-- 모바일 즐겨찾기
        <v-col cols="2" style="background:black" class="ma-0 pa-0">
          <template v-if="isFav">
            <v-btn icon fab @click="delFav">
              <v-icon color="red">mdi-heart</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon fab @click="goFav">
              <v-icon color="white">mdi-heart-outline</v-icon>
            </v-btn>
          </template>
        </v-col>
        -->

          <v-row dense align="center" justify="center" class="hidden-sm-and-down">
            <v-col cols="12" md="10" class="pa-0 ma-0">
              <div class="black_line"/>
            </v-col>
          </v-row>

          <v-row class="mt-10 hidden-sm-and-down" justify="center" style="background:white;" dense>
            <v-col md="10" class="pa-0 ma-0">
              <v-row dense class="pa-0 ma-0">
                <v-col cols="4" class="ma-0 pa-0" style="background:black">
                  <v-btn elevation="0" class="black_btn_noheight" width="100%" height="60px" @click="goQuestion">
                    <div style="font-size:17px;line-height:18px;margin-top:5px;margin-top:5px">문의하기<br><span style="font-size:14px">Questions</span></div>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="ma-0 pa-0" style="padding-left:1px !important;">
                  <v-btn elevation="0" class="black_btn_noheight" width="100%" height="60px" @click="gobuy">
                    <div style="font-size:17px;line-height:18px;margin-top:5px">구매요청<br><span style="font-size:14px">Buying Request</span></div>
                  </v-btn>
                </v-col>
                <v-col cols="4" class="ma-0 pa-0" style="padding-left:1px !important;">
                  <v-btn elevation="0" class="black_btn_noheight" width="100%" height="60px" @click="gobuyinfo">
                    <div style="font-size:17px;line-height:18px;margin-top:5px">구매안내<br><span style="font-size:14px">Buying Guide</span></div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    <v-row dense align="center" justify="center" class="mt-1">
      <v-col cols="12" class="pa-0 ma-0">
        <div class="black_line"/>
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center" class="mt-1">
      <v-col cols="10" class="pa-0 ma-0 text-center">
        <div style="text-weight:bold" class="text-md-h6 text-subtitle-1 mt-md-3 mb-md-3 mt-2 mb-1">PRODUCT INFO 상품정보</div>
      </v-col>
      <v-col cols="10" md="6" class="pa-0 ma-0">
        <div class="black_line_mb"/>
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12">

        <v-row class="mt-1">
          <v-col cols="12">

            <!-- 다이아몬드 -->
            <template v-if="editedItem.PRODUCT_CLASS == 0">

              <!-- 다이아몬드 공통 -->
              <v-row no-gutters dense align="center" justify="center">
                  <v-col cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Laboratory
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">감정기관</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.CERTI_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Diamond Type
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">타입</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.DIAMONDTYPE, DIAMONDTYPELIST) }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Weight
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">중량</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.WEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Shape
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">모양</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.SHAPE_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Clarity
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">투명도</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.TRANSPARENCY, TRANSLIST) }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              
              <template v-if="editedItem.DIAMOND_CLASS == 0">
                <!-- 일반 다이아몬드 -->
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Color
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">컬러</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.COLOR, COLORLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Cut
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">연마</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.EVALUATION, EVALLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Polish
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">폴리쉬</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.POLISH, EVALLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Symmetry
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">대칭</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.SYMMETRY, EVALLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Measurements
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">사이즈</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.HEIGHT + 'mm x ' + editedItem.WIDTH+'mm x ' + editedItem.DEPTH + 'mm' }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Fluorescence
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">형광성</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.FLUORESCENCE, FLUORESCENCELIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

              </template>
              <template v-else>
                <!-- 펜시 다이아몬드 -->
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Color
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">컬러</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.DIAMONDCOLOR, DIAMONDCOLORLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Color Distribution
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">색분포</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.COLORDISTRI }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Polish
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">폴리쉬</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.POLISH, EVALLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Symmetry
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">대칭</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.SYMMETRY, EVALLIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>                
                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Measurements
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">사이즈</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.HEIGHT + 'mm x ' + editedItem.WIDTH+'mm x ' + editedItem.DEPTH + 'mm' }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Color Grade
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">그레이드</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.COLORGRADE, DISCOLORGRADELIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>                

                <v-row no-gutters dense align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center">
                    <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                      <v-row>
                        <v-col cols="6" md="6" align="center">
                          <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                            <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Fluorescence
                            <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">형광성</span></div>
                          </div>
                        </v-col>              
                        <v-col cols="6" md="6" align="start" class="d-flex align-center">
                          <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.FLUORESCENCE, FLUORESCENCELIST) }}</div>
                        </v-col>              
                      </v-row>
                    </v-col>
                </v-row>

              </template>

            </template>

            <!-- 젬스톤 -->
            <template v-if="editedItem.PRODUCT_CLASS == 1">
              <v-row no-gutters dense align="center" justify="center">
                  <v-col cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Gem Name
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">보석명</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.JEWELRYKIND_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>
              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Laboratory
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">감정기관</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.CERTI_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>
              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Weight
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">중량</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.WEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Shape
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">모양</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.SHAPE_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Measurements
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">사이즈</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.HEIGHT + 'mm x ' + editedItem.WIDTH+'mm x ' + editedItem.DEPTH + 'mm' }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Identification
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">식별</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.IDENTIFICATION }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Color
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">컬러</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.GEMSTONECOLOR }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

            </template>


            <!-- 주얼리 -->
            <template v-if="editedItem.PRODUCT_CLASS == 2">

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Jewelry Items
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">주얼리 품목</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.PRODUCTITEM_DESC + ' ' + editedItem.PRODUCTITEM_NAME }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Gem Name
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">보석명</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.MAINSTONE + ' ' + editedItem.MAINWEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Sub Stone
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">보조스톤</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.SUBSTONE + ' ' + editedItem.SUBWEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Metal Type
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">금속종류</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.METALTYPE, METALLIST) + ' ' + getIdxtoName(editedItem.METALWEIGHT, WEIGHTLIST)}}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>              

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Metal Weight
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">금속중량</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.WEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Type
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">보석타입</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.DIAMONDTYPE, DIAMONDTYPELIST) }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

            </template>

            <!-- 세공 디자인 -->
            <template v-if="editedItem.PRODUCT_CLASS == 3">

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Design Type
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">디자인 분류</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIndextoValue(editedItem.PRODUCTTYPE_IDX, DESIGNCLASSLIST) }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

              <v-row no-gutters dense align="center" justify="center">
                  <v-col cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Metal Type
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">금속종류</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ getIdxtoName(editedItem.METALTYPE, METALLIST) + ' ' + getIdxtoName(editedItem.METALWEIGHT, WEIGHTLIST)}}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>              

              <v-row no-gutters dense align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-divider></v-divider>
                  </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center">
                  <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                    <v-row>
                      <v-col cols="6" md="6" align="center">
                        <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                          <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Metal Weight
                          <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">금속중량</span></div>
                        </div>
                      </v-col>              
                      <v-col cols="6" md="6" align="start" class="d-flex align-center">
                        <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.WEIGHT }}</div>
                      </v-col>              
                    </v-row>
                  </v-col>
              </v-row>

            </template>

            <!-- 전체 공통 설명 -->
            <v-row no-gutters dense align="center" justify="center">
                <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                  <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
                <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                  <v-row>
                    <v-col cols="6" md="6" align="center">
                      <div class="pt-1 pb-1 d-flex align-center" style="background:#efefef;width:100%;height:100%">
                        <div style="width:100%" class="text_subtitle_eng" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:20px'">Explanation
                        <span :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'" class="text_subtitle_kor">설명</span></div>
                      </div>
                    </v-col>              
                    <v-col cols="6" md="6" align="start" class="d-flex align-center">
                      <div class="pt-1 pb-1 text_subtitle_eng" style="white-space:pre-wrap" :style=" $vuetify.breakpoint.smAndDown ? 'font-size:12px':'font-size:16px'">{{ editedItem.DESC }}</div>
                    </v-col>              
                  </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters dense align="center" justify="center">
                <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                  <v-divider></v-divider>
                </v-col>
            </v-row>   

            <v-row no-gutters class="mt-5" dense align="center" justify="center">
                <v-col md="12" cols="12"  align="center" class="pl-1 pr-1">
                  <v-btn small elevation="1" @click="share" fab><v-icon>mdi-share-variant</v-icon></v-btn>
                </v-col>
            </v-row>   

          </v-col>
        </v-row>
        
      </v-col>

    </v-row>

    <v-row style="height:50px"></v-row>

    </v-col>
    </v-row>


<!-- 
        <v-col cols="2" style="background:black" class="ma-0 pa-0">
          <template v-if="isFav">
            <v-btn icon fab @click="delFav">
              <v-icon color="red">mdi-heart</v-icon>
            </v-btn>
          </template>
          <template v-else>
            <v-btn icon fab @click="goFav">
              <v-icon color="white">mdi-heart-outline</v-icon>
            </v-btn>
          </template>
        </v-col>
-->

    <!-- 모바일 하단 고정 버튼 -->
    <div v-if="buy_dialog == false && qna_dialog == false && login_dialog == false && userinfo_dialog == false"
        class="hidden-md-and-up footer_fixed">
      <v-row class="pa-0 ma-0" justify="center" style="background:white;">
        <v-col cols="4" class="ma-0 pa-0" style="background:black">
          <v-btn elevation="0" class="black_btn_noheight" width="100%" height="54px" @click="goQuestion">
            <div style="font-size:14px">문의하기<br><span style="font-size:10px">Questions</span></div>
          </v-btn>
        </v-col>
        <v-col cols="4" class="ma-0 pa-0" style="padding-left:1px !important;">
          <v-btn elevation="0" class="black_btn_noheight" width="100%" height="54px" @click="gobuy">
            <div style="font-size:14px">구매요청<br><span style="font-size:10px">Buying Request</span></div>
          </v-btn>
        </v-col>
        <v-col cols="4" class="ma-0 pa-0" style="padding-left:1px !important;">
          <v-btn elevation="0" class="black_btn_noheight" width="100%" height="54px" @click="gobuyinfo">
            <div style="font-size:14px">구매안내<br><span style="font-size:10px">Buying Guide</span></div>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!-- 구매안내 
    <div>
      <v-btn color="success" 
            :class="$vuetify.breakpoint.smAndDown ? 'fixed_rightbottom_mb text-caption' : 'fixed_rightbottom text-body-2 pt-6 pb-6'"
            @click="gobuyinfo"
            >
            How to buy<br>구매안내
      </v-btn>
    </div>
    -->
    <div class="circle-button"
      style="position:fixed;right:20px;bottom:60px;"   
      @click="openchat">
    </div>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css";
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
import moment from 'moment'
export default {

    components:{
      VueCropper,
    },

    data: () => ({
      
      //PDF첨부시 보여줄 공통 이미지
      pdfPreviewImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/pdfimg2.png",
      //외부 PDF 첨부시 보여줄 공통 이미지
      pdflinkImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/web_pdfimg3.png",

      userinfo_dialog : false,
      provItem:{},
      //PDF첨부시 보여줄 공통 이미지 - 사업자등록증
      provpdfPreviewImg1:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmimg.jpg",
      //PDF첨부시 보여줄 공통 이미지 - 명함
      provpdfPreviewImg2:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmcard.jpg",
      provpreviewImg1:"",
      provpreviewImg2:"",
      COMMENTSTATELIST: [
          {name:'최우수', value:5}
        , {name:'우수',   value:4}
        , {name:'보통',   value:3}
        , {name:'미흡',   value:2}
        , {name:'정지',   value:1}
        , {name:'없음',   value:0}
      ],      


      select_image:'',
      tab: null,
      isFav:false,
      showMovie:false,

      buy_dialog: false,
      BUY_NAME:'',
      BUY_ADDR:'',
      BUY_MSG:'',
      BUY_PRICE:0,
      BUY_QUANTITY:1,
      BUY_SHIP_TYPE:0,
      BUY_PRICE_TYPE:0,

      //보석세공 주문 정보
      BUY_OPTION_IDX:-1, //주문 옵션인덱스
      BUY_REQ_KIND:0,   //요청 보석종류
      BUY_REQ_CLASS:0,  //요청 보석타입
      BUY_REQDIA_SHAPE:0, //다이아의 경우 다이아 쉐입
      BUY_REQGEM_TYPE:0,  //보석의 경우 보석 종류
      REQ_WEIGHT:0,     //보석 중량
      REQ_SIZE:"",      //보석 사이즈
      REQ_CERTI:"",     //인증서명
      REQ_CERTIIMG:null,  //인증서 이미지
      REQ_CERTIIMG_REF:null,  //인증서 이미지

      crop_dialog:false,
      photo_index:0,
      imgSrc:'',

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            

      link_dialog: false,
      link_index:0,
      LINKADDR:"",

      //옵션리스트
      optionList:[],
      
      uploadFile6 : [],
      previewImg6:"",   //감정서
      //PDF첨부시 보여줄 공통 이미지
      pdfPreviewImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/pdfimg2.png",
      //외부 PDF 첨부시 보여줄 공통 이미지
      pdflinkImg:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/web_pdfimg3.png",

      DESIGNCLASSLIST: [
          {name:'반지',     value:0}
        , {name:'목걸이',   value:1}
        , {name:'귀걸이',   value:2}
        , {name:'커플링',   value:3}
        , {name:'팔찌',     value:4}
        , {name:'기타',     value:9}
      ],

      DIASHAPELIST:[],    //다이아몬드 쉐입리스트
      JEWELRYKINDLIST:[], //젬스톤 보석 종류

      SHIPTYPELIST: [
          {name:'Direct delivery from KDGE./본사직접배송',          value:0}
        , {name:'Delivery service./택배',                          value:1}
        , {name:'Visit a designate retailer to receive the products./지정소매상',  value:2}
        , {name:'Visit KDGE to receive the products./본사방문수령',                value:3}
      ],

      PRICETYPELIST: [
          {name:'USD/달러',   value:0}
        , {name:'WON/원화',   value:1}
      ],
      
      login_dialog: false,
      qna_dialog: false,
      INQUIRY_TITLE:'',
      INQUIRY_DESC:'',

      progress_dialog:false,
      PRODUCT_IDX:"",     //넘겨받은 상품 번호
      editedItem:{},
      items:[
              
      ],

      web_url:"",
      web_dialog:false,
      web_title:"",

      //이미지뷰어 옵션
      options: {
        focus:false,
      },
      
      //제품 구분 - 이걸 나눌까? 이렇게 합칠까?
      CLASSLIST: [
          {name:'다이아몬드',   value:0}
        , {name:'젬스톤',       value:1}
        , {name:'주얼리',       value:2}
      ],

      //모양
      SHAPELIST: [
      ],

      CERTILIST: [
      ],

      //평가
      EVALLIST: [
          {name:'No Grade',    value:9}
        , {name:'Ideal',       value:5}
        , {name:'Excellent',   value:0}
        , {name:'Very Good',   value:1}
        , {name:'Good',        value:2}
        , {name:'Fair',        value:3}
        , {name:'Poor',        value:4}
      ],

      //제목용 평가
      DISEVALLIST: [
          {name:'ID',   value:5}      
        , {name:'EX',   value:0}
        , {name:'VG',   value:1}
        , {name:'G',    value:2}
        , {name:'F',    value:3}
        , {name:'P',    value:4}
      ],

      //금속함량
      WEIGHTLIST: [
          {name:'24k',   value:0}
        , {name:'22k',   value:1}
        , {name:'20k',   value:2}
        , {name:'18k',   value:3}
        , {name:'14k',   value:4}
        , {name:'12k',   value:5}
        , {name:'10k',   value:6}
        , {name:'999',   value:7}
        , {name:'995',   value:8}
        , {name:'950',   value:9}
        , {name:'900',   value:10}
      ],

      //금속종류
      METALLIST:[
          {name:'Yellow Gold',value:0}
        , {name:'White Gold', value:1}
        , {name:'Pink Gold',  value:2}
        , {name:'Pure Gold',  value:3}
        , {name:'Platinum',   value:4}
        , {name:'Silver',     value:5}
        , {name:'Titanium',   value:6}
        , {name:'Other',      value:7}
      ],

      //투명도
      TRANSLIST: [
          {name:'FL',    value:0}
        , {name:'IF',    value:1}
        , {name:'VVS1',  value:2}
        , {name:'VVS2',  value:3}
        , {name:'VS1',   value:4}
        , {name:'VS2',   value:5}
        , {name:'SI1',   value:6}
        , {name:'SI2',   value:7}
        , {name:'SI3',   value:8}
        , {name:'I1',    value:9}
        , {name:'I2',    value:10}
        , {name:'I3',    value:11}
      ],

      //색상
      COLORLIST: [
          {name:'D',   value:0}
        , {name:'E',   value:1}
        , {name:'F',   value:2}
        , {name:'G',   value:3}
        , {name:'H',   value:4}
        , {name:'I',   value:5}
        , {name:'J',   value:6}
        , {name:'K',   value:7}
        , {name:'L',   value:8}
        , {name:'M',   value:9}
        , {name:'N',   value:10}
      ],

      DIAMONDCOLORLIST: [
          {name:'Red',    value:0, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/1red.png'}
        , {name:'Blue',   value:1, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/2blue.png'}
        , {name:'Pink',   value:2, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/3pink.png'}
        , {name:'Yellow', value:3, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/4yellow.png'}
        , {name:'Green',  value:4, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/5green.png'}
        , {name:'Brown',  value:5, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/6brown.png'}
        , {name:'Black',  value:6, IMG:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/enviroment/7black.png'}
        , {name:'Other',  value:7, IMG:''}
      ],
      
      FLUORESCENCELIST: [
          {name:'None',           value:0}
        , {name:'Faint',          value:1}
        , {name:'Faint Slight',   value:5}
        , {name:'Very Slight',    value:4}
        , {name:'Slight',         value:7}
        , {name:'Medium',         value:2}
        , {name:'Strong',         value:3}
        , {name:'Very Strong',    value:6}
        , {name:'N/A',            value:99}
      ],

      //형광성 제목용
      DIDFLUORESCENCELIST: [
          {name:'NON',  value:0}
        , {name:'FNT',  value:1}
        , {name:'FSL',  value:5}
        , {name:'VSL',  value:4}
        , {name:'SLT',  value:7}
        , {name:'MED',  value:2}
        , {name:'STG',  value:3}
        , {name:'VST',  value:6}
        , {name:'N/A',  value:99}
      ],

      DIAMONDTYPELIST: [
          {name:'Natural/천연',    value:0}
        , {name:'Lab Grown/합성',  value:1}
      ],

      DESIGNGEMLIST: [
          {name:'Diamond/다이아몬드',         value:0}
        , {name:'Gemstone/보석',             value:1}
      ],

      GEMTYPELIST: [
          {name:'Natural/천연',    value:0}
        , {name:'Lab Grown/합성',  value:1}
        , {name:'None/없음',       value:2}
      ],

      SALELIST: [
          {name:'할인없음',   value:0}
        , {name:'지속할인',   value:1}
        , {name:'기간할인',   value:2}
      ],


      //제목용 컬러등급
      DISCOLORGRADELIST: [
          {name:'Faint',        value:3}
        , {name:'Very Light',   value:4}
        , {name:'Light',        value:5}
        , {name:'Fancy Light',  value:2}
        , {name:'Fancy',        value:6}
        , {name:'Fancy Intense',value:1}
        , {name:'Fancy Vivid',  value:0}
        , {name:'Fancy Dark',   value:7}
        , {name:'Fancy Deep',   value:8}
        , {name:'Other',        value:99}
      ],

      TREATMENTLIST:[
          {name:'No/아니오',   value:0}
        , {name:'Yes/예',   value:1}
      ],

      grade:0,

      testview:null,

      imageviewer:null,
      imageviewer2:null,

      viewportHeight: window.innerHeight,

    }),

    mounted(){

      window.addEventListener('resize', this.updateHeight);
      //console.log("viewportHeight : " + this.viewportHeight);

      this.grade = this.$session.get("GRADE");
      //console.log("grade : "+this.grade);
      this.PRODUCT_IDX = this.$route.query.code;
      this.loadpage();
    },

    
    beforeDestroy(){
      window.removeEventListener('resize', this.updateHeight);
    },
    


    beforeRouteLeave(to, from, next){

      if(this.$refs.pdfinfo.pdf_dialog){
        this.$refs.pdfinfo.close();
        next(false);
      }else if(this.userinfo_dialog){

        if(this.imageviewer2 != null){
          try{
            this.imageviewer2.destroy();
            this.imageviewer2 = null;
          }catch(error){
          }finally{
            this.userinfo_dialog = false;
            next(false);
          }        
        }else{
          this.userinfo_dialog = false;
          next(false);
        }
      }else if(this.imageviewer != null){
        if(this.imageviewer.viewed){
          //이미지 상세보기가 떠있는 상태면
          try{
            this.imageviewer.destroy();
            this.imageviewer = null;
          }catch(error){
          }finally{
            next(false);
          }
        }else{
          next();
        }
      }else{
        next();
      }
    },    


    create(){
      
    },



    methods: {

      openchat(){
        window.ChannelIO('showMessenger');
      },


      updateHeight() {
        this.viewportHeight = window.innerHeight;
      },


      getOptionItem(option_idx){
        if(option_idx == null || option_idx == undefined){
          return null;
        }
        for(var i=0;i<this.optionList.length;i++){
          var element = this.optionList[i];
          if(element.CRAFTOPTION_IDX == option_idx){
            return element;
          }
        }
        return null;
      },      

      getIndextoValue(value, list){
        if(value == null || value == undefined){
          return '';
        }
        for(var i=0;i<list.length;i++){
          var element = list[i];
          if(element.value == Number(value)){
            return ' '+element.name;
          }
        }
        return '';
      },

      show_image(){

        const imglist = [];
        if(!this.isNullCheck(this.editedItem.PRODUCT_IMG1)){
          imglist.push(this.editedItem.PRODUCT_IMG1);
        }
        if(!this.isNullCheck(this.editedItem.PRODUCT_IMG2)){
          imglist.push(this.editedItem.PRODUCT_IMG2);
        }
        if(!this.isNullCheck(this.editedItem.APPRAISAL_IMG)){
          if(this.editedItem.APPRAISAL_IMG.split('.').pop().toLowerCase() != 'pdf'){
            imglist.push(this.editedItem.APPRAISAL_IMG);
          }
        }

        let initidx = 0;
        for(var i=0;i<imglist.length;i++){
          if(imglist[i] == this.select_image){
            initidx = i;
            break;
          }
        }
        
        this.imageviewer = this.$viewerApi({
          options:{
            initialViewIndex: initidx
          },
          images: imglist,
        });

      },

      show_image2(imgurl){
        this.imageviewer2 = this.$viewerApi({
          options:{
          },          
          images: [imgurl],
        });
      },      

      async share(){

        //자바스크립트 인터페이스로 플러터 호출
        try{
          //플러터 웹뷰에 등록된 자바스크립트 함수 호출 웹=>플러터 호출
          //window.flutter_inappwebview.callHandler('Print1', editedItem.PRODUCT_NUMBER, this.editedItem.PRODUCT_IMG1).then(function(result) {
          window.flutter_inappwebview.callHandler('Print1', this.editedItem.PRODUCT_IDX, this.editedItem.PRODUCT_NUMBER, this.editedItem.PRODUCT_IMG1).then(function(result) {
            //플러터에서 값을 리턴하는 경우 받아서 사용 가능
            //console.log(result);
          });

          /* 인자값이 있는 경우 아래처럼
          window.flutter_inappwebview.callHandler('Print2', 'test').then(function(result) {
            console.log(result);
          });
          */
          
        }catch(error){
          //플러터 환경이 아닌 경우 기본 웹API 사용
          try{
            await navigator.share({
                title: "KDGE 한국다이아몬드보석거래소"
              , text: this.editedItem.PRODUCT_NUMBER
              , url: window.location.href
            });
          }catch(e){
          }
        }


      },

      gobuyinfo(){
        this.$router.push("/BuyInfo");
      },

      goLogin(){
        this.$router.push({path:"/Login", query:{code:this.editedItem.PRODUCT_IDX}});
      },
      
      goJoin(){
        this.$router.push({path:"/Join", query:{code:this.editedItem.PRODUCT_IDX}});
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      select_img(img){
        this.showMovie = false;
        this.select_image = img;
      },

      select_pdf(img){
        this.$refs.pdfinfo.geturl(img);
        //this.$router.push("/PdfViewer");
      },

      play_movie(){
        this.showMovie = true;
        //this.$refs.video1.play();
      },

      getIdxtoName(idx, list){
        if(idx == undefined) return;
        for(var i=0;i<list.length;i++){
          var element = list[i];
          //console.log("idx : " + idx + " / " + element.value);
          if(element.value == idx){
            return element.name;
          }
        }
      },

      compute_price(){
        if(this.BUY_PRICE_TYPE == 0){
          //달러
          let price = this.editedItem.PRICE_USD;
          if(this.optionList.length > 0){
            //옵션가격 계산
            let optionItem = this.getOptionItem(this.BUY_OPTION_IDX);
            let option_price = optionItem.PRICE_USD;
            this.BUY_PRICE = (this.BUY_QUANTITY * (price + option_price)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }else{
            this.BUY_PRICE = (this.BUY_QUANTITY * price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }

        }else{
          //원화
          let price = this.editedItem.PRICE_WON;
          if(this.optionList.length > 0){
            //옵션가격 계산
            let optionItem = this.getOptionItem(this.BUY_OPTION_IDX);
            let option_price = optionItem.PRICE_WON;
            this.BUY_PRICE = (this.BUY_QUANTITY * (price + option_price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }else{
            this.BUY_PRICE = (this.BUY_QUANTITY * price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          }

        }
      },

      add_quantity(){
        this.BUY_QUANTITY = this.BUY_QUANTITY + 1;
        this.compute_price();
      },

      minus_quantity(){
        this.BUY_QUANTITY = this.BUY_QUANTITY - 1 > 1 ? this.BUY_QUANTITY - 1 : 1;
        this.compute_price();
      },

      goFav(){

        if(this.$session.get("GRADE") >= 1){

          //즐겨찾기
          this.$fire({
            html: "Do you want to add this product to your favorites?<br><br>해당 상품을 즐겨찾기에 추가하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

              this.$http.post(this.$host+'/FavoriteInsert',{
                  PRODUCT_IDX:this.editedItem.PRODUCT_IDX
                , PROV_IDX: this.editedItem.ACCNT_IDX
              },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
              .then((result)=>{
                if(result.data.resultCode == 0){
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                  this.$fire({
                    html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
                  this.loadpage();
                }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$fire({
                    html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      this.$EventBus.$emit('clearsession');
                    }
                  });
                }else{
                  this.$fire({
                    html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
                }
                
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                  this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });  

             });   

            }

          });
          
        }else{
          //this.login_dialog = true;
          
          //비회원 즐겨찾기 처리
          let favdata = localStorage.getItem("favlist");
          let favlist = favdata ? JSON.parse(favdata) : [];

          if(this.checkFav(favlist, Number(this.editedItem.PRODUCT_IDX))){
            //이미 즐겨찾기 된 경우 
          }else{
          //즐겨찾기
          this.$fire({
            html: "Do you want to add this product to your favorites?<br><br>해당 상품을 즐겨찾기에 추가하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

              favlist.push({idx:Number(this.editedItem.PRODUCT_IDX), date:moment().format("YYYY-MM-DD")});
              localStorage.setItem("favlist", JSON.stringify(favlist));
              this.isFav = true;

              this.$fire({
                html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

            }
          });

          }

        }

      },

      checkFav(favlist, checkidx){
        let flag = false;
        if(favlist.length == 0){
          return flag;
        }
        
        for(var i=0;i<favlist.length;i++){
          //console.log("check fav1 => " + favlist[i].idx + " / " + checkidx);
          if(favlist[i].idx == checkidx){
            flag = true;
            break;
          }
        }
        return flag;
      },
      checkFavIndex(favlist, checkidx){
        let idx = null;
        if(favlist.length == 0){
          return idx;
        }
        for(var i=0;i<favlist.length;i++){
          //console.log("check fav2 => " + favlist[i].idx + " / " + checkidx);
          if(favlist[i].idx == checkidx){
            idx = i;
            break;
          }
        }
        return idx;
      },

      delFav(){

        if(this.$session.get("GRADE") >= 1){

          //즐겨찾기 해제
          this.$fire({
            html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

              this.$http.post(this.$host+'/FavoriteDelete',{
                  PRODUCT_IDX:this.editedItem.PRODUCT_IDX
                , PROV_IDX: this.editedItem.ACCNT_IDX
              },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
              .then((result)=>{
                if(result.data.resultCode == 0){
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                  this.$fire({
                    html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      this.loadpage();
                    }
                  });
                  
                }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$fire({
                    html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      this.$EventBus.$emit('clearsession');
                    }
                  });
                }else{
                  this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

                }
                
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                  this.$fire({
                html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

            });

            }
          });
          
        }else{
          //this.login_dialog = true;
          //비회원 즐겨찾기 삭제 처리
          let favdata = localStorage.getItem("favlist");
          let favlist = favdata ? JSON.parse(favdata) : [];
          if(this.checkFav(favlist, Number(this.editedItem.PRODUCT_IDX))){

            //즐겨찾기 해제
            this.$fire({
              html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
              cancelButtonColor:'#ff0000',
              showCancelButton: true, 
              reverseButtons: true,
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){

                let fidx = this.checkFavIndex(favlist, Number(this.editedItem.PRODUCT_IDX));
                favlist.splice(fidx, 1);
                localStorage.setItem("favlist", JSON.stringify(favlist));
                this.isFav = false;

                this.$fire({
                  html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
            });

          }

        }

      },      

      goQuestion(){
        if(this.$session.get("GRADE") >= 1){
          //질문하기
          this.INQUIRY_TITLE = "";
          this.INQUIRY_DESC = "";
          this.qna_dialog = true;
        }else{
          this.login_dialog = true;
        }
      },

      gobuy(){

        if(this.$session.get("GRADE") >= 1){
          //구매하기 초기화
          this.BUY_NAME = "";
          this.BUY_ADDR = "";
          this.BUY_MSG  = "";
          this.BUY_QUANTITY = 1;
          this.BUY_PRICE_TYPE = 0;
          
          if(this.editedItem.PRODUCT_CLASS == 3){ 
            //보석세공 주문 정보
            this.uploadFile6 = [];
            this.previewImg6 = "";
            
            this.BUY_REQ_KIND   = 0;   //요청 보석종류
            this.BUY_REQ_CLASS  = 0;  //요청 보석타입
            this.REQ_WEIGHT = 0;     //보석 중량
            this.REQ_SIZE  = "";      //보석 사이즈
            this.REQ_CERTI = "";     //인증서명
            this.REQ_CERTIIMG = null;  //인증서 이미지
            this.REQ_CERTIIMG_REF = null;  //인증서 이미지

            //초기값을 가져와서 셋팅해야 한다.
            if(this.optionList.length > 0){
              this.BUY_OPTION_IDX   = this.optionList[0].CRAFTOPTION_IDX;
            }else{
              this.BUY_OPTION_IDX   = -1;
            }
            this.BUY_REQDIA_SHAPE = this.DIASHAPELIST[0].IDX;
            this.BUY_REQGEM_TYPE  = this.JEWELRYKINDLIST[0].IDX;
          }

          this.compute_price();
          this.buy_dialog = true;
        }else{
          this.login_dialog = true;
        }
      },

      showlink(link){
        window.open(link);
      },

      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        //this.$router.back();
        //console.log("window history len : " + window.history.length);
        if(window.history.length > 1){
          this.$router.go(-1);
          //this.$router.back();
        }else{
          this.$router.push("/Welcome");
        }
        
      },

      loadpage(){

        let api_url =  "/GuestProductDetail";
        let api_header = {};
        if(this.$session.get("GRADE") == 1){
          api_url =  "/ProductDetailUser";
          api_header = { headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")} };
        }else if(this.$session.get("GRADE") > 1){
          api_url =  "/ProductDetail";
          api_header = { headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")} };
        }

        //내용 가져오기
        this.$http.post(this.$host + api_url,{
            PRODUCT_IDX: this.PRODUCT_IDX
        },api_header)
        .then((result)=>{
          if(result.data.resultCode == 0){
            //이미 선택된 아이템에 상세만 교체
            window.scrollTo(0,0);

            this.editedItem  = result.data.resultData[0];

            if(result.data.optionData.length == 0){
              this.optionList  = [];
            }else{
              this.optionList  = result.data.optionData;
              this.BUY_OPTION_IDX = this.optionList[0].CRAFTOPTION_IDX;
            }
            if(this.$session.get("GRADE") >= 1){
              this.isFav       = result.data.favFlag;
            }else{
              //비회원 즐겨찾기
              let favdata = localStorage.getItem("favlist");
              let favlist = favdata ? JSON.parse(favdata) : [];
              if(this.checkFav(favlist, Number(this.PRODUCT_IDX))){
                //이미 즐겨찾기 된 경우 
                this.isFav = true;
              }else{
                this.isFav = false;
              }
            }

            const list1 = result.data.DiamondShape;
            list1.forEach(element => {
                this.DIASHAPELIST.push(element); 
            });
            const list2 = result.data.GemstoneType;
            list2.forEach(element => {
                this.JEWELRYKINDLIST.push(element); 
            });
            
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG1)){
              this.select_image = this.editedItem.PRODUCT_IMG1;
              this.items.push(this.editedItem.PRODUCT_IMG1)
            }
            if(!this.isNullCheck(this.editedItem.PRODUCT_IMG2)){
              this.items.push(this.editedItem.PRODUCT_IMG2)
            }
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          console.log("error : " + error);
        });

      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },
      
      
      inquiry_save(){

        if(this.isNullCheck(this.INQUIRY_TITLE)){
          
          this.$fire({
            html: "Please enter the title of question<br>질문제목을 입력하세요",
            confirmButtonText:"Confirm 확인",
            confirmButtonColor: '#000000'
          }).then(r => {
            //console.log(r.value);
          });
          return;
        } 

        if(this.isNullCheck(this.INQUIRY_DESC)){
          this.$fire({
            html: "Please enter the comment of question<br>질문내용을 입력하세요",
            confirmButtonText:"Confirm 확인",
            confirmButtonColor: '#000000'
          });
          return;
        } 

        this.$http.post(this.$host+'/InQuiryInsert', {
            TITLE: escape(this.INQUIRY_TITLE)
          , DESC: escape(this.INQUIRY_DESC)
          , PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PROV_IDX: this.editedItem.ACCNT_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.qna_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "A new inquiry has been registered.<br><br>새로운 문의사항이 등록되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });


          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
              html: "Failed to add inquiry<br><br>문의사항 추가 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.qna_dialog = false;
          this.$fire({
            html: "Failed to add inquiry<br><br>문의사항 추가 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });


      },

      ifstringnull(value){
        if(value == null || value == undefined){
          return "";
        }else{
          return escape(value);
        } 
      },

      //구매하기(구매요청)
      async buy_save(){

        if(this.isNullCheck(this.BUY_NAME)){
          this.$fire({
            html: "Please enter your name<br>이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 

        if(this.isNullCheck(this.BUY_ADDR)){
          this.$fire({
            html: "Please enter your address<br>주소를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            //console.log(r.value);
          });
          return;
        } 


        if(this.editedItem.PRODUCT_CLASS == 3){
          //세공 디자인은 요청 정보 체크
          const weightVal = /[^0-9.]/g;

          const checksize = cmvalidation.checkValReg(this.REQ_SIZE, null, 1, 50);
          if(checksize == 1){
            this.$fire({
              html: "Please enter the size.<br><br>보석사이즈를 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checksize == 3){
            this.$fire({
              html: "Please Enter size in 1 characters or then<br><br>사이즈는 1자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checksize == 4){
            this.$fire({
              html: "Please Enter size in 50 characters or less<br><br>사이즈는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const check = cmvalidation.checkValReg(this.REQ_WEIGHT, weightVal, -1, -1);
          if(check == 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 2){
            this.$fire({
              html: "Weight can only be entered with numbers and .<br><br>중량은 숫자와 .만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(String(this.REQ_WEIGHT).replaceAll('.','').length < 1){
            this.$fire({
              html: "Please enter the weight.<br><br>중량을 입력하세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          
          
          if(!this.isNullCheck(this.uploadFile6)){
            
            //로그인 계정의 accnt_idx
            let accnt_idx = this.$session.exists("ACCNT_IDX")

            var uploadpath = accnt_idx+"/"+Date.now().toString()+".jpg";
            if(this.previewImg6 == this.pdfPreviewImg || this.previewImg6.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath = accnt_idx+"/"+Date.now().toString()+".pdf";
            }

            //대표 이미지 등록
            let upload_cb6 = await this.uploadStorage("product", uploadpath, this.uploadFile6);
            if(upload_cb6 == null){
                this.progress_dialog = false;
                this.$fire({
                    html: "certificate registration error occurred<br><br>인증서 등록 오류 발생",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
                return;
              } 
              this.uploadFile6 = [];
              this.REQ_CERTIIMG_REF = upload_cb6.resultRef;
          }                

        }

        this.$http.post(this.$host+'/PurchaseInsert', {
            ADDRESS: this.ifstringnull(this.BUY_ADDR)
          , NAME: this.ifstringnull(this.BUY_NAME)
          , MESSAGE: this.ifstringnull(this.BUY_MSG)
          , QUANTITY: this.BUY_QUANTITY
          , PRICE: this.BUY_PRICE
          , SHIP_TYPE: this.BUY_SHIP_TYPE
          , PRICE_TYPE: this.BUY_PRICE_TYPE
          , PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PRODUCT_CLASS:this.editedItem.PRODUCT_CLASS
          , REQ_KIND: this.BUY_REQ_KIND
          , REQ_CLASS: this.BUY_REQ_CLASS
          , REQDIA_SHAPE: this.BUY_REQDIA_SHAPE
          , REQGEM_TYPE: this.BUY_REQGEM_TYPE
          , REQ_WEIGHT: this.REQ_WEIGHT
          , REQ_SIZE: this.ifstringnull(this.REQ_SIZE)
          , REQ_CERTI: this.ifstringnull(this.REQ_CERTI)
          , REQ_CERTIIMG: this.REQ_CERTIIMG_REF == null || this.REQ_CERTIIMG_REF == undefined ? '' : escape(this.REQ_CERTIIMG_REF)
          , CRAFTOPTION_IDX: this.BUY_OPTION_IDX == -1 ? null : this.BUY_OPTION_IDX
          , SALERATE: this.editedItem.SALERATE
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.buy_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
              html: "Purchase request failed<br><br>구매요청 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.buy_dialog = false;
            this.$fire({
              html: "Purchase request failed<br><br>구매요청 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

        });



      },


      updateprogress(e){
        //this.progress_value = (e.loaded / e.total) * 100;
      },

      async uploadStorage(folder, filename, blobd_ata){

        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },

      postcodetoAddr(addr){
        this.BUY_ADDR = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);

            }
        }).open();

      },          
      
      

      //mp4가 아닌 동영상 확인하기 (다른 웹사이트 띄우기)
      showsite2(title, path){
        //window.open(path, "_blank");
        //자바스크립트 인터페이스로 플러터 호출
        try{
          //플러터 웹뷰에 등록된 자바스크립트 함수 호출 웹=>플러터 호출
          window.flutter_inappwebview.callHandler('ShowSite', title, path).then(function(result) {
            //플러터에서 값을 리턴하는 경우 받아서 사용 가능
            //console.log(result);
          });
        }catch(error){
          //플러터 환경이 아닌 경우 기본 웹API 사용
          try{
            //this.web_title  = title;
            //this.web_url    = path;
            //this.web_dialog = true;
            window.open(path, "_blank");
          }catch(e){
            this.$fire({
              html: "Unable to verify.<br><br>확인할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }
        }

      },

      hidesite(){
        this.web_url = "about:blank";
        this.web_dialog = false;
      },


      showImage_pdf_popup(index){
        //console.log("showImage_pdf : " + index);
        if(index == 0){
          this.$refs.pdfinfo.geturl(this.provItem.BUSINESS_IMG);
        }else{
          this.$refs.pdfinfo.geturl(this.provItem.CARD_IMG);
        }
      },   
      
      //공급처 정보
      loadprov(idx){

        this.$http.post(this.$host+'/AcntDetail',{
          ACCNT_IDX:idx
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.provItem = result.data.resultData[0];

              this.provpreviewImg1 = "";
              this.provpreviewImg2 = "";
              if(this.provItem.BUSINESS_IMG != null && this.provItem.BUSINESS_IMG.length > 0){
                if(this.provItem.BUSINESS_IMG.split('.').pop().toLowerCase() == 'pdf'){
                  //pdf면 pdf미리보기로 지정
                  this.provpreviewImg1 = this.provpdfPreviewImg1;
                }else{
                  //이미지면 그대로 지정
                  this.provpreviewImg1 = this.provItem.BUSINESS_IMG != null && this.provItem.BUSINESS_IMG.length > 0 ? this.provItem.BUSINESS_IMG : "";
                }
              }
              if(this.provItem.CARD_IMG != null && this.provItem.CARD_IMG.length > 0){
                if(this.provItem.CARD_IMG.split('.').pop().toLowerCase() == 'pdf'){
                  //pdf면 pdf미리보기로 지정
                  this.provpreviewImg2 = this.provpdfPreviewImg2;
                }else{
                  //이미지면 그대로 지정
                  this.provpreviewImg2 = this.provItem.CARD_IMG != null && this.provItem.CARD_IMG.length > 0 ? this.provItem.CARD_IMG : "";
                }
              }
              

              //var temp = document.getElementById("prov_id");
              //this.$vuetify.goTo(temp, {duration:200, offset:50, easing:"easeInOutCubic"});

              this.userinfo_dialog  = true;
              

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },



      

      showImage(index){
        var img = [this.previewImg6];
        this.imageviewer = this.$viewerApi({
          images: img,
        })
      },      
 

      clearFile(index){
          this.previewImg6 = "";
          this.uploadFile6 = null;
          this.REQ_CERTIIMG = null;
      },

      linkadd(index){
        this.LINKADDR = "";
        this.link_index  = index;
        this.link_dialog = true;
      },      

      showImage_pdf(){
        if(this.isNullCheck(this.uploadFile6)){
          this.$refs.pdfinfo.geturl(this.REQ_CERTIIMG);
        }else{
          this.$refs.pdfinfo.getfile(this.uploadFile6);
        }
      },      

      setPdfPreview(){
        this.previewImg6 = this.pdfPreviewImg;
      },
      
      fileSelect_pdf(index){
        const changefunction    = this.changefile;
        const changepdffunction = this.changefile_pdf;
        const updatefunction    = this.setPdfPreview;

        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png,.pdf"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction();
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }else{

          input.onchange = function (event) {

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction();
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }
        input.click();
      },      

      //mp4가 아닌 동영상 확인하기 (다른 웹사이트 띄우기)
      showsite(path){
        window.open(path, "_blank");
      },

      //링크 저장
      save_link(){
        const urlVal = /https?:\/\//;
        const checklink = cmvalidation.checkValReg(this.LINKADDR, urlVal, -1, 255, false);
        if(checklink == 1){
          this.$fire({
            html: "Please enter the Link URL.<br><br>Link URL을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 2){
          this.$fire({
            html: "Link URL must start with http or https.<br><br>Link URL은 http 또는 https로 시작해야 합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 4){
          this.$fire({
            html: "Link URL the maximum input limit.<br><br>Link URL이 최대 입력 제한을 초과합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }              
        
        //인증서 
        if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
          //pdf면 분기처리
          this.previewImg6                  = this.pdfPreviewImg;
        }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
          this.previewImg6                  = this.LINKADDR;
        }else{
          //양식에 어긋나는 pdf 주소인 경우
          this.previewImg6                  = this.LINKADDR;
        }
        //양식에 어긋나도 저장되게 한다. kdge 요청
        this.REQ_CERTIIMG       = this.LINKADDR;
        this.REQ_CERTIIMG_REF   = this.LINKADDR;
        this.link_dialog = false;

      },

      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile6 = [];
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },

      //이미지 선택시
      changefile_pdf(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile6 = [];
          return;
        }

        //PDF는 크롭없이 바로 첨부
        const reader = new FileReader();
        reader.onload = (event2)=>{
          this.uploadFile6 = this.dataURItoBlob(event2.target.result);
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

      },      

      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          this.$refs.cropper_land.getCropData(async data=>{
              this.uploadFile6 = blob;
              this.previewImg6 = data; //이미지뷰 미리보기용
              //console.log("previewImg6 : " + this.previewImg6);
          });
        });
      },

      //옵션명 옆에 가격 표시 (선택한 화폐에 따라서 달라 보이게)
      getoptionName(item){
        if(this.BUY_PRICE_TYPE == 0){
          //달러
          return `${item.OPTION_NAME} ($${item.PRICE_DP_USD} USD)`;
        }else{
          //원화
          return `${item.OPTION_NAME} (₩ ${item.PRICE_DP_WON} 원)`;
        }
      }
            

    },
  
}
</script>


<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.bottombtn{
  width:90%; height:40px;
  text-align: center !important;
  color: #fff !important;
  border-radius: 10px !important;
  background-color: #5d5fef !important;
  transition: 0.3s;  
}
.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.text_prov{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
}

.text_title{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
}


.text_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 20px;
  
}

.text_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:#909090;
}


.dialog_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #090808;
  line-height: 26px;
}

.dialog_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:black;
}

a{
  color:black !important;
}

.v-slide-group__prev .v-slide-group__prev--disabled{
  visibility: collapse !important;
  display: none !important;
}

.tabs_content
{
  min-height: 70vh;
}

.footer_fixed{
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index:9990;
}


.text_sale_line{
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
  -webkit-text-decoration-color: #a6a6a6;
  text-decoration-color: #a6a6a6;
  color:#a6a6a6;
}


.fixed_rightbottom_mb{
  position: fixed;
  right: 5px;
  bottom: 70px;
}

.fixed_rightbottom{
  position: fixed;
  right: 50px;
  bottom: 50px;
}

.iframesize{
  width:100%;
  height:100%;
}

</style>

