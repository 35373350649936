<template>

  <!-- 소비자 제품거래 리스트 -->
  <v-container fluid class="container_bg">

    <!-- 구매요청 상세정보 -->
    <reqinfo ref="reqinfo" />

    <!-- 계약금 입금 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="downpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Deposit information&nbsp;계약금입금 정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">
                      Please deposit the down payment to the account number below under the name of the depositor and press the Save button.<br>
                      <span class="dialog_subtitle_kor">계약금을 입금자의 이름으로 아래 계좌번호로 입금하신 후 저장버튼을 눌러주세요</span></p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <p style="color:#ad9271;font-weight:bold" class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">{{ downpayItem.PRICE_TYPE == 0 ? '$ '+downpayItem.PRICE_USD : downpayItem.PRICE_WON + ' 원' }}</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <br><p style="color:#ad9271;font-weight:bold;white-space:pre-wrap" class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">{{ BANKNUMBER }}</p>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="downpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_senddownpay">{{ downpayItem.INPUTPAYMENT_IDX > 0 ? 'Edit 수정' : 'Save 저장'}}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 잔금입금 정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="balpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Remaining Deposit Information<br>잔금입금정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">
                      Please press the request button after depositing the balance to the account number below under the name of the depositor.<br>
                      <span class="dialog_subtitle_kor">잔금을 입금자의 이름으로 아래 계좌번호로 입금하신 후 확인요청 버튼을 눌러주세요</span></p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="balpayItem.NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <p style="color:#ad9271;font-weight:bold" class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">{{ balpayItem.PRICE_TYPE == 0 ? '$ '+balpayItem.PRICE_USD : balpayItem.PRICE_WON + ' 원' }}</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <br><p style="color:#ad9271;font-weight:bold;white-space:pre-wrap" class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">{{ BANKNUMBER }}</p>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="balpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_sendbalpay">{{ balpayItem.INPUTPAYMENT_IDX > 0 ? 'Edit 수정' : 'Request 확인요청'}}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 제품전달 -->
    <v-row align="center"  justify="center" >

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sned_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Product Delivery&nbsp;상품 전달</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Receiving method&nbsp;<span class="dialog_subtitle_kor">수령방식</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="sendItem.SHIP_TYPE" :items="SHIPTYPELIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shipping company&nbsp;<span class="dialog_subtitle_kor">택배사</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" disabled
                      single-line v-model="sendItem.DELIVERYCOMPANY" prefix="$"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Number&nbsp;<span class="dialog_subtitle_kor">송장번호</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" disabled
                      single-line v-model="sendItem.INVOICENUMBER" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>       
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="sendItem.COMMENT" color="black" hide-details disabled outlined dense/>
                  </v-col>
                </v-row>         
            
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="sned_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>          

        </v-card>
      </v-dialog>  
    </v-row>        

    <!-- 거래완료 처리 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="complete_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Transaction completed<br>거래완료</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->
                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">I received the product safely.<br>Complete the transaction.<br>상품을 안전하게 수령했습니다.<br>거래를 완료합니다.</p>
                  </v-col>
                </v-row>                  
              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="complete_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_complete">Complete<br>거래완료</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 거래 취소 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancellation request<br>취소요청</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">
                      I request cancellation of the transaction.<br>
                      The transaction will be cancelled after approval.<br>
                      현재의 거래를 취소요청합니다.<br>
                      취소 승인 후 거래가 취소됩니다.<br>
                    </p>
                  </v-col>
                </v-row>  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Reason for cancellation&nbsp;<span class="dialog_subtitle_kor">취소사유</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="editedItem.COMMENT" color="black" hide-details outlined dense/>
                  </v-col>
                </v-row>                


              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="cancel_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancel_save">Cancel Request<br>취소요청</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        

    <!-- 취소요청 정보 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancelreq_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancel Request Info<br>취소요청정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Type&nbsp;<span class="dialog_subtitle_kor">취소타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <template v-if="cancelreqItem.CANCEL_TYPE == 0">
                      <label>구매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 1">
                      <label>판매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 2">
                      <label>관리자의 취소</label>
                    </template>
                  </v-col>
                </v-row>              

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Message&nbsp;<span class="dialog_subtitle_kor">취소요청 메시지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelreqItem.COMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancellation Processing&nbsp;<span class="dialog_subtitle_kor">거래취소 처리</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="cancelreqItem.CANCEL_CONFIRM" :items="CONFIRMLIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Answer Message&nbsp;<span class="dialog_subtitle_kor">답변 메시지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelreqItem.RECOMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         


              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancelreq_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-3">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="3" class="pa-0 pl-1 pl-md-0 pr-1 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>

      <v-col cols="12" md="3" class="pa-0 pl-3 pr-3 mt-0 pt-0" >
        <v-select 
          v-model="SEARCHSTATE" :items="SEARCHSTATELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" 
          outlined hide-details class="basicselectstyle" color="black" 
          @change="loadpage(1)"
          />
      </v-col>

    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-md-0 mt-3" dense>

      <!-- 모바일 해상도 -->
      <v-col
        v-for="item in itemList"
        cols="12"
        class="hidden-md-and-up mb-5"
        >
        <v-card >
          <div class="text-body-2"> 

            <template v-if="item.CANCEL_CONFIRM == 1"> 
              <div class="pt-2 pl-2 d-flex" style="background:red;font-size:12px">
                <div style="color:white">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:red;font-size:12px">
                <v-icon class="ml-2 mb-1" color="white" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="ml-2" style="color:white">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mb-1 ml-1 mr-auto" small color="white" @click="loadcancelreq(item)">mdi-pencil</v-icon>
              </div>
              <div class="gray_line"/>
            </template>            
            <template v-else-if="item.CANCEL_CONFIRM == 0"> 
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
                <!-- <a @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{'Order No. '+item.PURCHASE_IDX}}</a> -->
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mr-2 mb-1" small color="black" @click="loadcancelreq(item)">mdi-delete</v-icon>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 1"> <!-- 구매요청 > 관리자확인대기 -->
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mr-2 mb-1" small color="black" @click="delete_item(item)">mdi-delete</v-icon>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 2"> <!-- 계약금요청 => 입금처리필요 -->
              <div class="pt-2 pl-2 d-flex" style="background:#311B92;font-size:12px">
                <div style="color:#ffffff">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#311B92;font-size:12px">
                <v-icon class="ml-2 mb-1" color="white" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#ffffff">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mr-2 mb-1" small color="white" @click="delete_item(item)">mdi-delete</v-icon>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 4"> <!-- 계약금입금 > 관리자확인대기 -->
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mb-1 ml-1 mr-auto" small color="black" @click="loaddownlay(item)">mdi-pencil</v-icon>
                <v-icon class="mr-2 mb-1" small color="black" @click="delete_item(item)">mdi-delete</v-icon>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 6"> <!-- 발송요청 -->
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 8"> <!-- 발송 -->
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 10"> <!-- 잔금요청 => 처리필요 -->
              <div class="pt-2 pl-2 d-flex" style="background:#311B92;font-size:12px">
                <div style="color:#ffffff">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#311B92;font-size:12px">
                <v-icon class="ml-2 mb-1" color="white" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#ffffff">{{item.PURCHASE_STATENAME}}</div>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 11"> <!-- 잔금입금 -->
              <div class="pt-2 pl-2 d-flex" style="background:#FFDE03;font-size:12px">
                <div style="color:#000000">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#FFDE03;font-size:12px">
                <v-icon class="ml-2 mb-1" color="black" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-2" style="color:#000000">{{item.PURCHASE_STATENAME}}</div>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 20"> <!-- 제품전달 => 처리필요 -->
              <div class="pt-2 pl-2 d-flex" style="background:#311B92;font-size:12px">
                <div style="color:#ffffff">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:#311B92;font-size:12px">
                <v-icon class="ml-2 mb-1" color="white" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="ml-2" style="color:#ffffff">{{item.PURCHASE_STATENAME}}</div>
                <v-icon class="mb-1 ml-1 mr-auto" small color="white" @click="loadtransper(item)">mdi-pencil</v-icon>
              </div>
              <div class="gray_line"/>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 25"> <!-- 거래완료 -->
              <div class="pt-2 pl-2 d-flex" style="background:green;font-size:12px">
                <div style="color:#ffffff">{{ 'Order No. '+item.PURCHASE_IDX }}</div>
              </div>
              <div class="pb-1 d-flex" style="background:green;font-size:12px">
                <v-icon class="ml-2 mb-1" color="white" small>mdi-information</v-icon>
                <div @click="showmsg(item)" class="mr-auto ml-1" style="color:#FFFFFF">{{item.PURCHASE_STATENAME}}</div>
              </div>
              <div class="gray_line"/>
            </template>
          </div>

          <v-list-item>
            <v-list-item-avatar
              tile
              size="50"
              >
              <img style="border-radius:10px" :src="item.THUM_IMG1"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-card-text @click="loadnotice(item)" class="pa-0 text_2line" style="color:#1976d2;font-size:14px;line-height:14px;font-weight:bold" v-text="item.PRODUCT_NUMBER"></v-card-text>
              <v-card-text class="pa-0" style="line-height:14px;font-size:13px;color:black" v-text="'$ ' + item.PRICE_DP" ></v-card-text>
              <v-card-text class="pa-0" style="line-height:14px;font-size:13px;color:black" v-text="'Q ' + item.QUANTITY" ></v-card-text>
              <v-card-text class="pa-0" style="line-height:13px;font-size:12px" v-text="item.REG_DATE"></v-card-text>
            </v-list-item-content>
          </v-list-item>          
          <v-row>
            <v-col cols="12" class="ma-0 pb-0">
              <template v-if="item.PURCHASE_STATE == 2">
                <v-btn icon height="33"
                  class="black_btn_noheight text-caption" 
                  small @click="edit_item(item)"><v-icon class="nextstep_textcolorani" small>mdi-bell-check</v-icon>
                  <div style="margin-top:3px">Down Payment 계약금처리</div>
                </v-btn>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 10">
                <v-btn icon
                  class="black_btn_noheight text-caption" small @click="edit_item(item)"><v-icon class="nextstep_textcolorani" small>mdi-bell-check</v-icon>
                  <div style="margin-top:3px">Balance Payment 잔금처리</div>
                </v-btn>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 20">
                <v-btn icon
                  class="black_btn_noheight text-caption" small @click="edit_item(item)"><v-icon class="nextstep_textcolorani" small>mdi-bell-check</v-icon>
                  <div style="margin-top:3px">confirmation 수령확인</div>
                </v-btn>
              </template>
            </v-col>        
          </v-row>
        </v-card>
      </v-col>
      
      <!-- PC해상도 -->
      <v-col cols="12" class="hidden-sm-and-down">
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
            
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
          <template v-slot:header.PURCHASE_IDX>
            <a :class="sort_name=='PURCHASE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PURCHASE_IDX')">No.<br>주문번호</a>
          </template>
          <template v-slot:header.THUM_IMG1>
            <label class="cheader_normal">Image<br>이미지</label>
          </template>
          <template v-slot:header.PRODUCT_NUMBER>
            <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
          </template>
          <template v-slot:header.QUANTITY>
            <label class="cheader_normal">Quantity<br>수량</label>
          </template>
          <template v-slot:header.PRICE_DP_USD>
            <label class="cheader_normal">Buy Price<br>구매가격</label>
          </template>
          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal">State<br>상태</label>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>등록일시</a>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">processing<br>처리</label>
          </template>
          <template v-slot:header.cancel>
            <label class="cheader_normal">Cancel<br>취소요청</label>
          </template>

          <template v-slot:item.PURCHASE_IDX="{ item }">  
            <a @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{item.PURCHASE_IDX}}</a>
          </template>
          <template v-slot:item.PRODUCT_NUMBER="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
          </template>

          <template v-slot:item.THUM_IMG1="{ item }">  
              <v-col class="pa-0" align="center">
                <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                  <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px;margin-top:5px;margin-bottom:5px" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                </template>
              </v-col>
          </template>

          <template v-slot:item.PRICE_DP_USD="{ item }">  
            <div>{{ item.PRICE_TYPE == 0 ? '$ '+item.PRICE_DP + ' USD' : '₩ '+item.PRICE_WON_DP +' 원' }}</div>
          </template>

          <template v-slot:item.PURCHASE_STATENAME="{ item }">  

            <template v-if="item.CANCEL_CONFIRM == 1"> <!-- 거래취소 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:red;color:white;padding-top:5px;padding-bottom:5px">
                    <a @click="loadcancelreq(item)">
                      <span style="color:white">{{item.PURCHASE_STATENAME}}<v-icon class="ml-1" small color="white">mdi-pencil</v-icon></span>
                    </a>
                  </div>
                </template>
                <span>It's a canceled transaction.<br>취소된 거래입니다.</span>
              </v-tooltip>              
            </template>
            <template v-else-if="item.CANCEL_CONFIRM == 0"> <!-- 취소요청 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                    <a @click="loadcancelreq(item)">
                      <span style="color:black">{{item.PURCHASE_STATENAME}}<v-icon class="ml-1" small color="black">mdi-pencil</v-icon></span>
                    </a>
                  </div>
                </template>
                <span>requested to cancel the transaction. Cancellation is completed after administrator approval. Cancellation requests may be rejected.<br>거래취소를 요청한 상태입니다. 관리자 승인후 취소완료됩니다. 취소요청은 거부될 수 있습니다.</span>
              </v-tooltip>              
            </template>
            <template v-else-if="item.PURCHASE_STATE == 1"> <!-- 구매요청 > 관리자확인대기 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>The manager is checking the purchase request.<br>구매요청 내용을 관리자가 확인중입니다.</span>
              </v-tooltip>              
            </template>
            <template v-else-if="item.PURCHASE_STATE == 2"> <!-- 계약금요청 => 입금처리필요 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#311B92;color:white;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>Please enter down payment information.<br>계약금 입금정보를 입력해주세요</span>
              </v-tooltip>              
            </template>
            <template v-else-if="item.PURCHASE_STATE == 4"> <!-- 계약금입금 > 관리자확인대기 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                  <a @click="loaddownlay(item)">
                    <span style="color:black">{{item.PURCHASE_STATENAME}}<v-icon class="ml-1" small color="black">mdi-pencil</v-icon></span>
                  </a>
                  </div>
                </template>
                <span>The manager is checking the deposit details.<br>계약금 입금내역을 관리자가 확인중에 있습니다.</span>
              </v-tooltip>              
            </template>

            <template v-else-if="item.PURCHASE_STATE == 6"> <!-- 발송요청 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>Preparing product.<br>제품을 준비중입니다.</span>
              </v-tooltip>                            
            </template>
            <template v-else-if="item.PURCHASE_STATE == 8"> <!-- 발송 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>Preparing product<br>제품을 준비중입니다.</span>
              </v-tooltip>                            
            </template>

            <template v-else-if="item.PURCHASE_STATE == 10"> <!-- 잔금요청 => 처리필요 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#311B92;color:white;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>Please deposit the balance and click to enter the balance deposit information.<br>잔금을 입금하신 후 클릭하여 잔금 입금정보를 입력해주세요.</span>
              </v-tooltip>                            
            </template>
            <template v-else-if="item.PURCHASE_STATE == 11"> <!-- 잔금입금 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#FFDE03;color:black;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>The manager is checking the balance deposit details.<br>잔금 입금내역을 관리자가 확인중에 있습니다.</span>
              </v-tooltip>              
            </template>
            <template v-else-if="item.PURCHASE_STATE == 20"> <!-- 제품전달 => 처리필요 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:#311B92;color:white;padding-top:5px;padding-bottom:5px">
                    <a @click="loadtransper(item)">
                      <span style="color:white">{{item.PURCHASE_STATENAME}}<v-icon class="ml-1" small color="white">mdi-pencil</v-icon></span>
                    </a>
                  </div>
                </template>
                <span>If you have received the product safely, please click to proceed with the transaction completion process<br>상품을 안전하게 수령하셨다면 클릭하셔서 거래완료 처리를 진행해주세요</span>
              </v-tooltip>                            
            </template>
            <template v-else-if="item.PURCHASE_STATE == 25"> <!-- 거래완료 -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" style="background:green;color:white;padding-top:5px;padding-bottom:5px">
                    {{item.PURCHASE_STATENAME}}
                  </div>
                </template>
                <span>completed transaction<br>완료된 거래</span>
              </v-tooltip>              
            </template>
          </template>

          <template v-slot:item.actions="{ item }">  
              <template v-if="item.CANCEL_CONFIRM == 1">
              </template>
              <template v-else-if="item.CANCEL_CONFIRM == 0">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 1">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 2">
                <v-icon class="nextstep_textcolorani" small @click="edit_item(item)">mdi-bell-check</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 4">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 6">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 8">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 10">
                <v-icon class="nextstep_textcolorani" small @click="edit_item(item)">mdi-bell-check</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 11">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 20">
                <v-icon class="nextstep_textcolorani" small @click="edit_item(item)">mdi-bell-check</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 25">
              </template>
          </template>  

          <template v-slot:item.cancel="{ item }">  
              <template v-if="item.CANCEL_CONFIRM == 1">
              </template>
              <template v-else-if="item.CANCEL_CONFIRM == 0">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 1">
                <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 2">
                <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 4">
                <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
              </template>
              <template v-else-if="item.PURCHASE_STATE == 6">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 8">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 10">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 11">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 20">
              </template>
              <template v-else-if="item.PURCHASE_STATE == 25">
              </template>
          </template>  

        </v-data-table>
      </v-col>
    </v-row>


    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>
<script>
import cmvalidation from '../assets/js/cmvalidation.js'
import Vue from 'vue'
export default {

    mounted() {

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      BANKNUMBER:'',

      SEARCHSTATE : -1,
      SEARCHSTATELIST:[
          {name:'전체', value: -1}
        , {name:'구매요청', value: 1}
        , {name:'계약금 요청', value: 2}
        , {name:'계약금 입금', value: 4}
        //, {name:'발송요청', value: 6}
        //, {name:'발송', value: 8}
        , {name:'잔금요청', value: 10}
        , {name:'잔금입금', value: 11}
        , {name:'상품전달', value: 20}
        , {name:'취소요청', value: 50}
        , {name:'거래완료', value: 25}
        , {name:'거래취소', value: 0}
      ],

      //취소요청 정보
      cancelreqItem:{},
      cancelreq_dialog:false,
      CONFIRMLIST:[
          {name:'Request Cancel/취소요청',   value:0}
        , {name:'Complete Cancel/취소승인',  value:1}
        , {name:'Reject Cancel/ 취소거부',   value:2}
      ],

      downpay_dialog:false,
      downpayItem:{
        PRICE_TYPE:1,
        PRICE_WON:0,
        PRICW_USD:0,
        NAME:"",
      },
      PRICETYPELIST: [
          {name:'달러',     value:0}
        , {name:'원화',     value:1}
      ],

      balpay_dialog:false,
      balpayItem:{
        PRICE_TYPE:1,
        PRICE_WON:0,
        PRICW_USD:0,
        NAME:"",
      },

      sned_dialog:false,
      sendItem:{},
      SHIPTYPELIST: [
          {name:'Direct delivery from KDGE./본사직접배송',          value:0}
        , {name:'Delivery service./택배',                          value:1}
        , {name:'Visit a designate retailer to receive the products./지정소매상',  value:2}
        , {name:'Visit KDGE to receive the products./본사방문수령',                value:3}
      ],

      complete_dialog:false,
      next_dialog:false,
      cancel_dialog:false,
      editedItem: {
      },

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '주문번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'250px', class:"cheader_normal"},
        {text: '수량', value: 'QUANTITY', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '구매가격', value: 'PRICE_DP_USD', sortable: false, align:'right', width:'150px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center', width:'250px', class:"cheader_normal"},
        {text: '처리', value: 'actions', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'center' , width:'180px', class:"cheader_normal"},
        {text: '취소요청', value: 'cancel', sortable: false, align:'center', width:'60px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

    }),

    created(){

    },

    methods: {

      initpage(){
        this.getbanknumber();
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      
      
      getbanknumber(){
        
        this.$http.post(this.$host+'/getBankNumber',{
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            this.BANKNUMBER = result.data.banknumber;
          }
        })
        .catch((error)=>{
          //console.log(error);
        });
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      
      //취소요청정보
      loadcancelreq(item){

        //계약금 입금 정보 가져오기
        this.$http.post(this.$host+'/AdminCancelReqDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "Request information is missing.<br><br>요청정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.cancelreqItem = result.data.resultData[0];
                this.cancelreq_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.cancelreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/UserTradeList", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/UserTradingList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , SEARCH_STATE: this.SEARCHSTATE
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },      


      //계약금 입금
      next_senddownpay(){
       const checkname = cmvalidation.checkValReg(this.downpayItem.NAME, null, -1, 50);
       if(checkname == 1){
          this.$fire({
            html: "Please enter the name of the depositor<br><br>입금자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }else if(checkname == 4){
          this.$fire({
            html: "Please enter the name of the depositor in up to 50 characters<br><br>입금자 이름은 50자 이내로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }


        if(this.downpayItem.INPUTPAYMENT_IDX > 0){
          //업데이트
          this.$http.post(this.$host+'/UserDownPayUpdate', {
              INPUTPAYMENT_IDX: this.downpayItem.INPUTPAYMENT_IDX
            , NAME: this.downpayItem.NAME == null || this.downpayItem.NAME == undefined ? '' : escape(this.downpayItem.NAME)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.downpay_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.downpay_dialog = false;
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          });

        }else{

          this.$http.post(this.$host+'/UserSendDownPay', {
              PRODUCT_IDX: this.editedItem.PRODUCT_IDX
            , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
            , ACCNT: this.editedItem.ACCNT
            , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            , NAME: this.downpayItem.NAME == null || this.downpayItem.NAME == undefined ? '' : escape(this.downpayItem.NAME)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.downpay_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Request failed<br><br>요청 실패 "+result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.search_run();
                }
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.downpay_dialog = false;
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          });

        }

      },



      //잔금 입금
      next_sendbalpay(){

       const checkname = cmvalidation.checkValReg(this.balpayItem.NAME, null, -1, 50);
       if(checkname == 1){
          this.$fire({
            html: "Please enter the name of the depositor<br><br>입금자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }else if(checkname == 4){
          this.$fire({
            html: "Please enter the name of the depositor in up to 50 characters<br><br>입금자 이름은 50자 이내로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }

        if(this.balpayItem.INPUTPAYMENT_IDX > 0){
          //업데이트
          this.$http.post(this.$host+'/UserBalPayUpdate', {
              INPUTPAYMENT_IDX: this.balpayItem.INPUTPAYMENT_IDX
            , NAME: this.balpayItem.NAME == null || this.balpayItem.NAME == undefined ? '' : escape(this.balpayItem.NAME)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.balpay_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.balpay_dialog = false;
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          });

        }else{

          this.$http.post(this.$host+'/UserSendBalPay', {
              PRODUCT_IDX: this.editedItem.PRODUCT_IDX
            , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
            , ACCNT: this.editedItem.ACCNT
            , ACCNT_IDX: this.SEARCH_ACCNT_IDX
            , NAME: this.balpayItem.NAME == null || this.balpayItem.NAME == undefined ? '' : escape(this.balpayItem.NAME)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.balpay_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Request failed<br><br>요청 실패 " + result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.balpay_dialog = false;
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          });

        }

      },


      //제품전달정보 가져오기
      loadtransper(item){

        this.$http.post(this.$host+'/AdminTransperDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no information.<br><br>정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.sendItem = result.data.resultData[0];
                this.sned_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.sned_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      //제품전달정보 가져오기
      next_complete(){

        this.$http.post(this.$host+'/UserTrandeComplete', {
            PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , ACCNT: this.editedItem.ACCNT
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.complete_dialog = false;
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The transaction has been completed.<br><br>거래가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();
              
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패 " + result.data.resultMsg,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
            });
            this.search_run();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.complete_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      cancel_save(){
        //거래 취소요청
        this.$http.post(this.$host+'/UserCancelReq', {
            PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , ACCNT: this.editedItem.ACCNT
          , COMMENT: this.editedItem.COMMENT
          , ACCNT_IDX: this.SEARCH_ACCNT_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.cancel_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Cancellation request completed.<br><br>취소요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>"+result.data.resultMsg,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.cancel_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },


      //계약금 입금정보 읽어오기
      loaddownlay(item){
        this.$http.post(this.$host+'/UserDownPayDetail',{
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.downpayItem = result.data.resultData[0];
              this.downpay_dialog = true;
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },


      //아이템 수정
      edit_item(item){
        
        this.editedItem = Object.assign({}, item);
        if(item.CANCEL_CONFIRM == 0){
        


        }else if(item.CANCEL_CONFIRM == 1){
        


        }else if(item.PURCHASE_STATE == 1){
          this.$fire({
            html: "You have requested a purchase. Please wait for approval.<br><br>상품구매를 요청했습니다. 승인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 2){
          
          /* 비추
          Vue.IMP().request_pay({
              pg: 'daou', //설정안하면 기본 pg 적용됨
              pay_method: 'vbank',
              name: '테스트 결제',
              merchant_uid: 'mid_12345',
              amount: 10000,
              buyer_email: 'toritosystem@gmail.com',
              buyer_name: '홍길동',
              buyer_tel: '010-2105-5427',
              m_redirect_url: 'http://localhost:8081/UserTradeList',  //모바일 결제 완료후 리다이렉션 URL
              //buyer_addr: '서울특별시 강남구 삼성동',
              //buyer_postcode: '123-456'
          }, (result_success) => {
              console.log("result_success : " + JSON.stringify(result_success));
          }, (result_failure) => {
              console.log("result_failure : " + JSON.stringify(result_failure));
            return;
          });
          */
          

          //페이조아 주의사항 : PC 결제는 팝업, 모바일 결제는 리디렉션 모드로 제공
          /*
          this.$iamport.request_pay({
              pg: 'daou',
              pay_method: 'vbank',
              merchant_uid: 'mid_12345',
              name: '테스트 결제',
              amount: 10000,
              buyer_email: 'toritosystem@gmail.com',
              buyer_name: '홍길동',
              buyer_tel: '010-2105-5427',
              m_redirect_url: 'http://localhost:8081/UserTradeList',  //모바일 결제 완료후 리다이렉션 URL
              //buyer_addr: '서울특별시 강남구 삼성동',
              //buyer_postcode: '123-456',
              //m_redirect_url: 'https://allerts.com/payments/complete',  //모바일 결제 완료후 리다이렉션 URL
              bypass: {
                // 페이조아(다우데이타) 전용 파라미터
                daou: {
                  PRODUCTCODE: 'iamport',
                  CASHRECEIPTFLAG: 1,
                },
              },
          }, (rsp) => {
              console.log("result : " + JSON.stringify(rsp));
              if(rsp.success || rsp.imp_success){
                //결제 성공

              }else{
                //결제 실패
                this.$fire({
                  html: rsp.error_msg,
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          });
          */


          //계약금 입금 정보 입력
          this.downpayItem.INPUTPAYMENT_IDX = -1;
          //this.downpayItem.NAME = item.BNAME;
          this.downpayItem.PRICE_TYPE = item.PRICE_TYPE;
          this.downpayItem.PRICE_USD = item.PRICE_DP_USD_DOWNPAY;
          this.downpayItem.PRICE_WON = item.PRICE_DP_WON_DOWNPAY;
          this.downpay_dialog = true;
          return;



        }else if(item.PURCHASE_STATE == 4){
          this.$fire({
            html: "checking the deposit.<br>Please wait for confirmation.<br><br>계약금입금을 확인중입니다.<br>확인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        
        }else if(item.PURCHASE_STATE == 6){
          this.$fire({
            html: "Preparing product.<br><br>제품을 준비중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        
        }else if(item.PURCHASE_STATE == 8){
          this.$fire({
            html: "Preparing product.<br><br>제품을 준비중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;

        }else if(item.PURCHASE_STATE == 10){
          //잔금 입금 정보 입력
          this.balpayItem.INPUTPAYMENT_IDX = -1;
          //this.balpayItem.NAME        = item.BNAME;
          this.balpayItem.PRICE_TYPE  = item.PRICE_TYPE;
          this.balpayItem.PRICE_USD   = item.PRICE_DP_USD_BALPAY;
          this.balpayItem.PRICE_WON   = item.PRICE_DP_WON_BALPAY;
          this.balpay_dialog = true;
          return;
        }else if(item.PURCHASE_STATE == 11){
          this.$fire({
            html: "checking the balance deposit details.<br>Please wait for confirmation.<br><br>잔금입금을 확인중입니다.<br>확인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;

        }else if(item.PURCHASE_STATE == 20){
          this.complete_dialog = true;
          return;

        }




      },


      showmsg(item){

        if(item.CANCEL_CONFIRM == 1){
          this.$fire({
            html: "This transaction has been canceled.<br><br>취소가 완료된 거래입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.CANCEL_CONFIRM == 0){
          this.$fire({
            html: "requested to cancel the transaction.<br>Cancellation is completed after administrator approval.<br>Cancellation requests may be rejected.<br><br>거래취소를 요청한 상태이며 관리자 승인후 취소완료됩니다.<br>취소요청은 거부될 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 1){
          this.$fire({
            html: "You have requested a purchase. Please wait for approval.<br><br>상품구매를 요청했습니다. 승인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 2){
          this.$fire({
            html: "Please enter down payment information.<br>계약금 입금정보를 입력해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 4){
          this.$fire({
            html: "checking the deposit.<br>Please wait for confirmation.<br><br>계약금입금을 확인중입니다.<br>확인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        
        }else if(item.PURCHASE_STATE == 6){
          this.$fire({
            html: "Preparing product.<br><br>제품을 준비중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        
        }else if(item.PURCHASE_STATE == 8){
          this.$fire({
            html: "Preparing product.<br><br>제품을 준비중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;

        }else if(item.PURCHASE_STATE == 10){
          this.$fire({
            html: "Please deposit the balance and click to enter the balance deposit information.<br><br>잔금을 입금하신 후 클릭하여 잔금 입금정보를 입력해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 11){
          this.$fire({
            html: "checking the balance deposit details.<br>Please wait for confirmation.<br><br>잔금입금을 확인중입니다.<br>확인까지 기다려주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 20){
          this.$fire({
            html: "If you have received the product safely, please click to proceed with the transaction completion process.<br><br>상품을 안전하게 수령하셨다면 클릭하셔서 거래완료 처리를 진행해주세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;

        }else if(item.PURCHASE_STATE == 25){
          this.$fire({
            html: "Completed Transaction.<br><br>완료된 거래입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

      },



      //아이템 삭제
      delete_item(item){
        this.editedItem = Object.assign({}, item);
        this.cancel_dialog = true;
      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      

    },



}
</script>

<style scoped>

@keyframes bgcolorframe {
  50%  {background-color: #ff000080; }
  100% {background-color: #ffff0080; }
}
.nextstep_bgcolorani{
  animation-name: bgcolorframe;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes textcolorframe {
  50%  { color: red;}
  100% {color:black; }
}
.nextstep_textcolorani{
  animation-name: textcolorframe;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}


.text_2line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

</style>