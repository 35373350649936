<template>

  <v-container fluid class="container_bg">
   
    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-5 ma-0">
                  <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">{{ editedItem.SHAPE_TYPE == 0 ? 'ROUND' :  'FANCY' }}{{'/' + editedItem.MINCT + "~" + editedItem.MAXCT + "/"+editedItem.COLOR_NAME }}</span></p>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">FL</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.FL"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">IF</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.IF"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">VVS1</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.VVS1"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">VVS2</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.VVS2"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">VS1</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.VS1"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">VS2</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.VS2"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">S1</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.SI1"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">S2</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.SI2"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">S3</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.SI3"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">I1</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.I1"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">I2</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.I2"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="4" md="2" align="start">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">I3</span></p>
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.I3"
                      :height="$vuetify.breakpoint.smAndDown ? 25 : 25"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>



    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="2">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" class="pl-1 pr-1 pa-1">
        <v-select 
          v-model="SEARCH_SHAPE_TYPE" :items="SEARCHSHAPETYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" label="Shape"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="loadpage(1)"
          />
      </v-col>
      <v-col cols="6" md="1" class="pl-1 pr-1 pa-1">
        <v-select 
          v-model="SEARCH_COLOR_TYPE" :items="SEARCHCOLORTYPELIST" item-color="black"
          placeholder="구분" dense item-text="name" item-value="value" label="Color"
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="loadpage(1)"
          />
      </v-col>
      <v-col cols="6" md="1" class="pl-1 pr-1 pa-1">
        <v-text-field
          v-model="SEARCH_MIN_CT" outlined dense hide-details label="minct"
          />
      </v-col>
      <v-col cols="6" md="1" class="pl-1 pr-1 pa-1">
        <v-text-field
          v-model="SEARCH_MAX_CT" outlined dense hide-details label="maxct"
          />
      </v-col>
      <v-col cols="6" md="1" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">Select<br>조회</v-btn>
      </v-col>        
    </v-row>

    
  <v-row align="start" class="mt-5" dense>
    <v-col>
      <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          item-key="IDX"
          :loading="loading"
          :items-per-page="9999"
          mobile-breakpoint="0"
          >

          <template v-slot:item.SHAPE_TYPE="{ item }">  
            <label>{{ item.SHAPE_TYPE == 0 ? 'ROUND' :  'FANCY' }}</label><br>
          </template>     

          <template v-slot:item.actions="{ item }">  
              <v-icon small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  

        </v-data-table>

    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    data: () => ({

      //필수 페이징 파라메터
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          SHAPE_NAME:'',
          SHAPE_TYPE:0,
          SHAPE_STATE:1,
          SORT: 1,
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: 'SHAPE',   value: 'SHAPE_TYPE', width:60, sortable: false, align:'center'},
        {text: 'MIN',     value: 'MINCT', width:60,sortable: false, align:'center' },
        {text: 'MAX',     value: 'MAXCT', width:60,sortable: false, align:'center' },
        {text: 'COLOR',   value: 'COLOR_NAME', width:40,sortable: false, align:'center'},
        {text: 'FL',      value: 'FL', width:70,sortable: false, align:'center'},
        {text: 'IF',      value: 'IF', width:70,sortable: false, align:'center'},
        {text: 'VVS1',    value: 'VVS1', width:70,sortable: false, align:'center'},
        {text: 'VVS2',    value: 'VVS2', width:70,sortable: false, align:'center'},
        {text: 'VS1',     value: 'VS1', width:70,sortable: false, align:'center'},
        {text: 'VS2',     value: 'VS2', width:70,sortable: false, align:'center'},
        {text: 'SI1',     value: 'SI1', width:70,sortable: false, align:'center'},
        {text: 'SI2',     value: 'SI2', width:70,sortable: false, align:'center'},
        {text: 'SI3',     value: 'SI3', width:70,sortable: false, align:'center'},
        {text: 'I1',      value: 'I1', width:70,sortable: false, align:'center'},
        {text: 'I2',      value: 'I2', width:70,sortable: false, align:'center'},
        {text: 'I3',      value: 'I3', width:70,sortable: false, align:'center'},
        {text: 'EDIT', value: 'actions', width:70,sortable: false, align:'center'}
      ],
      itemList: [],

      SEARCH_SHAPE_TYPE: 0,
      SEARCHSHAPETYPELIST: [
          {name:'Round Cut',   value:0},
          {name:'Fancy Cut',   value:1}
      ],
      
      SEARCH_COLOR_TYPE: -1,
      SEARCHCOLORTYPELIST: [
          {name:'전체',   value:-1},
          {name:'D',   value:0},
          {name:'E',   value:1},
          {name:'F',   value:2},
          {name:'G',   value:3},
          {name:'H',   value:4},
          {name:'I',   value:5},
          {name:'J',   value:6},
          {name:'K',   value:7},
          {name:'L',   value:8},
          {name:'M',   value:9},
          {name:'N',   value:10},
      ],

      SEARCH_MIN_CT: 0,
      SEARCH_MAX_CT: 0,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },


    methods: {

      loadpage(){
        
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화

        this.loading = true;

          this.$http.post(this.$host+'/RapaList',{
              SHAPE_TYPE: this.SEARCH_SHAPE_TYPE
            , COLOR_TYPE: this.SEARCH_COLOR_TYPE
            , MINCT: this.SEARCH_MIN_CT
            , MAXCT: this.SEARCH_MAX_CT
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              const list = result.data.resultData;
              list.forEach(element => {
                  this.itemList.push(element); 
              });
              this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      //추가 저장
      async save () {
        const numberVal = /[^0-9]/g;
        const names = ['FL', 'IF', 'VVS1', 'VVS2', 'VS1', 'VS2', 'SI1', 'SI2', 'SI3', 'I1', 'I2', 'I3'];
        for(var i=0;i<names.length;i++){
          const check = cmvalidation.checkValReg(this.editedItem[names[i]], numberVal, 1, 8);
          if(check == 1){
            this.$fire({
              html: "Please enter a "+names[i]+"<br><br>"+names[i]+"값을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 2){
            this.$fire({
              html: "You can enter only numbers in the "+names[i]+".<br><br>"+names[i]+"값은 숫자만 입력이 가능합니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }else if(check == 3){
            this.$fire({
              html: "Please Enter "+names[i]+" in 1 characters or then<br><br>"+names[i]+"값은 최소 1자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(check == 4){
            this.$fire({
              html: "Please Enter "+names[i]+" in 8 characters or less<br><br>"+names[i]+"값은 8자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

        }

        //수정
        this.loading = true;
        this.$http.post(this.$host+'/RapaUpdate',{
              FL: this.editedItem.FL
            , IF: this.editedItem.IF
            , VVS1: this.editedItem.VVS1
            , VVS2: this.editedItem.VVS2
            , VS1: this.editedItem.VS1
            , VS2: this.editedItem.VS2
            , SI1: this.editedItem.SI1
            , SI2: this.editedItem.SI2
            , SI3: this.editedItem.SI3
            , I1: this.editedItem.I1
            , I2: this.editedItem.I2
            , I3: this.editedItem.I3
            , RAPA_IDX: this.editedItem.RAPA_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            this.$fire({
              html: "The modification is complete.<br><br>수정이 완료되었습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.loadpage();
              }
            });

          }else if(result.data.resultCode == 2){
            this.loading = false;

            //로그인 필요
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });

          }else{
            this.loading = false;

            this.$fire({
              html: "Modification failed.<br><br>수정이 실패했습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$fire({
              html: "Modification failed.<br><br>수정이 실패했습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
        });
        });

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage();
      },
      
      //신규 추가
      newinsert(){
        
        this.uploadFile1 = [];
        this.previewImg1 = "";

        this.dialogTitle = "Add 추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.SHAPE_TYPE = this.SEARCH_PRODUCT_CLASS;
        this.editedItem.SORT = this.itemList.length+1;
        this.dialog = true;

        window.scrollTo(0,0);
      },

      //아이템 수정
      edit_item(item){

        this.uploadFile1 = [];
        this.previewImg1 = "";

        this.dialogTitle = "Edit 수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);

        this.previewImg1 = this.editedItem.SHAPE_IMG != null && this.editedItem.SHAPE_IMG.length > 0 ? this.editedItem.SHAPE_IMG : "";

        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){
        this.$fire({
          html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.loading = true;
            this.$http.post(this.$host+'/ShapeDelete',{
                SHAPE_IDX:item.SHAPE_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage();
                  }
                });

                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });      

          }
        });

      },


      //이미지 등록 관련
      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile1 = [];
          return;
        }      

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },


      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }

          });

        });
      },
            
      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>