<template>

<v-container fluid class="ma-0 pa-0" style="background:white;">
  <div class="d-flex justify-end" style="position:fixed;width:100%;height:40px;background:black;left:0px;padding-top:4px">
    <v-btn @click="close" icon><v-icon color="white">mdi-close</v-icon></v-btn>
  </div>

  <v-row align="center"  justify="center">
    <WebViewer 
      ref="webviewer"
      style="margin-top:40px;background:white"
      :path="`${publicPath}lib`" :data="pdfcontent" :bb="pdfbuffer" />
  </v-row>

</v-container>

</template>

<script>
//PDFTRON WEBDRIVER로 작업 
//vue 2.6 호환을 위해 public폴더 내부에 lib에 라이브러리 소스가 있고 path로 지정해야함.
export default {

  data(){
    return{
        publicPath: process.env.BASE_URL
      , pdfcontent:null
      , pdfbuffer:null
      , pdfurl:''
    }
  },

  created(){
    this.pdfurl = this.$route.query.url;
    this.geturl(this.pdfurl);
  },



  methods: {

    close(){
      this.$router.back();
    },

    geturl(url){
      this.getPdf(url, 1);
    },

    async getPdf(pdfdata, type) {
      
      this.pdf_dialog = true;

      if(type == 1){
        var pdfurl = "";
        if(String(pdfdata).startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){
          //우리측 PDF면 바로 로드
          pdfurl = pdfdata;
        }else{
          //다른 업체 PDF면 CORS우회
          pdfurl = this.$host+'/GuestPdfInfo?path='+pdfdata;
        }
        //const path = `${process.env.BASE_URL}webviewer`;
        this.pdfcontent = pdfurl;
      }else{
        //파일
        //console.log("pdfdata1  : " + pdfdata);
        this.pdfbuffer = pdfdata;
        //this.$refs.webviewer.loadfile();
      }

    }    
  }
};
</script>

<style>


</style>