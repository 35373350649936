<!-- 업체 선택용 공통 팝업 -->
<template>
    
  <v-row align="start">
    <v-col cols="12" class="d-flex justify-center">
      <v-btn v-if="bottompage > pagelimit"  icon  small @click="prevrun()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
      <span v-for="n in subpage" :key="n.id">
          <span v-if="page == bottompage-1+n">
              <v-btn color="red" class="numberstyle" icon small @click="loadpagerun(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
          <span v-else>
              <v-btn  icon small class="numberstyle2" @click="loadpagerun(bottompage-1+n)">{{bottompage-1+n}}</v-btn>
          </span>
      </span>
      <v-btn v-if="bottompage+pagelimit <= maxpage"  icon small @click="nextrun()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
    </v-col>
  </v-row>

</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
        },

        watch:{
          // eslint-disable-next-line no-unused-vars
          totalcnt:function(v1, v2){
            this.totalcnt = v1;
          },
        },

        data: ()=> ({
          //필수 페이징 파라메터
          pagelimit:10,
          page : 1,
          maxpage : 0,        //전체 페이지 개수
          startpage : 1,      //시작 인덱스
          bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
          subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
        }),

        methods: {

          makepaging(totalcnt, pagerow){

            var div1 = totalcnt / pagerow; 
            var div2 = totalcnt % pagerow;
            if(div2 == 0){  
                this.maxpage = parseInt(div1);    
            }else{
                this.maxpage = parseInt(div1+1);
            }
            //console.log("makepaging : " + totalcnt + " / " + pagerow +" / " + this.page + " / " + this.maxpage);

            //이미 page값은 있는 상태
            this.bottompage = (parseInt((this.page-1)/this.pagelimit) * this.pagelimit)+1;
            //console.log("bottompage : " + this.bottompage);
            
            this.subpage = this.maxpage - this.bottompage >= this.pagelimit ? this.pagelimit : this.maxpage - this.bottompage+1;
            //console.log("maxpage : " + this.maxpage + " / subpage " + this.subpage + " / bottompage " + this.bottompage + " / limit : " + this.pagelimit);

          },

          //외부에서 현재 페이지 번호 동기화 처리 - 현재 페이지 번호 구분하기 위함
          setpage(pagenum){
            this.page = pagenum;
          },

          loadpagerun(pagenum){
            this.$emit('loadpage', pagenum);
          },
          
          prevrun(){
            this.bottompage = this.bottompage - this.pagelimit >= 1 ? this.bottompage - this.pagelimit : 1;
            this.subpage = this.maxpage - this.bottompage >= this.pagelimit ? this.pagelimit : this.maxpage - this.bottompage+1;
            var temp_page = this.bottompage + this.subpage - 1; //이전을 누르면 가장 끝 번호 선택
            this.$emit('loadpage', temp_page);
          },

          nextrun(){
            this.bottompage = this.bottompage + this.pagelimit <= this.maxpage ? this.bottompage + this.pagelimit : this.maxpage;
            this.subpage = this.maxpage - this.bottompage >= this.pagelimit ? this.pagelimit : this.maxpage - this.bottompage+1;
            var temp_page = this.bottompage;
            this.$emit('loadpage', temp_page);
          },

        }

    }
</script>

<style scoped>


</style>