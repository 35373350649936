<template>

  <!-- 공급자 거래완료 리스트 -->
  <v-container fluid class="container_bg">

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="3" class="pa-0 pl-1 pl-md-0 pr-1 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>

    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-5 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
          <template v-slot:header.PURCHASE_IDX>
            <a :class="sort_name=='PURCHASE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PURCHASE_IDX')">No.<br>번호</a>
          </template>

          <template v-slot:item.THUM_IMG1="{ item }">  
              <v-col class="pa-0" align="center">
                <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                  <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px;margin-top:5px;margin-bottom:5px" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                </template>
              </v-col>
          </template>

          <template v-slot:header.PRODUCT_NUMBER>
            <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
          </template>
          <template v-slot:header.PROV_NAME>
            <label class="cheader_normal">Vendor<br>공급처</label>
          </template>
          <template v-slot:header.QUANTITY>
            <label class="cheader_normal">Quantity<br>수량</label>
          </template>
          <template v-slot:header.SUPPLY_PRICE_DP>
            <label class="cheader_normal">Supply Price<br>공급가</label>
          </template>
          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal">State<br>상태</label>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Complete Date<br>완료일시</a>
          </template>
          <template v-slot:header.NAME>
            <label class="cheader_normal">Buyer<br>구매자</label>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">Info<br>거래상세</label>
          </template>
          
          <template v-slot:item.PRODUCT_NUMBER="{ item }">  
            <template v-if="grade > 90">
              <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
            </template>
            <template v-else>
              <label>{{item.PRODUCT_NUMBER}}</label>
            </template>
          </template>



        </v-data-table>
      </v-col>
    </v-row>

    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>


<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
export default {

    components:{
      VueCropper,
    },

    mounted() {

      if( this.$session.get("GRADE") < 5 || (this.$session.get("GRADE") == 5 && this.$session.get("SUB_DAYS") < 0) ){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      
      editedItem: {
      },

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        //{text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '수량', value: 'QUANTITY', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '가격', value: 'SUPPLY_PRICE_DP', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '완료일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        //{text: '구매자', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

    }),

    created(){

    },

    methods: {

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }
        
        //첫페이지 로드
        this.loadpage(this.page);

      },      

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/ProvTradeCom", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/ProvTradeComList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      

    },



}
</script>

<style scoped>
  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }

</style>