<template>
  <v-container fluid class="container_bg">
    
    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />

    <!-- 문의하기 팝업 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="qna_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Product inquiry &nbsp;상품문의</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Title&nbsp;<span class="dialog_subtitle_kor">제목</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.TITLE" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Questions&nbsp;<span class="dialog_subtitle_kor">문의사항</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="editedItem.DESC"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Answer&nbsp;<span class="dialog_subtitle_kor">답변</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black"
                        single-line v-model="editedItem.REPPLE"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="qna_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="inquiry_save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="4" class="pa-0 pl-3 pl-md-0 pr-2 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>

    </v-row>

  <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
    <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
  </v-row>


    <v-row align="start" class="mt-5 mt-md-0" dense>


      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel
          v-for="item in itemList"
          :key="item.INQUIRY_IDX"
        >
          <v-expansion-panel-header>
            <v-row align="center" class="pb-2 pt-0 pt-md-2">

              <v-col class="ma-0 pa-0 hidden-sm-and-down" cols="12">
                <label style=" font-size:12px;" class="inter_font">
                  {{ item.INQUIRY_IDX }}
                </label>&nbsp;&nbsp;
              </v-col>

              <v-col class="ma-0 pa-0 pb-2 mt-3 mb-1 hidden-md-and-up" cols="12" @click="temp">
                <label style=" font-size:12px;" class="inter_font" :class="item.INQUIRY_STATE == 0 ? 'point_bg_red ' : 'point_bg_green'">
                  {{ item.INQUIRY_STATE == 0 ? 'Awaiting answer 답변 대기중':'Answer 답변' }}
                </label>&nbsp;&nbsp;
                <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)">{{ item.NAME }}</a>
              </v-col>

              <v-col class="pa-0" cols="3" align="center">
                <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                  <v-img :src="item.THUM_IMG1" style="width:80px;hegith:80px" class="radius_10" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                </template>
              </v-col>
              <v-col cols="9">
                <v-row dense>
                  <v-col cols="12" md="8" >
                    <a class="text-md-body-2 text-caption" style="color:blue;font-weight:bold" @click="loadnotice(item)">{{item.PRODUCT_NUMBER}}</a>
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="4">
                    <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)">{{ item.NAME }}</a>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="8">
                    <a class="text-md-body-2 text-caption">{{item.TITLE}}</a>
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="4">
                    {{item.REG_DATE}}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="8">
                  </v-col>
                  <v-col class="hidden-sm-and-down" md="4">
                    <label :class="item.INQUIRY_STATE == 0 ? 'point_bg_red ' : 'point_bg_green'">{{ item.INQUIRY_STATE == 0 ? 'Awaiting answer 답변 대기중':'Answer 답변' }}</label>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-row dense align="center" justify="center">
              <v-col cols="12" md="10">
                <div class="gray_line"/>
              </v-col>
              <v-col cols="12" md="10">
                <div class="hidden-sm-and-down pa-0 pa-md-3 ma-0" style="white-space:pre-wrap;font-size:13px">{{ item.DESC }}</div>
                <div class="hidden-md-and-up pa-0 pa-md-3 ma-0" style="white-space:pre-wrap;font-size:11px">{{ item.DESC }}</div>
              </v-col>
              <v-col cols="12" md="10">
                <v-textarea v-model="item.REPPLE" class="hidden-sm-and-down" color="black" style="font-size:13px;" hide-details outlined dense/>
                <v-textarea v-model="item.REPPLE" class="hidden-md-and-up" color="black" style="font-size:11px;" hide-details outlined dense/>
              </v-col>
              <v-col cols="12" md="10">
                <v-row class="ma-0 pa-0" justify="end">
                  <v-col cols="6" md="2" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="delete_item(item)">Delete 삭제</v-btn>
                  </v-col>
                  <v-col cols="6" md="2" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="inquiry_save(item)">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>      

    </v-row>


    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>
<script>
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    mounted() {

      if(this.$session.get("GRADE") < 90){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    data: () => ({
      
      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      panel: [],

      qna_dialog: false,
      editedItem: {
      },

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      pagerow : 20,       //한 페이지에 보여줄 row수
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '제목', value: 'TITLE', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
        {text: '상태', value: 'INQUIRY_STATE', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '작성일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '작성자', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

    }),

    created(){

    },

    methods: {

      temp(){

      },

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }

        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      
      
      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminInquiry", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);

          this.$http.post(this.$host+'/InQuiryList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //아이템 삭제
      delete_item(item){


        this.$fire({
          html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/InQuiryDelete',{
                PRODUCT_IDX:item.PRODUCT_IDX
              , INQUIRY_IDX:item.INQUIRY_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Delete Complete<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage(this.page);
                    this.panel = [];
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });      

            }
        });

      },
            

      //게시글 상세보기
      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },

      //아이템 수정
      edit_item(item){
        this.editedItem = Object.assign({}, item);
        this.qna_dialog = true;
      },

      inquiry_save(item){

        const checkrepple = cmvalidation.checkValReg(item.REPPLE, null, 5, 200);
        if(checkrepple == 1){
          this.$fire({
            html: "Please enter your answer<br><br>답변을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkrepple == 3){
          this.$fire({
            html: "Please enter the answer in 250 characters or then<br><br>답변은 5자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkrepple == 4){
          this.$fire({
            html: "Please enter the answer in 200 characters or less<br><br>답변은 200자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        this.$http.post(this.$host+'/InQuiryUpdate', {
            REPPLE: escape(item.REPPLE)
          , PRODUCT_IDX: item.PRODUCT_IDX
          , INQUIRY_IDX: item.INQUIRY_IDX
          , ACCNT: item.ACCNT
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.qna_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your answer has been registered.<br><br>답변이 등록되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.loadpage(this.page);
              this.panel = [];

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            
            this.$fire({
              html: "Failed to add answers<br><br>답변 추가 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.qna_dialog = false;
          this.$fire({
            html: "Failed to add answers<br><br>답변 추가 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      

    },



}
</script>

<style scoped>

</style>