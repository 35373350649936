<template>
  <v-container fluid class="container_bg">


    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_prog" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">Please wait...</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 추가/수정 팝업 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="pay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">결제정보입력</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <v-row dense align="center" justify="center" class="mt-10 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">이름을 입력하세요</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="PAY_NAME"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       

              <v-row dense align="center" justify="center" class="mt-10 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">연락처를 입력하세요</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black"
                    single-line v-model="PAY_TEL"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>       

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close_pay">취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="paymentv1">결제하기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>          

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     


    <v-row align="center" justify="center" dense class="mt-5">
      <v-col cols="12" md="10" align="center"> <!-- 해상도 조절 컬럼 -->

        <!-- 프리미엄 상점 정보 입력 -->
        <template v-if="ispremium">

          <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h3 text-h5 text-center ma-0 pa-0 club_title">VIVID CLUB 상점정보</p>
            </v-col>
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-1 text-center ma-0 pa-0 club_title">입력하신 상점정보가 가까운 매장에 노출됩니다.</p>
            </v-col>
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0" v-if="sub_days <= 31">
              <p class="text-md-h6 text-body-2 text-center ma-0 pa-0 club_title">VIVID CLUB 기간이 {{this.sub_days}}일 남았습니다. 결제하기를 통해 기간을 연장하세요.</p>
            </v-col>
            <v-col cols="12" md="5" align="start"  class="ma-0 pa-0" v-if="sub_days <= 31">
              <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="showpayment">결제하기</v-btn>
            </v-col>
          </v-row>


          <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
            <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">상점명</span></p>
              <v-spacer/>
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" @click="showdetail">
                <span class="dialog_subtitle_kor">상점보기</span>
              </p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"  
                single-line v-model="editedItem.SHOPNAME"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">매장설명</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-textarea
                class="none_radius" color="black"
                single-line v-model="editedItem.SHOPDESC"
                outlined dense hide-details
                />
            </v-col>
          </v-row>            

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">대표 연락처</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.SHOPTEL"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>            

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">홈페이지</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.HOMEPAGE"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>            

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >

            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">대표 이미지</span></p>
            </v-col>

            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
              <template v-if="previewImg1.length > 0">
                <v-row dense  justify="center">
                  <div style="position:relative">
                    <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                      <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                    </div>
                    <img height="120" width="213" :src="previewImg1" class="image">
                  </div>
                </v-row>
              </template>
              <template v-else>
                <v-row dense class="image-border" align="center" justify="center">
                  <v-icon size="25" @click="fileSelect(0)">mdi-plus</v-icon>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
                <template v-if="previewImg2.length > 0">
                  <v-row dense  justify="center">
                    <div style="position:relative">
                      <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                        <v-icon style="width:40px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                      </div>
                      <img height="120" width="213" :src="previewImg2" class="image">
                    </div>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border" justify="center">
                    <v-icon size="25" @click="fileSelect(1)">mdi-plus</v-icon>
                  </v-row>
                </template>
            </v-col>
            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px" >
                <template v-if="previewImg3.length > 0">
                  <v-row dense  justify="center">
                    <div style="position:relative">
                      <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                        <v-icon style="width:40px;height:40px;color:white" @click="showImage(2)">mdi-magnify-expand</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(2)">mdi-pencil</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="clearFile(2)">mdi-window-close</v-icon>
                      </div>
                      <img height="120" width="213" :src="previewImg3" class="image">
                    </div>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border" justify="center">
                    <v-icon size="25" @click="fileSelect(2)">mdi-plus</v-icon>
                  </v-row>
                </template>
            </v-col>            

          </v-row>            

          <v-row dense align="center" justify="center" class="mt-0 mt-md-5 ma-0" >
            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
              <template v-if="previewImg4.length > 0">
                <v-row dense  justify="center">
                  <div style="position:relative">
                    <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                      <v-icon style="width:40px;height:40px;color:white" @click="showImage(3)">mdi-magnify-expand</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(3)">mdi-pencil</v-icon>
                      <v-icon style="width:40px;height:40px;color:white" @click="clearFile(3)">mdi-window-close</v-icon>
                    </div>
                    <img height="120" width="213" :src="previewImg4" class="image">
                  </div>
                </v-row>
              </template>
              <template v-else>
                <v-row dense class="image-border" align="center" justify="center">
                  <v-icon size="25" @click="fileSelect(3)">mdi-plus</v-icon>
                </v-row>
              </template>
            </v-col>
            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px">
                <template v-if="previewImg5.length > 0">
                  <v-row dense  justify="center">
                    <div style="position:relative">
                      <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                        <v-icon style="width:40px;height:40px;color:white" @click="showImage(4)">mdi-magnify-expand</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(4)">mdi-pencil</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="clearFile(4)">mdi-window-close</v-icon>
                      </div>
                      <img height="120" width="213" :src="previewImg5" class="image">
                    </div>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border" justify="center">
                    <v-icon size="25" @click="fileSelect(4)">mdi-plus</v-icon>
                  </v-row>
                </template>
            </v-col>
            <v-col cols="12" md="3" class="mt-1 mt-md-0" align="center" align-self="center" style="height:120px" >
                <template v-if="previewImg6.length > 0">
                  <v-row dense  justify="center">
                    <div style="position:relative">
                      <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:213px;background-color:#00000080">
                        <v-icon style="width:40px;height:40px;color:white" @click="showImage(5)">mdi-magnify-expand</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(5)">mdi-pencil</v-icon>
                        <v-icon style="width:40px;height:40px;color:white" @click="clearFile(5)">mdi-window-close</v-icon>
                      </div>
                      <img height="120" width="213" :src="previewImg6" class="image">
                    </div>
                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border" justify="center">
                    <v-icon size="25" @click="fileSelect(5)">mdi-plus</v-icon>
                  </v-row>
                </template>
            </v-col>      
          </v-row>

            
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-textarea height="70"
                class="none_radius" color="black"
                single-line v-model="editedItem.ADDR1" disabled
                outlined dense hide-details/>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
            </v-col>
          </v-row>         

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">상세주소</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.ADDR2"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>            

          <v-row dense class="mt-0" justify="center">
            <v-col cols="10">
                <div id="gmap" style="width:100%;height:300px"/>
            </v-col>
          </v-row>
  

        <v-row align="center" justify="center">
          <v-col cols="12" md="10"> <!-- 해상도 조절 컬럼 -->
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        </template>
        <template v-else>

          <!-- 프리미엄 상점 업그레이드 결제 -->
          <v-row dense>
            <v-col cols="12" class="pt-md-10">
              <v-parallax :height="$vuetify.breakpoint.smAndDown ? 100 : 200" 
                dark src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/vivid-bottom-bg.png">
                <p class="text-center ma-0 pa-0 club_title" style="color:white"
                  :style="$vuetify.breakpoint.smAndDown ? 'font-size:28px;' : 'font-size:60px;'">
                  VIVID CLUB MEMBERSHIP
                </p>
              </v-parallax>    
            </v-col>
          </v-row>
      
          
          <v-row dense align="center" justify="center" class="mt-8 mt-md-10 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <div class="notosanskr_font text-center text_black"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:40px;'"
                >
                VIVID CLUB MEMBERSHIP 가입 혜택
              </div>
            </v-col>
          </v-row>

          <v-row id="info1" dense align="start" justify="center" class="mt-15 ma-0">
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                가까운 소매상 찾기에 연결
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                회원사의 정보가 소비자의 위치에서 가까운 상점을 찾을 때 노출됩니다.<br>
                kDiaGem App으로 간편하게 주변 상점에 노출되어 더 많은 문의를 받아보세요.
              </div>
            </v-col>
            <v-col cols="12" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/findmap.png"/>
              </div>
            </v-col>
          </v-row>

          <v-row id="info2" dense align="start" justify="center" class="mt-15 ma-0" >
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                상품 등록 서비스
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                보유하신 상품을 무제한으로 등록할 수 있습니다.<br>
                kDiaGem을 통해 쉽고 간편하게 상품을 관리하세요.
              </div>
            </v-col>
            <v-col cols="11" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/infoshop.png" />
              </div>
            </v-col>
          </v-row>


          <v-row id="info3" dense align="start" justify="center" class="mt-15 ma-0" >
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                소비자 연결, 수익 공유!
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                소비자가 보유한 다이아몬드와 보석을 판매할 때 가까운 소매상을 연결하여 수익을 공유합니다.
              </div>
            </v-col>
            <v-col cols="11" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/infomoney.jpg" />
              </div>
            </v-col>
          </v-row>

          <v-row id="info4" dense align="start" justify="center" class="mt-15 ma-0" >
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                라파포트 시세표 제공
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                365일 변하는 라파포트 시세표를 제공합니다.<br>
                간편하게 시세표를 확인하고 다운로드 해보세요!                
              </div>
            </v-col>
            <v-col cols="11" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/inforapa.png" />
              </div>
            </v-col>
          </v-row>

          <v-row id="info5" dense align="start" justify="center" class="mt-15 ma-0" >
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                공동 마케팅
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                멤버쉽 회원과의 조합형 공동 홍보 및 광고!<br>
                공용 마케팅으로 기업화 브랜드 가치창출을 함께합니다.
              </div>
            </v-col>
            <v-col cols="11" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/infomkt.png" />
              </div>
            </v-col>
          </v-row>

          <v-row id="info6" dense align="start" justify="center" class="mt-15 ma-0" >
            <v-col cols="12" md="12" class="ma-0 pa-0">
              <div class="text-left club_text" style="font-weight:bold"
                :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
                절세 절약
              </div>
              <div class="text-left club_text mt-2" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                위탁 판매 시스템 이용<br>
                본사 매출 세금계산서 발행<br>
                멤버쉽 회원사 판매 마진만 매출 신고!
              </div>
            </v-col>
            <v-col cols="11" md="6" class="ma-0 pa-0 mt-10">
              <div class="text-left club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
                <v-img src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/infotax.png"  />
              </div>
            </v-col>
          </v-row>

          <v-col cols="12" md="10" align="start"  class="mt-15 ma-0" 
            style="font-weight:bold;color:black"
            :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px;' : 'font-size:30px;'">
            <div class="text-center club_text" :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;' : 'font-size:18px;'">
              MEMBERSHIP 결제시 위 모든 혜택이 적용됩니다.
            </div>
            <div class="text-center club_price">1년/200,000원</div>
          </v-col>
          <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0 mb-15">
            <v-col cols="12" md="5" align="start"  class="ma-0 pa-0">
              <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="showpayment">결제하기</v-btn>
            </v-col>
          </v-row>

        </template>

      </v-col>
    </v-row>
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default {

    components:{
      VueCropper
    },

    mounted() {
      
      
      gsap.registerPlugin(ScrollTrigger);
      /*
      gsap.fromTo("#info1",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1 });     
      gsap.fromTo("#info2",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, 
        scrollTrigger: { trigger:"#info1", start:"top center"}
      });     
      gsap.fromTo("#info3",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, 
        scrollTrigger: { trigger:"#info3", start:"top center"}
      });     
      gsap.fromTo("#info4",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, 
        scrollTrigger: { trigger:"#info4", start:"top center"}
      });     
      gsap.fromTo("#info5",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, 
        scrollTrigger: { trigger:"#info5", start:"top center"}
      });     
      gsap.fromTo("#info6",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, 
        scrollTrigger: { trigger:"#info6", start:"top center"}
      });     
      */

      if(this.$session.get("GRADE") > 90){
        //슈퍼관리자
        if(this.$route.query.code != undefined || this.$route.query.code != null){
          this.ACCNT_IDX = Number(this.$route.query.code);
        }
      }else{
        this.ACCNT_IDX = this.$session.get("ACCNT_IDX");
      }

      if(this.ACCNT_IDX == null || this.ACCNT_IDX == undefined){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.loadpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

      
    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave(to, from, next){
      next();
    },


    data: () => ({

      ACCNT_IDX: null,
      
      pay_dialog: false,
      PAY_TEL:"",
      PAY_NAME:"",

      ispremium: false,
      sub_days:999,

      gmap:null,
      gmarker:null,
      marker_position:{lat: 37.512268, lng: 127.058940},
      map_position: {lat: 37.512268, lng: 127.058940},

      //파일 업로드
      ratio: 16/9,

      uploadFile1 : [],
      uploadFile2 : [],
      uploadFile3 : [],
      uploadFile4 : [],
      uploadFile5 : [],
      uploadFile6 : [],

      previewImg1:"",
      previewImg2:"",
      previewImg3:"",
      previewImg4:"",
      previewImg5:"",
      previewImg6:"",  

      crop_dialog:false,
      photo_index:0,
      imgSrc:'',

      options: {
        focus:false,
      },      
      editedItem:{},
      defaultItem: {
          SHOPNAME:'',
          SHOPDESC:'',
          IMAGE:'',
          YOUTUBE:'',
          LATITUTE:'37.51205332712675',
          LONGITUTE:'127.05914127516714',
          LINK_URL:'',
          ADDR1:'',
          ADDR2:'',
          INFO:'',
          AREANAME:'',
          AREA_IDX : -1,
          GALLERY_IDX:-1,
          IDX:-1,
      },

      loading_prog : false,

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [16,9],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

    }),

    created(){

    },

    methods: {

      showdetail(){

        this.$router.push({path:"/ShopDetail", query:{code:this.ACCNT_IDX}});

      },

      close_pay(){
        this.pay_dialog = false;
      },

      showpayment(){
        this.PAY_NAME = "";
        this.PAY_TEL = "";
        this.pay_dialog = true;
      },

      async paymentv1(){
        
        const checkname = cmvalidation.checkValReg(this.PAY_NAME, null, 2, 8);
        if(checkname == 1){
          this.$fire({
            html: "이름을 입력하세요.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 3){
          this.$fire({
            html: "이름은 2자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 4){
          this.$fire({
            html: "이름은 8자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const checktel = cmvalidation.checkValReg(this.PAY_TEL, null, 6, 25);
        if(checktel == 1){
          this.$fire({
            html: "연락처를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 3){
          this.$fire({
            html: "연락처는 6자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 4){
          this.$fire({
            html: "연락처는 25자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

        this.pay_dialog = false;

        this.$iamport.request_pay({
          pg: 'welcome.IMP2000039', //설정안하면 기본 pg 적용됨
          pay_method: 'card',
          name: "VIVID CLUB 1년 이용권",
          merchant_uid: 'mid_' + new Date().getTime(),
          amount: 200000,
          buyer_name: this.PAY_NAME,
          buyer_tel: this.PAY_TEL,
          notice_url: 'https://sndge-wyj2xokuya-du.a.run.app/paywebhook?accntcode='+this.ACCNT_IDX,
          m_redirect_url : 'http://kdiagem.com/ResultPayment?accntcode='+this.ACCNT_IDX
        }, async (result_success) => {

          if(result_success.error_code != null){
            this.$fire({
              html: result_success.error_msg,
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          //중료! => 콜백은 PC에서만 탄다. 모바일은 위의 m_redirect_url로 처리한다.
          //console.log("result_success : " + JSON.stringify(result_success));
          this.$router.push({path:"/ResultPayment", query:{accntcode:this.ACCNT_IDX, imp_uid:result_success.imp_uid, merchant_uid:result_success.merchant_uid}});
        
        // eslint-disable-next-line no-unused-vars
        }, (result_failure) => {
          this.$fire({
            html: "결제에러가 발생했습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        });

      },



      //지도
      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_mapfunction      = this.postcodetoMap;
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);
                
                var geocoder = new kakao.maps.services.Geocoder();
                
                //주소로 상세 정보를 검색
                geocoder.addressSearch(data.address, function(results, status) {

                    // 정상적으로 검색이 완료됐으면
                    if (status === daum.maps.services.Status.OK) {

                        var result = results[0]; //첫번째 결과의 값을 활용

                        // 해당 주소에 대한 좌표를 받아서
                        var coords = new daum.maps.LatLng(result.y, result.x);
                        
                        var markerpos = JSON.parse('{"lat":'+result.y+', "lng":'+result.x+'}');
                        callback_mapfunction(markerpos);

                        //지도를 보여준다.
                        /*
                        mapContainer.style.display = "block";
                        map.relayout();
                        // 지도 중심을 변경한다.
                        map.setCenter(coords);
                        // 마커를 결과값으로 받은 위치로 옮긴다.
                        marker.setPosition(coords)
                        */

                    }
                });
            }
        }).open();

      },

      postcodetoMap(mpos){
        this.map_position    = mpos;
        this.marker_position = mpos;
        this.gmap.panTo(this.map_position);
        this.gmarker.setPosition(this.marker_position);
        //지도위치 저장
        this.save_map();
      },

      postcodetoAddr(addr){
        this.editedItem.ADDR1 = addr;
      },

      save_map(){
        var temp = JSON.stringify(this.marker_position);
        if(temp == "{}"){
          this.$fire({
            html: "위치를 설정하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        const pos = JSON.parse(temp);
        this.editedItem.LATITUTE  =  String(pos.lat);
        this.editedItem.LONGITUTE =  String(pos.lng);
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }else if(index == 2){
          img = [this.previewImg3];
        }else if(index == 3){
          img = [this.previewImg4];
        }else if(index == 4){
          img = [this.previewImg5];
        }else if(index == 5){
          img = [this.previewImg6];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }else if(index == 2){
          this.previewImg3 = "";
        }else if(index == 3){
          this.previewImg4 = "";
        }else if(index == 4){
          this.previewImg5 = "";
        }else if(index == 5){
          this.previewImg6 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },


      
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }else if(index == 2){
          img = [this.previewImg3];
        }else if(index == 3){
          img = [this.previewImg4];
        }else if(index == 4){
          img = [this.previewImg5];
        }else if(index == 5){
          img = [this.previewImg6];

        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }else if(index == 2){
          this.previewImg3 = "";
        }else if(index == 3){
          this.previewImg4 = "";
        }else if(index == 4){
          this.previewImg5 = "";
        }else if(index == 5){
          this.previewImg6 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },      

      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }else if(pindex == 2){
            this.uploadFile3 = [];
          }else if(pindex == 3){
            this.uploadFile4 = [];
          }else if(pindex == 4){
            this.uploadFile5 = [];
          }else if(pindex == 5){
            this.uploadFile6 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      

      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 2){
              this.uploadFile3 = blob;
              this.previewImg3 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 3){
              this.uploadFile4 = blob;
              this.previewImg4 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 4){
              this.uploadFile5 = blob;
              this.previewImg5 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 5){
              this.uploadFile6 = blob;
              this.previewImg6 = data; //이미지뷰 미리보기용
            }

          });

        });
      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },

      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },
      
      loadpage(){

        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.uploadFile3 = [];
        this.uploadFile4 = [];
        this.uploadFile5 = [];
        this.uploadFile6 = [];
        this.previewImg1 = "";
        this.previewImg2 = "";
        this.previewImg3 = "";
        this.previewImg4 = "";
        this.previewImg5 = "";
        this.previewImg6 = "";

          this.$http.post(this.$host+'/ShopInfoDetail',{
            ACCNT_IDX: this.ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                this.ispremium = true;

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }


                if(result.data.resultData[0] == null || result.data.resultData[0] == undefined){
                  //결제 필요
                  this.editedItem   = Object.assign({}, this.defaultItem);
                  this.editedItem.LATITUTE  = 37.512268;
                  this.editedItem.LONGITUTE = 127.058940;

                }else{
                  //결제된 경우
                  this.editedItem = result.data.resultData[0];
                  this.TEL = this.editedItem.TEL;
                  this.sub_days = this.editedItem.SUB_DAYS;
                  if(this.editedItem.ACCNT_IDX == null){
                    //최초
                    this.editedItem.LATITUTE  = 37.512268;
                    this.editedItem.LONGITUTE = 127.058940;
                  }
                  
                  this.previewImg1 = this.editedItem.IMG1_URL != null && this.editedItem.IMG1_URL.length > 0 ? this.editedItem.IMG1_URL : "";
                  this.previewImg2 = this.editedItem.IMG2_URL != null && this.editedItem.IMG2_URL.length > 0 ? this.editedItem.IMG2_URL : "";
                  this.previewImg3 = this.editedItem.IMG3_URL != null && this.editedItem.IMG3_URL.length > 0 ? this.editedItem.IMG3_URL : "";
                  this.previewImg4 = this.editedItem.IMG4_URL != null && this.editedItem.IMG4_URL.length > 0 ? this.editedItem.IMG4_URL : "";
                  this.previewImg5 = this.editedItem.IMG5_URL != null && this.editedItem.IMG5_URL.length > 0 ? this.editedItem.IMG5_URL : "";
                  this.previewImg6 = this.editedItem.IMG6_URL != null && this.editedItem.IMG6_URL.length > 0 ? this.editedItem.IMG6_URL : "";

                }
                this.map_position    = JSON.parse('{"lat":'+this.editedItem.LATITUTE+', "lng":'+this.editedItem.LONGITUTE+'}');
                this.marker_position = JSON.parse('{"lat":'+this.editedItem.LATITUTE+', "lng":'+this.editedItem.LONGITUTE+'}');

                if(this.gmap == null){
                  setTimeout(() => {
                    this.gmap = new google.maps.Map(document.getElementById('gmap'), {
                      zoom: 16, center:this.map_position
                    });
                    this.gmarker = new google.maps.Marker({position:this.marker_position});
                    this.gmarker.setMap(this.gmap);
                  }, 300)
                }else{
                  this.gmap.panTo(this.map_position);
                  this.gmarker.setPosition(this.marker_position);
                }

            }else if(result.data.resultCode == 3){

              this.ispremium = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      //다이얼로그 닫기
      close () {
        this.$router.back();
      },      

            //추가 저장
      async save () {

        this.loading_prog = true;

        const checkname = cmvalidation.checkValReg(this.editedItem.SHOPNAME, null, 2, 50);
        if(checkname == 1){
          this.loading_prog = false;
          this.$fire({
            html: "상점명을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 3){
            this.loading_prog = false;
            this.$fire({
              html: "상점명은 2자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }else if(checkname == 4){
          this.loading_prog = false;
          this.$fire({
            html: "상점명은 50자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }


        const checkdesc = cmvalidation.checkValReg(this.editedItem.SHOPDESC, null, -1, 500);
        if(checkdesc == 1){
          this.loading_prog = false;
          this.$fire({
            html: "매장설명을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkdesc == 4){
          this.loading_prog = false;
          this.$fire({
            html: "매장설명은 500자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const checktel = cmvalidation.checkValReg(this.editedItem.SHOPTEL, /[^0-9-]/g, 5, 50);
        if(checktel == 1){
          this.loading_prog = false;
            this.$fire({
              html: "대표 연락처를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }else if(checktel == 2){
          this.loading_prog = false;
          this.$fire({
            html: "대표 연락처는 숫자와 -만 입력이 가능합니다",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 3){
          this.loading_prog = false;
          this.$fire({
            html: "대표 연락처는 5자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktel == 4){
          this.loading_prog = false;
          this.$fire({
            html: "대표 연락처는 20자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const urlVal = /https?:\/\//;
        const checklink = cmvalidation.checkValReg(this.editedItem.HOMEPAGE, urlVal, -1, 255, false);
        if(checklink == 2){
          this.$fire({
            html: "홈페이지 주손는 http 또는 https로 시작해야 합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 4){
          this.$fire({
            html: "홈페이지 주소의 최대 입력 제한을 초과합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }        

        const checkaddr = cmvalidation.checkValReg(this.editedItem.ADDR1, null, 6, 100);
        if(checkaddr == 1){
          this.loading_prog = false;
            this.$fire({
              html: "주소를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }else if(checkaddr == 3){
          this.loading_prog = false;
          this.$fire({
            html: "주소는 6자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkaddr == 4){
          this.loading_prog = false;
          this.$fire({
            html: "주소는 100자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }          

        const checkaddr2 = cmvalidation.checkValReg(this.editedItem.ADDR2, null, 2, 100);
        if(checkaddr2 == 1){
          this.loading_prog = false;
            this.$fire({
              html: "상세주소를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
        }else if(checkaddr2 == 3){
          this.loading_prog = false;
          this.$fire({
            html: "상세주소는 2자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkaddr2 == 4){
          this.loading_prog = false;
          this.$fire({
            html: "상세주소는 100자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }                

        //대표이미지는 1개만 등록해도 된다.
        if(this.isNullCheck(this.uploadFile1)){
            if(this.isNullCheck(this.editedItem.IMG1)){
              this.loading_prog = false;
              this.$fire({
                html: "대표 이미지를 등록하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
        }

        if(!this.isNullCheck(this.uploadFile1)){
          var uploadpath = Date.now().toString()+".jpg";
          let upload_cb1 = await this.uploadStorage("company", uploadpath, this.uploadFile1);
          if(upload_cb1 == null){
            this.loading_prog = false;
            this.$fire({
              html: "첫번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile1 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG1)){
            try{
              await this.deletefile(this.editedItem.IMG1);
            }catch(e){
            }
          }
          this.editedItem.IMG1 = upload_cb1.resultRef;
        }

        if(!this.isNullCheck(this.uploadFile2)){
          var uploadpath2 = Date.now().toString()+".jpg";
          let upload_cb2 = await this.uploadStorage("company", uploadpath2, this.uploadFile2);
          if(upload_cb2 == null){
            this.loading_prog = false;
            this.$fire({
              html: "두번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile2 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG2)){
            try{
              await this.deletefile(this.editedItem.IMG2);
            }catch(e){
            }
          }
          this.editedItem.IMG2 = upload_cb2.resultRef;
        }

        if(!this.isNullCheck(this.uploadFile3)){
          var uploadpath3 = Date.now().toString()+".jpg";
          let upload_cb3 = await this.uploadStorage("company", uploadpath3, this.uploadFile3);
          if(upload_cb3 == null){
            this.loading_prog = false;
            this.$fire({
              html: "세번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile3 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG3)){
            try{
              await this.deletefile(this.editedItem.IMG3);
            }catch(e){
            }
          }
          this.editedItem.IMG3 = upload_cb3.resultRef;
        }
        
        if(!this.isNullCheck(this.uploadFile4)){
          var uploadpath4 = Date.now().toString()+".jpg";
          let upload_cb4 = await this.uploadStorage("company", uploadpath4, this.uploadFile4);
          if(upload_cb4 == null){
            this.loading_prog = false;
            this.$fire({
              html: "네번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile4 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG4)){
            try{
              await this.deletefile(this.editedItem.IMG4);
            }catch(e){
            }
          }
          this.editedItem.IMG4 = upload_cb4.resultRef;
        }
        
        if(!this.isNullCheck(this.uploadFile5)){
          var uploadpath5 = Date.now().toString()+".jpg";
          let upload_cb5 = await this.uploadStorage("company", uploadpath5, this.uploadFile5);
          if(upload_cb5 == null){
            this.loading_prog = false;
            this.$fire({
              html: "다섯번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile5 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG5)){
            try{
              await this.deletefile(this.editedItem.IMG5);
            }catch(e){
            }
          }
          this.editedItem.IMG5 = upload_cb5.resultRef;
        }
        
        if(!this.isNullCheck(this.uploadFile6)){
          var uploadpath6 = Date.now().toString()+".jpg";
          let upload_cb6 = await this.uploadStorage("company", uploadpath6, this.uploadFile6);
          if(upload_cb6 == null){
            this.loading_prog = false;
            this.$fire({
              html: "여섯번째 대표 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile6 = [];

          //정상적으로 업로드시
          if(!this.isNullCheck(this.editedItem.IMG6)){
            try{
              await this.deletefile(this.editedItem.IMG6);
            }catch(e){
            }
          }
          this.editedItem.IMG6 = upload_cb6.resultRef;
        }
              
        this.$http.post(this.$host+'/ShopInfoUpdate',{
              SHOPNAME: escape(this.editedItem.SHOPNAME)
            , SHOPDESC: this.editedItem.SHOPDESC == undefined || this.editedItem.SHOPDESC.length < 1 ? '' : escape(this.editedItem.SHOPDESC)
            , SHOPTEL: this.editedItem.SHOPTEL == undefined || this.editedItem.SHOPTEL.length < 1 ? '' : escape(this.editedItem.SHOPTEL)
            , HOMEPAGE: this.editedItem.HOMEPAGE == undefined || this.editedItem.HOMEPAGE.length < 1 ? '' : escape(this.editedItem.HOMEPAGE)
            , IMG1: this.editedItem.IMG1 == null || this.editedItem.IMG1 == undefined ? '' : escape(this.editedItem.IMG1)
            , IMG2: this.editedItem.IMG2 == null || this.editedItem.IMG2 == undefined ? '' : escape(this.editedItem.IMG2)
            , IMG3: this.editedItem.IMG3 == null || this.editedItem.IMG3 == undefined ? '' : escape(this.editedItem.IMG3)
            , IMG4: this.editedItem.IMG4 == null || this.editedItem.IMG4 == undefined ? '' : escape(this.editedItem.IMG4)
            , IMG5: this.editedItem.IMG5 == null || this.editedItem.IMG5 == undefined ? '' : escape(this.editedItem.IMG5)
            , IMG6: this.editedItem.IMG6 == null || this.editedItem.IMG6 == undefined ? '' : escape(this.editedItem.IMG6)
            , ADDR1: this.editedItem.ADDR1 == undefined || this.editedItem.ADDR1.length < 1 ? '' : escape(this.editedItem.ADDR1) 
            , ADDR2: this.editedItem.ADDR2 == undefined || this.editedItem.ADDR2.length < 1 ? '' : escape(this.editedItem.ADDR2) 
            , LATITUTE: this.editedItem.LATITUTE 
            , LONGITUTE: this.editedItem.LONGITUTE
            , ACCNT_IDX: this.ACCNT_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading_prog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading_prog = false;
          this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });


      },
       


    },


}
</script>

<style scoped>

.club_title{
  font-family: 'AppleSDGothicNeoM' !important;

}
.club_price{
  font-family: 'NotoSansKR' !important;
}

.club_text{
  font-family: 'Inter' !important;
  
}

</style>