<!-- 업체 선택용 공통 팝업 -->
<template>
    
    <v-row align="center" justify="center" class="mt-5 mt-md-5">
      <v-col cols="10" align-self="center" align="center" class="align-center justify-center pa-0 mt-1 mt-md-0 pl-1 pl-md-0 pr-1 pr-md-2 d-flex">
        <input
          dense single-line clearable
          @keyup.enter="searchbar_run" 
          v-model="search_keyword" 
          color="#000000"
          hide-details
          class="basicsearchstyle_mb"
          :placeholder="hint"
          />
          <v-btn icon class="search_btn_mb" elevation="0" outlined large @click="searchbar_run">
            <v-icon size="20">mdi-magnify</v-icon>
          </v-btn>
      </v-col>
    </v-row>

</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
          hint:String
        },

        data: ()=> ({
          search_keyword: '',
        }),

        methods: {

          //외부에서 검색어 셋팅
          setSearch(search){
            this.search_keyword = search;
          },
          
          searchbar_run(){
            this.$emit('searchcallback', this.search_keyword);
          },

        }

    }
</script>

<style scoped>


</style>