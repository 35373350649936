<!-- 업체 선택용 공통 팝업 -->
<template>

  <v-container fluid class="container_bg">

    <v-row align="center" justify="center">
        <v-col cols="8" class="pa-0">
          <v-text-field 
            v-model="comname" dense disabled
            placeholder="구매자를 선택하세요"
            outlined height="43"
            color="#808080"
            hide-details
            class="basicsearchstyle"
            />
        </v-col>
        <v-col cols="4" class="pa-0" align="center">
          <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="showpopup_com">
            <v-icon left>mdi-check</v-icon>Select<br>선택</v-btn>
        </v-col>
    </v-row>   

    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000FF" v-model="dialog_com" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" 
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        <v-card class="pa-0 ma-0">
          
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
              <v-col cols="12" md="12" align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span class="text-md-h6 text-subtitle-1" style="color:white">계정선택</span>
              </v-col>
            </v-row>
          </v-card-title>
          
          <v-card-text class="ma-0 pa-1">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" md="12" align="center">

              <v-row dense class="ma-0 mt-5">
                <v-col cols="12" class="pa-0 d-flex pl-1 pl-md-0 pr-1 pr-md-0">
                  <v-text-field 
                    dense single-line clearable
                    @keyup.enter="search_run_com" 
                    v-model="search_keyword_com" 
                    placeholder="검색어를 입력하세요"
                    outlined height="43"
                    color="#000000"
                    hide-details
                    class="basicsearchstyle"
                    />
                  <v-btn :width="this.$vuetify.breakpoint.smAndDown ? 100 : 150" class="search_btn" elevation="0" outlined large @click="search_run_com">
                    <v-icon left>mdi-magnify</v-icon>Search<br>검색
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col md="12" sm="12" xs="12">
                  <v-data-table
                    :headers="headers_com"
                    :items="itemList_com"
                    v-model="select_com"
                    no-data-text="데이터가 없습니다."
                    hide-default-footer
                    class="datatablestyle"
                    :items-per-page="pagerow_com"
                    item-key="IDX"
                    mobile-breakpoint="0"
                    >
                    <template v-slot:header.NAME>
                      <a :class="sort_name_com =='NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort_com('NAME')">업체명</a>
                    </template>
                    <template v-slot:header.ACCNT>
                      <a :class="sort_name_com =='ACCNT'?'cheader_sortselect':'cheader_sort'" @click="customsort_com('ACCNT')">계정</a>
                    </template>

                    <template v-slot:item.NAME="{ item }">
                      <a @click="select_func(item)">{{item.NAME}}</a>
                    </template>
                    <template v-slot:item.LOGO="{ item }">
                      <v-img :src="item.LOGO" height="40" aspect-ratio="1" contain/>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="d-flex justify-center">
                  <v-btn v-if="bottompage_com > 10" icon  small @click="prev_com()"><v-icon dark>mdi-chevron-left</v-icon></v-btn>
                  <span v-for="n in subpage_com" :key="n.id">
                      <span v-if="page_com == bottompage_com-1+n">
                          <v-btn color="red" icon small @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                      <span v-else>
                          <v-btn icon small @click="loadpage_com(bottompage_com-1+n)">{{bottompage_com-1+n}}</v-btn>
                      </span>
                  </span>
                  <v-btn v-if="subpage_com == 10" icon small @click="next_com()" ><v-icon dark>mdi-chevron-right</v-icon></v-btn>
                </v-col>
              </v-row>
            
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" md="12" sm="10" xs="10">
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="clear_com">선택취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="close_com">닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>     
    </v-row>
    
  </v-container>

</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
            comname: String,
            cbname:{type:String, default: "dev..."}
        },

        data: ()=> ({
            
            dialog_com : false,
            load_flag : false,
            headers_com: [
                {text: '업체명',    value: 'NAME', sortable: false, align:'center', width:'200px',class:"cheader_normal"},
                {text: '계정',      value: 'ACCNT', sortable: false, align:'center', class:"cheader_normal" }
            ],
            sort_name_com: 'NAME',
            sort_type_com: true,    //true : DESC, false: ASC

            itemList_com: [],
            select_com:[],
            search_keyword_com : '',
            totalcnt_com : 0,      //전체 아이템 개수
            page_com : 0,
            maxpage_com : 0,       //전체 페이지 개수
            startpage_com: 1,      //시작 인덱스
            bottompage_com : 1,    //하단 페이징 번호의 시작점을 계산하기 위한 변수
            subpage_com : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
            pagerow_com : 15,      //한 페이지에 보여줄 row수
            
        }),

        updated(){
          
          if(this.dialog_com){
            if(!this.load_flag && this.itemList_com.length == 0 && this.search_keyword_com.length == 0){
              this.load_flag = true;
              this.loadpage_com(1);
            }
          }else{
            //팝업이 닫히면
            this.load_flag = false;
          }

        },

        methods: {

            customsort_com(sortname){
              this.sort_name_com = sortname;
              this.sort_type_com = !this.sort_type_com;
              this.loadpage_com(this.page_com);
            },

            prev_com(){
                this.bottompage_com = this.bottompage_com - 10 >= 1 ? this.bottompage_com - 10 : 1;
                this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                var temp_page = this.bottompage_com + this.subpage_com - 1; //이전을 누르면 가장 끝 번호 선택
                this.loadpage_com(temp_page);
            },
            next_com(){
                this.bottompage_com = this.bottompage_com + 10 <= this.maxpage_com ? this.bottompage_com + 10 : this.maxpage_com;
                this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                var temp_page = this.bottompage_com;
                this.loadpage_com(temp_page);
            },

            makepaging_com(){
                var div1 = this.totalcnt_com / this.pagerow_com; 
                var div2 = this.totalcnt_com % this.pagerow_com;
                if(div2 == 0){  
                    this.maxpage_com = parseInt(div1);    
                }else{
                    this.maxpage_com = parseInt(div1+1);
                }
                if(this.bottompage_com == 1){
                  this.subpage_com = this.maxpage_com - this.bottompage_com >= 10 ? 10 : this.maxpage_com - this.bottompage_com+1;
                }
            },

            search_run_com(){
                this.loadpage_com(1);
            },

            loadpage_com(p){

                this.page_com = p;
                this.$http.post(this.$host+'/UserAcntList',{
                      search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                    , sort_name:this.sort_name_com
                    , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
                    , page: this.page_com
                    , pagerow: this.pagerow_com
                },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
                .then((result)=>{
                    this.load_flag = true;
                    if(result.data.resultCode == 0){

                        //토큰 저장
                        if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                          this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                        }
                        if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                          this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                        }

                        this.totalcnt_com = result.data.totalcnt;
                        this.makepaging_com();
                        const list = result.data.resultData;
                        this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                        list.forEach(element => {
                            this.itemList_com.push(element); 
                        });

                    }else if(result.data.resultCode == 2){
                      //로그인 필요
                      this.$fire({
                        html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                        confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                        confirmButtonColor: '#000000',
                        allowEscapeKey:false, 
                        allowOutsideClick:false,
                        allowEnterKey:true,
                      }).then(r => {
                        if(r.value){
                          this.$EventBus.$emit('clearsession');
                        }
                      });
                    }
                })
                // eslint-disable-next-line no-unused-vars
                .catch((error)=>{
                    
                });

            },

            //다이얼로그 닫기
            close_com () {
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';
            },

            //선택취소
            clear_com(){
                this.$emit('cancelcallback', '')
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';
            },

            //추가 저장
            select_func(select_item) {
              this.$emit('callback', select_item);
              //콜백 호출 후 닫아야함.
              this.dialog_com = false
              this.select_com = [];
              this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
              this.search_keyword_com = '';
            },      

            showpopup_com(){
                this.dialog_com = true;
                this.load_flag = false;
            },


        }

    }
</script>

<style scoped>

  a{
    color: black;
  }

</style>