<template>
    <v-container fluid class="container_bg">
  
    <!-- 아임포트 상세 정보 -->
    <v-row align="center"  justify="center">
        <v-dialog scrollable persistent overlay-color="#000000FF"  v-model="dialog_iam" 
            :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
            class="none_radius">

            <v-card class="pa-0 ma-0">
  
            <v-card-title class="ma-0 pa-0" style="overflow:hidden">
                <v-row dense align="center" justify="center">
                <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                    <span style="color:white">결제상세정보</span>
                </v-col>
                </v-row>
            </v-card-title>
  
            <v-card-text>
                <v-row align="center" justify="center" dense>
                <v-col cols="12">
                    <vue-json-pretty
                    :data="iamData"                  
                    >
                    </vue-json-pretty>
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="mt-1 mb-1">
                <v-row align="center" justify="center">
                    <v-col cols="12" > <!-- 해상도 조절 컬럼 -->

                      <v-row class="ma-0 pa-0" justify="center">
                        <v-col cols="6" class="ma-0 pa-0">
                          <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="dialog_iam = false">닫기</v-btn>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>  
    </v-row>               

      <v-row align="start" class="mt-5" dense>
        <v-col cols="8" md="3">
          <label class="menunamestyle">결제내역</label>
        </v-col>
        <v-spacer/>
        <v-col cols="4" class="hidden-md-and-up" align="end">
          <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
        </v-col>
      </v-row>
  
  
      <v-row align="start" class="mt-1 mt-md-0" dense>
  
        <!-- 모바일 해상도 -->
        <v-col
          v-for="item in itemList"
          cols="12"
          class="hidden-md-and-up mb-3"
          >
          <v-card elevation="1">
            <v-list-item>
              <v-list-item-content>
                <div class="d-flex">
                    <div class="text-info me-auto">{{item.PAYMENTNAME}}</div>
                    <div v-if="item.USECANCEL == 1" class="text-info" style="font-size:14px" @click="delete_item(item)">취소</div>
                </div>
                <div class="text-info" style="font-size:14px">{{ `결제금액 : ${item.COST}원` }}</div>
                <div class="text-info" style="font-size:14px">{{ `결제일자 : ${item.REG_DATE}` }}</div>
              </v-list-item-content>
            </v-list-item>          
          </v-card>
        </v-col>
  
        <!-- PC해상도 -->
        <v-col cols="12" class="hidden-sm-and-down">
          <!-- 테이블 -->
          <v-data-table
            :headers="headers"
            :items="itemList"
            no-data-text="데이터가 없습니다."
            hide-default-footer
            class="datatablestyle"
            :items-per-page="pagerow"
            item-key="INQUIRY_IDX"
            :loading="loading"
            item-class="itemstyle"
            mobile-breakpoint="0"
            >
            <template v-slot:header.PAYCODE>
                <a :class="sort_name=='PAYCODE'?'cheader_sortselect':'cheader_sort'" @click="customsort('PAYCODE')">결제번호</a>
            </template>
            <template v-slot:header.STATE>
                <a :class="sort_name=='STATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('STATE')">상태</a>
            </template>
            <template v-slot:header.REG_DATE>
              <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">결제일시</a>
            </template>

            <template v-slot:item.PAYCODE="{ item }">  
                <template v-if="grade > 90">
                    <a style="text-align:center" class="text_info" @click="getiamport(item.PAYCODE)">{{ item.PAYCODE }}</a>        
                </template>
                <template v-else>
                    <div style="text-align:center" class="text_info">{{ item.PAYCODE }}</div>        
                </template>
            </template>     
            <template v-slot:item.PAYMENTNAME="{ item }">  
                <label style="text-align:center" class="text_info">{{ item.PAYMENTNAME }}</label>
            </template>     
            <template v-slot:item.STATE="{ item }">  
              <label style="text-align:center" class="text_info">{{ item.STATE == 1 ? '결제':'취소' }}</label>
            </template>     
            <template v-slot:item.COST="{ item }">  
                <label style="text-align:center" class="text_info">{{ item.COST }}</label>
            </template>     
            <template v-slot:item.REG_DATE="{ item }">  
                <label style="text-align:center" class="text_info">{{ item.REG_DATE }}</label>
            </template>     
    

            <template v-slot:item.actions="{ item }">  
                <v-icon v-if="item.USECANCEL == 1" class="mr-2" small @click="delete_item(item)">mdi-pencil</v-icon>
            </template>  
  
          </v-data-table>
        </v-col>
      </v-row>
  
      <!-- 하단검색 -->
      <searchbar ref="searchbar" @searchcallback="search_run"/>
  
      <!-- 하단페이징영역 -->
      <pagingbar ref="paging" @loadpage="movepage"/>
  
  
    </v-container>
  </template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

  export default {

    components:{
      VueJsonPretty
    },

      mounted() {
  
        if(this.$session.get("GRADE") > 90){
          //슈퍼관리자
          if(this.$route.query.code != undefined || this.$route.query.code != null){
            this.ACCNT_IDX = Number(this.$route.query.code);
          }
        }else{
          this.ACCNT_IDX = this.$session.get("ACCNT_IDX");
        }

        if(this.ACCNT_IDX == null || this.ACCNT_IDX == undefined){
          this.$fire({
            html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
              this.$router.back();
            }
          });
          return;
        }

        if(this.$session.exists("ACCNT_IDX")){
          this.initpage();
        }else{
          //키값 없으면 로그인으로
          this.$router.push("/Login");
        }      
  
      },
  
      data: () => ({

        dialog_iam:false,
        iamData:{},

        grade : 0,
        ACCNT_IDX: null,
        
        editedItem: {
        },
  
        //필수 페이징 파라메터
        totalcnt : 0,       //전체 아이템 개수
        page : 0,
        loading : false,    //로딩 표시
  
        browser_fav:false,
  
        //테이블 정의
        headers: [
          {text: '결제번호', value: 'PAYCODE', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
          {text: '상품명', value: 'PAYMENTNAME', sortable: false, align:'center', width:'160px', class:"cheader_normal"},
          {text: '금액', value: 'COST', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
          {text: '상태', value: 'STATE', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
          {text: '결제일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
          {text: '취소', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
        ],
        itemList: [],
        
        sort_name: 'REG_DATE',
        sort_type: true,    //true : DESC, false: ASC
  
        search_keyword : '',
  
        //한 페이지 ROW 개수 설정
        pagerow : 20,       //한 페이지에 보여줄 row수
  
      }),
  
      created(){
  
      },
  
      methods: {
  
        initpage(){

          this.page = this.$route.query.page;
          if(this.page == undefined || this.page == null){
            this.page = 1;
          }
          this.search_keyword = this.$route.query.search;
          if(this.search_keyword == undefined || this.search_keyword == null){
            this.search_keyword = '';
          }else{
            this.$refs.searchbar.setSearch(this.search_keyword);
          }
  
          if(this.sort_type == undefined || this.sort_type == null){
            this.sort_type = true;
          }else{
            if(this.$route.query.sorttype == "true"){
              this.sort_type = true;
            }else if(this.$route.query.sorttype == "false"){
              this.sort_type = false;
            }
          }
  
          this.sort_name = this.$route.query.sortname;
          if(this.sort_name == undefined || this.sort_name == null){
            this.sort_name = 'REG_DATE';
          }
  
          //첫페이지 로드
          this.loadpage(this.page);
        },
  
        //검색
        search_run(searchkeyword){
          if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
            this.search_keyword = searchkeyword;
          }else{
            this.search_keyword = '';
          }
          this.movepage(1);
          //this.loadpage(1);
        },
  
        //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
        movepage(p){
          this.$router.push({path:"/PaymentList", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
        },
  
        loadpage(p){
  
            this.loading = true;
            this.page = p;
            this.$refs.paging.setpage(this.page);
            this.$http.post(this.$host+'/PaymentList',{
                  search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
                , sort_name:this.sort_name
                , sort_type: this.sort_type==true ? "DESC" : "ASC" 
                , page: this.page
                , pagerow: this.pagerow
                , ACCNT_IDX: this.ACCNT_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
  
              if(result.data.resultCode == 0){
  
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
  
                  this.totalcnt = result.data.totalcnt;
                  this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                  const list = result.data.resultData;
                  this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                  list.forEach(element => {
                      this.itemList.push(element); 
                  });
                  this.loading = false;
  
              }else if(result.data.resultCode == 2){
  
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
  
              }else{
                this.loading = false;
                
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              if(error.message == 'Request failed with status code 429')
              {
                this.$fire({
                  html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }else{
                this.$fire({
                  html: error.message,
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            });
  
        },
  
        customsort(sortname){
          this.sort_name = sortname;
          this.sort_type = !this.sort_type;
          this.loadpage(this.page);
        },
  
      //아임포트 결제정보 
      getiamport(icode){

          this.$http.post(this.$host+'/getIamCode',{
              IAMCODE: icode
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.iamData = result.data.resultData
                this.dialog_iam = true;

            }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
            if(error.message == 'Request failed with status code 429')
              {
                this.$fire({
                  html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }else{
                this.$fire({
                  html: error.message,
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }            

          });

      },              

        //취소 환불
        delete_item(item){
  
          this.$fire({
            html: "결제 24시간 이내에는 취소가 가능합니다. 결제한 내용을 취소하고 환불처리 하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){
  
              this.loading = true;
              this.$http.post(this.$host+'/CancelPayment',{
                PAYCODE:item.PAYCODE,
                ACCNT_IDX: this.ACCNT_IDX
              },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
              .then((result)=>{
                this.loading = false;
                if(result.data.resultCode == 0){
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
                  this.$fire({
                    html: "취소가 완료되었습니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      const p = this.page;
                      this.page = 0;
                      this.loadpage(p);
                    }
                  });
                  
                }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$fire({
                    html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      this.$EventBus.$emit('clearsession');
                    }
                  });
                  
                }else{
                  this.$fire({
                    html: "취소 실패",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
  
                }
                
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error)=>{
                  this.loading = false;
                  this.$fire({
                    html: "취소 실패",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  });
  
              });      
  
            }
  
          });    
          
        }
  
      },
  
  
  
  }
  </script>
  
  <style scoped>
  
  .text_info{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
  }
  </style>