<!-- 가격 입력창 -->
<template>
  <div>
    <input ref="inputtext" :class="$vuetify.breakpoint.smAndDown ? 'inputstyle_mb' : 'inputstyle'" @keydown.enter="enterinput"
       v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"
       />
       <!-- oninput="javascript:this.value=this.value.replace(/[^-\.0-9]/g,'');" -->
  </div>
</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
          value:Number,
          pricetype:Number
        },

        computed: {
          displayValue: {
              get: function() {
                //console.log("get..." + this.isInputActive);
                  if(this.value == null || this.value == undefined){
                    return;
                  }else{
                    if(this.pricetype == 0){
                      return this.valuecomma(this.value);
                    }else{
                      return this.valuecomma(this.value);
                    }
                    
                  }
              },
              set: function(modifiedValue) {
                  //console.log("set...1 :"+modifiedValue);
                  let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""))
                  //console.log("set...2 :"+newValue);
                  if (isNaN(newValue)) {
                      newValue = 0
                  }
                  this.$emit('setprice', newValue);
              }
          }
        },


        data: ()=> ({
          isInputActive: false
        }),

        methods: {

          //금액 입력시 콤마 찍어주기 (소수점 포함)
          valuecomma(txt){
            
            var parts = txt.toString().split("."); 
            //console.log("vlaue comma : " + txt + " => " + parts);
            return String(parts[0]).replaceAll(",","").replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
          },

          enterinput(){
            this.$refs.inputtext.blur();
          }
        },

    }
</script>

<style scoped>

.inputstyle{
  border: 1px solid #888;
  border-radius: 10px;
  font-size: 16px;
  height: 34px;
  min-height:34px;
  width: 100%;
  padding-left: 10px;
  padding-top: 3px;
}

.inputstyle_mb{
  border: 1px solid #888;
  border-radius: 5px;
  font-size: 16px;
  height: 32px;
  min-height:25px;
  width: 100%;
  padding-left: 5px;
  padding-top: 3px;
}


input[type="number"] {
  -moz-appearance: textfield;
}

/* WebKit (Chrome, Safari, etc.) */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>