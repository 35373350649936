<template>
  <v-container fluid class="container_bg">

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h1>apiInsertProduct</h1>
      </v-col>
    </v-row>
    
    <v-divider/> 
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <p>Register your product.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>HTTP Request</h3>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">POST</v-col>
      <v-col cols="12" md="8" class="fielddesc">/apiInsertProduct</v-col>
    </v-row>
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Request Body</h3>
        The request body contains data with the following structure.<br>Request body type is <strong>form-data</strong>.
      </v-col>
    </v-row>
    
    <v-row justify="center" dense>

      <!-- 공통 -->
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_NUMBER</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String | <strong>Required</strong><br>
        Enter a product name. Enter at least 8 characters and no more than 25 characters
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_STOCK</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        Product Stock Number
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_CLASS</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">Number | <strong>Required</strong><br>
              Type of product to add
            </td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">0</td>
            <td width="80%">Diamond</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">1</td>
            <td width="80%">Gemstone</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">2</td>
            <td width="80%">Jewelry</td>
          </tr>
        </table>
      </v-col>      
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DISPLAY_STATE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Product Display Status</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Display Off</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Display On</td></tr>
        </table>
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">THUM_IMG1_FILE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">FILE<br>
        Thumbnail Image FILE, Either THUM_IMG1_FILE or THUM_IMG1 is required<br>When entering files and URLs simultaneously the file takes precedence.<br><span style="color:red">Diamonds are not mandatory.</span>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">THUM_IMG1</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        Thumbnail Image URL, Either THUM_FILE or THUM_IMG1 is required<br><span style="color:red">Diamonds are not mandatory.</span>
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IMG1_FILE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">FILE<br>
        Thumbnail Image FILE, Either PRODUCT_IMG1_FILE or PRODUCT_IMG1 is required<br>When entering files and URLs simultaneously the file takes precedence.<br><span style="color:red">Diamonds are not mandatory.</span>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IMG1</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        Product Representative Image URL<br><span style="color:red">Diamonds are not mandatory.</span>
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IMG2_FILE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">FILE<br>
        Thumbnail Image FILE<br>When entering files and URLs simultaneously the file takes precedence.
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IMG2</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        Second Product Image URL
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">APPRAISAL_IMG_FILE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">FILE<br>
        Thumbnail Image FILE, Either APPRAISAL_IMG_FILE or APPRAISAL_IMG is required<br>When entering files and URLs simultaneously the file takes precedence.
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">APPRAISAL_IMG</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        URL of the product's certificate image<br>Required for Diamond Gemstone only
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MOVIE_PATH_FILE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">FILE<br>
        Thumbnail Image FILE, Either MOVIE_PATH_FILE or MOVIE_PATH is required<br>When entering files and URLs simultaneously the file takes precedence.
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MOVIE_PATH</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>
        Product introduction video URL
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_TYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Currency classification of product price. Only USD is available on PRICE_CALCTYPE=2</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">USD</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">WON</td></tr>
        </table>
      </v-col>      
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_CALCTYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Set the pricing method.</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Direct input method, use the price entered in SUPPLY_PRICE.</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Price per weight method, the price entered in SUPPLY_PRICE <br>is multiplied by WEIGHT to calculate the price.<br><strong>Diamond and Gemstone only available PRODUCT_CLASS = 0 OR 1</strong></td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Based on the RAPAPORT market method, the price reflects the RAPAPORT market price.<br>RAPAPORT * WEIGHT * PRICE_DC<br><strong>Only natural diamonds from regular diamonds are available<br>PRODUCT_CLASS = 0 AND DIAMOND_CLASS = 0 AND DIAMONDTYPE = 0</strong></td></tr>
        </table>
      </v-col>      
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_DC</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Use only when PRICE_CALCTYPE is 2 (using RAPAPORT).<strong>Otherwise, please enter 0</strong><br>Calibrates the supply price according to the price entered.minus is DC and plus is ADD.<br>EX) -25 = DC 25% EX) 10 = ADD 10%</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SUPPLY_PRICE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the supply price of the product. The supply price entered is divided into USD and WON according to PRICE_TYPE.</td></tr>
          <tr><td width="20%" style="text-align:center">PRICE_CALCTYPE = 0</td><td width="80%">Please enter the supply price</td></tr>
          <tr><td width="20%" style="text-align:center">PRICE_CALCTYPE = 1</td><td width="80%">Enter unit price per weight</td></tr>
          <tr><td width="20%" style="text-align:center">PRICE_CALCTYPE = 2</td><td width="80%">Enter 0 in this case</td></tr>
        </table>
      </v-col>      
    </v-row>

    <v-row justify="center" class="mt-10" dense>
      <v-col cols="12" md="11" class="pa-0">
        <strong>The parameters vary depending on PRODUCT_CLASS. Please refer to the tab below.</strong>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5" dense>
      <v-col cols="12" md="11" class="pa-0">
        <v-tabs
          fixed-tabs
          background-color="#464646"
          color="white"
          flat
          v-model="tab"
          >
          <v-tab :key="0">
            DIAMOND<br>PRODUCT_CLASS=0
          </v-tab>
          <v-tab :key="1">
            COLORED DIAMOND<br>PRODUCT_CLASS=0
          </v-tab>
          <v-tab  :key="2">
            GEMSTONE<br>PRODUCT_CLASS=1
          </v-tab>
          <v-tab  :key="3">
            JEWELRY<br>PRODUCT_CLASS=2
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    
    <v-tabs-items v-model="tab" class="mt-2">
        <v-tab-item :key="0">
          <v-row justify="center" dense>

          <!-- 일반 다이아몬드 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMOND_CLASS</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>only used 0</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDTYPE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the detailed type of diamond.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Natural Diamond</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Lab Grown Diamond</td></tr>
            </table>
          </v-col>
          
          <!-- 감정기관 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CERTI_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Product Laboratory Index.<a @click="nav('apiCertiList')">See APi for detailed code.</a>
          </v-col>
          <!-- 쉐입 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Product Shape Index.<a @click="nav('apiShapeList')">See APi for detailed code.</a>
          </v-col>
          <!-- 중량 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Diamond product weight.
          </v-col>
          <!-- 사이즈 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">HEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement HEIGHT height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WIDTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement Width height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DEPTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement DEPTH height x width x depth</v-col>

          <!-- 투명도 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">TRANSPARENCY</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Diamond transparency</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">FL</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">IF</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">VVS1</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">VVS2</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">VS1</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">VS2</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">SI1</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">SI2</td></tr>
              <tr><td width="20%" style="text-align:center">8</td><td width="80%">SI3</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">I1</td></tr>
              <tr><td width="20%" style="text-align:center">10</td><td width="80%">I2</td></tr>
              <tr><td width="20%" style="text-align:center">11</td><td width="80%">I3</td></tr>
            </table>
          </v-col>
          <!-- 형광성 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">FLUORESCENCE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means Fluorescence of diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">None</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Faint</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Faint Slight</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Very Slight</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">Slight</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Medium</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Strong</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">Very Strong</td></tr>
              <tr><td width="20%" style="text-align:center">99</td><td width="80%">N/A</td></tr>
            </table>
          </v-col>
          
          <!-- Cut Grade -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">EVALUATION</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means Cut Grade of Diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
            </table>
          </v-col>

          <!-- Polish Grade -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">POLISH</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the Polish Grade of Diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
            </table>
          </v-col>
          <!-- Symmetry Grade -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SYMMETRY</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the Symmetry Grade of Diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
            </table>
          </v-col>
          
          <!-- 일반다이아몬드 컬러 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">COLOR</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Diamond Color.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">D</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">E</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">F</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">G</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">H</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">I</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">J</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">K</td></tr>
              <tr><td width="20%" style="text-align:center">8</td><td width="80%">L</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">M</td></tr>
              <tr><td width="20%" style="text-align:center">10</td><td width="80%">N</td></tr>
            </table>
          </v-col>

          <!-- 설명 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DESC</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>Product Additional Description</v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item :key="1" class="pa-0 ma-0">
          <v-row justify="center"  dense>
            <!-- 컬러 다이아몬드 -->
            <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMOND_CLASS</v-col>
            <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>only used 1</v-col>
            <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDTYPE</v-col>
            <v-col cols="12" md="8" class="pa-0" align-center="center">
              <table>
                <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the detailed type of diamond.</td></tr>
                <tr><td width="20%" style="text-align:center">0</td><td width="80%">Natural Diamond</td></tr>
                <tr><td width="20%" style="text-align:center">1</td><td width="80%">Lab Grown Diamond</td></tr>
              </table>
            </v-col>

          <!-- 감정기관 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CERTI_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Product Laboratory Index.<a @click="nav('apiCertiList')">See APi for detailed code.</a>
          </v-col>
          <!-- 쉐입 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Product Shape Index.<a @click="nav('apiShapeList')">See APi for detailed code.</a>
          </v-col>
          <!-- 중량 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Diamond product weight.
          </v-col>
          <!-- 사이즈 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">HEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement HEIGHT height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WIDTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement Width height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DEPTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement DEPTH height x width x depth</v-col>

          <!-- 투명도 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">TRANSPARENCY</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Diamond transparency</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">FL</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">IF</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">VVS1</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">VVS2</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">VS1</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">VS2</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">SI1</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">SI2</td></tr>
              <tr><td width="20%" style="text-align:center">8</td><td width="80%">SI3</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">I1</td></tr>
              <tr><td width="20%" style="text-align:center">10</td><td width="80%">I2</td></tr>
              <tr><td width="20%" style="text-align:center">11</td><td width="80%">I3</td></tr>
            </table>
          </v-col>
          <!-- 형광성 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">FLUORESCENCE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means Fluorescence of diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">None</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Faint</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Faint Slight</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Very Slight</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">Slight</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Medium</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Strong</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">Very Strong</td></tr>
              <tr><td width="20%" style="text-align:center">99</td><td width="80%">N/A</td></tr>
            </table>
          </v-col>

          <!-- Polish Grade -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">POLISH</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the Polish Grade of Diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
            </table>
          </v-col>
          <!-- Symmetry Grade -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SYMMETRY</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the Symmetry Grade of diamonds.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
            </table>
          </v-col>            

          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDCOLOR</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the color of the colored diamond.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Red</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Blue</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Pink</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Yellow</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Green</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Brown</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">Black</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">Other</td></tr>
            </table>
          </v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">COLORDISTRI</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>Color distribution used in colored diamonds</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">COLORGRADE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>Color grade used in colored diamonds</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fancy</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Very Light</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Light</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Fancy Light</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">Fancy</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Fancy Intense</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Fancy Vivid</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">Fancy Dark</td></tr>
              <tr><td width="20%" style="text-align:center">8</td><td width="80%">Fancy Deep</td></tr>
              <tr><td width="20%" style="text-align:center">99</td><td width="80%">Other</td></tr>
            </table>
          </v-col>

          <!-- 설명 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DESC</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Additional Description</v-col>
            
          </v-row>
        </v-tab-item>

        <v-tab-item :key="2">
          <v-row justify="center"  dense>
          <!-- 젬스톤 -->

          <!-- 감정기관 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CERTI_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>
            Product Laboratory Index.<a @click="nav('apiCertiList')">See APi for detailed code.</a>
          </v-col>
          
          <!-- 쉐입 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SHAPE_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product Shape Index.</v-col>
          
          <!-- 중량 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Gemstone means product weight.</v-col>
          
          <!-- 사이즈 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">HEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement HEIGHT height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WIDTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement Width height x width x depth</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DEPTH</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Product measurement DEPTH height x width x depth</v-col>

          <!-- 젬스톤 보석 종류 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">JEWELRYKIND_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Gemstone Jewelry Type Index <a @click="nav('apiGemJewelryList')">See APi for detailed code.</a></v-col>
          
          <!-- 식별 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">IDENTIFICATION</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String | <strong>Required</strong><br>Identification value used for Gemstone</v-col>
          
          <!-- 젬스톤 컬러 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">GEMSTONECOLOR</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String | <strong>Required</strong><br>Color for Gemstone</v-col>

          <!-- 설명 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DESC</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Additional Description</v-col>

          </v-row>
        </v-tab-item>

        <v-tab-item :key="3">
          <v-row justify="center"  dense>
          <!-- 주얼리 -->
          
          <!-- 대분류 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCTTYPE_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Jewelry Product Classification Category Index <a @click="nav('apiJewelTypeList')">See APi for detailed code.</a></v-col>
          
          <!-- 소분류 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCTITEM_IDX</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>Jewelry Product Classification Subcategory Index <a @click="nav('apiJewelItemList')">See APi for detailed code.</a></v-col>
          
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDTYPE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means Gemstone type of jewelry.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Natural</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">Lab Grown</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">None</td></tr>
            </table>
          </v-col>

          <!-- 금속함량 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">METALWEIGHT</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the kind of jewelry Metal Content.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">24k</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">22k</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">20k</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">18k</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">14k</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">12k</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">10k</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">999</td></tr>
              <tr><td width="20%" style="text-align:center">8</td><td width="80%">995</td></tr>
              <tr><td width="20%" style="text-align:center">9</td><td width="80%">950</td></tr>
              <tr><td width="20%" style="text-align:center">10</td><td width="80%">900</td></tr>
            </table>
          </v-col>
          
          <!-- 금속종류 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">METALTYPE</v-col>
          <v-col cols="12" md="8" class="pa-0" align-center="center">
            <table>
              <tr><td width="100%" colspan="2">Number | <strong>Required</strong><br>It means the kind of jewelry Metal Type.</td></tr>
              <tr><td width="20%" style="text-align:center">0</td><td width="80%">Yellow Gold</td></tr>
              <tr><td width="20%" style="text-align:center">1</td><td width="80%">White Gold</td></tr>
              <tr><td width="20%" style="text-align:center">2</td><td width="80%">Pink Gold</td></tr>
              <tr><td width="20%" style="text-align:center">3</td><td width="80%">Pure Gold</td></tr>
              <tr><td width="20%" style="text-align:center">4</td><td width="80%">Platinum</td></tr>
              <tr><td width="20%" style="text-align:center">5</td><td width="80%">Silver</td></tr>
              <tr><td width="20%" style="text-align:center">6</td><td width="80%">Titanium</td></tr>
              <tr><td width="20%" style="text-align:center">7</td><td width="80%">Other</td></tr>
            </table>
          </v-col>

          <!-- 금속중량 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number | <strong>Required</strong><br>jewelry refers to metal weight.</v-col>

          <!-- 메인스톤 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MAINSTONE</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>Jewelry Mainstone Name</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MAINWEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number<br>Weight of Jewelry Mainstone</v-col>
          <!-- 서브스톤 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SUBSTONE</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>Jewelry Substone Name</v-col>
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SUBWEIGHT</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Number<br>Weight of Jewelry Subtone</v-col>

          <!-- 설명 -->
          <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DESC</v-col>
          <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Additional Description</v-col>

          </v-row>
        </v-tab-item>

      </v-tabs-items>
        


    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Response Body</h3>
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12" md="11" class="fieldname"><h4>JSON representation</h4></v-col>
      <v-col cols="12" md="11" class="fielddesc pa-0">
        <pre translate="no" style="background:#fafafa">
        {
          "resultCode": 0,
        }          
        </pre>
      </v-col>
      
    </v-row>

    <v-row justify="center" dense class="mb-15">
      <v-col cols="12" md="11">
        <a @click="nav('apiCommon')">Click here for more information on resultCode.</a>
      </v-col>
    </v-row>    


  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({
      tab:0
    }),

    methods: {

      nav(name){
        this.$router.push("/ApiDoc/"+name).catch(()=>{});
      },

    },

    created(){
      //apiProductList
    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

table{
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #eaeaea;
  padding: 10px;
}
.fieldname {
  background: #f1f3f4;
  border: 1px solid #eaeaea;
}
.fielddivider {
  background: #464646;
  border: 1px solid #eaeaea;
  color: white;
}
.fielddesc {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
}

</style>