<!-- 현재 해당 페이지는 사용안함 -->
<template>
  <div class="fullscreen-map">

    <!-- 상점 상세정보 -->
    <shopinfo ref="shopinfo" />

        <GmapMap
            ref="mapRef"
            :center="center"
            :zoom="13"
            @zoom_changed="handleZoomChange"
            
            map-type-id="roadmap"
            class="fullscreen-map"
            >

            <!-- 내위치 -->
            <GmapMarker
                v-if="userLocation"
                :position="userLocation"
                :icon="myIcon"
                :clickable="false"
                :draggable="false"
                />    

            <!-- 상점 위치 :icon="shopIcon" 요청으로 커스텀 아이콘 설정 뺌. 빨간 아이콘 나오게 -->   
            <GmapMarker
                v-for="(item, index) in itemList"
                :key="index"
                :position="{lat: Number(item.LATITUTE), lng: Number(item.LONGITUTE)}"
                @click="openInfoWindow(index)"
                >
                <GmapInfoWindow
                    :opened="infoWindowOpened === index"
                    @closeclick="infoWindowOpened = null"
                    >
                    <div class="d-flex map_text mb-1">
                        <div class="me-auto">{{ item.SHOPNAME }}</div>
                        <div class="custom-close" @click="closeInfoWindow()" >X</div>
                    </div>
                    <v-btn width="100" class="map_text" style="color:white" color="black" small @click="showdetail(item)">상세 정보</v-btn>
              </GmapInfoWindow>    
            </GmapMarker>            
        </GmapMap>        
       
        <div style="position:fixed;left:10px;bottom:60px;">
          <v-btn @click="back" color="black shop_title_mb" style="color:white">BACK</v-btn>
        </div>
  </div>
  
  </template>
  
  <script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
  <script>
    export default {
  
      data: () => ({

        //지도 정보
        ismap:1,
        infoWindowOpened:null,
        ZOOM:13,  //api를 조회한 zoom값
        myIcon: {
            url: require('@/assets/mappin3.png'), // 커스텀 아이콘 URL
            scaledSize: new google.maps.Size(40, 40), // 아이콘 크기 조정
        },

        /* 커스텀 아이콘인데 요청으로 제거함.
        shopIcon: {
            url: require('@/assets/mappin2.png'), // 커스텀 아이콘 URL
            scaledSize: new google.maps.Size(40, 40), // 아이콘 크기 조정
        },
        */
        center: { lat: 37.5665, lng: 126.9780 }, // 기본 중심 위치 (예: 서울 시청)
        userLocation: null, // 사용자 위치 저장
        LATITUTE: null,
        LONGITUTE: null,
        RADIUS: 97.65625,

        itemList: [],
           
      }),
  
      mounted(){
        this.initpage();
      },

      //eslint-disable-next-line no-unused-vars
      beforeRouteLeave(to, from, next){
        if(this.$refs.shopinfo.info_dialog){
          this.$refs.shopinfo.closeinfo();
          next(false);
        }else{
          next();
        }
      },
  
      create(){
        
      },
  
      methods: {

        back(){
          if(window.history.length > 1){
            this.$router.go(-1);
          }else{
            this.$router.push("/Welcome");
          }
        },

        //지도 함수
        openInfoWindow(index) {
            this.infoWindowOpened = index;
        },  
        
        closeInfoWindow() {
            this.infoWindowOpened = null;
        },

        //지도 줌아웃에 따른 재조회 처리
        handleZoomChange(newZoom) {
          //새로 들어온 줌이 더 작으면 (더 축소되면) api 재조회.
          if(newZoom < this.ZOOM ){
            this.RADIUS = (40000/Math.pow(2, newZoom+1)) * 20;  //상점이 많이 없어서 처음부터 많은 범위를 조회해라. 가중치 20줌.
            this.ZOOM = newZoom;
            console.log("new zoom : " + this.ZOOM + " / " + this.RADIUS);
            this.loadpage();
          }
        },

        //위치정보 가져오기
        getNearbyStores() {
            if (navigator.geolocation) {
                console.log("gps 1");
                navigator.geolocation.getCurrentPosition(this.sendLocationToServer);
            } else {
                console.log("gps 2");
                this.$fire({
                    html: "The location information is not available, so it will be inquired to the default location.<br><br>위치 정보가 제공되지 않아 기본 위치로 조회됩니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
                    cancelButtonColor:'#ff0000',
                    showCancelButton: true, 
                    reverseButtons: true,
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                      this.LATITUTE  = 37.5049142;
                      this.LONGITUTE = 127.0049151;
                      this.userLocation = {lat: this.LATITUTE, lng: this.LONGITUTE};
                      this.center = this.userLocation;
                      this.loadpage();
                  }
                });                        
            }
        },        

        async sendLocationToServer(position) {
            
            this.LATITUTE  = position.coords.latitude;
            this.LONGITUTE = position.coords.longitude;

            if(this.LATITUTE == null || this.LATITUTE == undefined || this.LONGITUTE == null || this.LONGITUTE == undefined){
                this.$fire({
                    html: "The location information is not available, so it will be inquired to the default location.<br><br>위치 정보가 제공되지 않아 기본 위치로 조회됩니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
                    cancelButtonColor:'#ff0000',
                    showCancelButton: true, 
                    reverseButtons: true,
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                    }).then(r => {
                        if(r.value){
                            this.LATITUTE  = 37.5049142;
                            this.LONGITUTE = 127.0049151;
                            this.userLocation = {lat: this.LATITUTE, lng: this.LONGITUTE};
                            this.center = this.userLocation;
                            this.loadpage();
                        }
                    });                        
            }else{
                this.userLocation = {lat: this.LATITUTE, lng: this.LONGITUTE};
                this.center = this.userLocation;
                this.loadpage();
            }
        },        
        
        showdetail(item){
            this.$refs.shopinfo.showinfo(item.ACCNT_IDX);
            //this.$router.push({path:"/ShopDetail", query:{code:item.ACCNT_IDX}});
        },
  
        loadpage(){
  
          if(this.LATITUTE == null || this.LONGITUTE == null){
            this.LATITUTE  = 37.5049142;
            this.LONGITUTE = 127.0049151;
          }

            this.$http.post(this.$host+'/GuestFindShopMap',{
                  RADIUS: this.RADIUS
                , LATITUTE: this.LATITUTE
                , LONGITUTE: this.LONGITUTE
            })
            .then((result)=>{
              
              if(result.data.resultCode == 0){
  
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
  
                  const list = result.data.resultData;
                  this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                  list.forEach(element => {
                      this.itemList.push(element); 
                  });
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              if(error.message == 'Request failed with status code 429')
              {
                this.$fire({
                  html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            });
  
        },
  
        initpage(){
  
          //위치정보 조회
          this.getNearbyStores();

        },      
        
  
      },
    
  }
  </script>
  
  <!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
  <style scoped>
  
  .shop_title_mb{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
  }
  .shop_addr_mb{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 13px;
  }

  .map_text{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 12px;
  }
  div ::v-deep .gm-ui-hover-effect {
    display: none !important;   
  }
  .custom-close {
    background: white;
    border: none;
    cursor: pointer;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fullscreen-map {
    width: 100% !important;
    height: 100% !important;
  }
  </style>