<template>


  <v-container fluid class="ma-0 pa-0" style="background:black;min-height:100%;">

    <div :class="$vuetify.breakpoint.xsOnly ? 'imgtopbgmb2' : 'imgtopbg2'"/>

    <div :class="$vuetify.breakpoint.smAndDown ? 'imgtopbgmb' : 'imgtopbg'">
    

    <!-- 로그인 유도 팝업 -->
    <v-row align="center" justify="center">
      <v-dialog  persistent overlay-color="#000000FF"  v-model="login_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">KDGE</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-spacer/>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <div class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >문의나 구매는 회원가입이 필요합니다.<br><span class="dialog_subtitle_eng">Membership registration is for Questions or buying request.</span></div>

                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goJoin">Join 가입하기</v-btn>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-10 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="center" class="ma-0 pa-0">
                    <p class="text-center ma-0 pa-0 dialog_subtitle_kor"
                      :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:22px'"
                      >회원이시면 로그인해주세요<br><span class="dialog_subtitle_eng">If you are a member, please log in.</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goLogin">Login 로그인</v-btn>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <v-spacer/>
          </v-card-text>

          <v-card-actions class="mt-5 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12"> <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="login_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    
    
    <v-row align="center" justify="center" >
      <v-col cols="11" md="11">
        <v-row class="mt-0 mb-10 mt-md-10 mb-md-10" style="display:block;">

          <!-- PC 해상도 -->
          <v-col cols="12" md="6" class="pa-0 mt-10 mt-md-10">
            <v-row class="justify-md-end justify-sm-center justify-center" justify="start">

              <v-col cols="1"/>
              <v-col cols="10" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1"  style="background:rgba(43, 43, 43, 1.0);height:70px">
                <a @click="goEvent">
                  <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                    <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">EVENT</v-col>
                  </v-row>
                  <v-row align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">이벤트, 온라인 옥션</v-col>
                    <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                  </v-row>
                </a>
              </v-col>
              <v-col cols="1" md="3" lg="5"/>

              <!-- 
              <v-col cols="1"/>
              <v-col cols="10" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="gosalereq">
                <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                  <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">Consumer Sales Request</v-col>
                </v-row>
                <v-row align="center" justify="center" class="ma-0 pa-0">
                  <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">소비자 판매 요청</v-col>
                  <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                </v-row>
                </a>
              </v-col>
              <v-col cols="1" md="3" lg="5"/>
              -->
              
              <v-col cols="1"/>
              <v-col cols="10" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="gocraft">
                <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                  <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">Craft order</v-col>
                </v-row>
                <v-row align="center" justify="center" class="ma-0 pa-0">
                  <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">주얼리 세공의뢰</v-col>
                  <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                </v-row>
                </a>
              </v-col>
              <v-col cols="1" md="3" lg="5"/>

              <v-col cols="1"/>
              <v-col cols="10" md="7" lg="6" class="ma-0 pa-0 radius_10 mt-1" style="background: rgba(43, 43, 43, 1.0);height:70px">
                <a @click="goNotice">
                  <v-row align="center" justify="center" class="ma-0 pa-0 mt-2">
                    <v-col cols="10" style="color:white;" align="left" class="pa-0 btn_text_small mb-1">Notice</v-col>
                  </v-row>
                  <v-row align="center" justify="center" class="ma-0 pa-0">
                    <v-col cols="8" style="color:white" align="left" class="pa-0 btn_text_large">뉴스, 공지사항</v-col>
                    <v-col cols="2" class="pa-0" align="right"><v-icon color="white">mdi-arrow-right</v-icon></v-col>
                  </v-row>
                </a>
              </v-col>
              <v-col cols="1" md="4" lg="5"/>

            </v-row>            
          </v-col>


        </v-row>

      </v-col>
    </v-row>




    <!-- </div> -->
    </div>


  </v-container>
</template>
<script>

export default {

    mounted() {
      
    },

    data: () => ({
      nextpage:'',
      login_dialog : false,
      editedItem:null,
    }),

    created(){

      //이벤트 수신 - 라파포트 갱신용
      this.$EventBus.$on('reloadrapa', this.loadpage);

    },

    beforeDestroy(){
      this.$EventBus.$off('reloadrapa');
    },

    beforeRouteLeave(to, from, next){
      console.log("beforeRouteLeave");
      next();
    },

    methods: {


      

      goNotice(){
        this.$router.push({path:"/Notice", query:{stype:0}});
      },


      gocraft(){
        this.PRODUCT_CLASS = 3;
        this.$router.push({path:"/ProductList", query:{code:this.PRODUCT_CLASS, page:1, rtype:1, vivid:0, dtype:0, isquick:0}}); 
      },

      gosalereq(){
        if(this.$session.get("GRADE") >= 1){
          //판매요청으로 이동
          this.$router.push("/UserSaleReq");
        }else{
          //로그인 유도
          this.nextpage = "UserSaleReq";
          this.login_dialog = true;
        }
        
      },

      goLogin(){
        this.$router.push({path:"/Login", query:{next:this.nextpage}});
      },
      
      goJoin(){
        this.$router.push({path:"/Join"});
      },

      goEvent(){
        this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, vivid:0, stype:1}});
      },
 

    },



}
</script>


<style scoped>

.imgtopbg{
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    min-height:1000px;
    height: 100%;
    width: 100%;
}

.imgtopbgmb{
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}


.imgtopbg2{
    background-image: url('https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/main_diamond_ring4_2.png');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0%;
    bottom: 0%;
    max-width: 1080px;
    max-height: 578px;
    top: 50vh;
    width: 60vw;
    height: 578px;
}

/*background-image: url('@/assets/main_diamond_ring3.png');*/
.imgtopbgmb2{
    background-image: url('https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/main_diamond_ring5.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    right: 0;
    bottom: 10px;
    width: 60vw;
    height: 146px;
    max-width: 264px;
    max-height: 146px;
}

.mobilemenupos{
    position: absolute;
    bottom: 0;
}


/*
.imgtopbg{
    background-image: url('@/assets/main_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    min-height:1000px;
    height: 100%;
    width: 100%;
}

.imgtopbgmb{
    background-image: url('@/assets/main_bg_mb.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}
*/

.imgblack{
  opacity: 0.85;
  background-color: black;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background-attachment: fixed;
  min-height: 1000px;
  height: 100%;
  width: 100%;
}


.imgblackmb{
  opacity: 0.85;
  background-color: black;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  background-attachment: fixed;
}

.black_btn_bg{
  background:black;
  height:107px
}
.black_btn_bg:hover{
  background:blue;
  height:107px
}



.main_text{
  font-family: 'Inter' !important;
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  letter-spacing:0px !important;
}

.btn_text_small{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

.btn_text_large{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: -0.05em;
}

.btn_text_mbsmall{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  white-space: nowrap;
}
.btn_text_mblarge{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  letter-spacing: -0.05em;
  white-space: nowrap;
}

.imgscaleup{
  overflow: hidden;
  transform:scale(1.2);
  transition: transform 1s;
}


.dialog_subtitle_kor{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #090808;
  line-height: 26px;
}

.dialog_subtitle_eng{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color:black;
}


</style>