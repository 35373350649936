<template>

  <!-- 거래중인 리스트 -->
  <v-container fluid class="container_bg">

    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />

    <!-- 구매요청 상세정보 -->
    <reqinfo ref="reqinfo" />

    <!-- 프로그레스바 -->
    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>      
        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>    

    <!-- 발송요청정보 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sendreq_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Delivery request Infomation<br>발송요청정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">발송요청정보를 확인하세요</span></p>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Recipient Name&nbsp;<span class="dialog_subtitle_kor">수령자이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">수령주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.ADDRESS" 
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
                  </v-col>
                </v-row>                       

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">요청메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.COMMENT" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit Amount&nbsp;<span class="dialog_subtitle_kor">입금액</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">{{sendreqItem.SEND_PRICE_DP}}</p>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Image&nbsp;<span class="dialog_subtitle_kor">인보이스 이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="sendreqImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;bottom:5px;margin-left:0px;height:40px;width:240px;background-color:#00000080">
                              <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img  style="hegiht:auto" width="240" :src="sendreqImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>
     

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="sendreq_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="sendreq_update">Edit<br>수정</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 발송정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="send_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Shipment information<br>발송정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">발송정보를 확인하세요</p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.COMMENT" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Number&nbsp;<span class="dialog_subtitle_kor">송장번호</span></p>
                  </v-col>
                  <v-col cols="10" md="8" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.INVOICENUMBER" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="2" md="2" class="ma-0 pa-0 pl-1 pl-md-0">
                    <v-btn @click="check_dhl(sendItem.INVOICENUMBER)" color="#4d148c" style="color:#ffffff;font-weight:bold">Fedex</v-btn>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Image&nbsp;<span class="dialog_subtitle_kor">송장이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                      <template v-if="sendImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <img  style="height:auto" width="240" :src="sendImg1" class="image" @click="$viewerApi({images: [sendImg1]})">
                          </div>
                        </v-row>
                      </template>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="send_dialog = false">Close 닫기</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 잔금요청 -->
    <v-row align="center"  justify="center" >

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="next_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Request balance&nbsp;잔금요청</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">상품을 확인하고 문제가 없다면<br>고객에게 잔금을 요청합니다.</p>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="next_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_save">Request 요청</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 잔금입금 정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="balpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Remaining Deposit Information<br>잔금입금정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0" v-if="balpayItem.PRICE_TYPE == 0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(USD)&nbsp;<span class="dialog_subtitle_kor">입금액(달러)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.PRICE_USD_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0" v-if="balpayItem.PRICE_TYPE == 1">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(WON)&nbsp;<span class="dialog_subtitle_kor">입금액(원화)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.PRICE_WON_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="balpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <!-- 잔금 입금 강제처리 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="admin_balpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Waiting for balance payment&nbsp;잔금입금 대기중</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">
                      waiting for the balance deposit.<br>If you enter the name of the depositor and press the Save button, you will be forced to confirm the deposit.<br>
                      <span class="dialog_subtitle_kor">현재 잔금 입금을 대기중입니다.<br>입금자 이름을 입력 후 저장버튼을 누르시면 강제로 계약금 입금확인 처리가 됩니다.</span></p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="admin_bal_name" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="admin_balpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="AdminSendBalPay">강제 입금처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <!-- 강제 거래완료 처리 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="complete_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Transaction completed<br>거래완료 처리</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->
                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng"><br>고객의 상품수령 확인을 대기중입니다.<br>장기간 수령확이 없다면 수령완료 버튼을 눌러 강제로 상품수령을 처리합니다.</p>
                  </v-col>
                </v-row>                  
              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="complete_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_complete">Complete<br>수령완료</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    



    <!-- 제품전달 1 -->
    <v-row align="center"  justify="center" >

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sned_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Product Shipment&nbsp;상품 발송</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->


              <template v-if="sendItem.TRANSPERPRODUCT_IDX == -1">
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:purple">Receiving method&nbsp;<span class="dialog_subtitle_kor" style="color:purple">수령방식</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="sendItem.SHIP_TYPE2" :items="SHIPTYPELIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:purple">Name&nbsp;<span class="dialog_subtitle_kor" style="color:purple">이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:purple">Address&nbsp;<span class="dialog_subtitle_kor" style="color:purple">주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.ADDRESS" disabled
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" style="color:purple">Request Message&nbsp;<span class="dialog_subtitle_kor" style="color:purple">요청메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="sendItem.MESSAGE" color="black" disabled hide-details outlined dense/>
                  </v-col>
                </v-row>                

              </template>

              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Forwarding Type&nbsp;<span class="dialog_subtitle_kor">전달방식</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-select 
                    v-model="sendItem.SHIP_TYPE" :items="SHIPTYPELIST" 
                    dense
                    item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined color="#000000" hide-details class="none_radius" 
                    />
                </v-col>
              </v-row>

              
                <v-row  dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shipping company&nbsp;<span class="dialog_subtitle_kor">택배사</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="sendItem.DELIVERYCOMPANY" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>              
                <v-row  dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Number&nbsp;<span class="dialog_subtitle_kor">송장번호</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="sendItem.INVOICENUMBER" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>              
              

              <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-textarea v-model="sendItem.COMMENT" color="black" hide-details outlined dense/>
                </v-col>
              </v-row>              

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="sned_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_send">{{ sendItem.TRANSPERPRODUCT_IDX > -1 ? "발송정보수정" : "상품발송" }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        

    <!-- 거래 취소 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancellation of transaction<br>거래취소</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">모든 거래를 취소합니다.<br>해당 거래는 거래취소 리스트로 이관됩니다.</p>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="cancel_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancel_save">Cancel Confirm<br>취소실행</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        


    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="4" class="pa-0 pl-3 pl-md-0 pr-2 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>

    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>


    <v-row align="start" class="mt-5 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
          <template v-slot:header.PURCHASE_IDX>
            <a :class="sort_name=='PURCHASE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PURCHASE_IDX')">No.<br>번호</a>
          </template>
          <template v-slot:header.PRODUCT_NUMBER>
            <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
          </template>
          <template v-slot:header.PROV_NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Vendor<br>공급처</label>
          </template>
          <template v-slot:header.QUANTITY>
            <label class="cheader_normal">Quantity<br>수량</label>
          </template>
          <template v-slot:header.SUPPLY_PRICE>
            <label class="cheader_normal">Supply Price<br>공급가</label>
          </template>
          <template v-slot:header.PRICE_DP_USD>
            <label class="cheader_normal">Price<br>판매가</label>
          </template>
          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal" style="text-decoration: underline;">State<br>상태</label>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>구매일시</a>
          </template>
          <template v-slot:header.BNAME>
            <label class="cheader_normal" style="text-decoration: underline;">Name<br>이름</label>
          </template>
          <template v-slot:header.NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Buyer<br>구매자</label>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">processing<br>요청처리</label>
          </template>
          <template v-slot:header.cancel>
            <label class="cheader_normal">Cancel<br>취소</label>
          </template>
          
          <template v-slot:item.PURCHASE_IDX="{ item }">  
            <a @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{item.PURCHASE_IDX}}</a>
          </template>
          <template v-slot:item.PRODUCT_NUMBER="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
          </template>
          <template v-slot:item.PROV_NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.PROV_IDX)" >{{item.PROV_NAME}}</a>
          </template>
          <template v-slot:item.SHIP_NAME="{ item }">  
            <label>{{item.SHIP_NAME}}</label>
          </template>

          <template v-slot:item.SUPPLY_PRICE="{ item }">  
            <label>{{'₩ '+item.SUPPLY_PRICE_WON_DP}}</label><br>
            <label>{{'$ '+item.SUPPLY_PRICE_USD_DP}}</label>
          </template>

          <template v-slot:item.NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.NAME}}</a>
          </template>

          <template v-slot:item.PRICE_DP_USD="{ item }">  
            <!-- <div>{{ item.PRICE_TYPE == 0 ? '$ '+item.PRICE_DP_USD +' USD': '₩ '+item.PRICE_DP_WON +' 원' }}</div> -->
            <label>{{'₩ '+item.PRICE_DP_WON}}</label><br>
            <label>{{'$ '+item.PRICE_DP_USD}}</label>
          </template>

          <template v-slot:item.PURCHASE_STATENAME="{ item }">  
            <template v-if="item.PURCHASE_STATE == 6">
              <a @click="loadsendreq(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 8">
              <a @click="loadsend(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 10">
              <a @click="edit_item(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 11">
              <a @click="loadbalpay(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 20">
              <a @click="loadtransper(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
          </template>

          <template v-slot:item.actions="{ item }">  
              <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  

          <template v-slot:item.cancel="{ item }">  
              <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
          </template>  

        </v-data-table>
      </v-col>
    </v-row>

    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
export default {
    components:{
      VueCropper,
    },

    mounted() {

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',

      next_dialog:false,
      cancel_dialog:false,
      editedItem: {
      },

      sned_dialog:false,
      sendItem:{},
      SHIPTYPELIST: [
          {name:'Direct delivery from KDGE./본사직접배송',          value:0}
        , {name:'Delivery service./택배',                          value:1}
        , {name:'Visit a designate retailer to receive the products./지정소매상',  value:2}
        , {name:'Visit KDGE to receive the products./본사방문수령',                value:3}
      ],

      sendreq_dialog:false,
      sendreqItem:{},
      sendreqImg1:"",
      uploadFile1:[],

      progress_value:0,
      progress_dialog:false,

      crop_dialog:false,
      photo_index:0,
      imgSrc:'',
      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },     

      send_dialog:false,
      sendItem:{},
      sendImg1:"",

      //잔금 입금 정보
      balpayItem:{},
      balpay_dialog:false,

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '수량', value: 'QUANTITY', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '공급가', value: 'SUPPLY_PRICE', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '판매가', value: 'PRICE_DP_USD', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '이름', value: 'SHIP_NAME', sortable: false, align:'center' , width:'120px', class:"cheader_normal"},
        {text: '구매자', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '처리', value: 'actions', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '삭제', value: 'cancel', sortable: false, align:'center', width:'60px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

      //잔금 강제 처리
      admin_balpay_dialog:false,
      admin_bal_name:"",
      complete_dialog: false,

    }),

    created(){

    },

    methods: {

      check_dhl(tracknumber){

        window.open("https://www.fedex.com/fedextrack/?trknbr="+tracknumber, "_blank");

      },

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      
      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminTrading", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/AdminTradingList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },



      loadsendreq(item){

        //발송요청정보 가져오기
        this.$http.post(this.$host+'/AdminSendReqDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              
              if(result.data.resultData.length > 0){
                this.sendreqItem = result.data.resultData[0];
                this.sendreqImg1 = this.sendreqItem.INVOICE_IMG;
                this.sendreq_dialog = true;
              }else{
                this.$fire({
                  html: "Shipment request information is missing.<br><br>발송 요청 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.sendreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },


      loadsend(item){

        //발송정보 가져오기
        this.$http.post(this.$host+'/AdminSendDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              
              if(result.data.resultData.length > 0){
                this.sendItem = result.data.resultData[0];
                this.sendImg1 = this.sendItem.COURIER_IMG;
                this.send_dialog = true;
              }else{
                this.$fire({
                  html: "Shipment information is missing.<br><br>발송 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.sendreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //발송요청정보 수정
      async sendreq_update(){

        if(this.isNullCheck(this.sendreqItem.NAME)){
          this.$fire({
            html: "Please enter recipient name<br><br>수령자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        if(this.isNullCheck(this.sendreqItem.ADDRESS)){
          this.$fire({
            html: "Please enter your destination address<br><br>수령지 주소를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 

        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){
            
          if(this.isNullCheck(this.sendreqItem.INVOICE_IMG)){
            //기존에 등록된 이미지가 있으면 패스
            this.$fire({
              html: "Please register the invoice image<br><br>인보이스 이미지를 등록하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

        }else{

          this.progress_dialog = true;

          //대표 이미지 등록
          let upload_cb = await this.uploadStorage("product", this.sendreqItem.PROV_IDX+"/"+Date.now().toString()+".jpg", this.uploadFile1);
          if(upload_cb == null){
            this.progress_dialog = false;
            this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
            return;
          } 
          this.uploadFile1 = [];

          if(!this.isNullCheck(this.editedItem.INVOICE_IMG_REF)){
            try{
              await this.deletefile(this.editedItem.INVOICE_IMG_REF);
            }catch(derror){
            }
          }
          this.sendreqItem.INVOICE_IMG_REF = upload_cb.resultRef;

        }

        this.$http.post(this.$host+'/AdminSendReqUpdate', {
            SENDREQUEST_IDX: this.sendreqItem.SENDREQUEST_IDX
          , NAME:this.sendreqItem.NAME == null || this.sendreqItem.NAME == undefined ? '' : escape(this.sendreqItem.NAME)
          , ADDRESS:this.sendreqItem.ADDRESS == null || this.sendreqItem.ADDRESS == undefined ? '' : escape(this.sendreqItem.ADDRESS)
          , COMMENT:this.sendreqItem.COMMENT == null || this.sendreqItem.COMMENT == undefined ? '' : escape(this.sendreqItem.COMMENT)
          , INVOICE_IMG: this.sendreqItem.INVOICE_IMG_REF
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.sendreq_dialog = false;
          this.progress_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
              html: "Modification failed<br><br>수정 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.sendreq_dialog = false;
          this.progress_dialog = false;
          this.$fire({
              html: "Modification failed<br><br>수정 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

        });

      },


      //잔금 요청
      next_save(){

        this.$http.post(this.$host+'/AdminBalPayRequest', {
            PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , PROV_IDX: this.editedItem.PROV_IDX
          , ACCNT: this.editedItem.ACCNT
          , PROV_ACCNT: this.editedItem.PROV_ACCNT
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.next_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.next_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },

      //소비자에게 제품 발송
      next_send(){

        //택배 방식
        if(this.sendItem.SHIP_TYPE == 1){
          const checkcompany = cmvalidation.checkValReg(this.sendItem.DELIVERYCOMPANY, null, -1, 50);
          if(checkcompany == 1){
            this.$fire({
              html: "Enter delivery company<br><br>택배사를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkcompany == 4){
            this.$fire({
              html: "Please enter the name of the delivery company in 50 characters or less<br><br>택배사는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }        
          
          const checknumber = cmvalidation.checkValReg(this.sendItem.INVOICENUMBER, null, -1, 50);
          if(checknumber == 1){
            this.$fire({
              html: "Please enter the invoice number<br><br>송장번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checknumber == 4){
            this.$fire({
              html: "Please enter the invoice number in 50 characters or less<br><br>송장번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checkcomment = cmvalidation.checkValReg(this.sendItem.COMMENT, null, -1, 100);
          if(checkcomment == 4){
            this.$fire({
              html: "Please enter request message in 100 characters or less<br><br>요청메시지는 100자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

        }

          const checkship = cmvalidation.checkValReg(this.sendItem.SHIP_TYPE, null, -1, -1);
          if(checkship == 1){
            this.$fire({
              html: "Please select a Forwarding Type<br><br>전달방식을 선택하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


        if(this.sendItem.TRANSPERPRODUCT_IDX == -1){

          this.$http.post(this.$host+'/AdminTransperProduct', {
              PRODUCT_IDX: this.editedItem.PRODUCT_IDX
            , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
            , PROV_IDX: this.editedItem.PROV_IDX
            , ACCNT: this.editedItem.ACCNT
            , SHIP_TYPE:this.sendItem.SHIP_TYPE
            , DELIVERYCOMPANY: this.sendItem.DELIVERYCOMPANY == null || this.sendItem.DELIVERYCOMPANY == undefined ? '' : escape(this.sendItem.DELIVERYCOMPANY)
            , INVOICENUMBER: this.sendItem.INVOICENUMBER == null || this.sendItem.INVOICENUMBER == undefined ? '' : escape(this.sendItem.INVOICENUMBER)
            , COMMENT: this.sendItem.COMMENT == null || this.sendItem.COMMENT == undefined ? '' : escape(this.sendItem.COMMENT)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.sned_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Shipment processing is complete.<br><br>발송 처리가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Processing failed<br><br>처리 실패",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.sned_dialog = false;
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          });
          

        }else{

          this.$http.post(this.$host+'/AdminTransperProductUpdate', {
              TRANSPERPRODUCT_IDX: this.sendItem.TRANSPERPRODUCT_IDX
            , PROV_IDX: this.editedItem.PROV_IDX
            , SHIP_TYPE:this.sendItem.SHIP_TYPE
            , DELIVERYCOMPANY: this.sendItem.DELIVERYCOMPANY == null || this.sendItem.DELIVERYCOMPANY == undefined ? '' : escape(this.sendItem.DELIVERYCOMPANY)
            , INVOICENUMBER: this.sendItem.INVOICENUMBER == null || this.sendItem.INVOICENUMBER == undefined ? '' : escape(this.sendItem.INVOICENUMBER)
            , COMMENT: this.sendItem.COMMENT == null || this.sendItem.COMMENT == undefined ? '' : escape(this.sendItem.COMMENT)
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.sned_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                this.search_run();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
              html: "Modification failed<br><br>수정 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.sned_dialog = false;
            this.$fire({
              html: "Modification failed<br><br>수정 실패",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          });

        }


      },


      //잔금입금정보 가져오기
      loadbalpay(item){

        this.$http.post(this.$host+'/AdminBalPayDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no balance deposit information.<br><br>잔금 입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.balpayItem = result.data.resultData[0];
                this.balpay_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.downpay_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      


      //제품전달정보 가져오기
      loadtransper(item){

        this.$http.post(this.$host+'/AdminTransperDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no balance deposit information.<br><br>잔금 입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.sendItem = result.data.resultData[0];
                this.sned_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.sned_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      cancel_save(){

        this.$http.post(this.$host+'/AdminCancelPurchaseForce', {
          PURCHASE_IDX: this.editedItem.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.cancel_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Cancellation completed.<br><br>취소가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.cancel_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },

      //아이템 수정
      edit_item(item){
        
        this.editedItem = Object.assign({}, item);
        
        if(item.PURCHASE_STATE == 6){
          this.$fire({
            html: "requested the seller to ship the product.<br><br>공급자에게 상품 발송을 요청했습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(item.PURCHASE_STATE == 8){
          this.next_dialog = true;
          return;
        }else if(item.PURCHASE_STATE == 10){
          //잔금 강제처리 팝업 띄우기
          this.admin_balpay_dialog = true;
          return;
          /*
          this.$fire({
            html: "Waiting for the buyer to make the balance.<br><br>구매자의 잔금입금을 대기중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
          */
        }else if(item.PURCHASE_STATE == 11){
          this.sendItem.TRANSPERPRODUCT_IDX = -1;
          this.sendItem.SHIP_TYPE2 = this.editedItem.SHIP_TYPE;
          this.sendItem.SHIP_TYPE  = 0;
          this.sendItem.NAME       = this.editedItem.SHIP_NAME;
          this.sendItem.ADDRESS    = this.editedItem.ADDRESS;
          this.sendItem.MESSAGE    = this.editedItem.MESSAGE;
          this.sned_dialog = true;
          return;
        }else if(item.PURCHASE_STATE == 20){
          this.complete_dialog = true;
          /*
          this.$fire({
            html: "The product is being delivered to the buyer.<br><br>제품을 구매자에게 전달중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          */
          return;
        }

        
        
      },

      //아이템 삭제
      delete_item(item){

        this.$fire({
          html: "Are you sure you want to abort the transaction and force it to cancel?<br><br>해당 거래를 중단하고 강제로 취소하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

          }
        });

      },



      //잔금 입금 강제처리
      AdminSendBalPay(){
       const checkname = cmvalidation.checkValReg(this.admin_bal_name, null, -1, 50);
       if(checkname == 1){
          this.$fire({
            html: "Please enter the name of the depositor<br><br>입금자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }else if(checkname == 4){
          this.$fire({
            html: "Please enter the name of the depositor in up to 50 characters<br><br>입금자 이름은 50자 이내로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }

        this.$http.post(this.$host+'/AdminSendBalPay', {
            PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , NAME: escape(this.admin_down_name)
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.downpay_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });

          }else{
            this.$fire({
              html: "Request failed<br><br>요청 실패 " + result.data.resultMsg,
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            this.search_run();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.downpay_dialog = false;
          this.$fire({
          html: "Request failed<br><br>요청 실패",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
        });

      },

      //강제 수령완료 처리
      next_complete(){

        this.$http.post(this.$host+'/AdminTrandeComplete', {
            PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , ACCNT: this.editedItem.ACCNT
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.complete_dialog = false;
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The transaction has been completed.<br><br>거래가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();
              
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패 " + result.data.resultMsg,
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
            });
            this.search_run();
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.complete_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      





      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      




      showImage(index){
        var img;
        if(index == 0){
          img = [this.sendreqImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.sendreqImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },

      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile1 = [];
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },

      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.sendreqImg1 = data; //이미지뷰 미리보기용
            }

          });

        });
      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },

      postcodetoAddr(addr){
        this.sendreqItem.ADDRESS = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);

            }
        }).open();

      },      
      


    },



}
</script>

<style scoped>

</style>