<template>
  <v-container fluid class="pa-0 ma-0" style="background:white;min-height:100%">
    
    <v-row class="pt-10 pt-md-15 inter_font" justify="center"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:20px'"
      >
      <v-col cols="11" md="5" offset-md="2" >
        <div class="inter_font" style="font-weight:bold"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
          >kDiaGem</div>
        <div
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:30px'"
          >Korea Diamond Gem Exchange</div>
        <div class="hidden-md-and-up"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:30px'"
          >한국다이아몬드보석거래소</div><br>
        <div>(주)KDGE</div>
        <div>KDGE Co.,Ltd</div>
        <div>서울특별시 종로구 돈화문로5가길 1, 피카디리플러스 4층</div>
        <div>4F, Piccadilly Plus, DonHwaMun-ro 5-ga-gil 1,<br>JongNo-gu, Seoul, Republic of KOREA</div>
        <div>Tel: +82-2-765-0701 Fax: +82-2-3675-0702</div>
        <div>www.kdiagem.com</div>
        <div>kdge2030@gmail.com</div>
      </v-col>
      <v-col class="hidden-sm-and-down mt-14" md="5">
        <div 
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:30px'"
          >한국다이아몬드보석거래소</div>
      </v-col>
    </v-row>

    <v-row class="pt-md-15 pt-10">
      <v-col cols="12" class="pt-md-10 text-center">
        <div :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'" class="notosanskr_font text_black_bold">kDiaGem 한국다이아몬드보석거래소</div>
        <div :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'" class="inter_font text_black_bold">Korea Diamond Gem Exchange</div>
      </v-col>
    </v-row>

    <v-row class="pt-md-15 pt-10 notoserif_font">
      <v-col cols="12" align="center">
        <div
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >kDiaGem App 소개</div>
        <div style="width:50px" class="black_line_mb"/>
        <div
          class="pt-3 pt-md-5"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >About kDiaGem App</div>
      </v-col>
    </v-row>

    <v-row justify="center" 
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:13px' : 'font-size:20px'">
      <v-col cols="11" md="10" class="notosanskr_font">
        <div>kDiaGem &lt;한국다이아몬드보석거래소&gt;는 보석 분야 최초로 공유경제 개념을 도입한 플랫폼 운영 기업입니다. kDiaGem App에서는 기업이나 개인 모두, 누구나 회원가입 없이 전 세계의 다이아몬드, 보석 및 주얼리를 검색할 수 있습니다. 국내외 모든 보석 업자들은 보유한 재고를 전혀 수수료 없이 kDiaGem App에 재고 등록하여 공유 판매할 수 있습니다. 또한 kDiaGem App에서는 공급자와 구매자(도소매업자와 소비자 포함)가 직거래로 인해 발생할 수 있는 구매자의 피해 또는 공급자의 피해를 막기 위해 등록된 모든 재고의 거래는 KDGE 본사를 통해서 거래가 이루어집니다. 따라서 구매자는 kDiaGem App을 통해 환매나 교환이 보장되는 좋은 품질의 다이아몬드, 보석 및 주얼리를 안심하고 구매할 수 있습니다. 또한 kDiaGem App은 판매가를 낮추기 위해 불필요한 유통 구조를 합리적으로 개선하였습니다. 따라서 구매자는 해외 유명 브랜드에서 판매하는 가격의 30%~40%에 다이아몬드와 보석을 구매할 수 있습니다.</div>
      </v-col>
      <v-col cols="11" md="10" class="inter_font">
        <div>kDiaGem &lt;Korea Diamond Gem Exchange&gt; is a platform operating company that introduced the concept of sharing economy for the first time in the jewelry field. In the kDiaGem App, anyone, both businesses and individuals, can search for diamonds, gemstones and jewelry from around the world without registering. All domestic and foreign jewelers can share and sell their stocks by registering them on the kDiaGem App without any fees. In addition, in kDiaGem App, all registered stock transactions are conducted through KDGE headquarters to prevent damage to buyers or suppliers that may occur due to direct transactions between suppliers and buyers (including wholesalers and retailers and consumers). Therefore, buyers can rest assured to purchase quality diamonds, gemstones and jewelry with guaranteed redemption or exchange through the kDiaGem App. In addition, kDiaGem App rationally improved the unnecessary distribution structure to lower the selling price. Therefore, buyers can purchase diamonds and gemstones at 30% to 40% of the selling price of famous overseas brands.</div>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-10 mt-md-15 pt-md-10"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:13px' : 'font-size:20px'">
      <v-col cols="11" md="7" class="notosanskr_font text-center text_black">
        <div>천연 다이아몬드는 골드와 함께 제2의 화폐이며 미래를 대비한 안정적이고 확실한 최고의 투자가치 상품입니다. VIVID DIAMOND는 최고의 품질로 미래 자산 가치를 더 합니다.</div>
      </v-col>
      <v-col cols="11" md="7" class="inter_font text-center text_black">
        <div>Natural diamonds, along with gold, are the second currency and are the most stable and reliable investment product for the future. VIVID DIAMOND adds value to future assets with the highest quality</div>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" class="pt-md-10">
        <v-img :aspect-ratio="1920/900" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_img1.jpg" class="pt-3">
        </v-img>    
      </v-col>
    </v-row>

    <v-row class="pt-md-15 pt-10 notoserif_font pb-md-10 pb-5">
      <v-col cols="12" align="center">
        <div
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >About The CEO</div>
        <div style="width:50px" class="black_line_mb"/>
        <div
          class="pt-3 pt-md-5"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >CEO 소개</div>
      </v-col>
    </v-row>


    <v-row style="background:black" justify="center" class="inter_font pt-10 pt-md-15 pb-10 pb-md-15"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:20px'"
      >
      <v-col cols="11" md="5" offset-md="2" style="color:white">
        <div>PRESIDENT KIM, JONG MOK of KDGE Co., Ltd.</div><br>
        <div>MAESTRO of Korean Jewelry Craftsmanship</div>
        <div>PRESIDENT of KIM JONG MOK JEWELRY</div>
        <div>PRESIDENT of Maestro Jewelry Vocational College</div>
        <div>CHAIRMAN of Korea Jewelry Retailers Association</div>
        <div>Served as CHAIRMAN of Korea Jewelry Industry Promotion Foundation</div>
        <div>BRONZE MEDAL of 26th International Skills Olympic Jewelry</div>
        <div>Awarded the Republic of Korea Government Medal</div>
      </v-col>
      <v-col cols="11" md="4" offset-md="1" style="color:white">
        <div>대표이사 김 종 목</div>
        <div>대한민국 귀금속세공명장</div>
        <div>(주)KDGE / KDGE Co., Ltd. 대표이사</div>
        <div>김종목주얼리 대표</div>
      </v-col>
    </v-row>


    <v-row justify="center" class="pt-5 pt-md-10">
      <v-col cols="11" md="10" offset-md="2">
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-img :aspect-ratio="613/845" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_ceo1.png" class="pt-3">
            </v-img>    
          </v-col>
          <v-col cols="12" md="4">
            <v-img :aspect-ratio="613/845" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/about-ceo-profile.png" class="pt-3">
            </v-img>    
          </v-col>
          <v-col cols="12" md="4">
            <v-img :aspect-ratio="613/845" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_ceo2.png" class="pt-3">
            </v-img>    
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row justify="center" class="pt-5 pt-md-10">
        <v-col cols="11" md="10" offset-md="2">
            <div class="black_line"/>
        </v-col>
    </v-row>

    <v-row justify="center" class="notosanskr_font pt-5 pt-md-10"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
      >
      <v-col cols="11" md="5" offset-md="2">
        <h2>대표이사 김종목</h2>
        <p>대한민국 귀금속세공 명장</p>
        <p>MJC보석직업전문학교 이사장</p>
        <p>한국귀금속판매업중앙회 회장</p>
        <p>1981년 제26회 미국 국제기능올림픽대회 동메달</p>
        <p>1990년 명장선발 전국대회 금메달</p>
        <p>1981년 석탑산업훈장</p>
        <p>2013년 대통령표창</p>
      </v-col>
      <v-col cols="11" md="5">
        <h3>CEO Kim Jong-mok</h3>
        <p>Maestro of Korean jewelry craftsmanship</p>
        <p>President of MJC Jewelery Vocational College</p>
        <p>Chairman of Korea Jewelry Retail Industry Association</p>
        <p>Bronze medal at the 26th US International Skills Olympics</p>
        <p>Awarded a gold medal in the National Skills Competition</p>
        <p>Awarded the Stone Tower Order of Industrial Service Merit</p>
        <p>Presidential Citation</p>
      </v-col>

      <!-- 
      <v-col cols="11" md="5" offset-md="2">
        <h2>대표이사 김종목</h2>
        <p>1990 - Master of Precious Metals, Republic of Korea<br>1990년 대한민국 귀금속세공 명장</p>
        <p>1981 - Found Lucky Jewelry Co., Ltd.<br>1981년 럭키보석 주식회사 창업</p>
        <p>1997 - Chairman of MJC Jewelry Vocational School<br>1997년 MJC보석직업전문학교 이사장</p>
        <p>2015 - Chairman of the Korea Jewelry Industry Promotion Foundation<br>2015년 (재)한국주얼리산업진흥재단 이사장 </p>
        <p>2021 - Chairman of the Korea National Metal Retail Federation<br>2021년 (사)한국전국귀금속소매상중앙회 회장</p>
        <p>2014 - Chairman of the Korea Jewelry Association, the 9th and 10th<br>2014년 제9대. 제10대 (사)한국귀금속보석단체장협의회 회장 역임</p>
        <p>1994 - President of the 2nd Korea Jewelry Design Association<br>1994년 제2대 한국귀금속보석디자인협회 회장 역임</p>
        <p>1990 - Judge and Examiner of International and National Skills Competitions<br>11990년 국제 및 전국기능경기대회 심사위원 및 출제위원 역임</p>
        <p>2007 - served as a consultant for Vision<br>2007년 서울특별시 비젼 </p>
        <p>2020 Korean Traditional Manufacturing Division<br>2020 전통제조분과 자문위원 역임</p>
        <p>2008 - Served as honorary ambassador of Arisu, Seoul Metropolitan Government<br>2008년 서울특별시 아리수 명예 홍보대사 역임</p>
        <p>Qualification of Class 3 Professional Competency Development Training Teacher<br>직업능력개발훈련교사 3급 자격</p>
        <p>Qualification of MV Korea Jewelry Evaluation Company<br>MV 코리아 주얼리평가사 자격</p>
        <p>Qualification of Jinju Specialist at Jinju Science Institute of Japan<br>일본 진주과학원 진주전문가 자격</p>
        <p>Qualification as a first-class human resource development<br>인적자원개발사 1급 자격</p>
        <p>Qualification of Jewelry Industrial Technician<br>주얼리 산업기능사 자격</p>
      </v-col>
      <v-col cols="11" md="5">
        <h3>Awarded the National Medal and International Skills Olympic<br>국가 훈장 및 포상과 기능올림픽대회 수상</h3><br>
        <p>1981 - Bronze Medal in Jewelry at the 26th Atlanta International Skills Olympic Games<br>1981년 제26회 미국 아틀란타 국제기능올림픽대회 금은세공 동메달</p>
        <p>1990 - Gold Medal in Jewelry at Maestro National Skills Competition<br>1990년 명장부 전국 기능경기대회 금은세공 금메달</p>
        <p>1990 - Gold Medal in Jewelry at the Regional Skills Competition<br>1990년 명장부 지방 기능경기대회 금은세공 금메달</p>
        <p>1994 - 600-year-old Proud Seoul Citizen Award<br>1994년 정도 600년 기념 자랑스런 서울시민 600인상</p>
        <p>1981 - Stone Tower Order of Industrial Service Merit<br>1981년 석탑산업훈장</p>
        <p>2004 - Presidential Commendation<br>2004년 대통령 표창</p>
        <p>2013 - Presidential Commendation<br>2013년 대통령 표창</p>
        <p>2008 - Commendation of the Minister of Labor<br>2008년 노동부 장관 표창</p>
      </v-col>
      -->

    </v-row>

    <v-row justify="center" class="notosanskr_font"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:16px'"
      >
        <v-col cols="11" md="5" offset-md="2">
            <h3>Appearance on Broadcasting / Press Release<br>방송출연 / 언론보도</h3>
            <p>EBS Master of this Era<br>EBS 이시대의 명장</p>
            <p>EBS Prediction of success, into the Profession<br>EBS 성공예감 직업속으로 </p>
            <p>KBS Morning of the World<br>KBS 세상의 아침</p>
            <p>KBS Job Observatory<br>KBS 일자리전망대</p>
            <p>The Chosun Ilbo, “Reading the World by Moon Gap-sik<br>”조선일보, “문갑식의 세상읽기”</p>
            <p>The Ministry of Knowledge Economy, 지식경제부,<br>"Kim Jong-mok, the Master of the Republic of Korea" “대한민국 명장 김종목”</p>
            <p>Seoul Economic | Naver News<br>서울경제 | 네이버 뉴스 </p>
            <p>Seoul Economic Daily<br>서울경제신문</p>
            <p>YTN LIFE: A Master of Life<br>YTN 라이프 인생고수</p>
        </v-col>                
        <v-col cols="11" md="5">
            <h3>Exhibition 전시 활동</h3>
            <p>Shinsegae Department Store Museum of Art<br>신세계백화점 미술관</p>
            <p>Walkerhill Museum of Art<br>워커힐 미술관</p>
            <p>COEX Master Exhibition<br>코엑스 명장전시</p>
            <p>Tokyo Exhibition Hall<br>도쿄 전시장</p>
            <p>Se-jong Center for the Performing Arts<br>세종문화회관</p>
            <p>Gong-pyeong Art Hall<br>공평아트홀</p>
        </v-col>
    </v-row>
    


    <v-row style="background:#f1f1f1" class="pt-md-15 pt-10 notoserif_font pb-md-10 pb-5">
      <v-col cols="12" align="center">
        <div
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >Appearance on Broadcasting</div>
        <div style="width:50px" class="black_line_mb"/>
        <div
          class="pt-3 pt-md-5"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >방송 출연</div>
      </v-col>
    </v-row>


    <v-row style="background:#f1f1f1" justify="center">
        <v-col cols="11" md="10" lg="8">
            <v-row>
                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad1.png">
                    </v-img>    
                    <div>EBS Masters of this era,</div>
                    <div>YTN Life Master, etc.</div>
                    <div>Appeared on many TV shows.</div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad2.png">
                    </v-img>    
                </v-col>

                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad3.png">
                    </v-img>    
                    <div>EBS 이시대의 명장, YTN 인생고수 등</div>
                    <div>다수 방송 출연</div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad4.png">
                    </v-img>    
                    <div>Popular Drama</div>
                    <div>Stairway to Heaven Protagonists</div>
                    <div>Kwon Sang-Woo and Choi Ji-Woo</div>
                    <div>Sponsored</div>
                    <div>the Ring Design and Production</div>
                </v-col>

                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad5.png">
                    </v-img>    
                </v-col>

                <v-col cols="12" md="4">
                    <v-img :aspect-ratio="472/510" 
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_broad6.png">
                    </v-img>    
                    <div>인기 드라마</div>
                    <div>천국의 계단 주인공 권상우, 최지우</div>
                    <div>반지 디자인 제작 협찬</div>
                </v-col>

        </v-row>
      </v-col>
    </v-row>


    <v-row class="pt-md-15 pt-10 notoserif_font pb-md-10 pb-5">
      <v-col cols="12" align="center">
        <div
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >VIVID DIAMOND</div>
        <div style="width:50px" class="black_line_mb"/>
        <div
          class="pt-3 pt-md-5"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >가치를 담은 프리미엄 브랜드</div>
      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12">
        <v-img :aspect-ratio="1920/700" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_img2_2.jpg" class="pt-3">
        </v-img>    
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-15"
      :style="$vuetify.breakpoint.smAndDown ? 'font-size:13px' : 'font-size:20px'">
      <v-col cols="11" md="10" class="notosanskr_font">
        <div>VIVID DIAMOND는 기본적으로 GIA Certificate를 포함하고 있으며, KDGE의 엄격히 세분화된 감정 기준을 부합하여야만 VIVID DIAMOND Certificate를 발행합니다. 따라서 VIVID DIAMOND에는 구매하시면 전 세계 어디에서나 그 가치를 인정받을 수 있도록 총 2개의 Certificate를 첨부합니다.</div>
      </v-col>
      <v-col cols="11" md="10" class="inter_font">
        <div>VIVID DIAMOND basically includes a GIA Certificate, and a VIVID DIAMOND certificate is issued only when it meets the strict and detailed evaluation standards of KDGE. Therefore, when you purchase a VIVID DIAMOND, a total of 2 certificates are attached so that its value can be recognized anywhere in the world.</div>
      </v-col>
    </v-row>

    <v-row class="mt-5 mt-md-15" justify="center" style="background:#f1f1f1">

        <v-col cols="11" md="10" lg="8" class="pt-md-15 pt-5">

            <v-row>
                <v-col cols="12" align="center">
                    <v-img :aspect-ratio="500/166" class="ml-md-0" width="200"
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/vividlogo.png">
                    </v-img>    
                </v-col>

                <!-- 
                <v-col cols="12" md="6" align="center" class="hidden-sm-and-down">
                      <div
                        class="notoserif_font" style="font-weight:bold"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:24px'"
                        >K S</div>
                      <div
                        class="notoserif_font"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                        >Korean Industrial standards</div>
                </v-col>
                -->
            </v-row>

            <v-row>
                <v-col cols="12" align="center">
                    <v-img :aspect-ratio="910/699" class="ml-md-0"
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_certi1_1.jpg">
                    </v-img>    
                </v-col>
                <!-- 
                <v-col cols="12" md="6" align="center" class="hidden-md-and-up">
                      <div
                        class="notoserif_font" style="font-weight:bold"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:24px'"
                        >K S</div>
                      <div
                        class="notoserif_font"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                        >Korean Industrial standards</div>
                </v-col>
                <v-col cols="12" md="6" align="center">
                    <v-img :aspect-ratio="910/699" class="ml-4 ml-md-0"
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_certi2.png">
                    </v-img>    
                </v-col>
                -->
            </v-row>

            <v-row justify="center" class="pt-md-10 pb-md-15 pt-5 pb-5">
                <v-col cols="12" md="4" class="notoserif_font" align="center">
                    <v-btn 
                      :x-large="$vuetify.breakpoint.smAndUp"
                      :large="$vuetify.breakpoint.smAndDown" @click="govivid"
                      height="50" color="black" style="color:white">VIVID DIAMOND 자세히 보러가기</v-btn>
                </v-col>
            </v-row>

      </v-col>

    </v-row>



    <v-row class="pt-md-15 pt-10 notoserif_font pb-md-10 pb-5">
      <v-col cols="12" align="center">
        <div class="notoserif_font"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >Trademark Registration</div>
        <div class="notoserif_font"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
          >Certificate form Design Registration</div>
        <div style="width:50px" class="black_line_mb"/>
        <div class="mt-3 mt-md-5 notosanskr_font" 
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:24px'"
          >상표등록 및 감정 양식 디자인 등록</div>
      </v-col>
    </v-row>

    <v-row justify="center">

        <v-col cols="11" md="10" lg="8">
            <v-row>
                <v-col cols="12" md="6">
                    <v-img :aspect-ratio="589/790" class="ml-4 ml-md-0"
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_certi4.png">
                    </v-img>    
                </v-col>
                <v-col cols="12" md="6">
                    <v-img :aspect-ratio="589/790" class="ml-4 ml-md-0"
                        src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_certi5.png">
                    </v-img>    
                </v-col>
            </v-row>
      </v-col>
    </v-row>


    <v-row justify="center" class="pt-10 pt-md-15">
        <v-col cols="12">
            <v-img :aspect-ratio="1920/499" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_bottom.png">
                <v-row style="height:100%;color:white" align="center" justify="center">
                    <v-col cols="11" align="center" class="pt-5">
                        <div class="notoserif_font" style="color:white;font-weight:bold"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:20px' : 'font-size:36px'"
                          >KDGE</div>
                        <div style="color:white" class="notoserif_font mt-1 mt-md-5"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:20px'"
                          >Competitiveness in the Global Era is Quality and Value.</div>
                        <div style="color:white" class="notosanskr_font"
                          :style="$vuetify.breakpoint.smAndDown ? 'font-size:12px' : 'font-size:20px'"
                          >글로벌시대의 경쟁력은 품질과 가치입니다.</div>
                    </v-col>
                </v-row>
            </v-img>    
        </v-col>
    </v-row>


  </v-container>
</template>
<script>

export default {

    mounted() {
      window.scrollTo(0,0);
    },

    data: () => ({

    }),

    created(){

    },

    methods: {
      govivid(){
        this.$router.push("/VividDiamond");
      }
    },



}
</script>

<style scoped>
.v-expansion-panels>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.text_black{
  color: black;
}
.text_black_bold{
  color: black;
  font-weight: bold;
}

a{
    color:#0c121c !important;
    text-decoration: none !important;
    font-size: 15px !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    font-family: roboto, sans-serif !important;
}

li{
    margin-top: 12px;
}

</style>