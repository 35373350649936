<template>
  <v-container fluid class="container_bg">

    <pdfinfo ref="pdfinfo" />

    <!-- 링크 입력 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="link_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Link&nbsp;링크</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Link&nbsp;<span class="dialog_subtitle_kor">링크</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" placeholder="Link"
                      single-line v-model="LINKADDR" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>    

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="link_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save_link">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_prog" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">Please wait...</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <!-- API INFO -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="api_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">API INFO</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <label>API Key</label>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.APIKEY" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 40"
                      outlined dense hide-details disabled/>
                  </v-col>
                </v-row>   

                <v-row v-if="editedItem.APIKEY != null && editedItem.APIKEY.length == 0" dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <label>API 사용을 원하시면 아래 버튼을 눌러 APIKEY를 생성하세요.</label>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-btn @click="createAPIKey">Create API KEY</v-btn>
                  </v-col>
                </v-row>    

            </v-col>
          </v-row>
        </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0" justify="center">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="api_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="center" dense class="mt-5">
      <v-col cols="12" md="10" align="center"> <!-- 해상도 조절 컬럼 -->

        <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
          <v-col v-if="editedItem.GRADE >= 10" cols="12" md="10" align="end"  class="ma-0 pa-0">
            <v-btn small text style="text-transform:none" @click="api_dialog = true">API INFO</v-btn>
            <v-btn small text style="text-transform:none" @click="goApiDoc">API DOC</v-btn>
          </v-col>

          <!-- 소매업자의 경우 -->
          <v-col v-if="editedItem.GRADE == 5" cols="12" md="10" align="end"  class="ma-0 pa-0">
            <v-btn small text style="text-transform:none" @click="gopaylist">결제내역</v-btn>
          </v-col>

          <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
            <label class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Account&nbsp;<span class="dialog_subtitle_kor">계정</span></label>
          </v-col>
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <v-text-field 
              class="none_radius" color="black"  
              single-line v-model="editedItem.ACCNT" disabled
              :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
              outlined dense hide-details/>
          </v-col>
        </v-row>


        <template v-if="editedItem.GRADE > 1">
          <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Company&nbsp;<span class="dialog_subtitle_kor">업체명</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"  
                single-line v-model="editedItem.NAME" 
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.TEL"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>            

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Name&nbsp;<span class="dialog_subtitle_kor">신청자 이름</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.REGNAME"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Registrant Position&nbsp;<span class="dialog_subtitle_kor">신청자 직책</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.REGPOSITION"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>        
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">신청자 휴대폰번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.MOBILE"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>   
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Name&nbsp;<span class="dialog_subtitle_kor">대표자 이름</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.OWNERNAME"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>        
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Owner Mobile&nbsp;<span class="dialog_subtitle_kor">대표자 휴대폰번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.OWNERMOBILE"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>   

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business Number&nbsp;<span class="dialog_subtitle_kor">사업자번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.BUSINESSNUMBER"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>      
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">business conditions/specifications&nbsp;<span class="dialog_subtitle_kor">업태/종목</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.BUSINESSITEM"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>                  
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-textarea height="70"
                class="none_radius" color="black"
                single-line v-model="editedItem.ADDRESS"
                outlined dense hide-details/>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
            </v-col>
          </v-row>         

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Business license&nbsp;<span class="dialog_subtitle_kor">사업자등록증</span></p>
            </v-col>
            <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
              <div class="black_line"/>    
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
              <v-col align="center" align-self="center" >
                <template v-if="previewImg1.length > 0">
                  <v-row dense  justify="center">

                    <template v-if="previewImg1 == pdfPreviewImg1 || previewImg1.split('.').pop().toLowerCase() == 'pdf'">
                      <div style="position:relative">
                        <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                          <v-icon style="width:80px;height:40px;color:white" @click="showImage_pdf(0)">mdi-magnify-expand</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(0)">mdi-pencil</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                        </div>
                        <img  width="240" style="hegiht:auto" :src="pdfPreviewImg1" class="image">
                      </div>
                    </template>
                    <template v-else>
                      <div style="position:relative">
                        <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                          <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(0)">mdi-pencil</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                        </div>
                        <img  width="240" style="hegiht:auto" :src="previewImg1" class="image">
                      </div>
                    </template>

                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border2" align="center" justify="center">
                    <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect_pdf(0)">mdi-image</v-icon>
                    <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(0)">mdi-link</v-icon>
                  </v-row>
                </template>
              </v-col>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">{{ 'Business Card' }}&nbsp;<span class="dialog_subtitle_kor">{{ '명함' }}</span></p>
            </v-col>
            <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
              <div class="black_line"/>    
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
              <v-col align="center" align-self="center" >

                <template v-if="previewImg2.length > 0">
                  <v-row dense  justify="center">

                    <template v-if="previewImg2 == pdfPreviewImg2 || previewImg2.split('.').pop().toLowerCase() == 'pdf'">
                      <div style="position:relative">
                        <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                          <v-icon style="width:80px;height:40px;color:white" @click="showImage_pdf(1)">mdi-magnify-expand</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(1)">mdi-pencil</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                        </div>
                        <img  width="240" style="hegiht:auto" :src="pdfPreviewImg2" class="image">
                      </div>
                    </template>
                    <template v-else>
                      <div style="position:relative">
                        <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                          <v-icon style="width:80px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="fileSelect_pdf(1)">mdi-pencil</v-icon>
                          <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                        </div>
                        <img  width="240" style="hegiht:auto" :src="previewImg2" class="image">
                      </div>
                    </template>

                  </v-row>
                </template>
                <template v-else>
                  <v-row dense class="image-border2" align="center" justify="center">
                    <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect_pdf(1)">mdi-image</v-icon>
                    <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="linkadd(1)">mdi-link</v-icon>
                  </v-row>
                </template>
              </v-col>
            </v-col>
          </v-row>    
          
        </template>
        <template v-else>
          <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"  
                single-line v-model="editedItem.NAME" 
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Tel&nbsp;<span class="dialog_subtitle_kor">전화번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.TEL"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>            
          <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
            <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
              <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Mobile&nbsp;<span class="dialog_subtitle_kor">휴대폰번호</span></p>
            </v-col>
            <v-col cols="12" md="10" class="ma-0 pa-0">
              <v-text-field 
                class="none_radius" color="black"
                single-line v-model="editedItem.MOBILE"
                :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                outlined dense hide-details/>
            </v-col>
          </v-row>         

        </template>

        <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
          <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
            <a @click="membershipwithdrawal" class="text-md-body-2 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Membership Withdrawal&nbsp;<span class="dialog_subtitle_kor">회원탈퇴</span></a>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" md="10"> <!-- 해상도 조절 컬럼 -->
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    components:{
      VueCropper
    },

    mounted() {
      this.loadpage();
    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    beforeRouteLeave(to, from, next){

      if(this.$refs.pdfinfo.pdf_dialog){
        this.$refs.pdfinfo.close();
        next(false);
      }else{
        next();
      }

    },


    data: () => ({

      //PDF첨부시 보여줄 공통 이미지 - 사업자등록증
      pdfPreviewImg1:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmimg.jpg",

      //PDF첨부시 보여줄 공통 이미지 - 명함
      pdfPreviewImg2:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmcard.jpg",

      api_dialog: false,
      showkey: false,

      link_dialog: false,
      link_index:0,
      LINKADDR:"",


      //파일 업로드
      ratio: 16/9,

      uploadFile1 : [],
      uploadFile2 : [],
      previewImg1:"",
      previewImg2:"",
      crop_dialog:false,
      photo_index:0,
      imgSrc:'',

      options: {
        focus:false,
      },      
      editedItem: {
      },
      loading_prog : false,

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

    }),

    created(){

    },

    methods: {

      goApiDoc(){
        this.$router.push("/ApiDoc");
      },

      gopaylist(){
        this.$router.push("/PaymentList");
      },

      linkadd(index){
        this.LINKADDR = "";
        this.link_index = index;
        this.link_dialog = true;
      },

      //링크 저장
      save_link(){
        const urlVal = /https?:\/\//;
        const checklink = cmvalidation.checkValReg(this.LINKADDR, urlVal, -1, 255, false);
        if(checklink == 1){
          this.$fire({
            html: "Please enter the Link URL.<br><br>Link URL을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 2){
          this.$fire({
            html: "Link URL must start with http or https.<br><br>Link URL은 http 또는 https로 시작해야 합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }else if(checklink == 4){
          this.$fire({
            html: "Link URL the maximum input limit.<br><br>Link URL이 최대 입력 제한을 초과합니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return false;
        }              
        
        if(this.link_index == 0){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
            //pdf면 분기처리
            this.previewImg1                  = this.pdfPreviewImg1;
          }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg1              = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .png, .pdf<br><br>URL 주소의 끝은 .jpg, .png, .pdf이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
          this.editedItem.BUSINESS_REF   = this.LINKADDR;
          this.editedItem.BUSINESS_IMG   = this.LINKADDR;
        }else if(this.link_index == 1){
          if(this.LINKADDR.split('.').pop().toLowerCase() == 'pdf'){
            //pdf면 분기처리
            this.previewImg2                  = this.pdfPreviewImg2;
          }else if(this.LINKADDR.split('.').pop().toLowerCase() == 'jpg' || this.LINKADDR.split('.').pop().toLowerCase() == 'jpeg' || this.LINKADDR.split('.').pop().toLowerCase() == 'png'){
            this.previewImg2              = this.LINKADDR;
          }else{
            this.$fire({
              html: "The end of the URL address cannot be any other than .jpg, .png, .pdf<br><br>URL 주소의 끝은 .jpg, .png, .pdf이외에는 사용할 수 없습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return false;
          }
          this.editedItem.CARD_REF   = this.LINKADDR;
          this.editedItem.CARD_IMG   = this.LINKADDR;
        }
        this.link_dialog = false;
      },


      showImage_pdf(index){
        //console.log("showImage_pdf : " + index);
        if(index == 0){
          
          if(this.isNullCheck(this.uploadFile1)){
            //console.log("showImage_pdf url");
            this.$refs.pdfinfo.geturl(this.editedItem.BUSINESS_IMG);
          }else{
            //console.log("showImage_pdf file");
            //파일로 보여줘야함.
            this.$refs.pdfinfo.getfile(this.uploadFile1);
          }
        }else{
          if(this.isNullCheck(this.uploadFile2)){
            this.$refs.pdfinfo.geturl(this.editedItem.CARD_IMG);
          }else{
            //파일로 보여줘야함.
            this.$refs.pdfinfo.getfile(this.uploadFile2);
          }
        }
      },     


      setPdfPreview(index){
        if(index == 0){
          this.previewImg1 = this.pdfPreviewImg1;
        }else{
          this.previewImg2 = this.pdfPreviewImg2;
        }
      },

      fileSelect_pdf(index){

        const changefunction    = this.changefile;
        const changepdffunction = this.changefile_pdf;
        const updatefunction    = this.setPdfPreview;

        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png,.pdf"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction(index);
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }else{

          input.onchange = function (event) {

            //pdf 호환작업
            var fileExtension = event.target.files[0].name.split(".").pop();
            if(fileExtension.toLowerCase() == "pdf"){
              updatefunction(index);
              changepdffunction(event.target.files[0], index);
            }else{
              //이미지면 크롭처리
              changefunction(event.target.files[0], index);
            }
            
          };
        }
        input.click();
      },      


      //이미지 선택시
      changefile_pdf(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //PDF는 크롭없이 바로 첨부
        const reader = new FileReader();
        reader.onload = (event2)=>{
          //console.log("index : " + pindex);
          if(pindex == 0){
            this.uploadFile1 = this.dataURItoBlob(event2.target.result);
          }else if(pindex == 1){
            this.uploadFile2 = this.dataURItoBlob(event2.target.result);
          }
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

      },      
      
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },      

      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      

      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }

          });

        });
      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },
      
      loadpage(){

          this.$http.post(this.$host+'/AcntDetail',{
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.editedItem = result.data.resultData[0];

                //console.log("apikey:"+this.editedItem.APIKEY);

                if(this.editedItem.BUSINESS_IMG != null && this.editedItem.BUSINESS_IMG.length > 0){
                  if(this.editedItem.BUSINESS_IMG.split('.').pop().toLowerCase() == 'pdf'){
                    //pdf면 pdf미리보기로 지정
                    this.previewImg1 = this.pdfPreviewImg1;
                  }else{
                    //이미지면 그대로 지정
                    this.previewImg1 = this.editedItem.BUSINESS_IMG != null && this.editedItem.BUSINESS_IMG.length > 0 ? this.editedItem.BUSINESS_IMG : "";
                  }
                }
                if(this.editedItem.CARD_IMG != null && this.editedItem.CARD_IMG.length > 0){
                  if(this.editedItem.CARD_IMG.split('.').pop().toLowerCase() == 'pdf'){
                    //pdf면 pdf미리보기로 지정
                    this.previewImg2 = this.pdfPreviewImg2;
                  }else{
                    //이미지면 그대로 지정
                    this.previewImg2 = this.editedItem.CARD_IMG != null && this.editedItem.CARD_IMG.length > 0 ? this.editedItem.CARD_IMG : "";
                  }
                }


            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      membershipwithdrawal(){

        //회원탈퇴
        this.$fire({
          html: "You cannot rejoin when you withdraw from the membership.<br>Are you sure you want to leave?<br><br>회원탈퇴시 재가입할 수 없습니다.<br>정말 탈퇴하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Leave<br>탈퇴</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/UserAcntDraw',{
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }

                  this.$fire({
                    html: "Your membership withdrawal has been completed.<br><br>회원탈퇴가 완료되었습니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                  }).then(r => {
                    if(r.value){
                      this.$EventBus.$emit('clearsession');
                    }
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });

              }else{
                this.$fire({
                  html: "Failed to withdraw from membership. Please try again.<br><br>회원탈퇴에 실패했습니다. 다시 시도해주세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              this.$fire({
                html: "Failed to withdraw from membership. Please try again.<br><br>회원탈퇴에 실패했습니다. 다시 시도해주세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            });

          }
        });

      },

      //다이얼로그 닫기
      close () {
        this.$router.back();
      },      

            //추가 저장
      async save () {

        this.loading_prog = true;

        if(this.editedItem.GRADE > 1){

          const checkname = cmvalidation.checkValReg(this.editedItem.NAME, null, -1, 50);
          if(checkname == 1){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter your Company name<br><br>업체명을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkname == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter no more than 50 characters for your Company name<br><br>업체명은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checkbm = cmvalidation.checkValReg(this.editedItem.BUSINESSNUMBER, null, 5, 50);
          if(checkbm == 1){
            this.loading_prog = false;
            this.$fire({
              html: "Enter business number<br><br>사업자번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkbm == 3){
              this.loading_prog = false;
              this.$fire({
                html: "Please Enter the business number in 5 characters or then<br><br>사업자번호는 5자 이상 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkbm == 4){
              this.loading_prog = false;
              this.$fire({
                html: "Please Enter the business number in 50 characters or less<br><br>사업자번호는 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }

          const checkitem = cmvalidation.checkValReg(this.editedItem.BUSINESSITEM, null, -1, 50);
          if(checkitem == 1){
              this.loading_prog = false;
              this.$fire({
                html: "Enter business status/item<br><br>업태/종목을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkitem == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please Enter business type/item in 50 characters or less<br><br>업태/종목은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          const checkaddr = cmvalidation.checkValReg(this.editedItem.ADDRESS, null, 10, 100);
          if(checkaddr == 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter your address<br><br>주소를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkaddr == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter address in 10 characters or then<br><br>주소는 10자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkaddr == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter address in 100 characters or less<br><br>주소는 100자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          

          const checktel = cmvalidation.checkValReg(this.editedItem.TEL, /[^0-9-]/g, 5, 50);
          if(checktel == 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter your phone number<br><br>연락처를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checktel == 2){
            this.loading_prog = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and<br><br>전화번호는 숫자와 -만 입력이 가능합니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 5 characters or then<br><br>전화번호는 5자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>전화번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


          const checkmobile = cmvalidation.checkValReg(this.editedItem.MOBILE, /[^0-9-]/g, 5, 50);
          if(checkmobile == 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter registrant mobile phone number<br><br>신청자 휴대폰번호를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkmobile == 2){
            this.loading_prog = false;
            this.$fire({
              html: "registrant Mobile Phone numbers can only be entered in numbers and<br><br>신청자 휴대폰번호는 숫자와 -만 입력이 가능합니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the registrant mobile phone number in 5 characters or then<br><br>신청자 휴대폰번호는 5자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>신청자 휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkregname = cmvalidation.checkValReg(this.editedItem.REGNAME, null, -1, 50);
          if(checkregname == 1){
              this.loading_prog = false;
              this.$fire({
                html: "Enter registrant name<br><br>등록자 이름을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkregname == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please Enter registrant name in 50 characters or less<br><br>등록자 이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkregpos = cmvalidation.checkValReg(this.editedItem.REGPOSITION, null, -1, 50);
          if(checkregpos == 1){
              this.loading_prog = false;
              this.$fire({
                html: "Enter registrant position/item<br><br>등록자 직책을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkregpos == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please Enter registrant position in 50 characters or less<br><br>등록자 직책은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkownergname = cmvalidation.checkValReg(this.editedItem.OWNERNAME, null, -1, 50);
          if(checkownergname == 1){
              this.loading_prog = false;
              this.$fire({
                html: "Enter Owner Name<br><br>대표자 이름을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkownergname == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please Enter Owner Name in 50 characters or less<br><br>대표자 이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkownermobile = cmvalidation.checkValReg(this.editedItem.OWNERMOBILE, /[^0-9-]/g, 5, 50);
          if(checkownermobile == 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter owner mobile phone number<br><br>대표자 휴대폰번호를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkownermobile == 2){
            this.loading_prog = false;
            this.$fire({
              html: "owner mobile phone numbers can only be entered in numbers and -<br><br>대표자 휴대폰번호는 숫자와 -만 입력이 가능합니다)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkownermobile == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the owner mobile phone number in 5 characters or then<br><br>대표자 휴대폰번호는 5자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkownermobile == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the owner mobile phone number in 50 characters or less<br><br>대표자 휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }


          if(this.isNullCheck(this.uploadFile1)){
              if(this.isNullCheck(this.editedItem.BUSINESS_REF)){
                this.loading_prog = false;
                this.$fire({
                  html: "Register Business license image<br><br>사업자등록증 이미지를 등록하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          }
          if(this.isNullCheck(this.uploadFile2)){
              if(this.isNullCheck(this.editedItem.CARD_REF)){
                this.loading_prog = false;
                this.$fire({
                  html: "Register Business card image<br><br>명함 이미지를 등록하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }
          }          

          //사업자등록증 사진
          if(!this.isNullCheck(this.uploadFile1)){
            
            var uploadpath = Date.now().toString()+".jpg";
            if(this.previewImg1 == this.pdfPreviewImg1 || this.previewImg1.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath = Date.now().toString()+".pdf";
            }

            let upload_cb1 = await this.uploadStorage("company", uploadpath, this.uploadFile1);
            if(upload_cb1 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business license image upload error<br><br>사업자등록증 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile1 = [];

            if(!this.isNullCheck(this.editedItem.BUSINESS_REF)){
              try{
                await this.deletefile(this.editedItem.BUSINESS_REF);
              }catch(e){
              }
            }
            this.editedItem.BUSINESS_REF = upload_cb1.resultRef;
          }

          //명함 사진
          if(!this.isNullCheck(this.uploadFile2)){

            var uploadpath2 = Date.now().toString()+".jpg";
            if(this.previewImg2 == this.pdfPreviewImg2 || this.previewImg2.split('.').pop().toLowerCase() == 'pdf'){
              uploadpath2 = Date.now().toString()+".pdf";
            }

            //파일이 수정된 경우
            let upload_cb2 = await this.uploadStorage("company", uploadpath2, this.uploadFile2);
            if(upload_cb2 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business card image upload error<br><br>명함 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile2 = [];

            if(!this.isNullCheck(this.editedItem.CARD_REF)){
              try{
                await this.deletefile(this.editedItem.CARD_REF);
              }catch(e){
              }
            }

            this.editedItem.CARD_REF = upload_cb2.resultRef;

          }

        }else{

          //소비자는 해당 이미지 필요 없음
          this.editedItem.BUSINESS_REF  = "";
          this.editedItem.CARD_REF      = "";

          const checkname = cmvalidation.checkValReg(this.editedItem.NAME, null, -1, 50);
          if(checkname == 1){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter your name error<br><br>이름을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkname == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter no more than 50 characters for your name<br><br>이름은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checktel = cmvalidation.checkValReg(this.editedItem.TEL, /[^0-9-]/g, 5, 50);
          if(checktel == 2){
            this.loading_prog = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and<br><br>전화번호는 숫자와 -만 입력이 가능합니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 5 characters or then<br><br>전화번호는 5자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checktel == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>전화번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          const checkmobile = cmvalidation.checkValReg(this.editedItem.MOBILE, /[^0-9-]/g, 5, 50);
          if(checkmobile == 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter mobile phone number<br><br>휴대폰번호를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }else if(checkmobile == 2){
            this.loading_prog = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and<br><br>휴대폰번호는 숫자와 -만 입력이 가능합니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 3){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 5 characters or then<br><br>휴대폰번호는 5자 이상 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }else if(checkmobile == 4){
            this.loading_prog = false;
            this.$fire({
              html: "Please enter the phone number in 50 characters or less<br><br>휴대폰번호는 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }          

          
        }
              
        this.$http.post(this.$host+'/UserAcntUpdate',{
              NAME: escape(this.editedItem.NAME)
            , BUSINESSNUMBER: this.editedItem.BUSINESSNUMBER == undefined || this.editedItem.BUSINESSNUMBER.length < 1 ? '' : escape(this.editedItem.BUSINESSNUMBER)
            , BUSINESSITEM: this.editedItem.BUSINESSITEM == undefined || this.editedItem.BUSINESSITEM.length < 1 ? '' : escape(this.editedItem.BUSINESSITEM)
            , ADDRESS: this.editedItem.ADDRESS == undefined || this.editedItem.ADDRESS.length < 1 ? '' : escape(this.editedItem.ADDRESS) 
            , BUSINESS_IMG: this.editedItem.BUSINESS_REF == null || this.editedItem.BUSINESS_REF == undefined ? '' : escape(this.editedItem.BUSINESS_REF)
            , CARD_IMG: this.editedItem.CARD_REF == null || this.editedItem.CARD_REF == undefined ? '' : escape(this.editedItem.CARD_REF)
            , TEL: this.editedItem.TEL == undefined || this.editedItem.TEL.length < 1 ? '' : escape(this.editedItem.TEL)
            
            , MOBILE: this.editedItem.MOBILE == undefined || this.editedItem.MOBILE.length < 1 ? '' : escape(this.editedItem.MOBILE)
            , REGNAME: this.editedItem.REGNAME == undefined || this.editedItem.REGNAME.length < 1 ? '' : escape(this.editedItem.REGNAME)
            , REGPOSITION: this.editedItem.REGPOSITION == undefined || this.editedItem.REGPOSITION.length < 1 ? '' : escape(this.editedItem.REGPOSITION)
            , OWNERNAME: this.editedItem.OWNERNAME == undefined || this.editedItem.OWNERNAME.length < 1 ? '' : escape(this.editedItem.OWNERNAME)
            , OWNERMOBILE: this.editedItem.OWNERMOBILE == undefined || this.editedItem.OWNERMOBILE.length < 1 ? '' : escape(this.editedItem.OWNERMOBILE)

        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading_prog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  const p = this.page;
                  this.page = 0;
                  this.loadpage();
                }
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading_prog = false;
          this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });


      },

      createAPIKey(){

        this.$http.post(this.$host+'/createAPIKey',{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "API KEY creation completed.<br><br>API KEY 발급이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
              this.$fire({
                html: "Request failed.<br><br>요청이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading_prog = false;
          this.$fire({
                html: "Request failed.<br><br>요청이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });


      },

      postcodetoAddr(addr){
        this.editedItem.ADDRESS = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);

            }
        }).open();

      },            


    },


}
</script>

<style scoped>

</style>