<template>

  <!-- 거래 상세내역 -->
  <v-container fluid class="container_bg">

    <!-- 구매요청 정보 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="buy_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Purchase Request&nbsp;구매요청</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.ADDRESS" disabled
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="editedItem.MESSAGE" disabled color="black" hide-details outlined dense/>
                  </v-col>
                </v-row>                

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Quantity&nbsp;<span class="dialog_subtitle_kor">수량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.QUANTITY" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price&nbsp;<span class="dialog_subtitle_kor">가격</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.PRICE" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                

            
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="buy_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 계약금 입금 정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="downpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Deposit information&nbsp;계약금입금 정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="downpayItem.PRICE_TYPE == 0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(USD)&nbsp;<span class="dialog_subtitle_kor">입금액(달러)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.PRICE_USD_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>           

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="downpayItem.PRICE_TYPE == 1">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(WON)&nbsp;<span class="dialog_subtitle_kor">입금액(원화)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.PRICE_WON_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>           

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="downpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 발송요청정보 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="sendreq_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Delivery request Infomation<br>발송요청정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng"><span class="dialog_subtitle_kor">발송요청정보를 확인하세요</span></p>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Recipient Name&nbsp;<span class="dialog_subtitle_kor">수령자이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">수령주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.ADDRESS" disabled
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                       

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">요청메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="sendreqItem.COMMENT" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit Amount&nbsp;<span class="dialog_subtitle_kor">입금액</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">{{sendreqItem.SEND_PRICE_DP}}</p>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Image&nbsp;<span class="dialog_subtitle_kor">인보이스 이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    
                      <template v-if="sendreqImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <img  style="hegiht:auto" width="240" :src="sendreqImg1" class="image" @click="$viewerApi({images: [sendreqImg1]})">
                          </div>
                        </v-row>
                      </template>                      
                    
                  </v-col>
                </v-row>
     

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="sendreq_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 발송정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="send_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Shipment information<br>발송정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">발송정보를 확인하세요</p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.COMMENT" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Number&nbsp;<span class="dialog_subtitle_kor">송장번호</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="sendItem.INVOICENUMBER" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Image&nbsp;<span class="dialog_subtitle_kor">송장이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                      <template v-if="sendImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <img  height="120" width="120" :src="sendImg1" class="image" @click="$viewerApi({images: [sendImg1]})">
                          </div>
                        </v-row>
                      </template>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="12" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="send_dialog = false">Close 닫기</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 잔금입금 정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="balpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Remaining Deposit Information<br>잔금입금정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0" v-if="balpayItem.PRICE_TYPE == 0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(USD)&nbsp;<span class="dialog_subtitle_kor">입금액(달러)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.PRICE_USD_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0" v-if="balpayItem.PRICE_TYPE == 1">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(WON)&nbsp;<span class="dialog_subtitle_kor">입금액(원화)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="balpayItem.PRICE_WON_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="balpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 제품전달 정보-->
    <v-row align="center"  justify="center" >

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="transper_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Product Delivery&nbsp;상품 전달</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Receiving method&nbsp;<span class="dialog_subtitle_kor">수령방식</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="transperItem.SHIP_TYPE" :items="SHIPTYPELIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shipping company&nbsp;<span class="dialog_subtitle_kor">택배사</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" disabled
                      single-line v-model="transperItem.DELIVERYCOMPANY" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Number&nbsp;<span class="dialog_subtitle_kor">송장번호</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" disabled
                      single-line v-model="transperItem.INVOICENUMBER" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>       
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="transperItem.COMMENT" color="black" hide-details disabled outlined dense/>
                  </v-col>
                </v-row>         
            
              </v-col>
            </v-row>
          </v-card-text>


          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="transper_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        

    <!-- 거래취소 완료 정보-->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancel Info<br>거래취소완료</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Type&nbsp;<span class="dialog_subtitle_kor">취소타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <template v-if="cancelItem.CANCEL_TYPE == 0">
                      <label>구매자의 취소요청</label>
                    </template>
                    <template v-else>
                      <label>판매자의 취소요청</label>
                    </template>
                  </v-col>
                </v-row>              

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Message&nbsp;<span class="dialog_subtitle_kor">취소완료사유</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelItem.COMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancel_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 거래취소 요청 정보-->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancelreq_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancel Request Info<br>취소요청정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Type&nbsp;<span class="dialog_subtitle_kor">취소타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <template v-if="cancelreqItem.CANCEL_TYPE == 0">
                      <label>구매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 1">
                      <label>판매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 2">
                      <label>관리자의 취소</label>
                    </template>
                  </v-col>
                </v-row>              

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Message&nbsp;<span class="dialog_subtitle_kor">취소요청 메시지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelreqItem.COMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         

              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancelreq_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start"  class="mt-5">
      <v-col cols="12" md="4" sm="12" xs="12" align="left" class="pa-0 d-flex align-center" >
        <a @click="back"><span><v-icon color="black">mdi-chevron-left</v-icon><label class="menutitle">Back</label></span></a>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row align="center" justify="start" dense>
      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>
    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-1 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
            
          item-key="HISTORY_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >

          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal">State<br>상태</label>
          </template>
          <template v-slot:header.REG_DATE>
            <label class="cheader_normal">Date<br>처리일자</label>
          </template>

          <template v-slot:item.PURCHASE_STATENAME="{ item }">  
            <template v-if="item.CANCEL_CONFIRM == 0">
              <a @click="loadcancelreq(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.CANCEL_CONFIRM == 1">
              <a @click="loadcancelreq(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 1">
              <a @click="loadbuyinfo(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 2">
              <label>{{item.PURCHASE_STATENAME}}</label>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 4">
              <a @click="loaddownpay(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 6">
              <a @click="loadsendreq(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 8">
              <a @click="loadsend(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 10">
              <label>{{item.PURCHASE_STATENAME}}</label>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 11">
              <a @click="loadbalpay(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 20">
              <a @click="loadtransper(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 25">
              <label>{{item.PURCHASE_STATENAME}}</label>
            </template>
            <!-- 
            <template v-else-if="item.PURCHASE_STATE == 50">
              <a @click="loadcancelreq(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
            -->
          </template>

        </v-data-table>
      </v-col>
    </v-row>


  </v-container>
</template>
<script>

export default {
    

    mounted() {
      this.PURCHASE_IDX = this.$route.query.code;
      this.loadpage();
    },

    data: () => ({
      pagerow: 9999,
      PURCHASE_IDX:"",     //넘겨받은 거래 번호
      
      //구매요청 정보
      buy_dialog: false,
      buyItem:{},

      //계약금 입금 정보
      downpayItem:{},
      downpay_dialog:false,

      //발송요청 정보
      sendreq_dialog:false,
      sendreqItem:{},
      sendreqImg1:"",

      //발송정보
      send_dialog:false,
      sendItem:{},
      sendImg1:"",

      //잔금 입금 정보
      balpayItem:{},
      balpay_dialog:false,

      //제품전달 정보
      transperItem:{},
      transper_dialog:false,

      //거래취소완료 정보
      cancelItem:{},
      cancel_dialog:false,

      //취소요청 정보
      cancelreqItem:{},
      cancelreq_dialog:false,

      editedItem: {
      },

      SHIPTYPELIST: [
          {name:'Direct delivery from KDGE./본사직접배송',          value:0}
        , {name:'Delivery service./택배',                          value:1}
        , {name:'Visit a designate retailer to receive the products./지정소매상',  value:2}
        , {name:'Visit KDGE to receive the products./본사방문수령',                value:3}
      ],

      uploadFile1:[],

      progress_value:0,
      progress_dialog:false,



      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '처리일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
      ],
      itemList: [],
      

    }),

    created(){

    },

    methods: {

      back(){
        //뒤로
        this.$router.back();
      },

      initpage(){
        this.loadpage();
      },      

      //검색
      search_run(){
        this.loadpage();
      },

      //구매 처리내역 상세 히스토리
      loadpage(){
        this.loading = true;
        this.$http.post(this.$host+'/PurchaseHistoryList',{
          PURCHASE_IDX: this.PURCHASE_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              const list = result.data.resultData;
              this.itemList.splice(0, this.itemList.length);   //데이터 초기화
              list.forEach(element => {
                  this.itemList.push(element); 
              });

          }else if(result.data.resultCode == 2){

            //로그인 필요
            this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.loading = false;
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },

      //구매요청 정보
      loadbuyinfo(item){

        this.$http.post(this.$host+'/PuchaseDetail',{
          PURCHASE_IDX:item.PURCHASE_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.buyItem      = result.data.resultData[0];
              this.buy_dialog   = true;
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.buy_dialog   = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },            

      //계약금 입금 정보 가져오기
      loaddownpay(item){
        
        this.$http.post(this.$host+'/AdminDownPayDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no deposit information.<br><br>입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.downpayItem = result.data.resultData[0];
                this.downpay_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.downpay_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },

      //발송요청정보 가져오기
      loadsendreq(item){
        
        this.$http.post(this.$host+'/AdminSendReqDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              
              if(result.data.resultData.length > 0){
                this.sendreqItem = result.data.resultData[0];
                this.sendreqImg1 = this.sendreqItem.INVOICE_IMG;
                this.sendreq_dialog = true;
              }else{
                this.$fire({
                  html: "Shipment request information is missing.<br><br>발송 요청 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.sendreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },

      //발송정보 가져오기
      loadsend(item){
        
        this.$http.post(this.$host+'/AdminSendDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              
              if(result.data.resultData.length > 0){
                this.sendItem = result.data.resultData[0];
                this.sendImg1 = this.sendItem.COURIER_IMG;
                this.send_dialog = true;
              }else{
                this.$fire({
                  html: "Shipment information is missing.<br><br>발송 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.send_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },     


      //잔금입금정보 가져오기
      loadbalpay(item){

        this.$http.post(this.$host+'/AdminBalPayDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no balance deposit information.<br><br>잔금 입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.balpayItem = result.data.resultData[0];
                this.balpay_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.balpay_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      


      //제품전달정보 가져오기
      loadtransper(item){

        this.$http.post(this.$host+'/AdminTransperDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no balance deposit information.<br><br>잔금 입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.transperItem = result.data.resultData[0];
                this.transper_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.transper_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      




      //취소요청정보
      loadcancelreq(item){

        //계약금 입금 정보 가져오기
        this.$http.post(this.$host+'/AdminCancelReqDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "Request information is missing.<br><br>요청 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.cancelreqItem = result.data.resultData[0];
                this.cancelreq_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.cancelreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },



      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },





    },



}
</script>

<style scoped>
a{
  color:black;
}
</style>