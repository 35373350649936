<template>
  <v-container fluid class="container_bg">

    <v-row align="start" class="mt-0 mt-md-0" dense>
      <v-col cols="12" md="3">
        <label class="menunamestyle">Check Favorites 즐겨찾기 확인</label>
      </v-col>
      <v-spacer/>
    </v-row>


    <v-row align="start" class="mt-5 mt-md-0" dense>

      <!-- 모바일 해상도 -->
      <template v-if="itemList.length > 0">
        <v-col
          v-for="item in itemList"
          cols="12"
          class="hidden-md-and-up mb-3"
          >
          <v-card>
            <v-list-item>
              <v-list-item-avatar
                tile
                size="80"
                >
                <img @click="loadnotice(item)" style="border-radius:10px" :src="item.THUM_IMG1"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-card-text @click="loadnotice(item)" class="pa-0 text_2line" style="color:#1976d2;font-size:15px;line-height:14px;font-weight:bold" v-text="item.PRODUCT_NUMBER"></v-card-text>
                <v-card-text class="pa-0 inter_font" style="font-size:12px" v-text="item.REG_DATE"></v-card-text>
                <v-card-text class="pa-0 inter_font" style="font-size:10px"><v-btn color="black" style="color:white" x-small @click="delete_item(item)">Delete 삭제</v-btn></v-card-text>
              </v-list-item-content>
            </v-list-item>          
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <template v-if="first_flag">
          <v-col cols="12" class="hidden-md-and-up text-center mt-15 pt-15">
            <div class="inter_font" style="font-weight:bold;font-size:16px">There is no favorite product.</div><br>
            <div class="notosanskr_font" style="font-weight:bold;font-size:16px">즐겨찾기한 상품이 없습니다.</div>
          </v-col>
        </template>
      </template>

      <!-- PC해상도 -->
      <template v-if="itemList.length > 0">
        <v-col cols="12" class="hidden-sm-and-down">
          <!-- 테이블 -->
          <v-data-table
            :headers="headers"
            :items="itemList"
            no-data-text="데이터가 없습니다."
            hide-default-footer
            class="datatablestyle"
            :items-per-page="pagerow"
            item-key="INQUIRY_IDX"
            :loading="loading"
            item-class="itemstyle"
            mobile-breakpoint="0"
            >
            <template v-slot:header.THUM_IMG1>
              <label class="cheader_normal">Image<br>이미지</label>
            </template>
            <template v-slot:header.PROV_NAME>
              <label class="cheader_normal">Seller<br>공급처</label>
            </template>
            <template v-slot:header.PRODUCT_NUMBER>
              <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')">Product Name<br>상품명</a>
            </template>
            <template v-slot:header.REG_DATE>
              <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>등록일시</a>
            </template>
            <template v-slot:header.actions>
              <a class="cheader_normal">Delete<br>삭제</a>
            </template>

            <template v-slot:item.THUM_IMG1="{ item }">  
                <v-col class="pa-0" align="center">
                  <template v-if="item.THUM_IMG1 != null && item.THUM_IMG1.length > 0">
                    <v-img :src="item.THUM_IMG1" style="width:50px;hegith:50px" contain/>
                  </template>
                  <template v-else>
                    <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
                  </template>
                </v-col>
            </template>
            
            <template v-slot:item.PRODUCT_NUMBER="{ item }">  
              <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
            </template>

            <template v-slot:item.INQUIRY_STATE="{ item }">  
              <label style="text-align:center">{{ item.INQUIRY_STATE == 0 ? '질문':'답변' }}</label>
            </template>     

            <template v-slot:item.actions="{ item }">  
                <v-icon class="mr-2" small @click="delete_item(item)">mdi-pencil</v-icon>
            </template>  

          </v-data-table>
        </v-col>
      </template>
      <template v-else>
        <template v-if="first_flag">
          <v-col cols="12" class="hidden-sm-and-down text-center mt-15 pt-15">
            <div class="inter_font" style="font-weight:bold;font-size:16px">There is no favorite product.</div><br>
            <div class="notosanskr_font" style="font-weight:bold;font-size:16px">즐겨찾기한 상품이 없습니다.</div>
          </v-col>
        </template>
      </template>
      
    </v-row>

  </v-container>
</template>
<script>

export default {

    mounted() {
      this.initpage();
    },

    data: () => ({
      
      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',

      
      editedItem: {
      },

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '이미지', value: 'THUM_IMG1', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        //{text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '등록일자', value: 'REG_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],
      first_flag:false,

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 9999,       //한 페이지에 보여줄 row수

    }),

    created(){

    },

    methods: {

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },

      loadpage(){
        
        let favdata = localStorage.getItem("favlist");
        let favlist = favdata ? JSON.parse(favdata) : [];
        if(favlist.length == 0){
          this.itemList.splice(0, this.itemList.length);   //데이터 초기화
          this.first_flag = true;
          return;
        }

          this.loading = true;
          this.$http.post(this.$host+'/GuestFavoriteList',{
            favidxs: favlist.map(f => f.idx).join()
          })
          .then((result)=>{
            this.first_flag = true;
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                
                //리스트를 맵으로 변환
                const arrMap = favlist.reduce((map, obj) => {
                    map.set(obj.idx, obj.date);
                    return map;
                }, new Map);                
                
                list.forEach(element => {
                    //console.log("test : "+arrMap.get(element.PRODUCT_IDX));
                    element.REG_DATE = arrMap.get(element.PRODUCT_IDX);
                    this.itemList.push(element); 
                });
                this.loading = false;
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.first_flag = true;
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },



      //아이템 삭제
      delete_item(item){

        let favdata = localStorage.getItem("favlist");
        let favlist = favdata ? JSON.parse(favdata) : [];
        if(this.checkFav(favlist, Number(item.PRODUCT_IDX))){

          this.$fire({
            html: "Are you sure you want to delete your favorites?<br><br>즐겨찾기를 정말 삭제하시겠습니까?",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
            cancelButtonColor:'#ff0000',
            showCancelButton: true, 
            reverseButtons: true,
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          }).then(r => {
            if(r.value){

              let fidx = this.checkFavIndex(favlist, Number(item.PRODUCT_IDX));
              favlist.splice(fidx, 1);
              localStorage.setItem("favlist", JSON.stringify(favlist));
              this.loadpage();

              this.$fire({
                html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

            }

          });    

        }
      },

      
      //게시글 상세보기
      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },

      checkFav(favlist, checkidx){
        let flag = false;
        if(favlist.length == 0){
          return flag;
        }
        
        for(var i=0;i<favlist.length;i++){
          //console.log("check fav1 => " + favlist[i].idx + " / " + checkidx);
          if(favlist[i].idx == checkidx){
            flag = true;
            break;
          }
        }
        return flag;
      },
      checkFavIndex(favlist, checkidx){
        let idx = null;
        if(favlist.length == 0){
          return idx;
        }
        for(var i=0;i<favlist.length;i++){
          //console.log("check fav2 => " + favlist[i].idx + " / " + checkidx);
          if(favlist[i].idx == checkidx){
            idx = i;
            break;
          }
        }
        return idx;
      },


    },



}
</script>

<style scoped>

</style>