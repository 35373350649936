<template>
  <v-container fluid class="container_bg">

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h1>Common</h1>
      </v-col>
    </v-row>
    
    <v-divider/> 
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <p>Check the common conventions and codes of API.<br>We verified using the <a href="https://www.postman.com/" target="_blank">POSTMAN</a> program.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        The communication method uses the Post method and the Host address is as follows.
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">HOST URL</v-col>
      <v-col cols="12" md="8" class="fielddesc">https://sndge-wyj2xokuya-du.a.run.app</v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Request Header</h3>
        <p style="color:red">For all api requests, please send the API Key you issued to the value of the ApiKey field in the request header.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Request Body</h3>
        Send the request body by referring to each API document.
      </v-col>
    </v-row>
    
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Response Body</h3>
        Responses are answered in JSON format.
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12" md="11" class="fieldname"><h4>JSON representation</h4></v-col>
      <v-col cols="12" md="11" class="fielddesc pa-0">
        <pre translate="no" style="background:#fafafa">
        {
          "resultCode": 0,
          "resultMsg": "",
          "resultData": [
            {
              ...
            },
            {
              ...
            },
        }          
        </pre>
      </v-col>
      
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>ResultCode</h3>
        Check below for a list of response codes for the request.
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="11" class="pa-0" align-center="center">
        <table>
          <tr><td width="10%" class="fieldtd" style="text-align:center">0</td><td width="90%">Successful processing of request</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">1</td><td width="90%">Error on request.</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">9</td><td width="90%">Invalid parameter error. Check the message in resultMsg.</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">20</td><td width="90%">Failed to add product</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">21</td><td width="90%">Product modification failed</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">30</td><td width="90%">No data to delete</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">94</td><td width="90%">No ApiKey value in request Header.</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">95</td><td width="90%">Duplicate token (contact your administrator)</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">96</td><td width="90%">No API permissions.</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">97</td><td width="90%">Stop using tokens by an administrator</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">98</td><td width="90%">Missing token error</td></tr>
          <tr><td width="10%" class="fieldtd" style="text-align:center">99</td><td width="90%">Token verification error</td></tr>
        </table>
      </v-col>
    </v-row>



    <v-row justify="center" dense class="mt-15">
    </v-row>



  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    methods: {

      test(){



      }

    },

    created(){
      //apiProductList
    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

table{
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #eaeaea;
  padding: 10px;
}
.fieldtd {
  background: #f1f3f4;
}
.fieldname {
  background: #f1f3f4;
  border: 1px solid #eaeaea;
}
.fielddesc {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
}

</style>