<template>

  <v-container fluid class="container_bg">
    


    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">
          
          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Shape&nbsp;<span class="dialog_subtitle_kor">다이아몬드 쉐입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.SHAPE_NAME_ENG"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details
                      disabled
                      />
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Color&nbsp;<span class="dialog_subtitle_kor">다이아몬드 컬러</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.COLOR_NAME"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details
                      disabled
                      />
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Image&nbsp;<span class="dialog_subtitle_kor">이미지(450x450)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <!-- <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon> -->
                            </div>
                            <img height="120" width="120" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon color="#EDEDED" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>           

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Thumbnail&nbsp;<span class="dialog_subtitle_kor">썸네일(260x260)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg2.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                              <!-- <v-icon style="width:40px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon> -->
                            </div>
                            <img height="120" width="120" :src="previewImg2" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon color="#EDEDED" @click="fileSelect(1)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>                                  

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 업로드 프로그레스 -->
    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>    


    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="2" class="pl-3 pr-3 pl-md-0 pr-md-3 pa-1">
        <v-select 
          v-model="SEARCH_DIASHAPE" :items="DIASHAPELIST" item-color="black"
          placeholder="구분" dense item-text="NAME_ENG" item-value="IDX" 
          outlined hide-details  class="basicselectstyle" color="black" height="43"
          @change="loadpage(1)"
          />
      </v-col>
    </v-row>

    
  <v-row align="start" class="mt-5" dense>
    <v-col>

      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        item-key="IDX"
        :loading="loading"
        :items-per-page="9999"
        mobile-breakpoint="0"
        >
        <template v-slot:header.DIAIMG_COLOR>
          <a class="cheader_normal">Diamond Color<br>다이아몬드 컬러</a>
        </template>
        <template v-slot:header.actions>
          <a class="cheader_normal">Edit<br>수정</a>
        </template>
        <template v-slot:body="props">
            <tr v-for="(item, index) in props.items" :key="index">
              
              <td style="width:100px; text-align:center">
                <template v-if="item.DIAIMG_IMG != null && item.DIAIMG_IMG.length > 0">
                  <img :src="item.DIAIMG_IMG" style="width:100px;hegith:100px;margin-top:10px;margin-bottom:5px;" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:100px;width:100px;margin-top:10px;margin-bottom:10px" size="50">mdi-image</v-icon>
                </template>
              </td>

              <td style="width:100px; text-align:center">
                <template v-if="item.DIAIMG_THUM != null && item.DIAIMG_THUM.length > 0">
                  <img :src="item.DIAIMG_THUM" style="width:100px;hegith:100px;margin-top:10px;margin-bottom:5px;" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:100px;width:100px;margin-top:10px;margin-bottom:10px" size="50">mdi-image</v-icon>
                </template>
              </td>

              <td style="width:100px; text-align:center">{{ item.COLOR_NAME }}</td>
              <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon></td>
            </tr>
        </template>        
      </v-data-table>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    data: () => ({
      
      DIASHAPELIST: [],     //다이아몬드 모양
      SEARCH_DIASHAPE: -1,

      //필수 페이징 파라메터
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          DIAIMG_IMG:"",
          DIAIMG_THUM:"",
          DIAIMG_COLOR:"",
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '이미지',  value: 'DIAIMG_IMG', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '썸네일',  value: 'DIAIMG_THUM', sortable: false, align:'center', width:100, class:"cheader_normal"},
        {text: '종류',    value: 'DIAIMG_COLOR', width:120,sortable: false, align:'center' },
        {text: '수정',    value: 'actions', width:120,sortable: false, align:'center'}
      ],
      itemList: [],

      uploadFile1 : [],
      uploadFile2 : [],
      previewImg1:"",
      previewImg2:"",
      
      //이미지 크롭
      progress_value:0,
      progress_dialog:false,
      
      imgSrc:'',
      photo_index:0,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    create(){

    },

    methods: {

      loadDiaShape(){
        
        this.DIASHAPELIST.splice(0, this.DIASHAPELIST.length); 

        this.$http.post(this.$host+'/GuestDiaShapeList',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list1 = result.data.DiamondShape;
              list1.forEach(element => {
                  this.DIASHAPELIST.push(element); 
              });
              //첫페이지 로드
              this.SEARCH_DIASHAPE = this.DIASHAPELIST[0].IDX;
              this.loadpage();
          }else if(result.data.resultCode == 2){
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });
      },


      loadpage(){
        
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화

        this.loading = true;

          this.$http.post(this.$host+'/DiaImageList',{
            SHAPE_IDX: this.SEARCH_DIASHAPE
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              const list = result.data.resultData;
              list.forEach(element => {
                  this.itemList.push(element); 
              });
              this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        this.loadDiaShape();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        this.progress_dialog = true;
        if(!this.isNullCheck(this.uploadFile1)){
          //이미지 등록
          let upload_cb1 = await this.uploadStorage("enviroment", Date.now().toString()+".jpg", this.uploadFile1);
          if(upload_cb1 == null){
            this.progress_dialog = false;
            this.$fire({
                html: "Image registration error occurred<br><br>이미지 업로드 오류 발생",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            return;
          } 
          this.uploadFile1 = [];
          if(!this.isNullCheck(this.editedItem.DIAIMG_IMG_REF)){
              try{
                //원래는 지워야 하지만 지금 같은 이미지를 강제로 여러곳에 넣어놔서 지우면 안됨. 
                //await this.deletefile(this.editedItem.DIAIMG_IMG_REF);
              }catch(e){
              }
          }
          this.editedItem.DIAIMG_IMG_REF = upload_cb1.resultRef;
        }        

        if(!this.isNullCheck(this.uploadFile2)){
          //파일이 수정된 경우
          let upload_cb2 = await this.uploadStorage("enviroment", Date.now().toString()+".jpg", this.uploadFile2);
          if(upload_cb2 == null){
            this.loading_prog = false;
            this.$fire({
              html: "Business card image upload error<br><br>썸네일 이미지 업로드 오류",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          this.uploadFile2 = [];
          if(!this.isNullCheck(this.editedItem.DIAIMG_THUM_REF)){
            try{
              //원래는 지워야 하지만 지금 같은 이미지를 강제로 여러곳에 넣어놔서 지우면 안됨. 
              //await this.deletefile(this.editedItem.DIAIMG_THUM_REF);
            }catch(e){
            }
          }
          this.editedItem.DIAIMG_THUM_REF = upload_cb2.resultRef;
        }

        if(this.editedItem.DIAIMG_IMG_REF == null || this.editedItem.DIAIMG_IMG_REF == undefined){
          this.$fire({
            html: "이미지가 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(this.editedItem.DIAIMG_THUM_REF == null || this.editedItem.DIAIMG_THUM_REF == undefined){
          this.$fire({
            html: "썸네일 이미지가 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/DiaImageUpdate',{
                SHAPE_IDX: this.editedItem.SHAPE_IDX
              , COLOR_IDX: this.editedItem.COLOR_IDX
              , DIAIMG_IMG: escape(this.editedItem.DIAIMG_IMG_REF)
              , DIAIMG_THUM: escape(this.editedItem.DIAIMG_THUM_REF)
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              this.loading = false;
              this.progress_dialog = false;

              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              this.progress_dialog = false;
                this.$fire({
                  html: "Modification failed.<br><br>수정이 실패했습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.progress_dialog = false;

            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
            });

          });

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage();
      },
      

      //아이템 수정
      edit_item(item){

        this.uploadFile1 = [];
        this.uploadFile2 = [];
        this.previewImg1 = "";
        this.previewImg2 = "";

        this.dialogTitle = "Edit 수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.previewImg1 = this.editedItem.DIAIMG_IMG != null && this.editedItem.DIAIMG_IMG.length > 0 ? this.editedItem.DIAIMG_IMG : "";
        this.previewImg2 = this.editedItem.DIAIMG_THUM != null && this.editedItem.DIAIMG_THUM.length > 0 ? this.editedItem.DIAIMG_THUM : "";
        this.dialog = true;
      },


      //이미지 등록 관련
      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
          this.uploadFile1 = null;
          this.editedItem.DIAIMG_IMG_REF = null;
        }else if(index == 1){
          this.previewImg2 = "";
          this.uploadFile2 = null;
          this.editedItem.DIAIMG_THUM_REF = null;
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }          
          return;
        }      

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            if(this.photo_index == 0){
              this.previewImg1 = event.target.result;
              this.uploadFile1 = file;
            }else{
              this.previewImg2 = event.target.result;
              this.uploadFile2 = file;
            }
            //this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },


            

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>