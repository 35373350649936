import Vue from 'vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'

import App from './App.vue'

import AdminAccnt from './components/AdminAccnt.vue'
import AdminSetting from './components/AdminSetting.vue'
import AdminNotice from './components/AdminNotice.vue'
import AdminEvent from './components/AdminEvent.vue'
import AdminRapa from './components/AdminRapa.vue'
import AdminRapaPdf from './components/AdminRapaPdf.vue'
import AdminProduct from './components/AdminProduct.vue'
import AdminProduct_Backup from './components/AdminProduct_Backup.vue'
import AdminSaleReq from './components/AdminSaleReq.vue'
import AdminInquiry from './components/AdminInquiry.vue'

import AdminTradeReq from './components/AdminTradeReq.vue'
import AdminTradeDownPay from './components/AdminTradeDownPay.vue'
import AdminTrading from './components/AdminTrading.vue'
import AdminTradeCom from './components/AdminTradeCom.vue'
import AdminTradeCancel from './components/AdminTradeCancel.vue'
import AdminTradeCanReq from './components/AdminTradeCanReq.vue'

import StatComplete from './components/StatComplete.vue'
import StatDeposit from './components/StatDeposit.vue'
import StatWithdrawal from './components/StatWithdrawal.vue'
import StatProvider from './components/StatProvider.vue'
import StatDiamond from './components/StatDiamond.vue'
import StatGemstone from './components/StatGemstone.vue'
import StatJewelry from './components/StatJewelry.vue'
import BoardDetail from './components/BoardDetail.vue'
import EventDetail from './components/EventDetail.vue'

import ProductList from './components/ProductList.vue'
import ProductDetail from './components/ProductDetail.vue'
import SaleReqDetail from './components/SaleReqDetail.vue'
import PurchaseDetailList from './components/PurchaseDetailList.vue'

import AdminItem from './components/AdminItem.vue'
import AdminType from './components/AdminType.vue'
import JewelryKind from './components/JewelryKind.vue'
import CertiList from './components/CertiList.vue'
import ShapeList from './components/ShapeList.vue'
import AdminImage from './components/AdminImage.vue'
import TradeList from './components/TradeList.vue'

//공급자
import ProvTradeSend from './components/ProvTradeSend.vue'
import ProvTradeReq from './components/ProvTradeReq.vue'
import ProvTradeCancel from './components/ProvTradeCancel.vue'
import ProvTradeCom from './components/ProvTradeCom.vue'

//소비자
import UserInquiry from './components/UserInquiry.vue'
import UserSaleReq from './components/UserSaleReq.vue'
import UserTradeList from './components/UserTradeList.vue'
import UserTradeCom from './components/UserTradeCom.vue'
import UserFavList from './components/UserFavList.vue'

//퍼블릭(모두 오픈)
import Login from './components/Login.vue'
import Welcome from './components/Welcome.vue'
import MoreInfo from './components/MoreInfo.vue'
import Aboutus from './components/Aboutus.vue'
import VividDiamond from './components/VividDiamond.vue'
import BuyInfo from './components/BuyInfo.vue'
import KSInfo from './components/KSInfo.vue'
import PersonalPolicy from './components/PersonalPolicy.vue'
import FavList from './components/FavList.vue'

import Join from './components/Join.vue'
import Notice from './components/Notice.vue'
import NotFound from './components/NotFound.vue'
import Myinfo from './components/Myinfo.vue'
import ShopInfo from './components/ShopInfo.vue'
import ShopDetail from './components/ShopDetail.vue'
import PaymentList from './components/PaymentList.vue'
import ResultPayment from './components/ResultPayment.vue'
import FindShop from './components/FindShop.vue'
import FindShopMap from './components/FindShopMap.vue'
import FindPrice from './components/FindPrice.vue'

import MyHome from './components/MyHome.vue'

//API
import ApiDoc from './components/ApiDoc.vue'
import apiInsertProduct from './components/apiInsertProduct.vue'
import apiUpdateProduct from './components/apiUpdateProduct.vue'
import apiProductList from './components/apiProductList.vue'
import apiProductDetail from './components/apiProductDetail.vue'
import apiDeleteProduct from './components/apiDeleteProduct.vue'
import apiShapeList from './components/apiShapeList.vue'
import apiCertiList from './components/apiCertiList.vue'
import apiGemJewelryList from './components/apiGemJewelryList.vue'
import apiJewelTypeList from './components/apiJewelTypeList.vue'
import apiJewelItemList from './components/apiJewelItemList.vue'
import apiCommon from './components/apiCommon.vue'

//커스텀 컴포넌트
import DialogAccnt from './components/DialogAccnt.vue'
import DialogUserAccnt from './components/DialogUserAccnt.vue'
import Component_SearchBar from './components/Component_SearchBar.vue'
import Component_PagingBar from './components/Component_PagingBar.vue'
import Component_InputPrice from './components/Component_InputPrice.vue'
import Component_InputWeight from './components/Component_InputWeight.vue'
import Component_UserInfo from './components/Component_UserInfo.vue'
import Component_ReqInfo from './components/Component_ReqInfo.vue'
import Component_PdfInfo from './components/Component_PdfInfo.vue'
import Component_PdfInfoDown from './components/Component_PdfInfoDown.vue'
import Component_ShopDetail from './components/Component_ShopDetail.vue'

import PdfViewer from './components/PdfViewer.vue'
import WebViewer from './components/WebViewer.vue'

//라이브러리
import axios from 'axios'               //http library
import querystring from 'query-string'   //axios랑 함께 써야하는 라이브러리 (post 파라메터 전송)
import VueSessionStorage from 'vue-sessionstorage'  //저장소

//구글맵
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBnylgPkmmJi0b0vGSMR-jU2yH9rGgXM-4',
    libraries: 'places', // 필요한 경우 라이브러리 추가
  },
});

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

//아이프레임
import VueIframe from 'vue-iframes'
Vue.use(VueIframe);

//이미지 뷰어
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import VueSimpleAlert from "vue-simple-alert";
//https://sweetalert2.github.io/#configuration 옵션은 sweetalert2 참고
Vue.use(VueSimpleAlert,{reverseButtons: true, confirmButtonColor:'#000000', allowEscapeKey:true, allowEnterKey:true, confirmButtonText:"OK 확인", cancelButtonText:"Cancel 취소"});  //엔터를 처도 팝업창 클로즈 안함.(sweetalert2 라이브러리 옵션 참고)


//아임포트 설정 - v1방식
var IMP = window.IMP;
IMP.init("imp88132148");
Vue.prototype.$iamport = IMP;

//아임포트 회원가입 후 발급된 가맹점 고유 코드를 사용해주세요.
/*
import IMP from 'vue-iamport'           //아임포트
Vue.use(IMP, 'imp88132148');
Vue.IMP().load();
*/



//커스텀 컴포넌트 등록
Vue.component("dialogaccnt",     DialogAccnt);
Vue.component("dialoguser",      DialogUserAccnt);
Vue.component("ksinfo",          KSInfo);
Vue.component("searchbar", Component_SearchBar);
Vue.component("pagingbar", Component_PagingBar);
Vue.component("inputprice", Component_InputPrice);
Vue.component("inputweight", Component_InputWeight);
Vue.component("userinfo",   Component_UserInfo);
Vue.component("reqinfo",   Component_ReqInfo);
Vue.component("pdfinfo",   Component_PdfInfo);
Vue.component("pdfinfodown",   Component_PdfInfoDown);


Vue.component("shopinfo",   Component_ShopDetail);
Vue.component("vividdiamond",   VividDiamond);

Vue.component("WebViewer",   WebViewer);

//전역변수
Vue.prototype.$http = axios
Vue.prototype.$qstr = querystring

//Vue.prototype.$host = "http://192.168.0.39:8080";   //테스트 서버
Vue.prototype.$host = "https://sndge-wyj2xokuya-du.a.run.app";   //실서버

Vue.config.productionTip = false

//플러그인 사용
Vue.use(VueRouter)
Vue.use(VueSessionStorage)
Vue.use(VueViewer)


//라우터 인스턴스 생성
const router = new VueRouter({
  mode:'history', //history모드 중요함. 이걸로 해야함.
  routes : [
      {path : "/",              component:Welcome}
    , {path : "",               component:Welcome}
    
    , {path : "/Login",         component:Login}
    , {path : "/Login/:code",   component:Login}
    , {path : "/Join",          component:Join}
    , {path : "/Join/:code",    component:Join}
    , {path : "/Aboutus",       component:Aboutus}
    , {path : "/VividDiamond",  component:VividDiamond}
    , {path : "/BuyInfo",       component:BuyInfo}
    , {path : "/KSInfo",        component:KSInfo}
    , {path : "/PersonalPolicy", component:PersonalPolicy}

    , {path : "/AdminAccnt",       component:AdminAccnt}
    , {path : "/AdminSetting",     component:AdminSetting}
    , {path : "/AdminNotice",            component:AdminNotice}
    , {path : "/AdminNotice/:page/:search",      component:AdminNotice}
    , {path : "/AdminRapa",       component:AdminRapa}
    , {path : "/AdminRapaPdf",    component:AdminRapaPdf}

    , {path : "/AdminEvent",                  component:AdminEvent}
    , {path : "/AdminEvent/:page/:search",    component:AdminEvent}
    
    , {path : "/AdminProduct",     component:AdminProduct}
    , {path : "/AdminProduct/:page/:row/:spc/:sdc/:sgc/:sjt/:sji/:search/:sorttype/:sortname/:showdel", component:AdminProduct}

    , {path : "/AdminProduct_Backup",     component:AdminProduct_Backup}
    , {path : "/AdminProduct_Backup/:page/:row/:spc/:sdc/:sgc/:sjt/:sji/:search/:sorttype/:sortname/:showdel", component:AdminProduct_Backup}

    , {path : "/AdminSaleReq",     component:AdminSaleReq}
    , {path : "/AdminSaleReq/:page/:search",     component:AdminSaleReq}
    , {path : "/AdminInquiry",     component:AdminInquiry}
    , {path : "/AdminInquiry/:page/:search",     component:AdminInquiry}

    
    , {path : "/AdminTradeReq",     component:AdminTradeReq}
    , {path : "/AdminTradeReq/:page/:search",     component:AdminTradeReq}
    , {path : "/AdminTradeDownPay", component:AdminTradeDownPay}
    , {path : "/AdminTradeDownPay/:page/:search",     component:AdminTradeDownPay}
    , {path : "/AdminTrading",     component:AdminTrading}
    , {path : "/AdminTrading/:page/:search",     component:AdminTrading}
    , {path : "/AdminTradeCom",    component:AdminTradeCom}
    , {path : "/AdminTradeCom/:page/:search",     component:AdminTradeCom}
    , {path : "/AdminTradeCancel", component:AdminTradeCancel}
    , {path : "/AdminTradeCancel/:page/:search",     component:AdminTradeCancel}
    , {path : "/AdminTradeCanReq", component:AdminTradeCanReq}
    , {path : "/AdminTradeCanReq/:page/:search",     component:AdminTradeCanReq}

    , {path : "/StatComplete",     component:StatComplete}
    , {path : "/StatDeposit",      component:StatDeposit}
    , {path : "/StatWithdrawal",   component:StatWithdrawal}
    , {path : "/StatProvider",     component:StatProvider}
    , {path : "/StatDiamond",      component:StatDiamond}
    , {path : "/StatGemstone",     component:StatGemstone}
    , {path : "/StatJewelry",      component:StatJewelry}
    
    , {path : "/AdminType",        component:AdminType}
    , {path : "/AdminItem",        component:AdminItem}
    , {path : "/JewelryKind",      component:JewelryKind}
    , {path : "/CertiList",        component:CertiList}
    , {path : "/ShapeList",        component:ShapeList}
    , {path : "/AdminImage",       component:AdminImage}

    
    , {path : "/TradeList",         component:TradeList}
    , {path : "/TradeList/:page/:search",      component:TradeList}

    , {path : "/ProvTradeSend",     component:ProvTradeSend}
    , {path : "/ProvTradeSend/:page/:search",     component:ProvTradeSend}
    , {path : "/ProvTradeReq",      component:ProvTradeReq}
    , {path : "/ProvTradeReq/:page/:search",     component:ProvTradeReq}
    , {path : "/ProvTradeCancel",   component:ProvTradeCancel}
    , {path : "/ProvTradeCancel/:page/:search",     component:ProvTradeCancel}
    , {path : "/ProvTradeCom",      component:ProvTradeCom}
    , {path : "/ProvTradeCom/:page/:search",     component:ProvTradeCom}

    , {path : "/UserInquiry",       component:UserInquiry}
    , {path : "/UserInquiry/:page/:search",     component:UserInquiry}
    , {path : "/UserSaleReq",       component:UserSaleReq}
    , {path : "/UserSaleReq/:page/:search",     component:UserSaleReq}
    , {path : "/UserTradeList",     component:UserTradeList}
    , {path : "/UserTradeList/:page/:search",     component:UserTradeList}
    , {path : "/UserFavList",       component:UserFavList}
    , {path : "/UserFavList/:page/:search",     component:UserFavList}
    
    , {path : "/UserTradeCom",      component:UserTradeCom}
    , {path : "/Myinfo",            component:Myinfo}
    , {path : "/ShopInfo",          component:ShopInfo}
    , {path : "/ShopDetail",        component:ShopDetail}
    , {path : "/PaymentList",       component:PaymentList}
    , {path : "/FindShop",          component:FindShop}
    , {path : "/FindShopMap",       component:FindShopMap}
    , {path : "/FindPrice",         component:FindPrice}
    
    
    , {path : "/ResultPayment",     component:ResultPayment}
    
    , {path : "/MyHome",            component:MyHome}
    , {path : "/FavList",            component:FavList}
    
    , {path : "/Notice",           component:Notice}
    , {path : "/Notice/:stype/:search",    component:Notice}
    
    , {path : "/BoardDetail",       component:BoardDetail}
    , {path : "/BoardDetail/:code", component:BoardDetail}
    , {path : "/EventDetail",       component:EventDetail}
    , {path : "/EventDetail/:code", component:EventDetail}

    , {path : "/ProductDetail",       component:ProductDetail}
    , {path : "/ProductDetail/:code", component:ProductDetail}

    , {path : "/SaleReqDetail",       component:SaleReqDetail}
    , {path : "/SaleReqDetail/:code", component:SaleReqDetail}

    , {path : "/PurchaseDetailList",       component:PurchaseDetailList}
    , {path : "/PurchaseDetailList/:code", component:PurchaseDetailList}

    , {path : "/ProductList",        component:ProductList}
    , {path : "/ProductList/:code/:page/:rtype", component:ProductList}

    , {path : "/Welcome",       component:Welcome}
    , {path : "/MoreInfo",       component:MoreInfo}

    , {path : "/PdfViewer",        component:PdfViewer}
    , {path : "/PdfViewer/:url",   component:PdfViewer}

    , {
        path : "/ApiDoc/", component:ApiDoc, redirect:"/ApiDoc/apiCommon",
        children:[
          {path:'apiInsertProduct',component:apiInsertProduct},
          {path:'apiUpdateProduct',component:apiUpdateProduct},
          {path:'apiProductList',component:apiProductList},
          {path:'apiProductDetail',component:apiProductDetail},
          {path:'apiDeleteProduct',component:apiDeleteProduct},
          {path:'apiShapeList',component:apiShapeList},
          {path:'apiCertiList',component:apiCertiList},
          {path:'apiGemJewelryList',component:apiGemJewelryList},
          {path:'apiJewelTypeList',component:apiJewelTypeList},
          {path:'apiJewelItemList',component:apiJewelItemList},
          {path:'apiCommon',component:apiCommon},
        ]
    }

    , {path : "*",              redirect:"/404"}
    , {path : "/404",           component:Welcome}

  ]
})


Vue.prototype.$EventBus = new Vue();

new Vue({
  vuetify,
  render: h => h(App),
  router
}).$mount('#app')
