<template>
  <v-container fluid class="pa-0 ma-0" style="background:white">
    
    <v-row justify="center">
      <v-col cols="12" md="8" align="center" class="mt-5 mt-md-15">
        <div class="mt-5 mt-md-15" 
             style="color:black;font-weight:bold;font-family:NotoSansKR"
             :style="$vuetify.breakpoint.smAndDown ? 'font-size:22px' : 'font-size:36px'"
             >구매안내</div>
        <div style="color:black;font-size:30px;font-family:NotoSansKR"
          :style="$vuetify.breakpoint.smAndDown ? 'font-size:18px' : 'font-size:30px'"
          >Purchase Guide</div>
      </v-col>

      <v-col cols="11" md="8" align="center">
        <div class="mt-1 mt-md-5" style="font-family:NotoSansKR">kDiaGem App에는 KDGE(한국다이아몬드보석거래소)와 제휴를 맺은 전 세계의 다이아몬드 및 보석 회사들이 약 3000억 원 이상의 다양한 제품을 전시하고 있습니다. 또한 kDiaGem App에서는 고객의 주문을 받을 경우 KDGE가 직수입하고 중간 마진이나 불필요한 수수료 없이 공급합니다. 따라서 KDGE가 보증하는 가장 좋은 품질의 상품을 최저 가격으로 구매할 수 있습니다.</div>
        <div class="mt-5 mt-md-5" style="font-family:Noto Serif">On the kDiaGem App, diamond and gemstone companies all over the world that have partnered with KDGE (Korea DIAMOND GEM Exchange) display a variety of products worth about KRW 300 billion. In addition, in kDiaGem App, KDGE imports directly when customers order and supplies without intermediate margins or unnecessary fees. Therefore, you can purchase the best quality products guaranteed by KDGE at the lowest price.</div>
      </v-col>
      <v-col cols="11" md="8" align="center">
        <div class="mt-1 mt-md-5" style="font-family:NotoSansKR">지금껏 그래 왔듯이 천연 광물은 시간이 지나면 그 가치가 상승하고 국제가격이 오르기 때문에 천연 다이아몬드 또는 보석을 구매 후 오랫동안 착용하고 세월이 지날수록 더욱 가치가 높아지는 안전한 미래 투자가치를 지니고 있습니다.</div>
        <div class="mt-5 mt-md-5" style="font-family:Noto Serif">As has always been the case, natural minerals increase in value and international prices over time, so they are worn for a long time after purchasing natural diamonds or gemstones, and have a safe future investment value that increases in value as the years pass.</div>
      </v-col>

    </v-row>

    <v-row justify="center" class="mt-5 mt-md-15">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >구매 절차</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                        >Purchase Procedure</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content style="padding:0px !important;">
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>구매자가 특정 상품에 대해 구매요청을 하시면 본사 담당자가 먼저 이메일로 전화를 드린다는 안내를 합니다.</li>
                        <li>구매 문의한 상품은 KDGE가 철저하게 상품의 품질 등을 확인 한 후 요청하신 상품에 조금이라도 문제가 있을 경우에는 본사에서 책임지고 보증 할 수 있는 대체 상품을 추천 할 수 있습니다.</li>
                        <li>본사는 GIA 감정서 중에서 엄격한 평가등급 세분화 감정 기준을 통과한 다이아몬드만을 VIVID DIAMOND Certificate를 발행하여 기존 GIA Certificate를 함께 첨부하여 납품합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                        <li>When a buyer makes a purchase request for a specific product, a representative from our headquarters will first contact them via email.</li>
                        <li>After KDGE has thoroughly checked the quality of the product you have inquired about purchasing, if there is any problem with the requested product, we may recommend a replacement product that our head office can take responsibility for and guarantee.</li>
                        <li>Our company issues a VIVID DIAMOND Certificate only for diamonds that have passed the strict evaluation grading standards among the GIA Certificates and delivers them with the existing GIA Certificate attached.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>


    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >결제 방법</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Payment Method</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>불필요한 수수료를 없애고 최저가로 공급을 하기 위해 모든 상품의 대금 결제는 본사 통장 거래로만 가능합니다.</li>
                        <li>대금 결제<br>
                            (1)주문 시 상품대금 100% 전액을 입금할 경우 0.5% DC 혜택을 드립니다.<br>
                            (2)10%의 계약금(구매보증금)을 지불하고 인수하면서 잔액 90%를 지불할 수 있습니다.<br>
                        </li>
                        <li>모든 상품은 정식 수입을 하기 때문에 부가세 10%가 포함되어 있으며, 현금영수증 또는 세금계산서를 발행합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                        <li>In order to eliminate unnecessary fees and supply at the lowest price, payment for all products can only be made through the head office's bank account transaction.</li>
                        <li>Payment<br>
                            (1)If you deposit 100% of the product price when ordering, you will receive a 0.5% DC benefit.<br>
                            (2)You can pay 10% down payment (purchase deposit) and pay the remaining 90% upon takeover.<br>
                        </li>
                        <li>All products are formally imported, so 10% VAT is included, and cash receipts or tax invoices are issued.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >납품 절차</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Delivery Procedure</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>납품 기간은 상품대금 전액 또는 계약금(10%)을 KDGE 통장으로 입금한 날로부터 7일~14일이 소요됩니다.</li>
                        <li>납품 방법은 주문한 소매상에서 찾으실 수 있고, 고객이 지정하는 장소로 KDGE 직원이 직접 납품해 드립니다.</li>
                        <li>또한 고객이 희망할 경우 본사를 방문하여 직접 확인 후 인수하실 수도 있습니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                        <li>The delivery period takes 7 to 14 days from the date the full product price or down payment (10%) is deposited into KDGE bank account.</li>
                        <li>The delivery method can be found at the retailer where the order was placed, and KDGE staff will deliver directly to the location designated by the customer.</li>
                        <li>In addition, if the customer wishes, he or she can visit the head office and directly check and take over.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >품질보증</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Warranty</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                      <li>kDiaGem App에 판매하는 모든 상품은 KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 품질을 보증 합니다.</li>
                      <li>또한 구매한 상품으로 소비자 피해가 발생하면 KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 100% 보상 책임을 집니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                      <li>All products sold on kDiaGem App are guaranteed by KDGE&lt;Korea DIAMOND GEM Exchange&gt;.</li>
                      <li>In addition, if consumer damage occurs with the purchased product, KDGE &lt;Korea DIAMOND GEM Exchange&gt; is fully responsible for 100% compensation.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                    >환매 기준</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Repurchase Standards</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                      <li>KDGE는 판매한 다이아몬드 또는 보석 및 주얼리에 대해 구매자가 판매를 원할 경우 다음 환매 기준에 따라 재매입을 보장합니다.<br>
                        (1) VIVID DIAMOND 및 보석 나석은 환매 당시 판매가의 80%에 재매입 (VAT 포함)<br>
                        (2) 일반 DIAMOND 및 고가 보석이 세팅된 주얼리는 판매가의 70%에 재매입 (VAT 포함)<br>
                        (3) 순금주얼리 : 순금 실 중량 × 국제시세<br>
                        (4) 준보석 및 패션주얼리 : 감정평가 후 결정<br>
                      </li>
                      <li>환매하려는 상품이 구매자의 부주의로 인하여 크게 파손되었거나 KDGE가 판매한 상품이 아닌 경우에는 환매 기준이 적용되지 않습니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                      <li>KDGE guarantees the repurchase of sold diamonds, gemstones or jewelry according to the following repurchase standards if the buyer wants to sell them.<br>
                        (1) VIVID DIAMOND and polished gemstones are repurchased at 80% of the selling price at the time of redemption (VAT included)<br>
                        (2) Jewelry set with regular diamonds and expensive gemstones can be repurchased at 70% of the selling price (VAT included)<br>
                        (3) Pure gold jewelry: Actual weight of pure gold × International market price<br>
                        (4) Semi-precious stones and fashion jewelry: Decided after appraisal<br>
                      </li>
                      <li>If the product to be redeemed is significantly damaged due to the buyer's negligence or is not a product sold by KDGE, the repurchase standard does not apply.</li>        
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                    >구매 취소</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Cancel Purchase</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                      <li>구매자는 상품대금이나 10%의 계약금(구매 보증금)을 KDGE 본사의 통장으로 송금하기 전까지는 언제든지 구매를 취소할 수 있습니다.</li>
                      <li>주문하신 상품이 아니거나 상품에 하자가 있는 경우에는 아무 조건 없이 취소할 수 있으며 100% 환불해 드립니다.</li>
                      <li>그러나 상품 주문 후 천재지변이 아닌 단순 변심에 의해 구매 취소할 경우 계약금(구매보증금) 또는 상품 가격의 10%는 환불해 드리지 않습니다.</li>
                      <li>그 이유는 해외 수입에 따른 비용과 감정 수수료 및 부가세 등의 비용이 발생하기 때문입니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                      <li>The purchaser may cancel the purchase at any time until the product price or 10% down payment (purchase deposit) is remitted to the bank account of KDGE headquarters.</li>
                      <li>If the product is not what you ordered or if the product is defective, you can cancel without any conditions and we will give you a 100% refund.</li>
                      <li>However, if the purchase is canceled due to a simple change of mind, not a natural disaster after ordering the product, the down payment (purchase deposit) or 10% of the product price will not be refunded.</li>
                      <li>The reason is that there are costs related to overseas imports, appraisal fees, and VAT.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                    >주의사항</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Caution</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>다이아몬드 또는 보석에 첨부되어 있는 이미지(사진)는 실물과 다를 수 있습니다.</li>
                        <li>따라서 반드시 첨부된 다이아몬드 감정서를 참고하여 KDGE에 문의하시면 친절하게 안내 해 드립니다.</li>
                        <li>궁금한 점은 언제든지 회원가입이 필요 없는 채팅 상담 (채널톡)을 이용해 실시간 소통하실 수 있습니다.</li>
                        <li>데이터베이스권 침해 금지<br>본 kDiaGem 웹사이트 및 앱에 게시된 사진, 링크, 동영상을 포함하는 모든 정보에 대해 수집, 가공, 복제를 할 수 없습니다. 이를 어길 경우 저작권법에 의거하여 처벌받게 되며, 그 행위자는 본사의 손해배상 청구를 전적으로 책임져야 함을 고지합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                      <li>Images (photos) attached to diamonds or gemstones may differ from the actual product.</li>
                      <li>Therefore, if you make an inquiry to KDGE referring to the attached diamond appraisal certificate, we will kindly guide you.</li>
                      <li>If you have any questions, you can communicate in real time using a Chat Inquiry(channel.io) that does not require membership registration at any time.</li>
                      <li>Prohibition of Infringement of Database Rights<br>All information including photos, links, and videos posted on this kDiaGem  website and app cannot be collected, processed, or reproduced. If you violate this, you will be punished according to the copyright law, and we inform you that the violator must be fully responsible for claims for damages from the head office.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                    >분쟁 해결</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif;"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Dispute Resolution</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                      <li>본 약관에 적시되지 않은 사항들은 kDiaGem APP의 이용약관과 전자상거래 관련 법률에 의거합니다. 그러나 만약 소비자와 본사간의 법적 분쟁이 발생할 경우에는 관할 법원을 서울중앙지방법원으로 정합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                      <li>Matters not specified in these terms and conditions are governed by the terms of use of kDiaGem APP and e-commerce related laws. However, if a legal dispute arises between the consumer and the head office, the competent court is determined as the Seoul Central District Court.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    
    
    <v-row align="center" justify="center" class="pt-10 pb-10">
        <v-col cols="6" md="4" class="ma-0 pa-0">
            <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="goback">Cancel 닫기</v-btn>
        </v-col>
    </v-row>

  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    created(){

    },

    methods: {

        goback(){
            this.$router.back();
        }

    },



}
</script>

<style scoped>

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>