<template>

  <v-container fluid class="container_bg">
    
    <!-- 추가/수정 팝업 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '800px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">TITLE&nbsp;<span class="dialog_subtitle_kor">제목</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.BOARD_TITLE"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-2 ma-0">
                  <v-col md="5" cols="12">

                    <v-row dense align="center" justify="center" class="ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Notice Type&nbsp;<span class="dialog_subtitle_kor">타입</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.BOARD_TYPE" :items="TYPELIST" 
                          dense
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                  </v-col>
                  <v-col md="5" cols="12">

                    <v-row dense align="center" justify="center" class="ma-0">
                      <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                        <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">FIX&nbsp;<span class="dialog_subtitle_kor">상단고정</span></p>
                      </v-col>
                      <v-col cols="12" md="10" class="ma-0 pa-0">
                        <v-select 
                          v-model="editedItem.BOARD_FIX" :items="FIXLIST" 
                          dense
                          item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                          outlined color="#000000" hide-details class="none_radius"
                          />
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <editor
                      :initialValue="editorText"
                      :options="editorOptions"
                      height="500px"
                      initialEditType="wysiwyg"
                      previewStyle="vertical"
                      ref="toastuiEditor"
                      />
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" md="10" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px"  @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 업로드 프로그레스 -->
    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <v-col cols="12" md="2" class="pa-0 pr-0 pr-md-2" align="center">         
        <v-btn style="background:red" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">
          <v-icon left>mdi-plus</v-icon>Add<br>추가
        </v-btn>
      </v-col>
      
  </v-row>
  
  <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
    <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
  </v-row>
    
  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
        item-key="BOARD_NO"
        :loading="loading"
        item-class="itemstyle"
        mobile-breakpoint="0"
        >

        <template v-slot:header.BOARD_IDX>
          <a :class="sort_name=='BOARD_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('BOARD_IDX')">Notice No.<br>번호</a>
        </template>
        <template v-slot:header.BOARD_TITLE>
          <a :class="sort_name=='BOARD_TITLE'?'cheader_sortselect':'cheader_sort'" style="text-decoration: underline;" @click="customsort('BOARD_TITLE')">Notice Title<br>제목</a>
        </template>
        <template v-slot:header.BOARD_TYPE>
          <a :class="sort_name=='BOARD_TYPE'?'cheader_sortselect':'cheader_sort'" style="text-decoration: underline;" @click="customsort('BOARD_TYPE')">Notice Type<br>타입</a>
        </template>
        <template v-slot:header.BOARD_FIX>
          <label class="cheader_normal">Top Fix<br>상단고정</label>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>등록일</a>
        </template>

        <template v-slot:item.BOARD_TITLE="{ item }">  
          <template v-if="item.BOARD_FIX == 1">
            <a style="color:red" @click="loadnotice(item)" >{{item.BOARD_TITLE}}</a>
          </template>
          <template v-else>
            <a @click="loadnotice(item)" >{{item.BOARD_TITLE}}</a>
          </template>
        </template>

        <template v-slot:item.BOARD_FIX="{ item }">  
          <label style="text-align:center">{{ item.BOARD_FIX == 1 ? '사용' : '미사용' }}</label>
        </template>     
        <template v-slot:item.BOARD_TYPE="{ item }">  
          <label style="text-align:center">{{ item.BOARD_TYPE == 0 ? '공지' : '뉴스' }}</label>
        </template>     

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>

  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>

<script>
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import cmvalidation from '../assets/js/cmvalidation.js'

export default {

    components:{
      'editor':Editor,
      //quillEditor
    },

    data: () => ({


      editorOption: {
          // some quill options
      }      ,


      editor:null,
      editorText: {},
      editorOptions : {
        
                        //링크 클릭시 뉴탭으로 표시되도록 처리
                        linkAttribute: {
                          target: '_blank',
                          contenteditable: 'false',
                          rel: 'noopener noreferrer'          
                        },

                        minHeight: '200px',
                        language: 'ko',
                        useCommandShortcut: true,
                        useDefaultHTMLSanitizer: true,
                        usageStatistics: false,
                        hideModeSwitch: true, //markdown wysiwyg 토글 숨기기
                        plugins:[colorSyntax],
                        toolbarItems: [
                          'heading',
                          'bold',
                          'italic',
                          'strike',
                          'divider',
                          //'hr',
                          'quote',
                          'divider',
                          'ul',
                          'ol',
                          'task',
                          //'indent',
                          //'outdent',
                          'divider',
                          //'table',
                          'image',
                          'link',
                          'divider',
                          //'code',
                          //'codeblock'
                        ],
                      },
      
      uploadFile : [],
      descImgs:[],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      progress_dialog:false,
      progress_value:0,
      defaultItem: {
          BOARD_IDX:0,
          BOARD_TITLE:'',
          BOARD_DESC:'',
          BOARD_FIX:0,
          BOARD_TYPE:0,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {text: '번호', value: 'BOARD_IDX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '제목', value: 'BOARD_TITLE', sortable: false, align:'center', width:'320px', class:"cheader_normal"},
        {text: '타입', value: 'BOARD_TYPE', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '상단고정', value: 'BOARD_FIX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '등록일시', value: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
        {text: '수정/삭제', value: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
         
      TYPELIST: [
          {name:'공지사항',   value:0}
        , {name:'뉴스',       value:1}
      ],
      FIXLIST: [
          {name:'사용안함',     value:0}
        , {name:'상단고정',     value:1}
      ],

    }),


    beforeDestroy() {
      if(this.editor != null){
        this.editor.destroy()
      }
    },

    mounted(){
      if(this.$session.get("GRADE") < 90){
        this.$fire({
          html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.$router.back();
          }
        });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    create(){
      
    },

    methods: {

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminNotice", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/BoardList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    if(element.FILE1 != undefined && element.FILE1 != null && element.FILE1.length > 0){
                      const last = element.FILE1.split("/")
                      element.FILENAME =  decodeURI(last[last.length-1]);
                    }else{
                      element.FILENAME = "";
                    }
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }

        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }

        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = [];
        }, 300)
      },

      //추가 저장
      async save () {

        const checktitle = cmvalidation.checkValReg(this.editedItem.BOARD_TITLE, null, 5, 50);
        if(checktitle == 1){
          this.$fire({
            html: "Please enter a title<br><br>제목을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktitle == 3){
          this.$fire({
            html: "Please Enter title in 5 characters or then<br><br>제목은 5자 이상 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checktitle == 4){
          this.$fire({
            html: "Please Enter title in 50 characters or less<br><br>제목은 50자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        //에디터에서 값 가져오기
        this.editedItem.BOARD_DESC = this.$refs.toastuiEditor.invoke('getHtml');
        if(this.editedItem.BOARD_DESC == undefined || this.editedItem.BOARD_DESC.length < 1){
          this.$fire({
            html: "Please enter a description<br><br>내용을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        } 
        
        this.progress_dialog = true;

        //에디터에서 data_url을 이미지 url로 변환한다.
        const temp_div = document.createElement("div");
        temp_div.innerHTML = this.editedItem.BOARD_DESC;
        const imgs = temp_div.getElementsByTagName("img");
        const tempImgs = [];
        for(const img of imgs){
          const imgstr = String(img.src);
          if(imgstr.startsWith("data:image")){          
            let filename = Date.now().toString()+".jpg";
            let upload_cb = await this.uploadStorage("notice", filename, this.dataURLtoFile(imgstr, filename));
            //console.log("upload cb : " + JSON.stringify(upload_cb));
            if(upload_cb.resultCode == 0){
              img.src = "https://storage.googleapis.com/sndge-3f7bb.appspot.com/"+upload_cb.resultRef;
            }
          }else if(imgstr.startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){
            tempImgs.push(imgstr);
          }
        }
        //교체
        this.editedItem.BOARD_DESC = temp_div.innerHTML;
        this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.BOARD_DESC);

        //상세페이지에서 빠진 이미지 삭제
        
        for(const img1 of this.descImgs){
          var flag = false;
          for(const img2 of tempImgs){
            if(img1 == img2){
              flag = true;  //여전히 존재함.
              break;
            }
          }
          if(flag == false){
            //해당 이미지는 제거된 상태임 파베에서 제거 처리
            try{
              await this.deletefile(img1.toString().replace("https://storage.googleapis.com/sndge-3f7bb.appspot.com/", ""));
            }catch(e){
              //console.log("delete error");
            }
          }
        }

        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/BoardUpdate', {
              BOARD_TITLE: escape(this.editedItem.BOARD_TITLE)
            , BOARD_DESC: escape(this.editedItem.BOARD_DESC)
            , BOARD_FIX: this.editedItem.BOARD_FIX
            , BOARD_TYPE: this.editedItem.BOARD_TYPE
            , BOARD_IDX: this.editedItem.BOARD_IDX
          }, {headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          });

        }else{

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/BoardInsert',{
              BOARD_TITLE: escape(this.editedItem.BOARD_TITLE)
            , BOARD_DESC: escape(this.editedItem.BOARD_DESC)
            , BOARD_TYPE: this.editedItem.BOARD_TYPE
            , BOARD_FIX: this.editedItem.BOARD_FIX
          },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                const p = this.page;
                this.page = 0;

              this.$fire({
                html: "Notice has been added.<br><br>공지사항이 추가되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage(p);
                }
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });

        }

        this.close()
      },      

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },      

      //신규 추가
      newinsert(){
        this.uploadFile = [];
        this.descImgs = [];
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)

        this.editedItem.BOARD_DESC  = "";
        this.editorText = this.editedItem.BOARD_DESC;
        this.dialog = true;

        this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.BOARD_DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.            
        
      },

      
      //게시글 상세보기
      loadnotice(item){
        
        this.$router.push({path:"/BoardDetail", query:{code:item.BOARD_IDX}});

      },
      //아이템 수정
      edit_item(item){
        
        this.descImgs = [];

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestBoardDetail',{
          BOARD_IDX: item.BOARD_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            
            this.dialogTitle = "수정하기"
            this.editedIndex = this.itemList.indexOf(item)
            //this.editedItem = item;
            this.editedItem = Object.assign({}, item);

            //이미 선택된 아이템에 상세만 교체
            this.editedItem.BOARD_DESC  = result.data.resultData[0].BOARD_DESC;

            //에디터 등록된 이미지 URL을 파악한다.
            const temp_div = document.createElement("div");
            temp_div.innerHTML = this.editedItem.BOARD_DESC;
            const imgs = temp_div.getElementsByTagName("img");
            for(const img of imgs){
              const imgstr = String(img.src);
              if(imgstr.startsWith("https://storage.googleapis.com/sndge-3f7bb.appspot.com")){          
                this.descImgs.push(imgstr);
              }
            }


            //2중으로 처리해줘야 해당 에디터가 정상 작동해보인다.
            this.editorText = this.editedItem.BOARD_DESC;
            this.dialog = true;
            this.$refs.toastuiEditor.invoke('setHtml', this.editedItem.BOARD_DESC); //팝업이 떠서 에디터가 생성된 후 읽을 수 있다.

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      //아이템 삭제
      delete_item(item){


        this.$fire({
          html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/BoardDelete',{
              BOARD_IDX:item.BOARD_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.$fire({
                  html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    const p = this.page;
                    this.page = 0;
                    this.loadpage(p);
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });                  

          }
        });        

      },


      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },      

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>