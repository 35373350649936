<!-- 현재 해당 페이지는 사용안함 -->
<template>

    <v-container fluid class="container_bg">
      
      <!-- 프로그레스 -->
      <v-row>
        <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_prog" max-width="300px">
          <v-container class="d-flex flex-column justify-center align-center" 
                       style="background-color:white;width:300px;height:300px">
            <v-row class="ma-8">
              <v-progress-circular
                :size="100"
                :width="15"
                color="#000000"
                indeterminate
                >
              </v-progress-circular>
            </v-row>
            <v-row class="ma-5">
              <label style="text-align:center">Please wait...</label>
            </v-row>
          </v-container >
        </v-dialog>
      </v-row>
  
    <v-row align="center" justify="start" class="mt-5">
        <v-col cols="9" md="3">
          <label class="menunamestyle">내 주변 소매점</label>
        </v-col>
        <v-spacer/>
        <v-col cols="3" class="hidden-md-and-up">
          <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
        </v-col>
    </v-row>
  
    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label>
        <label class="total_label text-md-subtitle-2 text-caption me-auto">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-2 mt-md-0" dense>
      
      <!-- 모바일 해상도 -->
       <div class="hidden-md-and-up" :style="`height:${$vuetify.breakpoint.height * 0.7}px;overflow:auto`">
        <v-col cols="12"  v-for="item in itemList" v-bind:key="item.ACCNT_IDX"> 
            <div class="d-flex align-start mb-2" @click="showdetail(item)">
                <img width="100" height="56" :src="item.IMG1_URL"/>
                <div class="d-flex flex-column ml-2 mr-2 me-auto" style="overflow:hidden">
                    <div class="shop_title_mb">{{ item.SHOPNAME }}</div>
                    <div class="d-flex align-start">
                        <v-icon color="black" size="16">mdi-map-marker</v-icon>
                        <div class="d-flex flex-column ml-1">
                            <div class="shop_addr_mb ">{{ item.ADDR1 }}</div>
                            <div class="shop_addr_mb">{{ item.ADDR2 }}</div>
                        </div>
                    </div>
                    <!-- 
                    <div class="d-flex align-center">
                        <v-icon  color="black" size="16">mdi-phone</v-icon>
                        <div class="shop_addr_mb ml-1">{{ item.SHOPTEL }}</div>
                    </div>
                    -->
                </div>
                <div class="shop_addr_mb align-self-top text-center" style="width:50px">
                    {{ item.DISTANCE_NAME }}
                </div>
            </div>            
        </v-col>
       </div>

      <!-- PC 해상도 -->
      <v-col class="hidden-sm-and-down"> 
        <!-- 테이블 -->
        <v-data-table
          class="datatablestyle"
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          :items-per-page="pagerow"
          item-key="IDX"
          :loading="loading"
          mobile-breakpoint="0"
          :height="$vuetify.breakpoint.height * 0.7"
          >
          <template v-slot:header.SHOPNAME>
            <a :class="sort_name=='SHOPNAME'?'cheader_sortselect':'cheader_sort'" style="text-decoration: underline;" @click="customsort('SHOPNAME')">상점명</a>
          </template>
          <template v-slot:header.DISTANCE_NAME>
            <a :class="sort_name=='SHOPNAME'?'cheader_sortselect':'cheader_sort'" style="text-decoration: underline;" @click="customsort('DISTANCE')">거리</a>
          </template>

          <template v-slot:item.IMG1="{ item }">  
            <v-col class="pa-0" align="center">
              <template v-if="item.IMG1_URL != null && item.IMG1_URL.length > 0">
                <v-img :src="item.IMG1_URL" style="width:100px;hegith:56px" contain/>
              </template>
              <template v-else>
                <v-icon style="height:100px;width:56px" size="50">mdi-image</v-icon>
              </template>
            </v-col>
          </template>
          <template v-slot:item.SHOPNAME="{ item }">  
            <a style="text-align:center" @click="showdetail(item)">{{ item.SHOPNAME }}</a>
          </template>     
          <template v-slot:item.ADDR="{ item }">  
            <label style="text-align:center">{{ item.ADDR1 + " " + item.ADDR2 }}</label>
          </template>     
  
        </v-data-table>
      </v-col>
    </v-row>


    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run" hint="상점명이나 지역명 검색"/>
    
    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" :page="page" @loadpage="movepage"/>
    

    <div class="circle-button-map"
      style="position:fixed;right:20px;bottom:80px;"   
      @click="showmap">
    </div>

    </v-container>
  </template>
  
  <script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
  <script>
  import 'cropperjs/dist/cropper.css';
  import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
  import cmvalidation from '../assets/js/cmvalidation.js'
  export default {
      components:{
        VueCropper
      },
  
      data: () => ({

        LATITUTE: null,
        LONGITUTE: null,
        RADIUS: 600,

        //필수 페이징 파라메터
        totalcnt : 0,       //전체 아이템 개수
        page : 0,
        pagerow : 20,       //한 페이지에 보여줄 row수
        loading : false,    //로딩 표시
        loading_prog : false,    //로딩 표시
  
        //CRUD 관련
        dialogTitle: '추가하기',   //다이얼로그 제목
        editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
        dialog:false,
        defaultItem: {
            ACCNT_IDX:'',
            ACCNT:'',
            REFIMAGE:'',
            GRADE:1,
            STATE:0,
            COMMENT_STATE:0
        },
        editedItem: {
        },
  
        //테이블 정의
        headers: [
          {text: '대표이미지', value: 'IMG1', width:80, sortable: false, align:'center', class:"cheader_normal"},
          {text: '상점명', value: 'SHOPNAME', width:200, sortable: false, align:'center', class:"cheader_normal"},
          {text: '주소', value: 'ADDR', width:200, sortable: false, align:'center', class:"cheader_normal"},
          {text: '연락처', value: 'SHOPTEL', width:150, sortable: false, align:'center', class:"cheader_normal" },
          {text: '거리', value: 'DISTANCE_NAME', width:100, sortable: false, align:'center', class:"cheader_normal" },
        ],
        itemList: [],
  
        //검색 파라메터
        sort_name: 'DISTANCE',
        sort_type: false,    //true : DESC, false: ASC
        search_keyword : '',
           
      }),
  
      mounted(){
        window.scrollTo(0,0);
        this.initpage();
      },

      //eslint-disable-next-line no-unused-vars
      beforeRouteLeave(to, from, next){
        next();
      },
  
      create(){
        
      },
  
      methods: {

        //위치정보 가져오기
        getNearbyStores() {
            if (navigator.geolocation) {
                console.log("gps 1");
                navigator.geolocation.getCurrentPosition(this.sendLocationToServer);
            } else {
                console.log("gps 2");
                this.$fire({
                    html: "The location information is not available, so it will be inquired to the default location.<br><br>위치 정보가 제공되지 않아 기본 위치로 조회됩니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
                    cancelButtonColor:'#ff0000',
                    showCancelButton: true, 
                    reverseButtons: true,
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                    }).then(r => {
                        if(r.value){
                            this.LATITUTE  = 37.5049142;
                            this.LONGITUTE = 127.0049151;
                            this.loadpage(this.page);
                        }
                });                        
                
            }
        },        

        async sendLocationToServer(position) {
            
            this.LATITUTE  = position.coords.latitude;
            this.LONGITUTE = position.coords.longitude;

            console.log(`LATITUTE : ${this.LATITUTE}`);
            console.log(`LONGITUTE : ${this.LONGITUTE}`);

            if(this.LATITUTE == null || this.LATITUTE == undefined || this.LONGITUTE == null || this.LONGITUTE == undefined){
                this.$fire({
                    html: "The location information is not available, so it will be inquired to the default location.<br><br>위치 정보가 제공되지 않아 기본 위치로 조회됩니다.",
                    confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                    confirmButtonColor: '#000000',
                    cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
                    cancelButtonColor:'#ff0000',
                    showCancelButton: true, 
                    reverseButtons: true,
                    allowEscapeKey:false, 
                    allowOutsideClick:false,
                    allowEnterKey:true,
                    }).then(r => {
                        if(r.value){
                            this.LATITUTE  = 37.5049142;
                            this.LONGITUTE = 127.0049151;
                            this.loadpage(this.page);
                        }
                    });                        
            }else{
                this.loadpage(this.page);
            }
        },        
        
        showdetail(item){
            this.$router.push({path:"/ShopDetail", query:{code:item.ACCNT_IDX}});
        },

        showmap(){
            this.$router.push({path:"/FindShopMap", query:{}});
        },

        //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
        movepage(p){
          this.$router.push({path:"/FindShop", query:{page:p, row:this.pagerow, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name }}).catch(()=>{
            this.loadpage(1);
          });
        },
  
        changecombo(){
          this.movepage(1);
        },      
  
        customsort(sortname){
          this.sort_name = sortname;
          this.sort_type = !this.sort_type;
          this.$router.push({path:"/FindShop", query:{page:this.page, row:this.pagerow, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name }});
        },
  
        loadpage(p){
  
            this.loading = true;
            this.page = p;
            this.$refs.paging.setpage(this.page);
  
            if(this.LATITUTE == null || this.LONGITUTE == null){
              this.LATITUTE  = 37.5049142;
              this.LONGITUTE = 127.0049151;
            }

            this.$http.post(this.$host+'/GuestFindShop',{
                  search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
                , sort_name:this.sort_name
                , sort_type: this.sort_type==true ? "DESC" : "ASC" 
                , page: this.page
                , pagerow: this.pagerow
                , RADIUS: this.RADIUS
                , LATITUTE: this.LATITUTE
                , LONGITUTE: this.LONGITUTE
            })
            .then((result)=>{
              
              if(result.data.resultCode == 0){
  
                  //토큰 저장
                  if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                    this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  }
                  if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                    this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  }
  
                  this.totalcnt = result.data.totalcnt;
                  this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
  
                  const list = result.data.resultData;
                  this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                  list.forEach(element => {
                      this.itemList.push(element); 
                  });
  
                  this.loading = false;
  
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.loading = false;
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
              }else{
                this.loading = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              if(error.message == 'Request failed with status code 429')
              {
                this.$fire({
                  html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
  
              }else{
                this.$fire({
                  html: error.message,
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            });
  
        },
  
        initpage(){
  
          //get 파라메터 로드
          this.restorepage();
          
          //위치정보 조회
          this.getNearbyStores();


        },      
  
  
      //get 파라메터 가져오기
      restorepage(){
        
        //페이지 및 검색조건 복원
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
  
        if(this.$route.query.row == undefined || this.$route.query.row == null){
          this.pagerow = 20;
        }else{
          this.pagerow = Number(this.$route.query.row);
        }
  
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }
  
        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'DISTANCE';
        }
  
      },      
  
  
        //검색
        search_run(searchkeyword){
          if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
            this.search_keyword = searchkeyword;
          }else{
            this.search_keyword = '';
          }
          this.movepage(1);
        },
  
        
  
      },
    
  }
  </script>
  
  <!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
  <style scoped>
  
  .shop_title_mb{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 15px;
    font-weight: bold;
  }
  .shop_addr_mb{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 13px;
  }

  .map_text{
    font-family: 'Inter';
    font-style: normal;
    color: #000000;
    font-size: 12px;
  }
  div ::v-deep .gm-ui-hover-effect {
    display: none !important;   
  }
  .custom-close {
    background: white;
    border: none;
    cursor: pointer;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  
  </style>