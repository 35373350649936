<template>
  <v-container fluid class="container_bg">
    
    <v-btn fab x-small icon color="white" style="background:black"
      fixed right @click="drawer = !drawer"
      >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    
    <v-row>
      <v-navigation-drawer
        v-model="drawer"
        color="#ffffff" 
        absolute
        right
        >
      <v-list
        nav
        dense
        >
        <v-list-item-group
          active-class="deep-purple--text text--accent-4"
          >

          <v-list-item>
            <v-list-item-title @click="nav('apiCommon')">Common</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiInsertProduct')">apiInsertProduct</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiUpdateProduct')">apiUpdateProduct</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiProductList')">apiProductList</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiProductDetail')">apiProductDetail</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiDeleteProduct')">apiDeleteProduct</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="nav('apiShapeList')">apiShapeList</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="nav('apiCertiList')">apiCertiList</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="nav('apiGemJewelryList')">apiGemJewelryList</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="nav('apiJewelTypeList')">apiJewelTypeList</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="nav('apiJewelItemList')">apiJewelItemList</v-list-item-title>
          </v-list-item>

          

        </v-list-item-group>
      </v-list>
      </v-navigation-drawer>
    <router-view />
    </v-row>
    
    
  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({
      drawer: true
    }),

    methods: {

      nav(name){
        this.$router.push("/ApiDoc/"+name).catch(()=>{});
      }

    },

    created(){

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>