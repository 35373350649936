<template>
  <v-container fluid class="container_bg">

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h1>apiProductList</h1>
      </v-col>
    </v-row>
    
    <v-divider/> 
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <p>Look up the shape code.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>HTTP Request</h3>
      </v-col>
    </v-row>
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">POST</v-col>
      <v-col cols="12" md="8" class="fielddesc">/apiProductList</v-col>
    </v-row>
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Request Body</h3>
        The request body contains data with the following structure.<br>Request Body is in the form x-www-form-urlencoded.
      </v-col>
    </v-row>
    
    <v-row justify="center" dense>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PAGE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Number<br>Page number, default 1</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PAGEROW</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Number<br>Number of products to show per page, default 20</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_CLASS</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">Number | <strong>Required</strong><br>
              Product type code
            </td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">0</td>
            <td width="80%">Diamond</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">1</td>
            <td width="80%">Gemstone</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">2</td>
            <td width="80%">Jewelry</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SORT_NAME</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">String<br>
              Sort By Value, Default PRODUCT_IDX
            </td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">PRODUCT_IDX</td>
            <td width="80%">Sort by PRODUCT_IDX</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">REG_DATE</td>
            <td width="80%">Sort by REG_DATE</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SORT_TYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">String<br>
              Defining ascending and descending order when sorting, Default DESC
            </td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">DESC</td>
            <td width="80%">Sort by Descending</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">ASC</td>
            <td width="80%">Sort in ascending</td>
          </tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SEARCH_KEYWORD</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">String<br>Search PRODUCT_NUMBER or PRODUCT_STOCK as the entered search keywords.</v-col>

    </v-row>
    
    <v-row justify="center" dense class="mt-5">
      <v-col cols="12" md="11">
        <h3>Response Body</h3>
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12" md="11" class="fieldname"><h4>JSON representation</h4></v-col>
      <v-col cols="12" md="11" class="fielddesc pa-0">
        <pre translate="no" style="background:#fafafa">
        {
            "totalcnt": 100,
            "resultCode": 0,
            "resultData": [
                {
                    "PRODUCT_IDX": 2814,
                    "ACCNT_IDX": 3,
                    "NAME": "넥스퀘어233",
                    "PRODUCT_NUMBER": "necTest1234",
                    "PRODUCT_STOCK": "12asdf123123",
                    "PRODUCT_CLASS": 0,
                    "DISPLAY_STATE": 0,
                    "PRODUCT_IMG1": "https://storage.googleapis.com/sndge-3f7bb.appspot.com/product/3/1670408907422.jpg",
                    "THUM_IMG1": "https://storage.googleapis.com/sndge-3f7bb.appspot.com/product/3/1670408907422.jpg",
                    "PRICE_TYPE": 0,
                    "PRICE_CALCTYPE": 0,
                    "PRICE_DP": 0,
                    "CALC_PRICE": 1000000,
                    "SUPPLY_PRICE": 1000000,
                    "WEIGHT": "1 ct",
                    "REG_DATE": "2023-02-03 23:20:38",
                    "DIAMOND_CLASS": 0,
                    "DIAMONDTYPE": 0,
                    "FLUORESCENCE": 0,
                    "TRANSPARENCY": 0,
                    "COLOR": 0,
                    "EVALUATION": 0,
                    "DIAMONDCOLOR": null,
                    "CERTI_IDX": 2,
                    "CERTI_NAME": "VIVID",
                    "METALWEIGHT": null,
                    "METALTYPE": null,
                    "MAINSTONE": "null",
                    "MAINWEIGHT": ""
                }
                ...
            ]
        }
        </pre>
      </v-col>
      
    </v-row>

    <v-row justify="center" dense class="mt-5 mb-10">
      <v-col cols="12" md="11" class="fieldname"><h4>Fields</h4></v-col>

      <!-- 공통 -->
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IDX</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product index code</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">ACCNT_IDX</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Registration Account Index</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">NAME</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Registration Account Name</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_NUMBER</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Name</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_STOCK</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Stock Number</v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_CLASS</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr>
            <td width="100%" colspan="2">Product type code</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">0</td>
            <td width="80%">Diamond</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">1</td>
            <td width="80%">Gemstone</td>
          </tr>
          <tr>
            <td width="20%" style="text-align:center">2</td>
            <td width="80%">Jewelry</td>
          </tr>
        </table>
      </v-col>

      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DISPLAY_STATE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Product Display Status</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Display Off</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Display On</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRODUCT_IMG1</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Representative Image URL</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">THUM_IMG1</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product thumbnail image URL</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_TYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Currency classification of product price</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">USD</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">WON</td></tr>
        </table>
      </v-col>      
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_CALCTYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Set the pricing method.</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Direct input method, use the price entered in SUPPLY_PRICE.</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Price per weight method, the price entered in SUPPLY_PRICE<br>is multiplied by WEIGHT to calculate the price.</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Based on the RAPAPORT market method, the price reflects the RAPAPORT market price.<br>RAPAPORT * WEIGHT * PRICE_DC</td></tr>
        </table>
      </v-col>      
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">PRICE_DC</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Use only when PRICE_CALCTYPE is 2 (using RAPAPORT).<br>Calibrates the supply price according to the price entered.minus is DC and plus is ADD.<br>EX) -25 = DC 25% EX) 10 = ADD 10%</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CALC_PRICE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Actual supply price calculated according to PRICE_CALCTYPE</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">SUPPLY_PRICE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">It means the supply price of the product. The supply price entered is divided into USD and WON according to PRICE_TYPE.</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">WEIGHT</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Diamond, Gemstone means product weight. However, jewelry refers to metal weight.</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">REG_DATE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product First Registration Date</v-col>
      
      <!-- 다이아몬드 전용 -->
      <v-col cols="12" md="11" class="fielddivider d-flex align-center">Diamond only field</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMOND_CLASS</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">the classification of diamonds<br>Used only PRODUCT_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Diamond</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Colored Diamond</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDTYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means the detailed type of diamond.<br>Used only PRODUCT_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Natural Diamond</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Lab Grown Diamond</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">FLUORESCENCE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means Fluorescence of diamonds.<br>Used only PRODUCT_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">None</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Faint</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">Faint Slight</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">Very Slight</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">Slight</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Medium</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">Strong</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">Very Strong</td></tr>
          <tr><td width="20%" style="text-align:center">99</td><td width="80%">N/A</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">TRANSPARENCY</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">Diamond transparency, used only in diamonds.<br>Used only PRODUCT_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">FL</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">IF</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">VVS1</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">VVS2</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">VS1</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">VS2</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">SI1</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">SI2</td></tr>
          <tr><td width="20%" style="text-align:center">8</td><td width="80%">SI3</td></tr>
          <tr><td width="20%" style="text-align:center">9</td><td width="80%">I1</td></tr>
          <tr><td width="20%" style="text-align:center">10</td><td width="80%">I2</td></tr>
          <tr><td width="20%" style="text-align:center">11</td><td width="80%">I3</td></tr>
        </table>
      </v-col>

      <!-- 일반 다이아몬드 전용 DIAMOND_CLASS=0 -->
      <v-col cols="12" md="11" class="fielddivider d-flex align-center">Diamond Class 0: normal Diamond only field</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">COLOR</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">diamond color.<br>Used only Diamond PRODUCT_CLASS=0 DIAMOND_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">D</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">E</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">F</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">G</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">H</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">I</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">J</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">K</td></tr>
          <tr><td width="20%" style="text-align:center">8</td><td width="80%">L</td></tr>
          <tr><td width="20%" style="text-align:center">9</td><td width="80%">M</td></tr>
          <tr><td width="20%" style="text-align:center">10</td><td width="80%">N</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">EVALUATION</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means Cut Grade of a regular diamond. Product classification Diamond classification of diamonds Used in regular diamonds.<br>Used only PRODUCT_CLASS=0 DIAMOND_CLASS=0</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Excellent</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Very Good</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Good</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">Fair</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">Poor</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">Ideal</td></tr>
          <tr><td width="20%" style="text-align:center">9</td><td width="80%">No Grade</td></tr>
        </table>
      </v-col>

      <!-- 컬러 다이아몬드 전용 -->
      <v-col cols="12" md="11" class="fielddivider d-flex align-center">Diamond Class 1 : colored diamond only field</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">DIAMONDCOLOR</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means the color of the colored diamond.<br>Used only PRODUCT_CLASS=0 DIAMOND_CLASS=1</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Red</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">Blue</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Pink</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">Yellow</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">Green</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">Brown</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">Black</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">Other</td></tr>
        </table>
      </v-col>

      <!-- 다이아 젬스톤 공통 -->
      <v-col cols="12" md="11" class="fielddivider d-flex align-center">Diamond and Gemstone common field</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CERTI_IDX</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Laboratory Index.<br>Used only Diamond Gemstone. PRODUCT_CLASS=0 or 1</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">CERTI_NAME</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Product Laboratory Name<br>Used only PRODUCT_CLASS=0 or 1</v-col>

      <!-- 주얼리 전용 -->
      <v-col cols="12" md="11" class="fielddivider d-flex align-center">Jewelry only field</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">METALWEIGHT</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means the kind of jewelry Metal Content.<br>Used only PRODUCT_CLASS=2</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">24k</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">22k</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">20k</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">18k</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">14k</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">12k</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">10k</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">999</td></tr>
          <tr><td width="20%" style="text-align:center">8</td><td width="80%">995</td></tr>
          <tr><td width="20%" style="text-align:center">9</td><td width="80%">950</td></tr>
          <tr><td width="20%" style="text-align:center">10</td><td width="80%">900</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">METALTYPE</v-col>
      <v-col cols="12" md="8" class="pa-0" align-center="center">
        <table>
          <tr><td width="100%" colspan="2">It means the kind of jewelry Metal Type.<br>Used only PRODUCT_CLASS=2</td></tr>
          <tr><td width="20%" style="text-align:center">0</td><td width="80%">Yellow Gold</td></tr>
          <tr><td width="20%" style="text-align:center">1</td><td width="80%">White Gold</td></tr>
          <tr><td width="20%" style="text-align:center">2</td><td width="80%">Pink Gold</td></tr>
          <tr><td width="20%" style="text-align:center">3</td><td width="80%">Pure Gold</td></tr>
          <tr><td width="20%" style="text-align:center">4</td><td width="80%">Platinum</td></tr>
          <tr><td width="20%" style="text-align:center">5</td><td width="80%">Silver</td></tr>
          <tr><td width="20%" style="text-align:center">6</td><td width="80%">Titanium</td></tr>
          <tr><td width="20%" style="text-align:center">7</td><td width="80%">Other</td></tr>
        </table>
      </v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MAINSTONE</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Jewelry Mainstone Name<br>Used only PRODUCT_CLASS=2</v-col>
      <v-col cols="12" md="3" class="fieldname d-flex justify-center align-center">MAINWEIGHT</v-col>
      <v-col cols="12" md="8" class="fielddesc" align-center="center">Weight of Jewelry Mainstone<br>Used only PRODUCT_CLASS=2</v-col>

    </v-row>



  </v-container>
</template>
<script>

export default {

    mounted() {

    },

    data: () => ({

    }),

    methods: {

      test(){



      }

    },

    created(){
      //apiProductList
    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

table{
  width: 100%;
  border-collapse: collapse;
}
th, td {
  border: 1px solid #eaeaea;
  padding: 10px;
}
.fieldname {
  background: #f1f3f4;
  border: 1px solid #eaeaea;
}
.fielddivider {
  background: #464646;
  border: 1px solid #eaeaea;
  color: white;
}
.fielddesc {
  border: 1px solid #eaeaea;
  padding-left: 15px;
  padding-right: 15px;
}

</style>