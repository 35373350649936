<template>
  <v-container fluid class="container_bg">

    <!-- 프로그레스바 -->
    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>      
        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />

    <!-- 구매요청 상세정보 -->
    <reqinfo ref="reqinfo" />

    <!-- 구매요청 정보 -->
    <v-row align="center"  justify="center" >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="buy_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">      

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Purchase Request&nbsp;구매요청</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.ADDRESS" disabled
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="buyItem.MESSAGE" disabled color="black" hide-details outlined dense/>
                  </v-col>
                </v-row>                

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Quantity&nbsp;<span class="dialog_subtitle_kor">수량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.QUANTITY" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Price&nbsp;<span class="dialog_subtitle_kor">가격</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="buyItem.PRICE" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                

            
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="buy_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    


    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>


          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>    

    <!-- 계약금 입금 정보 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="downpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Deposit information&nbsp;계약금입금 정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="downpayItem.PRICE_TYPE == 0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(USD)&nbsp;<span class="dialog_subtitle_kor">입금액(달러)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.PRICE_USD_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>           

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" v-if="downpayItem.PRICE_TYPE == 1">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit amount(WON)&nbsp;<span class="dialog_subtitle_kor">입금액(원화)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="downpayItem.PRICE_WON_DP" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>           

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="downpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 발송요청 팝업 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="next_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Delivery request&nbsp;발송요청</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Enter the address and name to be sent from the supplier for product inspection<br><span class="dialog_subtitle_kor">공급처로부터 상품 검수를 위해 발송받을 주소와 이름을 입력하세요</span></p>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Recipient Name&nbsp;<span class="dialog_subtitle_kor">수령자이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="SEND_NAME" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Address&nbsp;<span class="dialog_subtitle_kor">수령주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea height="70"
                      class="none_radius" color="black"
                      single-line v-model="SEND_ADDRESS" 
                      outlined dense hide-details/>
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-btn class="search_btn_freesize ml-2"  elevation="0" outlined large @click="postcode">주소검색</v-btn>
                  </v-col>
                </v-row>        

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">요청메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="SEND_COMMENT" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>        

                <div class="black_line mt-5"/>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Currency&nbsp;<span class="dialog_subtitle_kor">화폐</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="SEND_PRICE_TYPE" :items="PRICETYPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" 
                      outlined color="#000000" hide-details class="none_radius" @change="currency_change"
                      />
                  </v-col>
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p style="color:red" class="text-body-2 text-left ma-0 pa-0 dialog_subtitle_kor">선택하신 화폐로 공급가를 입금하셔야 합니다.</p>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Supply Price&nbsp;<span class="dialog_subtitle_kor">공급가</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                  </v-col>
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p style="color:red" class="text-body-2 text-left ma-0 pa-0 dialog_subtitle_kor">{{ SEND_PRICE_TYPE == 0 ? '$ '+SEND_PRICE_USD+' USD' : '₩ '+SEND_PRICE_WON +' 원' }}</p>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Deposit Supply Price&nbsp;<span class="dialog_subtitle_kor">입금 공급가</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"  
                      single-line v-model="SEND_PROVIDER_PRICE"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Invoice Image&nbsp;<span class="dialog_subtitle_kor">인보이스 이미지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;bottom:5px;margin-left:0px;height:40px;width:240px;background-color:#00000080">
                              <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img  style="hegiht:auto" width="240" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border2" align="center" justify="center">
                          <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>


              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="next_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="next_save">Request<br>발송요청</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 거래 취소 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancellation of transaction<br>거래취소</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">모든 거래를 취소합니다.<br>해당 거래는 거래취소 리스트로 이관됩니다.</p>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="cancel_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancel_save">Cancel Confirm<br>취소실행</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>        
    




    <!-- 계약금 입금 강제처리 -->
    <v-row align="center"  justify="center">

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="admin_downpay_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Waiting for deposit&nbsp;계약금입금 대기중</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">
                      waiting for the deposit.<br>If you enter the name of the depositor and press the Save button, you will be forced to confirm the deposit.<br>
                      <span class="dialog_subtitle_kor">현재 계약금 입금을 대기중입니다.<br>입금자 이름을 입력 후 저장버튼을 누르시면 강제로 계약금 입금확인 처리가 됩니다.</span></p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Depositor&nbsp;<span class="dialog_subtitle_kor">입금자</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="admin_down_name" 
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="admin_downpay_dialog = false">Close 닫기</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="AdminSendDownPay">강제 입금처리</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    






    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="4" class="pa-0 pl-3 pl-md-0 pr-2 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>
        
    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>

    <v-row align="start" class="mt-5 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
            
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
          <template v-slot:header.PURCHASE_IDX>
            <a :class="sort_name=='PURCHASE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PURCHASE_IDX')">No.<br>번호</a>
          </template>
          <template v-slot:header.PRODUCT_NUMBER>
            <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
          </template>
          <template v-slot:header.PROV_NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Vendor<br>공급처</label>
          </template>
          <template v-slot:header.QUANTITY>
            <label class="cheader_normal">Quantity<br>수량</label>
          </template>
          <template v-slot:header.SUPPLY_PRICE>
            <label class="cheader_normal">Supply Price<br>공급가</label>
          </template>
          <template v-slot:header.PRICE_DP_USD>
            <label class="cheader_normal">Price<br>판매가</label>
          </template>
          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal" style="text-decoration: underline;">State<br>상태</label>
          </template>
          <template v-slot:header.REG_DATE>
            <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">Registration Date<br>구매일시</a>
          </template>
          <template v-slot:header.BNAME>
            <label class="cheader_normal">Name<br>이름</label>
          </template>
          <template v-slot:header.NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Buyer<br>구매자</label>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">processing<br>요청처리</label>
          </template>
          <template v-slot:header.cancel>
            <label class="cheader_normal">Cancel<br>취소</label>
          </template>

          <template v-slot:item.PURCHASE_IDX="{ item }">  
            <a @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{item.PURCHASE_IDX}}</a>
          </template>
          <template v-slot:item.PRODUCT_NUMBER="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
          </template>

          <template v-slot:item.PURCHASE_STATENAME="{ item }">  
            <template v-if="item.PURCHASE_STATE == 2">
              <!-- <a style="color:red" @click="loadbuyinfo(item)">{{item.PURCHASE_STATENAME}}</a> -->
              <a style="color:red" @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{item.PURCHASE_STATENAME}}</a>
            </template>
            <template v-else-if="item.PURCHASE_STATE == 4">
              <a @click="loaddownpay(item)">{{item.PURCHASE_STATENAME}}</a>
            </template>
          </template>

          <template v-slot:item.PRICE_DP_USD="{ item }">  
            <!-- <div>{{ item.PRICE_TYPE == 0 ? '$ '+item.PRICE_DP_USD : item.PRICE_DP_WON +' 원' }}</div> -->
            <label>{{'₩ '+item.PRICE_DP_WON}}</label><br>
            <label>{{'$ '+item.PRICE_DP_USD}}</label>
          </template>

          <template v-slot:item.SUPPLY_PRICE="{ item }">  
            <label>{{'₩ '+item.SUPPLY_PRICE_WON_DP}}</label><br>
            <label>{{'$ '+item.SUPPLY_PRICE_USD_DP}}</label>
          </template>

          <template v-slot:item.PROV_NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.PROV_IDX)" >{{item.PROV_NAME}}</a>
          </template>
          <template v-slot:item.BNAME="{ item }">  
            <label>{{item.BNAME}}</label>
          </template>

          <template v-slot:item.NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.NAME}}</a>
          </template>

          <template v-slot:item.actions="{ item }">  
              <v-icon small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  
          <template v-slot:item.cancel="{ item }">  
              <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
          </template>  

        </v-data-table>
      </v-col>
    </v-row>

    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>

<script type="text/javascript" src="//dapi.kakao.com/v2/maps/sdk.js?appkey=28865c287f3d5614a255dcb548ef5d42&libraries=services"></script>
<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    components:{
      VueCropper,
    },

    mounted() {

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',

      //구매요청정보
      buy_dialog:false,
      buyItem:{},

      next_dialog:false,
      cancel_dialog:false,
      editedItem: {},

      //발송요청 정보
      SEND_NAME:"",
      SEND_ADDRESS:"",
      SEND_COMMENT:"",
      SEND_INVOICE_IMG:"",
      
      SEND_PRICE_USD: 0,
      SEND_PRICE_WON: 0,
      SEND_PROVIDER_PRICE: 0,
      SEND_PRICE_TYPE: 0,
      PRICETYPELIST: [
          {name:'USD/달러',   value:0}
        , {name:'WON/원화',   value:1}
      ],

      progress_dialog:false,
      progress_value:0,

      uploadFile1 : [],
      previewImg1:"",
      photo_index:0,
      crop_dialog:false,
      imgSrc:'',

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:300,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 1000,
        limitMinSize: [500, 500]
      },            


      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'200px', class:"cheader_normal"},
        {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '수량', value: 'QUANTITY', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '공급가', value: 'SUPPLY_PRICE', sortable: false, align:'right', width:'140px', class:"cheader_normal"},
        {text: '판매가', value: 'PRICE_DP_USD', sortable: false, align:'right', width:'140px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '요청일자', value: 'REG_DATE', sortable: false, align:'center' , width:'180px', class:"cheader_normal"},
        {text: '이름', value: 'BNAME', sortable: false, align:'center' , width:'120px', class:"cheader_normal"},
        {text: '구매자', value: 'NAME', sortable: false, align:'center' , width:'120px', class:"cheader_normal"},
        {text: '처리', value: 'actions', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '삭제', value: 'cancel', sortable: false, align:'center', width:'60px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

      //계약금 입금 정보
      downpayItem:{},
      downpay_dialog:false,

      //계약금 강제 처리
      admin_down_name:"",
      admin_downpay_dialog:false,

    }),

    created(){

    },

    methods: {
      
      currency_change(){
        if(this.SEND_PRICE_TYPE == 0){
          this.SEND_PROVIDER_PRICE = this.SEND_PRICE_USD;
        }else{
          this.SEND_PROVIDER_PRICE = this.SEND_PRICE_WON;
        }
      },


      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      
      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminTradeDownPay", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/AdminDownPaymentList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },

      //이미지 선택시
      changefile(file, pindex){
        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile1 = [];
          return;
        }
        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}
      },      


      //이미지 크롭시
      cropimg(){
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }
          });
        });
      },      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },      

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },

      async next_save(){

        const checkprice = cmvalidation.checkValReg(this.SEND_PROVIDER_PRICE, /[^0-9.,]/g, -1, 20); 
        if(checkprice == 1){
          this.$fire({
            html: "Enter actual deposit amount<br><br>실제 입금액을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkprice == 2){
          this.loading = false;
          this.$fire({
            html: "deposit amount can only be entered in numbers and .,<br><br>입금액은 숫자와 .,만 입력이 가능합니다)",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkprice == 4){
          this.loading = false;
          this.$fire({
            html: "Please enter the deposit amount in 20 characters or less<br><br>입금액은 20자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }        

        const checkname = cmvalidation.checkValReg(this.SEND_NAME, null, -1, 30);
        if(checkname == 1){
          this.$fire({
            html: "Please enter recipient name<br><br>수령자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 4){
          this.$fire({
            html: "Please enter the recipient's name up to 30 characters<br><br>수령자 이름은 30자 이내로 입력해주세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        const checkaddr = cmvalidation.checkValReg(this.SEND_ADDRESS, null, -1, 100);
        if(checkaddr == 1){
          this.$fire({
            html: "Please enter your destination address<br><br>수령자 주소를 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkaddr == 4){
          this.$fire({
            html: "Please enter the address up to 100 characters<br><br>수령자 주소는 100자 이내로 입력해주세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(cmvalidation.isNullCheck(this.SEND_COMMENT)){
          const checkcomment = cmvalidation.checkValReg(this.SEND_COMMENT, null, -1, 200);
          if(checkcomment == 4){
            this.$fire({
              html: "Please enter the message up to 200 characters<br><br>요청 메시지는 200자 이내로 입력해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }

        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){
          this.$fire({
            html: "Please register the invoice image<br><br>인보이스 이미지를 등록하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;

        }else{
          this.progress_dialog = true;            
          let upload_cb1 = await this.uploadStorage("product", this.editedItem.PROV_IDX+"/"+Date.now().toString()+".jpg", this.uploadFile1);
          if(upload_cb1 == null){
            this.progress_dialog = false;
            this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
            return;
          } 
          this.uploadFile1 = [];
          this.SEND_INVOICE_IMG = upload_cb1.resultRef;
        }        

        //발송요청
        this.$http.post(this.$host+'/AdminSendRequest', {
            PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , PROV_IDX: this.editedItem.PROV_IDX
          , NAME:this.SEND_NAME == null || this.SEND_NAME == undefined ? '' : escape(this.SEND_NAME)
          , ADDRESS:this.SEND_ADDRESS == null || this.SEND_ADDRESS == undefined ? '' : escape(this.SEND_ADDRESS)
          , COMMENT:this.SEND_COMMENT == null || this.SEND_COMMENT == undefined ? '' : escape(this.SEND_COMMENT)
          , INVOICE_IMG:this.SEND_INVOICE_IMG
          , PROV_ACCNT:this.editedItem.PROV_ACCNT
          , ACCNT:this.editedItem.ACCNT
          , PRICE_TYPE: this.SEND_PRICE_TYPE
          , SEND_PRICE: parseFloat(this.SEND_PROVIDER_PRICE.replaceAll(",", ""))
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.next_dialog = false;
          this.progress_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.next_dialog = false;
          this.progress_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });
        

      },


      //거래 강제 취소
      cancel_save(){

        this.$http.post(this.$host+'/AdminCancelPurchaseForce', {
          PURCHASE_IDX: this.editedItem.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.cancel_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Cancellation completed.<br><br>취소가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.cancel_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },



      //구매요청 정보
      loadbuyinfo(item){

        this.$http.post(this.$host+'/PuchaseDetail',{
          PURCHASE_IDX:item.PURCHASE_IDX
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.buyItem      = result.data.resultData[0];
              this.buy_dialog   = true;
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },      


      loaddownpay(item){

        //계약금 입금 정보 가져오기
        this.$http.post(this.$host+'/AdminDownPayDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "There is no deposit information.<br><br>입금 정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.downpayItem = result.data.resultData[0];
                this.downpay_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.downpay_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },




      //계약금 입금 강제처리
      AdminSendDownPay(){
       const checkname = cmvalidation.checkValReg(this.admin_down_name, null, -1, 50);
       if(checkname == 1){
          this.$fire({
            html: "Please enter the name of the depositor<br><br>입금자 이름을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }else if(checkname == 4){
          this.$fire({
            html: "Please enter the name of the depositor in up to 50 characters<br><br>입금자 이름은 50자 이내로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
       }

        this.$http.post(this.$host+'/AdminSendDownPay', {
            PRODUCT_IDX: this.editedItem.PRODUCT_IDX
          , PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , NAME: escape(this.admin_down_name)
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.admin_downpay_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Your request has been completed.<br><br>요청이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });

          }else{
            this.$fire({
              html: "Request failed<br><br>요청 실패 " + result.data.resultMsg,
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.search_run();
              }
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.admin_downpay_dialog = false;
          this.$fire({
          html: "Request failed<br><br>요청 실패",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
        });

      },









      //아이템 수정
      edit_item(item){
        
        this.editedItem = Object.assign({}, item);

        if(item.PURCHASE_STATE == 2){
          //계약금 강제처리 팝업 띄우기
          this.admin_downpay_dialog = true;
          /*
          this.$fire({
            html: "Waiting for deposit of down payment<br><br>계약금 입금을 대기중입니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          */
          return;
        }
        
        this.SEND_NAME = "";
        this.SEND_ADDRESS = "";
        this.SEND_COMMENT = "";
        this.SEND_INVOICE_IMG = "";
        this.SEND_PRICE_USD = item.SUPPLY_PRICE_USD_DP;
        this.SEND_PRICE_WON = item.SUPPLY_PRICE_WON_DP;
        this.SEND_PRICE_TYPE = 0;
        this.currency_change();
        this.previewImg1 = "";
        this.uploadFile1 = [];
        this.next_dialog = true;
      },

      //아이템 삭제
      delete_item(item){

        this.$fire({
          html: "Are you sure you want to abort the transaction and force it to cancel?<br><br>해당 거래를 중단하고 강제로 취소하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){
            this.editedItem = Object.assign({}, item);
            this.cancel_dialog = true;
          }
        });

      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      



      postcodetoAddr(addr){
        this.SEND_ADDRESS = addr;
        this.$forceUpdate();
      },

      postcode(){

        //콜백에서 못찾아서 콘스트로 미리 빼둔다.
        const callback_addrfunction     = this.postcodetoAddr;

        new daum.Postcode({
            oncomplete: function(data) {

                //console.log(JSON.stringify(data));
                var addr = data.address; // 최종 주소 변수
                if(data.userSelectedType == "J"){
                  //지번
                  addr = data.jibunAddress; 
                }

                //주소 정보를 해당 필드에 넣는다.
                callback_addrfunction(addr);

            }
        }).open();

      },      







    },



}
</script>

<style scoped>
  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }
</style>