<template>
  <v-container fluid class="container_bg">


    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading_prog" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">Please wait...</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <v-row align="center" justify="center" dense class="mt-5">
      <v-col cols="12" md="10" align="center"> <!-- 해상도 조절 컬럼 -->

        <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
          <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex align-center">
            <v-icon color="black">mdi-shopping</v-icon>&nbsp;&nbsp;
            <p class="text-md-h6 text-subtitle-1 text-left ma-0 pa-0 subtitle_text">
              Purchase request&nbsp;<span class="dialog_subtitle_kor">구매현황</span></p>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-col cols="12" md="10" class="pa-0 ma-0">
            <div class="black_line"/>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="10" md="9" class="ma-0 pa-0">
            <a class="d-flex align-center">
            <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 sublabel_text">
              Purchase status&nbsp;<span class="dialog_subtitle_kor">구매요청</span></p>
            <v-spacer/>
            <div class="blue_round" style="margin-left:10px">99</div>
            </a>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-col cols="10" md="9" class="pa-0 ma-0">
            <div class="gray_line"/>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="10" md="9" class="ma-0 pa-0">
            <a class="d-flex align-center">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 sublabel_text">
                Purchase status&nbsp;<span class="dialog_subtitle_kor">구매요청</span></p>
              <v-spacer/>
              <div class="blue_round" style="margin-left:10px">99</div>
            </a>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-col cols="10" md="9" class="pa-0 ma-0">
            <div class="gray_line"/>
          </v-col>
        </v-row>


        <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
          <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex align-center">
            <v-icon color="black">mdi-close-circle</v-icon>&nbsp;&nbsp;
            <p class="text-md-h6 text-subtitle-1 text-left ma-0 pa-0 subtitle_text">
              Cancellation status&nbsp;<span class="dialog_subtitle_kor">취소현황</span></p>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-col cols="12" md="10" class="pa-0 ma-0">
            <div class="black_line"/>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center" class="mt-5 mt-md-10 ma-0">
          <v-col cols="12" md="10" align="start" class="ma-0 pa-0 d-flex align-center">
            <v-icon color="black">mdi-text-short</v-icon>&nbsp;&nbsp;
            <p class="text-md-h6 text-subtitle-1 text-left ma-0 pa-0 subtitle_text">
              Shortcut&nbsp;<span class="dialog_subtitle_kor">바로가기</span></p>
          </v-col>
        </v-row>
        <v-row dense align="center" justify="center">
          <v-col cols="12" md="10" class="pa-0 ma-0">
            <div class="black_line"/>
          </v-col>
        </v-row>


        <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <a class="d-flex align-center btn_round" style="padding-left:20px;">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text">
                My Info&nbsp;<span class="dialog_subtitle_kor">내정보</span></p>
              <v-spacer/>
              <v-icon style="margin-right:10px" color="black">mdi-chevron-right</v-icon>
            </a>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center" class="mt-3 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <a class="d-flex align-center btn_round" style="padding-left:20px;">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text">
                Purchase&nbsp;<span class="dialog_subtitle_kor">거래내역</span></p>
              <v-spacer/>
              <v-icon style="margin-right:10px" color="black">mdi-chevron-right</v-icon>
            </a>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center" class="mt-3 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <a class="d-flex align-center btn_round" style="padding-left:20px;">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text">
                Inquiry&nbsp;<span class="dialog_subtitle_kor">문의내역</span></p>
              <v-spacer/>
              <v-icon style="margin-right:10px" color="black">mdi-chevron-right</v-icon>
            </a>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center" class="mt-3 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <a class="d-flex align-center btn_round" style="padding-left:20px;">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text">
                Sales request&nbsp;<span class="dialog_subtitle_kor">판매요청</span></p>
              <v-spacer/>
              <v-icon style="margin-right:10px" color="black">mdi-chevron-right</v-icon>
            </a>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center" class="mt-3 mb-3 mt-md-3 mb-md-2 ma-0">
          <v-col cols="12" md="10" class="ma-0 pa-0">
            <a class="d-flex align-center btn_round" style="padding-left:20px;">
              <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text">
                Favorite&nbsp;<span class="dialog_subtitle_kor">즐겨찾기</span></p>
              <v-spacer/>
              <v-icon style="margin-right:10px" color="black">mdi-chevron-right</v-icon>
            </a>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리

export default {

    components:{
      VueCropper
    },

    mounted() {
      this.loadpage();
    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },


    data: () => ({

      //파일 업로드
      ratio: 16/9,

      uploadFile1 : [],
      uploadFile2 : [],
      previewImg1:"",
      previewImg2:"",
      crop_dialog:false,
      photo_index:0,
      imgSrc:'',

      options: {
        focus:false,
      },      
      editedItem: {
      },
      loading_prog : false,

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

    }),

    created(){

    },

    methods: {

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }else if(index == 1){
          this.previewImg2 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },      

      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      

      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }

          });

        });
      },

      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },
      
      loadpage(){

          this.$http.post(this.$host+'/AcntDetail',{
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            if(result.data.resultCode == 0){
                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }
                this.editedItem = result.data.resultData[0];

                this.previewImg   = this.editedItem.BUSINESS_IMG != null && this.editedItem.BUSINESS_IMG.length > 0 ? this.editedItem.BUSINESS_IMG : "";
                this.previewImg2  = this.editedItem.CARD_IMG != null && this.editedItem.CARD_IMG.length > 0 ? this.editedItem.CARD_IMG : "";

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      //다이얼로그 닫기
      close () {
        this.$router.back();
      },      

            //추가 저장
      async save () {

        this.loading_prog = true;

        if(this.editedItem.GRADE > 1){

          if(this.editedItem.NAME.length < 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter your Company name<br><br>업체명을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          if(this.editedItem.NAME.length > 50){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter no more than 50 characters for your Company name<br><br>업체명은 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }

          if(this.editedItem.BUSINESSNUMBER.length < 1){
            this.loading_prog = false;
            this.$fire({
              html: "Enter business number (up to 50 characters)<br><br>사업자번호를 입력하세요(최대 50자)",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 
          if(this.editedItem.BUSINESSNUMBER.length > 50){
              this.loading_prog = false;
              this.$fire({
                html: "Please Enter the business number in 50 characters or less<br><br>사업자번호는 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }

          if(this.editedItem.BUSINESSITEM.length < 1){
              this.loading_prog = false;
              this.$fire({
                html: "Enter business status/item<br><br>업태/종목을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          if(this.editedItem.BUSINESSITEM.length > 50){
            this.loading_prog = false;
              this.$fire({
                html: "Please Enter business type/item in 50 characters or less<br><br>업태/종목은 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }

          if(this.editedItem.BUSINESSITEM.length < 1){
            this.loading_prog = false;
              this.$fire({
                html: "Enter business status/item<br><br>업태/종목을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          if(this.editedItem.BUSINESSITEM.length > 50){
            this.loading_prog = false;
              this.$fire({
                html: "Please Enter business type/item in 50 characters or less<br><br>업태/종목은 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }

          if(this.editedItem.TEL.length < 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter your phone number<br><br>연락처를 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          if(this.editedItem.TEL.length > 50){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter the phone number in 50 characters or less<br><br>전화번호는 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          const mobileVal1 = /[^0-9-]/g;
          if(mobileVal1.test(this.editedItem.TEL)){
            this.loading_prog = false;
            this.$fire({
              html: "Phone numbers can only be entered in numbers and - <br><br>전화번호는 숫자와 -만 입력이 가능합니다",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          } 


          //사업자등록증 사진
          if(!this.isNullCheck(this.uploadFile1)){
            
            //파일이 수정된 경우
            let upload_cb1 = await this.uploadStorage("company", Date.now().toString()+".jpg", this.uploadFile1);
            if(upload_cb1 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business license image upload error<br><br>사업자등록증 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile1 = [];

            if(!this.isNullCheck(this.editedItem.BUSINESS_REF)){
              try{
                await this.deletefile(this.editedItem.BUSINESS_REF);
              }catch(e){
              }
            }
            this.editedItem.BUSINESS_REF = upload_cb1.resultRef;

          }

          //명함 사진
          if(!this.isNullCheck(this.uploadFile2)){
            //파일이 수정된 경우
            let upload_cb2 = await this.uploadStorage("company", Date.now().toString()+".png", this.uploadFile2);
            if(upload_cb2 == null){
              this.loading_prog = false;
              this.$fire({
                html: "Business license image upload error<br><br>사업자등록증 이미지 업로드 오류",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
            }
            this.uploadFile2 = [];

            if(!this.isNullCheck(this.editedItem.CARD_REF)){
              try{
                await this.deletefile(this.editedItem.CARD_REF);
              }catch(e){
              }
            }
            this.editedItem.CARD_REF = upload_cb2.resultRef;
          }

        }else{
          //소비자는 해당 이미지 필요 없음
          this.editedItem.BUSINESS_REF  = "";
          this.editedItem.CARD_REF      = "";

          if(this.editedItem.NAME.length < 1){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter your name<br><br>이름을 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          if(this.editedItem.NAME.length > 50){
            this.loading_prog = false;
              this.$fire({
                html: "Please enter no more than 50 characters for your name<br><br>이름은 50자 이하로 입력하세요",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              return;
          }
          
        }
              
        this.$http.post(this.$host+'/UserAcntUpdate',{
              NAME: escape(this.editedItem.NAME)
            , BUSINESSNUMBER: this.editedItem.BUSINESSNUMBER == undefined || this.editedItem.BUSINESSNUMBER.length < 1 ? '' : escape(this.editedItem.BUSINESSNUMBER)
            , BUSINESSITEM: this.editedItem.BUSINESSITEM == undefined || this.editedItem.BUSINESSITEM.length < 1 ? '' : escape(this.editedItem.BUSINESSITEM)
            , ADDRESS: this.editedItem.ADDRESS == undefined || this.editedItem.ADDRESS.length < 1 ? '' : escape(this.editedItem.ADDRESS) 
            , BUSINESS_IMG: this.editedItem.BUSINESS_REF == null || this.editedItem.BUSINESS_REF == undefined ? '' : escape(this.editedItem.BUSINESS_REF)
            , CARD_IMG: this.editedItem.CARD_REF == null || this.editedItem.CARD_REF == undefined ? '' : escape(this.editedItem.CARD_REF)
            , TEL: this.editedItem.TEL == undefined || this.editedItem.TEL.length < 1 ? '' : escape(this.editedItem.TEL)
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.loading_prog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  const p = this.page;
                  this.page = 0;
                  this.loadpage();
                }
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
              this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading_prog = false;
          this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
        });


      },


    },


}
</script>

<style scoped>

.subtitle_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: black;
}


.sublabel_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
}

.blue_round{
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #2C75E3;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: white;
}


.btn_round{
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 10px;
  background: rgba(227, 227, 227, 0.2);
  border: 1px solid #F2F2F2;
  border-radius: 15px;  
  height: 56px;
}


</style>