<template>
<div id="app" style="overflow:hidden">
  <v-app id="inspire">

    <template v-if="showflag">
      <!-- 로그인상태 -->
      <v-navigation-drawer
        clipped
        fixed
        v-model="drawer"
        app 
        color="#ffffff" 
        :right="grade < 10 ? true : false"
        :left="grade >= 10 ? true : false"
        style="z-index:9999"
        >
        <template v-if="grade >= 10 && userMode == false">

          <v-row dense align="center" justify="center" class="ma-0 mt-8">
            <v-col cols="11" align="start" class="ml-3 mr-3 ma-0 pa-0 d-flex align-center">
              <p class="text-subtitle-1 text-left ma-0 pa-0 pt-1 subtitle_text">
                <a @click="gomyinfo" style="color:black;font-weight:bold">
                  {{ this.$session.get("NAME") }}
                </a>
              </p>
              <v-spacer/>
              <v-btn @click="closenav" icon><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center" class="ma-0 pt-0">
            <v-col cols="11" align="start" class="ml-3 mr-3 ma-0 pa-0 d-flex align-center">
              <p style="color:black;font-weight:bold" class="text-left ma-0 pa-0">
                  <!-- {{ 'Supplier Number : 0210-' + this.$session.get("ACCNT_IDX") }} -->
                  {{ 'Supplier Number : ' + this.$session.get("ACCNT_IDX") }}
              </p>
            </v-col>
          </v-row>
          <v-row dense align="center" justify="center">
            <v-col cols="12"  class="pa-0 ma-0">
              <div class="black_line"/>
            </v-col>
          </v-row>

          <v-list>
            <template v-for="menu in menulist">
              <template v-if="menu.sublist.length > 0"> 
                
                <!-- 서브 메뉴 타이틀 -->
                <v-list-group
                  :key="menu.MENU_IDX"
                  no-action
                  color="#000000" 
                  >
                  <template v-slot:activator>
                      <v-icon v-if="menu.MENU_ICON.length > 0" color="black">{{menu.MENU_ICON}}</v-icon>
                      <v-list-item-content class="ml-2">
                        <v-list-item-title style="font-size:18px;color:#000000" :key="menu.MENU_IDX" >{{ menu.MENU_NAME }}</v-list-item-title>
                      </v-list-item-content>
                  </template>
                  <!-- 서브 메뉴 -->
                  <v-list-item-content style="border-top:1px solid black;border-bottom:1px solid black;">
                  <v-row v-for="subitem in menu.sublist" :key="subitem.MENU_IDX" 
                         dense align="center" justify="center" class="mt-1 mb-1 mt-md-1 mb-md-1 ma-0">
                    <v-col cols="11" class="ma-0 pa-0">
                    <v-btn 
                        @click="goMenuname(subitem.ROUTE, subitem.MENU_NAME2 + ' ' + subitem.MENU_NAME)" text elevation="0" width="100%" height="46"
                        class="d-flex align-center" 
                        :class="subitem.ROUTE === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                      <v-icon v-if="subitem.MENU_ICON.length > 0" left>{{subitem.MENU_ICON}}</v-icon>    
                      <p class="text-md-subtitle-2 text-caption text-left ma-0 pa-0 pt-1 menu_eng_font"
                        :style="subitem.ROUTE === ROUTERNAME ? 'color:white':'color:black'">
                        {{subitem.MENU_NAME2}}<br>
                        <span class="menu_kor_font" :style="subitem.ROUTE === ROUTERNAME ? 'color:white':'color:black'">{{subitem.MENU_NAME}}</span></p>
                      <v-spacer/>
                      <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                    </v-btn>
                    </v-col>
                  </v-row>
                  </v-list-item-content>

                  <!-- 
                  <v-list-item v-for="subitem in menu.sublist" :key="subitem.MENU_IDX"
                              :class="subitem.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'"
                              @click="callrouteName(subitem.ROUTE, subitem.GRADE, subitem.MENU_NAME)">
                    <v-list-item-content class="ml-2">
                      <v-list-item-title  :class="subitem.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'" :key="subitem.MENU_IDX">{{subitem.MENU_NAME}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  -->

                </v-list-group>

              </template>
              <template v-else>
                <!-- 단일 메뉴 -->
                <v-row :key="menu.MENU_IDX" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenuname(menu.ROUTE, menu.MENU_NAME2 + ' ' + menu.MENU_NAME)" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="menu.ROUTE === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon v-if="menu.MENU_ICON.length > 0" left>{{menu.MENU_ICON}}</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="menu.ROUTE === ROUTERNAME ? 'color:white':'color:black'">
                      {{menu.MENU_NAME2}}<br>
                      <span class="menu_kor_font" :style="menu.ROUTE === ROUTERNAME ? 'color:white':'color:black'">{{menu.MENU_NAME}}</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                <!-- 
                <v-list-item  :key="menu.MENU_IDX" 
                              :class="menu.ROUTE === ROUTERNAME ? 'selectlist' : 'normallist'"
                              @click="callrouteName(menu.ROUTE, menu.GRADE, menu.MENU_NAME)">
                  <v-icon :color="menu.ROUTE === ROUTERNAME ? 'color:white':'color:black'">{{menu.MENU_ICON}}</v-icon>
                  <v-list-item-content class="ml-2" :class="menu.ROUTE === ROUTERNAME ? 'selecttext' : 'normaltext'">
                    <v-list-item-title class="menu_eng_font" :key="menu.IDX">{{menu.MENU_NAME2}}</v-list-item-title>
                    <v-list-item-title class="menu_kor_font" :key="menu.IDX">{{menu.MENU_NAME}}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                -->
              </template>

            </template>
          </v-list>
          <v-divider></v-divider>

            <v-row v-if="grade == 10" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
              <v-col cols="11" class="ma-0 pa-0">
                <v-btn @click="providertouser" text elevation="0" width="100%" height="46"
                    class="d-flex align-center btn_round" style="padding-left:20px;">
                  <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font" style="color:black">
                    Switch to Consumer<br><span class="menu_kor_font" style="color:black">구매모드로 전환</span></p>
                  <v-spacer/>
                  <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-swap-horizontal</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
              <v-col cols="11" class="ma-0 pa-0">
                <v-btn @click="logout" text elevation="0" width="100%" height="46"
                    class="d-flex align-center btn_round" style="padding-left:20px;">
                  <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font" style="color:black">
                    Logout<br><span class="menu_kor_font" style="color:black">로그아웃</span></p>
                  <v-spacer/>
                  <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-exit-to-app</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          <v-divider v-if="grade == 10"></v-divider>
          <v-row v-if="grade == 10" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
            <v-col cols="11" class="ma-0 pa-0">
              <v-btn @click="showmanual" text elevation="0" width="100%" height="46"
                  class="d-flex align-center btn_round" style="padding-left:20px;">
                <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font" style="color:black">
                  Supplier Manual<br><span class="menu_kor_font" style="color:black">공급자 재고등록 매뉴얼</span></p>
                <v-spacer/>
                <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-swap-horizontal</v-icon>
              </v-btn>
            </v-col>
          </v-row>



        </template>

        <template v-else> <!-- 소비자/도매상 전용 UI -->

          <v-row align="center" justify="center" dense class="mt-5">
            <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <v-row dense align="center" justify="center" class="ma-0">
                <v-col cols="11" align="start" class="ml-3 mr-3 ma-0 pa-0 d-flex align-center">
                  <p class="text-subtitle-1 text-left ma-0 pa-0 pt-1 subtitle_text">
                    <a @click="gomyinfo" style="color:black;font-weight:bold">
                      {{ this.$session.get("NAME") }}
                    </a>
                  </p>
                  <v-spacer/>
                  <v-btn @click="closenav" icon><v-icon>mdi-close-circle</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row dense align="center" justify="center">
                <v-col cols="12"  class="pa-0 ma-0">
                  <div class="black_line"/>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="goMenuname('Myinfo', 'My Info')" text elevation="0" width="100%" height="46"
                     class="d-flex align-center" 
                     :class="'Myinfo' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-account-circle</v-icon>
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                      :style="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">
                      My Info&nbsp;<span class="dialog_subtitle_kor" :style="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">내정보</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="goMenuname('UserTradeList', 'Purchase 거래내역')" text elevation="0" width="100%" height="46"
                     class="d-flex align-center" 
                     :class="'UserTradeList' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                      :style="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">
                      Purchase&nbsp;<span class="dialog_subtitle_kor" :style="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">거래내역</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="goMenuname('UserInquiry', 'Inquiry 문의내역')" text elevation="0" width="100%" height="46"
                     class="d-flex align-center" 
                     :class="'UserInquiry' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-message-question</v-icon>
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                      :style="'UserInquiry' === ROUTERNAME ? 'color:white':'color:black'">
                      Inquiry&nbsp;<span class="dialog_subtitle_kor" :style="'UserInquiry' === ROUTERNAME ? 'color:white':'color:black'">문의내역</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserInquiry' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

              <!-- 
              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="goMenuname('UserSaleReq', 'Sale Request 판매요청')" text elevation="0" width="100%" height="46"
                     class="d-flex align-center" 
                     :class="'UserSaleReq' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-card-plus</v-icon>
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                      :style="'UserSaleReq' === ROUTERNAME ? 'color:white':'color:black'">
                      Sales request&nbsp;<span class="dialog_subtitle_kor" :style="'UserSaleReq' === ROUTERNAME ? 'color:white':'color:black'">판매요청</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserSaleReq' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              -->

              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="goMenuname('UserFavList', 'Favorite 즐겨찾기')" text elevation="0" width="100%" height="46"
                     class="d-flex align-center" 
                     :class="'UserFavList' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-heart</v-icon>
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                      :style="'UserFavList' === ROUTERNAME ? 'color:white':'color:black'">
                      Favorite&nbsp;<span class="dialog_subtitle_kor" :style="'UserFavList' === ROUTERNAME ? 'color:white':'color:black'">즐겨찾기</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserFavList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

              <template v-if="grade > 1">

                <v-row v-if="!userMode" dense align="center" justify="center">
                  <v-col cols="12" class="pa-0 ma-0">
                    <div class="gray_line"/>
                  </v-col>
                </v-row>

                <!-- VIVID CLUB 상점정보 -->
                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                    <v-btn @click="goMenuname('Shopinfo', 'VIVID CLUB 상점정보')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center" 
                      :class="'Shopinfo' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                      <v-icon left>mdi-diamond-stone</v-icon>
                      <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text"
                        :style="'Shopinfo' === ROUTERNAME ? 'color:white':'color:black'">
                        VIVID CLUB&nbsp;<span class="dialog_subtitle_kor" :style="'Shopinfo' === ROUTERNAME ? 'color:white':'color:black'">상점정보</span></p>
                      <v-spacer/>
                      <v-icon style="margin-right:10px" :color="'Shopinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenunamevivid('AdminProduct', 'Product List 제품 관리')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="'AdminProduct' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="'AdminProduct' === ROUTERNAME ? 'color:white':'color:black'">
                      Product List<br>
                      <span class="menu_kor_font" :style="'AdminProduct' === ROUTERNAME ? 'color:white':'color:black'">제품 관리</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenunamevivid('ProvTradeReq', 'Shipment Request 제품발송요청')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="'ProvTradeReq' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="'ProvTradeReq' === ROUTERNAME ? 'color:white':'color:black'">
                      Shipment Request<br>
                      <span class="menu_kor_font" :style="'ProvTradeReq' === ROUTERNAME ? 'color:white':'color:black'">제품발송요청</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenunamevivid('ProvTradeSend', 'Shipment 제품발송')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="'ProvTradeSend' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="'ProvTradeSend' === ROUTERNAME ? 'color:white':'color:black'">
                      Shipment<br>
                      <span class="menu_kor_font" :style="'ProvTradeSend' === ROUTERNAME ? 'color:white':'color:black'">제품발송</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenunamevivid('ProvTradeCom', 'Sales complete 판매완료')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="'ProvTradeCom' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="'ProvTradeCom' === ROUTERNAME ? 'color:white':'color:black'">
                      Sales complete<br>
                      <span class="menu_kor_font" :style="'ProvTradeCom' === ROUTERNAME ? 'color:white':'color:black'">판매완료</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!userMode" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                  <v-col cols="11" class="ma-0 pa-0">
                  <v-btn 
                      @click="goMenunamevivid('ProvTradeCancel', 'Cancel List 거래취소')" text elevation="0" width="100%" height="46"
                      class="d-flex align-center"
                      :class="'ProvTradeCancel' === ROUTERNAME ? 'btn_select_round' : 'btn_round'" style="padding-left:20px;">
                    <v-icon left>mdi-clipboard-text</v-icon>                      
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font"
                      :style="'ProvTradeCancel' === ROUTERNAME ? 'color:white':'color:black'">
                      Cancel List<br>
                      <span class="menu_kor_font" :style="'ProvTradeCancel' === ROUTERNAME ? 'color:white':'color:black'">거래취소</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'UserTradeList' === ROUTERNAME ? 'color:white':'color:black'">mdi-chevron-right</v-icon>
                  </v-btn>
                  </v-col>
                </v-row>
                
              </template>


              <v-row dense align="center" justify="center">
                <v-col cols="12" class="pa-0 ma-0">
                  <div class="gray_line"/>
                </v-col>
              </v-row>

              <v-row v-if="grade == 10" dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="usertoprovider" text elevation="0" width="100%" height="46"
                      class="d-flex align-center btn_round" style="padding-left:20px;">
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 menu_eng_font" style="color:black">
                      Switch to Provider<br><span class="menu_kor_font" style="color:black">공급자로 전환</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-swap-horizontal</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-4 mb-3 mt-md-3 mb-md-2 ma-0">
                <v-col cols="11" class="ma-0 pa-0">
                  <v-btn @click="logout" text elevation="0" width="100%" height="46"
                     class="d-flex align-center btn_round" style="padding-left:20px;">
                    <p class="text-md-subtitle-1 text-body-2 text-left ma-0 pa-0 pt-1 subtitle_text" style="color:black">
                      Logout&nbsp;<span class="dialog_subtitle_kor" style="color:black">로그아웃</span></p>
                    <v-spacer/>
                    <v-icon style="margin-right:10px" :color="'Myinfo' === ROUTERNAME ? 'color:white':'color:black'">mdi-exit-to-app</v-icon>

                  </v-btn>
                </v-col>
              </v-row>

            </v-col>
          </v-row>

        </template>

      </v-navigation-drawer>
      
      <v-app-bar 
        app fixed clipped-left height="68" elevation="0"
        style="background-color:#000000;color:#ffffff;">

        <v-row align="center" justify="start" no-gutters dense>
          <template v-if="grade >= 10">
            <v-btn small @click.stop="drawer = !drawer" icon><v-icon color="white">mdi-menu</v-icon></v-btn>
          </template>
          <a class="ml-3" @click="gohome"><div class="inter_font" style="font-size:25px;color:#ffffff;">kDiaGem</div></a>
          <v-spacer/>
          <v-col md="10" cols="10" class="hidden-sm-and-down text-right">
            <v-btn text :style="ROUTERNAME === 'Aboutus' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goaboutus">About US</v-btn>
            <v-btn text :style="ROUTERNAME === 'ProductList_Dian' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goNaturalDiamond">Natural Diamond<span style="font-size:10px;opacity: 0.6;">천연 다이아몬드</span></v-btn>
            <v-btn text :style="ROUTERNAME === 'ProductList_Dial' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goLabDiamond">Lab-Grown Diamond<span style="font-size:10px;opacity: 0.6;">렙-그로운 다이아몬드</span></v-btn>
            <v-btn text :style="ROUTERNAME === 'ProductList_Gem' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goGemstone">GEMSTONE<span style="font-size:10px;opacity: 0.6;">천연보석</span></v-btn>
            <v-btn text :style="ROUTERNAME === 'ProductList_Jwel' ? 'color:#ffffff;font-weight:bold;' : 'color:#fafafa'" @click="goJwewlry">Jewelry<span style="font-size:10px;opacity: 0.6;">주얼리</span></v-btn>
          </v-col>
          <template v-if="grade <= 5">
            <v-btn small @click.stop="drawer = !drawer" icon><v-icon color="white">mdi-account-circle</v-icon></v-btn>
          </template>
        </v-row>
      </v-app-bar>
    </template>
    <template v-else>
      <!-- 로그아웃상태 -->
      <v-navigation-drawer
        v-model="drawer"
        temporary
        color="#ffffff"
        fixed right
        >
        <v-list
          nav
          dense
          >
          <v-list-item-group
            active-class="border"
            >
            <v-list-item>
              <v-list-item-title class="ml-3" @click="gohome"></v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goaboutus">About US</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goDiamond">NATURAL Diamond(천연 다이아몬드)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goDiamond">LAB-GROWN Diamond<br>(렙-그로운 다이아몬드)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goGemstone">Gemstones(천연보석)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goJwewlry">Jewelry(주얼리)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="goFavList">Favorite(즐겨찾기)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="gologin">Login(로그인)</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="ml-3" @click="gojoin">Join(회원가입)</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>    

      <!-- Login 필요 -->
      <v-app-bar elevation="2"
        app fixed clipped-left height="68"
        style="background-color:#ffffff;color:#000000;"
        >

        <v-row align="center" justify="start" no-gutters dense>
          <a @click="gohome"><div class="inter_font" style="font-size:25px;color:#000000;">kDiaGem</div></a>
          <v-spacer/>
          <v-col md="10 " cols="8" class="hidden-sm-and-down text-right">
            <v-btn text class="inter_font" :style="ROUTERNAME === 'Aboutus' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goaboutus">About US</v-btn>
            <v-btn text class="inter_font" :style="ROUTERNAME === 'ProductList_Dian' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goNaturalDiamond">Natural Diamond<span style="font-size:10px;opacity: 0.6;">천연 다이아몬드</span></v-btn>
            <v-btn text class="inter_font" :style="ROUTERNAME === 'ProductList_Dial' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goLabDiamond">Lab-Grown Diamond<span style="font-size:10px;opacity: 0.6;">렙-그로운 다이아몬드</span></v-btn>
            <v-btn text class="inter_font" :style="ROUTERNAME === 'ProductList_Gem' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goGemstone">Gemstone<span style="font-size:10px;opacity: 0.6;">천연보석</span></v-btn>
            <v-btn text class="inter_font" :style="ROUTERNAME === 'ProductList_Jwel' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goJwewlry">Jewelry<span style="font-size:10px;opacity: 0.6;">주얼리</span></v-btn>
            <v-btn text class="inter_font" :style="ROUTERNAME === 'FavList' ? 'color:#000000;font-weight:bold;':'color:#000000;font-weight:normal;'" @click="goFavList">Favorite<span style="font-size:10px;opacity: 0.6;">즐겨찾기</span></v-btn>
            <v-btn small @click.stop="gologin" icon><v-icon color="black">mdi-account-circle-outline</v-icon></v-btn>
          </v-col>
          <v-btn class="hidden-md-and-up" small @click.stop="drawer = !drawer" icon><v-icon color="black">mdi-menu</v-icon></v-btn>
        </v-row>
        
      </v-app-bar>

    </template>

    <v-main style="background:#f8f8f8">
      <router-view :key="$route.fullPath"/> <!-- 같은 vue에 파라메터만 다른 경우에도 새로 읽기하려면 fullpath 처리 -->
    </v-main>

    <v-footer  height="auto" style="background-color:rgba(0, 0, 0, 1.0);color:#ffffff;">
      <v-spacer></v-spacer>
      <div style="text-align:center;"
        :style="$vuetify.breakpoint.smAndDown ?'font-size:10px;':'font-size:12px;'"
        >
        <template v-if="$vuetify.breakpoint.smAndDown">
          KDGE Co., Ltd. 서울특별시 종로구 돈화문로5가길 1, 4층 대표 : 김종목<br><span style="font-size:10px">사업자등록번호: 101-86-68059 연락처 : 02-765-0701</span><br><span style="font-size:10px">Copyright 2022. all rights reserved.</span>
        </template>
        <template v-else>
          KDGE Co.,Ltd. 서울특별시 종로구 돈화문로5가길 1, 4층 대표 : 김종목 사업자등록번호: 101-86-68059 연락처 : 02-765-0701 Copyright 2022. all rights reserved.
        </template>
			</div>      
      <v-spacer></v-spacer>
    </v-footer>

  </v-app>
  
</div>
</template>

<script>
export default {
  data: () => ({
      drawer: false
    , grade:0
    , menulist : []
    , login_flag : false
    , COM_NAME : ''
    , ROUTERNAME: ''
    , showflag: false //사이드 메뉴와 탑바의 보여주기 유무
    , db : null
    , group: null
    , userMode: false
  }),

  created(){

    //로그인 전역 이벤트 수신
    this.$EventBus.$on('loginresult', this.selectmenu);

    //풀사이즈 이벤트 수신
    this.$EventBus.$on('setfullsize', this.setfullsize);

    //화면이동 이벤트 수신
    this.$EventBus.$on('movepageresult', this.movepage);

    //세션 초기화 이벤트 수신
    this.$EventBus.$on('clearsession', this.clearsession);

    //console.log("create....1 " + localStorage.getItem("ACCESSTOKEN"));
    //console.log("create....2 " + localStorage.getItem("REFLASHTOKEN"));

    //여기서 세션 복원?
    if(localStorage.getItem("SAVE_ID") && localStorage.getItem("ACCESSTOKEN") != null && localStorage.getItem("ACCESSTOKEN") != undefined 
    && localStorage.getItem("REFLASHTOKEN") != null && localStorage.getItem("REFLASHTOKEN") != undefined){
      
      this.firerestore();
    }
    
  },

  watch: {
    
  },




  methods: {

    showmanual(){

      //공급자 매뉴얼 확인
      //this.$refs.pdfinfo2.geturl("https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/KDGE%20App%20Supplier%20User%20Manual%20(KDGE%20%EA%B3%B5%EA%B8%89%EC%9E%90%20%EC%9D%B4%EC%9A%A9%20%EB%A9%94%EB%89%B4%EC%96%BC).pdf");
      this.$router.push({path:"/PdfViewer", query:{url:'https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/KDGE%20App%20Supplier%20User%20Manual%20(KDGE%20%EA%B3%B5%EA%B8%89%EC%9E%90%20%EC%9D%B4%EC%9A%A9%20%EB%A9%94%EB%89%B4%EC%96%BC).pdf'}}).then(()=>{
      })
      .catch(()=>{
      });

    },

    providertouser(){
      this.userMode = true;
      this.gohome();
    },

    usertoprovider(){
      this.userMode = false;
      this.goMenuname("AdminProduct", "Product List 제품 관리");
    },

    setfullsize(flag){
      this.showflag = flag;
      this.$forceUpdate();
    },
    
    goMenuname(routername, menuname){
      this.$router.push("/"+routername).then(()=>{
        this.ROUTERNAME = routername;
        this.$session.set("MENUNAME",   menuname);
      })
      .catch(()=>{
      });
    },    

    goMenunamevivid(routername, menuname){

      if(this.$session.get("SUB_DAYS") < 0){
        this.closenav();
        this.$fire({
          html: "Only VIVID CLUB members can use this menu.<br><br>VIVID CLUB 회원만 사용이 가능한 메뉴입니다.",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        });
        return;
      }

      this.$router.push("/"+routername).then(()=>{
        this.ROUTERNAME = routername;
        this.$session.set("MENUNAME",   menuname);
      })
      .catch(()=>{
      });

    },    

    closenav(){
      this.drawer = false;
    },

    goNaturalDiamond(){
      this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, dtype:0, vivid:0, isquick:0}}).then(()=>{
        this.ROUTERNAME = "ProductList_Dian";
        this.$session.set("MENUNAME",   '천연 다이아몬드');
      })
      .catch(()=>{
      });
    },

    goLabDiamond(){
      this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, dtype:1, vivid:0, isquick:0}}).then(()=>{
        this.ROUTERNAME = "ProductList_Dial";
        this.$session.set("MENUNAME",   '렙-그로운 다이아몬드');
      })
      .catch(()=>{
      });
    },

    goDiamond(){
      /* 진입시 검색 거처가는 버전
      this.$router.push({path:"SearchOption", query:{code:0}}).then(()=>{
        this.ROUTERNAME = "ProductList_Dia";
      })
      .catch(()=>{
      });
      */
      this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1, isquick:0}}).then(()=>{
        this.ROUTERNAME = "ProductList_Dia";
        this.$session.set("MENUNAME",   '다이아몬드');
      })
      .catch(()=>{
      });
      

    },

    goFavList(){
      this.$router.push({path:"/FavList"}).then(()=>{
        this.ROUTERNAME = "FavList";
      })
      .catch(()=>{
      });
    },

    goGemstone(){
      /*  진입시 검색 거처가는 버전
      this.$router.push({path:"SearchOption", query:{code:1}}).then(()=>{
        this.ROUTERNAME = "ProductList_Gem";
      })
      .catch(()=>{
      });
      */
      
      this.$router.push({path:"/ProductList", query:{code:1, page:1, rtype:1}}).then(()=>{
        this.ROUTERNAME = "ProductList_Gem";
      })
      .catch(()=>{
      });
      
    },

    goJwewlry(){
      /*  진입시 검색 거처가는 버전
      this.$router.push({path:"SearchOption", query:{code:2}}).then(()=>{
        this.ROUTERNAME = "ProductList_Jwel";
      })
      .catch(()=>{
      });
      */

      this.$router.push({path:"/ProductList", query:{code:2, page:1, rtype:1}}).then(()=>{
        this.ROUTERNAME = "ProductList_Jwel";
      })
      .catch(()=>{
      });

    },

    gomyinfo(){
      this.$router.push("/Myinfo").then(()=>{
        this.ROUTERNAME = "Myinfo";
      })
      .catch(()=>{
      });
    },

    goaboutus(){
      this.$router.push("/Aboutus").then(()=>{
        this.ROUTERNAME = "Aboutus";
      })
      .catch(()=>{
      });
    },

    govivid(){
      this.$router.push("/VividDiamond").then(()=>{
        this.ROUTERNAME = "VividDiamond";
      })
      .catch(()=>{
      });
    },

    gohome(){
      this.$session.set("PRODUCT_CLASS", "");
      this.$router.push("/Welcome").then(()=>{
        this.ROUTERNAME = "Welcome";
      })
      .catch(()=>{
      });
    },

    gologin(){
      this.$router.push("/Login").then(()=>{
        this.ROUTERNAME = "Login";
      })
      .catch(()=>{
      });
    },


    gojoin(){
      this.$router.push("/Join").then(()=>{
        this.ROUTERNAME = "Join";
      })
      .catch(()=>{
      });
    },

    //중복 로그인 강제 로그아웃
    logout2(){
      this.$session.clear("ACCNT_IDX");
      this.$session.clear("ACCNT");
      this.$session.clear("NAME");
      this.$session.clear("GRADE");
      this.$session.clear("SUB_DAYS");
      this.$session.clear("ACCESSTOKEN");
      this.$session.clear("REFLASHTOKEN");
      this.selectmenu();
    },

    logout:function(){

      if(this.login_flag){
        this.drawer = false;

        this.$fire({
          html: "Are you sure you want to log out?<br><br>로그아웃 하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          
          if(r.value){

            this.$session.clear("ACCNT_IDX");
            this.$session.clear("ACCNT");
            this.$session.clear("NAME");
            this.$session.clear("GRADE");
            this.$session.clear("SUB_DAYS");
            this.$session.clear("ACCESSTOKEN");
            this.$session.clear("REFLASHTOKEN");
            localStorage.clear("ACCESSTOKEN");
            localStorage.clear("REFLASHTOKEN");
            this.selectmenu();
          }
        });

      }

    },

      //화면이동 전역 이벤트 수신
      clearsession(){
        this.$session.clear("ACCNT_IDX");
        this.$session.clear("ACCNT");
        this.$session.clear("NAME");
        this.$session.clear("GRADE");
        this.$session.clear("SUB_DAYS");
        this.$session.clear("ACCESSTOKEN");
        this.$session.clear("REFLASHTOKEN");
        this.$router.push('/Login');
      },


      //로그인 전역 이벤트 수신
      selectmenu:function(){

        //로그인이 되어있는 경우
        if(this.$session.exists("ACCNT_IDX")){
          
          this.showflag   = true;
          this.drawer     = false;
          this.login_flag = true;
          this.grade = this.$session.get("GRADE");
          if(this.grade > 90){
            this.userMode = false;
          }
          this.getmenulist();
          this.COM_NAME = this.$session.get("LOGIN_NAME");

          this.$forceUpdate();

          //path에 따른 이동 (이메일에서 진입시 바로 특정 페이지로 이동시킨다.)
          //console.log("create query : " + JSON.stringify(this.$route.query.path));
          var path = this.$route.query.path;
          if(path != undefined && path != null){
            this.$router.push('/'+path); 
          }          

        } else {
          
          //로그아웃
          this.showflag = false;
          this.drawer = false;
          this.login_flag = false;
          this.grade = 0;
          this.removemenulist();
          this.$router.push('/Login');   //로그인 페이지로 이동
          this.COM_NAME = "";

          this.$forceUpdate();

        }

      },

      //화면이동 전역 이벤트 수신
      movepage(router_name, menuname){
        //console.log("movepage : " + router_name + " / " + menuname);
        this.$router.push("/"+router_name).then(()=>{
          this.ROUTERNAME = router_name;
          this.$session.set("MENUNAME",   menuname);
          this.$forceUpdate();
        })
        .catch(()=>{
        });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
      },

      callrouteName:function(router_name, grade, menuname){

        //console.log("callroutename : " + router_name + " / " +menuname );

          //권한등급 체크
          if(this.$session.get("GRADE") >= grade){
            this.$session.set("MENUNAME",   menuname);
            this.$router.push("/"+router_name).then(()=>{
              this.ROUTERNAME = router_name;
            })
            .catch(()=>{
            });  //해당 이름으로 라우트됨 ex) link면 /link 로 라우트
            
          }else{
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
          }

      },

      getmenulist:function(){

        this.$http.post(this.$host+'/getMenuList'
        ,{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{

          if(result.data.resultCode == 0){

            var glist = result.data.groupData;
            var slist = result.data.subData;

            glist.forEach(group => {
                group.sublist = [];
                slist.forEach(sub=>{
                  if(group.MENU_NAME == sub.GROUPNAME){
                    group.sublist.push(sub);
                  }
                });
                /*
                if(group.sublist.length == 0){
                  group.sublist.push({IDX:group.IDX, COM_IDX:group.COM_IDX, MENUNAME:group.MENUNAME, ROUTE:group.ROUTE});
                }
                */
            });
            this.menulist.splice(0, this.menulist.length);
            glist.forEach(element => {
              this.menulist.push(element); 
            });

          }else if(result.data.resultCode == 2){

            //로그인 필요
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$session.clear("ACCNT_IDX");
                this.$session.clear("ACCNT");
                this.$session.clear("NAME");
                this.$session.clear("GRADE");
                this.$session.clear("SUB_DAYS");
                this.$session.clear("ACCESSTOKEN");
                this.$session.clear("REFLASHTOKEN");
                this.login_flag = false;
                this.removemenulist();
                this.$router.push('/Login');   //로그인 페이지로 이동
              }
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              
            }
        });

      },

      removemenulist:function(){
        //모두 제거
        this.menulist.splice(0, this.menulist.length);
      },

      //페이지 새로고침 또는 주소창에 다른주소 입력해서 빠져나갈 때 경고
      unloadevent:function(event){

        //자동로그인을 위한 토큰값 저장.
        localStorage.setItem("ACCESSTOKEN",  this.$session.get("ACCESSTOKEN"));
        localStorage.setItem("REFLASHTOKEN", this.$session.get("REFLASHTOKEN"));

        //페이지 새로고침 또는 나갈때 경고
        //event.preventDefault();
        //event.returnValue = "";

      },



      //이전 로그인 정보 복원
      firerestore:function(){

        this.$http.post(this.$host+'/fireRestore'
        ,{
        },{headers: { accesstoken:localStorage.getItem("ACCESSTOKEN"), reflashtoken:localStorage.getItem("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
            
            const user = result.data.resultData;
            

            //계정 세션값 셋팅
            this.$session.set("ACCNT_IDX",  user.ACCNT_IDX);
            this.$session.set("ACCNT",      user.ACCNT);
            this.$session.set("NAME",       user.NAME);
            this.$session.set("GRADE",      user.GRADE);
            this.$session.set("SUB_DAYS",   user.SUB_DAYS);

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            
            this.$EventBus.$emit('loginresult');
            this.$EventBus.$emit('reloadrapa');
            
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });

      },

  },


  beforeDestroy(){

    //로그인 전역 이벤트 수신 해제
    this.$EventBus.$off('loginresult');

    this.$EventBus.$off('setfullsize');

    //페이지 이동 이벤트 수신 해제
    this.$EventBus.$off('movepageresult');
    
    //세션 초기화 이벤트 해제
    this.$EventBus.$off('clearsession');

  },

  beforeUnmount() {
    //console.log("beforeMount...");
    window.removeEventListener('beforeunload', this.unloadevent);

  },


  mounted(){

    //페이지 나갈때 경고인데 끈다. - 여기서 토큰저장함
    window.addEventListener('beforeunload', this.unloadevent);

    //플러터와 통신하기 위한 자바스크립트 인터페이스
    //window.addEventListener('flutterInAppWebViewPlatformReady', this.flutterevent);

    if(this.$session.exists("ACCNT_IDX")){
      //로그인이 된 경우 (새로고침 케이스)
      this.showflag = true;
      this.selectmenu();
    }else{
      //로그인이 안된 상태면
      //this.gologin();
      //this.$router.push('Login');   //로그인 페이지로 이동
    }


  }

}
</script>

<style>
  /* 여기는 기본 태그들에 스타일을 먹이는 곳인듯 */


  .circle-button {
    width: 50px !important; 
    height: 50px !important;
    border-radius: 50%; /* 동그란 모양을 만듭니다. */
    padding: 0; /* 내부 여백을 없앱니다. */
    background-image: url('@/assets/chaticon.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  
  .circle-button-map {
    width: 80px !important; 
    height: 80px !important;
    border-radius: 50%; /* 동그란 모양을 만듭니다. */
    padding: 0; /* 내부 여백을 없앱니다. */
    background-image: url('@/assets/mapicon.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  }
  

  /* 페이지 제목 */
  .menunamestyle{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;    
    color: #000000;
  }
  .total_label{
    color: #000000;
    font-weight: bold;
  }
  .mytextstyle{
    font-size:16px;
    color: #777777;
    font-weight: bold;
  }

  /* 페이징 숫자 */
  .numberstyle{
    font-size:16px !important;
    color: black !important;
    font-weight: bold !important;
  }  
  .numberstyle2{
    font-size:16px !important;
    color: #5a5a5a !important;
    font-weight: bold !important;
  }  


  /* 상단 메뉴의 기본 텍스트 스타일 */
  .basictextstyle{
    font-size:16px;
    color: #5a5a5a;
    font-weight: bold;
  }

  /* 셀렉트 콤보의 기본 텍스트 스타일  box-shadow: 2px 2px 5px #00000030; */
  .basicselectstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    border-radius: 0px !important;
  }

  /* 셀렉트 콤보의 기본 텍스트 스타일  box-shadow: 2px 2px 5px #00000030; */
  .basicselectstyle_mobile{
    font-size:12px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    border-radius: 0px !important;
    height: 30px;
  }
    
  
  /* 요청으로 v-select의 팝업 높이 수정 */
  .menuable__content__active{
    max-height:600px !important;
    /* min-width:580px !important; */
  }
  

  /* 검색창 스타일  box-shadow: 2px 2px 5px #00000030; */
  .basicsearchstyle{
    font-size:16px;
    color: black;
    height: 43px;
    font-weight: bold;
    background: white;
    margin-right: 5px !important;
    border-radius: 0px !important;
  }
  .basicsearchstyle input{
    padding-top: 12px !important;
  }

  .basicsearchstyle_mb{
    font-size:14px;
    color: black;
    height: 30px;
    font-weight: bold;
    background: white;
    margin-right: 0px !important;
    border-radius: 0px !important;
    border: solid black 3px;
    max-width: 300px;
    width: 100%;
    padding-top: 2px !important;
    padding-left: 10px !important;
  }

  .basicsearchstyle_gem{
    font-size:14px;
    color: black;
    height: 30px;
    font-weight: bold;
    background: white;
    margin-right: 0px !important;
    border-radius: 0px !important;
    border: solid black 3px;
    width: 100%;
    padding-top: 2px !important;
    padding-left: 10px !important;
  }


  /* 검색창 스타일 */
  .mypageiputstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    box-shadow: 2px 2px 5px #00000030;
    margin-right: 5px !important;
  }



  /* 검색버튼 */
  .search_btn_freesize_nocolor {
    color: #ffffff !important;
    background-color: #58008b !important;
    border: thin solid #ffffff !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    height: 43px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }
  .search_btn_freesize {
    color: #ffffff !important;
    background-color: #000000 !important;
    border: thin solid #ffffff !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    height: 43px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }
  .search_btn {
    color: #ffffff !important;
    background-color: #000000 !important;
    border: thin solid #ffffff !important;
    border-radius: 0px !important;
    font-size: 12px !important;
    height: 43px !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }

  .search_btn_mb {
    color: #ffffff !important;
    background-color: #000000 !important;
    border: thin solid #000000 !important;
    border-radius: 0px !important;
    height: 30px !important;
    width: 30px !important;
  }


  /* 팝업 인풋창 스타일 */
  .popupiputstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    border-radius: 10px 10px 10px 10px !important;
    background: white;
  }

  /* 팝업 검색버튼 */
  .dialogsearch_btn_freesize {
    color: #ffffff !important;
    background-color: #C53D45 !important;
    border: thin solid #C53D45 !important;
    border-radius: 10px 10px 10px 10px !important;
    font-size: 16px !important;
    width: 98% !important;
    text-align: center !important;
    box-shadow: 2px 2px 5px #00000030 !important;
  }

  /* 전역 데이터 테이블 라운드 스타일  box-shadow: 1px 1px 5px #00000030 !important; */
  .datatablestyle{
    border-radius: 0px !important; 
    border-color: black !important;
    border-style: solid;
    border-width: 3px 0px 1px 0px !important;
    
  }

  /* 팝업  클로즈 버튼 */
  .dialog_close_btn {
    color: #e5e5e5 !important;
    background-color: #ffffff !important;
    border-color:#e5e5e5 !important; 
    border-style:solid !important; 
    border-width:3px 3px 3px 3px !important;
  }

  /* 팝업 타이틀 */
  .dialog_title {
    color: #0A0A0A !important;
    font-size: 20px !important;
    font-weight: bold !important;
  }

  /* 팝업 라운드 처리 */
  .dialog_round{
    border-radius: 0px 0px 0px 0px !important;
  }


  /* 팝업 하단 버튼 */
  .dialog_btn {
    color: #ffffff !important;
    background-color: #690505 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
  }

  /* 팝업 하단 버튼 */
  .dialog_cancel_btn {
    font-weight: bold !important;
    color: #6b0105 !important;
    background-color: #e5e5e5 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
  }  

  /* 팝업 셀렉트 콤보 스타일 */
  .dialogselectstyle{
    font-size:16px;
    color: #2b2b2b;
    font-weight: bold;
    background: white;
    border-radius: 10px 10px 10px 10px !important;
  }


  /* 데이터 테이블 정렬용 컬럼 헤더 스타일 */
  .cheader_sortselect{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;    
    font-size: 14px !important;
    color:purple !important;
    text-underline-position: auto;
  }
  
  .cheader_sort{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color:#2e2e2e !important;
    text-underline-position: auto;
  }

  .cheader_normal{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    color:#000000 !important;
    text-underline-position: auto;
    border-bottom: 2px red;
  }
  

  /* KDGE */
  .point_bg_gray{
    color:#000000;
    text-align:center;
    background-color: #8CBE5A10;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .point_bg_green{
    color:#26A107;
    text-align:center;
    background-color: #8CBE5A50;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .point_bg_blue{
    color:#2C75E3;
    text-align:center;
    background-color: #2C75E350;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .point_bg_red{
    color:#DB1D1D;
    text-align:center;
    background-color: #FC808050;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .point_text_green{
    color:#26A107;
    text-align:center;
  }
  .point_text_red{
    color:#DB1D1D;
    text-align:center;
  }


  .black_line{
    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 3px;
  }
  .black_line_mb{
    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }

  .gray_line{
    border-bottom-color: #dddddd;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }  

  .black_btn{
    color: #ffffff !important;
    background-color: #000000 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    height: 51px !important;
    width:100% !important;
  }

   .black_btn_noheight{
    color: #ffffff !important;
    background-color: #000000 !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    width:100% !important;
  }

  .white_btn{
    color: #000000 !important;
    background-color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    height: 51px !important;
    width:100% !important;
    border: black 2px solid;
  }

  .white_btn_freesize{
    color: #000000 !important;
    background-color: #ffffff !important;
    font-size: 16px !important;
    border-radius: 0px !important;
    border: black 2px solid;
  }

  /* 이미지 추가 양식 */
  .image-border {
    color: #ffffff !important;
    background-color: rgba(227, 227, 227, 0.2)!important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }
  .image-border2{
    color: #ffffff !important;
    background-color: rgba(227, 227, 227, 0.2)!important;
    height: 240px !important;
    width: 240px !important;
    text-align: center !important;
  }

  .none_radius{
    border-radius: 0px !important;
  }

  .radius_10{
    border-radius: 10px !important;
  }

  .radius_20{
    border-radius: 20px !important;
  }

  .radius_10_top{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }

  .radius_10_bottom{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .container_bg{
    background:white !important;
    min-height:100% !important;
  }
  

  .dialog_subtitle_kor{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    color: #090808;
    font-size: 14px;
    line-height: 26px;
  }

  .chartsum_round{
    background-color: #fafafa !important;
    border-color:#f0f0f0 !important; 
    border-style:solid !important; 
    border-width:2px 2px 2px 2px !important;
  }
  .chartsum_title{
    display: block;
    font-size: 15px;
    margin-left: 10px;
    margin-top:5px;
  }
  .chartsum_value{
    display: block;
    font-size: 25px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 5px;
    max-lines: 1;
    white-space: nowrap;
  }

  
  .cntsum_round{
    background-color: #fafafa !important;
    border-color:#f0f0f0 !important; 
    border-style:solid !important; 
    border-width:2px 2px 2px 2px !important;
  }
  .cntsum_title{
    display: block;
    font-size: 15px;
    margin-left: 10px;
    margin-top:5px;
    text-align: center;
  }
  .cntsum_value{
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 5px;
    max-lines: 1;
    white-space: nowrap;
    text-align: center;
  }

  .inter_font{
    font-family: 'Inter';
  }

  .notoserif_font{
    font-family: 'Noto Serif';
  }

  .notosanskr_font{
    font-family: 'NotoSansKR';
  }

</style>

<style scoped>
.menu_eng_font{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: #000000;
  font-size: 15px;
  line-height: 16px;
}
.menu_kor_font{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
  font-size: 12px;
  line-height: 13px;
}

.subtitle_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}

.sublabel_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  color: #919191;
}

.blue_round{
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #2C75E3;
  border-radius: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: white;
}



  #logouttitle{
    font-size: 18px;
  }

  .normallist{
    font-size: 18px;
    color: #b7b7b7;
    background-color: #ffffff;
    border-radius: 10px 10px 10px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 48px;
  }

  .selectlist{
    font-size: 18px;
    color: white;
    background-color: #3d3d3d;
    border-radius: 10px 10px 10px 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 48px;
  }

  .btn_round{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px;
    background: rgba(227, 227, 227, 0.2);
    border: 1px solid #F2F2F2;
    border-radius: 15px;  
    height: 46px;
  }

  .btn_select_round{
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 10px;
    background: #3d3d3d;
    border: 1px solid #3d3d3d;
    border-radius: 15px;  
    height: 46px;
    color: white;
  }

  .normaltext{
    font-size: 18px;
    color: #707070;
  }

  .selecttext{
    font-size: 18px;
    color: #ffffff;
  }

  .v-main{
    background:#000000 !important;
  }

</style>

<!-- 전체 폰트 -->
<style lang="scss">

  .redtesttable {
    tbody {
      tr:hover {
          background-color: transparent !important;
      }
    }
  }

  //로컬폰트 로드
  @font-face {
    font-family: 'AppleSDGothicNeoM';
    src: local('AppleSDGothicNeoM'), url("./assets/fonts/AppleSDGothicNeoM.ttf") format("truetype");
  }
  
  //구글폰트
  @import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');
  $font-family: 'NaumBrush', cursive;

  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;900&display=swap');
  $font-family: 'NotoSansKR', cursive;

  @import url('https://rsms.me/inter/inter.css');
  $font-family: 'Inter', sans-serif;

  @import url('https://fonts.cdnfonts.com/css/noto-serif');
  $font-family: 'Noto Serif', sans-serif;



  //전체 폰트 반영
  $font-family: 'AppleSDGothicNeoM';
  .v-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }

  /*
  $font-family: 'NotoSansKR';
  .v-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }
  */
  

</style>

