<template>

  <!-- 유형관리 -->
  <v-container fluid class="container_bg">
    
    <!-- 이미지 크롭 팝업 -->
    <v-row align="center"  justify="center" >
      <v-dialog persistent scrollable overlay-color="#00000040"
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Image Edit&nbsp;이미지 편집</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

              <div style="width:100%;height:500px;">
                <vueCropper
                  ref="cropper_land"
                  :img="imgSrc"
                  :outputType="option.outputType"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :fixedBox="option.fixedBox"
                  :original="option.original"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :centerBox="option.centerBox"
                  :high="option.high"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  :limitMinSize="option.limitMinSize"
                  />
              </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>     

    <!-- 추가/수정 팝업 -->
    <v-row align="center"  justify="center">
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" :retain-focus="false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
      
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">{{ dialogTitle }}</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Type Name(KOR)&nbsp;<span class="dialog_subtitle_kor">유형이름(한글)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea
                      class="none_radius" color="black"
                      single-line v-model="editedItem.PRODUCTTYPE_NAME"
                      height="70"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Type Name(ENG)&nbsp;<span class="dialog_subtitle_kor">유형이름(영문)</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="editedItem.PRODUCTTYPE_DESC"
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Type State&nbsp;<span class="dialog_subtitle_kor">상태</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="editedItem.PRODUCTTYPE_STATE" :items="STATELIST" 
                      dense
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Shape Icon&nbsp;<span class="dialog_subtitle_kor">모양 아이콘</span></p>
                  </v-col>
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                    <v-col align="center" align-self="center" >
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img height="120" width="120" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon color="#EDEDED" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                    </v-col>
                  </v-col>
                </v-row>                  

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="close">Cancel 취소</v-btn>
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 업로드 프로그레스 -->
    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="4" md="2" class="pa-0" align="center">
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="search_run">Select<br>조회</v-btn>
      </v-col>        
      <v-col cols="4" md="2" class="pa-0 pr-0" align="center">        
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="newinsert">ADD<br>추가</v-btn>
      </v-col>
      <v-col cols="4" md="2" class="pa-0 pr-0 pr-md-2" align="center">        
        <v-btn class="search_btn_freesize" elevation="0" outlined large @click="saveSort">Save Sort<br>순서저장</v-btn>
      </v-col>
    </v-row>
    
  <v-row align="start" class="mt-5" dense>
    <v-col>
      <!-- 테이블 -->
      <draggable v-model="itemList">
      <v-data-table
        :headers="headers"
        :items="itemList"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        item-key="IDX"
        :loading="loading"
        :items-per-page="9999"
        mobile-breakpoint="0"
        >
        <template v-slot:header.PRODUCTTYPE_NAME>
          <a class="cheader_normal">Type Name KOR<br>유형</a>
        </template>
        <template v-slot:header.PRODUCTTYPE_DESC>
          <a class="cheader_normal">Type Name ENG<br>유형</a>
        </template>
        <template v-slot:header.PRODUCTTYPE_STATE>
          <a class="cheader_normal">Type State<br>상태</a>
        </template>
        <template v-slot:header.actions>
          <a class="cheader_normal">Edit/Delete<br>수정/삭제</a>
        </template>
        

        <template v-slot:body="props">
          <draggable :list="props.items" ghost-class="ghost" @change="changedrag" tag="tbody">
            <tr v-for="(item, index) in props.items" :key="index">
              <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
              <td style="width:50px; text-align:center">
                <template v-if="item.PRODUCTTYPE_IMG != null && item.PRODUCTTYPE_IMG.length > 0">
                  <img :src="item.PRODUCTTYPE_IMG" style="width:50px;hegith:50px;margin-top:10px;margin-bottom:5px;" contain/>
                </template>
                <template v-else>
                  <v-icon style="height:50px;width:50px;margin-top:10px;margin-bottom:10px" size="50">mdi-image</v-icon>
                </template>
              </td>
              <td style="width:100px; text-align:center">{{ item.PRODUCTTYPE_NAME }}</td>
              <td style="width:80px; text-align:center">{{ item.PRODUCTTYPE_DESC }}</td>
              <td :class="item.PRODUCTTYPE_STATE == 1 ? 'point_text_green' : 'point_text_red'" style="width:80px; text-align:center">{{ item.PRODUCTTYPE_STATE == 1 ? "활성" : '비활성' }}</td>
              <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon><v-icon small @click="delete_item(item)">mdi-delete</v-icon></td>
            </tr>
          </draggable>
        </template>

      </v-data-table>
      </draggable>
    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import draggable from 'vuedraggable'
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    components: {
      VueCropper,
      draggable,
    },

    data: () => ({

      //필수 페이징 파라메터
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          PRODUCTTYPE_NAME:'',
          PRODUCTTYPE_DESC:'',
          PRODUCTTYPE_STATE:1,
          SORT:1,
      },
      editedItem: {
      },

      //테이블 정의
      headers: [
        {text: '',  value: '', width:60,sortable: false, align:'center' },
        {text: '이미지',  value: 'PRODUCTTYPE_IMG', sortable: false, align:'center', width:50, class:"cheader_normal"},
        {text: '유형',  value: 'PRODUCTTYPE_NAME', width:120, sortable: false, align:'center' },
        {text: '유형',  value: 'PRODUCTTYPE_DESC', width:120, sortable: false, align:'center'},
        {text: '상태',  value: 'PRODUCTTYPE_STATE', width:120, sortable: false, align:'center'},
        {text: '수정/삭제', value: 'actions', width:120, sortable: false, align:'center'}
      ],
      itemList: [],

      STATELIST: [
          {name:'비활성',     value:0}
        , {name:'활성',       value:1}
      ],
      
      //이미지 크롭
      progress_value:0,
      progress_dialog:false,
      crop_dialog:false,
      previewImg1:"",
      imgSrc:'',
      uploadFile1 : [],
      photo_index:0,

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "png",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 100,
        limitMinSize: [100, 100]
      },            
    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    beforeDestroy(){
      //아이폰의 경우 찌거기 제거
      let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
      if(indexcnt > -1){
        let elements = document.getElementsByName("imageinputcamera");
        for(var i=0, len=elements.length ; i != len; ++i){
            elements[0].parentNode.removeChild(elements[0]);
        }
      }
    },

    create(){

    },

    methods: {

      //오리지널 리스트 순서를 변경
      changedrag(e){
        this.itemList.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
        this.itemList.splice(e.moved.newIndex, 0, e.moved.element);  //새로운 위치에 다시 추가
      },

      saveSort(){

        if(this.itemList.length <= 1){
          this.$fire({
            html: "There is no history to change.<br><br>변경하실 내역이 없습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }
        //드래그앤드랍 순서를 저장한다.

        this.$fire({
          html: "Do you want to save the order?<br><br>순서를 저장하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

          var tempidx = 1;
          this.itemList.forEach(item=>{
            item.SORT = tempidx;
            tempidx++;
          });

          this.loading = true;
          this.$http.post(this.$host+'/AdminTypeUpdateSort',
            {
              SORTDATA: escape(JSON.stringify(this.itemList))
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            this.loading = false;
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              this.$alert('순서가 저장되었습니다.').then(()=>{
                this.loadpage();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.$fire({
                html: "Failed to change<br><br>순서 변경이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });            

          }
        });

      },       


      loadpage(){
        
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화

        this.loading = true;

          this.$http.post(this.$host+'/ProductTypeList',{
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }

              const list = result.data.resultData;
              list.forEach(element => {
                  this.itemList.push(element); 
              });
              this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        this.progress_dialog = true;
        if(!this.isNullCheck(this.uploadFile1)){

            //이미지 등록
            let upload_cb1 = await this.uploadStorage("enviroment", Date.now().toString()+".png", this.uploadFile1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$fire({
                  html: "Image registration error occurred<br><br>이미지 등록 오류 발생",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              return;
            } 
            this.uploadFile1 = [];
            
            if(!this.isNullCheck(this.editedItem.PRODUCTTYPE_IMG_REF)){
                try{
                  await this.deletefile(this.editedItem.PRODUCTTYPE_IMG_REF);
                }catch(e){
                }
            }

            this.editedItem.PRODUCTTYPE_IMG_REF = upload_cb1.resultRef;

        }        
        const checkname = cmvalidation.checkValReg(this.editedItem.PRODUCTTYPE_NAME, null, -1, 30);
        if(checkname == 1){
          this.progress_dialog = false;
          this.$fire({
            html: "Please enter a type<br><br>타입명을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }else if(checkname == 4){
          this.progress_dialog = false;
          this.$fire({
            html: "Please Enter Type in 30 characters or less<br><br>타입명은 30자 이하로 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        if(!cmvalidation.isNullCheck(this.editedItem.PRODUCTTYPE_DESC)){
          const checkdesc = cmvalidation.checkValReg(this.editedItem.PRODUCTTYPE_DESC, null, -1, 50);
          if(checkdesc == 4){
            this.progress_dialog = false;
            this.$fire({
              html: "Please enter no more than 50 characters in the description<br><br>설명은 50자 이하로 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
        }


        if (this.editedIndex > -1) {

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/ProductTypeUpdate',{
                PRODUCTTYPE_NAME: escape(this.editedItem.PRODUCTTYPE_NAME)
              , PRODUCTTYPE_DESC: this.editedItem.PRODUCTTYPE_DESC == null || this.editedItem.PRODUCTTYPE_DESC == undefined ? '' : escape(this.editedItem.PRODUCTTYPE_DESC)
              , PRODUCTTYPE_STATE: this.editedItem.PRODUCTTYPE_STATE
              , PRODUCTTYPE_IDX: this.editedItem.PRODUCTTYPE_IDX
              , SORT: this.editedItem.SORT
              , PRODUCTTYPE_IMG: this.editedItem.PRODUCTTYPE_IMG_REF == null || this.editedItem.PRODUCTTYPE_IMG_REF == undefined ? '' : escape(this.editedItem.PRODUCTTYPE_IMG_REF)
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
              this.loading = false;
              this.progress_dialog = false;

            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The modification is complete.<br><br>수정이 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              this.loading = false;
              this.progress_dialog = false;

              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              this.progress_dialog = false;

                this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.progress_dialog = false;

            this.$fire({
                html: "Modification failed.<br><br>수정이 실패했습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
          });
          });


        }else{
          
          //신규
          this.loading = true;
          this.$http.post(this.$host+'/ProductTypeInsert',{
                PRODUCTTYPE_NAME: escape(this.editedItem.PRODUCTTYPE_NAME)
              , PRODUCTTYPE_DESC: this.editedItem.PRODUCTTYPE_DESC == null || this.editedItem.PRODUCTTYPE_DESC == undefined ? '' : escape(this.editedItem.PRODUCTTYPE_DESC)
              , PRODUCTTYPE_STATE: this.editedItem.PRODUCTTYPE_STATE
              , SORT: this.editedItem.SORT
              , PRODUCTTYPE_IMG: this.editedItem.PRODUCTTYPE_IMG_REF == null || this.editedItem.PRODUCTTYPE_IMG_REF == undefined ? '' : escape(this.editedItem.PRODUCTTYPE_IMG_REF)
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{
              this.loading = false;
              this.progress_dialog = false;

            if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "The addition has been completed.<br><br>추가가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.loadpage();
                }
              });

            }else if(result.data.resultCode == 2){
              this.loading = false;
              this.progress_dialog = false;

              //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              this.progress_dialog = false;

              this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              this.loading = false;
              this.progress_dialog = false;

            this.$fire({
              html: "Registration failed. Please register again<br><br>등록실패, 다시 등록해주세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          });


        }

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage();
      },
      
      //신규 추가
      newinsert(){
        this.uploadFile1 = [];
        this.previewImg1 = "";

        this.dialogTitle = "Add 추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem.SORT = this.itemList.length+1;
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){

        this.uploadFile1 = [];
        this.previewImg1 = "";

        this.dialogTitle = "Edit 수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.previewImg1 = this.editedItem.PRODUCTTYPE_IMG != null && this.editedItem.PRODUCTTYPE_IMG.length > 0 ? this.editedItem.PRODUCTTYPE_IMG : "";
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){

        this.$fire({
          html: "Are you sure you want to delete it?<br><br>정말 삭제하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          if(r.value){

            this.loading = true;
            this.$http.post(this.$host+'/ProductTypeDelete',{
                PRODUCTTYPE_IDX:item.PRODUCTTYPE_IDX
            },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.$fire({
                  html: "Deletion completed successfully.<br><br>삭제가 완료되었습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.loadpage();
                  }
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$fire({
                  html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                }).then(r => {
                  if(r.value){
                    this.$EventBus.$emit('clearsession');
                  }
                });
                
              }else{
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$fire({
                  html: "Deletion failed<br><br>삭제 실패",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });

            });      
            
          }
        });        

      },

      //이미지 등록 관련
      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile1 = [];
          return;
        }      

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },


      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }

          });

        });
      },
            
      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>