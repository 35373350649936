<template>
    <v-row justify="center" align="center">
      <v-col cols="12" align="center">
        <v-progress-circular indeterminate size=""/>
      </v-col>
      <v-col cols="12" align="center">
        {{"잠시만 기다려주세요"}}
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
  
    data: () => ({
      PG:"toss",
      ACCNTCODE:"",
      IMP_UID:"",
      MERCHANT_UID:"",
      ERROR_MSG:"",
    }),
  
    mounted(){  
      //path를 통해서 들어올 경우 ex) /경로/값
      window.scrollTo(0,0);
      
      this.ACCNTCODE      = this.$route.query.accntcode;
      this.IMP_UID        = this.$route.query.imp_uid;
      this.MERCHANT_UID   = this.$route.query.merchant_uid;
    
      console.log("ACCNTCODE : " + this.ACCNTCODE);
      console.log("IMP_UID : " + this.IMP_UID);
      console.log("MERCHANT_UID : " + this.MERCHANT_UID);
  
      this.ERROR_MSG = this.$route.query.error_msg;

      this.resultpayment();
    },
  
    beforeDestroy() {
      
    },
    
  
    methods: {
      
      resultpayment(){

        this.sendSuccess();
  
      },
  
      //전송
      sendSuccess(){

        console.log("sendSuccess...");

        this.$http.post(this.$host+'/checkPayment',{
            accnt_idx:this.ACCNTCODE
          , imp_uid: this.IMP_UID
          , merchant_uid: this.MERCHANT_UID
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
            
            this.$alert('결제가 완료되었습니다.').then(()=>{
                this.$router.replace({path:"Shopinfo", query:{}});
            });

          }else{

            this.$alert('결제가 실패했습니다.').then(()=>{
                this.$router.replace({path:"Shopinfo", query:{}});
            });

          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
        });            
  
      },    
  
  
  
  
  
    },
  
  }
  </script>
  
  <style scoped>
  
  
  
  
  </style>