<template>

  <v-container fluid class="container_bg">

    <v-row>
      <v-dialog hide-overlay persistent v-model="progress_dialog" max-width="400px" >
        <v-card>
          <v-card-text class="pa-5">
            Please wait... 잠시만 기다려주세요
            <v-progress-linear
              :value="progress_value" height="10"
              color="black"
              class="mb-3 mt-3"/>            
          </v-card-text>           
        </v-card>
      </v-dialog>  
    </v-row>    
    
    <pdfinfo ref="pdfinfo" />

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row align="center" justify="center" dense>
      <v-col cols="12" md="8" align="center"> <!-- 해상도 조절 컬럼 -->

        <v-row dense justify="center" align="center">
          <v-col cols="4" align="start">
            <label>RAPAPORT ROUNDS 시세표</label>
          </v-col>
          <v-col cols="4" align="end">
            <v-btn v-if="editedItem.RAPA_ROUNDS != null && editedItem.RAPA_ROUNDS != undefined && editedItem.RAPA_ROUNDS.length > 0"
              outlined @click="showImage_pdf_popup(editedItem.RAPA_ROUNDS)">등록 시세표 확인</v-btn>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center">
          <v-col cols="2" align="center">
            <label>변경</label>
          </v-col>
          <v-col cols="6" align="center">
            <v-file-input 
              outlined dense height="48" class="popupiputstyle"
              prepend-icon="mdi-file"
              show-size hide-details v-model="uploadPdfFile1" 
              accept="application/pdf" 
              />
          </v-col>
        </v-row>

        <v-row dense justify="center" align="center" class="mt-10">
          <v-col cols="4" align="start">
            <label>RAPAPORT PEARS 시세표</label>
          </v-col>
          <v-col cols="4" align="end">
            <v-btn v-if="editedItem.RAPA_PEARS != null && editedItem.RAPA_PEARS != undefined && editedItem.RAPA_PEARS.length > 0"
              outlined @click="showImage_pdf_popup(editedItem.RAPA_PEARS)">등록 시세표 확인</v-btn>
          </v-col>
        </v-row>

        <v-row dense align="center" justify="center">
          <v-col cols="2" align="center">
            <label>변경</label>
          </v-col>
          <v-col cols="6" align="center">
            <v-file-input 
              outlined dense height="48" class="popupiputstyle"
              prepend-icon="mdi-file"
              show-size hide-details v-model="uploadPdfFile2" 
              accept="application/pdf" 
              />
          </v-col>
        </v-row>


        <v-row align="center" justify="center" class="mt-10">
          <v-col cols="11" md="6" class="ma-0 pa-0">
            <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="save">Save 저장</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import cmvalidation from '../assets/js/cmvalidation.js'
export default {

    data: () => ({
      
      editedItem:{},
      
      progress_dialog:false,
      progress_value:0,

      uploadPdfFile1 : null,
      uploadPdfFile2 : null,

    }),

    mounted(){

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }

    },

    beforeRouteLeave(to, from, next){
      console.log("beforeRouteLeave");
      if(this.$refs.pdfinfo.pdf_dialog){
        this.$refs.pdfinfo.close();
        next(false);
      }else{
        next();
      }
    },

    methods: {

      showImage_pdf_popup(url){
        this.$refs.pdfinfo.geturl(url);
      },         

      loadpage(){

        this.$http.post(this.$host+'/RapaPdf',{
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){

              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.editedItem = result.data.resultData[0];

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }

        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });        

      },

      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      


      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },

      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },

      //추가 저장
      async save () {

        this.progress_dialog = true;

        if(this.uploadPdfFile1 != null){
          //요청으로 인해 업로드 엑셀파일 백업
          let upload_cb1 = await this.uploadStorage("uploadpdf", "rapaport_rounds_"+Date.now().toString()+"."+this.uploadPdfFile1.name.split('.').pop().toLowerCase(), this.uploadPdfFile1);
          if(upload_cb1 == null){
            this.$fire({
                html: "RAPAPORT ROUNDS 시세표 등록에 실패하였습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.progress_dialog = false;
              return;
          }
          this.uploadPdfFile1 = null;
          this.editedItem.RAPA_ROUNDS_REF = upload_cb1.resultRef;
        }

        if(this.uploadPdfFile2 != null){
          //요청으로 인해 업로드 엑셀파일 백업
          let upload_cb2 = await this.uploadStorage("uploadpdf", "rapaport_rounds_"+Date.now().toString()+"."+this.uploadPdfFile2.name.split('.').pop().toLowerCase(), this.uploadPdfFile2);
          if(upload_cb2 == null){
            this.$fire({
                html: "RAPAPORT PEARS 시세표 등록에 실패하였습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.progress_dialog = false;
              return;
          }
          this.uploadPdfFile2 = null;
          this.editedItem.RAPA_PEARS_REF = upload_cb2.resultRef;
        }


        //수정
        this.$http.post(this.$host+'/RapaPdfUpdate',{
              RAPA_ROUNDS: this.editedItem.RAPA_ROUNDS_REF == null || this.editedItem.RAPA_ROUNDS_REF == undefined ? '' : escape(this.editedItem.RAPA_ROUNDS_REF)
            , RAPA_PEARS: this.editedItem.RAPA_PEARS_REF == null || this.editedItem.RAPA_PEARS_REF == undefined ? '' : escape(this.editedItem.RAPA_PEARS_REF)
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.progress_dialog = false;
          if(result.data.resultCode == 0){

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }
            this.$fire({
              html: "The modification is complete.<br><br>수정이 완료되었습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.loadpage();
              }
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$fire({
              html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$EventBus.$emit('clearsession');
              }
            });

          }else{
            this.$fire({
              html: "Modification failed.<br><br>수정이 실패했습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.progress_dialog = false;
          this.$fire({
              html: "Modification failed.<br><br>수정이 실패했습니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
          });
        });
        
      },      



      //이미지 등록 관련
      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },


      //이미지 선택시
      changefile(file, pindex){

        if(file.size/1024/1024 > 10){
          this.$fire({
            html: "Only files of 10MB or less are available.<br><br>10MB 이하 파일만 사용할 수 있습니다.",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          this.uploadFile1 = [];
          return;
        }      

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },


      //이미지 크롭시
      cropimg(){

        //이미지 크롭 
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }

          });

        });
      },
            
      updateprogress(e){
        this.progress_value = (e.loaded / e.total) * 100;
      },
      async uploadStorage(folder, filename, blobd_ata){
        try{
          const formData = new FormData();
          formData.append("FOLDER", folder);
          formData.append("FILENAME", filename);
          formData.append("customfile", blobd_ata);
          const result = await this.$http.post(this.$host+'/uploadimg'
            , formData
            , {headers:{'Content-Type':'multipart/form-data',accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}
            , onUploadProgress:this.updateprogress
          });
          if(result.data.resultCode == 0){
            return result.data;
          }else{
            return null;
          }
        }catch(error){
          return null;
        }
      },
      async deletefile(deletefilename){
        try{
          const result = await this.$http.post(this.$host+'/deleteimg',{filename: deletefilename},{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}});
          return result;
        }catch(error){
          return {"data":{resultCode:1}};
        }
      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>