<template>

    <!-- 구매요청 상세내역 -->
    <v-row align="center"  justify="center" >
      
      <pdfinfo ref="pdfinfopopup" />

      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="req_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false" 
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">
        
        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Purchase Info&nbsp;구매요청 상세내역</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            
            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Receiving method&nbsp;<span class="dialog_subtitle_kor">수령방식</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="reqItem.SHIP_TYPE" :items="SHIPTYPELIST" 
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" disabled
                      outlined color="#000000" hide-details class="none_radius" 
                      />
                    </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">이름</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="prov_id"
                      single-line v-model="reqItem.NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Name&nbsp;<span class="dialog_subtitle_kor">주소</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="prov_id"
                      single-line v-model="reqItem.ADDRESS" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Comment&nbsp;<span class="dialog_subtitle_kor">요청사항</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea
                      class="none_radius" color="black" disabled
                      single-line v-model="reqItem.MESSAGE"
                      :height="$vuetify.breakpoint.smAndDown ? 80 : 100"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                        


                <!-- 세공인 경우 세공정보 추가 입력 -->
                <template v-if="reqItem.PRODUCT_CLASS == 3">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng" id="PRODUCT_IMG1">Jewelry Making Information&nbsp;<span class="dialog_subtitle_kor">세공의뢰 정보</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                  </v-row>

                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Type of jewelry provided&nbsp;<span class="dialog_subtitle_kor">제공 보석종류</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-select 
                        v-model="reqItem.REQ_KIND" :items="DESIGNGEMLIST" disabled
                        dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                        item-text="name" item-value="value" 
                        outlined color="#000000" hide-details class="none_radius" 
                        />
                    </v-col>
                  </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Jewelry Type Provided&nbsp;<span class="dialog_subtitle_kor">제공 보석타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="reqItem.REQ_CLASS" :items="DIAMONDTYPELIST" disabled
                      dense :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      item-text="name" item-value="value" 
                      outlined color="#000000" hide-details class="none_radius" 
                      />
                  </v-col>
                </v-row>

                <v-row v-if="reqItem.REQ_KIND == 0" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Diamond Shape&nbsp;<span class="dialog_subtitle_kor">다이아 쉐입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="reqItem.DIASHAPE_NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>                

                <v-row v-if="reqItem.REQ_KIND == 1" dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Gemstone Kind&nbsp;<span class="dialog_subtitle_kor">보석 종류</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" 
                      single-line v-model="reqItem.GEMTYPE_NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Carat&nbsp;<span class="dialog_subtitle_kor">보석중량</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="reqItem.REQ_WEIGHT" suffix="ct" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Size&nbsp;<span class="dialog_subtitle_kor">보석사이즈</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="reqItem.REQ_SIZE" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>
                
                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Laboratory&nbsp;<span class="dialog_subtitle_kor">감정기관</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black"
                      single-line v-model="reqItem.REQ_CERTI" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>

                <template v-if="provpreviewImg1.length > 0">
                  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Certificate image&nbsp;<span class="dialog_subtitle_kor">감정서 이미지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                      <div class="black_line"/>    
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
                      <v-col align="center" align-self="center" >

                        <template v-if="provpreviewImg1.length > 0">
                          <v-row dense  justify="center">
                            <template v-if="provpreviewImg1 == provpdfPreviewImg1 || provpreviewImg1.split('.').pop().toLowerCase() == 'pdf'">
                              <img  width="240" style="hegiht:auto" :src="provpdfPreviewImg1" class="image" @click="showImage_pdf_popup()">
                            </template>
                            <template v-else>
                              <viewer class="viewer" ref="viewer">
                                <img style="hegiht:auto" width="240" :src="provpreviewImg1" class="image">
                              </viewer>
                            </template>
                          </v-row>
                        </template>
                        <template v-else>
                          <v-row dense class="image-border2" align="center" justify="center">
                          </v-row>
                        </template>
                      </v-col>
                    </v-col>
                  </v-row> 
                </template>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Option&nbsp;<span class="dialog_subtitle_kor">구매옵션</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-text-field 
                      class="none_radius" color="black" id="prov_id"
                      single-line v-model="reqItem.OPTION_NAME" disabled
                      :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined dense hide-details/>
                  </v-col>
                </v-row>


              </template>

              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Order Quantity&nbsp;<span class="dialog_subtitle_kor">주문수량</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <v-text-field 
                    class="none_radius" color="black" id="prov_id"
                    single-line v-model="reqItem.QUANTITY" disabled
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                  <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Total Order Price&nbsp;<span class="dialog_subtitle_kor">총 주문금액</span></p>
                </v-col>
                <v-col cols="12" md="10" class="ma-0 pa-0">
                  <div style="text-align:left;font-size:14px;color:black">구매상품 : {{ reqItem.PRODUCT_NUMBER }} </div>
                  <div style="text-align:left;font-size:14px;color:black">상품가격 : {{ reqItem.PPRICE_DP }} * {{ reqItem.QUANTITY }} = {{ priceToString(reqItem.PRICE_TYPE, Number(reqItem.PPRICE) * Number(reqItem.QUANTITY) ) }}</div>
                  <div v-if="reqItem.OPTION_NAME != null" style="text-align:left;font-size:14px;color:black">구매옵션 : {{ reqItem.OPTION_NAME }} </div>
                  <div v-if="reqItem.OPTION_NAME != null" style="text-align:left;font-size:14px;color:black">옵션가격 : {{ reqItem.OPRICE_DP }} * {{ reqItem.QUANTITY }} = {{ priceToString(reqItem.PRICE_TYPE, Number(reqItem.OPRICE) * Number(reqItem.QUANTITY) ) }}</div>
                  <v-text-field 
                    class="none_radius" color="black"  
                    single-line v-model="reqItem.BUY_PRICE_DP" disabled :prefix="reqItem.PRICE_TYPE == 0 ? '$' : 'W'"
                    :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                    outlined dense hide-details/>
                </v-col>
              </v-row>              

              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="req_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
          
        </v-card>
      </v-dialog>  
    </v-row>        
</template>

<script>
export default {

    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{

    },

    data: ()=> ({
      //공급처 정보
      req_dialog: false,
      reqItem:{},

      //PDF첨부시 보여줄 공통 이미지 - 사업자등록증
      provpdfPreviewImg1:"https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/bmimg.jpg",

      provpreviewImg1:"",

      SHIPTYPELIST: [
          {name:'Direct delivery from KDGE./본사직접배송',          value:0}
        , {name:'Delivery service./택배',                          value:1}
        , {name:'Visit a designate retailer to receive the products./지정소매상',  value:2}
        , {name:'Visit KDGE to receive the products./본사방문수령',                value:3}
      ],

      DIAMONDTYPELIST: [
          {name:'Natural/천연',    value:0}
        , {name:'Lab Grown/합성',  value:1}
      ],

      DESIGNGEMLIST: [
          {name:'Diamond/다이아몬드',         value:0}
        , {name:'Gemstone/보석',             value:1}
      ],

    }),

    methods: {

      priceToString(pricetype, price) {
        if(pricetype == 0){
          return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }else{
          return price.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      },

      showImage_pdf_popup(){
        this.$refs.pdfinfopopup.geturl(this.reqItem.REQ_CERTIIMG);
      },           

      isNullCheck_popup(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },      

      //거래내역 정보 조회
      loadpurchase(idx){

        this.$http.post(this.$host+'/PuchaseDetail2',{
          PURCHASE_IDX:idx
        },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.reqItem = result.data.resultData[0];
              this.provpreviewImg1 = "";
              if(this.reqItem.REQ_CERTIIMG != null && this.reqItem.REQ_CERTIIMG.length > 0){
                if(this.reqItem.REQ_CERTIIMG.split('.').pop().toLowerCase() == 'pdf'){
                  //pdf면 pdf미리보기로 지정
                  this.provpreviewImg1 = this.provpdfPreviewImg1;
                }else{
                  //이미지면 그대로 지정
                  this.provpreviewImg1 = this.reqItem.REQ_CERTIIMG != null && this.reqItem.REQ_CERTIIMG.length > 0 ? this.reqItem.REQ_CERTIIMG : "";
                }
              }
              this.req_dialog  = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }else{
            this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
          }
        });

      },

    }

}
</script>


<style scoped>

</style>

