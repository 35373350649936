<template>
  <v-container fluid class="container_bg">


    <!-- 공급처 정보 -->
    <userinfo ref="userinfo" />

    <!-- 구매요청 상세정보 -->
    <reqinfo ref="reqinfo" />

    <!-- 취소요청 정보 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancelreq_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancel Request Info<br>취소요청정보</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text>

            <v-row align="center" justify="center" dense>
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Type&nbsp;<span class="dialog_subtitle_kor">취소타입</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <template v-if="cancelreqItem.CANCEL_TYPE == 0">
                      <label>구매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 1">
                      <label>판매자의 취소요청</label>
                    </template>
                    <template v-else-if="cancelreqItem.CANCEL_TYPE == 2">
                      <label>관리자의 취소</label>
                    </template>
                  </v-col>
                </v-row>              

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Cancel Message&nbsp;<span class="dialog_subtitle_kor">취소요청 메시지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelreqItem.COMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
                    <div class="black_line"/>    
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">거래취소 처리</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="cancelreqItem.CANCEL_CONFIRM" :items="CONFIRMLIST" 
                      dense disabled
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Answer Message&nbsp;<span class="dialog_subtitle_kor">답변 메시지</span></p>
                    </v-col>
                    <v-col cols="12" md="10" class="ma-0 pa-0">
                      <v-textarea
                        class="none_radius" color="black" disabled
                        single-line v-model="cancelreqItem.RECOMMENT"
                        outlined dense hide-details/>
                    </v-col>
                </v-row>         


              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions class="mt-1 mb-1">
            <v-row align="center" justify="center">
              <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancelreq_dialog = false">Close 닫기</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <!-- 거래 취소 -->
    <v-row align="center"  justify="center">
      
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="cancel_dialog" 
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '600px'"
        class="none_radius">

        <v-card class="pa-0 ma-0">

          <v-card-title class="ma-0 pa-0" style="overflow:hidden">
          <v-row dense align="center" justify="center">
            <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
              <span style="color:white">Cancellation of transaction<br>거래취소</span>
            </v-col>
          </v-row>
          </v-card-title>

          <v-card-text class="d-flex">

            <v-row align="center" justify="center" dense >
              <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <v-row dense align="center" justify="center" class="d-flex mt-5 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="center"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-center ma-0 pa-0 dialog_subtitle_eng">거래 취소를 처리합니다.</p>
                  </v-col>
                </v-row>                  

                <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">거래취소 처리</p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-select 
                      v-model="CANCEL_CONFIRM" :items="CONFIRMLIST" 
                      dense 
                      item-text="name" item-value="value" :height="$vuetify.breakpoint.smAndDown ? 40 : 50"
                      outlined color="#000000" hide-details class="none_radius"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center" class="mt-2 mt-md-10 ma-0">
                  <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
                    <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">Message&nbsp;<span class="dialog_subtitle_kor">메시지</span></p>
                  </v-col>
                  <v-col cols="12" md="10" class="ma-0 pa-0">
                    <v-textarea v-model="RECOMMENT" color="black" hide-details outlined dense/>
                  </v-col>
                </v-row>         

              </v-col>
            </v-row>

          </v-card-text>

        <v-card-actions class="mt-1 mb-1">
          <v-row align="center" justify="center">
            <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="cancel_dialog = false">Close 닫기</v-btn>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cancel_save">Save<br>저장</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>       

    <v-row align="center" justify="start" class="mt-5">

      <v-col cols="9" md="3">
        <label class="menunamestyle">{{this.$session.get("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" class="hidden-md-and-up">
        <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
      </v-col>

      <template v-if="grade == 99">
        <v-col cols="12" md="4" class="pa-0 pl-3 pl-md-0 pr-2 pr-md-1">
          <dialogaccnt  :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></dialogaccnt>
        </v-col>
      </template>

    </v-row>

    <v-row dense no-gutters class="mt-md-0 mt-5 hidden-sm-and-down">
      <label class="total_label text-md-subtitle-2 text-caption">전체:</label><label class="total_label text-md-subtitle-2 text-caption">{{totalcnt}}</label>
    </v-row>


    <v-row align="start" class="mt-5 mt-md-0" dense>
      <v-col>
        <!-- 테이블 -->
        <v-data-table
          :headers="headers"
          :items="itemList"
          no-data-text="데이터가 없습니다."
          hide-default-footer
          class="datatablestyle"
          :items-per-page="pagerow"
          item-key="PURCHASE_IDX"
          :loading="loading"
          item-class="itemstyle"
          mobile-breakpoint="0"
          >
          <template v-slot:header.PURCHASE_IDX>
            <a :class="sort_name=='PURCHASE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('PURCHASE_IDX')">No.<br>번호</a>
          </template>
          <template v-slot:header.PRODUCT_NUMBER>
            <a :class="sort_name=='PRODUCT_NUMBER'?'cheader_sortselect':'cheader_sort'" @click="customsort('PRODUCT_NUMBER')" style="text-decoration: underline;">Product Name<br>상품명</a>
          </template>
          <template v-slot:header.PROV_NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Vendor<br>공급처</label>
          </template>
          <template v-slot:header.QUANTITY>
            <label class="cheader_normal">Quantity<br>수량</label>
          </template>
          <template v-slot:header.SUPPLY_PRICE>
            <label class="cheader_normal">Supply Price<br>공급가</label>
          </template>
          <template v-slot:header.PRICE_DP_USD>
            <label class="cheader_normal">Price<br>판매가</label>
          </template>
          <template v-slot:header.PURCHASE_STATENAME>
            <label class="cheader_normal">State<br>상태</label>
          </template>
          <template v-slot:header.COM_DATE>
            <a :class="sort_name=='COM_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('COM_DATE')">Registration Date<br>요청일자</a>
          </template>
          <template v-slot:header.BNAME>
            <label class="cheader_normal" style="text-decoration: underline;">Name<br>이름</label>
          </template>
          <template v-slot:header.NAME>
            <label class="cheader_normal" style="text-decoration: underline;">Buyer<br>구매자</label>
          </template>
          <template v-slot:header.actions>
            <label class="cheader_normal">Info<br>거래상세</label>
          </template>
          <template v-slot:header.cancel>
            <label class="cheader_normal">Cancel confirm<br>취소처리</label>
          </template>

          <template v-slot:item.PURCHASE_IDX="{ item }">  
            <a @click="$refs.reqinfo.loadpurchase(item.PURCHASE_IDX)" >{{item.PURCHASE_IDX}}</a>
          </template>
          <template v-slot:item.PRODUCT_NUMBER="{ item }">  
            <a @click="loadnotice(item)" >{{item.PRODUCT_NUMBER}}</a>
          </template>

          <template v-slot:item.PRICE_DP_USD="{ item }">  
            <!-- <div>{{ item.PRICE_TYPE == 0 ? '$ '+item.PRICE_DP_USD +' USD': '₩ '+item.PRICE_DP_WON +' 원' }}</div> -->
            <label>{{'₩ '+item.PRICE_DP_WON}}</label><br>
            <label>{{'$ '+item.PRICE_DP_USD}}</label>
          </template>

          <template v-slot:item.SUPPLY_PRICE="{ item }">  
            <label>{{'₩ '+item.SUPPLY_PRICE_WON_DP}}</label><br>
            <label>{{'$ '+item.SUPPLY_PRICE_USD_DP}}</label>
          </template>

          <template v-slot:item.PURCHASE_STATENAME="{ item }">  
            <a @click="loadcancelreq(item)">{{item.PURCHASE_STATENAME}}</a>
          </template>
          <template v-slot:item.PROV_NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.PROV_IDX)" >{{item.PROV_NAME}}</a>
          </template>
          <template v-slot:item.BNAME="{ item }">  
            <label>{{item.BNAME}}</label>
          </template>

          <template v-slot:item.NAME="{ item }">  
            <a @click="$refs.userinfo.loadprov(item.ACCNT_IDX)" >{{item.NAME}}</a>
          </template>

          <template v-slot:item.actions="{ item }">  
              <v-icon small @click="edit_item(item)">mdi-pencil</v-icon>
          </template>  

          <template v-slot:item.cancel="{ item }">  
              <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
          </template>  

        </v-data-table>
      </v-col>
    </v-row>

    <!-- 하단검색 -->
    <searchbar ref="searchbar" @searchcallback="search_run"/>

    <!-- 하단페이징영역 -->
    <pagingbar ref="paging" @loadpage="movepage"/>

  </v-container>
</template>
<script>

export default {

    mounted() {

      if(this.$session.get("GRADE") < 90){
            this.$fire({
              html: "Page of permissions not available.<br><br>사용할 수 없는 권한의 페이지입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            }).then(r => {
              if(r.value){
                this.$router.back();
              }
            });
        return;
      }

      this.SEARCH_ACCNT_IDX    = this.$session.get("SEARCH_ACCNT_IDX");
      this.SEARCH_ACCNT_NAME   = this.$session.get("SEARCH_ACCNT_NAME");
      this.grade = this.$session.get("GRADE");

      if(this.$session.exists("ACCNT_IDX")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }      

    },

    data: () => ({

      grade : 0,
      SEARCH_ACCNT_IDX: null,
      SEARCH_ACCNT_NAME: '',
      
      CONFIRMLIST:[
          {name:'취소요청',   value:0}
        , {name:'취소승인',   value:1}
        , {name:'취소거부',   value:2}
      ],
      CANCEL_CONFIRM:0,
      RECOMMENT:'',
      
      cancel_dialog:false,
      editedItem: {
      },

      //취소요청 정보
      cancelreqItem:{},
      cancelreq_dialog:false,

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      loading : false,    //로딩 표시

      //테이블 정의
      headers: [
        {text: '번호', value: 'PURCHASE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {text: '상품명', value: 'PRODUCT_NUMBER', sortable: false, align:'center', width:'120px', class:"cheader_normal"},
        {text: '공급처', value: 'PROV_NAME', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '수량', value: 'QUANTITY', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {text: '공급가', value: 'SUPPLY_PRICE', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '가격', value: 'PRICE_DP_USD', sortable: false, align:'right', width:'100px', class:"cheader_normal"},
        {text: '상태', value: 'PURCHASE_STATENAME', sortable: false, align:'center' , width:'80px', class:"cheader_normal"},
        {text: '요청일자', value: 'COM_DATE', sortable: false, align:'center' , width:'140px', class:"cheader_normal"},
        {text: '이름', value: 'BNAME', sortable: false, align:'center' , width:'120px', class:"cheader_normal"},
        {text: '구매자', value: 'NAME', sortable: false, align:'center' , width:'100px', class:"cheader_normal"},
        {text: '거래상세', value: 'actions', sortable: false, align:'center', width:'60px', class:"cheader_normal"},
        {text: '취소', value: 'cancel', sortable: false, align:'center', width:'60px', class:"cheader_normal"}
      ],
      itemList: [],
      
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수

    }),

    created(){

    },

    methods: {

      initpage(){
        this.page = this.$route.query.page;
        if(this.page == undefined || this.page == null){
          this.page = 1;
        }
        this.search_keyword = this.$route.query.search;
        if(this.search_keyword == undefined || this.search_keyword == null){
          this.search_keyword = '';
        }else{
          this.$refs.searchbar.setSearch(this.search_keyword);
        }
        if(this.sort_type == undefined || this.sort_type == null){
          this.sort_type = true;
        }else{
          if(this.$route.query.sorttype == "true"){
            this.sort_type = true;
          }else if(this.$route.query.sorttype == "false"){
            this.sort_type = false;
          }
        }

        this.sort_name = this.$route.query.sortname;
        if(this.sort_name == undefined || this.sort_name == null){
          this.sort_name = 'REG_DATE';
        }

        //첫페이지 로드
        this.loadpage(this.page);
      },      
      //페이지 이동인데 다시 읽도록, 그래야 뒤로 눌렀을 때 복원됨. 안그럼 1페이지로 감.
      movepage(p){
        this.$router.push({path:"/AdminTradeCanReq", query:{page:p, search:this.search_keyword, sorttype:this.sort_type, sortname:this.sort_name}});
      },

      //검색
      search_run(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.movepage(1);
        //this.loadpage(1);
      },

      loadpage(p){

          this.loading = true;
          this.page = p;
          this.$refs.paging.setpage(this.page);
          this.$http.post(this.$host+'/AdminTradeCanReqList', {
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , ACCNT_IDX: this.SEARCH_ACCNT_IDX
          },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
          .then((result)=>{

            if(result.data.resultCode == 0){

                //토큰 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                }

                this.totalcnt = result.data.totalcnt;
                this.$refs.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){

              //로그인 필요
              this.loading = false;
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

            }else{
              this.loading = false;
              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$fire({
                html: "Please try it later due to many requests.<br><br>많은 요청으로 인해 잠시 후 시도해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }else{
              this.$fire({
                html: error.message,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          });

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },
      
      //취소요청정보
      loadcancelreq(item){

        //계약금 입금 정보 가져오기
        this.$http.post(this.$host+'/AdminCancelReqDetail', {
          PURCHASE_IDX: item.PURCHASE_IDX
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              if(result.data.resultData.length < 1){
                this.$fire({
                  html: "Request information is missing.<br><br>요청정보가 없습니다.",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
                return;
              }else{
                this.cancelreqItem = result.data.resultData[0];
                this.cancelreq_dialog = true;
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });
          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          
          this.cancelreq_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },



      cancel_save(){

        this.$http.post(this.$host+'/AdminCancelPurchase', {
            PURCHASE_IDX: this.editedItem.PURCHASE_IDX
          , CANCEL_CONFIRM: this.CANCEL_CONFIRM  
          , RECOMMENT: this.RECOMMENT == null || this.RECOMMENT == undefined ? '' : escape(this.RECOMMENT)
        },{headers:{accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
        .then((result)=>{
          this.cancel_dialog = false;
          if(result.data.resultCode == 0){
              //토큰 저장
              if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
              }
              if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
              }
              this.$fire({
                html: "Cancellation completed.<br><br>취소가 완료되었습니다.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
              this.search_run();

          }else if(result.data.resultCode == 2){
            //로그인 필요
              this.$fire({
                html: "Please log in again due to token expiration.<br><br>토큰 만료로 인해 다시 로그인해주세요.",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$EventBus.$emit('clearsession');
                }
              });

          }else{
            this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.cancel_dialog = false;
          this.$fire({
            html: "Request failed<br><br>요청 실패",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
        });

      },      



      loadnotice(item){
        this.$router.push({path:"/ProductDetail", query:{code:item.PRODUCT_IDX}});
      },

      
      edit_item(item){
        this.$router.push({path:"/PurchaseDetailList", query:{code:item.PURCHASE_IDX}});
      },

      //아이템 삭제
      delete_item(item){
        this.editedItem = Object.assign({}, item);
        this.cancel_dialog = true;
      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME = val1.NAME;
          this.SEARCH_ACCNT_IDX  = val1.ACCNT_IDX;
          this.$session.set("SEARCH_ACCNT_NAME",  val1.NAME);
          this.$session.set("SEARCH_ACCNT_IDX",   val1.ACCNT_IDX);
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_ACCNT_IDX  = null;
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
        }
        this.search_run();
      },

      // eslint-disable-next-line no-unused-vars
      comsearchclear(var2){
          this.SEARCH_ACCNT_IDX    = null;
          this.SEARCH_ACCNT_NAME   = '';
          this.$session.set("SEARCH_ACCNT_NAME", "");
          this.$session.set("SEARCH_ACCNT_IDX",  null);
          this.search_run();
      },      

    },



}
</script>

<style scoped>

</style>