<template>
  <div  ref='viewer'></div>
</template>

<script>
/* eslint-disable */
import WebViewer from '@pdftron/webviewer'
export default {
  name: 'WebViewer',
  props: {
    path: String,
    data: String,
    bb:ArrayBuffer
  },
 
  data(){
    return{
      pdfinstance:null
    }
  }, 

  methods:{

      async reloadurl(pdfurl){
        //인스턴스가 생성된 경우면 타진다.
        //this.pdfinstance.updateView(1, 1);
        //console.log("reloadurl : "+ pdfurl);
        await this.pdfinstance.loadDocument(pdfurl);
        await this.pdfinstance.refreshPage(1);
        //await this.pdfinstance.refreshAll();
        //this.$refs.viewer.refreshAll();
      },

      async reloadfile(pdfdata){
        //인스턴스가 생성된 경우면 타진다.
        //console.log("reloadfile : "+ pdfdata);
        if(pdfdata != null){
          const arr = new Uint8Array(pdfdata);
          const blob = new Blob([arr], { type: 'application/pdf' });
          await this.pdfinstance.loadDocument(blob);
          await this.pdfinstance.refreshPage(1);
          //await this.pdfinstance.refreshAll();
        }
      }

  },

  

  mounted: function () {

    //console.log("path : "+ this.path);
    //console.log("data : "+ this.data);
    //console.log("bb : "+ this.bb);

      WebViewer({

        path: this.path,
        initialDoc: this.data, // replace with your own PDF file
        
        disabledElements:[
           'header','pageNavOverlay','toolbarGroup-Annotate','toolbarGroup-Shapes','toolbarGroup-Insert','toolbarGroup-Edit','toggleNotesButton'
          ,'moreButton','toolsHeader', 'toolbarGroup-View', 'textSelectButton', 'leftPanel','leftPanelButton', 'viewControlsButton'
          ,'panToolButton', 'selectToolButton', 'searchButton', 'menuButton', 'zoomOverlayButton', 'toolsHeader'
        ]

      }, this.$refs.viewer).then((instance) => {
        
        this.pdfinstance = instance;

        // call apis here
        //instance.UI.disableAnnotations(); //편집기능 버튼 안나옴.
        //instance.UI.enableDesktopOnlyMode();

        //로컬파일일 경우
        if(this.bb != null){
          //console.log("bb is not null : " + this.bb);
          const arr = new Uint8Array(this.bb);
          const blob = new Blob([arr], { type: 'application/pdf' });
          instance.loadDocument(blob);
        }
        

        //const arr = new Uint8Array(this.bb);
        //const blob = new Blob([arr], { type: 'application/pdf' });
        //instance.UI.loadDocument(this.bb, { filename: 'myfile.pdf' });

      });

    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%; 
  height: calc(100vh - 40px);
}
</style>