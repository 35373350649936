<template>
  <v-container fluid class="pa-0 ma-0" style="background:white">
    
    <v-row justify="center" dense>
      <v-col cols="11" md="8" align="center" class="mt-10 mt-md-15 pt-md-10">
        <v-img :aspect-ratio="500/166" class="ml-4 ml-md-0" :width="$vuetify.breakpoint.smAndDown ? 200 : 300"
            src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/vividlogo.png">
        </v-img>    
      </v-col>
    </v-row>

    <v-row class="mt-5 mt-md-15">
      <v-col cols="12">
        <v-img :aspect-ratio="1920/700" src="https://storage.googleapis.com/sndge-3f7bb.appspot.com/website/aboutus_img2_2.jpg" class="pt-3">
        </v-img>    
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="11" md="6" align="center">
        <div class="mt-10 mt-md-15" style="font-family:NotoSansKR">천연 다이아몬드는 국경이 없는 제2의 화폐이며, 미래를 대비한 투자가치 높은 최고의 상품입니다.</div>
        <div class="mt-5 mt-md-5" style="font-family:Noto Serif">Natural diamonds are the second currency without borders and are the best products with high investment value in preparation for the future.</div>
      </v-col>
    </v-row>

    <v-row class="mt-5 mt-md-15">
      <v-col cols="12">
        <v-img :aspect-ratio="1920/1866" :src="require('@/assets/vivid260.jpg')" class="pt-3">
        </v-img>    
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-15">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >1.품질보증</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                        >Quality Guarantee</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>VIVID DIAMOND는 전 세계에서 인정받고 있는 GIA 감정서의 다이아몬드 중에서도 최상위 품질을 엄격하게 선별하여 공급합니다.</li> 
                        <li>또한 VIVID DIAMOND Certificate는 소비자 눈높이에 맞는 평가등급 세분화 감정기준에 따라 일반인도 쉽게 그 품질을 구분할 수 있습니다.</li>
                        <li>VIVID DIAMOND는 구매자가 환매를 원할 경우 언제든지 80%에 재매입을 보증합니다.</li>
                        <li>만약 VIVID DIAMOND를 구매한 소비자에게 어떠한 피해가 발생한다면 KDGE&lt;Korea DIAMOND GEM Exchange&gt;가 전액 보상책임을 집니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10"/>
                    <ol class="ml-2 mr-2" type="1" start="1" style="font-family:Inter">
                        <li>VIVID DIAMOND strictly selects and supplies the highest quality among GIA appraisal diamonds recognized worldwide.</li>
                        <li>In addition, the quality of VIVID DIAMOND Certificate can be easily distinguished by the general public according to the evaluation grade subdivision evaluation criteria that meet the consumer's eye level.</li>
                        <li>VIVID DIAMOND guarantees 80% repurchase at any time if the buyer wants to repurchase.</li>
                        <li>If any damage occurs to a consumer who has purchased VIVID DIAMOND, KDGE &lt;Korea DIAMOND GEM Exchange&gt; is fully responsible for compensation.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >2.투자 가치</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Investment Value</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>평가등급 세분화로 엄격하게 선별한 VIVID DIAMOND의 품질과 가치는 오랜 세월이 지나도 영원히 변하지 않습니다.</li>
                        <li>천연 광물은 시간이 지나면 국제시세가 많이 오르기 때문에 품질이 보증된 VIVID DIAMOND 구매 후 세월이 지나면 지날수록 높은 수익이 보장됩니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>The quality and value of VIVID DIAMOND, strictly selected through subdivision of evaluation grades, will not change forever even after many years.</li>
                        <li>Since the international market price of natural minerals rises a lot over time, high profits are guaranteed as time goes by after purchasing VIVID DIAMOND with guaranteed quality.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >3.정찰 가격</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Fixed Price</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>VIVID DIAMOND는 가격경쟁이 아닌 품질과 가치로 소비자를 보호합니다.</li>
                        <li>VIVID DIAMOND는 전국의 어디에서나 동일한 소비자가격을 준수합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>VIVID DIAMOND protects consumers with quality and value, not price competition.</li>
                        <li>VIVID DIAMOND complies with the same retail price anywhere in the country.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    


    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >4.환매 보장</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Repurchase Guarantee</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>소비자가 원할 경우 언제든지 판매가의 80%에 재매입을 보장합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>Guaranteed repurchase at 80% of the selling price at any time if the consumer wants.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >5.거래 방법</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >How to trade</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>구매자가 특정 상품에 대해 구매요청을 하시면 본사 담당자가 먼저 이메일로 전화를 드린다는 안내를 합니다.</li>
                        <li>구매 문의한 상품은 KDGE가 철저하게 상품의 품질 등을 확인 한 후 요청하신 상품에 조금이라도 문제가 있을 경우에는 본사에서 책임지고 보증 할 수 있는 대체 상품을 추천 할 수 있습니다.</li>
                        <li>본사는 GIA감정서 중에서 엄격한 평가등급 세분화 감정 기준을 통과한 다이아몬드만을 VIVID DIAMOND Certificate를 발행하여 기존 GIA  Certificate를 함께 첨부하여 납품합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>When a buyer makes a purchase request for a specific product, a representative from our headquarters will first contact them via email.</li>
                        <li>After KDGE has thoroughly checked the quality of the product you have inquired about purchasing, if there is any problem with the requested product, we may recommend a replacement product that our head office can take responsibility for and guarantee.</li>
                        <li>Our company issues a VIVID DIAMOND Certificate only for diamonds that have passed the strict evaluation grading standards among the GIA Certificates and delivers them with the existing GIA Certificate attached.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >6.결제 방법</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Payment Method</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>불필요한 수수료를 없애고 최저가로 공급을 하기 위해 모든 상품의 대금 결제는 본사 통장 거래로만 가능합니다.</li>
                        <li>대금 결제<br>
                            (1)주문 시 상품대금 100% 전액을 입금할 경우 0.5% DC 혜택을 드립니다.<br>
                            (2)10%의 계약금(구매보증금)을 지불하고 인수하면서 잔액 90%를 지불할 수 있습니다.<br>
                        </li>
                        <li>모든 상품은 정식 수입을 하기 때문에 부가세 10%가 포함되어 있으며, 현금영수증 또는 세금계산서를 발행합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>In order to eliminate unnecessary fees and supply at the lowest price, payment for all products can only be made through the head office's bank account transaction.</li>
                        <li>Payment<br>
                            (1)If you deposit 100% of the product price when ordering, you will receive a 0.5% DC benefit.<br>
                            (2)You can pay 10% down payment (purchase deposit) and pay the remaining 90% upon takeover.<br>
                        </li>
                        <li>All products are formally imported, so 10% VAT is included, and cash receipts or tax invoices are issued.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >7.납품 절차</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Delivery Procedure</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>납품 기간은 상품대금 전액 또는 계약금(10%)을 KDGE 통장으로 입금한 날로부터 7일~14일이 소요됩니다.</li>
                        <li>납품 방법은 주문한 소매상에서 찾으실 수 있고, 고객이 지정하는 장소로 KDGE 직원이 직접 납품해 드립니다.</li>
                        <li>또한 고객이 희망할 경우 본사를 방문하여 직접 확인 후 인수하실 수도 있습니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>The delivery period takes 7 to 14 days from the date the full product price or down payment (10%) is deposited into KDGE bank account.</li>
                        <li>The delivery method can be found at the retailer where the order was placed, and KDGE staff will deliver directly to the location designated by the customer.</li>
                        <li>In addition, if the customer wishes, he or she can visit the head office and directly check and take over.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    
    
    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >8.구매 취소</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Cancel Purchase</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>구매자는 상품대금을 지급하였거나 10%의 계약금(구매보증금)을 KDGE 본사의 통장으로 송금하기 전까지는 언제든지 구매를 취소할 수 있습니다.</li>
                        <li>주문하신 상품이 아니거나 상품에 하자가 있는 경우에는 아무 조건 없이 취소할 수 있으며 100% 환불해 드립니다.</li>
                        <li>그러나 상품 주문 후 천재지변이 아닌 단순 변심에 의해 구매 취소할 경우 계약금(구매보증금) 또는 상품 가격의 10%는 환불해 드리지 않습니다.</li>
                        <li>그 이유는 해외 수입에 따른 비용과 감정 수수료 및 부가세 등의 비용이 발생하기 때문입니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>The purchaser may cancel the purchase at any time until the product price or 10% down payment (purchase deposit) is remitted to the bank account of KDGE headquarters.</li>
                        <li>If the product is not what you ordered or if the product is defective, you can cancel without any conditions and we will give you a 100% refund.</li>
                        <li>However, if the purchase is canceled due to a simple change of mind, not a natural disaster after ordering the product, the down payment (purchase deposit) or 10% of the product price will not be refunded.</li>
                        <li>The reason is that there are costs related to overseas imports, appraisal fees, and VAT.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >9.주의사항</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Caution</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>다이아몬드에 첨부되어 있는 이미지(사진)는 실물과 다를 수 있습니다.</li>
                        <li>따라서 반드시 첨부된 다이아몬드 감정서를 참고하여 KDGE에 문의하시면 친절하게 안내 해 드립니다.</li>
                        <li>궁금한 점은 언제든지 회원가입이 필요 없는 채팅 상담(채널톡)을 이용해 실시간 소통하실 수 있습니다.</li>
                        <li>데이터베이스권 침해 금지<br>본 kDiaGem 웹사이트 및 앱에 게시된 사진, 링크, 동영상을 포함하는 모든 정보에 대해 수집, 가공, 복제를 할 수 없습니다. 이를 어길 경우 저작권법에 의거하여 처벌받게 되며, 그 행위자는 본사의 손해배상 청구를 전적으로 책임져야 함을 고지합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>Images (photos) attached to diamonds may differ from the actual product.</li>
                        <li>Therefore, if you make an inquiry to KDGE referring to the attached diamond appraisal certificate, we will kindly guide you.</li>
                        <li>If you have any questions, you can communicate in real time using a Chat Inquiry(channel.io) that does not require membership registration at any time.</li>
                        <li>Prohibition of Infringement of Database Rights<br>All information including photos, links, and videos posted on this kDiaGem  website and app cannot be collected, processed, or reproduced. If you violate this, you will be punished according to the copyright law, and we inform you that the violator must be fully responsible for claims for damages from the head office.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>    

    <v-row justify="center" class="mt-5 mt-md-10">
        <v-col cols="12" md="8">
            <v-expansion-panels flat focusable style="color:red">
            <v-expansion-panel>
                <v-expansion-panel-header>
                <v-col class="ma-0 pa-0">
                    <div style="color:black;font-weight:bold;font-family:NotoSansKR"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:16px' : 'font-size:26px'"
                        >10.분쟁 해결</div>
                    <div class="mt-3" style="color:black;font-family:Noto Serif"
                        :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px' : 'font-size:20px'"
                    >Dispute Resolution</div>
                </v-col>
                </v-expansion-panel-header>
                <div :class="$vuetify.breakpoint.smAndDown ? 'black_line_mb' : 'black_line'"/>
                <v-expansion-panel-content>
                    <ol class="ml-2 mr-2 mt-5 mt-md-10" type="1" start="1" style="font-family:NotoSansKR">
                        <li>본 약관에 적시되지 않은 사항들은 kDiaGem APP의 이용약관과 전자상거래 관련 법률에  의거합니다.  그러나 만약 소비자와 본사간의 법적 분쟁이 발생할 경우에는 관할 법원을 서울중앙지방법원으로 정합니다.</li>
                    </ol>
                    <div class="gray_line mt-5 mb-5 mt-md-10 mb-md-10" style="font-family:Inter"/>
                    <ol class="ml-2 mr-2" type="1" start="1">
                        <li>Matters not specified in these terms and conditions are governed by the terms of use of kDiaGem APP and e-commerce related laws. However, if a legal dispute arises between the consumer and the head office, the competent court is determined as the Seoul Central District Court.</li>
                    </ol>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>            
        </v-col>
    </v-row>        

    <v-row class="pt-15"></v-row>

  </v-container>
</template>
<script>

export default {

    mounted() {
        
        window.scrollTo(0,0);

    },

    data: () => ({

    }),

    created(){

    },

    methods: {

    },



}
</script>

<style scoped>

.v-expansion-panel-content>>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

</style>