<template>
  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%;overflow:hidden">

    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#030303"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">Please wait...</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>
      
    <div :style="{backgroundImage:'url(' + require('@/assets/login_bg.png')+')', backgroundSize:'cover', height:'817px' }">

      <v-row class="mt-md-15" align="center" justify="center">
        <v-col class="mt-15" cols="12" md="5" sm="10" xs="10">
          <div class="roundbg text-center">  
              
              <p id="login_title" style="color:#030303;" class="login_text text-center text-md-h3 text-h4">LOGIN(로그인)</p>

              <v-row dense align="center" justify="center" class="mt-15">
                <v-col cols="12">
                  <v-text-field 
                    outlined v-model="user_id" prepend-inner-icon="mdi-account-outline" 
                    name="login" placeholder="이메일 계정을 입력하세요" 
                    color="#030303" hide-details autocapitalize="off"
                    type="text" dense></v-text-field>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-3" >
                <v-col cols="12">
                  <v-text-field 
                    outlined @keyup.enter="login" v-model="user_pw" prepend-inner-icon="mdi-key-outline" 
                    name="password" placeholder="비밀번호를 입력하세요" id="password" autocapitalize="off"
                    type="password" dense color="#030303" hide-details></v-text-field>
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-1" >
                <v-col cols="12">
                <v-checkbox
                  v-model="checkbox"
                  label="Auto Login 자동로그인" dense @change="checkchange"
                  color="#030303"
                  class="login_save"
                  ></v-checkbox> 
                </v-col>           
              </v-row>

              <v-btn  
                class="mt-10"
                style="height:62px;width:100%;font-size:22px;color:white" color="#000000" @click="login">로그인</v-btn>

          </div>
        </v-col>
      </v-row>

    

    <v-row align="center" justify="center">
      <v-col cols="10" md="5" sm="10" xs="10">
        <v-row align="center" justify="center"> 
          
          <v-col cols="11" md="6" align="start" class="hidden-md-and-up">
            <a style="color:#030303;font-size:16px;" @click="runjoin"><strong>Join</strong><span style="font-size:10px;vertical-align:middle;"> 회원가입</span>&nbsp;&nbsp;&nbsp;&nbsp;></a>
          </v-col>
          <v-col cols="11" md="6" align="center" class="hidden-sm-and-down">
            <a style="color:#030303;font-size:16px;" @click="runjoin"><strong>Join</strong><span style="font-size:10px;vertical-align:middle;"> 회원가입</span>&nbsp;&nbsp;&nbsp;&nbsp;></a>
          </v-col>

          <v-col cols="11" md="6" align="start" class="hidden-md-and-up">
            <a style="color:#030303;font-size:16px" @click="runpass"><strong>Find Password</strong> <span style="font-size:10px;vertical-align:middle;">비밀번호 찾기</span>&nbsp;&nbsp;&nbsp;&nbsp;></a>
          </v-col>
          <v-col cols="11" md="6" align="center" class="hidden-sm-and-down">
            <a style="color:#030303;font-size:16px" @click="runpass"><strong>Find Password</strong> <span style="font-size:10px;vertical-align:middle;">비밀번호 찾기</span>&nbsp;&nbsp;&nbsp;&nbsp;></a>
          </v-col>

        </v-row>
      </v-col>
    </v-row>   

    <!-- 
    <v-row style="height:350px" align="center" justify="center">
    </v-row>   
    -->
  </div>

  </v-container>
</template>
<script>

export default {

    mounted() {
      this.PRODUCT_IDX = this.$route.query.code;
      if(this.PRODUCT_IDX == undefined){
        this.PRODUCT_IDX = -1;
      }

      this.nextpage = this.$route.query.next;
      if(this.nextpage == undefined){
        this.nextpage = "";
      }

      this.checkbox = localStorage.getItem("SAVE_ID");
      if(this.checkbox){
        this.user_id = localStorage.getItem("LOGIN_ID");
      }
    },

    data: () => ({

        user_id : ""
      , user_pw : ""
      , loading:false
      , checkbox:false
      , PRODUCT_IDX: -1
      , nextpage: ""

    }),

    methods: {
      
      checkchange(value){
        
        if(value){
          localStorage.setItem("SAVE_ID", true);
        }else{
          localStorage.setItem("SAVE_ID", false);
        }
      },

      runjoin(){
        //회원가입 팝업 표시
        this.$router.push('/Join');
      },

      runpass(){

        if(this.user_id.length < 1){
          this.$fire({
            html: "Please enter an email account to find your password<br><br>비밀번호를 찾기 위한 이메일 계정을 입력하세요",
            confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
            confirmButtonColor: '#000000',
            allowEscapeKey:false, 
            allowOutsideClick:false,
            allowEnterKey:true,
          });
          return;
        }

        //인증코드 재발송
        this.$fire({
          title: "<span style='font-size:18px;color:black'>Find Password <br>비밀번호 찾기</span>",
          html: "Did you forget the password?<br>Are you sure you want to send an authentication email to find the password to the email you signed up for?<br><br>가입하신 이메일로 비밀번호를 찾기위한 인증메일을 발송 하시겠습니까?",
          confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
          confirmButtonColor: '#000000',
          cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
          cancelButtonColor:'#ff0000',
          showCancelButton: true, 
          reverseButtons: true,
          allowEscapeKey:false, 
          allowOutsideClick:false,
          allowEnterKey:true,
        }).then(r => {
          
          if(r.value){
            this.$http.post(this.$host+'/resetpassword',{
                ACCNT: this.user_id
            })
            // eslint-disable-next-line no-unused-vars
            .then((result)=>{
              if(result.data.resultCode == 0){
                this.$fire({
                  html: "password-finding authentication mail has been sent.<br>Please check the authentication mail<br><br>비밀번호 찾기 인증메일이 발송되었습니다.<br>인증메일을 확인하세요",
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }else{
                this.$fire({
                  html: result.data.resultMsg,
                  confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                  confirmButtonColor: '#000000',
                  allowEscapeKey:false, 
                  allowOutsideClick:false,
                  allowEnterKey:true,
                });
              }
            });
          }

        });

      },


      login(){

        const USERID = this.user_id.trim();

          if(USERID.length < 1){
            this.$fire({
              html: "Please enter your email account<br><br>계정을 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(this.user_pw.length < 1){
            this.$fire({
              html: "Please enter your password.<br><br>비밀번호를 입력하세요",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }
          if(this.user_pw.length < 6){
            this.$fire({
              html: "Password must be at least 6 characters long.<br><br>비밀번호는 최소 6글자 이상입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });
            return;
          }

          localStorage.setItem("LOGIN_ID", USERID);

          this.loading = true;
          this.$http.post(this.$host+'/fireLogin',{
              USER_ID: USERID
            , USER_PW: Buffer.from(this.user_pw).toString('base64')
          })
          .then((result)=>{
              
              this.loading = false;
              if(result.data.resultCode == 0){

                const user = result.data.resultData[0];
                
                //계정 세션값 셋팅
                this.$session.set("ACCNT_IDX",  user.ACCNT_IDX);
                this.$session.set("ACCNT",      user.ACCNT);
                this.$session.set("NAME",       user.NAME);
                this.$session.set("GRADE",      user.GRADE);
                this.$session.set("SUB_DAYS",   user.SUB_DAYS);

                //토큰 저장 및 로그인 정보 저장
                if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
                  
                  this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
                  localStorage.setItem("ACCESSTOKEN",  this.$session.get("ACCESSTOKEN"));
                }
                if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
                  this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
                  localStorage.setItem("REFLASHTOKEN", this.$session.get("REFLASHTOKEN"));
                }
                

                //이동
                if(user.GRADE > 90){
                  //로그인 성공 전역 이벤트 발송
                  if(this.PRODUCT_IDX == -1){
                    if(this.nextpage.length > 0){
                      this.$router.push('/'+this.nextpage);  
                    }else{
                      this.$router.push('/AdminAccnt');
                    }
                  }else{
                    this.$router.push({path:"/ProductDetail", query:{code:this.PRODUCT_IDX}});
                  }
                  this.$EventBus.$emit('loginresult');
                  
                }else if(user.GRADE >= 10){  //공급자와 도매업자는 승인 후 이용이 가능하다.
                  
                  if(user.STATE == 2){
                    //로그인 성공 전역 이벤트 발송
                    if(this.PRODUCT_IDX == -1){
                      if(this.nextpage.length > 0){
                        this.$router.push('/'+this.nextpage);  
                      }else{
                        if(user.GRADE == 5){
                          //도매업자는 제품리스트로
                          this.$router.push('/ProductList?code=0&page=1&rtype=1');
                        }else{
                          //공급자는 상품관리로
                          this.$router.push('/AdminProduct');
                        }
                      }
                    }else{
                      this.$router.push({path:"/ProductDetail", query:{code:this.PRODUCT_IDX}});
                    }
                  }else if(user.STATE == 1){
                    //공급자 승인대기
                    this.$fire({
                      html: "Waiting for business member approval.<br>All functions can be used after the approval of the business operator.<br><br>사업자회원 승인 대기중입니다.<br>사업자 승인 후 모든 기능을 사용할 수 있습니다.",
                      confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                      confirmButtonColor: '#000000',
                      allowEscapeKey:false, 
                      allowOutsideClick:false,
                      allowEnterKey:true,
                    });
                    this.$session.clear("ACCNT_IDX");
                    this.$session.clear("ACCNT");
                    this.$session.clear("NAME");
                    this.$session.clear("GRADE");
                    this.$session.clear("SUB_DAYS");
                  }

                  this.$EventBus.$emit('loginresult');

                }else{
                    //소비자, 소매업자
                    if(this.PRODUCT_IDX == -1){
                      //this.$router.push({path:"/ProductList", query:{code:0, page:1, rtype:1}});
                      if(this.nextpage.length > 0){
                        this.$router.push('/'+this.nextpage);  
                      }else{
                        //this.$router.push('/ProductList?code=0&page=1&rtype=1');
                        this.$router.push('/Welcome');
                      }
                    }else{
                      this.$router.push({path:"/ProductDetail", query:{code:this.PRODUCT_IDX}});
                    }
                    this.$EventBus.$emit('loginresult');
                }
                

            }else if(result.data.resultCode == 1){
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });

            }else if(result.data.resultCode == 2){

              //인증코드 재발송
              this.$fire({
                html: "This account has not been verified for email authentication.<br>Do you want to resend the email authentication code?<br><br>이메일 인증이 확인되지 않은 계정입니다.<br>이메일 인증코드를 재발송 하시겠습니까?",
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                cancelButtonText:"<div style='font-size:14px;line-height:15px'>Cancel<br>취소</div>",
                cancelButtonColor:'#ff0000',
                showCancelButton: true, 
                reverseButtons: true,
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              }).then(r => {
                if(r.value){
                  this.$http.post(this.$host+'/sendemail',{
                      USER_ID: this.user_id
                    , USER_PW: Buffer.from(this.user_pw).toString('base64')
                  })
                  // eslint-disable-next-line no-unused-vars
                  .then((result2)=>{
                    
                    
                    
                    if(result2.data.resultCode == 0){
                      this.$fire({
                        html: "Authentication mail has been sent.<br>Please check the authentication mail<br><br>인증메일이 발송되었습니다.<br>인증메일을 확인하세요",
                        confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                        confirmButtonColor: '#000000',
                        allowEscapeKey:false, 
                        allowOutsideClick:false,
                        allowEnterKey:true,
                      });
                    }else{
                      this.$fire({
                        html: result2.data.resultMsg,
                        confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                        confirmButtonColor: '#000000',
                        allowEscapeKey:false, 
                        allowOutsideClick:false,
                        allowEnterKey:true,
                      });
                    }
                  });
                }
              });       

            }else{
              this.$fire({
                html: result.data.resultMsg,
                confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
                confirmButtonColor: '#000000',
                allowEscapeKey:false, 
                allowOutsideClick:false,
                allowEnterKey:true,
              });
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
            this.loading = false;
            this.$fire({
              html: "Missing or invalid password.<br><br>아이디가 없거나 잘못된 비밀번호 입니다.",
              confirmButtonText:"<div style='font-size:14px;line-height:15px'>Confirm<br>확인</div>",
              confirmButtonColor: '#000000',
              allowEscapeKey:false, 
              allowOutsideClick:false,
              allowEnterKey:true,
            });

          });


      },

    },

    created(){

      if(this.$session.exists("ACCNT_IDX")){
        //키값이 있으면 로그인이 이미 된 경우
        this.$router.push('/AccntList');  
      }

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.v-main__wrap{
  background: white;
}

.roundbg{
  border-radius: 25px;
  padding: 20px;
  padding-bottom: 30px;
}

.login_text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.login_save{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #030303;
}

</style>